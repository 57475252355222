


















import { Component, Prop, Vue, Watch } from "vue-property-decorator"

import { GraeDTO } from "@/model/bean/GeneratedDTOs"
import GraeProjectsView from "@/views/grae/GraeProjectsView.vue"
import GraeEtablissementsView from "@/views/grae/GraeEtablissementsView.vue"
import GraeStructuresView from "@/views/grae/GraeStructuresView.vue"

@Component({
  components: {
    GraeProjectsView,
    GraeEtablissementsView,
    GraeStructuresView,
  },
})
export default class GraeDetailsView extends Vue {
  @Prop() grae: GraeDTO | undefined
  @Prop() activeTab: number
  @Prop({ default: false }) lowerTab: boolean

  private currentTabIndex = 0

  mounted(): void {
    this.currentTabIndex = this.activeTab
  }

  @Watch("currentTabIndex")
  refreshURLFromCurrentTabIndex(_oldCurrenTabIndex: number, _newCurrentTabIndex: number): void {
    const params = this.$router.currentRoute.params
    if (this.currentTabIndex && this.currentTabIndex.toString() !== params["activeSubTab"]) {
      params["activeSubTab"] = this.currentTabIndex.toString()
      this.$router.push({ params: params })
    }
  }
}
