var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "projet-part" }, [
    _c("h2", [_vm._v(_vm._s(_vm.$t("projetPage.mon-annee.conseils.titre")))]),
    _vm.projetAnnuel.typeDossier !== "DOSSIER_RENOUVELLEMENT_LIGHT" &&
    (!_vm.projetAnnuel.conseils || _vm.projetAnnuel.conseils.length == 0)
      ? _c(
          "div",
          [
            _c("InputWithValidation", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false"
                }
              ],
              attrs: {
                validationActived: _vm.validationActivated,
                vid: "conseil_number",
                rules: "required"
              }
            }),
            _vm.validationActivated
              ? _c("b-message", { attrs: { type: "is-danger" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("projetPage.mon-annee.conseils.conseils-missing")
                    )
                  )
                ])
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "conseil-cards" },
      [
        !_vm.readonly
          ? _c("ConseilCard", {
              staticClass: "mr-3",
              attrs: {
                validationActivated: _vm.validationActivated,
                showValidationErrors: _vm.showValidationErrors,
                projetAnnuel: _vm.projetAnnuel,
                canDelete: false,
                readonly: _vm.readonly
              },
              on: { add: _vm.added }
            })
          : _vm._e(),
        _vm._l(_vm.projetAnnuel.conseils, function(conseil) {
          return _c(
            "div",
            { key: conseil.id, staticClass: "mr-3" },
            [
              _c("ConseilCard", {
                attrs: {
                  validationActivated: _vm.validationActivated,
                  showValidationErrors: _vm.showValidationErrors,
                  conseil: conseil,
                  projetAnnuel: _vm.projetAnnuel,
                  canDelete: !_vm.readonly,
                  readonly: true
                },
                on: { delete: _vm.deleted }
              })
            ],
            1
          )
        })
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "mt-5" },
      [
        _c("SelectWithValidation", {
          attrs: {
            validationActivated: _vm.validationActivated,
            showValidationErrors: _vm.showValidationErrors,
            rules:
              _vm.projetAnnuel.typeDossier === "DOSSIER_RENOUVELLEMENT_LIGHT"
                ? ""
                : "required",
            disabled: _vm.readonly || !_vm.isPorteurProjet,
            vid: "conseil_constitutionConseilTerreMer",
            data: _vm.constitutionConseil,
            selectLabels: _vm.constitutionConseilLabels,
            label: _vm.$t("projetPage.mon-annee.conseils.constitution"),
            inputNotice: _vm.$t(
              "projetPage.mon-annee.conseils.constitution-message"
            )
          },
          on: { "manual-change": _vm.changeConseils },
          model: {
            value: _vm.projetAnnuel.constitutionConseilTerreMer,
            callback: function($$v) {
              _vm.$set(_vm.projetAnnuel, "constitutionConseilTerreMer", $$v)
            },
            expression: "projetAnnuel.constitutionConseilTerreMer"
          }
        }),
        _vm.displayOther
          ? _c("InputWithValidation", {
              attrs: {
                validationActivated: _vm.validationActivated,
                showValidationErrors: _vm.showValidationErrors,
                rules:
                  _vm.projetAnnuel.typeDossier ===
                  "DOSSIER_RENOUVELLEMENT_LIGHT"
                    ? ""
                    : "required",
                label: _vm.$t(
                  "projetPage.mon-annee.conseils.constitution-autre"
                ),
                placeholder: _vm.$t(
                  "projetPage.mon-annee.conseils.constitution-autre"
                ),
                disabled: _vm.readonly || !_vm.isPorteurProjet,
                vid: "conseil_autre"
              },
              on: { "manual-change": _vm.changeConseils },
              model: {
                value: _vm.projetAnnuel.constitutionConseilTerreMerAutre,
                callback: function($$v) {
                  _vm.$set(
                    _vm.projetAnnuel,
                    "constitutionConseilTerreMerAutre",
                    $$v
                  )
                },
                expression: "projetAnnuel.constitutionConseilTerreMerAutre"
              }
            })
          : _vm._e(),
        _c("InputWithValidation", {
          attrs: {
            validationActivated: _vm.validationActivated,
            showValidationErrors: _vm.showValidationErrors,
            rules:
              _vm.projetAnnuel.typeDossier === "DOSSIER_RENOUVELLEMENT_LIGHT"
                ? ""
                : "required",
            type: "textarea",
            label: _vm.$t(
              "projetPage.mon-annee.autres-infos.projet-aborde-debut-annee"
            ),
            inputNotice: _vm.$t(
              "projetPage.mon-annee.autres-infos.projet-aborde-debut-annee-hint"
            ),
            maxlength: "1400",
            disabled: _vm.readonly || !_vm.isPorteurProjet,
            vid: "conseil_projetAbordeDebutAnnee"
          },
          on: { "manual-change": _vm.changeConseils },
          model: {
            value: _vm.projetAnnuel.projetAbordeDebutAnnee,
            callback: function($$v) {
              _vm.$set(_vm.projetAnnuel, "projetAbordeDebutAnnee", $$v)
            },
            expression: "projetAnnuel.projetAbordeDebutAnnee"
          }
        }),
        _vm.displayOrganisation
          ? _c("InputWithValidation", {
              attrs: {
                validationActivated: _vm.validationActivated,
                showValidationErrors: _vm.showValidationErrors,
                type: "textarea",
                label: _vm.$t("projetPage.mon-annee.conseils.organisation"),
                maxlength: "1400",
                disabled: _vm.readonly || !_vm.isPorteurProjet,
                vid: "conseil_organisationClasses"
              },
              on: { "manual-change": _vm.changeConseils },
              model: {
                value: _vm.projetAnnuel.organisationClasses,
                callback: function($$v) {
                  _vm.$set(_vm.projetAnnuel, "organisationClasses", $$v)
                },
                expression: "projetAnnuel.organisationClasses"
              }
            })
          : _vm._e(),
        _vm.validationActivated &&
        (_vm.projetAnnuel.typeDossier === "DOSSIER_LABELLISATION" ||
          _vm.projetAnnuel.typeDossier === "DOSSIER_RENOUVELLEMENT") &&
        _vm.showRequiredMessage
          ? _c("b-message", { attrs: { type: "is-danger" } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "projetPage.mon-annee.autres-infos.labellisation-renouvellement"
                    )
                  ) +
                  " "
              )
            ])
          : _vm._e(),
        _c("InputWithValidation", {
          attrs: {
            validationActivated: _vm.validationActivated,
            showValidationErrors: _vm.showValidationErrors,
            type: "textarea",
            label: _vm.$t("projetPage.mon-annee.conseils.liens"),
            rules:
              _vm.projetAnnuel.typeDossier === "DOSSIER_RENOUVELLEMENT_LIGHT"
                ? ""
                : _vm.calculatedRules,
            maxlength: "1400",
            disabled: _vm.readonly || !_vm.isPorteurProjet,
            vid: "conseil_conseilsLiens"
          },
          on: { "manual-change": _vm.changeConseilsLiens },
          model: {
            value: _vm.projetAnnuel.conseilsLiens,
            callback: function($$v) {
              _vm.$set(_vm.projetAnnuel, "conseilsLiens", $$v)
            },
            expression: "projetAnnuel.conseilsLiens"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }