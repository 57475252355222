/*-
 * #%L
 * Sagae
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
import { CalendrierEvenementCreateDTO, CalendrierEvenementDTO } from "@/model/bean/GeneratedDTOs"
import { AbstractTypedRestService } from "@/services/rest/AbstractTypedRestService"

export class EvenementService extends AbstractTypedRestService<CalendrierEvenementDTO> {
  static INSTANCE = new EvenementService()

  async getEvenements(
    idProjetAnnuel: string,
    startDate: Date,
    endDate: Date
  ): Promise<CalendrierEvenementDTO[]> {
    const params = new Map<string, string | Array<any>>()
    params.set("startDate", startDate.toISOString().substring(0, 10))
    params.set("endDate", endDate.toISOString().substring(0, 10))
    return super.typedGetV2<CalendrierEvenementDTO>(
      "projets/" + idProjetAnnuel + "/evenements",
      2,
      params
    )
  }

  async createEvenement(
    idProjetAnnuel: string,
    evenement: CalendrierEvenementCreateDTO
  ): Promise<CalendrierEvenementDTO> {
    return super.typedPostWithDifferentResultTypeV2<
      CalendrierEvenementCreateDTO,
      CalendrierEvenementDTO
    >("projets/" + idProjetAnnuel + "/evenements", 2, evenement)
  }

  async removeEvenement(idProjetAnnuel: string, idEvenement: string): Promise<string[]> {
    return super.typedDeleteV2("projets/" + idProjetAnnuel + "/evenements/" + idEvenement, 2)
  }
}
