
















































































































import { FilterDTO } from "@/model/bean/GeneratedDTOs"
import Vue from "vue"
import Component from "vue-class-component"
import { Prop, Watch } from "vue-property-decorator"
import { FilterDTOWithLabel } from "../tables/FilterDTOWithLabel"
import Activable from "../utils/Activable"

export class FilterField {
  field: string
  label: string
  possibleSearchValues: string[]

  constructor() {
    this.field = ""
    this.label = ""
    this.possibleSearchValues = []
  }
}

@Component({
  components: {},
})
export default class FilterComponent extends Vue {
  @Prop({ default: () => [] }) filterFields: FilterField[]
  @Prop({ default: () => [] }) initialFilters: FilterDTO[]

  newFilterDTO = new FilterDTO()
  filterKind = "value"
  activeFilters = new Array<FilterDTO>()
  chosenFilterField = new FilterField()
  newFilterValue = ""

  mounted(): void {
    this.activeFilters = JSON.parse(JSON.stringify(this.initialFilters))
    this.newFilterDTO.values = []
  }

  @Watch("initialFilters")
  initialFiltersChanged(): void {
    if (this.activeFilters.length != this.initialFilters.length) {
      this.activeFilters = JSON.parse(JSON.stringify(this.initialFilters))
      this.$emit("update-active-filters", this.activeFilters)
    }
  }

  setChosenFilterField(): void {
    this.chosenFilterField =
      this.filterFields.find((ff) => ff.field === this.newFilterDTO.property) || new FilterField()
  }

  @Watch("newFilterValue")
  setFilterValues(newValue: string): void {
    this.newFilterDTO.values = [newValue]
  }

  addActiveFilter(): void {
    if (
      this.newFilterDTO.property &&
      (this.filterKind == "null" ||
        (this.newFilterDTO.values.length > 0 && this.newFilterDTO.values[0]))
    ) {
      // check if there is an already existing filter or create a new one
      const existingFilter = this.activeFilters.find((filter) => {
        return filter.property == this.newFilterDTO.property
      })
      if (!existingFilter) {
        const newFilter = new FilterDTOWithLabel()
        newFilter.property = this.newFilterDTO.property
        newFilter.values = this.newFilterDTO.values
        newFilter.label = this.filterFields.filter(
          (col) => col.field == newFilter.property
        )[0].label
        this.activeFilters.push(newFilter)
      } else {
        // Push empty string if filter was without any value
        if (existingFilter.values.length == 0) {
          existingFilter.values.push("")
        }

        if (this.filterKind == "value") {
          existingFilter.values.push(this.newFilterDTO.values[0].trim())
        } else {
          existingFilter.values.push("")
        }
      }

      // reset values and close dropdown
      this.newFilterDTO = new FilterDTO()
      this.newFilterValue = ""
      this.filterKind = "value"
      const dropdown = (this.$refs["columns-values-dropdown"] as unknown) as Activable
      dropdown.isActive = false
      // Warn that filters changed
      this.$emit("update-active-filters", this.activeFilters)
    }
  }

  removeFilterValue(property: string, value: string): void {
    const filter = this.activeFilters.filter((filter) => filter.property == property)
    if (filter.length > 0) {
      if (filter[0].values.length > 1) {
        // Only remove value but none filter, other values remaine
        filter[0].values = filter[0].values.filter((val) => val != value)
      } else {
        // Was last value, remove filter from list
        this.activeFilters = this.activeFilters.filter((filter) => filter.property != property)
      }
    }
    this.$forceUpdate()

    // Warn AbstractTableView that filters changed
    this.$emit("update-active-filters", this.activeFilters)
  }
}
