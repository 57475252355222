var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PageView",
    { attrs: { isOnBigHeaderPage: true, noSubTitleExpected: "true" } },
    [
      _c("TitleView", [_vm._v(_vm._s(_vm.$t("registerPage.titre")))]),
      _c("ContentView", [
        _vm.isLoading
          ? _c(
              "div",
              [
                _c("b-skeleton", { attrs: { width: "40%", animated: "" } }),
                _c("b-skeleton", { attrs: { width: "80%", animated: "" } })
              ],
              1
            )
          : _c(
              "div",
              [
                !_vm.internalErrorMessage
                  ? _c(
                      "div",
                      [
                        _c("b-message", { attrs: { type: "is-success" } }, [
                          _vm._v(" " + _vm._s(_vm.registrationMessage) + " ")
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.internalErrorMessage
                  ? _c(
                      "div",
                      [
                        _c("b-message", { attrs: { type: "is-danger" } }, [
                          _vm._v(" " + _vm._s(_vm.internalErrorMessage) + " ")
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _c("br"),
                !_vm.internalErrorMessage || _vm.initialResendMode !== "none"
                  ? _c("b-message", { attrs: { type: "is-warning" } }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("registerPage.checkspams")) + " "
                      )
                    ])
                  : _vm._e(),
                _c("br"),
                _vm.initialResendMode != "none"
                  ? _c(
                      "b-button",
                      {
                        attrs: { type: "is-primary" },
                        on: {
                          click: function($event) {
                            return _vm.resendValidationEmail()
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("registerPage.resend")))]
                    )
                  : _vm._e()
              ],
              1
            )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }