var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationProvider", {
    attrs: {
      vid: (_vm.group != "" ? _vm.group + "_" : "") + _vm.vid,
      name: _vm.$attrs.name || _vm.$attrs.label,
      rules: _vm.rules
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(ref) {
            var errors = ref.errors
            var valid = ref.valid
            return [
              _c(
                "b-field",
                {
                  staticClass: "InputWithValidation-field",
                  attrs: {
                    type: {
                      "is-normal": _vm.disabled,
                      "is-danger": errors[0] || _vm.customError.length > 0,
                      "is-success": valid
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { class: "InputWithValidation-control-container" },
                    [
                      _c("b-input", {
                        attrs: {
                          placeholder: _vm.placeholder,
                          type: _vm.$attrs.type,
                          "password-reveal": _vm.passwordReveal,
                          maxlength: _vm.maxlength,
                          disabled: _vm.disabled,
                          "custom-class": _vm.disabled
                            ? "InputWithValidation-disabled"
                            : "",
                          autocomplete: _vm.autocomplete,
                          "has-counter": _vm.hasCounter
                        },
                        model: {
                          value: _vm.innerValue,
                          callback: function($$v) {
                            _vm.innerValue = $$v
                          },
                          expression: "innerValue"
                        }
                      }),
                      _c(
                        "p",
                        { staticClass: "InputWithValidation-control" },
                        [_vm._t("default")],
                        2
                      )
                    ],
                    1
                  )
                ]
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }