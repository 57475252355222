

















import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import TitleView from "@/views/commons/TitleView.vue"
import { ProjetAnnuelDTO } from "@/model/bean/GeneratedDTOs"
import GroupCheckboxesWithValidation from "@/views/inputs/GroupCheckBoxesWithValidation.vue"
import { EnumHelper } from "@/model/bean/EnumHelper"

@Component({
  components: {
    TitleView,
    GroupCheckboxesWithValidation,
  },
})
export default class InstructionObjetsRealisation extends Vue {
  @Prop() projetAnnuel: ProjetAnnuelDTO
  @Prop({ default: false }) readOnly: boolean

  internalProjetAnnuel = new ProjetAnnuelDTO()
  allObjetsRealisation = EnumHelper.allObjetsRealisation
  checkedObjetsLabels: string[] = []

  created(): void {
    this.internalProjetAnnuel = this.projetAnnuel
    this.refreshChecked()
  }

  @Watch("projetAnnuel")
  dossierChanged(): void {
    this.internalProjetAnnuel = this.projetAnnuel
    this.refreshChecked()
  }

  refreshChecked(): void {
    if (this.internalProjetAnnuel.objectsConcretRealisation) {
      this.checkedObjetsLabels = this.internalProjetAnnuel.objectsConcretRealisation.map(
        (objet) => {
          return this.$i18n.t("instruction." + objet).toString()
        }
      )
    }
    if (this.internalProjetAnnuel.objectConcretRealisationAutre) {
      this.checkedObjetsLabels.push(this.$i18n.t("instruction.AUTRE").toString())
    }
  }

  allObjetsRealisationLabels(): string[] {
    return this.allObjetsRealisation.map((objet) => {
      return this.$i18n.t("instruction." + objet).toString()
    })
  }

  changeObjetsRealisation(): void {
    this.internalProjetAnnuel.objectsConcretRealisation = this.allObjetsRealisation.filter(
      (objet) => {
        return (
          this.checkedObjetsLabels.indexOf(this.$i18n.t("instruction." + objet).toString()) > -1
        )
      }
    )
    if (this.checkedObjetsLabels.indexOf(this.$i18n.t("instruction.AUTRE").toString()) == -1) {
      this.changeObjetRealisationAutre("")
    }
  }

  changeObjetRealisationAutre(newValue: string): void {
    this.internalProjetAnnuel.objectConcretRealisationAutre = newValue
    const indexAutre = this.checkedObjetsLabels.indexOf(
      this.$i18n.t("instruction.AUTRE").toString()
    )
    if (newValue && indexAutre === -1) {
      this.checkedObjetsLabels.push(this.$i18n.t("instruction.AUTRE").toString())
    }
  }
}
