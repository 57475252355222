var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "projet-part" },
      [
        _c("h2", [
          _vm._v(_vm._s(_vm.$t("projet.inscription.vue-ensemble.titre")))
        ]),
        _c("InputWithValidation", {
          attrs: {
            label: _vm.$t(
              "projet.inscription.vue-ensemble.projet_pedagogique.question"
            ),
            inputNotice: _vm.$t(
              "projet.inscription.vue-ensemble.projet_pedagogique.info"
            ),
            rules: "required",
            vid: "ensemble_0",
            disabled: _vm.readonly,
            showValidationErrors: _vm.showValidationErrors,
            type: "textarea"
          },
          on: {
            "manual-change": function($event) {
              return _vm.$emit("dirtify-project")
            }
          },
          model: {
            value: _vm.projetAnnuel.projetPedagogique,
            callback: function($$v) {
              _vm.$set(_vm.projetAnnuel, "projetPedagogique", $$v)
            },
            expression: "projetAnnuel.projetPedagogique"
          }
        }),
        _c("br"),
        _c("InputWithValidation", {
          attrs: {
            label: _vm.$t(
              "projet.inscription.vue-ensemble.presentation.question"
            ),
            inputNotice: _vm.$t(
              "projet.inscription.vue-ensemble.presentation.info"
            ),
            rules: "required",
            vid: "ensemble_1",
            disabled: _vm.readonly,
            showValidationErrors: _vm.showValidationErrors,
            type: "textarea"
          },
          on: {
            "manual-change": function($event) {
              return _vm.$emit("dirtify-project")
            }
          },
          model: {
            value: _vm.projetAnnuel.pitch,
            callback: function($$v) {
              _vm.$set(_vm.projetAnnuel, "pitch", $$v)
            },
            expression: "projetAnnuel.pitch"
          }
        }),
        _c("RadioWithInputAndValidation", {
          attrs: {
            label: _vm.$t("projet.inscription.vue-ensemble.reunions.question"),
            data: _vm.booleanValue,
            optionLabels: _vm.booleanValueLabels,
            showValidationErrors: _vm.showValidationErrors,
            rules: "required",
            vid: "ensemble_2",
            verticalDisplay: "true",
            disabled: _vm.readonly
          },
          on: {
            "manual-change": function($event) {
              return _vm.$emit("dirtify-project")
            }
          },
          model: {
            value: _vm.projetAnnuel.contexteReunionReferent,
            callback: function($$v) {
              _vm.$set(_vm.projetAnnuel, "contexteReunionReferent", $$v)
            },
            expression: "projetAnnuel.contexteReunionReferent"
          }
        }),
        _c("RadioWithInputAndValidation", {
          attrs: {
            label: _vm.$t("projet.inscription.vue-ensemble.mairie.question"),
            data: _vm.booleanValue,
            optionLabels: _vm.booleanValueLabels,
            rules: "required",
            vid: "ensemble_3",
            showValidationErrors: _vm.showValidationErrors,
            verticalDisplay: "true",
            disabled: _vm.readonly
          },
          on: {
            "manual-change": function($event) {
              return _vm.$emit("dirtify-project")
            }
          },
          model: {
            value: _vm.projetAnnuel.contexteLienMairie,
            callback: function($$v) {
              _vm.$set(_vm.projetAnnuel, "contexteLienMairie", $$v)
            },
            expression: "projetAnnuel.contexteLienMairie"
          }
        }),
        _c("ProjetAutresInfosView", {
          attrs: {
            projetAnnuel: _vm.projetAnnuel,
            readonly: _vm.readonly,
            validationActivated: true,
            showValidationErrors: _vm.showValidationErrors,
            withTitle: false,
            validationGroup: "ensemble"
          },
          on: {
            "dirtify-project": function($event) {
              return _vm.$emit("dirtify-project")
            }
          }
        }),
        _c("div")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }