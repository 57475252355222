var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PageView",
    {
      staticClass: "validate",
      attrs: {
        mainTitle: _vm.$t("validate-email.title"),
        noSubTitleExpected: "true"
      }
    },
    [
      _c(
        "ContentView",
        [
          _c(
            "b-message",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.loading && !_vm.emailValidated,
                  expression: "!loading && !emailValidated"
                }
              ],
              attrs: { type: "is-danger" }
            },
            [_vm._v(" " + _vm._s(_vm.$t("validate-email.ko")) + " ")]
          ),
          _c(
            "b-message",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    !_vm.loading && _vm.emailValidated && _vm.requireValidation,
                  expression: "!loading && emailValidated && requireValidation"
                }
              ],
              attrs: { type: "is-warning" }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("validate-email.require-validation")) + " "
              )
            ]
          ),
          _c(
            "b-message",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              attrs: { type: "is-info" }
            },
            [
              _vm._v(" " + _vm._s(_vm.$t("validate-email.loading")) + " "),
              _c("i", {
                staticClass: "fas fa-spinner fa-spin",
                attrs: { "aria-hidden": "true" }
              })
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }