


















import { Component, Prop, Vue, Watch } from "vue-property-decorator"

import { InfoReportingService } from "@/services/log/InfoReportingService"
import LabellisationClotureView from "./LabellisationClotureView.vue"
import LabellisationHarmonisationView from "./LabellisationHarmonisationView.vue"

@Component({
  components: { LabellisationClotureView, LabellisationHarmonisationView },
})
export default class LabellisationsView extends Vue {
  @Prop() graeId: string
  @Prop({ default: 0 }) activeSubTab: number
  @Prop({ default: 0 }) activeQuickFilter: number

  protected infoReportingService = InfoReportingService.INSTANCE

  private currentTabIndex = 0

  mounted(): void {
    this.currentTabIndex = this.activeSubTab
  }

  @Watch("currentTabIndex")
  refreshURLFromCurrentTabIndex(_oldCurrenTabIndex: number, _newCurrentTabIndex: number): void {
    const params = this.$router.currentRoute.params
    if (this.currentTabIndex && this.currentTabIndex.toString() !== params["activeSubTab"]) {
      params["activeSubTab"] = this.currentTabIndex.toString()
      this.$router.push({ params: params })
    }
  }
}
