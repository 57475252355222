var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PageView",
    {
      attrs: {
        mainTitle: _vm.$t("etablissementPage." + _vm.section),
        subTitle:
          _vm.etablissement && _vm.etablissement.nom
            ? _vm.etablissement.nom
            : ""
      }
    },
    [
      _c("ContentView", [
        _vm.section === "details"
          ? _c(
              "div",
              [
                !_vm.isChanging && _vm.etablissement
                  ? _c("EtablissementView", {
                      ref: "etablissementView",
                      attrs: {
                        etablissement: _vm.etablissement,
                        userCanChange:
                          !_vm.isAccompagnateurOrAdmin && !_vm.readonly,
                        readonly: _vm.readonly,
                        validationGroup: "EtablissementDetails"
                      },
                      on: {
                        "change-etablissement": function($event) {
                          _vm.isChanging = true
                        }
                      }
                    })
                  : _vm.user && _vm.user.profil === "ENSEIGNANT"
                  ? _c("EtablissementSelectionView", {
                      attrs: {
                        buttonLabel: _vm.$t("registerPage.valider"),
                        enableBack: true,
                        goBackLabel: _vm.$t("annuler")
                      },
                      on: {
                        "input-etablissement": _vm.changeEtablissement,
                        "go-back": function($event) {
                          _vm.isChanging = false
                        }
                      },
                      model: {
                        value: _vm.etablissement,
                        callback: function($$v) {
                          _vm.etablissement = $$v
                        },
                        expression: "etablissement"
                      }
                    })
                  : _vm._e(),
                !_vm.readonly && !_vm.isChanging
                  ? _c(
                      "div",
                      { staticClass: "buttons" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { type: "is-primary" },
                            on: { click: _vm.updateEtablissement }
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("profilePage.save")))
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          : _vm.section === "enseignants" &&
            !_vm.isChanging &&
            (_vm.isAccompagnateurOrAdmin ||
              _vm.isFromEtablissement ||
              _vm.isObservateurNational)
          ? _c(
              "div",
              [
                _vm.etablissement.id
                  ? _c("EtablissementEnseignantsView", {
                      attrs: { etablissementId: _vm.etablissement.id }
                    })
                  : _vm._e()
              ],
              1
            )
          : _vm.section === "projets" &&
            !_vm.isChanging &&
            (_vm.isAccompagnateurOrAdmin ||
              _vm.isFromEtablissement ||
              _vm.isObservateurNational)
          ? _c(
              "div",
              [
                _vm.etablissement
                  ? _c("EtablissementProjetsView", {
                      attrs: { etablissement: _vm.etablissement }
                    })
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }