var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("b-loading", {
        model: {
          value: _vm.isLoading,
          callback: function($$v) {
            _vm.isLoading = $$v
          },
          expression: "isLoading"
        }
      }),
      _vm._l(_vm.regionProjects, function(ref) {
        var region = ref[0]
        var structures = ref[1]
        return _c(
          "div",
          { key: region, staticClass: "structure-projets-view" },
          [
            _c("H3TitleView", { staticClass: "modal-card-title" }, [
              _vm._v(_vm._s(region))
            ]),
            _c(
              "div",
              { staticClass: "StructureProjetsView-cards" },
              _vm._l(structures, function(project) {
                return _c(
                  "div",
                  { key: project.id },
                  [
                    _c("ProjectCard", {
                      attrs: { project: project, loggedUser: _vm.loggedUser }
                    })
                  ],
                  1
                )
              }),
              0
            )
          ],
          1
        )
      }),
      !_vm.isLoading && _vm.regionProjects.size === 0
        ? _c("b-message", { attrs: { type: "is-warning" } }, [
            _vm._v(" " + _vm._s(_vm.$t("structurePage.noProjects")) + " ")
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }