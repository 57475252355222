





























































































































































































import AbstractTableView from "@/views/tables/AbstractTableView.vue"
import TableFilterView from "@/views/tables/TableFilterView.vue"
import Column from "@/views/tables/Column"
import {
  PaginationOrder,
  ProjetAnnuelDTO,
  StructureDTO,
  UtilisateurDTO,
} from "@/model/bean/GeneratedDTOs"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import { Component, Prop, Watch } from "vue-property-decorator"
import { LoginService } from "@/services/rest/login/LoginService"
import { UsersService } from "@/services/rest/users/UsersService"
import { DialogProgrammatic as Dialog } from "buefy"
import ObfuscationUtils from "@/services/ObfuscationUtils"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"

@Component({
  components: {
    TableFilterView,
    InputWithValidation,
  },
})
export default class ProjetReferentsView extends AbstractTableView {
  @Prop() projetAnnuel: ProjetAnnuelDTO
  @Prop() invitations: string[]
  @Prop() readonly: boolean
  @Prop() showInviteLink: boolean
  @Prop() isPorteurProjet: boolean

  private loginService = LoginService.INSTANCE
  private usersService = UsersService.INSTANCE
  private infoReportingService = InfoReportingService.INSTANCE

  loggedUser: UtilisateurDTO = new UtilisateurDTO()
  referents: Array<UtilisateurDTO> = []
  tableRef = "referents"
  hasEnoughValidatedReferents = false
  structures: Array<StructureDTO> = []

  @Watch("projetAnnuel.referents")
  projetAnnuelChanged(): void {
    this.reloadData()
  }

  created(): void {
    const loggedUser = this.loginService.getLoggedUser()
    if (!loggedUser || !loggedUser.id) {
      return
    }
    this.loggedUser = loggedUser
  }

  async reloadData(): Promise<void> {
    this.referents = JSON.parse(JSON.stringify(this.projetAnnuel.referents))
    this.isLoading = false
    this.hasEnoughValidatedReferents =
      this.projetAnnuel.referents.length - this.nbReferentEnAttente() > 0
    this.sortReferents()
  }

  getInitialColumns(): Array<Column> {
    return [
      {
        field: "nouveau",
        label: this.$t("nouveau").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "structure",
        label: this.$t("registerPage.structure").toString(),
        sortable: true,
        backEndSearchable: true,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "prenom",
        label: this.$t("graePage.membres.prenom").toString(),
        sortable: true,
        backEndSearchable: true,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "nom",
        label: this.$t("graePage.membres.nom").toString(),
        sortable: true,
        backEndSearchable: true,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "courriel",
        label: this.$t("graePage.membres.courriel").toString(),
        sortable: true,
        backEndSearchable: true,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "participation",
        label: "",
        sortable: true,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "action",
        label: this.$t("projetPage.actions").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
    ]
  }

  getDefaultSort(): Array<PaginationOrder> {
    const nameSort = new PaginationOrder()
    nameSort.clause = "nom"
    nameSort.desc = true
    return [nameSort]
  }

  showUserDetailsPage(event: Event, id: number): void {
    // Do not foward click event to row (would trigger modal)
    event.stopPropagation()
    //For APP to open ProfileModale
    this.$root.$emit("open-profile-modale", true, id)
  }

  dirtifyProject(withRedirect: boolean): void {
    this.$emit("dirtify-project", withRedirect)
  }

  invite(referent: UtilisateurDTO): void {
    this.projetAnnuel.referents = [...this.projetAnnuel.referents, referent]
    this.invitations.push(referent.courriel)
    this.projetAnnuel.charteFileValid = false
    this.dirtifyProject(false)
  }

  quit(referent: UtilisateurDTO): void {
    if (this.projectHasMoreThanOneReferent()) {
      Dialog.confirm({
        message: this.$t("projet.infos_administratives.areYouSureToRemoveUser").toString(),
        confirmText: this.$t("confirmer").toString(),
        cancelText: this.$t("annuler").toString(),
        onConfirm: () => this.removeUser(referent),
      })
    } else {
      this.infoReportingService.dialog(
        this.$t("projet.infos_administratives.at-least-one-title", ["référent"]).toString(),
        this.$t("projet.infos_administratives.at-least-one-message", ["ce référent"]).toString(),
        "OK",
        () => {
          // Nothing to do
        },
        "is-warning"
      )
    }
  }

  annuler(referent: UtilisateurDTO): void {
    if (this.nbReferentEnAttente() > 0) {
      Dialog.confirm({
        message: this.$t(
          "projet.infos_administratives.areYouSureToRemoveInvitationUser2"
        ).toString(),
        confirmText: this.$t("confirmer").toString(),
        cancelText: this.$t("annuler").toString(),
        onConfirm: () => this.removeInvitation(referent),
      })
    } else {
      this.infoReportingService.dialog(
        this.$t("projet.infos_administratives.at-least-one-title", ["référent"]).toString(),
        this.$t("projet.infos_administratives.at-least-one-message", ["ce référent"]).toString(),
        "OK",
        () => {
          // Nothing to do
        },
        "is-warning"
      )
    }
  }

  private removeInvitation(referent: UtilisateurDTO) {
    this.projetAnnuel.consentementEnAttente = this.projetAnnuel.consentementEnAttente.filter(
      (element) => element !== referent.id
    )
    this.removeUser(referent)
  }
  private removeUser(referent: UtilisateurDTO) {
    this.projetAnnuel.referents = this.projetAnnuel.referents.filter(
      (element) => element.id !== referent.id
    )
    this.dirtifyProject(this.loggedUser.id === referent.id)
  }

  showInviteButton(toDelete: UtilisateurDTO): boolean {
    return (
      (this.usersService.isPorteurProjet(this.loggedUser) ||
        this.usersService.isAccompagnateurRegional(this.loggedUser) ||
        this.usersService.isAccompagnateurNationalOrAdmin(this.loggedUser)) &&
      !this.isLinked(toDelete)
    )
  }

  showQuitButton(toDelete: UtilisateurDTO): boolean {
    return this.canDeleteReferents(toDelete) && !this.invitations.includes(toDelete.courriel)
  }

  private canDeleteReferents(toDelete: UtilisateurDTO): boolean {
    return (
      (this.usersService.isPorteurProjet(this.loggedUser) ||
        this.usersService.isAccompagnateurRegional(this.loggedUser) ||
        this.usersService.isAccompagnateurNationalOrAdmin(this.loggedUser)) &&
      this.isLinked(toDelete) &&
      !this.hasConsentementEnAttente(toDelete)
    )
  }

  private isLinked(toDelete: UtilisateurDTO): boolean {
    return this.projetAnnuel.referents.some((utilisateur) => utilisateur.id === toDelete.id)
  }

  private hasConsentementEnAttente(toDelete: UtilisateurDTO): boolean {
    return this.projetAnnuel.consentementEnAttente.some((id) => id === toDelete.id)
  }

  private projectHasMoreThanOneReferent(): boolean {
    return this.projetAnnuel.referents.length - this.nbReferentEnAttente() > 1
  }

  private nbReferentEnAttente(): number {
    return (
      this.projetAnnuel.consentementEnAttente.filter((id) =>
        this.projetAnnuel.referents.some((r) => r.id === id)
      ).length + this.invitations.length
    )
  }

  participating(user: UtilisateurDTO): boolean {
    if (user.id) {
      return (
        this.projetAnnuel.referents.some((referent) => referent.id === user.id) &&
        this.projetAnnuel.consentementEnAttente.indexOf(user.id) === -1 &&
        !this.invitations.includes(user.courriel)
      )
    } else {
      return false
    }
  }

  showRemove(user: UtilisateurDTO): boolean {
    let role = this.loggedUser.profil
    console.log(role)
    const roles = ["SUPER_ADMINISTRATEUR", "ACCOMPAGNATEUR_NATIONAL", "ANIMATEUR_REGIONAL"]
    if (user.id && roles.includes(role)) {
      return this.projetAnnuel.consentementEnAttente.indexOf(user.id) !== -1
    } else {
      return false
    }
  }

  invited(user: UtilisateurDTO): boolean {
    let role = this.loggedUser.profil
    console.log(role)
    if (user.id) {
      return this.projetAnnuel.consentementEnAttente.indexOf(user.id) !== -1
    } else {
      return false
    }
  }

  isNewReferent(referentId: string): boolean {
    return this.projetAnnuel.nouveauxActeurs.some((acteur) => referentId === acteur.id)
  }

  treatCourriel(user: UtilisateurDTO, forceObfuscation: boolean): string {
    if (this.canSeeCourriel(user) && !forceObfuscation) {
      return user.courriel
    }
    return ObfuscationUtils.obfuscateCourriel(user.courriel)
  }

  canSeeCourriel(user: UtilisateurDTO): boolean {
    return (
      user.acceptNameVisibilityOnProtectedListing ||
      user.id === this.loggedUser.id ||
      ObfuscationUtils.hasRightToSeeCourriel(user, this.loggedUser)
    )
  }

  sortReferents(): void {
    // Sort
    let participatingReferents = this.referents.filter((ref) => this.participating(ref))
    let structureIds: string[] = []
    this.structures = []
    participatingReferents.forEach((ref) => {
      if (ref.structure && ref.structure.id && structureIds.indexOf(ref.structure.id) < 0) {
        structureIds.push(ref.structure.id)
        this.structures.push(ref.structure)
      }
    })
    const participatingReferentsIds = participatingReferents.map((ref) => ref.id!)
    this.referents = this.referents.sort((ref1, ref2) => {
      // Me first
      if (ref1.id == this.loggedUser.id) {
        return -1
      }
      if (ref2.id == this.loggedUser.id) {
        return 1
      }

      // Then participating
      const ref1Participating = participatingReferentsIds.indexOf(ref1.id!) > -1
      const ref2Participating = participatingReferentsIds.indexOf(ref2.id!) > -1
      if (ref1Participating != ref2Participating) {
        return ref1Participating ? -1 : 1
      }

      // Sort per structure
      if (ref1.structure && ref2.structure && ref1.structure.nom != ref2.structure!.nom) {
        return ref1.structure!.nom.localeCompare(ref2.structure!.nom)
      }

      // Sort alphabetically then
      return ref1.courriel.localeCompare(ref2.courriel)
    })
  }

  showStructureDetailsPage(s: StructureDTO): void {
    this.$router.push("/structure/" + s.id + "/details")
  }
}
