

































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { AireEducativeCartoDetailsDTO } from "@/model/bean/GeneratedDTOs"

@Component
export default class ProjectDetails extends Vue {
  @Prop({ required: true }) project!: AireEducativeCartoDetailsDTO
  @Prop({ required: true }) role!: string
  @Prop({ required: true }) icon!: string
  @Prop({ required: true }) userRegionId!: string
  @Prop({ required: true }) instructedGraes!: string[]

  showFullPitch = false
  showFullClasses = false
  showFullEnseignants = false
  showFullReferents = false
  trimmedPitch = ""
  trimmedClasses: string[] = []
  trimmedEnseignants: any[] = []
  trimmedReferents: any[] = []

  get visibleEnseignants() {
    return this.project.enseignants.filter(
      (enseignant) =>
        // @ts-ignore
        this.project.consentementValide.includes(enseignant.id) &&
        enseignant.acceptNameVisibilityOnProtectedListing
    )
  }

  get visibleReferents() {
    return this.project.referents.filter(
      (referent) =>
        // @ts-ignore
        this.project.consentementValide.includes(referent.id) &&
        referent.acceptNameVisibilityOnProtectedListing
    )
  }

  get displayedClasses() {
    return this.showFullClasses ? this.project.classes : this.trimmedClasses
  }

  get displayedPitch() {
    return this.showFullPitch ? this.project.pitch : this.trimmedPitch
  }

  get displayedEnseignants() {
    return this.showFullEnseignants ? this.visibleEnseignants : this.trimmedEnseignants
  }

  get displayedReferents() {
    return this.showFullReferents ? this.visibleReferents : this.trimmedReferents
  }

  togglePitch() {
    this.showFullPitch = !this.showFullPitch
  }

  toggleClasses() {
    this.showFullClasses = !this.showFullClasses
  }

  toggleEnseignants() {
    this.showFullEnseignants = !this.showFullEnseignants
  }

  toggleReferents() {
    this.showFullReferents = !this.showFullReferents
  }

  showViewProject(id: string): boolean {
    if (this.userRegionId === id || this.instructedGraes.includes(id)) {
      return true
    }
    return false
  }

  @Watch("project")
  onProjectChange(newProject: AireEducativeCartoDetailsDTO) {
    console.log(newProject)
    this.setTrimmedPitch()
    this.setTrimmedClasses()
    this.setTrimmedEnseignants()
    this.setTrimmedReferents()
  }

  @Watch("showFullPitch")
  onShowFullPitchChange() {
    this.setTrimmedPitch()
  }

  @Watch("showFullClasses")
  onShowFullClassesChange() {
    this.setTrimmedClasses()
  }

  @Watch("showFullEnseignants")
  onShowFullEnseignantsChange() {
    this.setTrimmedEnseignants()
  }

  @Watch("showFullReferents")
  onShowFullReferentsChange() {
    this.setTrimmedReferents()
  }

  setTrimmedPitch() {
    if (this.project.pitch && this.project.pitch.length > 300 && !this.showFullPitch) {
      this.trimmedPitch = this.project.pitch.slice(0, 300) + "..."
    } else {
      this.trimmedPitch = this.project.pitch
    }
  }

  setTrimmedClasses() {
    if (this.project.classes && this.project.classes.length > 2 && !this.showFullClasses) {
      this.trimmedClasses = this.project.classes.slice(0, 2)
    } else {
      this.trimmedClasses = this.project.classes
    }
  }

  setTrimmedEnseignants() {
    if (
      this.visibleEnseignants &&
      this.visibleEnseignants.length > 2 &&
      !this.showFullEnseignants
    ) {
      this.trimmedEnseignants = this.visibleEnseignants.slice(0, 2)
    } else {
      this.trimmedEnseignants = this.visibleEnseignants
    }
  }

  setTrimmedReferents() {
    if (this.visibleReferents && this.visibleReferents.length > 2 && !this.showFullReferents) {
      this.trimmedReferents = this.visibleReferents.slice(0, 2)
    } else {
      this.trimmedReferents = this.visibleReferents
    }
  }

  mounted() {
    this.setTrimmedPitch()
    this.setTrimmedClasses()
    this.setTrimmedEnseignants()
    this.setTrimmedReferents()
  }

  viewProject() {
    this.$router.push(
      "/project/" + this.project?.idProjet + "/" + this.project?.projetAnnuelId + "/historique/0"
    )
  }
}
