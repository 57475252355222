var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "subheader" },
        [
          _c(
            "SubHeaderItem",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.projetAnnuel.anneeScolaireDebut,
                  expression: "projetAnnuel.anneeScolaireDebut"
                }
              ],
              staticClass: "choose-period"
            },
            [
              _c(
                "b-dropdown",
                {
                  attrs: { "aria-role": "list" },
                  on: {
                    change: function(index) {
                      return _vm.selectPeriod(index)
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "trigger",
                      fn: function() {
                        return [
                          _c(
                            "a",
                            {
                              staticClass: "navbar-item",
                              attrs: { role: "button" }
                            },
                            [
                              _vm.projetAnnuel.typeDossier ===
                              "DOSSIER_INSCRIPTION"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "type-dossier-shorter.DOSSIER_INSCRIPTION"
                                        )
                                      )
                                    )
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getPeriodLabel(
                                          _vm.projetAnnuel.anneeScolaireDebut,
                                          _vm.projetAnnuel.anneeScolaireFin
                                        )
                                      )
                                    )
                                  ]),
                              _c("b-icon", { attrs: { icon: "angle-down" } })
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                _vm._l(_vm.periods, function(period, index) {
                  return _c(
                    "div",
                    { key: period.projetAnnuelId },
                    [
                      _c(
                        "b-dropdown-item",
                        { attrs: { "aria-role": "listitem", value: index } },
                        [
                          period.typeDossier === "DOSSIER_INSCRIPTION"
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "type-dossier-shorter.DOSSIER_INSCRIPTION"
                                    )
                                  )
                                )
                              ])
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getPeriodLabel(
                                      period.anneeScolaireDebut,
                                      period.anneeScolaireFin
                                    )
                                  )
                                )
                              ])
                        ]
                      )
                    ],
                    1
                  )
                }),
                0
              )
            ],
            1
          ),
          _c(
            "SubHeaderItem",
            {
              staticClass: "inscription-title",
              class: { "only-in-inscription": _vm.periods.length == 1 }
            },
            [
              _vm.projetAnnuel.typeDossier == "DOSSIER_INSCRIPTION"
                ? _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("projetDetails.inscription-title")) +
                        " "
                    )
                  ])
                : _vm.projetAnnuel.typeDossier == "DOSSIER_LABELLISATION"
                ? _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("projetDetails.labellisation-title")) +
                        " "
                    )
                  ])
                : _vm.isPorteurProjet &&
                  _vm.projetAnnuel.typeDossier == "DOSSIER_RENOUVELLEMENT_LIGHT"
                ? _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "projetDetails.renouvellement-leger-title-porteur"
                          )
                        ) +
                        " "
                    )
                  ])
                : _vm.isPorteurProjet
                ? _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "projetDetails.renouvellement-lourd-title-porteur"
                          )
                        ) +
                        " "
                    )
                  ])
                : _vm.projetAnnuel.typeDossier == "DOSSIER_RENOUVELLEMENT_LIGHT"
                ? _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("projetDetails.renouvellement-leger-title")
                        ) +
                        " "
                    )
                  ])
                : _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("projetDetails.renouvellement-lourd-title")
                        ) +
                        " "
                    )
                  ])
            ]
          ),
          _c(
            "div",
            { staticClass: "end-sub-headers-container" },
            [
              _vm.isPorteurProjet
                ? _c(
                    "SubHeaderItem",
                    {
                      staticClass: "date-depot mr-4",
                      attrs: { icon: "calendar" }
                    },
                    [
                      _vm.projetAnnuel.typeDossier == "DOSSIER_INSCRIPTION"
                        ? _c("div", [
                            _c("div", { staticClass: "item-text" }, [
                              _vm._v(
                                _vm._s(_vm.$t("projetDetails.dateInscription"))
                              )
                            ]),
                            _c("div", { staticClass: "item-text bold" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("projetDetails.dateDepotRange", [
                                      _vm.formatDate(
                                        _vm.parametrage.debutInscription
                                      ),
                                      _vm.formatDate(
                                        _vm.parametrage.finInscription
                                      )
                                    ])
                                  ) +
                                  " "
                              )
                            ])
                          ])
                        : _c("div", [
                            _c("div", { staticClass: "item-text" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("projetDetails.dateLabellisation")
                                )
                              )
                            ]),
                            _c("div", { staticClass: "item-text bold" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("projetDetails.dateDepotRange", [
                                      _vm.formatDate(
                                        _vm.parametrage.debutLabellisation
                                      ),
                                      _vm.formatDate(
                                        _vm.parametrage.finLabellisation
                                      )
                                    ])
                                  ) +
                                  " "
                              )
                            ])
                          ])
                    ]
                  )
                : _vm._e(),
              _c(
                "SubHeaderItem",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.projetAnnuel.etat &&
                        _vm.projetAnnuel.typeDossier != "DOSSIER_INSCRIPTION",
                      expression:
                        "projetAnnuel.etat && projetAnnuel.typeDossier != 'DOSSIER_INSCRIPTION'"
                    }
                  ],
                  staticClass: "change-etat",
                  attrs: { id: "project-state" }
                },
                [
                  _vm.canChangeEtat
                    ? _c(
                        "b-dropdown",
                        {
                          attrs: {
                            "aria-role": "list",
                            disabled: _vm.isObservateurNational,
                            position: "is-bottom-left"
                          },
                          on: {
                            change: function(etat) {
                              return _vm.changeEtat(etat)
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "trigger",
                                fn: function() {
                                  return [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "navbar-item",
                                        attrs: { role: "button" }
                                      },
                                      [
                                        _vm.projetAnnuel.etat === "ACTIF"
                                          ? _c("b-icon", {
                                              attrs: {
                                                icon: "play-circle",
                                                type: "is-success"
                                              }
                                            })
                                          : _vm.projetAnnuel.etat === "EN_PAUSE"
                                          ? _c("b-icon", {
                                              attrs: {
                                                icon: "pause-circle",
                                                type: "is-warning"
                                              }
                                            })
                                          : _c("b-icon", {
                                              attrs: {
                                                icon: "stop-circle",
                                                type: "is-danger"
                                              }
                                            }),
                                        _c(
                                          "span",
                                          { staticClass: "item-text bold" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(_vm.projetAnnuel.etat)
                                              )
                                            )
                                          ]
                                        ),
                                        _c("b-icon", {
                                          attrs: { icon: "angle-down" }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            392337419
                          ),
                          model: {
                            value: _vm.selectedEtat,
                            callback: function($$v) {
                              _vm.selectedEtat = $$v
                            },
                            expression: "selectedEtat"
                          }
                        },
                        _vm._l(_vm.etats, function(etat) {
                          return _c(
                            "div",
                            { key: etat },
                            [
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: {
                                    "aria-role": "listitem",
                                    value: etat
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.$t(etat)) + " ")]
                              )
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _c("div", [
                        _c(
                          "div",
                          { staticClass: "navbar-item" },
                          [
                            _vm.projetAnnuel.etat === "ACTIF"
                              ? _c("b-icon", {
                                  attrs: {
                                    icon: "play-circle",
                                    type: "is-success"
                                  }
                                })
                              : _vm.projetAnnuel.etat === "EN_PAUSE"
                              ? _c("b-icon", {
                                  attrs: {
                                    icon: "pause-circle",
                                    type: "is-warning"
                                  }
                                })
                              : _c("b-icon", {
                                  attrs: {
                                    icon: "stop-circle",
                                    type: "is-danger"
                                  }
                                }),
                            _c("span", { staticClass: "item-text bold" }, [
                              _vm._v(_vm._s(_vm.$t(_vm.projetAnnuel.etat)))
                            ])
                          ],
                          1
                        )
                      ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ConfirmChangementEtat", {
        attrs: {
          isModalActive: _vm.showConfirmModal,
          oldEtatProjet: _vm.projetAnnuel.etat,
          newEtatProjet: _vm.selectedEtat
        },
        on: { close: _vm.closeConfirmModal, confirm: _vm.confirmChangementEtat }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }