





























































import { ValidationProvider } from "vee-validate"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import FormInputLabel from "./FormInputLabel.vue"

@Component({
  components: {
    ValidationProvider,
    FormInputLabel,
  },
})
export default class DatePickerWithValidation extends Vue {
  @Prop({ default: "" }) group: string
  @Prop() vid: string
  @Prop() rules: [string]
  @Prop() value: Date
  @Prop() tooltip: string
  @Prop({ default: "" }) placeholder: string
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: false }) hideBr: boolean
  @Prop() minDate: Date | undefined
  @Prop() maxDate: Date | undefined
  @Prop({ default: () => [] }) unselectableDates: Date[]
  @Prop({ default: false }) validationActivated: boolean
  @Prop({ default: false }) showValidationErrors: boolean
  innerValue: Date | null = null
  isMandatory = false

  created(): void {
    if (this.value) {
      this.innerValue = this.value
    }
    this.isMandatory = this.rules && this.rules.includes("required")
  }

  @Watch("innerValue")
  onInnerValueChanged(newVal: Date, _oldValue: Date): void {
    this.$emit("input", newVal)
  }

  @Watch("value")
  onExternalValueChanged(newVal: Date, _oldValue: Date): void {
    this.innerValue = newVal
    if (newVal != _oldValue) {
      this.$emit("manual-change", newVal)
    }
  }
}
