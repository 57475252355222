var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationProvider", {
    attrs: {
      vid: (_vm.group != "" ? _vm.group + "_" : "") + _vm.vid,
      name: _vm.$attrs.name || _vm.$attrs.label,
      rules: _vm.rulesWithoutRequiredRulesIfValidationDisabled
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var errors = ref.errors
          var valid = ref.valid
          return [
            _c(
              "div",
              [
                _c(
                  "b-field",
                  {
                    staticClass: "InputWithValidation-field",
                    attrs: {
                      type: {
                        "is-danger":
                          _vm.showValidationErrors &&
                          (errors[0] || _vm.customError.length > 0),
                        "is-success": _vm.showValidationErrors && valid
                      },
                      message: _vm.showValidationErrors
                        ? [errors + _vm.customError]
                        : "",
                      position: "is-right"
                    }
                  },
                  [
                    _c("template", { slot: "label" }, [
                      _c("div", { staticClass: "fieldLabel" }, [
                        _c("div", {
                          staticClass: "innerLabel",
                          domProps: { innerHTML: _vm._s(_vm.$attrs.label) }
                        }),
                        !_vm.disabled && _vm.validationActivated
                          ? _c("div", [
                              _vm.isMandatory
                                ? _c("div", { staticClass: "mandatory" }, [
                                    _vm._v(_vm._s(_vm.$t("obligatoire")))
                                  ])
                                : _c("div", { staticClass: "mandatory" }, [
                                    _vm._v(_vm._s(_vm.$t("facultatif")))
                                  ])
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "uploader",
                        class: {
                          "has-errors":
                            _vm.showValidationErrors &&
                            (errors[0] || _vm.customError.length > 0)
                        }
                      },
                      [
                        _c("div", {
                          staticClass: "inputNotice",
                          domProps: { innerHTML: _vm._s(_vm.inputNotice) }
                        }),
                        !_vm.disabled && !_vm.fileIsPresent && !_vm.isUploading
                          ? _c(
                              "b-upload",
                              {
                                staticClass: "file-label",
                                attrs: {
                                  accept: "application/pdf",
                                  type: "is-dark"
                                },
                                on: { input: _vm.addFile },
                                model: {
                                  value: _vm.innerValue,
                                  callback: function($$v) {
                                    _vm.innerValue = $$v
                                  },
                                  expression: "innerValue"
                                }
                              },
                              [
                                _c("template", { slot: "placeholder" }, [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(_vm.placeholder)
                                    }
                                  })
                                ]),
                                _c("span", { staticClass: "file-cta" }, [
                                  _c("span", { staticClass: "file-label" }, [
                                    _vm._v(_vm._s(_vm.placeholder))
                                  ])
                                ])
                              ],
                              2
                            )
                          : _c("b-checkbox", {
                              staticStyle: { display: "none" },
                              model: {
                                value: _vm.fileIsPresent,
                                callback: function($$v) {
                                  _vm.fileIsPresent = $$v
                                },
                                expression: "fileIsPresent"
                              }
                            }),
                        _vm.isUploading
                          ? _c(
                              "div",
                              { staticClass: "loading" },
                              [
                                _c("b-loading", {
                                  attrs: { "is-full-page": false },
                                  model: {
                                    value: _vm.isUploading,
                                    callback: function($$v) {
                                      _vm.isUploading = $$v
                                    },
                                    expression: "isUploading"
                                  }
                                }),
                                _vm._v(" " + _vm._s(_vm.$t("uploading")) + " ")
                              ],
                              1
                            )
                          : _vm.uploadError
                          ? _c("div", { staticClass: "upload-error" }, [
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t("upload-failed", [
                                      _vm.maxUploadSizeString
                                    ])
                                  )
                                }
                              })
                            ])
                          : _c(
                              "div",
                              { staticClass: "seeFile" },
                              [
                                (_vm.innerValue || _vm.fileIsPresent) &&
                                _vm.fileOperationUrl
                                  ? _c("a", { on: { click: _vm.openFile } }, [
                                      _vm._v("Voir le fichier")
                                    ])
                                  : _vm._e(),
                                _vm.innerValue && !_vm.fileOperationUrl
                                  ? _c("span", { staticClass: "file-name" }, [
                                      _vm._v(" " + _vm._s(_vm.fileName) + " ")
                                    ])
                                  : _vm._e(),
                                !_vm.disabled &&
                                (_vm.innerValue || _vm.fileIsPresent)
                                  ? _c(
                                      "b-button",
                                      {
                                        attrs: { type: "is-danger" },
                                        on: { click: _vm.deleteFile }
                                      },
                                      [_c("span", [_vm._v("Supprimer")])]
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                        _vm.tooltip && _vm.tooltip.length > 0 && !_vm.disabled
                          ? _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  type: "is-dark",
                                  label: _vm.tooltip,
                                  size: "is-large",
                                  multilined: "",
                                  position: "is-left"
                                }
                              },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    size: "is-small",
                                    icon: "question-circle"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  2
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }