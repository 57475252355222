var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "home-main",
      class: { "with-header-big": !_vm.isUserLoggedIn }
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("section", { staticClass: "home" }, [
          _c("div", { staticClass: "columns is-desktop is-vcentered" }, [
            _c("div", { staticClass: "column is-5-desktop catchphrase" }, [
              _c("h1", { staticClass: "title is-size-1 is-kabel" }, [
                _vm._v(" " + _vm._s(_vm.$t("homepage.title")) + " ")
              ])
            ]),
            _c(
              "div",
              { staticClass: "column is-offset-1-desktop is-6-desktop" },
              [
                _c(
                  "b-carousel",
                  { attrs: { autoplay: false } },
                  [
                    _c("b-carousel-item", [
                      _c(
                        "section",
                        { staticClass: "hero is-medium is-primary" },
                        [
                          _c(
                            "div",
                            { staticClass: "hero-body has-text-centered" },
                            [
                              _c("h2", { staticClass: "title" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "homepage.aire-educative-description"
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          )
                        ]
                      )
                    ]),
                    _c("b-carousel-item", [
                      _c(
                        "section",
                        { staticClass: "hero is-medium is-success" },
                        [
                          _c(
                            "div",
                            { staticClass: "hero-body has-text-centered" },
                            [
                              _c("h2", { staticClass: "title" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("homepage.venez")) + " "
                                )
                              ])
                            ]
                          )
                        ]
                      )
                    ]),
                    _c("b-carousel-item", [
                      _c(
                        "section",
                        { staticClass: "hero is-medium is-warning" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "hero-body has-text-centered has-direction-column"
                            },
                            [
                              _c("h2", { staticClass: "title" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("homepage.decouvrez")) +
                                    " "
                                ),
                                _c("br")
                              ]),
                              _c(
                                "b-button",
                                {
                                  staticClass: "is-primary",
                                  attrs: {
                                    tag: "a",
                                    href: "#carte",
                                    size: "is-medium"
                                  }
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("homepage.decouvrir")) +
                                        " "
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ]),
                    _c("b-carousel-item", [
                      _c("section", { staticClass: "hero-logos" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/AME.png"),
                            alt: "Logo Aire Marine Éducative",
                            title: "Logo Aire Marine Éducative"
                          }
                        }),
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/ATE.png"),
                            alt: "Logo Aire Terrestre Éducative",
                            title: "Logo Aire Terrestre Éducative"
                          }
                        })
                      ])
                    ]),
                    _c("b-carousel-item", [
                      _c(
                        "section",
                        {
                          staticClass: "hero is-medium is-dark contains-image"
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/photos/46796.jpg"),
                              alt: "Photos d'élèves",
                              title: "Crédit photo"
                            }
                          })
                        ]
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "buttons-container" },
            [
              _c(
                "b-button",
                {
                  staticClass: "is-primary",
                  attrs: {
                    tag: "a",
                    href: "#publique",
                    outlined: "",
                    rounded: ""
                  }
                },
                [_c("strong", [_vm._v(_vm._s(_vm.$t("homepage.a-qui")))])]
              ),
              _c(
                "b-button",
                {
                  staticClass: "is-primary",
                  attrs: {
                    tag: "a",
                    href: "#chiffres",
                    outlined: "",
                    rounded: ""
                  }
                },
                [_c("strong", [_vm._v(_vm._s(_vm.$t("homepage.chiffres")))])]
              ),
              _c(
                "b-button",
                {
                  staticClass: "is-primary",
                  attrs: {
                    tag: "a",
                    href: "#entrer",
                    outlined: "",
                    rounded: ""
                  }
                },
                [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("homepage.entrer-demarche")))
                  ])
                ]
              ),
              _c(
                "b-button",
                {
                  staticClass: "is-primary",
                  attrs: {
                    tag: "a",
                    href: "#ensuite",
                    outlined: "",
                    rounded: ""
                  }
                },
                [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("homepage.apres-compte")))
                  ])
                ]
              ),
              _c(
                "b-button",
                {
                  staticClass: "is-primary",
                  attrs: {
                    tag: "a",
                    href: "#membre",
                    outlined: "",
                    rounded: ""
                  }
                },
                [_c("strong", [_vm._v(_vm._s(_vm.$t("homepage.membre-grae")))])]
              )
            ],
            1
          )
        ]),
        _c("section", { staticClass: "anchor", attrs: { id: "publique" } }, [
          _c("div", { staticClass: "columns point-important" }, [
            _c("h2", { staticClass: "column is-12 title is-size-1" }, [
              _vm._v(" " + _vm._s(_vm.$t("homepage.a-qui")) + " ")
            ])
          ]),
          _c("div", { staticClass: "columns" }, [
            _vm._m(0),
            _c("div", { staticClass: "column is-half bullet-home" }, [
              _c(
                "p",
                [
                  _c("b-icon", {
                    staticClass: "bullet-marker",
                    attrs: { icon: "school", size: "is-large" }
                  }),
                  _c("span", { staticClass: "is-size-5 bullet-content" }, [
                    _vm._v(" " + _vm._s(_vm.$t("homepage.toute-classe")) + " ")
                  ])
                ],
                1
              ),
              _c(
                "p",
                [
                  _c("b-icon", {
                    staticClass: "bullet-marker",
                    attrs: { icon: "chalkboard-teacher", size: "is-large" }
                  }),
                  _c("span", { staticClass: "is-size-5 bullet-content" }, [
                    _vm._v(" " + _vm._s(_vm.$t("homepage.volontaires")) + " ")
                  ])
                ],
                1
              ),
              _c(
                "p",
                [
                  _c("b-icon", {
                    staticClass: "bullet-marker",
                    attrs: { icon: "child", size: "is-large" }
                  }),
                  _c("span", { staticClass: "is-size-5 bullet-content" }, [
                    _vm._v(" " + _vm._s(_vm.$t("homepage.referent")) + " ")
                  ])
                ],
                1
              ),
              _c(
                "p",
                [
                  _c("b-icon", {
                    staticClass: "bullet-marker",
                    attrs: { icon: "question-circle", size: "is-large" }
                  }),
                  _c("span", { staticClass: "is-size-5 bullet-content" }, [
                    _vm._v(" " + _vm._s(_vm.$t("homepage.plus-infos")) + " "),
                    _c(
                      "div",
                      [
                        _c(
                          "b-button",
                          {
                            staticStyle: { "margin-top": "10px" },
                            attrs: {
                              type: "is-primary is-rounded",
                              tag: "a",
                              target: "_blank",
                              href: "https://ame.ofb.fr/doku.php"
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("homepage.wiki")) + " ")]
                        ),
                        _c(
                          "b-button",
                          {
                            staticStyle: {
                              "margin-left": "20px",
                              "margin-top": "10px"
                            },
                            attrs: {
                              type: "is-primary is-rounded",
                              tag: "a",
                              target: "_blank",
                              href: "https://ofb.gouv.fr/aires-educatives"
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("homepage.ofb")) + " ")]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "anchor-hidden", attrs: { id: "chiffres" } }),
        _c("section", { staticClass: "point-chiffres" }, [
          _c("div", { staticClass: "columns" }, [
            _c("h2", { staticClass: "column is-12 title is-size-1 is-kabel" }, [
              _vm._v(" " + _vm._s(_vm.$t("homepage.ae-chiffres")) + " ")
            ])
          ]),
          _c("div", { staticClass: "columns" }, [
            _c("div", { staticClass: "column is-4 center" }, [
              _c("div", { staticClass: "figure" }, [
                _vm._v(_vm._s(_vm.$t("homepage.labellisees-number")))
              ]),
              _vm._v(" " + _vm._s(_vm.$t("homepage.labellisees")) + " ")
            ]),
            _c("div", { staticClass: "column is-4 center" }, [
              _c("div", { staticClass: "figure" }, [
                _vm._v(_vm._s(_vm.$t("homepage.eleves-number")))
              ]),
              _vm._v(" " + _vm._s(_vm.$t("homepage.eleves")) + " ")
            ]),
            _c("div", { staticClass: "column is-4 center" }, [
              _c("div", { staticClass: "figure" }, [
                _vm._v(_vm._s(_vm.$t("homepage.nouveaux-projets-number")))
              ]),
              _vm._v(" " + _vm._s(_vm.$t("homepage.nouveaux-projets")) + " ")
            ])
          ])
        ]),
        _c("section", { staticClass: "anchor", attrs: { id: "entrer" } }, [
          _c("div", { staticClass: "columns point-important" }, [
            _c("h2", { staticClass: "column is-12 title is-size-1" }, [
              _vm._v(" " + _vm._s(_vm.$t("homepage.entrer-demarche")) + " ")
            ])
          ]),
          _c("div", { staticClass: "columns" }, [
            _c("div", { staticClass: "column is-half bullet-home" }, [
              _c(
                "p",
                [
                  _c("b-icon", {
                    staticClass: "bullet-marker",
                    attrs: { icon: "desktop", size: "is-large" }
                  }),
                  _c(
                    "span",
                    { staticClass: "is-size-5 bullet-content" },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("homepage.cree-compte")) + " "
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "button is-primary",
                          staticStyle: { "vertical-align": "baseline" },
                          attrs: { to: "/register/user/0" }
                        },
                        [
                          _c("strong", [
                            _vm._v(" " + _vm._s(_vm.$t("register")))
                          ])
                        ]
                      ),
                      _c("br"),
                      _c("em", [
                        _vm._v(_vm._s(_vm.$t("homepage.choix-profil")))
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "p",
                [
                  _c("b-icon", {
                    staticClass: "bullet-marker",
                    attrs: { icon: "edit", size: "is-large" }
                  }),
                  _c("span", { staticClass: "is-size-5 bullet-content" }, [
                    _vm._v(" " + _vm._s(_vm.$t("homepage.inscription")) + " "),
                    _c("br"),
                    _c("em", [
                      _vm._v(" " + _vm._s(_vm.$t("homepage.campagne-ouverte")))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "p",
                [
                  _c("b-icon", {
                    staticClass: "bullet-marker",
                    attrs: { icon: "check-square", size: "is-large" }
                  }),
                  _c("span", { staticClass: "is-size-5 bullet-content" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("homepage.dossier-valide")) + " "
                    )
                  ])
                ],
                1
              )
            ]),
            _vm._m(1)
          ])
        ]),
        _c("section", { staticClass: "anchor", attrs: { id: "ensuite" } }, [
          _c("div", { staticClass: "columns point-important" }, [
            _c("h2", { staticClass: "column is-12 title is-size-1" }, [
              _vm._v(_vm._s(_vm.$t("homepage.apres-compte")))
            ])
          ]),
          _c("div", { staticClass: "columns" }, [
            _vm._m(2),
            _c("div", { staticClass: "column is-half bullet-home" }, [
              _c(
                "p",
                [
                  _c("b-icon", {
                    staticClass: "bullet-marker",
                    attrs: { icon: "people-arrows", size: "is-large" }
                  }),
                  _c("span", { staticClass: "is-size-5 bullet-content" }, [
                    _vm._v(" " + _vm._s(_vm.$t("homepage.accompagne"))),
                    _c("br"),
                    _c("em", [
                      _vm._v(" " + _vm._s(_vm.$t("homepage.accompagne-reseau")))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "p",
                [
                  _c("b-icon", {
                    staticClass: "bullet-marker",
                    attrs: { icon: "edit", size: "is-large" }
                  }),
                  _c("span", { staticClass: "is-size-5 bullet-content" }, [
                    _vm._v(" " + _vm._s(_vm.$t("homepage.labellisation"))),
                    _c("br"),
                    _c("em", [
                      _vm._v(
                        " " + _vm._s(_vm.$t("homepage.campagne-ouverte")) + " "
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "p",
                [
                  _c("b-icon", {
                    staticClass: "bullet-marker",
                    attrs: { icon: "graduation-cap", size: "is-large" }
                  }),
                  _c("span", { staticClass: "is-size-5 bullet-content" }, [
                    _vm._v(" " + _vm._s(_vm.$t("homepage.projet-labellise"))),
                    _c("br"),
                    _c("em", [
                      _vm._v(" " + _vm._s(_vm.$t("homepage.poursuivre")))
                    ])
                  ])
                ],
                1
              )
            ])
          ])
        ]),
        _c("section", { staticClass: "anchor", attrs: { id: "membre" } }, [
          _c("div", { staticClass: "columns point-important" }, [
            _c("h2", { staticClass: "column is-12 title is-size-1" }, [
              _vm._v(" " + _vm._s(_vm.$t("homepage.membre-grae")) + " "),
              _c("br"),
              _c("em", [_vm._v(_vm._s(_vm.$t("homepage.grae")))])
            ])
          ]),
          _c("div", { staticClass: "columns" }, [
            _c("div", { staticClass: "column is-half bullet-home" }, [
              _c(
                "p",
                [
                  _c("b-icon", {
                    staticClass: "bullet-marker",
                    attrs: { icon: "desktop", size: "is-large" }
                  }),
                  _c("span", { staticClass: "is-size-5 bullet-content" }, [
                    _vm._v(" " + _vm._s(_vm.$t("homepage.creation-compte"))),
                    _c("br"),
                    _c("em", [
                      _vm._v(" " + _vm._s(_vm.$t("homepage.utilisateur")))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "p",
                [
                  _c("b-icon", {
                    staticClass: "bullet-marker",
                    attrs: { icon: "map-marked-alt", size: "is-large" }
                  }),
                  _c("span", { staticClass: "is-size-5 bullet-content" }, [
                    _vm._v(" " + _vm._s(_vm.$t("homepage.rejoins-grae"))),
                    _c("br"),
                    _c("em", [
                      _vm._v(_vm._s(_vm.$t("homepage.compte-sera-valide")))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "p",
                [
                  _c("b-icon", {
                    staticClass: "bullet-marker",
                    attrs: { icon: "people-arrows", size: "is-large" }
                  }),
                  _c("span", { staticClass: "is-size-5 bullet-content" }, [
                    _vm._v(" " + _vm._s(_vm.$t("homepage.voir-grae")))
                  ])
                ],
                1
              ),
              _c(
                "p",
                [
                  _c("b-icon", {
                    staticClass: "bullet-marker",
                    attrs: { icon: "folder-open", size: "is-large" }
                  }),
                  _c("span", { staticClass: "is-size-5 bullet-content" }, [
                    _vm._v(" " + _vm._s(_vm.$t("homepage.instruire"))),
                    _c("br"),
                    _c("em", [_vm._v(_vm._s(_vm.$t("homepage.types-dossier")))])
                  ])
                ],
                1
              )
            ]),
            _vm._m(3)
          ])
        ]),
        _c("section", { staticClass: "anchor", attrs: { id: "carte" } }, [
          _c("div", { staticClass: "columns point-important" }, [
            _c(
              "div",
              { staticClass: "column" },
              [
                _c("h3", { staticClass: "column is-12 title" }, [
                  _vm._v(_vm._s(_vm.$t("homepage.cartographie")))
                ]),
                _c("MapClusterComponent")
              ],
              1
            )
          ])
        ]),
        _c("section", [
          _c("div", { staticClass: "columns point-important" }, [
            _c(
              "div",
              { staticClass: "column" },
              [
                _c("h3", { staticClass: "column is-12 title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("homepage.outils")) + " ")
                ]),
                _c(
                  "b-button",
                  {
                    staticStyle: { "margin-top": "10px" },
                    attrs: {
                      type: "is-primary is-rounded",
                      tag: "a",
                      target: "_blank",
                      href: "https://ame.ofb.fr/doku.php"
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("homepage.wiki")) + " ")]
                ),
                _c(
                  "b-button",
                  {
                    staticStyle: {
                      "margin-top": "10px",
                      "margin-left": "30px"
                    },
                    attrs: {
                      type: "is-primary is-rounded",
                      tag: "a",
                      target: "_blank",
                      href: "https://ofb.gouv.fr/aires-educatives"
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("homepage.ofb")) + " ")]
                )
              ],
              1
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "back-to-top-container" }, [
        _c(
          "a",
          {
            staticClass: "back-to-top",
            attrs: { href: "#", title: "Revenir en haut de la page" }
          },
          [
            _c("b-icon", {
              attrs: { icon: "arrow-circle-up", size: "is-large" }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column is-half center" }, [
      _c("img", {
        staticClass: "photo",
        attrs: {
          src: require("@/assets/images/photos/39267.jpg"),
          alt: "Photos d'élèves",
          title: "Crédit photo"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column is-half center" }, [
      _c("img", {
        staticClass: "photo",
        attrs: {
          src: require("@/assets/images/photos/41964.jpg"),
          alt: "Photos d'élèves",
          title: "Crédit photo"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column is-half center" }, [
      _c("img", {
        staticClass: "photo",
        attrs: {
          src: require("@/assets/images/photos/41967.jpg"),
          alt: "Photos d'élèves",
          title: "Crédit photo"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column is-half center" }, [
      _c("img", {
        staticClass: "photo",
        attrs: {
          src: require("@/assets/images/photos/46284.jpg"),
          alt: "Photos d'élèves",
          title: "Crédit photo"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }