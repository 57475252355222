















import { ProjetAnnuelForInstructionDTO, EvaluationDTO } from "@/model/bean/GeneratedDTOs"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import Evaluation from "@/pages/instruction/side-pannel/commons/labellisation/Evaluation.vue"
import { LoginService } from "@/services/rest/login/LoginService"

@Component({
  components: {
    Evaluation,
  },
})
export default class InstructionPannelLabellisationMonEvaluation extends Vue {
  @Prop() project: ProjetAnnuelForInstructionDTO
  @Prop() instructionPannelWidth: number

  private loginService = LoginService.INSTANCE

  evaluation = new EvaluationDTO()

  @Watch("project")
  mounted(): void {
    const user = this.loginService.getLoggedUser()

    this.project.evaluations.forEach((item) => {
      if (user !== undefined && item.instructeur.id === user.id) {
        this.evaluation = item
      }
    })
  }

  saveEvaluation(evaluation: EvaluationDTO): void {
    this.$emit("save-evaluation", evaluation)
  }
}
