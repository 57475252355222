var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      staticClass: "PitchModale",
      attrs: {
        active: _vm.open,
        "has-modal-card": "",
        "trap-focus": "",
        "aria-role": "dialog",
        "aria-label": "PitchModale",
        "aria-modal": "",
        width: "100vw"
      },
      on: { cancel: _vm.emitCloseModale }
    },
    [
      [
        _c("div", { staticClass: "modal-card" }, [
          _c(
            "header",
            { staticClass: "modal-card-head modal-card-head-notes" },
            [_vm._v(" " + _vm._s(_vm.$t("pitchModale.title")) + " ")]
          ),
          _c("section", { staticClass: "modal-card-body" }, [
            _vm._v(" " + _vm._s(_vm.pitch) + " ")
          ]),
          _c("footer", { staticClass: "modal-card-foot" }, [
            _c("div", { staticClass: "buttons" }, [
              _c(
                "div",
                {
                  staticClass: "hoverable-discrete-link mr-3",
                  on: { click: _vm.emitCloseModale }
                },
                [_vm._v(" " + _vm._s(_vm.$t("annuler")) + " ")]
              )
            ])
          ])
        ])
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }