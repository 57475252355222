/*-
 * #%L
 * Aires Éducatives
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */

import { FilterDTO, PaginationOrder } from "@/model/bean/GeneratedDTOs"
import Column from "@/views/tables/Column"

/**
 * Stores tab filters, visible columns and sort to restore them when displaying the same tab again.
 */
export default class TabParamsStore {
  static INSTANCE = new TabParamsStore()

  private static COLUMNS_SUFFIX = "_columns"
  private static FILTERS_SUFFIX = "_filters"
  private static SORT_SUFFIX = "_sort"

  storeVisibleColumns(path: string, visibleColumns: Column[]): void {
    localStorage.setItem(path + TabParamsStore.COLUMNS_SUFFIX, JSON.stringify(visibleColumns))
  }

  storeFilters(path: string, activeFilters: FilterDTO[]): void {
    localStorage.setItem(path + TabParamsStore.FILTERS_SUFFIX, JSON.stringify(activeFilters))
  }

  storeSorts(path: string, currentSorts: PaginationOrder[]): void {
    localStorage.setItem(path + TabParamsStore.SORT_SUFFIX, JSON.stringify(currentSorts))
  }

  getLastVisibleColumns(path: string): Column[] {
    const storedColumns = localStorage.getItem(path + TabParamsStore.COLUMNS_SUFFIX)
    if (storedColumns) {
      return JSON.parse(storedColumns)
    }
    return []
  }

  getLastFilters(path: string): FilterDTO[] {
    const storedFilters = localStorage.getItem(path + TabParamsStore.FILTERS_SUFFIX)
    if (storedFilters) {
      return JSON.parse(storedFilters)
    }
    return []
  }

  getLastSorts(path: string): PaginationOrder[] {
    const storedFilters = localStorage.getItem(path + TabParamsStore.SORT_SUFFIX)
    if (storedFilters) {
      return JSON.parse(storedFilters)
    }
    return []
  }
}
