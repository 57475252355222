












import { Component, Prop, Vue } from "vue-property-decorator"

@Component({})
export default class LoadingFullScreenSpinner extends Vue {
  @Prop() loadingText: string

  point = "."
}
