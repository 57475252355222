






































































import { Component, Prop, Vue } from "vue-property-decorator"
import PageView from "@/views/commons/PageView.vue"
import ContentView from "@/views/commons/ContentView.vue"
import TitleView from "@/views/commons/TitleView.vue"
import DatePickerWithValidation from "@/views/inputs/DatePickerWithValidation.vue"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import { GraeDTO, ParametrageDTO } from "@/model/bean/GeneratedDTOs"
import { ParametersService } from "@/services/rest/parameters/ParametersService"
import { GraeService } from "@/services/rest/grae/GraeService"
import { UsersService } from "@/services/rest/users/UsersService"
import { LoginService } from "@/services/rest/login/LoginService"
import ConversionUtils from "@/services/ConversionUtils"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import { ApplicationDateService } from "@/services/rest/utils/ApplicationDateService"

@Component({
  components: {
    PageView,
    ContentView,
    TitleView,
    DatePickerWithValidation,
    InputWithValidation,
  },
})
export default class GraeDatesView extends Vue {
  private infoReportingService = InfoReportingService.INSTANCE

  private applicationDateService = ApplicationDateService.INSTANCE

  @Prop() grae: GraeDTO

  usedGrae: GraeDTO

  debutInscription = new Date()
  finInscription = new Date()
  debutLabellisation = new Date()
  finLabellisation = new Date()

  defaultParametrage: ParametrageDTO | null = null

  readOnly = true
  readOnlyInscription = true
  dirty = false
  isLoading = false
  isApplicationDateDevModeActivated = false

  debutAnneeScolaire = ""

  private parametrageService = ParametersService.INSTANCE
  private graeService = GraeService.INSTANCE
  private userService = UsersService.INSTANCE
  private loginService = LoginService.INSTANCE

  async created(): Promise<void> {
    this.usedGrae = Object.assign({}, this.grae)
    await this.initParametrage()

    const loggedUser = this.loginService.getLoggedUser()
    if (loggedUser) {
      this.readOnly = !this.userService.canModifyGrae(loggedUser, this.usedGrae.id)
      this.readOnlyInscription =
        !this.userService.canModifyGrae(loggedUser, this.usedGrae.id) ||
        loggedUser.profil == "ANIMATEUR_REGIONAL"
    }
    this.dirty = false
  }

  dateChanged(): void {
    this.dirty = true
  }

  //Create parametrage is needed and than copy form values and submit
  async submitParametrage(): Promise<void> {
    if (this.usedGrae.parametrage) {
      await this.copyAndSubmit()
    } else if (
      this.defaultParametrage !== null &&
      (this.defaultParametrage.debutInscription !== this.debutInscription ||
        this.defaultParametrage.finInscription !== this.finInscription ||
        this.defaultParametrage.debutLabellisation !== this.debutLabellisation ||
        this.defaultParametrage.finLabellisation !== this.finLabellisation)
    ) {
      this.usedGrae.parametrage = new ParametrageDTO()
      await this.copyAndSubmit()
    }
  }

  //Copy the form values and submit
  async copyAndSubmit(): Promise<void> {
    if (this.usedGrae.parametrage) {
      this.usedGrae.parametrage.debutInscription = this.debutInscription
      this.usedGrae.parametrage.finInscription = this.finInscription
      this.usedGrae.parametrage.debutLabellisation = this.debutLabellisation
      this.usedGrae.parametrage.finLabellisation = this.finLabellisation

      this.usedGrae = await this.graeService.updateGrae(this.usedGrae)
      await this.initParametrage()
      this.dirty = false
    }
  }

  resetParametrage(): void {
    this.infoReportingService.dialog(
      this.$t("ParametersPage.confirm-title").toString(),
      this.$t("ParametersPage.confirm-question").toString(),
      this.$t("ParametersPage.confirm-action").toString(),
      () => this.doResetParametrage(),
      "is-info"
    )
  }

  async doResetParametrage(): Promise<void> {
    this.usedGrae = await this.graeService.resetGraeParametrage(this.usedGrae.id)
    await this.initParametrage()
    this.dirty = false
  }

  //Init the parametrage for the form
  async initParametrage(): Promise<void> {
    //get back default parametrage
    this.defaultParametrage = await this.parametrageService.getParametrage(this.usedGrae.id)

    if (this.usedGrae.parametrage !== undefined && this.usedGrae.parametrage !== null) {
      if (this.usedGrae.parametrage.debutInscription) {
        this.debutInscription = this.usedGrae.parametrage.debutInscription
      } else {
        this.debutInscription = this.defaultParametrage.debutInscription
      }

      if (this.usedGrae.parametrage.finInscription) {
        this.finInscription = this.usedGrae.parametrage.finInscription
      } else {
        this.finInscription = this.defaultParametrage.finInscription
      }

      if (this.usedGrae.parametrage.debutLabellisation) {
        this.debutLabellisation = this.usedGrae.parametrage.debutLabellisation
      } else {
        this.debutLabellisation = this.defaultParametrage.debutLabellisation
      }

      if (this.usedGrae.parametrage.finLabellisation) {
        this.finLabellisation = this.usedGrae.parametrage.finLabellisation
      } else {
        this.finLabellisation = this.defaultParametrage.finLabellisation
      }
    } else {
      //use full default parametrage
      this.debutInscription = this.defaultParametrage.debutInscription
      this.finInscription = this.defaultParametrage.finInscription
      this.debutLabellisation = this.defaultParametrage.debutLabellisation
      this.finLabellisation = this.defaultParametrage.finLabellisation
    }

    this.debutInscription = ConversionUtils.convertToUTCDate(this.debutInscription)
    this.finInscription = ConversionUtils.convertToUTCDate(this.finInscription)
    this.debutLabellisation = ConversionUtils.convertToUTCDate(this.debutLabellisation)
    this.finLabellisation = ConversionUtils.convertToUTCDate(this.finLabellisation)

    if (
      this.usedGrae.parametrage !== undefined &&
      this.usedGrae.parametrage !== null &&
      this.usedGrae.parametrage.moisDebutAnneeScolaire !== null &&
      this.usedGrae.parametrage.jourDebutAnneeScolaire !== undefined
    ) {
      this.debutAnneeScolaire = `${this.usedGrae.parametrage.jourDebutAnneeScolaire} ${this.usedGrae.parametrage.moisDebutAnneeScolaire}`
    } else {
      this.debutAnneeScolaire = `${this.defaultParametrage.jourDebutAnneeScolaire} ${this.defaultParametrage.moisDebutAnneeScolaire}`
    }

    try {
      await this.applicationDateService.getApplicationDate()
      this.isApplicationDateDevModeActivated = true
    } catch (e) {
      // Silent catch, applicationDevMod is not activated
    }
  }

  async moveToNextYear(): Promise<void> {
    this.infoReportingService.dialogWithCancel(
      "Simuler la fin d'année scolaire",
      "Simuler un changement d'année scolaire pour ce GRAE ? Tous les projets en cours seront décalés d'un an dans le passé et de nouveau projets seront créé pour l'année en cours.",
      "Oui",
      async () => {
        this.isLoading = true
        await this.applicationDateService.simulateAnneeScolaireEnd(this.grae.id)
        this.isLoading = false
      },
      () => {
        // Do nothing on cancel
      },
      "is-danger"
    )
  }
}
