


































import { Component, Prop, Vue } from "vue-property-decorator"
import TitleView from "@/views/commons/TitleView.vue"
import ContentView from "@/views/commons/ContentView.vue"
import PageView from "@/views/commons/PageView.vue"
import { LoginService } from "@/services/rest/login/LoginService"

@Component({
  components: {
    TitleView,
    ContentView,
    PageView,
  },
})
export default class RegisterPendingPage extends Vue {
  @Prop() errorMessage: string
  @Prop() registrationMessage: string
  @Prop() mail: string
  @Prop({ default: "none" }) initialResendMode: string

  isLoading = false
  internalErrorMessage = ""

  mounted(): void {
    this.internalErrorMessage = this.errorMessage
    if (!this.registrationMessage) {
      this.$router.push("/login")
    }
  }

  async resendValidationEmail(): Promise<void> {
    try {
      this.isLoading = true
      this.internalErrorMessage = ""
      await LoginService.INSTANCE.resendValidationEMail(this.mail)
      this.isLoading = false
    } catch (e) {
      this.isLoading = false
      this.internalErrorMessage = this.$t("registerPage.resent-failure").toString()
    }
  }
}
