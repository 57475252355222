var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.user.profil == _vm.profils.ANIMATEUR_REGIONAL ||
      _vm.user.profil == _vm.profils.ACCOMPAGNATEUR_REGIONAL
        ? _c(
            "div",
            [
              !_vm.readonly
                ? _c("AutoCompleteWithValidation", {
                    attrs: {
                      group: "PosteDetails",
                      vid: "region",
                      rules:
                        "required|shouldMatchFollowing:" +
                        _vm.filteredRegions(),
                      label: _vm.$t("RegionSelection.region"),
                      data: _vm.filteredRegions(),
                      placeholder: _vm.$t("RegionSelection.entrer-region"),
                      emptyMessage: _vm.$t("RegionSelection.region-non-trouvee")
                    },
                    on: { select: _vm.changeUserRegion },
                    model: {
                      value: _vm.region,
                      callback: function($$v) {
                        _vm.region = $$v
                      },
                      expression: "region"
                    }
                  })
                : _c(
                    "b-field",
                    { attrs: { label: _vm.$t("RegionSelection.region") } },
                    [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.user.grae ? _vm.user.grae.region : "") +
                            " "
                        )
                      ])
                    ]
                  )
            ],
            1
          )
        : _vm._e(),
      _c("InputWithValidation", {
        attrs: {
          group: "PosteDetails",
          vid: "fonction",
          rules: "required",
          label: _vm.$t("profilePage.fonction"),
          placeholder: _vm.$t("profilePage.saisir-fonction"),
          disabled: _vm.readonly
        },
        on: { "manual-change": _vm.changeUserProfile },
        model: {
          value: _vm.user.fonction,
          callback: function($$v) {
            _vm.$set(_vm.user, "fonction", $$v)
          },
          expression: "user.fonction"
        }
      }),
      _c("InputWithValidation", {
        attrs: {
          group: "PosteDetails",
          vid: "employeur",
          rules: "required",
          label: _vm.$t("profilePage.employeur"),
          placeholder: _vm.$t("profilePage.saisir-employeur"),
          disabled: _vm.readonly
        },
        on: { "manual-change": _vm.changeUserProfile },
        model: {
          value: _vm.user.employeur,
          callback: function($$v) {
            _vm.$set(_vm.user, "employeur", $$v)
          },
          expression: "user.employeur"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }