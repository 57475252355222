var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loggedUser &&
    (_vm.loggedUser.profil == "ACCOMPAGNATEUR_NATIONAL" ||
      _vm.loggedUser.profil == "OBSERVATEUR_NATIONAL" ||
      _vm.loggedUser.profil == "SUPER_ADMINISTRATEUR" ||
      _vm.loggedUser.profil == "ANIMATEUR_REGIONAL" ||
      _vm.loggedUser.profil == "ACCOMPAGNATEUR_REGIONAL")
    ? _c(
        "section",
        [
          _c(
            "div",
            { staticClass: "FirstConnectionView-cards" },
            _vm._l(_vm.demandesEnCours, function(user) {
              return _c(
                "div",
                { key: user.id },
                [
                  _vm.canAccept(user)
                    ? _c("GraeMemberDemandeCard", {
                        attrs: {
                          userDemande: user,
                          reject: _vm.deny,
                          accept: _vm.accept
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            }),
            0
          ),
          _c("TableFilterView", {
            attrs: {
              columns: _vm.columns,
              initialFilters: _vm.activeFilters,
              page: "userList"
            },
            on: {
              "update-active-filters": _vm.updateActiveFilters,
              "update-visible-columns": _vm.updateVisibleColumns,
              "export-csv": _vm.exportCSV,
              "export-xls": _vm.exportXLS
            }
          }),
          _c(
            "b-table",
            {
              ref: _vm.tableRef,
              staticClass: "userslist",
              class: { filtered: this.activeFilters.length },
              attrs: {
                striped: _vm.config.isStriped,
                hoverable: _vm.config.isHoverable,
                "debounce-search": _vm.config.searchDebounce,
                "aria-next-label": _vm.config.nextLabel(),
                "aria-previous-label": _vm.config.previousLabel(),
                "aria-page-label": _vm.config.pageLabel(),
                "aria-current-label": _vm.config.currentPageLabel(),
                "per-page": _vm.config.perPage,
                "sticky-header": _vm.config.stickyHeader,
                "current-page": _vm.currentPagedSortedParameter.pageNumber,
                "pagination-position": _vm.config.paginationPosition,
                paginated: "",
                "backend-pagination": "",
                "sort-icon": _vm.config.sortIcon,
                "sort-icon-size": _vm.config.sortIconSize,
                "backend-sorting": "",
                "sort-multiple": "",
                "sort-multiple-data": _vm.buefySortingPriority,
                total: _vm.total,
                data: _vm.userslist,
                loading: _vm.isLoading,
                "detail-key": "courriel",
                "row-class": function(row) {
                  return (
                    (row.statutActivation === "INSCRIPTION_A_VALIDER" ||
                      row.statutActivation === "EMAIL_A_VALIDER") &&
                    _vm.canDeleteUser &&
                    "is-waiting"
                  )
                }
              },
              on: {
                sort: _vm.onSort,
                "sorting-priority-removed": _vm.sortingPriorityRemoved,
                "page-change": _vm.onPageChange
              }
            },
            [
              _vm._l(_vm.columns, function(column) {
                return [
                  _c(
                    "b-table-column",
                    _vm._b(
                      {
                        key: column.id,
                        attrs: { visible: column.visible },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(props) {
                                return [
                                  column.field === "profil"
                                    ? _c(
                                        "div",
                                        [
                                          _vm.canPromote(props.row) ||
                                          _vm.canDemote(props.row)
                                            ? _c(
                                                "b-select",
                                                {
                                                  ref: "select-" + props.row.id,
                                                  refInFor: true,
                                                  attrs: {
                                                    placeholder: _vm.$t(
                                                      "usersList.select-profile"
                                                    ),
                                                    value: props.row.profil
                                                  },
                                                  on: {
                                                    input: function(profil) {
                                                      return _vm.changeUserProfile(
                                                        props.row,
                                                        profil
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: {
                                                        value:
                                                          "ACCOMPAGNATEUR_REGIONAL"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "ACCOMPAGNATEUR_REGIONAL"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: {
                                                        value:
                                                          "ANIMATEUR_REGIONAL"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "ANIMATEUR_REGIONAL"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(props.row.profil)
                                                    ) +
                                                    " "
                                                )
                                              ])
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  column.field === "projets"
                                    ? _c(
                                        "div",
                                        [
                                          _vm.isVisibleProjects(props.row)
                                            ? _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    type: "is-primary",
                                                    disabled: !_vm.isVisibleProjects(
                                                      props.row
                                                    )
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.GoToProject(
                                                        props.row
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "usersList.projets"
                                                      )
                                                    ) + " "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  column.field == _vm.statusColumnField
                                    ? _c("div", [
                                        _vm.canAccept(props.row)
                                          ? _c(
                                              "div",
                                              [
                                                _vm.userBeingValidated !=
                                                  props.row["id"] &&
                                                _vm.userBeingRefused !=
                                                  props.row["id"]
                                                  ? _c(
                                                      "b-select",
                                                      {
                                                        attrs: {
                                                          placeholder: _vm.$t(
                                                            "usersList.waiting"
                                                          )
                                                        },
                                                        on: {
                                                          input: function(
                                                            value
                                                          ) {
                                                            return _vm.changeStatut(
                                                              value,
                                                              props.row
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "option",
                                                          {
                                                            domProps: {
                                                              value:
                                                                _vm.statut
                                                                  .valide
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.statut
                                                                    .valide
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "option",
                                                          {
                                                            domProps: {
                                                              value:
                                                                _vm.statut
                                                                  .refuse
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.statut
                                                                    .refuse
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : _vm.userBeingValidated ==
                                                    props.row["id"]
                                                  ? _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.statut.validated
                                                          ) +
                                                          " "
                                                      )
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.statut.refused
                                                          ) +
                                                          " "
                                                      )
                                                    ])
                                              ],
                                              1
                                            )
                                          : _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      props.row[
                                                        "statutActivation"
                                                      ]
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                      ])
                                    : _vm._e(),
                                  column.field === "action" && _vm.canDeleteUser
                                    ? _c(
                                        "div",
                                        [
                                          _c("b-button", {
                                            attrs: {
                                              type: "is-danger is-outlined",
                                              "icon-left": "trash",
                                              title: _vm.$t(
                                                "usersList.supprimer"
                                              )
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteUser(
                                                  $event,
                                                  props.row
                                                )
                                              }
                                            }
                                          }),
                                          _vm.canValidateEmail(props.row)
                                            ? _c("b-button", {
                                                staticStyle: {
                                                  "margin-left": "10px"
                                                },
                                                attrs: {
                                                  type:
                                                    "is-primary is-outlined",
                                                  "icon-left": "check",
                                                  title: _vm.$t(
                                                    "usersList.validate-email"
                                                  )
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.validateEmail(
                                                      $event,
                                                      props.row
                                                    )
                                                  }
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  column.field === "region"
                                    ? _c("div", [
                                        props.row["grae"] &&
                                        props.row["grae"]["region"]
                                          ? _c(
                                              "span",
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      props.row["grae"][
                                                        "region"
                                                      ]
                                                    ) +
                                                    " "
                                                ),
                                                props.row["instructedGraes"] &&
                                                props.row["instructedGraes"]
                                                  .length !== 0
                                                  ? _c(
                                                      "span",
                                                      _vm._l(
                                                        props.row[
                                                          "instructedGraes"
                                                        ],
                                                        function(
                                                          instructedGrae
                                                        ) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: instructedGrae
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.getInstructedGraeRegion(
                                                                      instructedGrae
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  : _vm._e(),
                                                _vm.loggedUser &&
                                                (_vm.loggedUser.profil ===
                                                  "SUPER_ADMINISTRATEUR" ||
                                                  _vm.loggedUser.profil ===
                                                    "ACCOMPAGNATEUR_NATIONAL") &&
                                                props.row.profil &&
                                                (props.row.profil ===
                                                  "ACCOMPAGNATEUR_REGIONAL" ||
                                                  props.row.profil ===
                                                    "ANIMATEUR_REGIONAL")
                                                  ? _c("b-button", {
                                                      attrs: {
                                                        type:
                                                          "is-dark is-outlined",
                                                        "icon-left": "edit"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.openGRAEDialog(
                                                            $event,
                                                            props.row
                                                          )
                                                        }
                                                      }
                                                    })
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : _c("span")
                                      ])
                                    : _vm._e(),
                                  !column.custom
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "clickable",
                                          on: {
                                            click: function($event) {
                                              return _vm.showDetailsPage(
                                                $event,
                                                props.row["id"]
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(props.row[column.field])
                                            )
                                          ])
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      "b-table-column",
                      column,
                      false
                    )
                  )
                ]
              }),
              _c(
                "div",
                {
                  staticClass: "empty-table",
                  attrs: { slot: "empty" },
                  slot: "empty"
                },
                [_vm._v(" " + _vm._s(_vm.$t("usersList.aucun-resultat")) + " ")]
              )
            ],
            2
          ),
          _c("GRAEModale", {
            attrs: { open: _vm.openGRAEModale, user: _vm.selectedUser },
            on: {
              "close-modale": function(refresh) {
                return _vm.closeGRAEDialog(refresh)
              }
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }