var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ContentView",
    [
      _c(
        "b-modal",
        {
          staticClass: "FirstConnectionView",
          attrs: {
            active: _vm.isComponentModalActive,
            "has-modal-card": "",
            "trap-focus": "",
            "aria-role": "dialog",
            "aria-label": "FirstConnectionModal",
            "aria-modal": "",
            width: "100vw"
          }
        },
        [
          [
            _vm.pendingProjects &&
            _vm.pendingProjects.length > 0 &&
            _vm.currentStep === 0
              ? _c("div", { staticClass: "modal-card" }, [
                  _c(
                    "header",
                    { staticClass: "modal-card-head" },
                    [
                      _c("H3TitleView", { staticClass: "modal-card-title" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("myProjects.firstConnectionTitle").toString()
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "section",
                    { staticClass: "modal-card-body" },
                    [
                      _c("H4TitleView", [
                        _vm._v(_vm._s(_vm.$t("myProjects.pendingInvit")) + " ")
                      ]),
                      _c(
                        "div",
                        { staticClass: "FirstConnectionView-cards" },
                        [
                          _vm.isLoading
                            ? _c(
                                "div",
                                [
                                  _c("BookmarkCard", {
                                    attrs: {
                                      isLoading: true,
                                      tagType: _vm.tagTypes.INVITATION,
                                      iconName: "paper-plane"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._l(_vm.pendingProjects, function(project) {
                                return _c(
                                  "div",
                                  { key: project.id },
                                  [
                                    _c("PendingProjectCard", {
                                      attrs: {
                                        project: project,
                                        loggedUserId: _vm.loggedUser
                                          ? _vm.loggedUser.id
                                          : "",
                                        sendToServer: false
                                      }
                                    })
                                  ],
                                  1
                                )
                              })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c("footer", { staticClass: "modal-card-foot" }, [
                    _c("div", { staticClass: "buttons" }, [
                      _vm.projectsInEtablissement &&
                      _vm.projectsInEtablissement.length > 0
                        ? _c(
                            "div",
                            { staticClass: "modal-finish-buttons" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "hoverable-discrete-link",
                                  on: { click: _vm.endFirstConnection }
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("annuler")) + " ")]
                              ),
                              _c(
                                "b-button",
                                {
                                  attrs: { rounded: "", type: "is-primary" },
                                  on: { click: _vm.sendAnswersAndGoNext }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("myProjects.nextStep")) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "modal-finish-buttons" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "hoverable-discrete-link",
                                  on: { click: _vm.endFirstConnection }
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("annuler")) + " ")]
                              ),
                              _c(
                                "b-button",
                                {
                                  attrs: { rounded: "", type: "is-primary" },
                                  on: { click: _vm.sendAnswersAndClose }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("myProjects.end")) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                    ])
                  ])
                ])
              : _vm._e(),
            _vm.projectsInEtablissement &&
            _vm.projectsInEtablissement.length > 0 &&
            (_vm.currentStep === 1 ||
              !_vm.pendingProjects ||
              _vm.pendingProjects.length === 0)
              ? _c("div", { staticClass: "modal-card" }, [
                  _c(
                    "header",
                    { staticClass: "modal-card-head" },
                    [
                      _c("H3TitleView", { staticClass: "modal-card-title" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("myProjects.firstConnectionTitle").toString()
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "section",
                    { staticClass: "modal-card-body" },
                    [
                      _c("H4TitleView", [
                        _vm._v(_vm._s(_vm.$t("myProjects.joinProject")) + " ")
                      ]),
                      _c(
                        "div",
                        { staticClass: "FirstConnectionView-cards" },
                        [
                          _vm.isLoading
                            ? _c(
                                "div",
                                [
                                  _c("BookmarkCard", {
                                    attrs: {
                                      isLoading: true,
                                      tagType: _vm.tagTypes.JOIN,
                                      iconName: "pencil-ruler"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._l(_vm.projectsInEtablissement, function(
                                project
                              ) {
                                return _c(
                                  "div",
                                  { key: project.id },
                                  [
                                    _c("EtablissementProjectCard", {
                                      attrs: {
                                        project: project,
                                        loggedUser: _vm.loggedUser
                                      }
                                    })
                                  ],
                                  1
                                )
                              })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c("footer", { staticClass: "modal-card-foot" }, [
                    _c(
                      "div",
                      { class: "buttons modal-finish-buttons" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "hoverable-discrete-link",
                            on: { click: _vm.endFirstConnection }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("annuler")) + " ")]
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: { rounded: "", type: "is-primary" },
                            on: { click: _vm.joinProjectsAndClose }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("myProjects.end")) + " ")]
                        )
                      ],
                      1
                    )
                  ])
                ])
              : _vm._e()
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }