














import { EtablissementDTO } from "@/model/bean/GeneratedDTOs"
import Vue from "vue"
import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"

@Component({
  components: {},
})
export default class SingleEtablissement extends Vue {
  @Prop() etablissement: EtablissementDTO
}
