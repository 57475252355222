






























































































import { ProfilUtilisateur, ProjetAnnuelDTO, UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import { Component, Prop, Vue } from "vue-property-decorator"
import ProjetEnseignantsView from "@/pages/projet-page/info-administratives/ProjetEnseignantsView.vue"
import ProjetReferentsView from "@/pages/projet-page/info-administratives/ProjetReferentsView.vue"
import ProjetClassesView from "@/pages/projet-page/info-administratives/ProjetClassesView.vue"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import { LoginService } from "@/services/rest/login/LoginService"
import { UsersService } from "@/services/rest/users/UsersService"
import { DialogProgrammatic as Dialog } from "buefy"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"

@Component({
  components: {
    ProjetEnseignantsView,
    ProjetReferentsView,
    ProjetClassesView,
    InputWithValidation,
  },
})
export default class ProjetParticipants extends Vue {
  @Prop() projetAnnuel: ProjetAnnuelDTO
  @Prop() readonly: boolean
  @Prop() isPorteurProjet: boolean
  @Prop({ default: true }) validationActivated: boolean
  @Prop({ default: true }) showValidationErrors: boolean

  private loginService = LoginService.INSTANCE
  private usersService = UsersService.INSTANCE
  private infoReportingService = InfoReportingService.INSTANCE

  loggedUser: UtilisateurDTO = new UtilisateurDTO()
  invitations: string[] = []

  created(): void {
    const loggedUser = this.loginService.getLoggedUser()
    if (!loggedUser || !loggedUser.id) {
      return
    }
    this.loggedUser = loggedUser
    this.$root.$on("project-saved", () => {
      this.invitations = []
    })
  }

  showInviteLink(): boolean {
    return (
      (this.usersService.isPorteurProjet(this.loggedUser) ||
        this.usersService.isAnimateurRegional(this.loggedUser) ||
        this.usersService.isAccompagnateurNationalOrAdmin(this.loggedUser)) &&
      !this.readonly
    )
  }

  dirtifyProject(withRedirect = false): void {
    this.$emit("dirtify-project", withRedirect)
  }
  isValidEmail(email: string): boolean {
    const regex = /^[^\s@'"&]+@[^\s@'"&]+\.[^\s@'"&]+$/
    return regex.test(email.toLowerCase())
  }

  inviteEnseignant(): void {
    this.infoReportingService.dialogWithInput(
      this.$t("projetPage.infoAdministratives.participants.modaleAjout.titreEnseignant").toString(),
      this.$t("projetPage.infoAdministratives.participants.modaleAjout.message").toString(),
      {
        type: "email",
        placeholder: this.$t(
          "projetPage.infoAdministratives.participants.modaleAjout.emailPlaceholder"
        ).toString(),
      },
      this.$t("projetPage.infoAdministratives.participants.modaleAjout.valider").toString(),
      (courriel: string) => {
        if (this.isValidEmail(courriel)) {
          this.addEnseignant(courriel)
        } else {
          this.infoReportingService.warn("Invalid email format", new Error())
        }
      },
      ""
    )
  }

  async addEnseignant(courriel: string): Promise<void> {
    if (!this.alreadyParticipating(courriel)) {
      if (await this.hasExpectedProfilAndGrae(courriel, "ENSEIGNANT")) {
        this.projetAnnuel.enseignants = [
          ...this.projetAnnuel.enseignants,
          { courriel: courriel } as UtilisateurDTO,
        ]
        this.projetAnnuel.charteFileValid = false
        this.invitations = [...this.invitations, courriel]
        this.dirtifyProject()
      }
    }
  }

  inviteReferent(): void {
    if (this.projetAnnuel.referents.length) {
      Dialog.confirm({
        message: this.$t(
          "projetPage.infoAdministratives.participants.AddReferentConfirm"
        ).toString(),
        confirmText: this.$t("confirmer").toString(),
        cancelText: this.$t("annuler").toString(),
        onConfirm: () => this.showReferentDialog(),
      })
    } else {
      this.showReferentDialog()
    }
  }

  private showReferentDialog(): void {
    this.infoReportingService.dialogWithInput(
      this.$t("projetPage.infoAdministratives.participants.modaleAjout.titreReferent").toString(),
      this.$t("projetPage.infoAdministratives.participants.modaleAjout.message").toString(),
      {
        type: "email",
        placeholder: this.$t(
          "projetPage.infoAdministratives.participants.modaleAjout.emailPlaceholder"
        ).toString(),
      },
      this.$t("projetPage.infoAdministratives.participants.modaleAjout.valider").toString(),
      (courriel: string) => {
        this.addReferent(courriel)
      },
      ""
    )
  }

  async addReferent(courriel: string): Promise<void> {
    if (!this.alreadyParticipating(courriel)) {
      if (await this.hasExpectedProfilAndGrae(courriel, "REFERENT")) {
        this.projetAnnuel.referents = [
          ...this.projetAnnuel.referents,
          { courriel: courriel } as UtilisateurDTO,
        ]
        this.projetAnnuel.charteFileValid = false
        this.invitations = [...this.invitations, courriel]
        this.dirtifyProject()
      }
    }
  }

  alreadyParticipating(courriel: string): boolean {
    let allParticipantsCourriel = this.projetAnnuel.enseignants.map((ens) => ens.courriel)
    allParticipantsCourriel = allParticipantsCourriel.concat(
      this.projetAnnuel.referents.map((ref) => ref.courriel)
    )
    const alreadyParticipating = allParticipantsCourriel.indexOf(courriel.trim()) > -1
    if (alreadyParticipating) {
      this.infoReportingService.warn(
        this.$t("projetPage.infoAdministratives.participants.already-in-project").toString(),
        new Error()
      )
      return true
    } else {
      return false
    }
  }

  async hasExpectedProfilAndGrae(
    courriel: string,
    expectedProfil: ProfilUtilisateur
  ): Promise<boolean> {
    const grae = this.loggedUser.grae
    const hasExpectedProfilAndGrae = await this.usersService.checkProfilAndGrae(
      courriel,
      expectedProfil,
      grae
    )
    if (!hasExpectedProfilAndGrae) {
      this.infoReportingService.warn(
        this.$t("projetPage.infoAdministratives.participants.wrong-profile", [
          this.$t(expectedProfil).toString(),
        ]).toString(),
        new Error()
      )
    }
    return hasExpectedProfilAndGrae
  }
}
