






























import { UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import { UsersService } from "@/services/rest/users/UsersService"
import { ProjectService } from "@/services/rest/project/ProjectService"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import MainVerticalMenu from "./MainVerticalMenu.vue"
import SubVerticalMenu from "./SubVerticalMenu.vue"
import i18n from "@/i18n"
import { Route } from "vue-router"
import { ContextMenu, NavbarItem } from "@/views/menus/MenuItems"
import { LoginService } from "@/services/rest/login/LoginService"

const MENU_LABELS_WITH_SUB_MENU = {
  STRUCTURE: i18n.t("menu.myStructure").toString(),
  ETABLISSEMENT: i18n.t("menu.myEtablissement").toString(),
  INSTRUCTION: i18n.t("menu.instruction").toString(),
  PERIODS: i18n.t("menu.periods").toString(),
  AIRES_EDUCATIVES: i18n.t("menu.aires-educatives").toString(),
  GRAE: i18n.t("menu.grae").toString(),
  DOCUMENT: i18n.t("menu.documents").toString(),
  CARTE: i18n.t("menu.carte").toString(),
}

@Component({
  components: { MainVerticalMenu, SubVerticalMenu },
})
export default class VerticalMenus extends Vue {
  @Prop() loggedUser: UtilisateurDTO | undefined
  @Prop() contextMenuProp: ContextMenu
  @Prop() menuExpanded: boolean
  @Prop({ default: "" }) currentScreenName: string

  contextMenu: ContextMenu = new ContextMenu([], [])

  private usersService = UsersService.INSTANCE
  private projectService = ProjectService.INSTANCE

  isAccompagnateurNationalOrAdmin = false
  isObservateurNational = false
  isAccompagnateurRegional = false
  isReferent = false
  isEnseignant = false
  innerLoggedUser: UtilisateurDTO | undefined = new UtilisateurDTO()
  mainNavbarItems: NavbarItem[] = []
  private previousRoutes: Route[] = []
  previousScreenName = ""
  private previousScreenNames: string[] = []
  backWasClicked = false
  storeNextScreenName = true

  // New variable to store graeId
  graeId: string | undefined = ""
  graeIdRoute: string | undefined = ""
  pendingRequest = {
    users: 0,
    modif_porteur: 0,
  }
  pendingRequestMember = {
    users: 0,
    modif_porteur: 0,
  }
  role: string | undefined = ""
  notifications: { [index: string]: number } = {}

  mounted(): void {
    this.innerLoggedUser = this.loggedUser
    // this.graeId = this.loggedUser?.grae?.id
    // Before each route, remember the last visited page to be able to handle back button behavior
    this.$router.beforeEach((to, from, next) => {
      // Empty contextual menu before changing route
      if (from.name?.split(" ")[0] != to.name?.split(" ")[0]) {
        if (!this.backWasClicked) {
          this.previousScreenName = this.currentScreenName
          this.storeNextScreenName = true
          this.previousRoutes.push(from)
          this.contextMenu.items = []
          this.contextMenu.parentsPath = []
          this.refreshActiveLinkDisplay(to)
          this.$emit("show-sub-menu", false)
        } else {
          this.$emit("show-sub-menu", false)
          const previous = this.previousScreenNames[this.previousScreenNames.length - 2]
          this.previousScreenName = previous ? previous : this.getDefaultPreviousScreenName()
        }
        this.backWasClicked = false
      }
      this.refreshActiveLinkDisplay(to)
      next()
    })

    this.$router.afterEach(() => {
      this.pendingRequest = {
        users: 0,
        modif_porteur: 0,
      }
      this.pendingRequestMember = {
        users: 0,
        modif_porteur: 0,
      }
      this.someFunction()
    })
  }

  async someFunction() {
    this.graeId = this.loggedUser?.grae?.id
    try {
      // Extract the graeIdRoute from the URL
      const url = new URL(window.location.href)
      const pathParts = url.pathname.split("/")
      const graeIdRoute = pathParts.includes("grae")
        ? pathParts[pathParts.indexOf("grae") + 3]
        : "all"
      const gareid = graeIdRoute != null ? graeIdRoute : this.graeId
      if (this.role && gareid /*&& graeIdRoute != "all"*/) {
        if (this.role === "ANIMATEUR_REGIONAL" || this.role === "ACCOMPAGNATEUR_REGIONAL") {
          this.pendingRequest.users = await this.usersService.getNumberOfConsentement(gareid)
          this.pendingRequestMember = this.pendingRequest
        }
        if (this.role === "ACCOMPAGNATEUR_NATIONAL" || this.role === "OBSERVATEUR_NATIONAL") {
          this.pendingRequest.users = await this.usersService.getNumberOfConsentementNational()
          this.pendingRequestMember.users = await this.usersService.getNumberOfConsentement(gareid)
        }
      }

      const notifs = await this.projectService.getNotificationPP(this.graeId)
      if(notifs){
        this.pendingRequest.modif_porteur = notifs[0].total
        this.notifications = notifs[0].notifications
      }

    } catch (error) {
      console.error("Error fetching number of consentement:", error)
    }
  }

  @Watch("loggedUser")
  onLoggedUserChanged(newLoggedUser: UtilisateurDTO): void { 
    this.graeId = newLoggedUser?.grae?.id
    this.role = newLoggedUser?.profil
    this.refreshMenuForNewLoggedUser(newLoggedUser)
    this.innerLoggedUser = newLoggedUser
  }

  @Watch("currentScreenName")
  screenNameChanged(): void {
    if (this.storeNextScreenName) {
      // Initialize first previous screen name if this is the first page seen
      if (this.previousScreenNames.length == 0) {
        this.previousScreenNames.unshift(this.getDefaultPreviousScreenName())
        this.previousScreenName = this.previousScreenNames[0]
      }

      // Then store current screen in the previous screen names list
      this.previousScreenNames.push(this.currentScreenName)
      this.storeNextScreenName = false
    }
  }

  /**
   * Send reset notification porteur backend
   */
  async resetNotifPorteur(): Promise<void> {
    this.pendingRequest.modif_porteur = 0
    await this.projectService.resetNotificationPP(undefined)
  }

  async resestNotifGraeId(event: any): Promise<void> {
    // Mise à jour compteur global
    this.pendingRequest.modif_porteur = this.pendingRequest.modif_porteur - event.countNotifGrae
    // Reset notif grae
    await this.projectService.resetNotificationPP(event.idGrae)
  }

  /**
   * Refreshes the active link (in both main menu and contextual menu)
   */
  refreshActiveLinkDisplay(route: Route): void {
    this.mainNavbarItems.forEach((item) => (item.isLinkActive = false))
    this.contextMenu?.items.forEach((item) => (item.isLinkActive = false))

    // Case 1: there is a contextual menu
    // => Highlight sub-item matching route (if any)
    const matchedSubItem = this.contextMenu?.items.filter((subItem) => {
      return this.itemIsMatchingRoute(subItem, route, false)
    })
    if (matchedSubItem?.length) {
      matchedSubItem[0].isLinkActive = true
      // Also highlight parent of this sub item
      const matchedParentItem = this.mainNavbarItems.filter((mainItem) => {
        const matchedParentPath = this.contextMenu.parentsPath.filter((pathCandidate) => {
          return pathCandidate.replace(/[0-9]/g, "") == mainItem.toPath.replace(/[0-9]/g, "")
        })
        return matchedParentPath.length > 0
      })
      if (matchedParentItem.length) {
        matchedParentItem[0].isLinkActive = true
      }
    } else {
      // Case 2: no context menu
      // => Highlight main navbar item matching route (if any)
      const matchedMainItem = this.mainNavbarItems.filter((subItem) => {
        return this.itemIsMatchingRoute(subItem, route, true)
      })
      if (matchedMainItem.length) {
        matchedMainItem[0].isLinkActive = true
      }
    }
  }

  refreshMenuForNewLoggedUser(newLoggedUser: UtilisateurDTO | undefined): void {
    this.innerLoggedUser = newLoggedUser

    if (this.innerLoggedUser) {
      this.isReferent = this.innerLoggedUser.profil === "REFERENT"
      this.isEnseignant = this.innerLoggedUser.profil === "ENSEIGNANT"
      this.isAccompagnateurNationalOrAdmin = this.usersService.isAccompagnateurNationalOrAdmin(
        this.innerLoggedUser
      )
      this.isObservateurNational = this.usersService.isObservateurNational(this.innerLoggedUser)
      this.isAccompagnateurRegional = this.usersService.isAccompagnateurRegional(
        this.innerLoggedUser
      )
    } else {
      this.isReferent = false
      this.isEnseignant = false
      this.isAccompagnateurNationalOrAdmin = false
      this.isObservateurNational = false
      this.isAccompagnateurRegional = false
    }

    this.initialiseMainMenu()
  }

  initialiseMainMenu(): void {
    if (this.isReferent) {
      this.mainNavbarItems = [
        new NavbarItem("/myProjects", this.$t("menu.myProjects").toString(), "clipboard", false),
        new NavbarItem("/structure/details", MENU_LABELS_WITH_SUB_MENU.STRUCTURE, "building", true),
        new NavbarItem("/carte", MENU_LABELS_WITH_SUB_MENU.CARTE, "map-marked-alt", false),
      ]
    } else if (this.isEnseignant) {
      this.mainNavbarItems = [
        new NavbarItem("/myProjects", this.$t("menu.myProjects").toString(), "clipboard", false),
        new NavbarItem(
          "/etablissement/details",
          MENU_LABELS_WITH_SUB_MENU.ETABLISSEMENT,
          "building",
          true
        ),
        new NavbarItem("/carte", MENU_LABELS_WITH_SUB_MENU.CARTE, "map-marked-alt", false),
      ]
    } else if (
      this.isAccompagnateurRegional &&
      this.innerLoggedUser &&
      this.innerLoggedUser.instructedGraes &&
      this.innerLoggedUser?.instructedGraes?.length !== 0
    ) {
      this.mainNavbarItems = [
        new NavbarItem("/carte", MENU_LABELS_WITH_SUB_MENU.CARTE, "map-marked-alt", false),
        new NavbarItem("/grae-list", this.$t("menu.grae-list").toString(), "university", false),
      ]
    } else if (this.isAccompagnateurRegional) {
      this.mainNavbarItems = [
        new NavbarItem("/indicateurs", this.$t("menu.indicateurs").toString(), "chart-pie", false),
        new NavbarItem("/carte", MENU_LABELS_WITH_SUB_MENU.CARTE, "map-marked-alt", false),
        new NavbarItem("/grae/details/0", MENU_LABELS_WITH_SUB_MENU.GRAE, "university", true),
        new NavbarItem(
          "/instruction/inscriptions/0/0",
          MENU_LABELS_WITH_SUB_MENU.INSTRUCTION,
          "gavel",
          false
        ),
        new NavbarItem(
          "/users-list",
          this.$t("menu.contact-porteurs-projet").toString(),
          "users",
          false
        ),
      ]
    } else if (this.isAccompagnateurNationalOrAdmin || this.isObservateurNational) {
      this.mainNavbarItems = [
        new NavbarItem("/grae-list", this.$t("menu.grae-list").toString(), "university", false),
        new NavbarItem("/ae/0", MENU_LABELS_WITH_SUB_MENU.AIRES_EDUCATIVES, "clipboard-list", true),
        new NavbarItem("/ModifProjet", this.$t("menu.ModifProjet").toString(), "calendar-week", false),
        new NavbarItem("/users-list", this.$t("menu.utilisateurs").toString(), "users", false),
        new NavbarItem("/actualites", this.$t("menu.actualites").toString(), "newspaper", false),
        new NavbarItem("/parameters", MENU_LABELS_WITH_SUB_MENU.PERIODS, "calendar-week", true),
        new NavbarItem("/documents", MENU_LABELS_WITH_SUB_MENU.DOCUMENT, "newspaper", false),
        new NavbarItem("/carte", MENU_LABELS_WITH_SUB_MENU.CARTE, "map-marked-alt", false),
      ]
    } else {
      this.mainNavbarItems = []
    }
  }

  /**
   * Indicates if the given item is corresponding to the given route (and hence should be highlighted).
   */
  itemIsMatchingRoute(item: NavbarItem, route: Route | undefined, exactPath: boolean): boolean {
    let itemRoutePath = item.toPath
    const uuidRegex = /\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/g
    const numericRegex = /\/[0-9]/g
    if (!exactPath) {
      itemRoutePath = itemRoutePath.replace(uuidRegex, "")
      itemRoutePath = itemRoutePath.replace(numericRegex, "")
    }
    let currentRoutePath = route?.path
    if (!exactPath) {
      currentRoutePath = currentRoutePath?.replace(uuidRegex, "")
      currentRoutePath = currentRoutePath?.replace(numericRegex, "")
    }
    return itemRoutePath == currentRoutePath
  }

  backToPreviousRoute(): void {
    this.backWasClicked = true
    if (this.previousRoutes.length) {
      const previousRoute = this.previousRoutes.pop()
      this.previousScreenNames.pop()
      if (previousRoute) {
        this.$router.replace(previousRoute.path)
        return
      }
    }
    this.$router.push(LoginService.INSTANCE.getPageToRedirectToAfterLogin(this.loggedUser))
  }

  @Watch("contextMenuProp")
  contextMenuPropChanged(newContextMenu: ContextMenu): void {
    this.contextMenu = newContextMenu
    this.refreshActiveLinkDisplay(this.$router.currentRoute)
    this.$emit("show-sub-menu", newContextMenu.items.length > 0)
  }

  getDefaultPreviousScreenName(): string {
    if (this.loggedUser) {
      if (
        this.usersService.isAccompagnateurNationalOrAdmin(this.loggedUser) ||
        this.usersService.isObservateurNational(this.loggedUser) ||
        (this.usersService.isAccompagnateurRegional(this.loggedUser) &&
          this.loggedUser.instructedGraes &&
          this.loggedUser.instructedGraes.length !== 0)
      ) {
        return this.$t("menu.grae-list").toString()
      } else if (
        this.loggedUser.profil == "ACCOMPAGNATEUR_REGIONAL" ||
        this.loggedUser.profil == "ANIMATEUR_REGIONAL"
      ) {
        return this.$t("menu.grae").toString()
      }
    }
    return this.$t("menu.myProjects").toString()
  }
}
