/*-
 * #%L
 * Aires Éducatives
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
import { HealthcheckService } from "./../utils/HealthcheckService"
import { AbstractRestService } from "@/services/rest/AbstractRestService"
import Events from "@/services/Events"
import {
  AuthenticationDTO,
  PasswordCheckDTO,
  PasswordResetDTO,
  ForgotPasswordDTO,
  UtilisateurRegistrationDTO,
  ValidateEmailDTO,
  UtilisateurDTO,
} from "@/model/bean/GeneratedDTOs"
import { LoginAttempts } from "@/model/LoginAttempts"
import i18n from "@/i18n"
import vueApp from "@/main.ts"
import { UsersService } from "../users/UsersService"

export class LoginService extends AbstractRestService {
  // Delay (in MS) for considering to consecutive logins to be counted as abusive attempt
  RECENT_ATTEMPT_DELAY = 10000
  // Number of consecutive attempts to accept before locking the account
  MAX_ATTEMPTS_COUNT = 3
  // Delay during which the account will be locked
  TOO_MANY_ATTEMPS_DELAY = 30000

  static INSTANCE = new LoginService()
  static LOCAL_STORAGE_LOGGUED_USER = "loggedUser"
  static LOCAL_STORAGE_LOGIN_ATTEMPTS = "loginAttemps"
  static LOCAL_STORAGE_LAST_SUCCESSFULL_EMAIL = "lastSuccessfullEmail"
  static LOCAL_STORAGE_FIRST_CONNECTED = "haveBeenFirstConnected"
  static WEBPACK_KEY = "loglevel:webpack-dev-server"

  // In-memory representation of the currently logged user
  // This information is also mirrored in localStorage
  private loggedUser?: UtilisateurDTO
  isRetrievingUser = false
  usersService = UsersService.INSTANCE

  constructor() {
    super()
  }

  isUserLoggedIn(): boolean {
    return this.getLoggedUser() != undefined
  }

  getLoggedUser(): UtilisateurDTO | undefined {
    return this.loggedUser
  }

  setUserHaveBeenLoggedIn(): void {
    localStorage.setItem(LoginService.LOCAL_STORAGE_FIRST_CONNECTED, "true")
  }

  haveUserBeenLoggedIn(): boolean {
    const haveBeenLoggedIn = localStorage.getItem(LoginService.LOCAL_STORAGE_FIRST_CONNECTED)
    if (haveBeenLoggedIn && haveBeenLoggedIn === "true") {
      return true
    } else {
      return false
    }
  }

  /**
   * Performs a login with the given credentials
   * @param credentials credentials to use for loggin in
   * @return the corresponding Utilisateur if loggin succeeds (reject otherwise)
   * Possible rejects :
   * - invalid credentials
   * - account not validated
   * - too many attemps
   */
  async login(credentials: AuthenticationDTO): Promise<UtilisateurDTO> {
    let errorMessage = ""
    // Step 1: check that we did not exceeded login attempts
    const previousLogginAttemptsCount = await this.getRecentLogginAttempsCount()
    if (previousLogginAttemptsCount > this.MAX_ATTEMPTS_COUNT) {
      errorMessage = i18n.t("login-error-too-many-attempts").toString()
    } else {
      try {
        const loginResult: UtilisateurDTO = await super.typedPostWithDifferentResultTypeV2(
          "/authenticate/login",
          2,
          credentials
        )
        return this.loginSucceeded(loginResult)
      } catch (e) {
        // Possible causes of rejection
        if (e.statusText != undefined && e.statusText.indexOf("email") > -1) {
          errorMessage = i18n.t("login-error-non-validated-email").toString()
        } else if (e.statusText != undefined && e.statusText.indexOf("account") > -1) {
          errorMessage = i18n.t("login-error-non-validated-account").toString()
        } else {
          // Invalid credentials
          // Only increase failed attempts in case of invalid credentials
          const updatedLoginAttemptsCount = new LoginAttempts()
          updatedLoginAttemptsCount.count = previousLogginAttemptsCount + 1
          updatedLoginAttemptsCount.lastAttemptDate = new Date().getTime()
          localStorage.setItem(
            LoginService.LOCAL_STORAGE_LOGIN_ATTEMPTS,
            JSON.stringify(updatedLoginAttemptsCount)
          )
          if (e.status == 400) {
            errorMessage = i18n.t("login-error-too-many-attempts").toString()
          } else {
            errorMessage = i18n.t("login-error-invalid-credentials").toString()
          }
        }
      }
    }
    return Promise.reject(errorMessage)
  }

  async validateEmail(token: string): Promise<UtilisateurDTO> {
    let errorMessage = ""
    try {
      const validateEmail = new ValidateEmailDTO()
      validateEmail.token = token
      const validateEmailResult: UtilisateurDTO = await super.typedPostWithDifferentResultTypeV2(
        "/users/validate-email",
        2,
        validateEmail
      )
      if (validateEmailResult.statutActivation == "ACTIF") {
        // We directly log the user
        return this.loginSucceeded(validateEmailResult)
      } else {
        return Promise.resolve(validateEmailResult)
      }
    } catch (e) {
      errorMessage = e.toString()
    }
    return Promise.reject(errorMessage)
  }

  /**
   * Logs out the currently logged user.
   */
  async logout(): Promise<void> {
    try {
      await super.typedPostV2("/authenticate/logout", 2, new UtilisateurDTO())
    } catch (e) {
      console.error(e)
      // Silent catch
    }
    return this.clearLoggedUser()
  }

  /**
   * Clears the currently logged user on front-side (no cool to logout).
   */
  async clearLoggedUser(): Promise<void> {
    this.loggedUser = undefined
    Object.keys(localStorage).forEach((key) => {
      if (
        key !== LoginService.LOCAL_STORAGE_LAST_SUCCESSFULL_EMAIL &&
        key !== LoginService.WEBPACK_KEY
      )
        localStorage.removeItem(key)
    })
    localStorage.removeItem(LoginService.LOCAL_STORAGE_LOGIN_ATTEMPTS)
    this.emit(Events.LOGIN_STATUS_CHANGED)
  }

  /**
   * Asks server to send a forgot password email.
   */
  async forgotPassword(email: string): Promise<ForgotPasswordDTO> {
    const forgotPassword = new ForgotPasswordDTO()
    forgotPassword.courriel = email
    return super.typedPostV2("/users/forgotPassword", 2, forgotPassword)
  }

  /**
   * Asks server to reset password
   */
  async resetPassword(token: string, newPassword: PasswordResetDTO): Promise<UtilisateurDTO> {
    try {
      const forgotPasswordResult: UtilisateurDTO = await super.typedPostWithDifferentResultTypeV2(
        "/users/resetPassword/" + token,
        2,
        newPassword
      )
      return this.loginSucceeded(forgotPasswordResult)
    } catch (e) {
      return Promise.reject(e.toString())
    }
  }

  /**
   * Asks server to reset password
   */
  async changePassword(
    newPassword: PasswordResetDTO,
    utilisateur: UtilisateurDTO
  ): Promise<PasswordResetDTO> {
    return super.typedPostV2("/users/changePassword/" + utilisateur.id, 2, newPassword)
  }

  /**
   * Checks if the connected users password is valid
   */
  async checkPassword(password: PasswordCheckDTO): Promise<PasswordCheckDTO> {
    return super.typedPostV2("/authenticate/checkPassword", 2, password)
  }

  /**
   * Try to register the given user.
   * @param newUser the new user to register
   */
  async register(newUser: UtilisateurRegistrationDTO): Promise<UtilisateurDTO> {
    try {
      const createdUser: UtilisateurDTO = await super.typedPostWithDifferentResultTypeV2(
        "/users",
        2,
        newUser
      )
      return Promise.resolve(createdUser)
    } catch (e) {
      // Handle possible causes of rejection
      // Already existing email
      // Malformed user
      return Promise.reject("registerPage.email-deja-utilise")
    }
  }

  async resendValidationEMail(mail: string): Promise<UtilisateurDTO> {
    return await super.typedPostWithDifferentResultTypeV2(
      "/authenticate/resend-validation-email",
      2,
      mail
    )
  }

  /**
   * Tries to get the previously logged user from DB cache and emits an event in one found.
   */
  async retrieveLoggedUserFromDB(forceCacheInvalidation?: boolean): Promise<void> {
    if (!this.isRetrievingUser) {
      this.isRetrievingUser = true
      const loggedUserString = localStorage.getItem(LoginService.LOCAL_STORAGE_LOGGUED_USER)
      if (loggedUserString) {
        const lastlyLoggedUser = JSON.parse(loggedUserString)
        if (
          lastlyLoggedUser != undefined &&
          (forceCacheInvalidation ||
            this.loggedUser == undefined ||
            this.loggedUser.courriel !== lastlyLoggedUser.courriel)
        ) {
          // Check if token is still valid by asking healthService
          const healthCheck = await HealthcheckService.INSTANCE.healthCheck()
          if (healthCheck.userConnected) {
            // If so, the user stored in db is considered as the current user
            this.loggedUser = lastlyLoggedUser
            this.emit(Events.LOGIN_STATUS_CHANGED)
          }
        }
      }
      this.isRetrievingUser = false
    }
  }

  /**
   * Returns the number of recent loggin attemps
   */
  private async getRecentLogginAttempsCount(): Promise<number> {
    const lastLoginAttemptString = localStorage.getItem(LoginService.LOCAL_STORAGE_LOGIN_ATTEMPTS)
    if (lastLoginAttemptString) {
      const lastLoginAttempt = JSON.parse(lastLoginAttemptString)
      if (
        lastLoginAttempt != undefined &&
        new Date().getTime() - lastLoginAttempt.lastAttemptDate < this.RECENT_ATTEMPT_DELAY
      ) {
        return lastLoginAttempt.count
      }
    }
    return 0
  }

  public getLastSuccessfullEmail(): string {
    const lastSuccessfullEmail = localStorage.getItem(
      LoginService.LOCAL_STORAGE_LAST_SUCCESSFULL_EMAIL
    )
    if (lastSuccessfullEmail) {
      return lastSuccessfullEmail
    }
    return ""
  }

  private async loginSucceeded(newLoggedUser: UtilisateurDTO): Promise<UtilisateurDTO> {
    // Reset login attempts count
    localStorage.removeItem(LoginService.LOCAL_STORAGE_LOGIN_ATTEMPTS)

    this.loggedUser = newLoggedUser

    localStorage.setItem(LoginService.LOCAL_STORAGE_LOGGUED_USER, JSON.stringify(this.loggedUser))
    localStorage.setItem(LoginService.LOCAL_STORAGE_LAST_SUCCESSFULL_EMAIL, newLoggedUser.courriel)
    this.emit(Events.LOGIN_STATUS_CHANGED)

    vueApp.$router.push(this.getPageToRedirectToAfterLogin(newLoggedUser))

    return Promise.resolve(this.loggedUser)
  }

  getPageToRedirectToAfterLogin(user?: UtilisateurDTO): string {
    const redirectURL: string = vueApp.$route.query?.redirect?.toString()
    if (redirectURL) {
      return redirectURL
    } else {
      if (user) {
        if (
          this.usersService.isPorteurProjet(user) &&
          !this.usersService.isAccompagnateurNationalOrAdmin(user)
        ) {
          return "/myProjects"
        } else if (
          this.usersService.isAccompagnateurNationalOrAdmin(user) ||
          this.usersService.isObservateurNational(user) ||
          (this.usersService.isAccompagnateurRegional(user) &&
            user.instructedGraes &&
            user.instructedGraes.length !== 0)
        ) {
          return "/grae-list"
        } else {
          return "/indicateurs"
        }
      }
    }
    return "/"
  }

  async invalidateLoggedUserCache(updatedProfile: UtilisateurDTO): Promise<void> {
    localStorage.setItem(LoginService.LOCAL_STORAGE_LOGGUED_USER, JSON.stringify(updatedProfile))
    await this.retrieveLoggedUserFromDB(true)
  }
}
