var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "columns skeleton" }, [
    _c("div", { staticClass: "column is-half is-offset-one-quarter" }, [
      _c("i", {
        staticClass: "fas fa-spinner fa-spin",
        attrs: { "aria-hidden": "true" }
      }),
      _c("br"),
      _c("article", { staticClass: "media" }, [
        _c("figure", { staticClass: "media-left" }, [
          _c(
            "p",
            { staticClass: "image is-64x64" },
            [
              _c("b-skeleton", {
                attrs: { circle: "", width: "64px", height: "64px" }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "media-content" }, [
          _c("div", { staticClass: "content" }, [
            _c(
              "p",
              [
                _vm._v(" " + _vm._s(_vm.loadingText)),
                _c("br"),
                _c("b-skeleton", { attrs: { active: "" } }),
                _c("b-skeleton", { attrs: { height: "80px" } })
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }