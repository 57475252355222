import { UtilisateurDTO } from "@/model/bean/GeneratedDTOs"

/*-
 * #%L
 * Aires Éducatives
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
export default class ObfuscationUtils {
  static hasRightToSeeCourriel(user: UtilisateurDTO, loggedUser: UtilisateurDTO): boolean {
    return (
      user.statutActivation === "ACTIF" &&
      (loggedUser.profil === "ANIMATEUR_REGIONAL" ||
        loggedUser.profil === "ACCOMPAGNATEUR_REGIONAL" ||
        loggedUser.profil === "ACCOMPAGNATEUR_NATIONAL" ||
        loggedUser.profil === "OBSERVATEUR_NATIONAL" ||
        loggedUser.profil === "SUPER_ADMINISTRATEUR")
    )
  }
  static obfuscateCourriel(courriel: string): string {
    let result = ""
    if (courriel) {
      const parts = courriel.split(/[-.@]/)
      parts.forEach((part) => {
        if (part.length > 3) {
          result += part.substring(0, 3)
          result += "*".repeat(part.length - 3)
        } else {
          result += part.substring(0, part.length)
        }
        if (courriel.length > result.length + 1) {
          result += courriel.substring(result.length, result.length + 1)
        }
      })
    }
    return result
  }
}
