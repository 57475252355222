































































import { Component, Prop, Vue } from "vue-property-decorator"
import { ProjetAnnuelDTO, UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import { ProjectService } from "@/services/rest/project/ProjectService"
import RouterUtils from "@/router/RouterUtils"

const PROJECT_STATE_STRINGS = {
  EN_COURS_DE_CREATION: "en cours de création",
  ACTIF: "active",
  EN_PAUSE: "paused",
  ARCHIVE: "stopped",
}

const PROJECT_NATURE_STRINGS = {
  ATE: "ate",
  AME: "ame",
  UNDEFINED: "undefined",
}

@Component
export default class ProjectCard extends Vue {
  @Prop() project: ProjetAnnuelDTO
  @Prop() loggedUser: UtilisateurDTO

  private infoReportingService = InfoReportingService.INSTANCE
  private projectService = ProjectService.INSTANCE

  projectState = ""
  projectNature = ""

  created(): void {
    if (this.project && this.project.etat) {
      this.projectState = PROJECT_STATE_STRINGS[this.project.etat]
      this.projectNature = PROJECT_NATURE_STRINGS[this.project.natureAireEducative] || "undefined"
    }
  }

  deleteProject(e: Event): void {
    e.stopPropagation()
    this.infoReportingService.dialogWithCancel(
      this.$t("ProjectCard.archive-popup.confirmation").toString(),
      this.$t("ProjectCard.archive-popup.are-you-sure").toString(),
      this.$t("ProjectCard.archive-popup.confirm").toString(),
      () => {
        this.projectService.deleteProject(this.project.projetId).then(() => {
          this.$emit("delete-project", this.project)
        })
      },
      () => {
        //do nothing
      },
      "is-danger"
    )
  }

  redirectToProjectDetails(): void {
    this.$router.push(RouterUtils.getRouteForProjectDetails(this.project.projetId, this.project.id))
  }
}
