




































































import { Component, Vue } from "vue-property-decorator"
import TitleView from "@/views/commons/TitleView.vue"
import PageView from "@/views/commons/PageView.vue"
import ContentView from "@/views/commons/ContentView.vue"
import DatePickerWithValidation from "@/views/inputs/DatePickerWithValidation.vue"
import { ParametrageDTO } from "@/model/bean/GeneratedDTOs"
import { ParametersService } from "@/services/rest/parameters/ParametersService"
import { LoginService } from "@/services/rest/login/LoginService"
import { UsersService } from "@/services/rest/users/UsersService"
import { ValidationObserver } from "vee-validate"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import H3TitleView from "@/views/commons/H3TitleView.vue"

@Component({
  components: {
    TitleView,
    PageView,
    ContentView,
    DatePickerWithValidation,
    ValidationObserver,
    InputWithValidation,
    H3TitleView,
  },
})
export default class ParametersPage extends Vue {
  private parametersService = ParametersService.INSTANCE
  private infoReportingService = InfoReportingService.INSTANCE
  private loginService = LoginService.INSTANCE
  private usersService = UsersService.INSTANCE

  isObservateurNational = false

  debutAnneeScolaire = ""
  parametrage: ParametrageDTO = new ParametrageDTO()

  created(): void {
    this.init()

    const loggedUser = this.loginService.getLoggedUser()
    if (loggedUser) {
      this.isObservateurNational = this.usersService.isObservateurNational(loggedUser)
    }
  }

  async init(): Promise<void> {
    try {
      this.parametrage = await this.parametersService.getParametrage()
      this.debutAnneeScolaire = `${this.parametrage.jourDebutAnneeScolaire} ${this.parametrage.moisDebutAnneeScolaire}`
    } catch (e) {
      console.warn("[WARNING] ", e)
    }
  }

  async submit(): Promise<void> {
    try {
      await this.parametersService.createParametrage(this.parametrage)
      this.infoReportingService.success(this.$i18n.t("ParametersPage.modification-ok").toString())
    } catch (e) {
      this.infoReportingService.error(
        this.$i18n.t("ParametersPage.modification-error").toString(),
        e
      )
    }
  }
}
