var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BookmarkCard", {
    attrs: { tagType: _vm.tagType, iconName: "paper-plane" },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function() {
          return [
            _vm.project.etablissements[0]
              ? _c("div", { staticClass: "PendingProjectCard-text" }, [
                  _vm._v(" " + _vm._s(_vm.project.etablissements[0].nom) + " ")
                ])
              : _vm._e(),
            _c("div", { staticClass: "PendingProjectCard-text" }, [
              _vm._v(_vm._s(_vm.project.natureAireEducative))
            ]),
            _c("div", { staticClass: "PendingProjectCard-text name" }, [
              _vm._v(_vm._s(_vm.project.nom))
            ])
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _vm.invitationStatus === "pending"
              ? _c(
                  "div",
                  { staticClass: "buttons PendingProjectCard-buttons" },
                  [
                    _c("b-button", {
                      attrs: {
                        type: "is-danger",
                        "icon-left": "trash-alt",
                        rounded: "",
                        outlined: ""
                      },
                      on: { click: _vm.declineInvitation }
                    }),
                    _c(
                      "b-button",
                      {
                        attrs: { type: "is-primary", rounded: "" },
                        on: { click: _vm.acceptInvitation }
                      },
                      [_vm._v(_vm._s(_vm.$t("accept")))]
                    )
                  ],
                  1
                )
              : _vm.invitationStatus === "accepted"
              ? _c(
                  "div",
                  { staticClass: "PendingProjectCard-mention" },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("myProjects.accepted")) + " "),
                    _c("b-icon", {
                      attrs: {
                        icon: "check-circle",
                        size: "is-large",
                        type: "is-success"
                      }
                    })
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "PendingProjectCard-mention declined" },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("myProjects.declined")) + " "),
                    _c("b-icon", {
                      attrs: {
                        icon: "times-circle",
                        size: "is-large",
                        type: "is-danger"
                      }
                    })
                  ],
                  1
                )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }