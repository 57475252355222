
































































import { ProjetInscriptionDTO } from "@/model/bean/GeneratedDTOs"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import RadioWithInputAndValidation from "@/views/inputs/RadioWithInputAndValidation.vue"
import GroupCheckboxesWithValidation from "@/views/inputs/GroupCheckBoxesWithValidation.vue"
import { TRILEAN } from "@/model/bean/EnumHelper"
import i18n from "@/i18n"
import { BOOLEAN } from "@/model/bean/EnumHelper"

const EDD_STRINGS = {
  E3D: i18n.t("projet.inscription.vue-ensemble.edd.e3d").toString(),
  ECO_ECOLE: i18n.t("projet.inscription.vue-ensemble.edd.eco_ecole").toString(),
  AUTRE: i18n.t("projet.inscription.vue-ensemble.edd.autre").toString(),
}

@Component({
  components: { InputWithValidation, RadioWithInputAndValidation, GroupCheckboxesWithValidation },
})
export default class ProjetAutresInfosView extends Vue {
  @Prop() projetAnnuel: ProjetInscriptionDTO
  @Prop() readonly: boolean
  @Prop({ default: true }) withTitle: boolean
  @Prop({ default: false }) validationActivated: boolean
  @Prop({ default: true }) showValidationErrors: boolean
  @Prop({ default: "autresinfos" }) validationGroup: string

  trileanValue = Object.values(TRILEAN)
  trileanValueLabels = this.trileanValue.map((value) => {
    return this.$t(`projet.inscription.vue-ensemble.projet_ecole.${value}`).toString()
  })

  eddAllPossibleValues = Object.values(EDD_STRINGS).filter((value) => value !== EDD_STRINGS.AUTRE)
  eddSelectedValues: string[] = []

  booleanValue = Object.values(BOOLEAN)
  booleanValueLabels = Object.keys(BOOLEAN).map((key) => {
    return this.$t(`projetPage.labellisation.suivi.${key.toLowerCase()}`).toString()
  })
  created(): void {
    this.projetAnnuelChanged()
  }

  @Watch("projetAnnuel")
  projetAnnuelChanged(): void {
    // Refresh eddSelectedValues according to project state
    this.eddSelectedValues = []
    if (this.projetAnnuel.contexteInscritE3d) {
      this.eddSelectedValues.push(EDD_STRINGS.E3D)
    }
    if (this.projetAnnuel.contexteInscritEcoecole) {
      this.eddSelectedValues.push(EDD_STRINGS.ECO_ECOLE)
    }
    if (this.projetAnnuel.contexteInscritAutre) {
      this.eddSelectedValues.push(EDD_STRINGS.AUTRE)
    }
  }

  eddCheckboxChanged(): void {
    // Modify project according to eddSelectedValues
    this.projetAnnuel.contexteInscritE3d = this.eddSelectedValues.includes(EDD_STRINGS.E3D)
    this.projetAnnuel.contexteInscritEcoecole = this.eddSelectedValues.includes(
      EDD_STRINGS.ECO_ECOLE
    )
    if (!this.eddSelectedValues.includes(EDD_STRINGS.AUTRE)) {
      this.projetAnnuel.contexteInscritAutre = ""
    }
    this.$emit("dirtify-project")
  }

  eddAutreValueChanged(value: string): void {
    this.projetAnnuel.contexteInscritAutre = value
    this.$emit("dirtify-project")
  }
}
