/*-
 * #%L
 * Aires Éducatives
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
import { AbstractRestService } from "@/services/rest/AbstractRestService"

/* Abstract REST Service providing facilities for handling GET/POST/PUT/DELETE requests on a given class.
 * You can still use generic get, put, post, delete methods to get other typed elements.
 */
export abstract class AbstractTypedRestService<T> extends AbstractRestService {
  /**
   * Performs a GET request at given url with given (optionnal) GET params.  Expect an array of T or a single T as result.
   * @param urlPath the URL to perform a GET request on
   * @param params (optionnal) GET params
   * @returns a promise that will only be resolves if responses is OK and of expect type (rejected in all other cases)
   */
  async getV2(
    urlPath: string,
    params: Map<string, string> = new Map<string, string>()
  ): Promise<T[]> {
    return super.typedGetV2<T>(urlPath, 2, params)
  }

  /**
   * Performs a POST request at given url posting the given T. Expect an array of T or a single T as result.
   * @param urlPath the URL to perform a GET request on
   * @param data the T to post
   * @returns a promise that will only be resolves if responses is OK and of expect type (rejected in all other cases)
   */
  async postV2(urlPath: string, data: T): Promise<T> {
    return super.typedPostV2<T>(urlPath, 2, data)
  }

  /**
   * Performs a PUT request at given url puting the given T. Expect an array of T or a single T as result.
   * @param urlPath the URL to perform a GET request on
   * @param data the T to put
   * @returns a promise that will only be resolves if responses is OK and of expect type (rejected in all other cases)
   */
  async putV2(urlPath: string, data: T): Promise<T> {
    return super.typedPutV2<T>(urlPath, 2, data)
  }

  /**
   * Performs a DELETE request at given url.  Expect an array of T or a single T as result.
   * @param urlPath the URL to perform a DELETE request on
   * @returns a promise that will only be resolves if responses is OK and of expect type (rejected in all other cases)
   */
  async deleteV2(urlPath: string): Promise<T[]> {
    return super.typedDeleteV2<T>(urlPath, 2)
  }

  /**
   * Sames as get() method, but expects exactly one T as result. Will fail in all other cases.
   * @param urlPath the URL to perform a GET request on
   * @returns a promise that will only be resolves if responses is OK and contains exactly one T
   */
  async getSingleV2(
    urlPath: string,
    params: Map<string, string> = new Map<string, string>()
  ): Promise<T> {
    return super.typedGetSingleV2<T>(urlPath, 2, params)
  }
}
