var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PageView",
    {
      attrs: {
        mainTitle: _vm.$t("404-page-not-found"),
        noSubTitleExpected: "true"
      }
    },
    [
      _c("ContentView", [
        _vm._v(" " + _vm._s(_vm.$t("404-page-not-found-details")) + " ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }