var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BookmarkCard", {
    attrs: { tagType: _vm.tagType, iconName: "comment-alt" },
    scopedSlots: _vm._u(
      [
        {
          key: "content",
          fn: function() {
            return [_vm._t("default")]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }