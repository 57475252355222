












































































// Work around missing icons
// See https://vue2-leaflet.netlify.app/quickstart/#marker-icons-are-missing
import { latLng, Icon, FeatureGroup, LatLng, LatLngBounds, canvas } from "leaflet"
import "leaflet"
import "leaflet-draw"
type D = Icon.Default & {
  _getIconUrl?: string
}
delete (Icon.Default.prototype as D)._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
})
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"

import TitleView from "@/views/commons/TitleView.vue"
import { EtablissementDTO } from "@/model/bean/GeneratedDTOs"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import InputWithValidation from "../inputs/InputWithValidation.vue"
import SelectWithValidation from "../inputs/SelectWithValidation.vue"
import i18n from "@/i18n"
import * as leafletImage from "leaflet-image"

const TILE_URLS = {
  OPEN_STREET_MAP: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  SATELLITE:
    "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
}

const TILE_CHOICES = {
  AUTO: i18n.t("mapDraw.tile-choices.auto"),
  SATELLITE: i18n.t("mapDraw.tile-choices.pref-satellite"),
  OSM: i18n.t("mapDraw.tile-choices.pref-osm"),
}

@Component({
  components: {
    TitleView,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    InputWithValidation,
    SelectWithValidation,
  },
})
export default class MapDrawView extends Vue {
  @Prop({}) zoneGeographique: string
  @Prop({}) etablissement: EtablissementDTO
  @Prop() mapHasFocus: boolean
  @Prop({ default: "" }) group: string
  @Prop() readonly: boolean
  @Prop() mapScreenShotRequested: boolean
  @Prop({ default: false }) validationActivated: boolean
  @Prop({ default: true }) showValidationErrors: boolean
  mapVisible = false

  private infoReportingService = InfoReportingService.INSTANCE

  markerLocation: LatLng
  // eslint-disable-next-line
  drawControl: any
  // eslint-disable-next-line
  drawControlEditOnly: any
  drawnItems: FeatureGroup = new window.L.FeatureGroup()
  currentZoom = 5
  tileURL = TILE_URLS.SATELLITE

  tileChoicesLabels = Object.values(TILE_CHOICES)
  tileChoice = TILE_CHOICES.AUTO

  innerZoneGeographique = ""

  created(): void {
    if (this.zoneGeographique && this.zoneGeographique !== "null") {
      this.innerZoneGeographique = this.zoneGeographique
    }
    this.setMarkerLocation()
    this.revealMapOnlyIfNeeded(this.mapHasFocus)
  }

  @Watch("mapHasFocus")
  mapFocusChanged(newFocus: boolean, _oldFocus: boolean): void {
    this.revealMapOnlyIfNeeded(newFocus)
  }

  @Watch("zoneGeographique")
  onExternalZoneChanged(): void {
    this.innerZoneGeographique = this.zoneGeographique === "null" ? "" : this.zoneGeographique
  }

  @Watch("etablissement")
  onEtablissementChanged(): void {
    this.setMarkerLocation()
  }

  @Watch("tileChoice")
  onTileChoiceChanged(): void {
    this.updateTileUrl()
  }

  setMarkerLocation(): void {
    if (this.etablissement && this.etablissement.latitude && this.etablissement.longitude) {
      this.markerLocation = latLng(
        parseFloat(this.etablissement.latitude),
        parseFloat(this.etablissement.longitude)
      )
    } else {
      this.markerLocation = latLng(0, 0)
    }
  }

  onZoneChanged(): void {
    this.$emit("zone-update", this.innerZoneGeographique)
  }

  /**
   * Method called when map is ready
   */
  mapIsReady(): void {
    // We ts ignore the next ligne as leaflet is not strongly typed
    // @ts-ignore
    let map = this.$refs.map.mapObject
    this.currentZoom = map.getZoom()
    var bounds = new LatLngBounds([])
    if (this.innerZoneGeographique) {
      const zoneGeoParse = JSON.parse(this.innerZoneGeographique)
      if (zoneGeoParse.geometry && zoneGeoParse.geometry.coordinates) {
        zoneGeoParse.geometry.coordinates.forEach((geoCoord: any) => {
          for (let i = 0; i < geoCoord.length; i++) {
            bounds.extend([geoCoord[i][1], geoCoord[i][0]])
          }
        })
      }
      var canvasRenderer = canvas({ padding: 0.5 })
      // @ts-ignore
      this.drawnItems = window.L.geoJSON(zoneGeoParse, { renderer: canvasRenderer })
    } else {
      // @ts-ignore
      this.drawnItems = new window.L.FeatureGroup([], { renderer: canvasRenderer })
    }
    this.drawnItems.bindTooltip(
      this.$t("mapDraw.aire-educative-tooltip").toString() + this.etablissement.nom,
      {
        sticky: true, // If true, the tooltip will follow the mouse instead of being fixed at the feature center.
      }
    )
    map.addLayer(this.drawnItems)

    // Change the language of labels and tooltips
    this.modifyLocale()
    map.zoomControl.remove()
    window.L.control
      .zoom({
        zoomInTitle: this.$t("mapDraw.zoomin").toString(),
        zoomOutTitle: this.$t("mapDraw.zoomout").toString(),
      })
      .addTo(map)

    // Add draw control to map
    // @ts-ignore
    this.drawControl = new window.L.Control.Draw({
      position: "topleft",
      draw: {
        polyline: false,
        polygon: true,
        rectangle: false,
        circle: false,
        marker: false,
        circlemarker: false,
      },
    })

    // @ts-ignore
    this.drawControlEditOnly = this.getEditControl()

    if (!this.readonly) {
      map.addControl(this.innerZoneGeographique ? this.drawControlEditOnly : this.drawControl)
    }

    map.on("zoomend", () => this.updateZoom(map))

    // Center map
    console.log("centré 0")
    let padding = 4
    let hasValidBounds = true
    if (!bounds.isValid()) {
      if (this.markerLocation.lat != 0 && this.markerLocation.lng != 0) {
        bounds.extend(this.markerLocation)
      } else {
        hasValidBounds = false
      }
    }
    if (hasValidBounds) {
      console.log("***** centré sur 1")
      map.fitBounds(bounds, { padding: [padding, padding] })
    } else {
      map.panTo(latLng(46.227, 2.213), 2)
    }
    //check for map screenshot after first render
    this.createMapScreenshot()
  }

  // eslint-disable-next-line
  updateZoom(map: any) {
    this.currentZoom = map.getZoom()
    this.updateTileUrl()
  }

  updateTileUrl(): void {
    if (this.tileChoice === TILE_CHOICES.SATELLITE) {
      this.tileURL = TILE_URLS.SATELLITE
    } else if (this.tileChoice === TILE_CHOICES.OSM) {
      this.tileURL = TILE_URLS.OPEN_STREET_MAP
    } else {
      this.tileURL = this.currentZoom < 14 ? TILE_URLS.OPEN_STREET_MAP : TILE_URLS.SATELLITE
    }
  }

  // eslint-disable-next-line
  getEditControl(): any {
    //@ts-ignore
    window.L.EditToolbar.Delete.include({
      enable: () => this.onDelete(),
    })

    //@ts-ignore
    return new window.L.Control.Draw({
      edit: {
        featureGroup: this.drawnItems,
      },
      draw: false,
    })
  }

  // eslint-disable-next-line
  stopDraw(e: any): void {
    console.log("stopDraw")
    const type = e.layerType
    if (type === "polygon") {
      this.updateZoneGeographique(e)
    }
  }

  // eslint-disable-next-line
  updateZoneGeographique(e: any): void {
    console.log("updateZoneGeographique")
    const layers = e.target._layers

    // eslint-disable-next-line
    Object.values(layers).forEach((element: any) => {
      if (element._latlngs && element._latlngs.length !== 0) {
        const geoJsonElement = element.toGeoJSON()
        this.innerZoneGeographique = JSON.stringify(geoJsonElement)
        // @ts-ignore
        let map = this.$refs.map.mapObject

        // @ts-ignore
        element
          .bindTooltip("Aire éducative de : " + this.etablissement.nom, {
            sticky: true, // If true, the tooltip will follow the mouse instead of being fixed at the feature center.
          })
          .addTo(map)
        this.onZoneChanged()
      }
    })
  }

  onDelete(): void {
    this.infoReportingService.dialog(
      this.$t("usersList.confirmation").toString(),
      this.$t("mapDraw.delete-draw").toString(),
      this.$t("mapDraw.delete").toString(),
      () => this.deleteAireAndEnableDrawControl(),
      "is-warning"
    )
  }

  deleteAireAndEnableDrawControl(): void {
    this.refreshDrawnItems()

    // We ts ignore the next ligne as leaflet is not strongly typed
    // @ts-ignore
    let map = this.$refs.map.mapObject

    if (this.drawnItems.getLayers().length === 0) {
      // When the previous polygon has been removed we allow to draw a new one
      map.removeControl(this.drawControlEditOnly)
      map.addControl(this.drawControl)
      this.innerZoneGeographique = ""
      this.onZoneChanged()
    }
  }

  refreshDrawnItems(): void {
    // We ts ignore the next ligne as leaflet is not strongly typed
    // @ts-ignore
    let map = this.$refs.map.mapObject

    map.removeLayer(this.drawnItems)
    this.drawnItems = new window.L.FeatureGroup()
    map.addLayer(this.drawnItems)
  }

  /**
   * This method purpose is to enable the "only one polygon drawn" feature
   */
  // eslint-disable-next-line
  enableEditPolygon(e: any): void {
    console.log("enableEditPolygon")
    // We ts ignore the next ligne as leaflet is not strongly typed
    // @ts-ignore
    let map = this.$refs.map.mapObject
    // When a polygon has been drawn we disabled the draw control and enable the edit control
    this.drawnItems.addLayer(e.layer)
    map.removeControl(this.drawControl)
    // the drawnItems have changed when removing the previous one
    this.refreshDrawnControlEditOnly()
  }

  refreshDrawnControlEditOnly(): void {
    // @ts-ignore
    let map = this.$refs.map.mapObject

    // @ts-ignore
    this.drawControlEditOnly = this.getEditControl()
    map.addControl(this.drawControlEditOnly)
  }

  modifyLocale(): void {
    // @ts-ignore
    window.L.drawLocal = {
      draw: {
        toolbar: {
          // #TODO: this should be reorganized where actions are nested in actions
          // ex: actions.undo  or actions.cancel
          actions: {
            title: this.$t("mapDraw.toolbar.annuler-title"),
            text: this.$t("annuler"),
          },
          finish: {
            title: this.$t("mapDraw.toolbar.finir-title"),
            text: this.$t("mapDraw.toolbar.finir-text"),
          },
          undo: {
            title: this.$t("mapDraw.toolbar.supprimer-title"),
            text: this.$t("mapDraw.toolbar.supprimer-text"),
          },
          buttons: {
            polyline: this.$t("mapDraw.toolbar.polyline"),
            rectangle: this.$t("mapDraw.toolbar.rectangle"),
            circle: this.$t("mapDraw.toolbar.circle"),
            circlemarker: this.$t("mapDraw.toolbar.circlemarker"),
            polygon: this.$t("mapDraw.toolbar.polygon"),
            marker: this.$t("mapDraw.toolbar.marker"),
          },
        },
        handlers: {
          circle: {
            tooltip: {
              start: "Click and drag to draw circle.",
            },
            radius: "Radius",
          },
          circlemarker: {
            tooltip: {
              start: "Click map to place circle marker.",
            },
          },
          polyline: {
            error: "<strong>Error:</strong> shape edges cannot cross!",
            tooltip: {
              start: "Click to start drawing line.",
              cont: "Click to continue drawing line.",
              end: "Click last point to finish line.",
            },
          },
          rectangle: {
            tooltip: {
              start: "Click and drag to draw rectangle.",
            },
          },
          simpleshape: {
            tooltip: {
              end: "Release mouse to finish drawing.",
            },
          },
          marker: {
            tooltip: {
              start: this.$t("mapDraw.handlers.marker"),
            },
          },
          polygon: {
            tooltip: {
              start: this.$t("mapDraw.handlers.polygon-start"),
              cont: this.$t("mapDraw.handlers.polygon-cont"),
              end: this.$t("mapDraw.handlers.polygon-end"),
            },
          },
        },
      },
      edit: {
        toolbar: {
          actions: {
            save: {
              title: this.$t("mapDraw.edit-toolbar.save-title"),
              text: this.$t("mapDraw.edit-toolbar.save-text"),
            },
            cancel: {
              title: this.$t("mapDraw.edit-toolbar.cancel-title"),
              text: this.$t("annuler"),
            },
            clearAll: {
              title: this.$t("mapDraw.edit-toolbar.clearAll-title"),
              text: this.$t("mapDraw.edit-toolbar.clearAll-text"),
            },
          },
          buttons: {
            edit: this.$t("mapDraw.edit-toolbar.edit"),
            editDisabled: this.$t("mapDraw.edit-toolbar.editDisabled"),
            remove: this.$t("mapDraw.edit-toolbar.remove"),
            removeDisabled: this.$t("mapDraw.edit-toolbar.removeDisabled"),
          },
        },
        handlers: {
          edit: {
            tooltip: {
              text: this.$t("mapDraw.edit-handlers.edit-tooltip-text"),
              subtext: this.$t("mapDraw.edit-handlers.edit-tooltip-subtext"),
            },
          },
          remove: {
            tooltip: {
              text: this.$t("mapDraw.edit-handlers.remove-tooltip-text"),
            },
          },
        },
      },
    }
  }

  revealMapOnlyIfNeeded(reveal: boolean): void {
    if (!this.mapVisible && reveal) {
      this.$nextTick(() => {
        this.mapVisible = true
      })
    }
  }

  @Watch("mapScreenShotRequested")
  createMapScreenshot(): void {
    //@ts-ignore
    let map = this.$refs.map.mapObject
    if (this.mapScreenShotRequested) {
      console.info("Map Screenshot request...")
      leafletImage(map, (err: any, canvas: any) => {
        let dataURL
        if (!err) {
          dataURL = canvas.toDataURL()
          // const mapScreenshot = document.getElementById("map-screenshot")
          // mapScreenshot?.setAttribute("src", dataURL)
        } else {
          console.error("Error while taking map screenshot")
        }
        this.$emit("map-screenshot-done", dataURL)
      })
    }
  }
}
