var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "projet-part" }, [
    _c("h2", [_vm._v(_vm._s(_vm.$t("projetPage.mon-annee.diffusion.title")))]),
    _c(
      "fieldset",
      [
        _c("legend", [
          _vm._v(_vm._s(_vm.$t("projetPage.mon-annee.diffusion.ecole")))
        ]),
        _c("InputWithValidation", {
          attrs: {
            validationActivated: _vm.validationActivated,
            showValidationErrors: _vm.showValidationErrors,
            type: "textarea",
            label: _vm.$t("projetPage.mon-annee.diffusion.passage"),
            inputNotice: _vm.$t("projetPage.mon-annee.diffusion.passage-help"),
            maxlength: "1400",
            disabled: _vm.readonly || !_vm.isPorteurProjet,
            vid: "diffusion_descriptionPassageFlambeau"
          },
          on: {
            "manual-change": function($event) {
              return _vm.modified()
            }
          },
          model: {
            value: _vm.projetAnnuel.descriptionPassageFlambeau,
            callback: function($$v) {
              _vm.$set(_vm.projetAnnuel, "descriptionPassageFlambeau", $$v)
            },
            expression: "projetAnnuel.descriptionPassageFlambeau"
          }
        }),
        _c("InputWithValidation", {
          attrs: {
            validationActivated: _vm.validationActivated,
            showValidationErrors: _vm.showValidationErrors,
            rules:
              _vm.projetAnnuel.typeDossier === "DOSSIER_RENOUVELLEMENT_LIGHT"
                ? ""
                : "required",
            type: "textarea",
            label: _vm.$t("projetPage.mon-annee.diffusion.communication"),
            maxlength: "1400",
            disabled: _vm.readonly || !_vm.isPorteurProjet,
            vid: "diffusion_communicationAutresClasses"
          },
          on: {
            "manual-change": function($event) {
              return _vm.modified()
            }
          },
          model: {
            value: _vm.projetAnnuel.communicationAutresClasses,
            callback: function($$v) {
              _vm.$set(_vm.projetAnnuel, "communicationAutresClasses", $$v)
            },
            expression: "projetAnnuel.communicationAutresClasses"
          }
        })
      ],
      1
    ),
    _c(
      "fieldset",
      [
        _c("legend", [
          _vm._v(_vm._s(_vm.$t("projetPage.mon-annee.diffusion.territoire")))
        ]),
        _c("InputWithValidation", {
          attrs: {
            rules:
              _vm.projetAnnuel.typeDossier === "DOSSIER_RENOUVELLEMENT"
                ? "required"
                : "",
            validationActivated: _vm.validationActivated,
            showValidationErrors: _vm.showValidationErrors,
            type: "textarea",
            label: _vm.$t("projetPage.mon-annee.diffusion.presentation-elus"),
            maxlength: "1400",
            disabled: _vm.readonly || !_vm.isPorteurProjet,
            vid: "diffusion_presentationProjetExterieur"
          },
          on: {
            "manual-change": function($event) {
              return _vm.modified()
            }
          },
          model: {
            value: _vm.projetAnnuel.presentationProjetElus,
            callback: function($$v) {
              _vm.$set(_vm.projetAnnuel, "presentationProjetElus", $$v)
            },
            expression: "projetAnnuel.presentationProjetElus"
          }
        }),
        _c("InputWithValidation", {
          attrs: {
            validationActivated: _vm.validationActivated,
            showValidationErrors: _vm.showValidationErrors,
            rules:
              _vm.projetAnnuel.typeDossier === "DOSSIER_RENOUVELLEMENT"
                ? "required"
                : "",
            type: "textarea",
            label: _vm.$t("projetPage.mon-annee.diffusion.rencontre"),
            inputNotice: _vm.$t(
              "projetPage.mon-annee.diffusion.rencontre-message"
            ),
            maxlength: "1400",
            disabled: _vm.readonly || !_vm.isPorteurProjet,
            vid: "diffusion_rencontresActeursTerritoire"
          },
          on: {
            "manual-change": function($event) {
              return _vm.modified()
            }
          },
          model: {
            value: _vm.projetAnnuel.rencontresActeursTerritoire,
            callback: function($$v) {
              _vm.$set(_vm.projetAnnuel, "rencontresActeursTerritoire", $$v)
            },
            expression: "projetAnnuel.rencontresActeursTerritoire"
          }
        }),
        _c("FileWithValidation", {
          attrs: {
            validationActivated: _vm.validationActivated,
            showValidationErrors: _vm.showValidationErrors,
            placeholder: _vm.$t(
              "projetPage.infoAdministratives.charte.televerser"
            ),
            inputNotice: _vm.$t("projetPage.mon-annee.diffusion.fichier"),
            fileOperationUrl: _vm.adhesionDiffusionFileUrl,
            disabled: _vm.readonly,
            value: _vm.projetAnnuel.adhesionDiffusionFilePresent,
            defaultFileName: "ADHESION_DIFFUSION.pdf"
          },
          on: {
            "add-file": _vm.addAdhesionDiffusionFile,
            "delete-file": _vm.deleteAdhesionDiffusionFile
          }
        })
      ],
      1
    ),
    _c(
      "fieldset",
      [
        _c("legend", [
          _vm._v(_vm._s(_vm.$t("projetPage.mon-annee.diffusion.ae")))
        ]),
        _c("InputWithValidation", {
          attrs: {
            validationActivated: _vm.validationActivated,
            showValidationErrors: _vm.showValidationErrors,
            type: "textarea",
            label: _vm.$t("projetPage.mon-annee.diffusion.echanges"),
            maxlength: "1400",
            disabled: _vm.readonly || !_vm.isPorteurProjet,
            vid: "diffusion_organisationEchangesInterAe"
          },
          on: {
            "manual-change": function($event) {
              return _vm.modified()
            }
          },
          model: {
            value: _vm.projetAnnuel.organisationEchangesInterAe,
            callback: function($$v) {
              _vm.$set(_vm.projetAnnuel, "organisationEchangesInterAe", $$v)
            },
            expression: "projetAnnuel.organisationEchangesInterAe"
          }
        }),
        _c("FileWithValidation", {
          attrs: {
            validationActivated: _vm.validationActivated,
            showValidationErrors: _vm.showValidationErrors,
            placeholder: _vm.$t(
              "projetPage.infoAdministratives.charte.televerser"
            ),
            inputNotice: _vm.$t(
              "projetPage.mon-annee.diffusion.fichier-interae"
            ),
            fileOperationUrl: _vm.organisationEchangesInterAeFileUrl,
            disabled: _vm.readonly,
            value: _vm.projetAnnuel.organisationEchangesInterAePresent,
            defaultFileName: "ECHANGES_INTER_AE.pdf"
          },
          on: {
            "add-file": _vm.addInterAeDiffusionFile,
            "delete-file": _vm.deleteInterAeDiffusionFile
          }
        })
      ],
      1
    ),
    _c(
      "fieldset",
      [
        _c("legend", [
          _vm._v(_vm._s(_vm.$t("projetPage.mon-annee.diffusion.enseignements")))
        ]),
        _vm.validationActivated &&
        (_vm.projetAnnuel.typeDossier === "DOSSIER_LABELLISATION" ||
          _vm.projetAnnuel.typeDossier === "DOSSIER_RENOUVELLEMENT") &&
        _vm.showRequiredMessage
          ? _c("b-message", { attrs: { type: "is-danger" } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "projetPage.mon-annee.autres-infos.labellisation-renouvellement"
                    )
                  ) +
                  " "
              )
            ])
          : _vm._e(),
        _c("InputWithValidation", {
          attrs: {
            validationActivated: _vm.validationActivated,
            showValidationErrors: _vm.showValidationErrors,
            type: "textarea",
            label: _vm.$t("projetPage.mon-annee.diffusion.liens-enseignements"),
            rules:
              _vm.projetAnnuel.typeDossier === "DOSSIER_RENOUVELLEMENT_LIGHT"
                ? ""
                : _vm.calculatedRules,
            maxlength: "1400",
            disabled: _vm.readonly || !_vm.isPorteurProjet,
            vid: "diffusion_diffusionLiensEnseignements"
          },
          on: {
            "manual-change": function($event) {
              return _vm.modifiedLiens()
            }
          },
          model: {
            value: _vm.projetAnnuel.diffusionLiensEnseignements,
            callback: function($$v) {
              _vm.$set(_vm.projetAnnuel, "diffusionLiensEnseignements", $$v)
            },
            expression: "projetAnnuel.diffusionLiensEnseignements"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }