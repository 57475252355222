var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.project.statut != "BROUILLON"
      ? _c(
          "div",
          {
            staticClass: "onlyDisplayedifSectionIsActive",
            class: { activeSection: _vm.section == "historique" }
          },
          [
            _c(
              "div",
              { staticClass: "projet-part" },
              [
                _c("ProjetHistoryView", {
                  attrs: {
                    role: _vm.role,
                    projetAnnuel: _vm.project,
                    isPorteurProjet: _vm.isPorteurProjet
                  }
                })
              ],
              1
            )
          ]
        )
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "onlyDisplayedifSectionIsActive",
        class: { activeSection: _vm.section != "historique" }
      },
      [
        _c("div", { staticClass: "anchor", attrs: { id: "ensemble" } }),
        _c("ProjetInscriptionVueEnsemble", {
          attrs: {
            projetAnnuel: _vm.project,
            showValidationErrors: _vm.showValidationErrors,
            readonly: _vm.readonly || !_vm.isPorteurProjet
          },
          on: {
            "dirtify-project": function($event) {
              return _vm.$emit("dirtify-project")
            }
          }
        }),
        _c("div", { staticClass: "anchor", attrs: { id: "participants" } }),
        _c("ProjetParticipants", {
          attrs: {
            projetAnnuel: _vm.project,
            readonly: _vm.readonly,
            isPorteurProjet: _vm.isPorteurProjet,
            showValidationErrors: _vm.showValidationErrors
          },
          on: {
            "dirtify-project": function($event) {
              return _vm.$emit("dirtify-project", $event)
            },
            "trigger-validation": function($event) {
              return _vm.$emit("trigger-validation")
            }
          }
        }),
        _c("div", { staticClass: "anchor", attrs: { id: "site" } }),
        _c("ProjetInscriptionSite", {
          attrs: {
            projetAnnuel: _vm.project,
            showValidationErrors: _vm.showValidationErrors,
            readonly: _vm.readonly || !_vm.isPorteurProjet
          },
          on: {
            "dirtify-project": function($event) {
              return _vm.$emit("dirtify-project")
            }
          }
        }),
        _c("div", { staticClass: "anchor", attrs: { id: "financements" } }),
        _c("ProjetFinancementView", {
          attrs: {
            projetAnnuel: _vm.project,
            readonly: _vm.readonly || !_vm.isPorteurProjet
          },
          on: {
            "dirtify-project": function($event) {
              return _vm.$emit("dirtify-project")
            }
          }
        }),
        _c("div", { staticClass: "anchor", attrs: { id: "finalisation" } }),
        _c("ProjetInscriptionFinalisation", {
          attrs: {
            validationActivated: _vm.validationActivated,
            projetAnnuel: _vm.project,
            showValidationErrors: _vm.showValidationErrors,
            readonly: _vm.readonly || !_vm.isPorteurProjet,
            projetValidation: _vm.lastProjectValidation
          },
          on: {
            "dirtify-project": function($event) {
              return _vm.$emit("dirtify-project")
            },
            "trigger-validation": function($event) {
              return _vm.$emit("trigger-validation")
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }