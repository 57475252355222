var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "projet-part" }, [
    _c("div", { staticClass: "presentation-container" }, [
      _c("div", { staticClass: "presentation-explanations" }, [
        _vm.projetAnnuel.typeDossier == "DOSSIER_LABELLISATION" ||
        _vm.projetAnnuel.typeDossier == "DOSSIER_INSCRIPTION"
          ? _c("h3", [
              _vm._v(
                " " + _vm._s(_vm.$t("projet.labellise.presentation")) + " "
              )
            ])
          : _vm._e(),
        _vm.projetAnnuel.typeDossier == "DOSSIER_RENOUVELLEMENT" ||
        _vm.projetAnnuel.typeDossier == "DOSSIER_RENOUVELLEMENT_LIGHT"
          ? _c("h3", [
              _vm._v(" " + _vm._s(_vm.$t("projet.labellise.renewal")) + " ")
            ])
          : _vm._e(),
        _c("div", { staticClass: "explanation-bloc" }, [
          _c("div", { staticClass: "explanation-bloc-text" }, [
            _c(
              "div",
              { staticClass: "toggle-validation" },
              [
                _c("div", { staticClass: "toggle-validation-text" }, [
                  !_vm.validationActivated
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.$t("projet.labellise.recap.help-on")))
                      ])
                    : _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("projet.labellise.recap.help-off"))
                        )
                      ])
                ]),
                _c("hr"),
                _c("b-switch", {
                  on: {
                    input: function($event) {
                      return _vm.$emit("toggle-validation")
                    }
                  },
                  model: {
                    value: _vm.validationActivatedInternal,
                    callback: function($$v) {
                      _vm.validationActivatedInternal = $$v
                    },
                    expression: "validationActivatedInternal"
                  }
                })
              ],
              1
            )
          ])
        ]),
        _vm.validationActivated
          ? _c(
              "div",
              { staticClass: "presentation-recap" },
              [
                _c("RecapCard", [
                  _vm.errorsPerLabel.size
                    ? _c("div", [
                        _c("span", { staticClass: "invalid-text" }, [
                          _vm._v(_vm._s(_vm.$t("projet.labellise.invalid")))
                        ]),
                        _c(
                          "ul",
                          _vm._l(_vm.errorsPerLabel.keys(), function(section) {
                            return _c(
                              "ul",
                              { key: section, staticClass: "section-in-error" },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "section-errors-link",
                                    on: {
                                      click: function($event) {
                                        return _vm.redirectToSection(section)
                                      }
                                    }
                                  },
                                  [
                                    _c("b-icon", {
                                      staticClass: "item-icon",
                                      attrs: {
                                        size: "is-normal",
                                        icon: _vm.sectionsToIcon.get(section)
                                      }
                                    }),
                                    !_vm.isPorteurProjet &&
                                    (section == "mon-annee" ||
                                      section == "labellise")
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "projet.menu." +
                                                    section +
                                                    "-accompagnateur"
                                                )
                                              )
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("projet.menu." + section)
                                            )
                                          )
                                        ])
                                  ],
                                  1
                                ),
                                _vm._l(
                                  Array.from(
                                    _vm.errorsPerLabel.get(section).keys()
                                  ).sort(_vm.compareSections),
                                  function(fieldWithError) {
                                    return _c(
                                      "li",
                                      {
                                        key: fieldWithError,
                                        staticClass: "field-in-error"
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "error-circle" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.errorsPerLabel
                                                    .get(section)
                                                    .get(fieldWithError)
                                                )
                                              )
                                            ])
                                          ]
                                        ),
                                        section != fieldWithError
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "projet." +
                                                        section +
                                                        "." +
                                                        fieldWithError
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "projet.labellise.section-invalid"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                      ]
                                    )
                                  }
                                )
                              ],
                              2
                            )
                          }),
                          0
                        )
                      ])
                    : _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("projet.labellise.all-valid")) +
                            " "
                        )
                      ])
                ])
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "explanation-bloc" }, [
          _c(
            "div",
            { staticClass: "explanation-bloc-pic" },
            [
              _c("b-icon", {
                attrs: {
                  type: "is-success",
                  size: "is-large",
                  icon: "exclamation-circle"
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "explanation-bloc-text" }, [
            _c("strong", [
              _vm._v(
                _vm._s(
                  _vm.$t("projet.labellise.recap.why-title" + _vm.renewalSuffix)
                ) + " "
              )
            ]),
            _c("p", {
              domProps: {
                innerHTML: _vm._s(_vm.$t("projet.labellise.recap.why-text"))
              }
            })
          ])
        ]),
        _c("div", { staticClass: "explanation-bloc" }, [
          _c(
            "div",
            { staticClass: "explanation-bloc-pic" },
            [
              _c("b-icon", {
                attrs: { type: "is-success", size: "is-large", icon: "smile" }
              })
            ],
            1
          ),
          _c("div", { staticClass: "explanation-bloc-text" }, [
            _c("strong", [
              _vm._v(_vm._s(_vm.$t("projet.labellise.recap.how-title")) + " ")
            ]),
            _c("p", {
              domProps: {
                innerHTML: _vm._s(_vm.$t("projet.labellise.recap.how-text"))
              }
            })
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }