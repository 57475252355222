var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "b-tabs",
        {
          class: "sticky-tabs-headers " + (_vm.lowerTab ? "lower" : ""),
          attrs: { animated: false },
          model: {
            value: _vm.currentTabIndex,
            callback: function($$v) {
              _vm.currentTabIndex = $$v
            },
            expression: "currentTabIndex"
          }
        },
        [
          _c(
            "b-tab-item",
            {
              attrs: { label: _vm.$t("profilePage.etablissement.identite") },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function() {
                    return [
                      _c("TabHeaderWithHint", {
                        attrs: {
                          label: _vm.$t("profilePage.etablissement.identite"),
                          tabValidationHint: _vm.tabValidationHints[0]
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "ValidationObserver",
                { ref: "observer-0" },
                [
                  _c("EtablissementIdentiteDetails", {
                    attrs: {
                      etablissement: _vm.etablissement,
                      userCanChange: _vm.userCanChange,
                      readonly: _vm.readonly,
                      validationGroup: _vm.validationGroup
                    },
                    on: {
                      "update-etablissement": _vm.updateEtablissement,
                      "change-etablissement": _vm.changeEtablissement
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-tab-item",
            {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function() {
                    return [
                      _c("TabHeaderWithHint", {
                        attrs: {
                          label: _vm.$t("profilePage.etablissement.contact"),
                          tabValidationHint: _vm.tabValidationHints[1]
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "ValidationObserver",
                { ref: "observer-1" },
                [
                  _c("EtablissementContactDetails", {
                    attrs: {
                      etablissement: _vm.etablissement,
                      userCanChange: _vm.userCanChange,
                      readonly: _vm.readonly,
                      validationGroup: _vm.validationGroup
                    },
                    on: { "update-etablissement": _vm.updateEtablissement }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-tab-item",
            {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function() {
                    return [
                      _c("TabHeaderWithHint", {
                        attrs: {
                          label: _vm.$t("profilePage.etablissement.chef"),
                          tabValidationHint: _vm.tabValidationHints[2]
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "ValidationObserver",
                { ref: "observer-2" },
                [
                  _c("EtablissementChefDetails", {
                    attrs: {
                      etablissement: _vm.etablissement,
                      userCanChange: _vm.userCanChange,
                      readonly: _vm.readonly,
                      validationGroup: _vm.validationGroup
                    },
                    on: { "update-etablissement": _vm.updateEtablissement }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-tab-item",
            {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function() {
                    return [
                      _c("TabHeaderWithHint", {
                        attrs: {
                          label: _vm.$t("profilePage.etablissement.siteWeb"),
                          tabValidationHint: _vm.tabValidationHints[3]
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "ValidationObserver",
                { ref: "observer-3" },
                [
                  _c("EtablissementSiteWebDetails", {
                    attrs: {
                      etablissement: _vm.etablissement,
                      userCanChange: _vm.userCanChange,
                      readonly: _vm.readonly,
                      validationGroup: _vm.validationGroup
                    },
                    on: { "update-etablissement": _vm.updateEtablissement }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }