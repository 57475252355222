


































import { Component, Prop, Vue } from "vue-property-decorator"

@Component({
  components: {},
})
export default class PitchModale extends Vue {
  @Prop() pitch: string
  @Prop() open: boolean

  emitCloseModale(): void {
    this.$emit("close-modale")
  }
}
