























import { Component, Vue } from "vue-property-decorator"
import { ValidationObserver } from "vee-validate"
import GroupCheckboxesWithValidation from "@/views/inputs/GroupCheckBoxesWithValidation.vue"
import { UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import i18n from "@/i18n"

const COMPETENCES_STRINGS = {
  PEDAGOGIE: i18n.t("profilePage.competences-referent.pedagogie").toString(),
  ECOLOGIE: i18n.t("profilePage.competences-referent.ecologie").toString(),
  AE: i18n.t("profilePage.competences-referent.ae").toString(),
}

@Component({
  components: {
    ValidationObserver,
    GroupCheckboxesWithValidation,
  },
})
export default class ReferentCompetencesView extends Vue {
  innerUtilisateur = new UtilisateurDTO()

  competencesStrings = Object.values(COMPETENCES_STRINGS)
  checkedCompetenceStrings: string[] = []

  changeCompetences(): void {
    this.innerUtilisateur.competencesReferentPedagogie = this.checkedCompetenceStrings.includes(
      COMPETENCES_STRINGS.PEDAGOGIE
    )
    this.innerUtilisateur.competencesReferentEcologie = this.checkedCompetenceStrings.includes(
      COMPETENCES_STRINGS.ECOLOGIE
    )
    this.innerUtilisateur.competencesReferentAe = this.checkedCompetenceStrings.includes(
      COMPETENCES_STRINGS.AE
    )
  }

  submit(): void {
    this.$emit("input-competences", this.innerUtilisateur)
  }

  goBack(): void {
    this.$emit("go-back", this.innerUtilisateur)
  }
}
