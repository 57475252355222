




















































































































import { EvaluationDTO, ProjetAnnuelDTO } from "@/model/bean/GeneratedDTOs"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import { InstructionService } from "@/services/rest/instruction/InstructionService"
import { Component, Prop, Vue } from "vue-property-decorator"

@Component({ components: {} })
export default class LabellisationEvaluationCommentsPopup extends Vue {
  protected infoReportingService = InfoReportingService.INSTANCE
  instructionService = InstructionService.INSTANCE
  @Prop() projectAnnuel: ProjetAnnuelDTO
  @Prop() evaluation: EvaluationDTO
  @Prop() isFinal: boolean
  @Prop({ default: false }) readOnly: boolean

  modalOpened = false

  async sendFinalEval(): Promise<void> {
    try {
      await this.instructionService.saveEvaluationFinale(this.projectAnnuel.id!, this.evaluation)
      this.infoReportingService.success(
        this.$t("instructionPage.labellisations.harmonisation.evaluation-finale-saved").toString()
      )
      this.modalOpened = false
      this.$emit("comment-changed")
    } catch (e) {
      this.infoReportingService.error(
        this.$t(
          "instructionPage.labellisations.harmonisation.evaluation-finale-save-ko"
        ).toString(),
        new Error()
      )
    }
  }
}
