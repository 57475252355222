/*-
 * #%L
 * Aires Éducatives
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
import { AbstractTypedRestService } from "@/services/rest/AbstractTypedRestService"
import {
  DocumentsDTO,
  FilterDTO,
  PaginationParameter,
  PaginationResult,
} from "@/model/bean/GeneratedDTOs"

export class DocumentsService extends AbstractTypedRestService<DocumentsDTO> {
  static INSTANCE = new DocumentsService()

  async createDocument(param: DocumentsDTO): Promise<void> {
    await super.postV2("/document", {
      ...param,
      anneescolaire: param.anneescolaire,
      documentfile: param.documentfile,
      type: param.type,
      documentname: param.documentname,
      file: param.file,
    })
  }

  async deleteDocument(document: DocumentsDTO): Promise<DocumentsDTO> {
    return super.typedDeleteSingleV2("/document/" + document.id, 2)
  }
  async getDocument(document: DocumentsDTO): Promise<DocumentsDTO> {
    return super.typedGetSingleV2("/document/" + document.id, 2)
  }

  async getAllDocuments(
    paginationParameters: PaginationParameter,
    filtersList: Array<FilterDTO>
  ): Promise<PaginationResult<DocumentsDTO>> {
    const paginatedResult = await super.typeGetPaginatedV2(
      "document",
      2,
      paginationParameters,
      filtersList
    )
    return paginatedResult as PaginationResult<DocumentsDTO>
  }

  async getDocumentByTypeAndAnneScolaire(type: string, anneescolaire: string): Promise<boolean> {
    return super.typedGetSingleV2<boolean>("document/annee/" + anneescolaire + "/type/" + type, 2)
  }
}
