


























































































































































































































































import {
  CommentaireDTO,
  ProjetAnnuelDTO,
  ProjetAnnuelForInstructionDTO,
} from "@/model/bean/GeneratedDTOs"
import AbstractTableView from "@/views/tables/AbstractTableView.vue"
import { Component, Prop } from "vue-property-decorator"
import Column from "@/views/tables/Column"
import { ProjectService } from "@/services/rest/project/ProjectService"
import PitchModale from "./PitchModaleView.vue"
import CommentairesModale from "./CommentairesModaleView.vue"
import { CommentaireService } from "@/services/rest/commentaire/CommentaireService"

@Component({
  components: {
    PitchModale,
    CommentairesModale,
  },
})
export default class ProjetHistoryView extends AbstractTableView {
  @Prop() projetAnnuel: ProjetAnnuelDTO
  @Prop({ default: false }) isPorteurProjet: boolean
  @Prop({ default: "" }) role: string

  private projectService = ProjectService.INSTANCE
  private commentaireService = CommentaireService.INSTANCE

  projets: Array<ProjetAnnuelForInstructionDTO> = []
  commentaires: Array<CommentaireDTO> = []
  openPitchModale = false
  selectedPitch = ""
  openCommentairesModale = false
  selectedCommentaires: Array<CommentaireDTO> = []

  isLoading = false

  created(): void {
    if (this.projetAnnuel.projetId) {
      this.loadHistory()
      this.loadCommentaires()
    }
  }

  async loadHistory(): Promise<void> {
    this.isLoading = true
    this.projets = await this.projectService.getProjectHistory(this.projetAnnuel.projetId)
    this.isLoading = false
  }

  async loadCommentaires(): Promise<void> {
    this.commentaires = await this.commentaireService.getCommentaires(this.projetAnnuel.projetId)
  }

  getInitialColumns(): Array<Column> {
    var columns = [
      {
        field: "anneeScolaire",
        label: this.$t("projet.historique.annee-scolaire").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: false,
        canBeAdded: false,
      },
      {
        field: "action",
        label: " ",
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: false,
      },
      {
        field: "statut",
        label: this.$t("projet.historique.statut").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: false,
      },
      {
        field: "etat",
        label: this.$t("projet.historique.etat").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: false,
        canBeAdded: false,
      },
      {
        field: "classes",
        label: this.$t("projet.historique.classes").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: false,
      },
      {
        field: "pitch",
        label: this.$t("projet.historique.pitch").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: false,
      },
    ]
    if (!this.isPorteurProjet || this.role == "SUPER_ADMINISTRATEUR") {
      columns.push(
        {
          field: "referents",
          label: this.$t("projet.historique.referents").toString(),
          sortable: false,
          backEndSearchable: false,
          visible: true,
          custom: true,
          canBeAdded: false,
        },
        {
          field: "structures",
          label: this.$t("projet.historique.structures").toString(),
          sortable: false,
          backEndSearchable: false,
          visible: true,
          custom: true,
          canBeAdded: false,
        },
        {
          field: "echanges",
          label: this.$t("projet.historique.echanges").toString(),
          sortable: false,
          backEndSearchable: false,
          visible: true,
          custom: true,
          canBeAdded: false,
        }
      )
    }

    return columns
  }

  openPitchDialog(event: Event, projetAnnuel: ProjetAnnuelDTO): void {
    // Do not foward click event to row (would trigger modal)
    event.stopPropagation()
    this.selectedPitch = projetAnnuel.pitch
    this.openPitchModale = true
  }

  openCommentairesDialog(event: Event, projetAnnuel: ProjetAnnuelForInstructionDTO): void {
    // Do not foward click event to row (would trigger modal)
    event.stopPropagation()
    this.selectedCommentaires = this.commentaires.filter(
      (o) =>
        o.dateCreation < projetAnnuel.anneeScolaireFin &&
        o.dateCreation > projetAnnuel.anneeScolaireDebut
    )
    this.openCommentairesModale = true
  }
  getLink(event: Event, projet: ProjetAnnuelDTO): void {
    event.stopPropagation()
    if (projet.typeDossier === "DOSSIER_INSCRIPTION") {
      this.$router.push("/project/" + projet.projetId + "/" + projet.id + "/inscription/0")
    } else {
      this.$router.push("/project/" + projet.projetId + "/" + projet.id + "/bref/0")
    }
  }
  showDetailsPage(event: Event, projet: ProjetAnnuelDTO): void {
    // Do not forward click event to row (would trigger modal)
    event.stopPropagation()
    if (projet.typeDossier === "DOSSIER_INSCRIPTION") {
      this.$router.push("/project/" + projet.projetId + "/" + projet.id + "/inscription/0")
    } else {
      this.$router.push("/project/" + projet.projetId + "/" + projet.id + "/bref/0")
    }
  }
}
