



































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator"

import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import UserInfoDetails from "@/views/profile/UserInfoDetails.vue"
import PasswordReset from "@/views/profile/PasswordReset.vue"
import TitleView from "@/views/commons/TitleView.vue"
import PageView from "@/views/commons/PageView.vue"

import { UsersService } from "@/services/rest/users/UsersService"
import { LoginService } from "@/services/rest/login/LoginService"
import { PasswordCheckDTO, UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import { PROFIL_UTILISATEUR } from "@/model/bean/EnumHelper"
import H3TitleView from "@/views/commons/H3TitleView.vue"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import { ValidationObserver } from "vee-validate"

@Component({
  components: {
    TitleView,
    PageView,
    InputWithValidation,
    UserInfoDetails,
    PasswordReset,
    H3TitleView,
    ValidationObserver,
  },
})
export default class ProfileModale extends Vue {
  @Prop() userId: string
  @Prop() idDossier?: string
  @Prop() open: boolean

  private loginService = LoginService.INSTANCE
  private userService = UsersService.INSTANCE
  private infoReportingService = InfoReportingService.INSTANCE

  user = new UtilisateurDTO()
  profils = PROFIL_UTILISATEUR
  canUserEditProfil = false
  isCurrentUserProfile = false
  canUserChangeOtherPwd = false
  currentTabIndex = 0
  doDeleteProfil = false
  password = ""

  previousUrl = "/users-list"
  previousTitle = this.$t("profilePage.previous-title").toString()

  created(): void {
    this.setUserProfile()
  }

  @Watch("userId")
  onUserIdUpdates(): void {
    this.setUserProfile()
  }

  async setUserProfile(): Promise<void> {
    const loggedUser = this.loginService.getLoggedUser()
    const userProfile = await this.userService.getUserProfile(this.userId)
    this.user = userProfile
    if (this.user && loggedUser) {
      this.canUserChangeOtherPwd = loggedUser.profil === this.profils.SUPER_ADMINISTRATEUR
    }

    if (loggedUser && loggedUser.id == this.userId) {
      this.canUserEditProfil = true
      this.isCurrentUserProfile = true
    } else if (loggedUser && loggedUser.profil == this.profils.SUPER_ADMINISTRATEUR) {
      this.canUserEditProfil = true
      this.isCurrentUserProfile = false
    } else {
      this.canUserEditProfil = false
      this.isCurrentUserProfile = false
    }
  }

  async saveAndClose(): Promise<void> {
    const userInfoComponent = this.$refs["userInfoDetails"] as UserInfoDetails
    const resetPwdComponent = this.$refs["resetPwd"] as PasswordReset

    //Chainage des deux enregistrements pour ne pas que le profil soit enregistré après le mot de passe
    //sinon le mdp n'ets pas modifié, cf. #12359

    await userInfoComponent.submitProfile().then((value) => {
      if (value !== "") {
        this.infoReportingService.error(value, new Error())
      } else {
        this.infoReportingService.success(
          this.$i18n.t("profilePage.profile-modification-ok").toString()
        )
      }
    })

    await resetPwdComponent.submitPassword().then((value) => {
      if (value !== "") {
        this.infoReportingService.error(value, new Error())
      } else {
        this.infoReportingService.success(
          this.$i18n.t("profilePage.profile-modification-ok").toString()
        )
      }
    })
  }

  emitCloseModale(): void {
    this.setUserProfile()
    this.$emit("close-modale")
  }

  deleteUserAccount(): void {
    if (!this.user || !this.user.id) {
      return
    }
    const passwordBean = new PasswordCheckDTO()
    passwordBean.motDePasse = this.password
    this.loginService
      .checkPassword(passwordBean)
      .then(() => this.userService.deleteUserAccount(this.user))
      .then(() => this.emitCloseModale())
      .then(() => this.loginService.logout())
      .then(() =>
        this.infoReportingService.success(this.$t("profilePage.account-deleted").toString())
      )
      .catch((error) => {
        this.infoReportingService.error(
          this.$i18n.t("profilePage.authentication-error").toString(),
          error
        )
      })
  }
}
