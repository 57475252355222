var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "projet-part" },
    [
      _c("h2", [
        _vm._v(_vm._s(_vm.$t("projetPage.mon-annee.etat-des-lieux.titre")))
      ]),
      _vm.requiredIfAllFieldsAreEmptyRule == "required" &&
      _vm.validationActivated &&
      _vm.projetAnnuel.typeDossier !== "DOSSIER_RENOUVELLEMENT_LIGHT"
        ? _c("b-message", { attrs: { type: "is-danger" } }, [
            _c("span", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t(
                    "projetPage.mon-annee.etat-des-lieux.elements-etudies-error"
                  )
                )
              }
            })
          ])
        : _vm._e(),
      _c("h4"),
      _c("h3", [
        _vm._v(
          _vm._s(
            _vm.$t("projetPage.mon-annee.etat-des-lieux.titre-elements-etudies")
          )
        )
      ]),
      _c("b-message", { attrs: { type: "is-info" } }, [
        _c("span", {
          domProps: {
            innerHTML: _vm._s(
              _vm.$t("projetPage.mon-annee.etat-des-lieux.elements-etudies-doc")
            )
          }
        })
      ]),
      _c("div", { staticClass: "spider" }, [
        _c(
          "div",
          { staticClass: "left_circle", attrs: { id: "left_circle" } },
          [
            _c("div", {
              staticClass: "circle",
              attrs: { id: "circle" },
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("projetPage.mon-annee.etat-des-lieux.circle")
                )
              }
            })
          ]
        ),
        _c(
          "div",
          { staticClass: "right_questions" },
          [
            _c("InputWithValidation", {
              attrs: {
                validationActivated: _vm.validationActivated,
                showValidationErrors: _vm.showValidationErrors,
                id: "etat1",
                rules: _vm.projetAnnuel.etatDesLieuxContexteAdministratif
                  ? "required"
                  : _vm.requiredIfAllFieldsAreEmptyRule,
                type: "textarea",
                label: _vm.$t(
                  "projetPage.mon-annee.etat-des-lieux.administratif-question"
                ),
                placeholder: _vm.$t(
                  "projetPage.mon-annee.etat-des-lieux.administratif-input"
                ),
                maxlength: "1400",
                disabled: _vm.readonly || !_vm.isPorteurProjet,
                hiddenBadge: true,
                vid: "etat_administratif"
              },
              on: { "manual-change": _vm.dirtifyProject },
              model: {
                value: _vm.projetAnnuel.etatDesLieuxContexteAdministratif,
                callback: function($$v) {
                  _vm.$set(
                    _vm.projetAnnuel,
                    "etatDesLieuxContexteAdministratif",
                    $$v
                  )
                },
                expression: "projetAnnuel.etatDesLieuxContexteAdministratif"
              }
            }),
            _c("InputWithValidation", {
              attrs: {
                validationActivated: _vm.validationActivated,
                showValidationErrors: _vm.showValidationErrors,
                id: "etat2",
                rules: _vm.projetAnnuel.etatDesLieuxContextePhysique
                  ? "required"
                  : _vm.requiredIfAllFieldsAreEmptyRule,
                type: "textarea",
                label: _vm.$t(
                  "projetPage.mon-annee.etat-des-lieux.physique-question"
                ),
                placeholder: _vm.$t(
                  "projetPage.mon-annee.etat-des-lieux.physique-input"
                ),
                maxlength: "1400",
                disabled: _vm.readonly || !_vm.isPorteurProjet,
                vid: "etat_physique"
              },
              on: { "manual-change": _vm.dirtifyProject },
              model: {
                value: _vm.projetAnnuel.etatDesLieuxContextePhysique,
                callback: function($$v) {
                  _vm.$set(
                    _vm.projetAnnuel,
                    "etatDesLieuxContextePhysique",
                    $$v
                  )
                },
                expression: "projetAnnuel.etatDesLieuxContextePhysique"
              }
            }),
            _c("InputWithValidation", {
              attrs: {
                validationActivated: _vm.validationActivated,
                showValidationErrors: _vm.showValidationErrors,
                id: "etat3",
                rules: _vm.projetAnnuel.etatDesLieuxContexteSocio
                  ? "required"
                  : _vm.requiredIfAllFieldsAreEmptyRule,
                type: "textarea",
                label: _vm.$t(
                  "projetPage.mon-annee.etat-des-lieux.socio-question"
                ),
                placeholder: _vm.$t(
                  "projetPage.mon-annee.etat-des-lieux.socio-input"
                ),
                maxlength: "1400",
                disabled: _vm.readonly || !_vm.isPorteurProjet,
                vid: "etat_socio"
              },
              on: { "manual-change": _vm.dirtifyProject },
              model: {
                value: _vm.projetAnnuel.etatDesLieuxContexteSocio,
                callback: function($$v) {
                  _vm.$set(_vm.projetAnnuel, "etatDesLieuxContexteSocio", $$v)
                },
                expression: "projetAnnuel.etatDesLieuxContexteSocio"
              }
            }),
            _c("InputWithValidation", {
              attrs: {
                validationActivated: _vm.validationActivated,
                showValidationErrors: _vm.showValidationErrors,
                id: "etat4",
                rules: _vm.projetAnnuel.etatDesLieuxContextePatrimonial
                  ? "required"
                  : _vm.requiredIfAllFieldsAreEmptyRule,
                type: "textarea",
                label: _vm.$t(
                  "projetPage.mon-annee.etat-des-lieux.patrimonial-question"
                ),
                placeholder: _vm.$t(
                  "projetPage.mon-annee.etat-des-lieux.patrimonial-input"
                ),
                maxlength: "1400",
                disabled: _vm.readonly || !_vm.isPorteurProjet,
                vid: "etat_patrimonial"
              },
              on: { "manual-change": _vm.dirtifyProject },
              model: {
                value: _vm.projetAnnuel.etatDesLieuxContextePatrimonial,
                callback: function($$v) {
                  _vm.$set(
                    _vm.projetAnnuel,
                    "etatDesLieuxContextePatrimonial",
                    $$v
                  )
                },
                expression: "projetAnnuel.etatDesLieuxContextePatrimonial"
              }
            }),
            _c("InputWithValidation", {
              attrs: {
                validationActivated: _vm.validationActivated,
                showValidationErrors: _vm.showValidationErrors,
                id: "etat5",
                rules: _vm.projetAnnuel.etatDesLieuxContexteEcoGeo
                  ? "required"
                  : _vm.requiredIfAllFieldsAreEmptyRule,
                type: "textarea",
                label: _vm.$t(
                  "projetPage.mon-annee.etat-des-lieux.ecogeo-question"
                ),
                placeholder: _vm.$t(
                  "projetPage.mon-annee.etat-des-lieux.ecogeo-input"
                ),
                maxlength: "1400",
                disabled: _vm.readonly || !_vm.isPorteurProjet,
                vid: "etat_ecogeo"
              },
              on: { "manual-change": _vm.dirtifyProject },
              model: {
                value: _vm.projetAnnuel.etatDesLieuxContexteEcoGeo,
                callback: function($$v) {
                  _vm.$set(_vm.projetAnnuel, "etatDesLieuxContexteEcoGeo", $$v)
                },
                expression: "projetAnnuel.etatDesLieuxContexteEcoGeo"
              }
            })
          ],
          1
        )
      ]),
      _c("FileWithValidation", {
        attrs: {
          validationActivated: _vm.validationActivated,
          showValidationErrors: _vm.showValidationErrors,
          placeholder: _vm.$t(
            "projetPage.infoAdministratives.charte.televerser"
          ),
          inputNotice: _vm.$t("projetPage.mon-annee.etat-des-lieux.fichier"),
          fileOperationUrl: _vm.etatDesLieuxFileUrl,
          disabled: _vm.readonly,
          vid: "etat_fichier_single",
          value: _vm.projetAnnuel.etatdesLieuxFilePresent,
          defaultFileName: "ETAT_DES_LIEUX.pdf"
        },
        on: {
          "add-file": _vm.addEtatDesLieuxFile,
          "delete-file": _vm.deleteEtatDesLieuxFile
        }
      }),
      _c(
        "fieldset",
        [
          _c("legend", [
            _vm._v(
              _vm._s(
                _vm.$t(
                  "projetPage.mon-annee.etat-des-lieux.liens-enseignements-titre"
                )
              )
            )
          ]),
          _vm.validationActivated &&
          (_vm.projetAnnuel.typeDossier === "DOSSIER_LABELLISATION" ||
            _vm.projetAnnuel.typeDossier === "DOSSIER_RENOUVELLEMENT") &&
          _vm.showRequiredMessage
            ? _c("b-message", { attrs: { type: "is-danger" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "projetPage.mon-annee.autres-infos.labellisation-renouvellement"
                      )
                    ) +
                    " "
                )
              ])
            : _vm._e(),
          _c("InputWithValidation", {
            attrs: {
              validationActivated: _vm.validationActivated,
              showValidationErrors: _vm.showValidationErrors,
              type: "textarea",
              rules:
                _vm.projetAnnuel.typeDossier === "DOSSIER_RENOUVELLEMENT_LIGHT"
                  ? ""
                  : _vm.calculatedRules,
              label: _vm.$t(
                "projetPage.mon-annee.etat-des-lieux.liens-enseignements"
              ),
              maxlength: "1400",
              disabled: _vm.readonly || !_vm.isPorteurProjet,
              vid: "etat_liens"
            },
            on: { "manual-change": _vm.dirtifyProject },
            model: {
              value: _vm.projetAnnuel.etatDesLieuxLiensEnseignements,
              callback: function($$v) {
                _vm.$set(
                  _vm.projetAnnuel,
                  "etatDesLieuxLiensEnseignements",
                  $$v
                )
              },
              expression: "projetAnnuel.etatDesLieuxLiensEnseignements"
            }
          }),
          _c("FileWithValidation", {
            attrs: {
              validationActivated: _vm.validationActivated,
              showValidationErrors: _vm.showValidationErrors,
              placeholder: _vm.$t(
                "projetPage.infoAdministratives.charte.televerser"
              ),
              inputNotice: _vm.$t(
                "projetPage.mon-annee.etat-des-lieux.liens-enseignements-fichier"
              ),
              fileOperationUrl: _vm.etatDesLieuxLiensUrl,
              disabled: _vm.readonly,
              vid: "etat_fichier_liens",
              value: _vm.projetAnnuel.etatdesLieuxLiensFilePresent,
              defaultFileName: "ETAT_DES_LIEUX_LIENS_ENSEIGNEMENTS.pdf"
            },
            on: {
              "add-file": _vm.addLiensFile,
              "delete-file": _vm.deleteLiensFile
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }