var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "projet-part" }, [
    _vm.projetAnnuel.typeDossier != "DOSSIER_INSCRIPTION"
      ? _c("h2", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("projetPage.infoAdministratives.financement.titre")
              ) +
              " "
          )
        ])
      : _c("h2", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "projetPage.infoAdministratives.financement.titre-inscription"
                )
              ) +
              " "
          )
        ]),
    _c(
      "fieldset",
      [
        _c("legend", [
          _vm._v(
            _vm._s(
              _vm.$t("projetPage.infoAdministratives.financement.sous-titre-1")
            )
          )
        ]),
        _c("b-message", { attrs: { type: "is-info" } }, [
          _c("p", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "projetPage.infoAdministratives.financement.info-message-1"
                  )
                ) +
                " "
            ),
            _c(
              "a",
              {
                attrs: {
                  href: _vm.$t("projet.doc.financement-inscription-url"),
                  target: "_blank"
                }
              },
              [_vm._v(_vm._s(_vm.$t("projet.doc.financement-inscription-url")))]
            )
          ])
        ]),
        _c(
          "div",
          { staticStyle: { "margin-top": "5px", "margin-bottom": "5px" } },
          [
            _c(
              "b-button",
              {
                staticStyle: { "margin-top": "5px", "margin-bottom": "5px" },
                attrs: {
                  type: "is-primary is-rounded",
                  tag: "a",
                  target: "_blank",
                  href: "https://ame.ofb.fr/doku.php?id=justificatifs"
                }
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t("projetPage.infoAdministratives.financement.demande")
                  )
                )
              ]
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "fieldset",
      [
        _c("legend", [
          _vm._v(
            _vm._s(
              _vm.$t("projetPage.infoAdministratives.financement.sous-titre-2")
            )
          )
        ]),
        _c(
          "div",
          { staticClass: "cards" },
          [
            !_vm.readonly
              ? _c("FinancementCard", {
                  attrs: { projetAnnuel: _vm.projetAnnuel, canDelete: false },
                  on: { add: _vm.added }
                })
              : _vm._e(),
            _vm._l(_vm.projetAnnuel.financements.slice().reverse(), function(
              financement
            ) {
              return _c(
                "div",
                { key: financement.id },
                [
                  _vm.isValid(financement)
                    ? _c("FinancementCard", {
                        attrs: {
                          validationActivated: _vm.validationActivated,
                          showValidationErrors: _vm.showValidationErrors,
                          financement: financement,
                          projetAnnuel: _vm.projetAnnuel,
                          canDelete: !_vm.readonly
                        },
                        on: { delete: _vm.deleted }
                      })
                    : _vm._e()
                ],
                1
              )
            })
          ],
          2
        ),
        _c(
          "b-message",
          {
            staticStyle: { "margin-top": "20px", "margin-bottom": "5px" },
            attrs: { type: "is-info" }
          },
          [
            _c("p", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "projetPage.infoAdministratives.financement.info-message-2"
                    )
                  ) +
                  " "
              ),
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.$t("projet.doc.financement-url"),
                    target: "_blank"
                  }
                },
                [_vm._v(_vm._s(_vm.$t("projet.doc.financement-label")))]
              )
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }