






















































































































import { Component, Prop, Vue } from "vue-property-decorator"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import AdresseView from "@/views/commons/AdresseView.vue"
import SubTitleView from "@/views/commons/SubTitleView.vue"
import { EtablissementDTO } from "@/model/bean/GeneratedDTOs"
import { STATUT_ETABLISSEMENT } from "@/model/bean/EnumHelper"
import { InfoReportingService } from "@/services/log/InfoReportingService"

@Component({
  components: {
    InputWithValidation,
    AdresseView,
    SubTitleView,
  },
})
export default class EtablissementIdentiteDetails extends Vue {
  @Prop() etablissement: EtablissementDTO
  @Prop({ default: false }) userCanChange: boolean
  @Prop({ default: false }) readonly: boolean
  @Prop() validationGroup: string

  private infoReportingService = InfoReportingService.INSTANCE

  isChanging = false

  statuts = STATUT_ETABLISSEMENT

  changeEtablissement(): void {
    this.infoReportingService.dialog(
      this.$t("profilePage.etablissement.change.confirmation").toString(),
      this.$t("profilePage.etablissement.change.are-you-sure").toString(),
      this.$t("profilePage.etablissement.change.save").toString(),
      () => this.$emit("change-etablissement"),
      "is-warning"
    )
  }

  updateEtablissementIdentite(): void {
    this.$emit("update-etablissement", this.etablissement)
  }
}
