var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "instructionPannelMenu" },
      _vm._l(_vm.menuItems, function(item, i) {
        return _c("div", { key: item.label }, [
          _c("div", [
            _c(
              "a",
              {
                staticClass: "menu-item",
                class: { active: item.isLinkActive },
                attrs: { title: item.label },
                on: {
                  click: function($event) {
                    return _vm.selectActiveItem(i)
                  }
                }
              },
              [
                _c("b-icon", {
                  staticClass: "item-icon",
                  attrs: { size: "is-normal", icon: item.icon }
                }),
                _c("div", { staticClass: "instructionPannelMenu-arrow" })
              ],
              1
            )
          ])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }