




















































import { Component, Vue } from "vue-property-decorator"
import TitleView from "@/views/commons/TitleView.vue"
import ContentView from "@/views/commons/ContentView.vue"
import EtablissementSelectionView from "@/views/login/registration/EtablissementSelectionView.vue"
import H3TitleView from "@/views/commons/H3TitleView.vue"
import { EtablissementDTO } from "@/model/bean/GeneratedDTOs"

@Component({
  components: { TitleView, ContentView, EtablissementSelectionView, H3TitleView },
})
export default class ChoseEtablissementView extends Vue {
  chosenEtablissement = new EtablissementDTO()

  setChosenEtablissement(etablissement: EtablissementDTO): void {
    this.chosenEtablissement = etablissement
  }

  submit(): void {
    this.$emit("etablissement-chosen", this.chosenEtablissement)
  }

  cancelProjectCreation(): void {
    this.$emit("cancel-project-creation")
  }
}
