/*-
 * #%L
 * Aires Éducatives
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
import { AbstractRestService } from "../AbstractRestService"

// Simple service allowing to get
export class SettingsService extends AbstractRestService {
  public static INSTANCE = new SettingsService()

  async getSettings(): Promise<Settings> {
    return await super.typedGetSingleV2<Settings>("settings/json", 2)
  }
}

/**
 * Data class indicating server heatlh.
 */
export class Settings {
  MATOMO_URL = ""
  MATOMO_SITE_ID = ""
  MAX_UPLOAD_SIZE = ""
}
