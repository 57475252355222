
































































import { CalendrierEvenementDTO, FichierDTO, ProjetAnnuelDTO } from "@/model/bean/GeneratedDTOs"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import EventModale from "./EventModale.vue"
import { EvenementService } from "@/services/rest/evenement/EvenementService"
import { DialogProgrammatic as Dialog } from "buefy"
import TitleView from "@/views/commons/TitleView.vue"
import * as frLocale from "date-fns/locale/fr"
import { format } from "date-fns"
import { FileService } from "@/services/rest/utils/FileService"
import FileWithValidation from "@/views/inputs/FileWithValidation.vue"

@Component({
  components: { EventModale, TitleView, FileWithValidation },
})
export default class CalendrierView extends Vue {
  @Prop() projetAnnuel: ProjetAnnuelDTO
  @Prop({ default: false }) readonly: boolean
  @Prop({ default: false }) isPorteurProjet: boolean

  private evenementService = EvenementService.INSTANCE
  private fileService = FileService.INSTANCE

  selectedDate = new Date()
  selectedEvenements: CalendrierEvenementDTO[] = []
  minDate = new Date()
  maxDate = new Date()
  events: { date: Date; type: string }[] = []
  allEvenements: CalendrierEvenementDTO[] = []
  unselectableDates: Date[] = []

  openEventModale = false

  calendriedFichier: FichierDTO = new FichierDTO()
  calendrierFichierUrl = ""

  @Watch("selectedDate")
  selectedDateChanged(): void {
    this.computeSelectedEvenements()
  }

  mounted(): void {
    if (this.projetAnnuel && this.projetAnnuel.id) {
      this.minDate = new Date(this.projetAnnuel.anneeScolaireDebut)
      this.maxDate = new Date(this.projetAnnuel.anneeScolaireFin)
      this.loadEvenements(this.projetAnnuel.id, this.minDate, this.maxDate)
    }
  }

  async loadEvenements(idProjetAnnuel: string, minDate: Date, maxDate: Date): Promise<void> {
    this.allEvenements = await this.evenementService.getEvenements(idProjetAnnuel, minDate, maxDate)
    this.events = this.buildEvends(this.allEvenements)
    this.computeSelectedEvenements()
    this.unselectableDates = this.buildUnselectableDates(this.allEvenements)
    const calendrierFichiers = await this.fileService.getFichiers(idProjetAnnuel, "CALENDRIER")
    if (calendrierFichiers && calendrierFichiers.length > 0) {
      this.calendriedFichier = calendrierFichiers[0]
      this.calendrierFichierUrl = this.fileService.buildUniqueFileURL(idProjetAnnuel, "CALENDRIER")
    }
  }

  computeSelectedEvenements(): void {
    this.selectedEvenements = this.buildSelectedEvents(this.allEvenements, this.selectedDate)
  }

  buildEvends(evenements: CalendrierEvenementDTO[]): { date: Date; type: string }[] {
    return evenements.map((evenement) => ({
      date: new Date(evenement.dateEvenement),
      type: "is-danger",
    }))
  }

  buildSelectedEvents(evenements: CalendrierEvenementDTO[], date: Date): CalendrierEvenementDTO[] {
    const dateToCompare = new Date(date.getFullYear(), date.getMonth(), date.getDate())
    return evenements.filter((evenement) => {
      const eventDate = new Date(evenement.dateEvenement)
      return (
        eventDate.getFullYear() === dateToCompare.getFullYear() &&
        eventDate.getMonth() === dateToCompare.getMonth() &&
        eventDate.getDate() === dateToCompare.getDate()
      )
    })
  }

  closeModale(withRefresh: boolean): void {
    this.openEventModale = false
    if (withRefresh && this.projetAnnuel && this.projetAnnuel.id) {
      this.loadEvenements(this.projetAnnuel.id, this.minDate, this.maxDate)
    }
  }

  buildUnselectableDates(evenements: CalendrierEvenementDTO[]): Date[] {
    const counterByDate = evenements.reduce((acc, curr) => {
      let counter = acc.get(curr.dateEvenement)
      return acc.set(curr.dateEvenement, counter ? counter + 1 : 1)
    }, new Map<Date | string, number>())
    const result: Date[] = []
    counterByDate.forEach((counter, date) => {
      if (counter > 2) {
        const newDate = new Date(date)
        newDate.setHours(0, 0, 0, 0)
        result.push(newDate)
      }
    })
    return result
  }

  removeEvenement(idProjetAnnuel: string, idEvenement: string): void {
    Dialog.confirm({
      message: this.$t("projet.en-bref.remove-event-confirm").toString(),
      confirmText: this.$t("confirmer").toString(),
      cancelText: this.$t("annuler").toString(),
      onConfirm: () =>
        this.evenementService
          .removeEvenement(idProjetAnnuel, idEvenement)
          .then(() => this.loadEvenements(idProjetAnnuel, this.minDate, this.maxDate)),
    })
  }

  formatDateForPDF(date: Date): string {
    return date ? format(date, "D MMMM YYYY", { locale: frLocale }) : ""
  }
}
