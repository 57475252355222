var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "observer",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var handleSubmit = ref.handleSubmit
          return [
            _c(
              "div",
              { staticClass: "modal-card", class: { embeded: _vm.embeded } },
              [
                _c(
                  "section",
                  { staticClass: "modal-card-body" },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isLoggingIn,
                            expression: "!isLoggingIn"
                          }
                        ]
                      },
                      [
                        _c("InputWithValidation", {
                          attrs: {
                            rules: "required|email",
                            type: "email",
                            customError: _vm.logginError,
                            placeholder: _vm.$t("input-login-tooltip"),
                            label: _vm.$t("input-login")
                          },
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.enterPressed($event)
                            }
                          },
                          model: {
                            value: _vm.email,
                            callback: function($$v) {
                              _vm.email = $$v
                            },
                            expression: "email"
                          }
                        }),
                        _c("InputWithValidation", {
                          attrs: {
                            rules: "required",
                            type: "password",
                            passwordReveal: "",
                            placeholder: _vm.$t("input-password-tooltip"),
                            label: _vm.$t("input-password"),
                            vid: "password"
                          },
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.enterPressed($event)
                            }
                          },
                          model: {
                            value: _vm.password,
                            callback: function($$v) {
                              _vm.password = $$v
                            },
                            expression: "password"
                          }
                        }),
                        _c(
                          "CheckboxWithValidation",
                          {
                            attrs: {
                              rules: "accept-cookie",
                              vid: "acceptCookie"
                            },
                            model: {
                              value: _vm.acceptCookie,
                              callback: function($$v) {
                                _vm.acceptCookie = $$v
                              },
                              expression: "acceptCookie"
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("login-accept-cookie")) + " "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm.isLoggingIn
                      ? _c("LoadingSkeleton", {
                          attrs: { "loading-text": _vm.$t("login-connecting") }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                !_vm.isLoggingIn
                  ? _c(
                      "footer",
                      { staticClass: "modal-card-foot" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              type: "is-primary",
                              disabled: !_vm.acceptCookie
                            },
                            on: {
                              click: function($event) {
                                return handleSubmit(_vm.submit)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("login")) + " ")]
                        ),
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: { path: "/forgot-password/" + _vm.email }
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("forgot-password")) + " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }