var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "quill-editor", staticStyle: { width: "100%" } },
    [
      _vm._t("toolbar"),
      _c("div", { ref: "editor" }),
      _c("div", { staticClass: "char-counter" }, [
        _vm._v(_vm._s(_vm.charCount - 1) + " / " + _vm._s(_vm.maxLength))
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }