var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("img", {
        class: "ImageWithModal " + (_vm.withModal ? "clickable" : ""),
        style: "width:" + _vm.imgWidth + "; height:" + _vm.imgHeight,
        attrs: { src: _vm.getImgUrl(), alt: _vm.missingImgLabel },
        on: {
          click: function($event) {
            _vm.isImageModalActive = true
          }
        }
      }),
      _vm.withModal
        ? _c(
            "b-modal",
            {
              model: {
                value: _vm.isImageModalActive,
                callback: function($$v) {
                  _vm.isImageModalActive = $$v
                },
                expression: "isImageModalActive"
              }
            },
            [
              _c("p", { staticClass: "image" }, [
                _c("img", {
                  attrs: { src: _vm.getImgUrl(), alt: _vm.missingImgLabel }
                })
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }