







































































































































































import { Component, Prop } from "vue-property-decorator"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import AdresseView from "@/views/commons/AdresseView.vue"
import { StructureDTO, TeteStructure, TypeStructure } from "@/model/bean/GeneratedDTOs"
import { BOOLEAN, EnumHelper, TETES_STRINGS, TYPE_STRUCTURE } from "@/model/bean/EnumHelper"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import FileWithValidation from "@/views/inputs/FileWithValidation.vue"
import TabHeaderWithHint from "@/views/commons/TabHeaderWithHint.vue"
import AbstractFormStepWithTabs from "@/views/commons/AbstractFormStepWithTabs.vue"
import SelectWithValidation from "../inputs/SelectWithValidation.vue"
import GroupCheckboxesWithValidation from "../inputs/GroupCheckBoxesWithValidation.vue"

@Component({
  components: {
    ValidationProvider,
    InputWithValidation,
    AdresseView,
    FileWithValidation,
    ValidationObserver,
    TabHeaderWithHint,
    SelectWithValidation,
    GroupCheckboxesWithValidation,
  },
})
export default class StructureUpdateView extends AbstractFormStepWithTabs {
  private infoReportingService = InfoReportingService.INSTANCE

  @Prop() structure: StructureDTO
  @Prop({ default: false }) userCanChange: boolean
  @Prop({ default: false }) readonly: boolean
  @Prop() validationGroup: string
  @Prop({ default: false }) lowerTab: boolean

  displayOther = false
  agrementFileUrl?: string
  tetesStructureLabels = Object.values(TETES_STRINGS).filter((t) => t !== TETES_STRINGS.AUTRE)
  checkedTetesStructure: string[] = []

  // eslint-disable-next-line
  data() {
    return {
      displayOther: this.structure.type == TYPE_STRUCTURE.AUTRE,
    }
  }

  structures: StructureDTO[] = []
  structureTypes = Object.values(EnumHelper.allTypesStructure)
  structureTypesOptionLabels = this.structureTypes.map((type) => {
    return this.$t(`StructureSelectionView.types-label-${type.toLowerCase().replace(/_/g, "-")}`)
  })
  agree = Object.values(BOOLEAN)
  agreeLabels = Object.keys(BOOLEAN).map((key) => {
    return this.$t(`StructureSelectionView.agree-label-${key.toLowerCase()}`)
  })

  isAireProtegee = (type: TypeStructure): boolean => {
    return (
      type !== TYPE_STRUCTURE.ASSOCIATION &&
      type !== TYPE_STRUCTURE.COLLECTIVITE &&
      type !== TYPE_STRUCTURE.SYNDICAT_MIXTE &&
      type !== TYPE_STRUCTURE.ENTREPRISE_PRIVEE
    )
  }
  structureTypesRadios = Object.values(EnumHelper.allTypesStructure).filter(
    (type) => !this.isAireProtegee(type)
  )
  structureTypesRadiosLabels = this.structureTypesRadios.map((type) => {
    return this.$t(`StructureSelectionView.types-label-${type.toLowerCase().replace(/_/g, "-")}`)
  })
  aireProtegeeTypes = Object.values(EnumHelper.allTypesStructure).filter((type) =>
    this.isAireProtegee(type)
  )
  aireProtegeeTypesLabels = this.aireProtegeeTypes.map((type) => {
    return this.$t(`StructureSelectionView.types-label-${type.toLowerCase().replace(/_/g, "-")}`)
  })

  created(): void {
    this.numberOfTabs = 2
    this.currentTabIndex = 0
    if (this.structure.id) {
      this.agrementFileUrl = "/organismes/structures/" + this.structure.id + "/agrement"
    } else {
      this.agrementFileUrl = "/organismes/structures/newAgrement"
    }
    this.checkedTetesStructure = this.getCheckedTetesStructure()
  }

  changeStructure(): void {
    this.infoReportingService.dialog(
      this.$t("profilePage.structure.change.confirmation").toString(),
      this.$t("profilePage.structure.change.are-you-sure").toString(),
      this.$t("profilePage.structure.change.save").toString(),
      () => this.validateChangeStructure(),
      "is-warning"
    )
  }

  validateChangeStructure(): void {
    this.$emit("change-structure")
  }

  setStructureType(value: TypeStructure): void {
    this.structure.type = value
    this.displayOther = this.structure.type == TYPE_STRUCTURE.AUTRE
    this.updateStructure()
  }

  updateStructure(): void {
    this.setWarnings()
    this.$emit("update-structure", this.structure)
  }

  deleteAgrement(): void {
    this.structure.agrementFilePresent = false
    this.updateStructure()
  }

  agrementUploaded(id: string): void {
    if (!this.structure.id) {
      this.structure.agrementFilePresent = true
      this.structure.agrementFile = id
    }
    this.setWarnings()
  }

  getCheckedTetesStructure(): string[] {
    if (!this.structure.tetesStructure || this.structure.tetesStructure.length === 0) {
      return []
    }
    return this.structure.tetesStructure.map((t) =>
      this.$t(`StructureSelectionView.label-${t}`).toString()
    )
  }

  updateTetesStructure(): void {
    const tetes: TeteStructure[] = []

    if (this.checkedTetesStructure.includes(TETES_STRINGS.CEN)) {
      tetes.push("CEN")
    }
    if (this.checkedTetesStructure.includes(TETES_STRINGS.FCPN)) {
      tetes.push("FCPN")
    }
    if (this.checkedTetesStructure.includes(TETES_STRINGS.FNE)) {
      tetes.push("FNE")
    }
    if (this.checkedTetesStructure.includes(TETES_STRINGS.FPNR)) {
      tetes.push("FPNR")
    }
    if (this.checkedTetesStructure.includes(TETES_STRINGS.LPO)) {
      tetes.push("LPO")
    }
    if (this.checkedTetesStructure.includes(TETES_STRINGS.OFB)) {
      tetes.push("OFB")
    }
    if (this.checkedTetesStructure.includes(TETES_STRINGS.ONF)) {
      tetes.push("ONF")
    }
    if (this.checkedTetesStructure.includes(TETES_STRINGS.PNF)) {
      tetes.push("PNF")
    }
    if (this.checkedTetesStructure.includes(TETES_STRINGS.REN)) {
      tetes.push("REN")
    }
    if (this.checkedTetesStructure.includes(TETES_STRINGS.RGF)) {
      tetes.push("RGF")
    }
    if (this.checkedTetesStructure.includes(TETES_STRINGS.RNF)) {
      tetes.push("RNF")
    }
    if (this.checkedTetesStructure.includes(TETES_STRINGS.UICN)) {
      tetes.push("UICN")
    }
    if (this.checkedTetesStructure.includes(TETES_STRINGS.UNCPIE)) {
      tetes.push("UNCPIE")
    }
    if (this.checkedTetesStructure.includes(TETES_STRINGS.AUTRE)) {
      tetes.push("AUTRE")
    }

    this.structure.tetesStructure = tetes
    this.updateStructure()
  }

  updateAutreTeteStructure(value: string): void {
    this.structure.autreTeteStructure = value
    this.updateStructure
  }
}
