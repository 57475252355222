/*-
 * #%L
 * Aires Éducatives
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
import { FeedbackDTO } from "@/model/bean/GeneratedDTOs"
import { AbstractTypedRestService } from "@/services/rest/AbstractTypedRestService"

export class FeedbackService extends AbstractTypedRestService<FeedbackDTO> {
  static INSTANCE = new FeedbackService()

  async sendFeedback(feedback: FeedbackDTO): Promise<FeedbackDTO> {
    return super.typedPutV2("/feedback", 2, feedback)
  }
}
