





















































import { Component, Prop, Vue } from "vue-property-decorator"
import TitleView from "@/views/commons/TitleView.vue"
import PageView from "@/views/commons/PageView.vue"
import ContentView from "@/views/commons/ContentView.vue"

import { EtablissementDTO, UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import { UsersService } from "@/services/rest/users/UsersService"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import { LoginService } from "@/services/rest/login/LoginService"
import { OrganismeService } from "@/services/rest/organisme/OrganismeService"
import EtablissementView from "@/views/commons/EtablissementView.vue"
import EtablissementSelectionView from "@/views/login/registration/EtablissementSelectionView.vue"
import EtablissementEnseignantsView from "@/views/etablissement/EtablissementEnseignantsView.vue"
import EtablissementProjetsView from "@/views/etablissement/EtablissementProjetsView.vue"
import {
  BackToPreviousScreenItem,
  ContextMenu,
  NavbarItem,
  NavbarItemWithoutLink,
} from "@/views/menus/MenuItems"

@Component({
  components: {
    TitleView,
    PageView,
    ContentView,
    EtablissementView,
    EtablissementSelectionView,
    EtablissementEnseignantsView,
    EtablissementProjetsView,
  },
})
export default class EtablissementPage extends Vue {
  @Prop() etablissementId?: string
  @Prop({ default: "details" }) section: string

  private organismeService = OrganismeService.INSTANCE
  private usersService = UsersService.INSTANCE
  private infoReportingService = InfoReportingService.INSTANCE
  private loginService = LoginService.INSTANCE

  user = new UtilisateurDTO()
  etablissement = new EtablissementDTO()
  readonly = true
  isFromEtablissement = false
  isAccompagnateurOrAdmin = false
  isObservateurNational = false
  isChanging = false

  created(): void {
    const loggedUser = this.loginService.getLoggedUser()
    if (!loggedUser || !loggedUser.id) {
      return
    }

    this.isAccompagnateurOrAdmin = this.usersService.isAccompagnateurOrAdmin(loggedUser)
    this.isObservateurNational = this.usersService.isObservateurNational(loggedUser)
    this.readonly =
      loggedUser.profil !== "SUPER_ADMINISTRATEUR" &&
      loggedUser.profil !== "ACCOMPAGNATEUR_NATIONAL" &&
      loggedUser.profil !== "ANIMATEUR_REGIONAL"

    if (!this.etablissementId) {
      this.getEtablissementFromLoggedUser(loggedUser.id)
    } else {
      this.getEtablissementFromServer(loggedUser, this.etablissementId)
    }
  }

  async getEtablissementFromLoggedUser(id: string): Promise<void> {
    this.user = await this.usersService.getUserProfile(id)
    if (this.user && this.user.etablissement) {
      this.etablissement = this.user.etablissement as EtablissementDTO
      this.isFromEtablissement = true
      this.readonly = false
    }
    this.refreshContextMenu(false)
  }

  async getEtablissementFromServer(
    loggedUser: UtilisateurDTO,
    etablissementId: string
  ): Promise<void> {
    this.user = loggedUser
    this.etablissement = await this.organismeService.getEtablissement(etablissementId)
    this.isFromEtablissement = this.user.etablissement?.id == this.etablissement?.id
    this.readonly =
      !this.isFromEtablissement &&
      loggedUser.profil !== "SUPER_ADMINISTRATEUR" &&
      loggedUser.profil !== "ACCOMPAGNATEUR_NATIONAL" &&
      loggedUser.profil !== "ANIMATEUR_REGIONAL"
    this.refreshContextMenu(!this.isFromEtablissement)
  }

  changeEtablissement(etablissement: EtablissementDTO): void {
    this.etablissement = etablissement
    this.user.etablissement = etablissement

    this.usersService.updateProfile(this.user).then(
      (utilisateur: UtilisateurDTO) => {
        this.user = utilisateur
        this.infoReportingService.success(
          this.$i18n.t("profilePage.profile-modification-ok").toString()
        )
        this.isChanging = false
      },
      (_reject) => {
        this.infoReportingService.error(
          this.$i18n.t("profilePage.modification-error").toString(),
          _reject
        )
        this.isChanging = false
      }
    )
  }

  updateEtablissement(): void {
    //@ts-ignore
    if (this.$refs.etablissementView.getNumberOfWarningsOnAllTabs() !== 0) {
      this.infoReportingService.error(this.$t("profilePage.field-errors").toString(), new Error())
      return
    }

    this.infoReportingService.dialog(
      this.$t("profilePage.confirmation").toString(),
      this.$t("profilePage.etablissement.are-you-sure").toString(),
      this.$t("profilePage.etablissement.save").toString(),
      () => this.validateEtablissementModifications(this.etablissement as EtablissementDTO),
      "is-warning"
    )
  }

  validateEtablissementModifications(etablissement: EtablissementDTO): void {
    this.organismeService.updateEtablissement(etablissement).then(
      (_success) => {
        this.infoReportingService.success(
          this.$i18n.t("profilePage.etablissement.modification-ok").toString()
        )
      },
      (_reject) => {
        this.infoReportingService.error(
          this.$i18n.t("profilePage.modification-error").toString(),
          _reject
        )
      }
    )
  }

  refreshContextMenu(showBackToListMenuEntry: boolean): void {
    const menuItems = []
    if (this.etablissement) {
      const etablissementId = this.etablissement.id
      // Back to previous screen (if comming from list)
      if (showBackToListMenuEntry) {
        menuItems.push(new BackToPreviousScreenItem())
      }

      // Current Etablissement
      menuItems.push(new NavbarItemWithoutLink(this.etablissement.nom, ""))

      // Sub-sections
      if (this.isAccompagnateurOrAdmin || this.isFromEtablissement || this.isObservateurNational) {
        menuItems.push(
          new NavbarItem(
            "/etablissement/" + etablissementId + "/projets",
            this.$t("structurePage.liste-projets").toString(),
            "clipboard-list",
            false
          )
        )
        menuItems.push(
          new NavbarItem(
            "/etablissement/" + etablissementId + "/enseignants",
            this.$t("etablissementPage.enseignants").toString(),
            "users",
            false
          )
        )
      }
      menuItems.push(
        new NavbarItem(
          "/etablissement/" + etablissementId + "/details",
          this.$t("etablissementPage.details").toString(),
          "cog",
          false
        )
      )
      const possibleMenuParents = ["/etablissement/details"]
      this.$root.$emit("contextual-menu-changed", new ContextMenu(possibleMenuParents, menuItems))
    }
  }
}
