













import { Component, Vue } from "vue-property-decorator"
import PDFLinkWithModal from "@/views/commons/PDFLinkWithModal.vue"

@Component({
  components: { PDFLinkWithModal },
})
export default class Footer extends Vue {}
