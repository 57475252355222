


















































































































import { ProjetAnnuelDTO } from "@/model/bean/GeneratedDTOs"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import FileWithValidation from "@/views/inputs/FileWithValidation.vue"
import { FileService } from "@/services/rest/utils/FileService"
import GroupCheckboxesWithValidation from "@/views/inputs/GroupCheckBoxesWithValidation.vue"

const OBJECTIF_STRINGS = {
  ENRICHISSEMENT:
    "<b>Enrichissement de l'étude du site de l'année passée et comparaison</b><br/>redéfinition des objectifs de l'aire éducative en fontion des nouveaux éléments<br/>",
  NOUVELLE_ETUDE:
    "<b>Nouvelle étude du site sans utiliser la précédente</b><br/>nouveaux objectifs, nouvelles actions<br/>",
  REPRISE: "<b>Reprise des objectifs/actions de l'an passé</b><br/> sans nouvelle étude<br/>",
  AUTRE: "<b>Autre</b>",
}

@Component({
  components: { InputWithValidation, FileWithValidation, GroupCheckboxesWithValidation },
})
export default class ProblematiquesOrientationsActionsView extends Vue {
  private fileService = FileService.INSTANCE

  @Prop() projetAnnuel: ProjetAnnuelDTO
  @Prop() readonly: boolean
  @Prop() isPorteurProjet: boolean
  @Prop({ default: false }) validationActivated: boolean
  @Prop({ default: true }) showValidationErrors: boolean

  problematiquesFileUrl = ""
  activitesTransDisFileUrl = ""
  calculatedRules = "required"
  showRequiredMessage = true

  objectifCheckBoxesStrings = Object.values(OBJECTIF_STRINGS).filter(
    (value) => value !== OBJECTIF_STRINGS.AUTRE
  )

  checkedObjectifCheckBoxesStrings: string[] = []

  created(): void {
    if (this.projetAnnuel.id) {
      this.problematiquesFileUrl = this.fileService.buildUniqueFileURL(
        this.projetAnnuel.id,
        "PROBLEMATIQUES"
      )
      this.activitesTransDisFileUrl = this.fileService.buildUniqueFileURL(
        this.projetAnnuel.id,
        "ACTIVITES_TRANSDISCIPLINAIRES"
      )
    }
    const objectifString: string[] = []
    if (this.projetAnnuel.objectifs) {
      if (this.projetAnnuel.objectifs.includes("ENRICHISSEMENT")) {
        objectifString.push(OBJECTIF_STRINGS.ENRICHISSEMENT)
      }
      if (this.projetAnnuel.objectifs.includes("NOUVELLE_ETUDE")) {
        objectifString.push(OBJECTIF_STRINGS.NOUVELLE_ETUDE)
      }
      if (this.projetAnnuel.objectifs.includes("REPRISE")) {
        objectifString.push(OBJECTIF_STRINGS.REPRISE)
      }
      if (this.projetAnnuel.objectifs.includes("AUTRE")) {
        objectifString.push(OBJECTIF_STRINGS.AUTRE)
      }
    }
    this.checkedObjectifCheckBoxesStrings = objectifString
  }

  mounted(): void {
    this.updateProblematiquesLiensRules()
  }

  @Watch("projetAnnuel")
  dossierChanged(): void {
    if (!this.projetAnnuel || !this.projetAnnuel.id) {
      return
    }
    this.problematiquesFileUrl = this.fileService.buildUniqueFileURL(
      this.projetAnnuel.id,
      "PROBLEMATIQUES"
    )
  }

  @Watch("projetAnnuel.conseilsLiens")
  @Watch("projetAnnuel.diffusionLiensEnseignements")
  @Watch("projetAnnuel.etatDesLieuxLiensEnseignements")
  @Watch("projetAnnuel.problematiquesLiens")
  updateProblematiquesLiensRules(): void {
    if (
      !this.projetAnnuel.conseilsLiens &&
      !this.projetAnnuel.diffusionLiensEnseignements &&
      !this.projetAnnuel.etatDesLieuxLiensEnseignements
    ) {
      this.calculatedRules = "required"
    } else {
      if (this.projetAnnuel.problematiquesLiens) {
        this.calculatedRules = "required"
      } else {
        this.calculatedRules = ""
      }
    }

    if (
      this.projetAnnuel.problematiquesLiens ||
      this.projetAnnuel.conseilsLiens ||
      this.projetAnnuel.diffusionLiensEnseignements ||
      this.projetAnnuel.etatDesLieuxLiensEnseignements
    ) {
      this.showRequiredMessage = false
    } else {
      this.showRequiredMessage = true
    }
  }

  changeProblematiques(): void {
    this.updateProblematiquesLiensRules()
    this.$emit("dirtify-project")
  }

  addFile(): void {
    this.projetAnnuel.problematiquesFilePresent = true
    this.$emit("trigger-validation")
  }

  deleteFile(): void {
    this.projetAnnuel.problematiquesFilePresent = false
    this.$emit("trigger-validation")
  }

  addTransdisFile(): void {
    // this.projetAnnuel.activitesTransDisFilePresent = true
    this.$emit("trigger-validation")
  }

  deleteTransdisFile(): void {
    //this.projetAnnuel.activitesTransDisFilePresent = false
    this.$emit("trigger-validation")
  }
}
