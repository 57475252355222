var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("SubTitleView", [
        _vm._v(_vm._s(_vm.$t("profilePage.etablissement.contact")) + " ")
      ]),
      _c("AdresseView", {
        attrs: {
          readonly: _vm.readonly,
          withPays: "true",
          validationGroup: _vm.validationGroup
        },
        on: { "change-address": _vm.updateEtablissementContact },
        model: {
          value: _vm.etablissement,
          callback: function($$v) {
            _vm.etablissement = $$v
          },
          expression: "etablissement"
        }
      }),
      _c("InputWithValidation", {
        attrs: {
          rules: "required|email",
          label: _vm.$t("profilePage.etablissement.courriel"),
          placeholder: _vm.$t("profilePage.etablissement.placeholder.courriel"),
          disabled: _vm.readonly,
          group: _vm.validationGroup,
          vid: "courrielEtablissement",
          autocomplete: "etablissementEmail"
        },
        on: { "manual-change": _vm.updateEtablissementContact },
        model: {
          value: _vm.etablissement.courriel,
          callback: function($$v) {
            _vm.$set(_vm.etablissement, "courriel", $$v)
          },
          expression: "etablissement.courriel"
        }
      }),
      _c("InputWithValidation", {
        attrs: {
          rules: "required|validPhoneNumber",
          type: "tel",
          label: _vm.$t("profilePage.etablissement.telephone"),
          placeholder: _vm.$t(
            "profilePage.etablissement.placeholder.telephone"
          ),
          disabled: _vm.readonly,
          group: _vm.validationGroup,
          vid: "telephoneEtablissement"
        },
        on: { "manual-change": _vm.updateEtablissementContact },
        model: {
          value: _vm.etablissement.telephone,
          callback: function($$v) {
            _vm.$set(_vm.etablissement, "telephone", $$v)
          },
          expression: "etablissement.telephone"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }