import { render, staticRenderFns } from "./LabellisationResult.vue?vue&type=template&id=22c0737e&"
import script from "./LabellisationResult.vue?vue&type=script&lang=ts&"
export * from "./LabellisationResult.vue?vue&type=script&lang=ts&"
import style0 from "./LabellisationResult.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/ofb/sagae/src/main/webapp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('22c0737e')) {
      api.createRecord('22c0737e', component.options)
    } else {
      api.reload('22c0737e', component.options)
    }
    module.hot.accept("./LabellisationResult.vue?vue&type=template&id=22c0737e&", function () {
      api.rerender('22c0737e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/projet-page/labellisation/LabellisationResult.vue"
export default component.exports