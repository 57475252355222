var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "SubHeaderItem" },
      [
        _vm.icon
          ? _c("b-icon", {
              attrs: { icon: _vm.icon, type: _vm.type, size: "is-medium" }
            })
          : _vm._e(),
        _c(
          "div",
          { staticClass: "SubHeaderItem-content" },
          [_vm._t("default")],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }