


















































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import { FinancementService } from "@/services/rest/financement/FinancementService"
import TitleView from "@/views/commons/TitleView.vue"
import ContentView from "@/views/commons/ContentView.vue"
import BookmarkCard from "../generic_components/BookmarkCard.vue"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import SelectWithValidation from "@/views/inputs/SelectWithValidation.vue"
import DatePickerWithValidation from "@/views/inputs/DatePickerWithValidation.vue"
import { ValidationObserver } from "vee-validate"
import { TAG_TYPES_FOR_BOOKMARK_CARD } from "@/model/bean/EnumHelper"
import { FinancementDTO, OrigineFinancement, ProjetAnnuelDTO } from "@/model/bean/GeneratedDTOs"
import ConversionUtils from "@/services/ConversionUtils"

@Component({
  components: {
    TitleView,
    ContentView,
    BookmarkCard,
    InputWithValidation,
    DatePickerWithValidation,
    SelectWithValidation,
    ValidationObserver,
  },
})
export default class FinancementCard extends Vue {
  @Prop({ default: () => undefined })
  financement: FinancementDTO | undefined
  @Prop()
  projetAnnuel: ProjetAnnuelDTO
  @Prop()
  canDelete: boolean
  financementAdded = false

  hasAtLeastOneFieldValued = false

  $refs!: {
    financementObserver: InstanceType<typeof ValidationObserver>
  }

  protected infoReportingService = InfoReportingService.INSTANCE
  private financementService = FinancementService.INSTANCE

  protected tagType = TAG_TYPES_FOR_BOOKMARK_CARD.NOUVEAU_FINANCEMENT
  protected readOnly = false
  protected iconName = "plus"

  protected internalFinancement = new FinancementDTO()

  origines: OrigineFinancement[] = [
    "AGENCE_EAU",
    "APPEL_PROJET",
    "AUTRE",
    "COMMUNAUTE_COMMUNE",
    "COMMUNE",
    "DEPARTEMENT",
    "EUROPE",
    "FONDATION_PRIVEE",
    "OFB",
    "INTERVENTION_EDD",
    "REGION",
    "STRUCTURE_GEST_ENV",
  ]
  originesLabels = this.origines.map((value) => {
    return this.$t(`projetPage.infoAdministratives.financement.origineLabels.${value}`).toString()
  })

  mounted(): void {
    this.financementChanged()
    if (
      this.internalFinancement.origine !== undefined &&
      this.internalFinancement.origine !== null
    ) {
      this.readOnly = true
      this.tagType = TAG_TYPES_FOR_BOOKMARK_CARD.FINANCEMENT
      this.iconName = "euro-sign"
    }
  }

  addFinancement(): void {
    this.financementAdded = false
    this.$emit("add", this.internalFinancement)
    this.financementChanged()

    //this.$refs.financementObserver.reset()
  }

  deleteFinancement(): void {
    this.infoReportingService.dialog(
      this.$t("projetPage.infoAdministratives.financement.confirmation").toString(),
      this.$t("projetPage.infoAdministratives.financement.are-you-sure").toString(),
      this.$t("projetPage.infoAdministratives.financement.supprimer").toString(),
      () => {
        this.$emit("delete", this.financement)
      },
      "is-info"
    )
  }

  @Watch("financement")
  financementChanged(): void {
    if (this.financement) {
      this.financementAdded = true
      this.internalFinancement = ConversionUtils.convertFinancementDatesToLocal(this.financement)
      // @ts-ignore
      this.internalFinancement.internal_id =
        // @ts-ignore
        this.financement.id || this.internalFinancement.internal_id
    } else {
      this.internalFinancement = new FinancementDTO()
      // @ts-ignore
      this.internalFinancement.internal_id = "new-" + (this.projetAnnuel.financements.length + 1)
    }
    requestAnimationFrame(() => {
      this.$refs.financementObserver.reset()
    })
  }

  @Watch("projetAnnuel")
  projetAnnuelChanged(): void {
    requestAnimationFrame(() => {
      this.$refs.financementObserver.reset()
    })
  }

  isValid(): boolean {
    return this.financementService.isValid(
      this.projetAnnuel,
      this.internalFinancement.debut,
      this.internalFinancement.fin
    )
  }
}
