






import { Component, Prop, Vue } from "vue-property-decorator"

@Component
export default class ContentView extends Vue {
  // Indicates if content view should take the whole screen (usefull for big arrays for instance)
  @Prop({ default: false }) wideScreen: boolean
}
