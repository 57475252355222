























































import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import { ValidationProvider } from "vee-validate"
import CheckBoxEntryInput from "./CheckBoxEntryInput.vue"

@Component({
  components: { ValidationProvider, CheckBoxEntryInput },
})
export default class CheckBoxEntry extends Vue {
  @Prop({ default: "" }) vid: string
  @Prop({ default: "" }) group: string
  @Prop({ default: false }) isChecked: boolean
  @Prop({ default: "" }) entryLabel: string
  @Prop({ default: "" }) entryInputPlaceholder: string
  @Prop({ default: "" }) entryInputValue: string
  @Prop({ default: "" }) extraInput1Control: string
  @Prop({ default: "" }) extraInput1Placeholder: string
  @Prop({ default: 0 }) extraInput1Value: number
  @Prop({ default: "" }) extraInput1Rule: string
  @Prop({ default: "" }) extraInput2Control: string
  @Prop({ default: "" }) extraInput2Placeholder: string
  @Prop({ default: 0 }) extraInput2Value: number
  @Prop({ default: "" }) extraInput2Rule: string
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: false }) hideExtrasUntilChecked: boolean

  entryInputType = ""
  innerChecked = false
  resetInputs = false

  created(): void {
    this.innerChecked = this.isChecked
  }

  @Watch("innerChecked")
  onChecked(): void {
    this.resetInputs = !this.innerChecked
  }
}
