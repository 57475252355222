


























import { Component, Prop, Vue } from "vue-property-decorator"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import SubTitleView from "@/views/commons/SubTitleView.vue"
import { EtablissementDTO } from "@/model/bean/GeneratedDTOs"

@Component({
  components: {
    InputWithValidation,
    SubTitleView,
  },
})
export default class EtablissementChefDetails extends Vue {
  @Prop() etablissement: EtablissementDTO
  @Prop({ default: false }) readonly: boolean
  @Prop() validationGroup: string

  updateEtablissementChefEtablissement(): void {
    this.$emit("update-etablissement", this.etablissement)
  }
}
