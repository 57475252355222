var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "SubVerticalMenu " + (_vm.toggleOpen ? "open" : "") },
    [
      _c(
        "div",
        { ref: "start", staticClass: "SubVerticalMenu-start" },
        _vm._l(_vm.items, function(item) {
          return _c("div", { key: item.label }, [
            item.type == "BACK_TO_PREVIOUS_SCREEN"
              ? _c("div", { staticClass: "SubVerticalMenu-back-to-previous" }, [
                  _c(
                    "div",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$emit("back-to-previous")
                        }
                      }
                    },
                    [
                      _c("b-icon", {
                        attrs: {
                          size: _vm.toggleOpen ? "is-normal" : "is-large",
                          icon: "caret-left"
                        }
                      }),
                      _vm.toggleOpen
                        ? _c("span", [
                            _vm._v(" " + _vm._s(_vm.previousScreenName) + " ")
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ])
              : item.type == "NO_LINK" && _vm.toggleOpen
              ? _c(
                  "div",
                  { staticClass: "SubVerticalMenu-item no-link" },
                  [
                    item.icon.length > 0
                      ? _c("b-icon", {
                          staticClass: "icon",
                          attrs: { size: "is-normal", icon: item.icon }
                        })
                      : _c("span", { staticClass: "empty-icon-spacer" }),
                    _vm.toggleOpen
                      ? _c("span", { staticClass: "label" }, [
                          _vm._v(" " + _vm._s(item.label) + " ")
                        ])
                      : _vm._e()
                  ],
                  1
                )
              : item.type == "NORMAL"
              ? _c(
                  "div",
                  {
                    on: {
                      click: function($event) {
                        _vm.isDisplayPorteurProjet(item.label)
                          ? _vm.resetNotif()
                          : function() {
                              return false
                            }
                      }
                    }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "SubVerticalMenu-item SubVerticalMenu-item-hoverable",
                        class: {
                          "router-link-active": item.isLinkActive
                        },
                        attrs: { to: { path: item.toPath } }
                      },
                      [
                        _vm.allowedMenus.includes(item.label)
                          ? _c("div", {
                              class: {
                                "menu-item-vertical-bar":
                                  item.label !== "Historique",
                                "router-link-desactive": item.isLinkActive
                              }
                            })
                          : _vm._e(),
                        item.icon.length > 0
                          ? _c("b-icon", {
                              staticClass: "item-icon",
                              attrs: { size: "is-normal", icon: item.icon }
                            })
                          : _c("span", { staticClass: "empty-icon-spacer" }),
                        _vm.toggleOpen
                          ? _c(
                              "span",
                              { staticClass: "item-label label-container" },
                              [
                                _vm._v(" " + _vm._s(item.label) + " "),
                                _vm.shouldDisplayBadge(item.label)
                                  ? _c("span", { staticClass: "badge" }, [
                                      _vm._v(_vm._s(_vm.pendingRequest.users))
                                    ])
                                  : _vm._e(),
                                _vm.isDisplayPorteurProjet(item.label)
                                  ? _c(
                                      "span",
                                      { staticClass: "badge porteur" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.countModifPorteurGrae)
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "SubVerticalMenu-arrow" })
                      ],
                      1
                    ),
                    _vm._l(item.children, function(bulletItem) {
                      return _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.isLinkActive,
                              expression: "item.isLinkActive"
                            }
                          ],
                          key: bulletItem.path,
                          staticClass: "SubVerticalMenu-bullet",
                          class: {
                            active: _vm.activeAnchor == bulletItem.toPath
                          }
                        },
                        [
                          _c(
                            "a",
                            { attrs: { href: "#" + bulletItem.toPath } },
                            [
                              _c("div", { staticClass: "bullet-container" }, [
                                _c("div", {
                                  staticClass: "bullet",
                                  class: {
                                    none:
                                      bulletItem.validationStatus == 4 ||
                                      bulletItem.validationStatus == 5,
                                    valid: bulletItem.validationStatus == 0,
                                    warning:
                                      bulletItem.validationStatus == 1 ||
                                      bulletItem.validationStatus == 2,
                                    error: bulletItem.validationStatus == 3
                                  }
                                })
                              ]),
                              _vm.toggleOpen
                                ? _c("span", [
                                    _vm._v(" " + _vm._s(bulletItem.label) + " ")
                                  ])
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    })
                  ],
                  2
                )
              : _vm._e()
          ])
        }),
        0
      ),
      _c("div", { staticClass: "SubVerticalMenu-end" }, [
        _c(
          "div",
          {
            staticClass: "SubVerticalMenu-arrow-button clickable",
            on: { click: _vm.toggleOpenMenu }
          },
          [
            _c("b-icon", {
              attrs: {
                size: "is-normal",
                icon:
                  "" +
                  (_vm.toggleOpen ? "angle-double-left" : "angle-double-right")
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }