var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a",
        {
          staticClass: "PdfWithModal clickable",
          on: {
            click: function($event) {
              _vm.isPDFModalActive = true
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.linkText) + " ")]
      ),
      _c(
        "b-modal",
        {
          model: {
            value: _vm.isPDFModalActive,
            callback: function($$v) {
              _vm.isPDFModalActive = $$v
            },
            expression: "isPDFModalActive"
          }
        },
        [
          _c("div", [
            _c(
              "a",
              { attrs: { href: _vm.getPdfUrl(), target: "pdf" } },
              [
                _c("pdf", {
                  staticClass: "pdfViewer",
                  attrs: { src: _vm.getPdfUrl(), page: _vm.page },
                  on: {
                    "num-pages": function($event) {
                      _vm.numPages = $event
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "navigationButtonContainer" },
              [
                _vm.page > 1
                  ? _c(
                      "b-button",
                      {
                        staticClass: "navigationButton is-pulled-left",
                        attrs: { type: "is-primary" },
                        on: {
                          click: function($event) {
                            _vm.page--
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("registerPage.precedent")))]
                    )
                  : _vm._e(),
                _vm.page < _vm.numPages
                  ? _c(
                      "b-button",
                      {
                        staticClass: "navigationButton is-pulled-right",
                        attrs: { type: "is-primary" },
                        on: {
                          click: function($event) {
                            _vm.page++
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("registerPage.suivant")))]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }