/*-
 * #%L
 * Aires Éducatives
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
import Vue from "vue"
import { AccessRestriction, AccessRestrictionChecker } from "@/router/AccessRestriction"
import VueRouter, { RouteConfig } from "vue-router"
import HomePage from "@/pages/HomePage.vue"
import LoginPage from "@/pages/login/LoginPage.vue"
import ResetPasswordPage from "@/pages/login/ResetPasswordPage.vue"
import RegisterPage from "@/pages/login/registration/RegisterPage.vue"
import RegisterPendingPage from "@/pages/login/registration/RegisterPendingPage.vue"
import ValidateEmailPage from "@/pages/login/registration/ValidateEmailPage.vue"
import ForgotPasswordPage from "@/pages/login/ForgotPasswordPage.vue"
import UsersListPage from "@/pages/users_management/UsersListPage.vue"
import GraeListPage from "@/pages/grae/GraeListPage.vue"
import CartePage from "@/pages/carte/CartePage.vue"
import ModifProjetPage from "@/pages/modif_projet/ModifProjetPage.vue"
import GraePage from "@/pages/grae/GraePage.vue"
import IndicateursPage from "@/pages/grae/IndicateursPage.vue"
import AEPage from "@/pages/grae/AEPage.vue"
import ActualitesNationalesPage from "@/pages/grae/ActualitesNationalesPage.vue"
import InstructionPage from "@/pages/instruction/InstructionPage.vue"
import ProjetDetailsPage from "@/pages/projet-page/commons/ProjetDetailsPage.vue"
import NotFound404Page from "@/pages/NotFound404Page.vue"
import ParametersPage from "@/pages/parameters/ParametersPage.vue"
import DocumentsPage from "@/pages/document/DocumentsPage.vue"
import MapDrawView from "@/views/maps/MapDrawView.vue"
import AcceptOrDeclineProjectInvitationPage from "@/pages/users_management/AcceptOrDeclineProjectInvitationPage.vue"
import MyProjectsPage from "@/pages/porteur_projet_pages/MyProjectsPage.vue"
import StructurePage from "@/pages/porteur_projet_pages/StructurePage.vue"
import EtablissementPage from "@/pages/porteur_projet_pages/EtablissementPage.vue"
import CreateProjectView from "@/views/projects_pp/CreateProjectView.vue"
import ChoseEtablissementView from "@/views/projects_pp/ChoseEtablissementView.vue"
import JoinProjectBeforeAskingView from "@/views/projects_pp/JoinProjectBeforeAskingView.vue"
import AskForProjectCreationView from "@/views/projects_pp/AskForProjectCreationView.vue"
import PageChangeForbidder from "./PageChangeForbidder"

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
  },
  // Login routes
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
  },
  {
    path: "/register/:registerStep/:urlTabIndex",
    name: "Register",
    component: RegisterPage,
    props: true,
  },
  {
    path: "/register-pending/:initialResendMode",
    name: "RegisterPending",
    component: RegisterPendingPage,
    props: true,
  },

  {
    path: "/validate-email/:validationToken",
    name: "Validate Email",
    component: ValidateEmailPage,
    props: true,
  },

  {
    path: "/forgot-password/:defaultEmail?",
    name: "Forgot Password",
    component: ForgotPasswordPage,
    props: true,
  },
  {
    path: "/reset-password/:token",
    name: "Reset Password",
    component: ResetPasswordPage,
    props: true,
  },
  // Accept route
  {
    path: "/project-invitation/:projetId/:acceptOrDecline/:userId",
    name: "ProjectInvitation",
    component: AcceptOrDeclineProjectInvitationPage,
    props: true,
    meta: { accessRestrictions: [AccessRestriction.AUTHENTICATED_ANY] },
  },

  // Account routes
  {
    path: "/myProjects",
    name: "MyProjects",
    component: MyProjectsPage,
    meta: { accessRestrictions: [AccessRestriction.AUTHENTICATED_ANY] },
    children: [
      {
        path: "new",
        name: "ChoseEtablissementView",
        component: ChoseEtablissementView,
        meta: { accessRestrictions: [AccessRestriction.AUTHENTICATED_ANY] },
      },
      {
        path: "new/:codeEtablissement",
        name: "CreateProjectView",
        component: CreateProjectView,
        meta: { accessRestrictions: [AccessRestriction.AUTHENTICATED_ANY] },
      },
      {
        path: "new/:codeEtablissement/join",
        name: "JoinProjectBeforeAskingView",
        component: JoinProjectBeforeAskingView,
        meta: { accessRestrictions: [AccessRestriction.AUTHENTICATED_ANY] },
      },
      {
        path: "new/:codeEtablissement/ask",
        name: "AskForProjectCreationView",
        component: AskForProjectCreationView,
        meta: { accessRestrictions: [AccessRestriction.AUTHENTICATED_ANY] },
      },
    ],
  },
  {
    path: "/structure/:section",
    name: "StructurePage",
    component: StructurePage,
    meta: { accessRestrictions: [AccessRestriction.AUTHENTICATED_ANY] },
    props: true,
  },
  {
    path: "/structure/:structureId/:section",
    name: "StructurePage WithIdAndSection",
    component: StructurePage,
    meta: { accessRestrictions: [AccessRestriction.AUTHENTICATED_ANY] },
    props: true,
  },
  {
    path: "/etablissement/:section",
    name: "EtablissementPage",
    component: EtablissementPage,
    meta: { accessRestrictions: [AccessRestriction.AUTHENTICATED_ANY] },
    props: true,
  },
  {
    path: "/etablissement/:etablissementId/:section",
    name: "EtablissementPage WithIdAndSection",
    component: EtablissementPage,
    meta: { accessRestrictions: [AccessRestriction.AUTHENTICATED_ANY] },
    props: true,
  },
  {
    path: "/parameters",
    name: "Parameters",
    component: ParametersPage,
    meta: {
      accessRestrictions: [AccessRestriction.AUTHENTICATED_CAN_UPDATE_PARAMETERS],
    },
  },
  {
    path: "/documents",
    name: "Documents",
    component: DocumentsPage,
    meta: {
      accessRestrictions: [AccessRestriction.AUTHENTICATED_CAN_UPDATE_PARAMETERS],
    },
  },
  {
    path: "/users-list",
    name: "Users List",
    component: UsersListPage,
    meta: { accessRestrictions: [AccessRestriction.AUTHENTICATED_CAN_VALIDATE_ACCOUNTS] },
    props: true,
  },
  {
    name: "Map",
    path: "/map",
    component: MapDrawView,
  },
  {
    path: "/indicateurs",
    name: "IndicateursPage",
    component: IndicateursPage,
    meta: { accessRestrictions: [AccessRestriction.AUTHENTICATED_CAN_VALIDATE_ACCOUNTS] },
    props: true,
  },
  {
    path: "/grae-list",
    name: "GRAE List",
    component: GraeListPage,
    meta: {
      accessRestrictions: [AccessRestriction.AUTHENTICATED_CAN_MANAGE_GRAE],
    },
  },
  {
    path: "/carte",
    name: "Carte des projets",
    component: CartePage,
    meta: {
      accessRestrictions: [AccessRestriction.AUTHENTICATED_ANY],
    },
  },
  {
    path: "/ModifProjet",
    name: "Modification des porteurs de projet",
    component: ModifProjetPage,
    meta: {
      accessRestrictions: [AccessRestriction.AUTHENTICATED_CAN_VALIDATE_ACCOUNTS],
    },
  },
  {
    path: "/grae/:section/:activeSubTab/:graeId",
    name: "GraePage WithSubtabAndId",
    component: GraePage,
    meta: { accessRestrictions: [AccessRestriction.AUTHENTICATED_CAN_VALIDATE_ACCOUNTS] },
    props: true,
  },
  {
    path: "/grae/:section/:activeSubTab",
    name: "GraePage WithSubTab",
    component: GraePage,
    meta: { accessRestrictions: [AccessRestriction.AUTHENTICATED_CAN_VALIDATE_ACCOUNTS] },
    props: true,
  },
  {
    path: "/grae/:section",
    name: "GraePage",
    component: GraePage,
    meta: { accessRestrictions: [AccessRestriction.AUTHENTICATED_CAN_VALIDATE_ACCOUNTS] },
    props: true,
  },
  {
    path: "/ae/:activeSubTab",
    name: "AEPage WithSubTab",
    component: AEPage,
    meta: { accessRestrictions: [AccessRestriction.AUTHENTICATED_CAN_VALIDATE_ACCOUNTS] },
    props: true,
  },
  {
    path: "/actualites",
    name: "ActualitesNationalesPage",
    component: ActualitesNationalesPage,
    meta: { accessRestrictions: [AccessRestriction.AUTHENTICATED_CAN_VALIDATE_ACCOUNTS] },
  },
  {
    path: "/instruction/:section/:activeSubTab/:activeQuickFilter/:graeId",
    name: "InstructionPage WithSubtabAndId",
    component: InstructionPage,
    meta: { accessRestrictions: [AccessRestriction.AUTHENTICATED_CAN_VALIDATE_ACCOUNTS] },
    props: true,
  },
  {
    path: "/instruction/:section/:activeSubTab/:activeQuickFilter",
    name: "InstructionPage WithSubTab",
    component: InstructionPage,
    meta: { accessRestrictions: [AccessRestriction.AUTHENTICATED_CAN_VALIDATE_ACCOUNTS] },
    props: true,
  },
  {
    path: "/instruction/:section",
    name: "InstructionPage",
    component: InstructionPage,
    meta: { accessRestrictions: [AccessRestriction.AUTHENTICATED_CAN_VALIDATE_ACCOUNTS] },
    props: true,
  },
  {
    path: "/project/:projectId/:annualProjectId/:section/:activeTab",
    name: "Project Details",
    component: ProjetDetailsPage,
    props: true,
    meta: {
      accessRestrictions: [AccessRestriction.AUTHENTICATED_ANY],
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../pages/AboutPage.vue"),
  },
  {
    path: "*",
    name: "404",
    component: NotFound404Page,
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

const accessRestrictionChecker = new AccessRestrictionChecker()
router.beforeEach((to, from, next) => {
  /// Step 1: check if page change is forbiden (typically for a project with unsaved changes)
  const newAndOldRoutesHaveSameName = to.name == from.name

  // Page change can be allowed only for routes with same names (e.g. tabs of a same projet) or not allowed at all
  if (
    PageChangeForbidder.INSTANCE.pageChangeForbidden &&
    (!PageChangeForbidder.INSTANCE.allowPageChangeIfNewRouteHasTheSameName ||
      (PageChangeForbidder.INSTANCE.allowPageChangeIfNewRouteHasTheSameName &&
        !newAndOldRoutesHaveSameName))
  ) {
    PageChangeForbidder.INSTANCE.callbackActionWhenForbidden(next)
  } else {
    // Step 2: Protect routes according to authentication status
    if ("/" !== to.fullPath.toString()) {
      // Check access restrictions for the route (OK if none declared)
      accessRestrictionChecker.checkAccessRestrictionsForRoutes(to.matched).then(
        (accessGranted) => {
          if (accessGranted) {
            next()
          } else {
            accessRestrictionChecker.redirectAfterFailure(to, next)
          }
        },
        (_accessDenied) => {
          accessRestrictionChecker.redirectAfterFailure(to, next)
        }
      )
    } else {
      next()
    }
  }
})

export default router
