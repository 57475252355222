


































































































































import { ProjetAnnuelDTO } from "@/model/bean/GeneratedDTOs"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import DocumentationLinks from "@/views/commons/DocumentationLinks.vue"
import { FileService } from "@/services/rest/utils/FileService"
import FileWithValidation from "@/views/inputs/FileWithValidation.vue"

@Component({
  components: {
    DocumentationLinks,
    InputWithValidation,
    FileWithValidation,
  },
})
export default class ProjetDiffusionView extends Vue {
  @Prop() projetAnnuel: ProjetAnnuelDTO
  @Prop() readonly: boolean
  @Prop() isPorteurProjet: boolean
  @Prop({ default: false }) validationActivated: boolean
  @Prop({ default: true }) showValidationErrors: boolean

  fileService = FileService.INSTANCE

  calculatedRules = "required"
  showRequiredMessage = true
  adhesionDiffusionFileUrl = ""
  organisationEchangesInterAeFileUrl = ""

  mounted(): void {
    this.updateDiffusionLiensRenseignementRules()
    if (this.projetAnnuel.id) {
      this.adhesionDiffusionFileUrl = this.fileService.buildUniqueFileURL(
        this.projetAnnuel.id,
        "ADHESION_DIFFUSION"
      )
      this.organisationEchangesInterAeFileUrl = this.fileService.buildUniqueFileURL(
        this.projetAnnuel.id,
        "ECHANGES_INTER_AE"
      )
    }
  }

  @Watch("projetAnnuel.problematiquesLiens")
  @Watch("projetAnnuel.conseilsLiens")
  @Watch("projetAnnuel.etatDesLieuxLiensEnseignements")
  @Watch("projetAnnuel.diffusionLiensEnseignements")
  updateDiffusionLiensRenseignementRules(): void {
    if (
      !this.projetAnnuel.problematiquesLiens &&
      !this.projetAnnuel.conseilsLiens &&
      !this.projetAnnuel.etatDesLieuxLiensEnseignements
    ) {
      this.calculatedRules = "required"
    } else {
      if (this.projetAnnuel.diffusionLiensEnseignements) {
        this.calculatedRules = "required"
      } else {
        this.calculatedRules = ""
      }
    }

    if (
      this.projetAnnuel.problematiquesLiens ||
      this.projetAnnuel.conseilsLiens ||
      this.projetAnnuel.diffusionLiensEnseignements ||
      this.projetAnnuel.etatDesLieuxLiensEnseignements
    ) {
      this.showRequiredMessage = false
    } else {
      this.showRequiredMessage = true
    }
  }

  modified(): void {
    this.$emit("dirtify-project")
  }

  modifiedLiens(): void {
    this.updateDiffusionLiensRenseignementRules()
    this.modified()
  }

  addAdhesionDiffusionFile(): void {
    this.projetAnnuel.adhesionDiffusionFilePresent = true
    this.$emit("trigger-validation")
  }

  deleteAdhesionDiffusionFile(): void {
    this.projetAnnuel.adhesionDiffusionFilePresent = false
    this.$emit("trigger-validation")
  }

  addInterAeDiffusionFile(): void {
    this.projetAnnuel.organisationEchangesInterAePresent = true
    this.$emit("trigger-validation")
  }

  deleteInterAeDiffusionFile(): void {
    this.projetAnnuel.organisationEchangesInterAePresent = false
    this.$emit("trigger-validation")
  }
}
