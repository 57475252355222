








































import { Component, Prop, Vue } from "vue-property-decorator"
import TitleView from "@/views/commons/TitleView.vue"
import PageView from "@/views/commons/PageView.vue"
import ContentView from "@/views/commons/ContentView.vue"
import { ValidationObserver } from "vee-validate"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import PasswordCreationWithValidation from "@/views/inputs/PasswordCreationWithValidation.vue"
import { LoginService } from "@/services/rest/login/LoginService"
import { PasswordResetDTO } from "@/model/bean/GeneratedDTOs"
import { InfoReportingService } from "@/services/log/InfoReportingService"

@Component({
  components: {
    TitleView,
    PageView,
    ContentView,
    InputWithValidation,
    ValidationObserver,
    PasswordCreationWithValidation,
  },
})
export default class ResetPasswordPage extends Vue {
  @Prop({ default: "" }) token: string
  passwordResetError = ""
  password = ""
  confirmation = ""

  private loginService = LoginService.INSTANCE
  infoReportingService = InfoReportingService.INSTANCE

  submit(): void {
    const passwordReset = new PasswordResetDTO()
    passwordReset.motDePasse = this.password
    passwordReset.confirmationMotDePasse = this.confirmation
    this.loginService.resetPassword(this.token, passwordReset).then(
      (_success) => {
        // Nothing to do: user already re-routed after succesfull login (see LoginService.loginSucceeded())
      },
      (_reject) => {
        this.passwordResetError = this.$i18n.t("resetPassword.error").toString()
      }
    )
  }
}
