/*-
 * #%L
 * Aires Éducatives
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
import { LoginService } from "@/services/rest/login/LoginService"
import { EventEmitter } from "events"
import Constants from "@/services/Constants"
import HttpStatusCode from "@/services/rest/HttpStatusCode"
import { FilterDTO, PaginationParameter, PaginationResult } from "@/model/bean/GeneratedDTOs"
import { Filters } from "@/model/bean/Filters"
import { InfoReportingService } from "@/services/log/InfoReportingService"

import i18n from "@/i18n"

/* Abstract REST Service providing facilities for handling GET/POST/PUT/DELETE requests.
 * Child classes will have to override cache management informations.
 */
export abstract class AbstractRestService extends EventEmitter {
  /**
   * Performs a GET request at given url with given (optionnal) GET params.  Expect an array of T or a single T as result.
   * @param urlPath the URL to perform a GET request on
   * @param params (optionnal) GET params
   * @returns a promise that will only be resolves if responses is OK and of expect type (rejected in all other cases)
   */
  async typedGetV2<T>(
    urlPath: string,
    version: number,
    params: Map<string, string | Array<any>> = new Map<string, string | Array<any>>()
  ): Promise<T[]> {
    const apiURL: any = new URL(this.getUrl(urlPath, version))
    // Add GET parameters
    params.forEach((value, name) => {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          apiURL.searchParams.append(name, this.treatValue(v))
        })
      } else {
        apiURL.searchParams.append("" + name, value)
      }
    })
    const response = await fetch(apiURL.toString(), {
      method: "GET",
      mode: "cors",
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
    })
    return this.handleResponse(apiURL.toString(), "GET", response)
  }

  private treatValue(value: any): string {
    if (typeof value === "string" && !/\s/.test(value)) {
      return value as string
    }
    return JSON.stringify(value)
  }

  /**
   * Performs a GET request a PaginationResult of T result.
   * @param urlPath the URL to perform a GET request on
   * @param paginationParameters indicates the page size, current page, sorting for paginated result
   * @param filtersList list of filters (value filtering)
   * @returns a promise that will only be resolves if responses is OK and of expect type (rejected in all other cases)
   */
  async typeGetPaginatedV2<T>(
    urlPath: string,
    version: number,
    paginationParameters: PaginationParameter,
    filtersList: Array<FilterDTO>,
    queryParams: Map<string, string | Array<any>> = new Map<string, string | Array<any>>()
  ): Promise<PaginationResult<T>> {
    const params = new Map<string, string | Array<any>>(queryParams)
    params.set("pageSize", paginationParameters.pageSize.toString())
    params.set("pageNumber", (paginationParameters.pageNumber - 1).toString())
    params.set("orderClauses", paginationParameters.orderClauses || [])
    // Convert filters (remove unused attributes)
    const cleanFiltersList = new Array<FilterDTO>()
    filtersList.forEach((filter) => {
      cleanFiltersList.push(Filters.asFilter({ property: filter.property, values: filter.values }))
    })
    params.set("filters", cleanFiltersList)
    const result: PaginationResult<T> = await this.typedGetSingleV2(urlPath, version, params)
    // FIXME TODO temporary work-around
    // For all these attributes
    const attributesToWorkAround = [
      "instructeur",
      "referent",
      "enseignant",
      "etablissement",
      "structure",
    ]
    // If the attributes contains a null id
    // e.g. "enseignant": {id: null}
    // => we remove the attribute totally
    result.elements.forEach((el) => {
      Object.keys(el)
        .filter((key) => attributesToWorkAround.indexOf(key) > -1)
        .forEach((key) => {
          const element = (el as any)[key]
          if (element) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const id = element["id"]
            if (!id) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              delete (el as any)[key]
            }
          }
        })
    })
    return result
  }

  /**
   * Returns a file containing the export result
   * @param urlPath the URL to perform a GET request on
   * @param filtersList list of filters (value filtering)
   */
  async exportFileV2(
    urlPath: string,
    version: number,
    filtersList: Array<FilterDTO>,
    queryParams: Map<string, string | Array<any>> = new Map<string, string | Array<any>>()
  ): Promise<void> {
    const params = new Map<string, string | Array<any>>(queryParams)
    const cleanFiltersList = new Array<FilterDTO>()
    filtersList.forEach((filter) => {
      cleanFiltersList.push(Filters.asFilter({ property: filter.property, values: filter.values }))
    })
    params.set("filters", cleanFiltersList)

    const apiURL: any = new URL(this.getUrl(urlPath, version))

    // Add GET parameters
    params.forEach((value, name) => {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          apiURL.searchParams.append(name, JSON.stringify(v))
        })
      } else {
        apiURL.searchParams.append("" + name, value)
      }
    })

    window.open(apiURL.toString(), "_blank")
  }

  /**
   * Performs a POST request at given url posting the given T. Expect an array of T or a single T as result.
   * @param urlPath the URL to perform a GET request on
   * @param data the T to post
   * @returns a promise that will only be resolves if responses is OK and of expect type (rejected in all other cases)
   */
  async typedPostV2<T>(urlPath: string, version: number, data: T): Promise<T> {
    const apiURL = this.getUrl(urlPath, version)
    const response = await fetch(apiURL, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: this.treatValue(data),
    })
    const result = await this.handleResponse<T>(apiURL, "POST", response)
    if (result.length == 1) {
      return Promise.resolve(result[0])
    }
    return Promise.reject({
      status: "Result did not have a single value but " + result.length,
    })
  }

  /**
   * Performs a POST request at given url posting the given T. Expect an array of T or a single U as result.
   * @param urlPath the URL to perform a GET request on
   * @param data the T to post
   * @returns a promise that will only be resolves if responses is OK and of expect type (rejected in all other cases)
   */
  async typedPostWithDifferentResultTypeV2<T, U>(
    urlPath: string,
    version: number,
    data: T
  ): Promise<U> {
    const apiURL = this.getUrl(urlPath, version)
    const response = await fetch(apiURL, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    const result = await this.handleResponse<U>(apiURL, "POST", response)
    if (result.length == 1) {
      return Promise.resolve(result[0])
    }
    return Promise.reject({
      status: "Result did not have a single value but " + result.length,
    })
  }

  /**
   * Performs a PUT request at given url posting the given T. Expect an array of T or a single T as result.
   * @param urlPath the URL to perform a GET request on
   * @param data the T to put
   * @returns a promise that will only be resolves if responses is OK and of expect type (rejected in all other cases)
   */
  async typedPutV2<T>(urlPath: string, version: number, data: T): Promise<T> {
    const apiURL = this.getUrl(urlPath, version)
    const response = await fetch(apiURL, {
      method: "PUT",
      mode: "cors",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    const result = await this.handleResponse<T>(apiURL, "PUT", response)
    if (result.length == 1) {
      return Promise.resolve(result[0])
    }
    return Promise.reject({
      status: "Result did not have a single value but " + result.length,
    })
  }

  /**
   * Performs a PATCH request at given url posting the given T. Expect an array of T or a single T as result.
   * @param urlPath the URL to perform a GET request on
   * @param data the T to put
   * @returns a promise that will only be resolves if responses is OK and of expect type (rejected in all other cases)
   */
  async typedPatchV2<T>(urlPath: string, version: number, data: T): Promise<T> {
    const apiURL = this.getUrl(urlPath, version)
    const response = await fetch(apiURL, {
      method: "PATCH",
      mode: "cors",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    const result = await this.handleResponse<T>(apiURL, "PATCH", response)
    if (result.length == 1) {
      return Promise.resolve(result[0])
    }
    return Promise.reject({
      status: "Result did not have a single value but " + result.length,
    })
  }

  /**
   * Performs a DELETE request at given url.  Expect an array of T or a single T as result.
   * @param urlPath the URL to perform a DELETE request on
   * @returns a promise that will only be resolves if responses is OK and of expect type (rejected in all other cases)
   */
  async typedDeleteV2<T>(urlPath: string, version: number): Promise<T[]> {
    const apiURL = this.getUrl(urlPath, version)
    const response = await fetch(apiURL, {
      method: "DELETE",
      mode: "cors",
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
    })
    return this.handleResponse(apiURL, "DELETE", response)
  }

  async typedDeleteSingleV2<T>(urlPath: string, version: number): Promise<T> {
    const apiURL = this.getUrl(urlPath, version)
    const response = await fetch(apiURL, {
      method: "DELETE",
      mode: "cors",
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
    })
    const result = await this.handleResponse<T>(apiURL, "DELETE", response)
    if (result.length == 1) {
      return Promise.resolve(result[0])
    }
    return Promise.reject({
      status: "Result did not have a single value but " + result.length,
    })
  }

  async getString(
    urlPath: string,
    params: Map<string, string | Array<any>> = new Map<string, string | Array<any>>()
  ): Promise<string> {
    const apiURL: any = new URL(this.getUrl(urlPath, 2))
    // Add GET parameters
    params.forEach((value, name) => {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          apiURL.searchParams.append(name, this.treatValue(v))
        })
      } else {
        apiURL.searchParams.append("" + name, value)
      }
    })
    const response = await fetch(apiURL.toString(), {
      method: "GET",
      mode: "cors",
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
    })

    // Step 1: parse response content (no need if code is 204)
    if (response.status === HttpStatusCode.OK) {
      const responseText = await response.text()
      return Promise.resolve(responseText)
    } else if (response.status === HttpStatusCode.NO_CONTENT) {
      return Promise.resolve("")
    } else if (response.status === HttpStatusCode.UNAUTHORIZED) {
      this.notifyUnauthenticated()
    }

    // If we are here, response wasn't ok, we reject the promise
    return Promise.reject({
      status: response.status,
      statusText: await response.text(),
      url: urlPath + " [GET] ",
    })
  }

  async typedGetSingleV2<T>(
    urlPath: string,
    version: number,
    params: Map<string, string | Array<any>> = new Map<string, string | Array<any>>()
  ): Promise<T> {
    const getArray = await this.typedGetV2<T>(urlPath, version, params)
    if (getArray.length == 1) {
      return Promise.resolve(getArray[0])
    }
    return Promise.reject({
      status: "Result did not have a single value but " + getArray.length,
    })
  }

  async handleResponse<T>(url: string, method: string, response: Response): Promise<T[]> {
    let errorMessage = ""
    try {
      // Step 1: parse response content (no need if code is 204)
      if (response.status === HttpStatusCode.OK) {
        const responseText = await response.text()
        if (responseText.startsWith("[")) {
          const casted: T[] = JSON.parse(responseText)
          return Promise.resolve(casted)
        } else {
          const casted: T[] = JSON.parse("[" + responseText + "]")
          return Promise.resolve(casted)
        }
      } else if (response.status === HttpStatusCode.NO_CONTENT) {
        const array = new Array<T>()
        array.push(JSON.parse("{}"))
        return Promise.resolve(array)
      } else if (response.status === HttpStatusCode.UNAUTHORIZED) {
        this.notifyUnauthenticated()
      }
    } catch (e) {
      errorMessage = e
    }
    // If we are here, response wasn't ok, we reject the promise
    return Promise.reject({
      status: response.status,
      statusText: await response.text(),
      url: url + " [" + method + "] ",
      message: errorMessage,
    })
  }

  notifyUnauthenticated(): void {
    InfoReportingService.INSTANCE.warn(i18n.t("authentication-expired").toString(), new Error())
    LoginService.INSTANCE.clearLoggedUser()
  }

  getUrl(urlPath: string, version: number): string {
    let apiURL = ""
    if (version === 1) {
      apiURL = Constants.apiUrl(urlPath)
    }
    if (version === 2) {
      apiURL = Constants.apiUrlv2(urlPath)
    }
    return apiURL
  }
}
