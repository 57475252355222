


































































































































































































import {
  FilterDTO,
  GraeDTO,
  PaginationOrder,
  ProfilUtilisateur,
  UtilisateurDTO,
} from "@/model/bean/GeneratedDTOs"
import { Filters } from "@/model/bean/Filters"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import { UsersService } from "@/services/rest/users/UsersService"
import { LoginService } from "@/services/rest/login/LoginService"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import AbstractTableView from "@/views/tables/AbstractTableView.vue"

import TableFilterView from "@/views/tables/TableFilterView.vue"
import Column from "@/views/tables/Column"
import { EnumHelper, PROFIL_UTILISATEUR } from "@/model/bean/EnumHelper"
import GraeMemberDemandeCard from "@/views/grae/GraeMemberDemandeCard.vue"
import { OrganismeService } from "@/services/rest/organisme/OrganismeService"
import GRAEModale from "@/pages/users_management/GRAEModale.vue"
import ContentView from "@/views/commons/ContentView.vue"

@Component({
  components: {
    ContentView,
    TableFilterView,
    GraeMemberDemandeCard,
    GRAEModale,
  },
})
export default class GraeContactView extends AbstractTableView {
  @Prop() $loggedUser: UtilisateurDTO | undefined
  @Prop() grae?: GraeDTO

  private userService = UsersService.INSTANCE
  private infoReportingService = InfoReportingService.INSTANCE
  private loginService = LoginService.INSTANCE
  private organismeService = OrganismeService.INSTANCE

  userslist: Array<UtilisateurDTO> = []
  statusColumnField = "statutActivation"

  canDeleteUser = false
  loggedUser?: UtilisateurDTO
  userBeingValidated: string | undefined = ""
  userBeingRefused: string | undefined = ""

  regionsList: Array<string> = []
  graesList: Array<GraeDTO> = []

  statut = {
    valide: this.$t("usersList.validate").toString(),
    refuse: this.$t("usersList.refuse").toString(),
    validated: this.$t("usersList.validated").toString(),
    refused: this.$t("usersList.refused").toString(),
  }

  demandesEnCours: Array<UtilisateurDTO> = []
  openGRAEModale = false
  selectedUser: UtilisateurDTO = new UtilisateurDTO()

  created(): void {
    this.init()
  }

  @Watch("grae")
  async reloadUserslist(): Promise<void> {
    await this.reloadData()
  }

  async init(): Promise<void> {
    this.loggedUser = this.$loggedUser

    if (!this.loggedUser) {
      this.loggedUser = this.loginService.getLoggedUser()
    }

    this.canDeleteUser = this.canDelete()
  }

  // It's a quick fix for now. cf #11603
  canDelete(): boolean {
    if (!this.loggedUser) {
      return false
    }

    return this.loggedUserCanManageUsers() && this.loggedUser.profil !== "ANIMATEUR_REGIONAL"
  }

  mounted(): void {
    // Get region lists for filtering purposes
    this.organismeService.getAllGraes().then((graes) => {
      this.graesList = graes
      this.regionsList = graes.map((g) => g.region)
      this.regionsList.push(this.$t("table-filter.none-region").toString())
      this.refreshInitialColumns()
    })
  }

  async reloadData(): Promise<void> {
    this.isLoading = true
    try {
      const activeFilters = this.getActiveFiltersForApis()

      const paginatedUserList = await this.userService.getAllUsers(
        this.currentPagedSortedParameter,
        activeFilters
      )
      this.userslist = this.filter(paginatedUserList.elements)
      this.total = paginatedUserList.count
    } catch (e) {
      // Silent catch
    }
    await this.loadDemandes()
    this.isLoading = false
  }

  getActiveFiltersForApis(): FilterDTO[] {
    //We want a copy of activeFilters
    const activeFilters = this.activeFilters.map((filter) => filter)
    if (
      this.loggedUser &&
      this.userService.isAccompagnateurRegional(this.loggedUser) &&
      this.loggedUser.grae
    ) {
      let graeRegion = this.grae ? this.grae.region : this.loggedUser.grae.region
      const newFilters = Filters.asFilters([
        {
          property: "profil",
          values: [PROFIL_UTILISATEUR.REFERENT, PROFIL_UTILISATEUR.ENSEIGNANT],
        },
        {
          property: "region",
          values: [graeRegion],
        },
      ])
      activeFilters.push(...newFilters)
    }
    return activeFilters
  }

  async loadDemandes(): Promise<void> {
    if (this.loggedUserCanManageUsers()) {
      try {
        this.demandesEnCours = await this.userService.getDemandesCreationCompteNationales()
      } catch (e) {
        // Silent catch
      }
    }
  }

  filter(users: Array<UtilisateurDTO>): Array<UtilisateurDTO> {
    return users
  }

  getInitialColumns(): Array<Column> {
    const possibleSearchProfils =
      this.loggedUser && this.userService.isAccompagnateurRegional(this.loggedUser)
        ? [PROFIL_UTILISATEUR.REFERENT, PROFIL_UTILISATEUR.ENSEIGNANT]
        : EnumHelper.allProfilsUtilisateur

    const columns: Column[] = [
      {
        field: "prenom",
        label: this.$t("usersList.prenom").toString(),
        sortable: true,
        backEndSearchable: true,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "nom",
        label: this.$t("usersList.nom").toString(),
        sortable: true,
        backEndSearchable: true,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "profil",
        label: this.$t("usersList.profil").toString(),
        tooltip: this.$t("usersList.profil-explanations").toString(),
        sortable: true,
        backEndSearchable: true,
        possibleSearchValues: possibleSearchProfils,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "courriel",
        label: this.$t("usersList.courriel").toString(),
        sortable: true,
        backEndSearchable: true,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "projets",
        label: this.$t("usersList.projets").toString(),
        sortable: true,
        backEndSearchable: true,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
    ]

    if (this.loggedUser && this.userService.isObservateurNational(this.loggedUser)) {
      columns.push({
        field: "region",
        label: this.$t("usersList.region").toString(),
        sortable: true,
        backEndSearchable: true,
        possibleSearchValues: this.regionsList,
        visible: true,
        custom: true,
        canBeAdded: true,
      })
    }
    if (this.loggedUser && this.userService.isAccompagnateurNationalOrAdmin(this.loggedUser)) {
      columns.push(
        {
          field: "region",
          label: this.$t("usersList.region").toString(),
          sortable: true,
          backEndSearchable: true,
          possibleSearchValues: this.regionsList,
          visible: true,
          custom: true,
          canBeAdded: true,
        },
        {
          field: "statutActivation",
          label: this.$t("usersList.statut").toString(),
          tooltip: this.$t("usersList.statut-explanations").toString(),
          sortable: true,
          backEndSearchable: true,
          possibleSearchValues: EnumHelper.allStatusActivation,
          visible: true,
          custom: true,
          canBeAdded: true,
        },
        {
          field: "action",
          label: this.$t("projetPage.actions").toString(),
          sortable: false,
          backEndSearchable: false,
          visible: true,
          custom: true,
          canBeAdded: true,
        }
      )
    }

    return columns
  }

  getDefaultSort(): Array<PaginationOrder> {
    const defaultSort = new PaginationOrder()
    defaultSort.clause = "profil"
    defaultSort.desc = true
    return [defaultSort]
  }

  showDetailsPage(event: Event, id: number): void {
    // Do not foward click event to row (would trigger modal)
    event.stopPropagation()
    //For APP to open ProfileModale
    this.$root.$emit("open-profile-modale", true, id)
  }

  canPromote(user: UtilisateurDTO): boolean {
    return (
      this.loggedUserCanManageUsers() &&
      user.statutActivation == "ACTIF" &&
      user.profil == "ACCOMPAGNATEUR_REGIONAL"
    )
  }

  promote(user: UtilisateurDTO): void {
    this.userService.promoteUser(user).then(
      (_success) => {
        this.reloadData()
      },
      (reject) => {
        this.infoReportingService.error(this.$t("userActions.cannot-promote").toString(), reject)
        this.updateSelectValue(user, "ACCOMPAGNATEUR_REGIONAL")
      }
    )
  }

  changeUserProfile(user: UtilisateurDTO, profil: ProfilUtilisateur): void {
    if (profil == "ACCOMPAGNATEUR_REGIONAL" && user.profil != profil) {
      if (this.loggedUser && this.loggedUser.id === user.id) {
        this.infoReportingService.dialog(
          this.$t("usersList.confirmation").toString(),
          this.$t("usersList.are-you-sure-demote").toString(),
          this.$t("usersList.demote").toString(),
          () => this.demote(user),

          "is-warning"
        )
        //If user has clicked on cancel button :
        this.updateSelectValue(user, "ANIMATEUR_REGIONAL")
      } else {
        this.demote(user)
      }
    }

    if (profil == "ANIMATEUR_REGIONAL" && user.profil != profil) {
      this.promote(user)
    }
  }

  updateSelectValue(user: UtilisateurDTO, value: string): void {
    const elements = this.$refs[`select-${user.id}`] as Vue[]

    if (!elements || elements.length === 0) {
      return
    }

    const select = elements[0]
    //@ts-ignore
    select.computedValue = value
  }

  canDemote(user: UtilisateurDTO): boolean {
    return (
      this.loggedUserCanManageUsers() &&
      user.statutActivation == "ACTIF" &&
      user.profil == "ANIMATEUR_REGIONAL"
    )
  }

  demote(user: UtilisateurDTO): void {
    this.userService.demoteUser(user).then(
      (_success) => {
        this.reloadData()
      },
      (reject) => {
        this.infoReportingService.error(this.$t("userActions.cannot-demote").toString(), reject)
        this.updateSelectValue(user, "ANIMATEUR_REGIONAL")
      }
    )
  }

  canAccept(user: UtilisateurDTO): boolean {
    const hasExpectedProfilForRegional =
      ["ACCOMPAGNATEUR_REGIONAL", "ANIMATEUR_REGIONAL"].indexOf(user.profil) >= 0

    const hasExpectedProfilForNational =
      ["OBSERVATEUR_NATIONAL", "ACCOMPAGNATEUR_NATIONAL"].indexOf(user.profil) >= 0

    const hasExpectedProfilForSuperAdmin =
      ["SUPER_ADMINISTRATEUR", "ACCOMPAGNATEUR_NATIONAL"].indexOf(user.profil) >= 0

    return (
      this.loggedUserCanManageUsers() &&
      user.statutActivation === "INSCRIPTION_A_VALIDER" &&
      (hasExpectedProfilForRegional ||
        (hasExpectedProfilForNational && this.loggedUser?.profil === "ACCOMPAGNATEUR_NATIONAL") ||
        (hasExpectedProfilForSuperAdmin && this.loggedUser?.profil === "SUPER_ADMINISTRATEUR"))
    )
  }

  changeStatut(statutValue: string, user: UtilisateurDTO): void {
    if (statutValue === this.statut.valide) {
      this.accept(user)
    } else {
      this.deny(user)
    }
  }

  accept(user: UtilisateurDTO): void {
    this.infoReportingService.dialog(
      this.$t("usersList.confirmation").toString(),
      this.$t("usersList.are-you-sure-validate").toString(),
      this.$t("usersList.validate").toString(),
      () => this.validateUserRegistration(user),
      "is-info"
    )
  }

  validateUserRegistration(user: UtilisateurDTO): void {
    this.userBeingValidated = user.id
    this.userService.validateUserRegistration(user).then(
      (_success) => {
        this.reloadData()
        this.userBeingValidated = ""
      },
      (reject) => {
        this.userBeingValidated = ""
        console.error("Could not validate user ", reject)
        this.infoReportingService.error(this.$t("userActions.cannot-validate").toString(), reject)
      }
    )
  }

  canDeny(user: UtilisateurDTO): boolean {
    const hasExpectedProfil =
      [
        "SUPER_ADMINISTRATEUR",
        "ACCOMPAGNATEUR_NATIONAL",
        "ACCOMPAGNATEUR_REGIONAL",
        "ANIMATEUR_REGIONAL",
      ].indexOf(user.profil) >= 0
    return (
      this.loggedUserCanManageUsers() &&
      hasExpectedProfil &&
      user.statutActivation == "INSCRIPTION_A_VALIDER"
    )
  }

  deny(user: UtilisateurDTO): void {
    this.infoReportingService.dialogWithInput(
      this.$t("usersList.confirmation").toString(),
      this.$t("usersList.are-you-sure-deny").toString(),
      { type: "text", placeholder: this.$t("usersList.refuse-message").toString() },
      this.$t("usersList.refuse").toString(),
      (message: string) => {
        this.rejectUserRegistration(message, user)
      },
      "is-danger"
    )
  }

  rejectUserRegistration(message: string, user: UtilisateurDTO): void {
    this.userBeingRefused = user.id
    this.userService.rejectUserRegistration(user, message).then(
      (_success) => {
        this.reloadData()
        this.userBeingRefused = ""
      },
      (reject) => {
        this.userBeingRefused = ""
        this.infoReportingService.error(this.$t("userActions.cannot-reject").toString(), reject)
      }
    )
  }

  deleteUser(event: Event, user: UtilisateurDTO): void {
    event.stopPropagation()
    let message = this.$t("usersList.are-you-sure-delete").toString()
    let type = "is-warning"
    if (user.hasAireEducative) {
      type = "is-danger"
      message = this.$t("usersList.delete-user-warning").toString()
    }
    this.infoReportingService.dialog(
      this.$t("usersList.confirmation").toString(),
      message,
      this.$t("usersList.delete").toString(),
      () => {
        this.deleteUserAccount(user)
      },
      type
    )
  }

  isVisibleProjects(user: UtilisateurDTO): boolean {
    if (user.profil == "ENSEIGNANT" || user.profil == "REFERENT") {
      if (user.grae?.id) return true
    }
    return false
  }

  GoToProject(user: UtilisateurDTO): void {
    // Extract necessary values from the user object
    const graeId = user.grae?.id
    const name = user.nom
    // Get the current year and the previous year
    const currentYear = new Date().getFullYear()
    const previousYear = currentYear - 1
    const schoolYear = `${previousYear}-${currentYear}`
    // Construct the filters query parameter
    const filtersE = [
      {
        label: "Enseignants",
        property: "enseignant.name",
        values: [name],
      },
      {
        property: "anneeScolaire",
        values: [schoolYear],
      },
    ]
    const filtersR = [
      {
        label: "Référents",
        property: "referent.name",
        values: [name],
      },
      {
        property: "anneeScolaire",
        values: [schoolYear],
      },
    ]
    const filters = user.profil == "ENSEIGNANT" ? filtersE : filtersR
    // Encode the filters object to a URI component
    const encodedFilters = encodeURIComponent(JSON.stringify(filters))
    // Construct the full URL
    const url = `/grae/details/0/${graeId}?filters=${encodedFilters}`
    // Use Vue Router to push the new route
    this.$router.push(url)
  }

  canValidateEmail(user: UtilisateurDTO): boolean {
    return (
      this.loggedUserCanManageUsers() &&
      user.statutActivation == "EMAIL_A_VALIDER" &&
      user.nom !== "N/A"
    )
  }

  validateEmail(event: Event, user: UtilisateurDTO): void {
    event.stopPropagation()
    let message = this.$t("usersList.are-you-sure-validate-email").toString()
    let type = "is-warning"
    this.infoReportingService.dialog(
      this.$t("usersList.confirmation").toString(),
      message,
      this.$t("usersList.validate-email").toString(),
      () => {
        this.userService.validateEmail(user).then(
          (_success) => {
            this.infoReportingService.success(this.$t("usersList.user-email-validated").toString())
            this.reloadData()
          },
          (reject) => {
            this.infoReportingService.error(
              this.$t("usersList.cannot-validate-email").toString(),
              reject
            )
          }
        )
      },
      type
    )
  }

  deleteUserAccount(user: UtilisateurDTO): void {
    this.userService.deleteUserAccount(user).then(
      (_success) => {
        this.infoReportingService.success(this.$t("usersList.user-deleted").toString())
        this.reloadData()
      },
      (reject) => {
        this.infoReportingService.error(this.$t("usersList.cannot-delete").toString(), reject)
      }
    )
  }

  loggedUserCanManageUsers(): boolean {
    return (
      this.loggedUser != undefined &&
      this.loggedUser.profil != "OBSERVATEUR_NATIONAL" &&
      this.loggedUser.profil != "ACCOMPAGNATEUR_REGIONAL" &&
      this.loggedUser.profil != "REFERENT" &&
      this.loggedUser.profil != "ENSEIGNANT"
    )
  }

  exportCSV(): void {
    const activeFilters = this.getActiveFiltersForApis()
    this.userService.exportUsersCSV(activeFilters)
  }

  exportXLS(): void {
    const activeFilters = this.getActiveFiltersForApis()
    this.userService.exportUsersXls(activeFilters)
  }

  openGRAEDialog(event: Event, user: UtilisateurDTO): void {
    event.stopPropagation()
    this.selectedUser = user
    this.openGRAEModale = true
  }

  closeGRAEDialog(refresh: boolean): void {
    this.openGRAEModale = false
    if (refresh) {
      this.reloadData()
    }
    // const newUserIndex = this.userslist.findIndex((user) => user.id === newUser.id)
    // if (newUserIndex !== -1) {
    //   this.userslist[newUserIndex] = newUser
    // }
  }

  getInstructedGraeRegion(instructedGrae: string): string {
    const foundGrae = this.graesList.find((grae) => grae.id === instructedGrae)
    return foundGrae ? foundGrae.region : ""
  }
}
