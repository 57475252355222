




















import { ClasseDTO, ProjetAnnuelDTO } from "@/model/bean/GeneratedDTOs"
import { Component, Prop, Vue } from "vue-property-decorator"
import ClasseCard from "@/views/project/ClasseCard.vue"
import BookmarkCard from "@/views/generic_components/BookmarkCard.vue"
import { TAG_TYPES_FOR_BOOKMARK_CARD } from "@/model/bean/EnumHelper"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"

@Component({
  components: {
    ClasseCard,
    BookmarkCard,
    InputWithValidation,
  },
})
export default class ProjetClasseView extends Vue {
  @Prop() projetAnnuel: ProjetAnnuelDTO
  @Prop() readonly: boolean
  @Prop() isPorteurProjet: boolean

  protected tagType = TAG_TYPES_FOR_BOOKMARK_CARD.CLASSES_ENGAGEES
  protected iconNameInfo = "exclamation-circle"

  added(classe: ClasseDTO): void {
    this.projetAnnuel.classes.push(classe)
    this.$emit("dirtify-project")
    this.$emit("trigger-validation")
  }

  deleted(classe: ClasseDTO): void {
    this.projetAnnuel.classes = this.projetAnnuel.classes.filter(function (value) {
      return value !== classe
    })
    this.$emit("dirtify-project")
    this.$emit("trigger-validation")
  }
}
