





























































import { ValidationProvider } from "vee-validate"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import FormInputLabel from "./FormInputLabel.vue"
import Editor from "@tinymce/tinymce-vue"

import VueQuillCustom from "./VueQuillCustom.vue"

@Component({
  components: {
    ValidationProvider,
    FormInputLabel,
    editor: Editor,
    VueQuillCustom,
  },
})
export default class RichInputTextWithValidation extends Vue {
  @Prop({ default: "" }) group!: string
  @Prop() vid!: string
  @Prop() rules!: [string]
  @Prop() value!: string
  @Prop() tooltip!: string
  @Prop() inputNotice!: string
  @Prop() control!: string
  @Prop({ default: "" }) placeholder!: string
  @Prop({ default: "" }) customError!: string
  @Prop({ default: false }) disabled!: boolean
  @Prop({ default: false }) passwordReveal!: boolean
  @Prop({ default: "" }) maxlength!: number
  @Prop({ default: false }) controlRight!: boolean
  @Prop({ default: false }) controlAction!: boolean
  @Prop({ default: "" }) autocomplete!: string
  @Prop({ default: true }) validationActivated!: boolean
  @Prop({ default: true }) showValidationErrors!: boolean
  @Prop({ default: false }) inCard!: boolean

  innerValue = ""
  editorOption = {
    theme: "snow",
  }
  isMandatory = false
  rulesWithoutRequiredRulesIfValidationDisabled = ""

  created(): void {
    if (this.value != null && this.value != undefined) {
      this.innerValue = this.value
    }
    this.onRulesChanged()
  }

  mounted() {
    this.$nextTick(() => {
      this.adjustTextAreaHeight()
    })
  }

  adjustTextAreaHeight() {
    // Access the input element using the ref
    const field = this.$refs.inputField as any

    // Check if the input element is available and correctly referenced
    if (!field || !field.$el) {
      console.error("Input field not found.")
      return
    }
    console.log(field)
    // Assuming the actual input element is the root element of 'b-input' or within it
    const actualInput = field.$el.querySelector("textarea")

    if (!actualInput || !actualInput.style) {
      console.error("Actual input field not found.")
      return
    }
    console.log(actualInput)

    // Reset field height
    actualInput.style.height = "inherit"

    // Calculate the height
    const computed = window.getComputedStyle(actualInput)
    let height =
      parseInt(computed.getPropertyValue("border-top-width"), 10) +
      parseInt(computed.getPropertyValue("padding-top"), 10) +
      actualInput.scrollHeight +
      parseInt(computed.getPropertyValue("padding-bottom"), 10) +
      parseInt(computed.getPropertyValue("border-bottom-width"), 10)
    console.log("height" + height)

    actualInput.style.height = height + "px"
  }

  testDisplay(event: any) {
    this.innerValue = event.html
  }

  @Watch("innerValue")
  onInnerValueChanged(newVal: string, _oldValue: string): void {
    this.$emit("input", newVal)
  }

  @Watch("value")
  onExternalValueChanged(newVal: string, _oldValue: string): void {
    this.innerValue = newVal
    if (newVal != _oldValue) {
      this.$emit("manual-change", newVal)
    }
  }

  @Watch("rules")
  onRulesChanged(): void {
    this.isMandatory = this.rules && this.rules.includes("required")
    if (this.rules) {
      this.rulesWithoutRequiredRulesIfValidationDisabled = this.rules.toString()
      if (!this.validationActivated) {
        this.rulesWithoutRequiredRulesIfValidationDisabled = this.rules
          .toString()
          .replace("required|", "")
          .replace("|required", "")
          .replace("required", "")
      }
    } else {
      this.rulesWithoutRequiredRulesIfValidationDisabled = ""
    }
  }

  @Watch("validationActivated")
  onValidationActivated(): void {
    this.onRulesChanged()
  }

  splitParagraphsForPDF(value: string): string[] {
    const paragraphLength = 800
    if (value.length > paragraphLength) {
      let splitted = []
      var currentSplit = ""
      for (var i = 0; i < value.length; i++) {
        currentSplit += value[i]
        if (
          currentSplit.length > paragraphLength &&
          (value[i] == "." || value[i] == "!" || value[i] == "?")
        ) {
          splitted.push(currentSplit)
          currentSplit = ""
        } else if (
          currentSplit.length > 2 * paragraphLength &&
          (value[i] == "," || value[i] == ";")
        ) {
          splitted.push(currentSplit)
          currentSplit = ""
        }
      }
      splitted.push(currentSplit)
      return splitted
    }
    return [value]
  }

  formatEmailContent(content: string): string {
    return `
      <!DOCTYPE html>
      <html>
      <head>
      <style>
        body, p, ul, li {
          margin: 0;
          padding: 0;
          line-height: 1.5; /* Default line height */
        }
      </style>
      </head>
      <body style="font-family: Arial, sans-serif; line-height: 1.5; margin: 0; padding: 0;">
        ${content}
      </body>
      </html>
    `
  }
}
