/*-
 * #%L
 * Aires Éducatives
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
/**
 * All application constants.
 */
export default class Constants {
  // If VUE_APP_AIRES_EDUCATIVES_API_URL is not overriden in .env file
  // Serve the currently served URL /api
  public static apiURLRoot =
    (process.env.VUE_APP_AIRES_EDUCATIVES_API_URL ||
      window.location.protocol + "//" + window.location.host + "/api") + "/v1"

  /**
   * Returns the full API URL from given path.
   * @param path the path to postfix the API URL with
   * @returns <API SERVER URL>/path
   */
  static apiUrl(path: string): string {
    let slashPath = path
    if (!path.startsWith("/")) {
      slashPath = "/" + path
    }
    const result = this.apiURLRoot + slashPath
    return result
  }

  // If VUE_APP_AIRES_EDUCATIVES_API_URL is not overriden in .env file
  // Serve the currently served URL /api
  public static apiURLRootv2 =
    (process.env.VUE_APP_AIRES_EDUCATIVES_API_URL ||
      window.location.protocol + "//" + window.location.host + "/api") + "/v2"

  /**
   * Returns the full API URL from given path.
   * @param path the path to postfix the API URL with
   * @returns <API SERVER URL>/path
   */
  static apiUrlv2(path: string): string {
    let slashPath = path
    if (!path.startsWith("/")) {
      slashPath = "/" + path
    }
    const result = this.apiURLRootv2 + slashPath
    return result
  }
}
