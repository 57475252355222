var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "projet-part" },
    [
      _c("h2", [
        _vm._v(
          _vm._s(_vm.$t("projet.inscription.finalisation-inscription.titre"))
        )
      ]),
      _c("InputWithValidation", {
        attrs: {
          validationActivated: _vm.validationActivated,
          showValidationErrors: _vm.showValidationErrors,
          rules: "required",
          type: "textarea",
          label: _vm.$t("projet.inscription.vue-ensemble.edd.how-to-know-us"),
          disabled: _vm.readonly,
          maxlength: "1400",
          vid: "finalisation_cmtAvezConnuLeDispositif"
        },
        on: {
          "manual-change": function($event) {
            return _vm.$emit("dirtify-project")
          }
        },
        model: {
          value: _vm.projetAnnuel.cmtAvezConnuLeDispositif,
          callback: function($$v) {
            _vm.$set(_vm.projetAnnuel, "cmtAvezConnuLeDispositif", $$v)
          },
          expression: "projetAnnuel.cmtAvezConnuLeDispositif"
        }
      }),
      _c(
        "div",
        { staticClass: "finalisation" },
        [
          _c("div", { staticClass: "validation-display" }, [
            _c(
              "div",
              { staticClass: "validation-icon" },
              [
                _vm.errorsPerLabel.size
                  ? _c("b-icon", {
                      attrs: {
                        type: "is-danger",
                        size: "is-large",
                        icon: "exclamation-circle"
                      }
                    })
                  : _c("b-icon", {
                      attrs: {
                        type: "is-success",
                        size: "is-large",
                        icon: "check-circle"
                      }
                    })
              ],
              1
            ),
            _c(
              "div",
              [
                _vm.projetAnnuel.statut == "ACCEPTE"
                  ? _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("projet.submit-inscription-validated")
                          ) +
                          " "
                      )
                    ])
                  : _vm.projetAnnuel.statut != "BROUILLON"
                  ? _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("projet.submit-inscription-sent")) +
                          " "
                      )
                    ])
                  : _vm.errorsPerLabel.size
                  ? _c("div", [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "projet.inscription.finalisation-inscription.invalid"
                            )
                          )
                        )
                      ]),
                      _c(
                        "ul",
                        _vm._l(_vm.errorsPerLabel.keys(), function(
                          fieldWithError
                        ) {
                          return _c(
                            "li",
                            {
                              key: fieldWithError,
                              staticClass: "field-in-error"
                            },
                            [
                              _c(
                                "a",
                                { attrs: { href: "#" + fieldWithError } },
                                [
                                  _c("span", { staticClass: "error-circle" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errorsPerLabel.get(fieldWithError)
                                        )
                                      )
                                    ])
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "projet.inscription." + fieldWithError
                                        )
                                      )
                                  )
                                ]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  : _c(
                      "div",
                      [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t(
                                "projet.inscription.finalisation-inscription.valid"
                              )
                            )
                          }
                        }),
                        _vm.isMissingReferent
                          ? _c("b-message", { attrs: { type: "is-danger" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "projetPage.infoAdministratives.participants.at-least-one-referent"
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    ),
                _c(
                  "div",
                  { staticClass: "checkboxes" },
                  [
                    _vm.projetAnnuel.statut == "BROUILLON"
                      ? _c(
                          "CheckboxWithValidation",
                          {
                            attrs: {
                              group: "finalisation_2",
                              rules: "certified-exact",
                              disabled: _vm.readonly,
                              vid: "accepted"
                            },
                            on: {
                              "manual-change": function($event) {
                                return _vm.$emit("trigger-validation")
                              }
                            },
                            model: {
                              value: _vm.exactInfosCertified,
                              callback: function($$v) {
                                _vm.exactInfosCertified = $$v
                              },
                              expression: "exactInfosCertified"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "projet.inscription.finalisation-inscription.certify"
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "CheckboxWithValidation",
                      {
                        attrs: {
                          group: "finalisation_23",
                          disabled: _vm.readonly,
                          vid: "accepted"
                        },
                        on: {
                          "manual-change": function($event) {
                            return _vm.$emit("dirtify-project")
                          }
                        },
                        model: {
                          value: _vm.projetAnnuel.recevoirKitAireEducative,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.projetAnnuel,
                              "recevoirKitAireEducative",
                              $$v
                            )
                          },
                          expression: "projetAnnuel.recevoirKitAireEducative"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "projet.inscription.finalisation-inscription.kit"
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c("InputWithValidation", {
                  attrs: {
                    disabled: _vm.readonly,
                    label: _vm.$t(
                      "projet.inscription.finalisation-inscription.commentaire"
                    ),
                    placeholder: _vm.$t(
                      "projet.inscription.finalisation-inscription.commentaire-placeholder"
                    ),
                    group: "finalisation_4",
                    showValidationErrors: _vm.showValidationErrors,
                    type: "textarea"
                  },
                  on: {
                    "manual-change": function($event) {
                      return _vm.$emit("dirtify-project")
                    }
                  },
                  model: {
                    value: _vm.projetAnnuel.commentaireFinal,
                    callback: function($$v) {
                      _vm.$set(_vm.projetAnnuel, "commentaireFinal", $$v)
                    },
                    expression: "projetAnnuel.commentaireFinal"
                  }
                })
              ],
              1
            )
          ]),
          _c("RecapCard", [
            _c("div", { staticClass: "recap-text" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "projet.inscription.finalisation-inscription.recap-text-projet"
                    )
                  ) +
                  " "
              ),
              _vm.projetAnnuel.natureAireEducative == "ATE"
                ? _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t(
                          "projet.inscription.finalisation-inscription.recap-text-ate-type"
                        )
                      )
                    }
                  })
                : _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t(
                          "projet.inscription.finalisation-inscription.recap-text-ame-type"
                        )
                      )
                    }
                  }),
              _vm.projetAnnuel.etablissements.length > 0
                ? _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "projet.inscription.finalisation-inscription.recap-text-at"
                          )
                        ) +
                        " "
                    ),
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.projetAnnuel.etablissements
                            .map(function(et) {
                              return et.nom
                            })
                            .join(",")
                        )
                      )
                    ])
                  ])
                : _vm._e()
            ]),
            _vm.projetAnnuel.financements.length
              ? _c("div", { staticClass: "recap-financements" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "projet.inscription.finalisation-inscription.financement-declared",
                          [_vm.projetAnnuel.financements.length]
                        )
                      ) +
                      " "
                  )
                ])
              : _vm._e(),
            _c("div", { staticClass: "recap-invited" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "projet.inscription.finalisation-inscription.invited"
                    )
                  ) +
                  " "
              ),
              _c("ul", [
                _vm.projetAnnuel.enseignants.length
                  ? _c("li", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "projet.inscription.finalisation-inscription.invited-enseignants",
                              [_vm.projetAnnuel.enseignants.length]
                            )
                          ) +
                          " "
                      )
                    ])
                  : _vm._e(),
                _vm.projetAnnuel.referents.length
                  ? _c("li", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "projet.inscription.finalisation-inscription.invited-referents",
                              [_vm.projetAnnuel.referents.length]
                            )
                          ) +
                          " "
                      )
                    ])
                  : _vm._e()
              ])
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }