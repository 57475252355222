/*-
 * #%L
 * Aires Éducatives
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
import { UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import { LoginService } from "@/services/rest/login/LoginService"
import { NavigationGuardNext, Route, RouteRecord } from "vue-router"

/**
 * Represents authentication restriction than can be set up for any routes
 */
export enum AccessRestriction {
  // Requires user to be logged in to be accessed (no matter the user rights)
  AUTHENTICATED_ANY,
  // Requires user to be logged and have super admin rights
  AUTHENTICATED_AS_SUPER_ADMIN,
  // Requires user to be logged and have account validation rights
  AUTHENTICATED_CAN_VALIDATE_ACCOUNTS,
  // Requires user to be logged and have right to update parameters
  AUTHENTICATED_CAN_UPDATE_PARAMETERS,
  // Requires user to be logged and have right to manage GRAE
  AUTHENTICATED_CAN_MANAGE_GRAE,
}

export class AccessRestrictionChecker {
  private loginService = LoginService.INSTANCE

  /**
   * Checks that the given user respects the given access restriction
   * @param loggedUser the currently logged user
   * @param accessRestriction the AccessRestriction to check
   */
  private checkAccessRestrictions(
    loggedUser: UtilisateurDTO | undefined,
    accessRestriction: AccessRestriction
  ): boolean {
    switch (accessRestriction) {
      case AccessRestriction.AUTHENTICATED_ANY: {
        return loggedUser != undefined
      }
      case AccessRestriction.AUTHENTICATED_AS_SUPER_ADMIN: {
        return loggedUser != undefined && loggedUser.profil == "SUPER_ADMINISTRATEUR"
      }
      case AccessRestriction.AUTHENTICATED_CAN_VALIDATE_ACCOUNTS: {
        return (
          loggedUser != undefined &&
          (loggedUser.profil == "SUPER_ADMINISTRATEUR" ||
            loggedUser.profil == "ACCOMPAGNATEUR_NATIONAL" ||
            loggedUser.profil == "OBSERVATEUR_NATIONAL" ||
            loggedUser.profil == "ACCOMPAGNATEUR_REGIONAL" ||
            loggedUser.profil == "ANIMATEUR_REGIONAL")
        )
      }
      case AccessRestriction.AUTHENTICATED_CAN_UPDATE_PARAMETERS: {
        return (
          loggedUser != undefined &&
          (loggedUser.profil == "SUPER_ADMINISTRATEUR" ||
            loggedUser.profil == "OBSERVATEUR_NATIONAL" ||
            loggedUser.profil == "ACCOMPAGNATEUR_NATIONAL")
        )
      }
      case AccessRestriction.AUTHENTICATED_CAN_MANAGE_GRAE: {
        return (
          loggedUser != undefined &&
          (loggedUser.profil == "SUPER_ADMINISTRATEUR" ||
            loggedUser.profil == "OBSERVATEUR_NATIONAL" ||
            loggedUser.profil == "ACCOMPAGNATEUR_NATIONAL" ||
            ((loggedUser.profil == "ANIMATEUR_REGIONAL" ||
              loggedUser.profil == "ACCOMPAGNATEUR_REGIONAL") &&
              loggedUser.instructedGraes &&
              loggedUser.instructedGraes.length !== 0))
        )
      }
    }
  }

  redirectAfterFailure(to: Route, next: NavigationGuardNext<Vue>): void {
    // If not loggued, redirect to loggin screen
    if (!this.loginService.isUserLoggedIn()) {
      // Redirect to login screen
      next({ name: "Login", query: { redirect: to.path } })
    } else {
      next({ name: "404" })
    }
  }

  async checkAccessRestrictionsForRoutes(routeRecords: RouteRecord[]): Promise<boolean> {
    let allRoutesOk = true
    await this.loginService.retrieveLoggedUserFromDB()
    routeRecords.forEach((record) => {
      // If the route has been defined with restrictions, check it
      const accessRestrictions = this.getAccessRestrictions(record)
      if (accessRestrictions.length > 0) {
        const loggedUser = this.loginService.getLoggedUser()
        allRoutesOk =
          allRoutesOk &&
          accessRestrictions.every((accessRestriction) => {
            return this.checkAccessRestrictions(loggedUser, accessRestriction)
          })
      }
    })
    return Promise.resolve(allRoutesOk)
  }

  /* Returns the array of restrictions that has been definied on the given
   * route with a "meta.accessRestrictions parameter (empty array if none found)
   */
  getAccessRestrictions(routeRecord: RouteRecord): Array<AccessRestriction> {
    if (
      routeRecord.meta &&
      routeRecord.meta.accessRestrictions &&
      routeRecord.meta.accessRestrictions.length > 0
    ) {
      return routeRecord.meta.accessRestrictions
    }
    return []
  }
}
