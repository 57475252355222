var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ContentView",
    { staticClass: "ActualitesView" },
    [
      _c(
        "div",
        { staticClass: "ActualitesView-buttons-container" },
        [
          _vm.isAnimateur && !_vm.isObservateurNational
            ? _c(
                "b-button",
                {
                  staticClass: "button is-primary",
                  attrs: { outlined: "" },
                  on: { click: _vm.createNewActualite }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.graeId
                          ? _vm.$t("actualitesPage.create-regional")
                          : _vm.$t("actualitesPage.create-national")
                      ) +
                      " "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c("TableFilterView", {
        attrs: {
          columns: _vm.columns,
          initialFilters: _vm.activeFilters,
          showExport: false,
          showCustomColumns: false
        },
        on: {
          "update-active-filters": _vm.updateActiveFilters,
          "update-visible-columns": _vm.updateVisibleColumns
        }
      }),
      _c(
        "b-table",
        {
          ref: _vm.tableRef,
          attrs: {
            striped: _vm.config.isStriped,
            hoverable: _vm.config.isHoverable,
            "debounce-search": _vm.config.searchDebounce,
            "aria-next-label": _vm.config.nextLabel(),
            "aria-previous-label": _vm.config.previousLabel(),
            "aria-page-label": _vm.config.pageLabel(),
            "aria-current-label": _vm.config.currentPageLabel(),
            "per-page": _vm.config.perPage,
            "sticky-header": _vm.config.stickyHeader,
            "current-page": _vm.currentPagedSortedParameter.pageNumber,
            "pagination-position": _vm.config.paginationPosition,
            paginated: "",
            "backend-pagination": "",
            "sort-icon": _vm.config.sortIcon,
            "sort-icon-size": _vm.config.sortIconSize,
            "backend-sorting": "",
            "sort-multiple": "",
            "sort-multiple-data": _vm.buefySortingPriority,
            total: _vm.total,
            data: _vm.actualites,
            loading: _vm.isLoading
          },
          on: {
            sort: _vm.onSort,
            "sorting-priority-removed": _vm.sortingPriorityRemoved,
            "page-change": _vm.onPageChange
          }
        },
        [
          _vm._l(_vm.columns, function(column) {
            return [
              _c(
                "b-table-column",
                _vm._b(
                  {
                    key: column.id,
                    attrs: { visible: column.visible !== false },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(props) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "clickable",
                                  on: {
                                    click: function($event) {
                                      return _vm.openAcualitesModal(
                                        $event,
                                        props.row["id"]
                                      )
                                    }
                                  }
                                },
                                [
                                  column.field === "dateCreation"
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatDate(
                                                props.row["dateCreation"]
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    : _vm._e(),
                                  column.field === "audience"
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              props.row.grae
                                                ? _vm.$t(
                                                    "actualitesPage.regionale"
                                                  )
                                                : _vm.$t(
                                                    "actualitesPage.nationale"
                                                  )
                                            ) +
                                            " "
                                        )
                                      ])
                                    : _vm._e(),
                                  column.field === "nomAuteur"
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(props.row["auteur"].nom) +
                                            " "
                                        )
                                      ])
                                    : _vm._e(),
                                  column.field === "prenomAuteur"
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(props.row["auteur"].prenom) +
                                            " "
                                        )
                                      ])
                                    : _vm._e(),
                                  column.field === "courrielAuteur"
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              props.row["auteur"].courriel
                                            ) +
                                            " "
                                        )
                                      ])
                                    : _vm._e(),
                                  column.field === "dateDebutPublication"
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatDate(
                                                props.row[
                                                  "dateDebutPublication"
                                                ]
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    : _vm._e(),
                                  column.field === "dateFinPublication"
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatDate(
                                                props.row["dateFinPublication"]
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    : _vm._e(),
                                  column.field === "action"
                                    ? _c(
                                        "div",
                                        [
                                          _vm.isAnimateur &&
                                          (props.row.grae || _vm.isNational)
                                            ? _c("b-button", {
                                                attrs: {
                                                  type: "is-danger is-outlined",
                                                  "icon-left": "trash",
                                                  title: _vm.$t("delete")
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteActualite(
                                                      $event,
                                                      props.row
                                                    )
                                                  }
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !column.custom
                                    ? _c("div", { staticClass: "clickable" }, [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(props.row[column.field])
                                          )
                                        ])
                                      ])
                                    : _vm._e()
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  "b-table-column",
                  column,
                  false
                )
              )
            ]
          })
        ],
        2
      ),
      _c("ActualitesModalView", {
        attrs: {
          actualite: _vm.actualiteSelected,
          open: _vm.openActualitesModal,
          readOnly:
            !(
              _vm.isNational ||
              (_vm.isAnimateur &&
                (_vm.actualiteSelected.grae || !_vm.actualiteSelected.id))
            ) || _vm.isObservateurNational
        },
        on: {
          close: function($event) {
            _vm.openActualitesModal = false
          },
          "close-refresh": _vm.closeActualitesModalAndRefresh
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }