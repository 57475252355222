var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PageView",
    {
      attrs: {
        mainTitle: _vm.$t("ModifProjet.title"),
        subTitle: _vm.subTitle,
        noSubTitleExpected: _vm.hasSubTitle
      }
    },
    [
      _c(
        "ContentView",
        { attrs: { wideScreen: "true" } },
        [_c("ModifProjetView")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }