










import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import Footer from "./Footer.vue"

@Component({
  components: {
    Footer,
  },
})
export default class PageView extends Vue {
  @Prop({ default: false }) isOnBigHeaderPage: boolean
  @Prop({ default: false }) noSubTitleExpected: boolean
  @Prop({ default: "" }) mainTitle: string
  @Prop({ default: "" }) subTitle: string
  @Prop({ default: "" }) subTitleIcon: string
  @Prop({ default: false }) subTitleIsEditable: boolean
  @Prop({ default: false }) displaySubTitleInGreen: boolean

  mounted(): void {
    this.onMainTitleChanged()
    this.onSubTitleChanged()
    this.onSubTitleIsEditableChanged()
    this.onDisplaySubTitleInGreenChanged()
  }

  @Watch("mainTitle")
  onMainTitleChanged(): void {
    this.$root.$emit("main-title-changed", this.mainTitle)
  }

  @Watch("subTitle")
  onSubTitleChanged(): void {
    // If subtitle is empty but we are expecting a subitle, do not emit event yet, wait for subtitle
    if (this.subTitle || this.noSubTitleExpected) {
      this.$root.$emit("sub-title-changed", this.subTitle)
    }
  }

  @Watch("subTitleIcon")
  onSubTitleIconChanged(): void {
    this.$root.$emit("sub-title-icon-changed", this.subTitleIcon)
  }

  @Watch("subTitleIsEditable")
  onSubTitleIsEditableChanged(): void {
    this.$root.$emit("sub-title-is-editable-changed", this.subTitleIsEditable)
  }

  @Watch("displaySubTitleInGreen")
  onDisplaySubTitleInGreenChanged(): void {
    this.$root.$emit("display-sub-title-in-green-changed", this.displaySubTitleInGreen)
  }
}
