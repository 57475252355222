var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ValidationObserver",
        { ref: "observer" },
        [
          _c("UserPersonnalInfo", {
            attrs: {
              user: _vm.user,
              readonly: _vm.readonly,
              errormessage: _vm.errorMessage,
              validationGroup: "UserInfoDetails"
            },
            on: {
              "change-utilisateur": _vm.changeUserInfos,
              "close-modale": _vm.emitCloseModale
            }
          }),
          !_vm.readonly &&
          (_vm.user.profil == "ENSEIGNANT" || _vm.user.profil == "REFERENT")
            ? _c(
                "div",
                [
                  _c("br"),
                  _c("br"),
                  _c(
                    "CheckboxWithValidation",
                    {
                      attrs: { vid: "acceptNameVisibilityOnMap" },
                      model: {
                        value: _vm.acceptNameVisibilityOnProtectedListing,
                        callback: function($$v) {
                          _vm.acceptNameVisibilityOnProtectedListing = $$v
                        },
                        expression: "acceptNameVisibilityOnProtectedListing"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("registerPage.accept-name-visibility-on-map")
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c("br"),
                  _c("br")
                ],
                1
              )
            : _vm._e(),
          _vm.errorMessage
            ? _c("b-message", { attrs: { type: "is-danger" } }, [
                _vm._v(" " + _vm._s(_vm.errorMessage) + " ")
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }