























































import { Component, Prop, Vue } from "vue-property-decorator"
import TitleView from "@/views/commons/TitleView.vue"
import ContentView from "@/views/commons/ContentView.vue"
import H3TitleView from "@/views/commons/H3TitleView.vue"
import { EtablissementDTO, UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import InputWithValidation from "../inputs/InputWithValidation.vue"
import { ValidationObserver } from "vee-validate"
import SingleEtablissement from "../organisme/SingleEtablissement.vue"

@Component({
  components: {
    TitleView,
    ContentView,
    H3TitleView,
    InputWithValidation,
    ValidationObserver,
    SingleEtablissement,
  },
})
export default class CreateProjectView extends Vue {
  @Prop() loggedUser: UtilisateurDTO
  @Prop() chosenEtablissement: EtablissementDTO

  hasPreviousStep = false
  projectName = ""

  created(): void {
    if (!this.chosenEtablissement || !this.chosenEtablissement.codeEtablissement) {
      this.$router.push("")
    }
    if (this.loggedUser.profil === "REFERENT") {
      this.hasPreviousStep = true
    }
  }

  goPrevious(): void {
    this.$router.push("/myProjects/new")
  }

  cancelProjectCreation(): void {
    this.$emit("cancel-project-creation")
  }

  endProjectCreation(): void {
    this.$emit("end-project-creation", this.projectName)
  }
}
