<template>
  <div style="width: 100%" class="quill-editor">
    <slot name="toolbar"></slot>
    <div ref="editor"></div>
    <div class="char-counter">{{ charCount - 1 }} / {{ maxLength }}</div>
  </div>
</template>

<script>
import _Quill from "quill"

const defaultOptions = {
  theme: "snow",
  boundary: document.body,
  modules: {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      [{ header: 1 }, { header: 2 }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ["link", "image", "video"],
    ],
  },
  placeholder: "Insérer un texte ...",
  readOnly: false,
}

const Quill = window.Quill || _Quill
const alignStyle = Quill.import("attributors/style/align")
Quill.register(alignStyle, true)

export default {
  name: "quill-editor",
  data() {
    return {
      _options: {},
      _content: "",
      defaultOptions,
      maxLength: 3000, // Limite de caractères
      charCount: 0, // Compteur de caractères
    }
  },
  props: {
    content: String,
    value: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    globalOptions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  mounted() {
    this.initialize()
  },
  beforeDestroy() {
    this.quill = null
    delete this.quill
  },
  methods: {
    // Init Quill instance
    initialize() {
      if (this.$el) {
        this._options = Object.assign({}, this.defaultOptions, this.globalOptions, this.options)
        this.quill = new Quill(this.$refs.editor, this._options)
        this.quill.enable(false)

        if (this.value || this.content) {
          this.quill.pasteHTML(this.value || this.content)
          this.charCount = this.getTextLength(this.value || this.content) // Initialisation du compteur
        }

        if (!this.disabled) {
          this.quill.enable(true)
        }

        this.quill.on("selection-change", (range) => {
          if (!range) {
            this.$emit("blur", this.quill)
          } else {
            this.$emit("focus", this.quill)
          }
        })

        this.quill.on("text-change", () => {
          let html = this.quill.root.innerHTML
          var textLength = this.quill.getLength()
          if (textLength > this.maxLength) {
            //this.quill.root.innerHTML = html
            this.quill.deleteText(this.maxLength, this.quill.getLength())
            this.charCount = this.quill.getLength()
          }
          this.charCount = this.quill.getLength()
          this._content = html
          this.$emit("input", this._content)
          this.$emit("change", { html, text: this.quill.getText(), quill: this.quill })
        })

        this.$emit("ready", this.quill)
      }
    },
    getTextLength(html) {
      const div = document.createElement("div")
      div.innerHTML = html
      return div.innerText.length
    },
  },
  watch: {
    content(newVal) {
      if (this.quill) {
        if (newVal && newVal !== this._content) {
          this._content = newVal
          this.quill.pasteHTML(newVal)
          this.charCount = this.getTextLength(newVal)
        } else if (!newVal) {
          this.quill.setText("")
          this.charCount = 0
        }
      }
    },
    value(newVal) {
      if (this.quill) {
        if (newVal && newVal !== this._content) {
          this._content = newVal
          this.quill.pasteHTML(newVal)
          this.charCount = this.getTextLength(newVal)
        } else if (!newVal) {
          this.quill.setText("")
          this.charCount = 0
        }
      }
    },
    disabled(newVal) {
      if (this.quill) {
        this.quill.enable(!newVal)
      }
    },
  },
}
</script>

<style>
.quill-editor {
  position: relative;
  padding-bottom: 40px;
}

.char-counter {
  position: absolute;
  bottom: -15px;
  right: 0;
  padding: 5px;
  font-size: 14px;
  color: #666;
}
</style>
