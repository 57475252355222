



















































import { ValidationProvider } from "vee-validate"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import FormInputLabel from "./FormInputLabel.vue"

@Component({
  components: {
    ValidationProvider,
    FormInputLabel,
  },
})
export default class SelectWithValidation extends Vue {
  @Prop({ default: "" }) group: string
  @Prop() vid: string
  @Prop() rules: [string]
  @Prop() value: string
  @Prop() tooltip: string
  @Prop({ default: "" }) placeholder: string
  @Prop() data: string[]
  @Prop() selectLabels: string[]
  @Prop({ default: false }) disabled: boolean
  @Prop() inputNotice: string
  @Prop({ default: true }) validationActivated: boolean
  @Prop({ default: true }) showValidationErrors: boolean
  innerValue = ""
  isMandatory = false
  rulesWithoutRequiredRulesIfValidationDisabled = ""

  created(): void {
    if (this.value) {
      this.innerValue = this.value
    }
    this.isMandatory = this.rules && this.rules.includes("required")
    this.onRulesChanged()
  }

  @Watch("innerValue")
  onInnerValueChanged(newVal: string, _oldValue: string): void {
    this.$emit("input", newVal)
  }

  @Watch("value")
  onExternalValueChanged(newVal: string, _oldValue: string): void {
    this.innerValue = newVal
    if (newVal != _oldValue) {
      this.$emit("manual-change", newVal)
    }
  }

  @Watch("rules")
  onRulesChanged(): void {
    this.isMandatory = this.rules && this.rules.includes("required")
    if (this.rules) {
      this.rulesWithoutRequiredRulesIfValidationDisabled = this.rules.toString()
      if (!this.validationActivated) {
        this.rulesWithoutRequiredRulesIfValidationDisabled = this.rules
          .toString()
          .replace("required|", "")
          .replace("|required", "")
          .replace("required", "")
      }
    } else {
      this.rulesWithoutRequiredRulesIfValidationDisabled = ""
    }
  }

  @Watch("validationActivated")
  onValidationActivated(): void {
    this.onRulesChanged()
  }
}
