var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "observer",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var handleSubmit = ref.handleSubmit
          return [
            _c(
              "b-modal",
              {
                staticClass: "GRAEModale",
                attrs: {
                  active: _vm.open,
                  "has-modal-card": "",
                  "trap-focus": "",
                  "aria-role": "dialog",
                  "aria-label": "GRAEModale",
                  "aria-modal": "",
                  width: "100vw"
                },
                on: {
                  cancel: function($event) {
                    return _vm.emitCloseModale(false)
                  }
                }
              },
              [
                [
                  _c("div", { staticClass: "modal-card" }, [
                    _c(
                      "header",
                      { staticClass: "modal-card-head" },
                      [
                        _c("H3TitleView", { staticClass: "modal-card-title" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("usersList.grae-modale-title").toString()
                            )
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "section",
                      { staticClass: "modal-card-body" },
                      [
                        _vm.graeListIds.length !== 0 && !_vm.isLoading
                          ? _c("GroupCheckboxesWithValidation", {
                              staticClass: "GRAEModale-checkboxes",
                              attrs: {
                                vid: "graeModale",
                                rules: "required|maxChoices:3",
                                label: _vm.$t(
                                  "usersList.grae-modale-chose-grae"
                                ),
                                data: _vm.graeListIds,
                                checkBoxLabels: _vm.graeListRegions,
                                verticalDisplay: "true"
                              },
                              model: {
                                value: _vm.selectedGraesIds,
                                callback: function($$v) {
                                  _vm.selectedGraesIds = $$v
                                },
                                expression: "selectedGraesIds"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c("footer", { staticClass: "modal-card-foot" }, [
                      _c(
                        "div",
                        { staticClass: "buttons" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "hoverable-discrete-link mr-3",
                              on: {
                                click: function($event) {
                                  return _vm.emitCloseModale(false)
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("close-generic")) + " "
                              )
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: { rounded: "", type: "is-primary" },
                              on: {
                                click: function($event) {
                                  return handleSubmit(_vm.saveAndClose)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("save-generic")) + " ")]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ]
              ],
              2
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }