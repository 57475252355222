












































































































import { Component, Watch } from "vue-property-decorator"
import TitleView from "@/views/commons/TitleView.vue"
import PageView from "@/views/commons/PageView.vue"
import ContentView from "@/views/commons/ContentView.vue"
import DatePickerWithValidation from "@/views/inputs/DatePickerWithValidation.vue"
import { DocumentsDTO, PaginationResult, ParametrageDTO } from "@/model/bean/GeneratedDTOs"
import { LoginService } from "@/services/rest/login/LoginService"
import { ValidationObserver } from "vee-validate"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import H3TitleView from "@/views/commons/H3TitleView.vue"
import DocumentsModalView from "@/pages/document/DocumentsModalView.vue"
import AbstractTableView from "@/views/tables/AbstractTableView.vue"
import { DocumentsService } from "@/services/rest/documents/DocumentsService"
import Column from "@/views/tables/Column"
import { Filters } from "@/model/bean/Filters"
import { GraeService } from "@/services/rest/grae/GraeService"

@Component({
  components: {
    DocumentsModalView,
    TitleView,
    PageView,
    ContentView,
    DatePickerWithValidation,
    ValidationObserver,
    InputWithValidation,
    H3TitleView,
  },
})
export default class DocumentsPage extends AbstractTableView {
  private loginService = LoginService.INSTANCE
  private documentService = DocumentsService.INSTANCE
  private graeService = GraeService.INSTANCE

  previousYearAvailable = true
  nextYearAvailable = true
  selectedAnneeScolaire = ""
  anneesScolaires: Array<string> = []

  private infoReportingService = InfoReportingService.INSTANCE
  isAnimateur = false
  isNational = false
  documents: DocumentsDTO[] = []
  openDocumentModal = false
  isObservateurNational = false
  debutAnneeScolaire = ""
  parametrage: ParametrageDTO = new ParametrageDTO()

  created(): void {
    const loggedUser = this.loginService.getLoggedUser()

    if (!loggedUser) {
      return
    }

    this.isNational =
      loggedUser.profil === "SUPER_ADMINISTRATEUR" ||
      loggedUser.profil === "ACCOMPAGNATEUR_NATIONAL" ||
      loggedUser.profil === "OBSERVATEUR_NATIONAL"

    this.isAnimateur = this.isNational || loggedUser.profil === "ANIMATEUR_REGIONAL"

    this.isObservateurNational = loggedUser.profil === "OBSERVATEUR_NATIONAL"

    this.refreshInitialColumns()
  }

  async reloadData(): Promise<void> {
    this.isLoading = true
    try {
      console.log("currentPagedSortedParameter => " + this.currentPagedSortedParameter)
      console.log("activeFilters => " + this.activeFilters)
      let paginatedDocumentsList: PaginationResult<DocumentsDTO> = new PaginationResult()
      paginatedDocumentsList = await this.documentService.getAllDocuments(
        this.currentPagedSortedParameter,
        this.activeFilters
      )
      this.documents = paginatedDocumentsList.elements
      this.total = paginatedDocumentsList.count
    } catch (e) {
      // Silent catch
    }
    this.isLoading = false
  }

  importDiplome(): void {
    this.openDocumentModal = true
  }

  async submitDocumentEmit(document: DocumentsDTO): Promise<void> {
    this.openDocumentModal = false
    try {
      await this.documentService.createDocument(document)
      this.infoReportingService.success(this.$i18n.t("DocumentsPage.modification-ok").toString())
    } catch (e) {
      this.infoReportingService.error(this.$t("actualitesPage.error-delete").toString(), e as Error)
    }
    this.reloadData()
  }

  @Watch("openDocumentModal")
  refreshTable() {
    this.reloadData()
  }

  async closeDocumentsModalAndRefresh(): Promise<void> {
    this.openDocumentModal = false
    await this.reloadData()
  }

  deleteDocument(event: Event, docToDelete: DocumentsDTO): void {
    event.stopPropagation()
    this.infoReportingService.dialog(
      this.$t("graePage.confirmation").toString(),
      this.$t("actualitesPage.delete-document").toString(),
      this.$t("actualitesPage.confirm-delete").toString(),
      () => this.doDeleteDocument(docToDelete),
      "is-danger"
    )
  }

  async doDeleteDocument(docToDelete: DocumentsDTO): Promise<void> {
    try {
      await this.documentService.deleteDocument(docToDelete)
    } catch (e) {
      this.infoReportingService.error(this.$t("actualitesPage.error-delete").toString(), e as Error)
    }
    this.reloadData()
  }

  async downloadDoc(docToGet: DocumentsDTO): Promise<void> {
    window.location.href = "./api/v2/document/" + docToGet.id
  }

  getInitialColumns(): Array<Column> {
    const initialColumns: Array<Column> = [
      {
        field: "annees",
        label: this.$t("actualitesPage.anneescolaire").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "typeDoc",
        label: this.$t("actualitesPage.type").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "name",
        label: this.$t("actualitesPage.nameFile").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
    ]

    if (this.isAnimateur && !this.isObservateurNational) {
      initialColumns.push({
        field: "action",
        label: this.$t("projetsListPage.actions").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      })
    }
    return initialColumns
  }

  previousYear(): void {
    const thisYearIndex = this.anneesScolaires.indexOf(this.selectedAnneeScolaire)
    const previousYear = this.anneesScolaires[thisYearIndex + 1]

    this.clearAnneeScolaireFilter()

    this.activeFilters.push(Filters.asFilter({ property: "anneeScolaire", values: [previousYear] }))
    this.reloadData()
    this.selectedAnneeScolaire = previousYear
  }

  nextYear(): void {
    const thisYearIndex = this.anneesScolaires.indexOf(this.selectedAnneeScolaire)
    const nextYear = this.anneesScolaires[thisYearIndex - 1]

    this.clearAnneeScolaireFilter()

    this.activeFilters.push(Filters.asFilter({ property: "anneeScolaire", values: [nextYear] }))
    this.reloadData()
    this.selectedAnneeScolaire = nextYear
  }

  goToYear(): void {
    this.clearAnneeScolaireFilter()

    this.activeFilters.push(
      Filters.asFilter({ property: "anneeScolaire", values: [this.selectedAnneeScolaire] })
    )
    this.reloadData()
  }

  mounted(): void {
    this.loadAnneesScolaires()
  }

  async loadAnneesScolaires(): Promise<void> {
    this.anneesScolaires = await this.graeService.getAnneesScolaires()
    this.selectedAnneeScolaire ||= this.anneesScolaires[0]
    this.goToYear()
  }

  clearAnneeScolaireFilter(): void {
    this.activeFilters = this.activeFilters.filter((item) => item.property !== "anneeScolaire")
  }
}
