








































































import { DocumentsDTO, FilterDTO, UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import { DATE_TIME_FORMAT } from "@/views/grae/ActualitesView.vue"
import { Component, Prop } from "vue-property-decorator"
import { format } from "date-fns"
import { ValidationObserver } from "vee-validate"
import { Filters } from "@/model/bean/Filters"
import { GraeService } from "@/services/rest/grae/GraeService"
import AbstractTableView from "@/views/tables/AbstractTableView.vue"
import SelectWithValidation from "@/views/inputs/SelectWithValidation.vue"
import i18n from "@/i18n"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import { DocumentsService } from "@/services/rest/documents/DocumentsService"
import ConversionUtils from "@/services/ConversionUtils"
import H3TitleView from "@/views/commons/H3TitleView.vue"

const TYPE_CHOICES = {
  TYPE1: i18n.t("mapDraw.type-choices.type1"),
  TYPE2: i18n.t("mapDraw.type-choices.type2"),
  TYPE3: i18n.t("mapDraw.type-choices.type3"),
  TYPE4: i18n.t("mapDraw.type-choices.type4"),
}
@Component({
  components: {
    H3TitleView,
    SelectWithValidation,
    ValidationObserver,
  },
})
export default class DocumentsModalView extends AbstractTableView {
  private documentsService = DocumentsService.INSTANCE
  private infoReportingService = InfoReportingService.INSTANCE
  private graeService = GraeService.INSTANCE
  document: DocumentsDTO = new DocumentsDTO()
  @Prop({ default: false }) readonly: boolean
  @Prop() open: boolean
  @Prop() readOnly: boolean
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: "" }) placeholder: string
  @Prop() inputNotice: string
  @Prop() data: DocumentsDTO[]
  selectedAnneeScolaire = ""
  typeDocument = ""

  isUploading = false
  fileIsPresent = false
  innerValue: File | undefined
  typeChoice = TYPE_CHOICES.TYPE1

  loggedUser? = new UtilisateurDTO()
  anneesScolaires: Array<string> = []
  activeFilters = new Array<FilterDTO>()
  typesLabels = TYPE_CHOICES
  $i18n: any
  selectedFile = ""
  fileName: string
  hasDocument = false

  emitCloseModale(): void {
    this.$emit("close")
  }

  formatDate(date: Date): string {
    if (date) {
      return format(date, DATE_TIME_FORMAT)
    }
    return ""
  }

  goToYear(): void {
    this.clearAnneeScolaireFilter()

    this.activeFilters.push(
      Filters.asFilter({ property: "anneeScolaire", values: [this.selectedAnneeScolaire] })
    )
  }

  mounted(): void {
    this.loadAnneesScolaires()
  }

  async loadAnneesScolaires(): Promise<void> {
    this.anneesScolaires = await this.graeService.getAnneesScolaires()
    this.selectedAnneeScolaire ||= this.anneesScolaires[0]
    this.goToYear()
  }

  clearAnneeScolaireFilter(): void {
    this.activeFilters = this.activeFilters.filter(
      (item: { property: string }) => item.property !== "anneeScolaire"
    )
  }

  isConditionMet() {
    return this.typeDocument === "" || this.selectedFile === ""
  }

  async documentExist() {
    this.hasDocument = await this.documentsService.getDocumentByTypeAndAnneScolaire(
      this.typeDocument,
      this.selectedAnneeScolaire
    )
  }

  async submit(): Promise<void> {
    await this.documentExist()
    if (this.hasDocument) {
      this.infoReportingService.error(
        this.$i18n.t("DocumentsPage.modification-error").toString(),
        new Error()
      )
    } else {
      try {
        this.document.type = this.typeDocument
        this.document.anneescolaire = this.selectedAnneeScolaire
        this.document.documentname = this.selectedFile
        this.document.file = this.fileName
        let foundValues = false

        for (let i = 0; i < this.data.length; i++) {
          if (
            this.data[i].anneescolaire === this.selectedAnneeScolaire &&
            this.data[i].type === this.typeDocument
          ) {
            foundValues = true
          }
        }

        if (!foundValues) {
          this.$emit("close-refresh", this.document)
        } else {
          throw new Error()
        }
      } catch (e) {
        this.infoReportingService.error(
          this.$i18n.t("DocumentsPage.modification-error").toString(),
          e as Error
        )
      }
    }
  }

  handleSelectChange(value: string) {
    this.typeDocument = value
    var btnSubmit = document.getElementById("btnSubmit") as HTMLButtonElement
    if (this.typeDocument != "" && this.selectedFile != "") {
      btnSubmit.disabled = false
    } else {
      btnSubmit.disabled = true
    }
  }

  async uploadFile(event: any) {
    const file = event.target.files[0]
    this.fileName = file.name
    if (file) {
      try {
        const base64String = await ConversionUtils.convertFileToBase64String(file)
        this.selectedFile = base64String
        var btnSubmit = document.getElementById("btnSubmit") as HTMLButtonElement
        if (this.typeDocument != "" && this.selectedFile != "") {
          btnSubmit.disabled = false
        } else {
          btnSubmit.disabled = true
        }
      } catch (error) {
        console.error("Error converting file to base64:", error)
      }
    }
  }
}
