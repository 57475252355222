var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ValidationObserver",
        { ref: "observer" },
        [
          _c(
            "div",
            { staticClass: "tabs-header" },
            [
              _c("TabsDropdownSelector", {
                attrs: {
                  currentContainerWidth: _vm.instructionPannelWidth,
                  tabs: _vm.tabs,
                  activeTab: _vm.activeTab,
                  disabled: _vm.isSaving
                },
                on: { "change-tab": _vm.changeTab }
              }),
              _c("b-loading", {
                attrs: { "is-full-page": false },
                model: {
                  value: _vm.isSaving,
                  callback: function($$v) {
                    _vm.isSaving = $$v
                  },
                  expression: "isSaving"
                }
              })
            ],
            1
          ),
          _c(
            "b-tabs",
            {
              staticClass: "block InstructionModale-tabs",
              attrs: { vertical: "", animated: false },
              model: {
                value: _vm.activeTab,
                callback: function($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab"
              }
            },
            [
              _c(
                "b-tab-item",
                {
                  attrs: {
                    label: _vm.$t(
                      "instruction-pannel.instruction.instructeurs.title"
                    )
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "instruction-pannel.instruction.instructeurs.text"
                        )
                      ) +
                      " "
                  ),
                  _c(
                    "ul",
                    { staticClass: "instructeurs-list" },
                    _vm._l(_vm.getInstructeurs(), function(ins) {
                      return _c("li", { key: ins }, [_vm._v(_vm._s(ins))])
                    }),
                    0
                  )
                ]
              ),
              _c(
                "b-tab-item",
                {
                  attrs: {
                    label: _vm.$t(
                      "instruction-pannel.instruction.deroule.title"
                    )
                  }
                },
                [
                  _c("ResponsiveEvaluationHelp", {
                    attrs: {
                      criteria: _vm.$t(
                        "instruction-pannel.instruction.deroule.title"
                      ),
                      helpText: _vm.$t(
                        "instruction-pannel.instruction.deroule.help" +
                          _vm.renewalHelpSuffix
                      )
                    }
                  }),
                  _vm.isRenouvellementLight(_vm.projetAnnuel.typeDossier)
                    ? _c("SelectWithValidation", {
                        attrs: {
                          label: _vm.$t("instruction-pannel.instruction.note"),
                          vid: "derouleNote",
                          data: _vm.notes,
                          disabled: _vm.readOnly
                        },
                        on: { "manual-change": _vm.computeNote },
                        model: {
                          value: _vm.evaluation.derouleNote,
                          callback: function($$v) {
                            _vm.$set(_vm.evaluation, "derouleNote", $$v)
                          },
                          expression: "evaluation.derouleNote"
                        }
                      })
                    : _c("SelectWithValidation", {
                        attrs: {
                          label: _vm.$t("instruction-pannel.instruction.note"),
                          vid: "derouleNote",
                          data: _vm.notes,
                          disabled: _vm.readOnly,
                          rules: "required"
                        },
                        on: { "manual-change": _vm.computeNote },
                        model: {
                          value: _vm.evaluation.derouleNote,
                          callback: function($$v) {
                            _vm.$set(_vm.evaluation, "derouleNote", $$v)
                          },
                          expression: "evaluation.derouleNote"
                        }
                      }),
                  _vm.isRenouvellementLight(_vm.projetAnnuel.typeDossier)
                    ? _c("InputWithValidation", {
                        attrs: {
                          type: "textarea",
                          label: _vm.$t(
                            "instruction-pannel.instruction.commentaire"
                          ),
                          maxlength: "700",
                          vid: "derouleCommentaire",
                          disabled: _vm.readOnly
                        },
                        model: {
                          value: _vm.evaluation.derouleCommentaire,
                          callback: function($$v) {
                            _vm.$set(_vm.evaluation, "derouleCommentaire", $$v)
                          },
                          expression: "evaluation.derouleCommentaire"
                        }
                      })
                    : _c("InputWithValidation", {
                        attrs: {
                          type: "textarea",
                          label: _vm.$t(
                            "instruction-pannel.instruction.commentaire"
                          ),
                          maxlength: "700",
                          vid: "derouleCommentaire",
                          rules: "required",
                          disabled: _vm.readOnly
                        },
                        model: {
                          value: _vm.evaluation.derouleCommentaire,
                          callback: function($$v) {
                            _vm.$set(_vm.evaluation, "derouleCommentaire", $$v)
                          },
                          expression: "evaluation.derouleCommentaire"
                        }
                      }),
                  _vm.readOnly && !_vm.isEvaluationFinale
                    ? _c("b-message", { attrs: { type: "is-warning" } }, [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t(
                                "instructionPage.labellisations.harmonisation.readonly-cloture"
                              )
                            )
                          }
                        })
                      ])
                    : _c("b-message", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "instruction-pannel.instruction.commentaires-non-visibles-warning"
                              )
                            ) +
                            " "
                        )
                      ])
                ],
                1
              ),
              _c(
                "b-tab-item",
                {
                  attrs: {
                    label: _vm.$t(
                      "instruction-pannel.instruction.adhesion.title"
                    )
                  }
                },
                [
                  _c("ResponsiveEvaluationHelp", {
                    attrs: {
                      criteria: _vm.$t(
                        "instruction-pannel.instruction.adhesion.title"
                      ),
                      helpText: _vm.$t(
                        "instruction-pannel.instruction.adhesion.help" +
                          _vm.renewalHelpSuffix
                      )
                    }
                  }),
                  _vm.isRenouvellementLight(_vm.projetAnnuel.typeDossier)
                    ? _c("SelectWithValidation", {
                        attrs: {
                          label: _vm.$t("instruction-pannel.instruction.note"),
                          vid: "adhesionNote",
                          data: _vm.notes,
                          disabled: _vm.readOnly
                        },
                        on: { "manual-change": _vm.computeNote },
                        model: {
                          value: _vm.evaluation.adhesionNote,
                          callback: function($$v) {
                            _vm.$set(_vm.evaluation, "adhesionNote", $$v)
                          },
                          expression: "evaluation.adhesionNote"
                        }
                      })
                    : _c("SelectWithValidation", {
                        attrs: {
                          label: _vm.$t("instruction-pannel.instruction.note"),
                          vid: "adhesionNote",
                          data: _vm.notes,
                          rules: "required",
                          disabled: _vm.readOnly
                        },
                        on: { "manual-change": _vm.computeNote },
                        model: {
                          value: _vm.evaluation.adhesionNote,
                          callback: function($$v) {
                            _vm.$set(_vm.evaluation, "adhesionNote", $$v)
                          },
                          expression: "evaluation.adhesionNote"
                        }
                      }),
                  _vm.isRenouvellementLight(_vm.projetAnnuel.typeDossier)
                    ? _c("InputWithValidation", {
                        attrs: {
                          type: "textarea",
                          label: _vm.$t(
                            "instruction-pannel.instruction.commentaire"
                          ),
                          maxlength: "700",
                          vid: "adhesionCommentaire",
                          disabled: _vm.readOnly
                        },
                        model: {
                          value: _vm.evaluation.adhesionCommentaire,
                          callback: function($$v) {
                            _vm.$set(_vm.evaluation, "adhesionCommentaire", $$v)
                          },
                          expression: "evaluation.adhesionCommentaire"
                        }
                      })
                    : _c("InputWithValidation", {
                        attrs: {
                          type: "textarea",
                          label: _vm.$t(
                            "instruction-pannel.instruction.commentaire"
                          ),
                          maxlength: "700",
                          vid: "adhesionCommentaire",
                          rules: "required",
                          disabled: _vm.readOnly
                        },
                        model: {
                          value: _vm.evaluation.adhesionCommentaire,
                          callback: function($$v) {
                            _vm.$set(_vm.evaluation, "adhesionCommentaire", $$v)
                          },
                          expression: "evaluation.adhesionCommentaire"
                        }
                      }),
                  _vm.readOnly && !_vm.isEvaluationFinale
                    ? _c("b-message", { attrs: { type: "is-warning" } }, [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t(
                                "instructionPage.labellisations.harmonisation.readonly-cloture"
                              )
                            )
                          }
                        })
                      ])
                    : _c("b-message", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "instruction-pannel.instruction.commentaires-non-visibles-warning"
                              )
                            ) +
                            " "
                        )
                      ])
                ],
                1
              ),
              _c(
                "b-tab-item",
                {
                  attrs: {
                    label: _vm.$t(
                      "instruction-pannel.instruction.integration.title"
                    )
                  }
                },
                [
                  _c("ResponsiveEvaluationHelp", {
                    attrs: {
                      criteria: _vm.$t(
                        "instruction-pannel.instruction.integration.title"
                      ),
                      helpText: _vm.$t(
                        "instruction-pannel.instruction.integration.help" +
                          _vm.renewalHelpSuffix
                      )
                    }
                  }),
                  _vm.isRenouvellementLight(_vm.projetAnnuel.typeDossier)
                    ? _c("SelectWithValidation", {
                        attrs: {
                          label: _vm.$t("instruction-pannel.instruction.note"),
                          vid: "integrationNote",
                          data: _vm.notes,
                          disabled: _vm.readOnly
                        },
                        on: { "manual-change": _vm.computeNote },
                        model: {
                          value: _vm.evaluation.integrationNote,
                          callback: function($$v) {
                            _vm.$set(_vm.evaluation, "integrationNote", $$v)
                          },
                          expression: "evaluation.integrationNote"
                        }
                      })
                    : _c("SelectWithValidation", {
                        attrs: {
                          label: _vm.$t("instruction-pannel.instruction.note"),
                          vid: "integrationNote",
                          data: _vm.notes,
                          rules: "required",
                          disabled: _vm.readOnly
                        },
                        on: { "manual-change": _vm.computeNote },
                        model: {
                          value: _vm.evaluation.integrationNote,
                          callback: function($$v) {
                            _vm.$set(_vm.evaluation, "integrationNote", $$v)
                          },
                          expression: "evaluation.integrationNote"
                        }
                      }),
                  _vm.isRenouvellementLight(_vm.projetAnnuel.typeDossier)
                    ? _c("InputWithValidation", {
                        attrs: {
                          type: "textarea",
                          label: _vm.$t(
                            "instruction-pannel.instruction.commentaire"
                          ),
                          maxlength: "700",
                          vid: "integrationCommentaire",
                          disabled: _vm.readOnly
                        },
                        model: {
                          value: _vm.evaluation.integrationCommentaire,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.evaluation,
                              "integrationCommentaire",
                              $$v
                            )
                          },
                          expression: "evaluation.integrationCommentaire"
                        }
                      })
                    : _c("InputWithValidation", {
                        attrs: {
                          type: "textarea",
                          label: _vm.$t(
                            "instruction-pannel.instruction.commentaire"
                          ),
                          maxlength: "700",
                          vid: "integrationCommentaire",
                          rules: "required",
                          disabled: _vm.readOnly
                        },
                        model: {
                          value: _vm.evaluation.integrationCommentaire,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.evaluation,
                              "integrationCommentaire",
                              $$v
                            )
                          },
                          expression: "evaluation.integrationCommentaire"
                        }
                      }),
                  _vm.readOnly && !_vm.isEvaluationFinale
                    ? _c("b-message", { attrs: { type: "is-warning" } }, [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t(
                                "instructionPage.labellisations.harmonisation.readonly-cloture"
                              )
                            )
                          }
                        })
                      ])
                    : _c("b-message", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "instruction-pannel.instruction.commentaires-non-visibles-warning"
                              )
                            ) +
                            " "
                        )
                      ])
                ],
                1
              ),
              _c(
                "b-tab-item",
                {
                  attrs: {
                    label: _vm.$t(
                      "instruction-pannel.instruction.implication.title"
                    )
                  }
                },
                [
                  _c("ResponsiveEvaluationHelp", {
                    attrs: {
                      criteria: _vm.$t(
                        "instruction-pannel.instruction.implication.title"
                      ),
                      helpText: _vm.$t(
                        "instruction-pannel.instruction.implication.help" +
                          _vm.renewalHelpSuffix
                      )
                    }
                  }),
                  _vm.isRenouvellementLight(_vm.projetAnnuel.typeDossier)
                    ? _c("SelectWithValidation", {
                        attrs: {
                          label: _vm.$t("instruction-pannel.instruction.note"),
                          vid: "implicationNote",
                          data: _vm.notes,
                          disabled: _vm.readOnly
                        },
                        on: { "manual-change": _vm.computeNote },
                        model: {
                          value: _vm.evaluation.implicationNote,
                          callback: function($$v) {
                            _vm.$set(_vm.evaluation, "implicationNote", $$v)
                          },
                          expression: "evaluation.implicationNote"
                        }
                      })
                    : _c("SelectWithValidation", {
                        attrs: {
                          label: _vm.$t("instruction-pannel.instruction.note"),
                          vid: "implicationNote",
                          data: _vm.notes,
                          rules: "required",
                          disabled: _vm.readOnly
                        },
                        on: { "manual-change": _vm.computeNote },
                        model: {
                          value: _vm.evaluation.implicationNote,
                          callback: function($$v) {
                            _vm.$set(_vm.evaluation, "implicationNote", $$v)
                          },
                          expression: "evaluation.implicationNote"
                        }
                      }),
                  _vm.isRenouvellementLight(_vm.projetAnnuel.typeDossier)
                    ? _c("InputWithValidation", {
                        attrs: {
                          type: "textarea",
                          label: _vm.$t(
                            "instruction-pannel.instruction.commentaire"
                          ),
                          maxlength: "700",
                          vid: "implicationCommentaire",
                          disabled: _vm.readOnly
                        },
                        model: {
                          value: _vm.evaluation.implicationCommentaire,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.evaluation,
                              "implicationCommentaire",
                              $$v
                            )
                          },
                          expression: "evaluation.implicationCommentaire"
                        }
                      })
                    : _c("InputWithValidation", {
                        attrs: {
                          type: "textarea",
                          label: _vm.$t(
                            "instruction-pannel.instruction.commentaire"
                          ),
                          maxlength: "700",
                          vid: "implicationCommentaire",
                          rules: "required",
                          disabled: _vm.readOnly
                        },
                        model: {
                          value: _vm.evaluation.implicationCommentaire,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.evaluation,
                              "implicationCommentaire",
                              $$v
                            )
                          },
                          expression: "evaluation.implicationCommentaire"
                        }
                      }),
                  _vm.readOnly && !_vm.isEvaluationFinale
                    ? _c("b-message", { attrs: { type: "is-warning" } }, [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t(
                                "instructionPage.labellisations.harmonisation.readonly-cloture"
                              )
                            )
                          }
                        })
                      ])
                    : _c("b-message", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "instruction-pannel.instruction.commentaires-non-visibles-warning"
                              )
                            ) +
                            " "
                        )
                      ])
                ],
                1
              ),
              !_vm.readOnly && !_vm.isEvaluationFinale
                ? _c(
                    "b-tab-item",
                    {
                      attrs: {
                        label: _vm.$t(
                          "instruction-pannel.instruction.labellisation"
                        )
                      }
                    },
                    [
                      _vm.evaluation.note && _vm.evaluation.mentionAutomatique
                        ? _c(
                            "div",
                            [
                              _c(
                                "b-field",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "instruction-pannel.instruction.total"
                                    ),
                                    message: _vm.$t(
                                      "instruction-pannel.instruction.note-placeholder"
                                    ),
                                    disabled: _vm.readOnly
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.evaluation.note) +
                                        " " +
                                        _vm._s(
                                          _vm.$t(
                                            "instruction-pannel.instruction.points"
                                          )
                                        )
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "b-field",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "instruction-pannel.instruction.mention"
                                    ),
                                    message: _vm.$t(
                                      "instruction-pannel.instruction.mention-placeholder"
                                    ),
                                    disabled: _vm.readOnly
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "instruction-pannel.instruction." +
                                            _vm.evaluation.mentionAutomatique
                                        )
                                      ) + " "
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "instruction-pannel.instruction.calcul-non-disponible"
                                  )
                                ) +
                                " "
                            )
                          ])
                    ]
                  )
                : _vm._e(),
              _c(
                "b-tab-item",
                {
                  staticClass: "informations-visibles",
                  attrs: {
                    label: _vm.$t("instruction-pannel.instruction.appreciation")
                  }
                },
                [
                  _c("InputWithValidation", {
                    attrs: {
                      rules: _vm.isRenouvellementLight(
                        _vm.projetAnnuel.typeDossier
                      )
                        ? ""
                        : "required",
                      type: "textarea",
                      label: _vm.$t(
                        "instruction-pannel.instruction.commentaireInstructeur"
                      ),
                      inputNotice: _vm.$t(
                        "instruction-pannel.instruction.commentaireInstructeur-placeholder"
                      ),
                      maxlength: "700",
                      vid: "commentaireInstructeur",
                      disabled: _vm.readOnly,
                      validationActivated: true
                    },
                    model: {
                      value: _vm.evaluation.commentaireInstructeur,
                      callback: function($$v) {
                        _vm.$set(_vm.evaluation, "commentaireInstructeur", $$v)
                      },
                      expression: "evaluation.commentaireInstructeur"
                    }
                  }),
                  !_vm.isEvaluationFinale
                    ? _c(
                        "b-tooltip",
                        {
                          staticClass: "mail-preview-tooltip",
                          attrs: {
                            type: "is-light",
                            position: "is-bottom",
                            multilined: "",
                            active: _vm.emailDisplayed,
                            always: ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "content",
                                fn: function() {
                                  return [
                                    _c("div", {
                                      staticClass: "mail-preview",
                                      domProps: {
                                        innerHTML: _vm._s(_vm.emailText)
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            1318896943
                          )
                        },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { type: "is-primary" },
                              on: {
                                click: function($event) {
                                  _vm.emailDisplayed
                                    ? _vm.undisplayMail()
                                    : _vm.displayMail()
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "ellipsize",
                                  style: {
                                    "max-width":
                                      _vm.instructionPannelWidth - 180 + "px"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "instruction-pannel.instruction.displayEmail"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("SelectWithValidation", {
                    attrs: {
                      label: _vm.$t("instruction-pannel.instruction.mention"),
                      inputNotice: _vm.$t(
                        "instruction-pannel.instruction.mention-finale-placeholder"
                      ),
                      vid: "mention",
                      data: _vm.instructionStatus,
                      selectLabels: _vm.statusLabels,
                      rules: _vm.isRenouvellementLight(
                        _vm.projetAnnuel.typeDossier
                      )
                        ? ""
                        : "required",
                      disabled: _vm.readOnly,
                      validationActivated: true
                    },
                    model: {
                      value: _vm.evaluation.mention,
                      callback: function($$v) {
                        _vm.$set(_vm.evaluation, "mention", $$v)
                      },
                      expression: "evaluation.mention"
                    }
                  }),
                  _vm.readOnly && !_vm.isEvaluationFinale
                    ? _c("b-message", { attrs: { type: "is-warning" } }, [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t(
                                "instructionPage.labellisations.harmonisation.readonly-cloture"
                              )
                            )
                          }
                        })
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "bottom-buttons-evaluation" }, [
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { type: "is-primary", disabled: _vm.readOnly },
                    on: {
                      click: function($event) {
                        return _vm.save()
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("instruction-pannel.instruction.save")) +
                        " "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }