/*-
 * #%L
 * Aires Éducatives
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
import { FilterDTO, GraeDTO, ProjetAnnuelDTO } from "@/model/bean/GeneratedDTOs"
import { PaginationParameter, UtilisateurDTO, ProfilUtilisateur } from "@/model/bean/GeneratedDTOs"
import { PaginationResult } from "@/model/bean/GeneratedDTOs"
import { AbstractTypedRestService } from "@/services/rest/AbstractTypedRestService"
import { LoginService } from "@/services/rest/login/LoginService"
import { PROFIL_UTILISATEUR } from "@/model/bean/EnumHelper"

export class UsersService extends AbstractTypedRestService<UtilisateurDTO> {
  static INSTANCE = new UsersService()

  async promoteUser(user: UtilisateurDTO): Promise<UtilisateurDTO[]> {
    return super.typedGetV2("users/" + user.id + "/promote", 2)
  }

  async demoteUser(user: UtilisateurDTO): Promise<UtilisateurDTO[]> {
    return super.typedGetV2("users/" + user.id + "/demote", 2)
  }

  async validateUserRegistration(user: UtilisateurDTO): Promise<UtilisateurDTO[]> {
    return super.typedGetV2("users/" + user.id + "/validate", 2)
  }

  async validateEmail(user: UtilisateurDTO): Promise<UtilisateurDTO[]> {
    return super.typedGetV2("users/" + user.id + "/validate-email-manual", 2)
  }

  async rejectUserRegistration(user: UtilisateurDTO, message: string): Promise<string> {
    return super.typedPostV2("users/" + user.id + "/reject", 2, message)
  }

  async getAllUsers(
    paginationParameters: PaginationParameter,
    filtersList: Array<FilterDTO>
  ): Promise<PaginationResult<UtilisateurDTO>> {
    return super.typeGetPaginatedV2<UtilisateurDTO>("users", 2, paginationParameters, filtersList)
  }

  async exportUsersCSV(filtersList: Array<FilterDTO>): Promise<void> {
    this.exportFileV2("/users/export/csv", 2, filtersList)
  }

  async exportUsersXls(filtersList: Array<FilterDTO>): Promise<void> {
    this.exportFileV2("/users/export/xls", 2, filtersList)
  }

  async deleteUserAccount(user: UtilisateurDTO): Promise<string[]> {
    return super.typedDeleteV2("/users/" + user.id, 2)
  }

  async getUserProfile(userID: string): Promise<UtilisateurDTO> {
    return super.typedGetSingleV2("users/" + userID + "/profile", 2)
  }

  async getUserById(userID: string): Promise<UtilisateurDTO> {
    return super.typedGetSingleV2("users/" + userID, 2)
  }

  async updateProfile(user: UtilisateurDTO): Promise<UtilisateurDTO> {
    const updatedProfile = await super.typedPutV2("/users", 2, user)

    // Invalidate logged user cache if the modified user is currently logged
    if (
      updatedProfile &&
      updatedProfile.courriel == LoginService.INSTANCE.getLoggedUser()?.courriel
    ) {
      await LoginService.INSTANCE.invalidateLoggedUserCache(updatedProfile)
    }
    return updatedProfile
  }

  async getEnseignants(
    etablissementId: string,
    paginationParameters: PaginationParameter,
    filtersList: Array<FilterDTO>
  ): Promise<PaginationResult<UtilisateurDTO>> {
    const urlPath = "users/etablissement/" + etablissementId + "/enseignants"
    const enseignants = await super.typeGetPaginatedV2<UtilisateurDTO>(
      urlPath,
      2,
      paginationParameters,
      filtersList
    )
    return enseignants
  }

  async getReferents(
    structureId: string,
    paginationParameters: PaginationParameter,
    filtersList: Array<FilterDTO>
  ): Promise<PaginationResult<UtilisateurDTO>> {
    const urlPath = "users/structure/" + structureId + "/referents"
    const referents = await super.typeGetPaginatedV2<UtilisateurDTO>(
      urlPath,
      2,
      paginationParameters,
      filtersList
    )
    return referents
  }

  isPorteurProjet(user: UtilisateurDTO): boolean {
    return (
      user.profil &&
      (user.profil === "ENSEIGNANT" ||
        user.profil === "REFERENT" ||
        user.profil === "SUPER_ADMINISTRATEUR")
    )
  }

  async getNumberOfConsentement(graeId: any): Promise<any> {
    return await super.typedGetSingleV2<number>("/grae/" + graeId + "/nombre-creation-comptes", 2)
  }

  async getNumberOfConsentementNational(): Promise<any> {
    return await super.typedGetSingleV2<number>("/users/nombre-creation-comptes-nationales", 2)
  }

  isAccompagnateurOrAdmin(user: UtilisateurDTO): boolean {
    return (
      user.profil &&
      (user.profil === "ACCOMPAGNATEUR_NATIONAL" ||
        user.profil === "ACCOMPAGNATEUR_REGIONAL" ||
        user.profil === "ANIMATEUR_REGIONAL" ||
        user.profil === "SUPER_ADMINISTRATEUR")
    )
  }

  canModifyGrae(user: UtilisateurDTO, graeId: string): boolean {
    return (
      user.profil &&
      (user.profil === "SUPER_ADMINISTRATEUR" ||
        user.profil === "ACCOMPAGNATEUR_NATIONAL" ||
        (user.profil === "ANIMATEUR_REGIONAL" &&
          ((user.grae && user.grae.id === graeId) ||
            (user.instructedGraes && user.instructedGraes.indexOf(graeId) !== -1))))
    )
  }

  isAccompagnateurNationalOrAdmin(user: UtilisateurDTO): boolean {
    return (
      user.profil &&
      (user.profil === "ACCOMPAGNATEUR_NATIONAL" || user.profil === "SUPER_ADMINISTRATEUR")
    )
  }

  isObservateurNational(user: UtilisateurDTO): boolean {
    return user.profil && user.profil === "OBSERVATEUR_NATIONAL"
  }

  isAccompagnateurRegional(user: UtilisateurDTO): boolean {
    return (
      user.profil &&
      (user.profil === "ANIMATEUR_REGIONAL" || user.profil === "ACCOMPAGNATEUR_REGIONAL")
    )
  }

  isAnimateurRegional(user: UtilisateurDTO): boolean {
    return user.profil && user.profil === "ANIMATEUR_REGIONAL"
  }

  async checkProfilAndGrae(
    courriel: string,
    expectedProfil: ProfilUtilisateur,
    grae?: GraeDTO
  ): Promise<boolean> {
    const dto = new UtilisateurDTO()
    dto.courriel = courriel
    dto.profil = expectedProfil
    dto.grae = grae
    const hasExpectedProfilAndGrae: boolean = await this.typedPostWithDifferentResultTypeV2<
      UtilisateurDTO,
      boolean
    >("/users/profile-check", 2, dto)
    return hasExpectedProfilAndGrae
  }

  isUserAccompagnateurFromRegion(loggedUser: UtilisateurDTO, projetDTO: ProjetAnnuelDTO): boolean {
    const isAdmin =
      loggedUser &&
      loggedUser.profil &&
      (loggedUser.profil == PROFIL_UTILISATEUR.ACCOMPAGNATEUR_NATIONAL ||
        ((loggedUser.profil == PROFIL_UTILISATEUR.ANIMATEUR_REGIONAL ||
          loggedUser.profil == PROFIL_UTILISATEUR.ACCOMPAGNATEUR_REGIONAL) &&
          ((loggedUser.grae && loggedUser.grae.id === projetDTO.grae.id) ||
            (loggedUser.instructedGraes &&
              loggedUser.instructedGraes.filter((graeId) => graeId === projetDTO.grae.id).length !==
                0))) ||
        loggedUser.profil == PROFIL_UTILISATEUR.SUPER_ADMINISTRATEUR)
    return isAdmin as boolean
  }

  async getDemandesCreationCompteNationales(): Promise<Array<UtilisateurDTO>> {
    // If we want to paginate this one day
    // Add a paginationParameters: PaginationParameter parameters - see UserService.getAllUser()
    const paginationParameters = new PaginationParameter()
    // We want pageNumber 0, but AbstractRestService operates a -1
    paginationParameters.pageNumber = 1
    paginationParameters.pageSize = -1

    const urlPath = "users/creation-comptes-nationales"
    const members = await super.typeGetPaginatedV2<UtilisateurDTO>(
      urlPath,
      2,
      paginationParameters,
      []
    )
    return members.elements
  }

  async affectGraes(userId: string, graeIds: string[]): Promise<UtilisateurDTO> {
    return super.typedPostWithDifferentResultTypeV2(`users/${userId}/affect-graes`, 2, graeIds)
  }
}
