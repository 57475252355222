var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PageView",
    {
      attrs: {
        mainTitle:
          _vm.loggedUser &&
          (_vm.loggedUser.profil == "ANIMATEUR_REGIONAL" ||
            _vm.loggedUser.profil == "ACCOMPAGNATEUR_REGIONAL")
            ? _vm.$t("menu.contact-porteurs-projet")
            : _vm.$t("usersList.title"),
        noSubTitleExpected: ""
      }
    },
    [
      _c(
        "ContentView",
        { attrs: { wideScreen: "" } },
        [_c("GraeContactView", { attrs: { loggedUser: _vm.loggedUser } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }