import {
  ParametrageDTO,
  FinancementDTO,
  ConseilDTO,
  ActualiteDTO,
} from "@/model/bean/GeneratedDTOs"

/*-
 * #%L
 * Aires Éducatives
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
export default class ConversionUtils {
  static async convertFileToBase64String(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
        resolve(reader.result as string)
      }
      reader.onerror = function (error) {
        reject(error)
      }
    })
  }

  static convertToUTCDate(date: Date): Date {
    const retrievedDate = new Date(date)
    const convertedDate = new Date(
      Date.UTC(
        retrievedDate.getFullYear(),
        retrievedDate.getMonth(),
        retrievedDate.getDate(),
        retrievedDate.getHours(),
        retrievedDate.getMinutes()
      )
    )
    return convertedDate
  }

  static convertParametrageDatesToLocal(parametrageDTO: ParametrageDTO): ParametrageDTO {
    if (parametrageDTO.debutInscription) {
      parametrageDTO.debutInscription = new Date(parametrageDTO.debutInscription)
    }
    if (parametrageDTO.finInscription) {
      parametrageDTO.finInscription = new Date(parametrageDTO.finInscription)
    }
    if (parametrageDTO.debutLabellisation) {
      parametrageDTO.debutLabellisation = new Date(parametrageDTO.debutLabellisation)
    }
    if (parametrageDTO.finLabellisation) {
      parametrageDTO.finLabellisation = new Date(parametrageDTO.finLabellisation)
    }
    return parametrageDTO
  }

  static convertFinancementDatesToLocal(financementDTO: FinancementDTO): FinancementDTO {
    if (financementDTO.debut) {
      financementDTO.debut = new Date(financementDTO.debut)
    }
    if (financementDTO.fin) {
      financementDTO.fin = new Date(financementDTO.fin)
    }
    return financementDTO
  }

  static convertConseilDatesToLocal(conseilDTO: ConseilDTO): ConseilDTO {
    if (conseilDTO.dateConseil) {
      conseilDTO.dateConseil = new Date(conseilDTO.dateConseil)
    }
    return conseilDTO
  }

  static convertActualiteDatesToUTC(actualite: ActualiteDTO): ActualiteDTO {
    actualite.dateCreation = this.convertToUTCDate(actualite.dateCreation)
    actualite.dateDebutPublication = this.convertToUTCDate(actualite.dateDebutPublication)
    actualite.dateFinPublication = this.convertToUTCDate(actualite.dateFinPublication)
    return actualite
  }

  // Shows the year according to annee_scolaire begging / end
  // E.g. Janvier 2021 -> Décembre 2021 => "2021"
  // Aout 2021 -> Juillet 2022 = "2021-2022"
  static getAnneeScolaireString(anneeScolaireDebut: Date, anneeScolaireFin: Date): string {
    const anneeDebut = ("" + anneeScolaireDebut).split("-")[0]
    const anneeFin = ("" + anneeScolaireFin).split("-")[0]
    if (anneeDebut == anneeFin) {
      return anneeDebut
    } else {
      return anneeDebut + "-" + anneeFin
    }
  }
}
