var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        "MainVerticalMenu " +
        (_vm.toggleOpen && !_vm.hasContextMenu ? "open" : "")
    },
    [
      _c(
        "div",
        { ref: "start", staticClass: "MainVerticalMenu-start" },
        _vm._l(_vm.items, function(item, index) {
          return _c(
            "div",
            {
              key: item.label,
              on: {
                click: function($event) {
                  item.label == _vm.labelPorteur
                    ? _vm.clickOnItem(item)
                    : function() {
                        return false
                      }
                }
              }
            },
            [
              _c(
                "router-link",
                {
                  ref: "item" + index,
                  refInFor: true,
                  staticClass: "MainVerticalMenu-item",
                  class: { "router-link-active": item.isLinkActive },
                  attrs: { to: { path: item.toPath } }
                },
                [
                  _c("b-icon", {
                    attrs: { size: "is-large", icon: item.icon }
                  }),
                  _vm.toggleOpen && !_vm.hasContextMenu
                    ? _c("span", { staticClass: "content-label" }, [
                        _vm._v(" " + _vm._s(item.label) + " "),
                        _vm.shouldDisplayBadge(item.label)
                          ? _c("span", { staticClass: "badge" }, [
                              _vm._v(_vm._s(_vm.pendingRequest.users))
                            ])
                          : _vm._e(),
                        _vm.isDisplayPorteurProjet(item.label)
                          ? _c("span", { staticClass: "badge porteur" }, [
                              _vm._v(_vm._s(_vm.pendingRequest.modif_porteur))
                            ])
                          : _vm._e()
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "MainVerticalMenu-end" },
        [
          _c("FeedbackModal", { staticClass: "MainVerticalMenu-feedback" }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.hasContextMenu,
                  expression: "!hasContextMenu"
                }
              ],
              staticClass: "MainVerticalMenu-arrow-button clickable",
              on: { click: _vm.toggleOpenMenu }
            },
            [
              _c("b-icon", {
                attrs: {
                  size: "is-normal",
                  icon:
                    "" +
                    (_vm.toggleOpen
                      ? "angle-double-left"
                      : "angle-double-right")
                }
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }