





























































import { CommentaireDTO } from "@/model/bean/GeneratedDTOs"
import { Component, Prop, Vue } from "vue-property-decorator"
import { format } from "date-fns"
import * as frLocale from "date-fns/locale/fr"

const DATE_FORMAT = "DD/MM/YYYY"

@Component({
  components: {},
})
export default class CommentairesModale extends Vue {
  @Prop() commentaires: Array<CommentaireDTO>
  @Prop() open: boolean

  emitCloseModale(): void {
    this.$emit("close-modale")
  }

  formatDate(date: Date): string {
    return date ? format(date, DATE_FORMAT, { locale: frLocale }) : ""
  }
}
