


























































































































































































import { TAG_TYPES_FOR_BOOKMARK_CARD } from "@/model/bean/EnumHelper"
import {
  ActualiteDTO,
  FilterDTO,
  GraeDTO,
  PaginationParameter,
  UtilisateurDTO,
} from "@/model/bean/GeneratedDTOs"
import { Filters } from "@/model/bean/Filters"
import BookmarkCard from "@/views/generic_components/BookmarkCard.vue"
import { Component, Prop, Vue } from "vue-property-decorator"
import { format } from "date-fns"
import { DATE_TIME_FORMAT } from "@/views/grae/ActualitesView.vue"
import { GraeService } from "@/services/rest/grae/GraeService"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import H3TitleView from "@/views/commons/H3TitleView.vue"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import ConversionUtils from "@/services/ConversionUtils"
import { OrganismeService } from "@/services/rest/organisme/OrganismeService"
import { UsersService } from "@/services/rest/users/UsersService"

@Component({ components: { BookmarkCard, H3TitleView, InputWithValidation } })
export default class ActualitesForPorteursProjetView extends Vue {
  @Prop() graeId?: string

  private graeService = GraeService.INSTANCE
  private infoReportingService = InfoReportingService.INSTANCE
  private organismeService = OrganismeService.INSTANCE
  private usersService = UsersService.INSTANCE

  grae = new GraeDTO()
  actualites: ActualiteDTO[] = []
  types = TAG_TYPES_FOR_BOOKMARK_CARD
  open = false

  actualiteSelected = new ActualiteDTO()
  contact = new UtilisateurDTO()

  created(): void {
    this.refreshData()
  }

  async refreshData(): Promise<void> {
    if (!this.graeId) {
      this.contact.courriel = this.$t("default.email").toString()
      this.contact.telephone = this.$t("default.phone").toString()
      this.contact.nom = this.$t("default.profil").toString()
      return
    }

    const paginationParameter = new PaginationParameter()
    paginationParameter.pageNumber = 1
    paginationParameter.pageSize = 9999

    const filters = new Array<FilterDTO>()
    const today = ConversionUtils.convertToUTCDate(new Date()).toISOString().substring(0, 10)
    const publishedAtFilter = Filters.asFilter({ property: "is-published-at", values: [today] })
    filters.push(publishedAtFilter)

    try {
      const paginatedResponse = await this.graeService.getAllActualitesForGRAE(
        this.graeId,
        paginationParameter,
        filters
      )
      this.grae = await this.organismeService.getGrae(this.graeId)
      console.log(this.grae.contactId)
      if (this.grae.contactId) {
        try {
          this.contact = await this.usersService.getUserProfile(this.grae.contactId)
        } catch (e) {
          this.infoReportingService.error("Erreur rencontrée sur le contact", e as Error)
        }
      } else {
        this.contact.courriel = this.$t("default.email").toString()
        this.contact.telephone = this.$t("default.phone").toString()
        this.contact.nom = this.$t("default.profil").toString()
      }

      this.actualites = paginatedResponse.elements
      console.log(this.contact)
      console.log(this.contact.id)
    } catch (e) {
      this.infoReportingService.error(
        this.$t("projet.en-bref.error-actualites").toString(),
        e as Error
      )
    }
  }

  formatDate(date: Date): string {
    if (date) {
      return format(date, DATE_TIME_FORMAT)
    }
    return ""
  }

  openModale(actuId: string): void {
    const actuFound = this.actualites.find((a) => a.id === actuId)
    if (!actuFound) {
      return
    }
    this.actualiteSelected = actuFound
    this.open = true
  }
}
