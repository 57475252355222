var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "projet-part" },
    [
      _c("h2", [_vm._v(_vm._s(_vm.$t("projetPage.labellisation.plus.titre")))]),
      _c("p", [_vm._v(_vm._s(_vm.$t("projetPage.labellisation.plus.intro")))]),
      _c("InputWithValidation", {
        attrs: {
          validationActivated: _vm.validationActivated,
          showValidationErrors: _vm.showValidationErrors,
          rules: "numeric|required",
          type: "text",
          label:
            _vm.projetAnnuel.typeDossier !== "DOSSIER_RENOUVELLEMENT"
              ? _vm.$t("projetPage.labellisation.plus.nb-sorties-label")
              : _vm.$t("projetPage.labellisation.plus.nb-sorties-label-lourd"),
          disabled: _vm.readOnly,
          vid: "plus_nbSortiesSite"
        },
        on: {
          "manual-change": function($event) {
            return _vm.modified()
          }
        },
        model: {
          value: _vm.projetAnnuel.nbSortiesSite,
          callback: function($$v) {
            _vm.$set(_vm.projetAnnuel, "nbSortiesSite", $$v)
          },
          expression: "projetAnnuel.nbSortiesSite"
        }
      }),
      _vm.projetAnnuel.typeDossier !== "DOSSIER_RENOUVELLEMENT"
        ? _c("InputWithValidation", {
            attrs: {
              validationActivated: _vm.validationActivated,
              showValidationErrors: _vm.showValidationErrors,
              rules: "numeric|required",
              type: "text",
              label: _vm.$t(
                "projetPage.labellisation.plus.nb-presence-referent-label"
              ),
              disabled: _vm.readOnly,
              vid: "plus_nbTotalPresenceReferent"
            },
            on: {
              "manual-change": function($event) {
                return _vm.modified()
              }
            },
            model: {
              value: _vm.projetAnnuel.nbTotalPresenceReferent,
              callback: function($$v) {
                _vm.$set(_vm.projetAnnuel, "nbTotalPresenceReferent", $$v)
              },
              expression: "projetAnnuel.nbTotalPresenceReferent"
            }
          })
        : _c("InputWithValidation", {
            attrs: {
              validationActivated: _vm.validationActivated,
              showValidationErrors: _vm.showValidationErrors,
              rules: "required",
              type: "textarea",
              label: _vm.$t(
                "projetPage.labellisation.plus.nb-presence-referent-label-lourd"
              ),
              disabled: _vm.readOnly,
              vid: "plus_cmtFonctionnementBinome"
            },
            on: {
              "manual-change": function($event) {
                return _vm.modified()
              }
            },
            model: {
              value: _vm.projetAnnuel.cmtFonctionnementBinome,
              callback: function($$v) {
                _vm.$set(_vm.projetAnnuel, "cmtFonctionnementBinome", $$v)
              },
              expression: "projetAnnuel.cmtFonctionnementBinome"
            }
          }),
      _vm.projetAnnuel.typeDossier !== "DOSSIER_RENOUVELLEMENT"
        ? _c("SelectWithValidation", {
            attrs: {
              validationActivated: _vm.validationActivated,
              showValidationErrors: _vm.showValidationErrors,
              label: _vm.$t("projetPage.labellisation.plus.temps-passe-projet"),
              data: _vm.tempsPasseProjet,
              selectLabels: _vm.tempsPasseProjetLabels,
              rules: "required",
              disabled: _vm.readOnly,
              vid: "plus_tempsPasseProjet"
            },
            on: {
              "manual-change": function($event) {
                return _vm.modified()
              }
            },
            model: {
              value: _vm.projetAnnuel.tempsPasseProjet,
              callback: function($$v) {
                _vm.$set(_vm.projetAnnuel, "tempsPasseProjet", $$v)
              },
              expression: "projetAnnuel.tempsPasseProjet"
            }
          })
        : _c("InputWithValidation", {
            attrs: {
              validationActivated: _vm.validationActivated,
              showValidationErrors: _vm.showValidationErrors,
              rules: "required",
              type: "textarea",
              label: _vm.$t(
                "projetPage.labellisation.plus.temps-passe-projet-lourd"
              ),
              disabled: _vm.readOnly,
              vid: "plus_cmtProjetEtablissement"
            },
            on: {
              "manual-change": function($event) {
                return _vm.modified()
              }
            },
            model: {
              value: _vm.projetAnnuel.cmtProjetEtablissement,
              callback: function($$v) {
                _vm.$set(_vm.projetAnnuel, "cmtProjetEtablissement", $$v)
              },
              expression: "projetAnnuel.cmtProjetEtablissement"
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }