var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showInviteLink
        ? _c(
            "a",
            {
              staticClass: "invite",
              on: {
                click: function($event) {
                  return _vm.$emit("invite-referent")
                }
              }
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t("projetPage.infoAdministratives.participants.inviter")
                )
              )
            ]
          )
        : _vm._e(),
      _c("br"),
      !_vm.hasEnoughValidatedReferents
        ? _c(
            "div",
            [
              _c("InputWithValidation", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false"
                  }
                ],
                attrs: { vid: "participants_referents", rules: "required" }
              }),
              _c("b-message", { attrs: { type: "is-danger" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "projetPage.infoAdministratives.participants.at-least-one-referent"
                      )
                    ) +
                    " "
                )
              ]),
              _c("br")
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-table",
        {
          ref: _vm.tableRef,
          attrs: {
            striped: _vm.config.isStriped,
            hoverable: false,
            "debounce-search": _vm.config.searchDebounce,
            "aria-next-label": _vm.config.nextLabel(),
            "aria-previous-label": _vm.config.previousLabel(),
            "aria-page-label": _vm.config.pageLabel(),
            "aria-current-label": _vm.config.currentPageLabel(),
            "per-page": _vm.config.perPage,
            "sticky-header": false,
            "current-page": _vm.currentPagedSortedParameter.pageNumber,
            "pagination-position": _vm.config.paginationPosition,
            "backend-pagination": "",
            "sort-icon": _vm.config.sortIcon,
            "sort-icon-size": _vm.config.sortIconSize,
            "backend-sorting": "",
            "sort-multiple": "",
            "sort-multiple-data": _vm.buefySortingPriority,
            total: _vm.total,
            data: _vm.referents,
            loading: _vm.isLoading
          },
          on: {
            sort: _vm.onSort,
            "sorting-priority-removed": _vm.sortingPriorityRemoved,
            "page-change": _vm.onPageChange
          }
        },
        [
          _vm._l(_vm.columns, function(column) {
            return [
              _c(
                "b-table-column",
                _vm._b(
                  {
                    key: column.id,
                    attrs: { visible: column.visible !== false },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(props) {
                            return [
                              column.field === "nouveau"
                                ? _c(
                                    "div",
                                    [
                                      _vm.isNewReferent(props.row.id)
                                        ? _c("b-icon", {
                                            attrs: {
                                              icon: "circle",
                                              size: "is-small",
                                              type: "is-primary"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              column.field === "structure" &&
                              props.row.structure
                                ? _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(props.row.structure.nom) +
                                        " "
                                    )
                                  ])
                                : _vm._e(),
                              column.field === "structure" &&
                              !props.row.structure
                                ? _c("div")
                                : _vm._e(),
                              column.field === "prenom"
                                ? _c("div", [
                                    _vm.canSeeCourriel(props.row) &&
                                    _vm.participating(props.row)
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "clickable",
                                            on: {
                                              click: function($event) {
                                                return _vm.showUserDetailsPage(
                                                  $event,
                                                  props.row["id"]
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(props.row.prenom) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _c("div", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "projetPage.infoAdministratives.participants.referentDe",
                                                  [
                                                    props.row.structure
                                                      ? props.row.structure.nom
                                                      : _vm.$t("inconnu")
                                                  ]
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                  ])
                                : _vm._e(),
                              column.field === "nom"
                                ? _c("div", [
                                    _vm.canSeeCourriel(props.row) &&
                                    _vm.participating(props.row)
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "clickable",
                                            on: {
                                              click: function($event) {
                                                return _vm.showUserDetailsPage(
                                                  $event,
                                                  props.row["id"]
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(props.row.nom) + " "
                                            )
                                          ]
                                        )
                                      : _c("div", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "projetPage.infoAdministratives.participants.referentDe",
                                                  [
                                                    props.row.structure
                                                      ? props.row.structure.nom
                                                      : _vm.$t("inconnu")
                                                  ]
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                  ])
                                : _vm._e(),
                              column.field === "courriel"
                                ? _c("div", [
                                    _vm.participating(props.row)
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "clickable",
                                            on: {
                                              click: function($event) {
                                                return _vm.showUserDetailsPage(
                                                  $event,
                                                  props.row["id"]
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.treatCourriel(
                                                    props.row,
                                                    false
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _c("div", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.treatCourriel(
                                                  props.row,
                                                  true
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                  ])
                                : _vm._e(),
                              column.field === "participation"
                                ? _c("div", [
                                    _vm.participating(props.row)
                                      ? _c(
                                          "div",
                                          { staticClass: "participating" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "projetPage.infoAdministratives.participants.participe"
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _c("b-icon", {
                                              staticClass: "bullet-marker",
                                              attrs: {
                                                icon: "check-circle",
                                                size: "is-small"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm.invited(props.row)
                                      ? _c("div", { staticClass: "invited" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "projetPage.infoAdministratives.participants.invitationEnvoyee"
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                      : _vm._e()
                                  ])
                                : _vm._e(),
                              column.field === "action"
                                ? _c(
                                    "div",
                                    [
                                      _vm.showInviteButton(props.row)
                                        ? _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                disabled: _vm.readonly,
                                                type: "is-primary is-outlined"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.invite(props.row)
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "projetPage.infoAdministratives.participants.inviterCourt"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.showQuitButton(props.row)
                                        ? _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                disabled: _vm.readonly,
                                                type: "is-danger is-outlined"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.quit(props.row)
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "projetPage.infoAdministratives.participants.quitter"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.showRemove(props.row)
                                        ? _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                disabled: _vm.readonly,
                                                type: "is-warning is-outlined"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.annuler(props.row)
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "projetPage.infoAdministratives.participants.annuler"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !column.custom
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "clickable",
                                      on: {
                                        click: function($event) {
                                          return _vm.showUserDetailsPage(
                                            $event,
                                            props.row["id"]
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(_vm._s(props.row[column.field]))
                                      ])
                                    ]
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  "b-table-column",
                  column,
                  false
                )
              )
            ]
          })
        ],
        2
      ),
      _c("br"),
      _c("br"),
      _c(
        "b-table",
        {
          ref: _vm.tableRef,
          class: {
            clickable: !_vm.isPorteurProjet
          },
          attrs: {
            data: _vm.structures,
            striped: _vm.config.isStriped,
            hoverable: false,
            "aria-next-label": _vm.config.nextLabel(),
            "aria-previous-label": _vm.config.previousLabel(),
            "aria-page-label": _vm.config.pageLabel(),
            "aria-current-label": _vm.config.currentPageLabel(),
            "sort-icon": _vm.config.sortIcon,
            "sort-icon-size": _vm.config.sortIconSize,
            loading: _vm.isLoading
          },
          on: {
            click: function($event) {
              !_vm.isPorteurProjet
                ? _vm.showStructureDetailsPage($event)
                : _vm.isPorteurProjet
            }
          }
        },
        [
          _c("b-table-column", {
            attrs: {
              field: "name",
              label: "Nom de la structure",
              width: "120",
              sortable: ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(props) {
                  return [_vm._v(" " + _vm._s(props.row.nom) + " ")]
                }
              }
            ])
          }),
          _c("b-table-column", {
            attrs: { field: "type", label: "Type", width: "80", sortable: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(props) {
                  return [_vm._v(" " + _vm._s(props.row.type) + " ")]
                }
              }
            ])
          }),
          _c("b-table-column", {
            attrs: {
              field: "name",
              width: "120",
              label: "Adresse",
              sortable: ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(props) {
                  return [
                    _vm._v(" " + _vm._s(props.row.rue)),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(props.row.codePostal) +
                        " " +
                        _vm._s(props.row.ville) +
                        " "
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }