var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "projet-part" },
    [
      _c("h2", [_vm._v(_vm._s(_vm.$t("projet.site.titre")))]),
      _c("DocumentationLinks", { attrs: { links: _vm.links } }),
      _c("RadioWithInputAndValidation", {
        attrs: {
          validationActivated: _vm.validationActivated,
          showValidationErrors: _vm.showValidationErrors,
          rules: "required",
          data: _vm.natures,
          optionLabels: _vm.natureLabels,
          verticalDisplay: "true",
          disabled: _vm.readonly || _vm.projetAnnuel.statut !== "BROUILLON",
          label: _vm.$t("projet.inscription.site-inscription.nature-question"),
          inputNotice:
            _vm.projetAnnuel.etat == "EN_COURS_DE_CREATION"
              ? _vm.$t(
                  "projet.inscription.site-inscription.nature-question-notice"
                )
              : "",
          vid: "site_1"
        },
        on: {
          "manual-change": function($event) {
            return _vm.$emit("dirtify-project")
          }
        },
        model: {
          value: _vm.projetAnnuel.natureAireEducative,
          callback: function($$v) {
            _vm.$set(_vm.projetAnnuel, "natureAireEducative", $$v)
          },
          expression: "projetAnnuel.natureAireEducative"
        }
      }),
      _c("RadioWithInputAndValidation", {
        attrs: {
          validationActivated: _vm.validationActivated,
          showValidationErrors: _vm.showValidationErrors,
          data: _vm.natureSiteValue,
          optionLabels: _vm.natureSiteValueLabels,
          disabled: _vm.readonly,
          verticalDisplay: "true",
          label: _vm.$t("projetPage.infoAdministratives.site.nature-site"),
          inputNotice: _vm.$t(
            "projetPage.infoAdministratives.site.nature-site-message"
          ),
          tooltip: _vm.$t(
            "projetPage.infoAdministratives.site.nature-site-tooltip"
          ),
          vid: "site_natureSite"
        },
        on: { "manual-change": _vm.modified },
        model: {
          value: _vm.projetAnnuel.natureSite,
          callback: function($$v) {
            _vm.$set(_vm.projetAnnuel, "natureSite", $$v)
          },
          expression: "projetAnnuel.natureSite"
        }
      }),
      _c("RadioWithInputAndValidation", {
        attrs: {
          validationActivated: _vm.validationActivated,
          showValidationErrors: _vm.showValidationErrors,
          rules:
            _vm.projetAnnuel.typeDossier === "DOSSIER_RENOUVELLEMENT_LIGHT"
              ? ""
              : "required",
          data: _vm.proprietaireValue,
          optionLabels: _vm.proprietaireValueLabels,
          disabled: _vm.readonly,
          verticalDisplay: "true",
          label: _vm.$t(
            "projetPage.infoAdministratives.site.autorisation-proprietaire"
          ),
          vid: "site_autorisationProprietaireSite"
        },
        on: { "manual-change": _vm.modified },
        model: {
          value: _vm.projetAnnuel.autorisationProprietaire,
          callback: function($$v) {
            _vm.$set(_vm.projetAnnuel, "autorisationProprietaire", $$v)
          },
          expression: "projetAnnuel.autorisationProprietaire"
        }
      }),
      _c("RadioWithInputAndValidation", {
        attrs: {
          validationActivated: _vm.validationActivated,
          showValidationErrors: _vm.showValidationErrors,
          data: _vm.booleanValue,
          optionLabels: _vm.booleanValueLabels,
          disabled: _vm.readonly,
          verticalDisplay: "true",
          label: _vm.$t(
            "projetPage.infoAdministratives.site.avis-favorable-conseil-municipal"
          ),
          inputNotice: _vm.$t(
            "projetPage.infoAdministratives.site.avis-favorable-input-notice"
          ),
          tooltip: _vm.$t(
            "projetPage.infoAdministratives.site.autorisation-proprietaire-tooltip"
          ),
          vid: "site_avisFavorableConseilMunicipalSite"
        },
        on: { "manual-change": _vm.modified },
        model: {
          value: _vm.projetAnnuel.avisFavorableConseilMunicipalSite,
          callback: function($$v) {
            _vm.$set(_vm.projetAnnuel, "avisFavorableConseilMunicipalSite", $$v)
          },
          expression: "projetAnnuel.avisFavorableConseilMunicipalSite"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }