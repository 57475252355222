

































import { ValidationProvider } from "vee-validate"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"

@Component({
  components: {
    ValidationProvider,
  },
})
export default class CheckboxWithValidation extends Vue {
  @Prop({ default: "" }) group: string
  @Prop({ default: "" }) vid: string
  @Prop() rules: [string]
  @Prop({ default: "" }) errorMessage: string
  @Prop() value: boolean
  @Prop({ default: false }) disabled: boolean
  innerValue = false

  @Watch("innerValue")
  onInnerValueChanged(newVal: boolean, _oldValue: boolean): void {
    this.$emit("input", newVal)
  }

  @Watch("value")
  onExternalValueChanged(newVal: boolean, _oldValue: boolean): void {
    this.innerValue = newVal
    if (newVal != _oldValue) {
      this.$emit("manual-change", newVal)
    }
  }

  created(): void {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
