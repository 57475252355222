





























import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { ValidationObserver } from "vee-validate"
import { UsersService } from "@/services/rest/users/UsersService"
import TitleView from "@/views/commons/TitleView.vue"
import { UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import UserPersonnalInfo from "@/views/commons/UserPersonnalInfo.vue"
import CheckboxWithValidation from "@/views/inputs/CheckBoxWithValidation.vue"

@Component({
  components: {
    ValidationObserver,
    UserPersonnalInfo,
    TitleView,
    CheckboxWithValidation,
  },
})
export default class UserInfoDetails extends Vue {
  @Prop() user: UtilisateurDTO
  @Prop({ default: false }) readonly: boolean

  private userService = UsersService.INSTANCE
  errorMessage = ""

  acceptNameVisibilityOnProtectedListing = false

  @Watch("user")
  onUserChanged(): void {
    this.acceptNameVisibilityOnProtectedListing = this.user.acceptNameVisibilityOnProtectedListing
  }

  // Used by ProfileModale on save
  async submitProfile(): Promise<string> {
    const observerComponent = this.$refs["observer"]
    //@ts-ignore
    const validationResult = await observerComponent.validateWithInfo()

    const errors = Object.values(validationResult.errors).flat()
    if (errors.length !== 0) {
      this.errorMessage = this.$i18n.t("profilePage.missing-fields").toString()
      return this.errorMessage
    }

    this.errorMessage = ""
    // Updates the users personnal information
    if (this.user) {
      this.user.acceptNameVisibilityOnProtectedListing = this.acceptNameVisibilityOnProtectedListing
      return this.userService.updateProfile(this.user).then(
        (_success) => {
          return ""
        },
        (reject) => {
          this.errorMessage = this.$i18n.t("profilePage.modification-error").toString()
          if (parseInt(reject["status"]) == 400) {
            this.errorMessage = this.$i18n.t("registerPage.email-deja-utilise").toString()
          }
          return this.errorMessage
        }
      )
    } else {
      return ""
    }
  }

  changeUserInfos(utilisateur: UtilisateurDTO): void {
    this.user.acceptNameVisibilityOnProtectedListing = this.acceptNameVisibilityOnProtectedListing
    this.user = utilisateur
  }
  emitCloseModale(): void {
    this.$emit("close-modale")
  }
}
