var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationProvider", {
    attrs: {
      vid: (_vm.group != "" ? _vm.group + "_" : "") + _vm.vid,
      name: _vm.$attrs.name || _vm.$attrs.label,
      rules: _vm.rules
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var errors = ref.errors
          var valid = ref.valid
          return [
            _c(
              "b-field",
              {
                staticClass: "DatePickerWithValidation-field",
                attrs: {
                  type: {
                    "is-normal": _vm.disabled,
                    "is-danger": errors[0],
                    "is-success": valid
                  },
                  message: errors
                }
              },
              [
                _c(
                  "template",
                  { slot: "label" },
                  [
                    _c("FormInputLabel", {
                      attrs: {
                        validationActivated: _vm.validationActivated,
                        showValidationErrors: _vm.showValidationErrors,
                        label: _vm.$attrs.label,
                        isMandatory: _vm.isMandatory,
                        disabled: _vm.disabled,
                        tooltip: _vm.tooltip
                      }
                    })
                  ],
                  1
                ),
                _c("b-datepicker", {
                  attrs: {
                    "show-week-number": "",
                    placeholder: _vm.placeholder,
                    locale: "fr",
                    icon: "calendar-alt",
                    "trap-focus": "",
                    disabled: _vm.disabled,
                    "custom-class": _vm.disabled
                      ? "DatePickerWithValidation-disabled"
                      : "",
                    "min-date": _vm.minDate,
                    "max-date": _vm.maxDate,
                    "unselectable-dates": _vm.unselectableDates
                  },
                  on: { input: _vm.onInnerValueChanged },
                  model: {
                    value: _vm.innerValue,
                    callback: function($$v) {
                      _vm.innerValue = $$v
                    },
                    expression: "innerValue"
                  }
                })
              ],
              2
            ),
            !_vm.hideBr ? _c("br") : _vm._e(),
            !_vm.hideBr ? _c("br") : _vm._e()
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }