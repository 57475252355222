var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "instruction-pannel-entry mt-2" },
    [
      _c("h2", { staticClass: "h2" }, [
        _vm._v(_vm._s(_vm.$t("instruction-pannel.echanges.title")))
      ]),
      _vm.isNational || _vm.isRegional
        ? _c("ValidationObserver", {
            ref: "commentObserver",
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var handleSubmit = ref.handleSubmit
                    return [
                      _c("div", { staticClass: "columns mt-5" }, [
                        _c("div", { staticClass: "column is-one-third" }, [
                          _c("div", { staticClass: "columns" }, [
                            _c("div", { staticClass: "column pb-0" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.loggedUser.prenom +
                                    " " +
                                    _vm.loggedUser.nom
                                )
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "columns" }, [
                            _c(
                              "div",
                              { staticClass: "column pb-0 today date-style" },
                              [_vm._v(_vm._s(_vm.formatDate(new Date())))]
                            ),
                            _c(
                              "div",
                              { staticClass: "column pt-4 pb-0" },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    icon: "comment-alt",
                                    size: "is-small",
                                    type: "is-info"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "column" }, [
                          _c("div", { staticClass: "columns" }, [
                            _c(
                              "div",
                              { staticClass: "column pt-0 pb-0" },
                              [
                                _c("InputWithValidation", {
                                  attrs: {
                                    rules: "required",
                                    type: "textarea",
                                    vid: "comment",
                                    maxlength: "1400"
                                  },
                                  model: {
                                    value: _vm.contenu,
                                    callback: function($$v) {
                                      _vm.contenu = $$v
                                    },
                                    expression: "contenu"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _c("div", { staticClass: "columns" }, [
                            _c(
                              "div",
                              { staticClass: "column pt-0 pb-0" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: { type: "is-info is-outlined" },
                                    on: {
                                      click: function($event) {
                                        return handleSubmit(_vm.addComment)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "instruction-pannel.echanges.poster"
                                        )
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ])
                      ])
                    ]
                  }
                }
              ],
              null,
              false,
              1434598312
            )
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "mt-5" },
        _vm._l(_vm.echanges.elements, function(echange) {
          return _c(
            "div",
            { key: echange.id, staticClass: "columns mt-1 mb-0" },
            [
              _c("div", { staticClass: "column is-one-third" }, [
                _c("div", { staticClass: "columns" }, [
                  echange.auteur.nom !== null
                    ? _c("div", { staticClass: "column pb-0" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              echange.auteur.prenom + " " + echange.auteur.nom
                            ) +
                            " "
                        )
                      ])
                    : _c("div", { staticClass: "column pb-0" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("instruction-pannel.echanges.unknown-user")
                            ) +
                            " "
                        )
                      ])
                ]),
                _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column pb-0 today date-style" }, [
                    _vm._v(
                      " " + _vm._s(_vm.formatDate(echange.dateCreation)) + " "
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "column pt-4 pb-0" },
                    [
                      echange.type === "COMMENTAIRE"
                        ? _c("b-icon", {
                            attrs: {
                              icon: "comment-alt",
                              size: "is-small",
                              type: "is-info"
                            }
                          })
                        : _vm._e(),
                      echange.type === "COURRIEL"
                        ? _c("b-icon", {
                            attrs: {
                              icon: "envelope",
                              size: "is-small",
                              type: "is-info"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ]),
              _c("div", { staticClass: "column" }, [
                _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column pb-0" }, [
                    _c("div", { staticClass: "textarea-container" }, [
                      echange.type === "COMMENTAIRE"
                        ? _c(
                            "div",
                            {
                              staticClass: "box message",
                              on: {
                                click: function($event) {
                                  return _vm.openComment(echange)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(echange.contenu) + " ")]
                          )
                        : _vm._e(),
                      echange.type === "COURRIEL"
                        ? _c(
                            "div",
                            {
                              staticClass: "box courriel-title",
                              on: {
                                click: function($event) {
                                  return _vm.openCourriel(echange)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(echange.titre) + " ")]
                          )
                        : _vm._e(),
                      echange.type === "COMMENTAIRE"
                        ? _c("div", { staticClass: "actions" }, [
                            _c("div", { staticClass: "columns" }, [
                              _c("div", { staticClass: "column p-0 m-0" }, [
                                _c(
                                  "a",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.openComment(echange)
                                      }
                                    }
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: {
                                        icon: "angle-down",
                                        size: "is-small",
                                        type: "is-dark"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ]),
                            _c("div", { staticClass: "columns" }, [
                              _c("div", { staticClass: "column p-0 m-0" }, [
                                _vm.isNational || _vm.isRegional
                                  ? _c(
                                      "a",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.removeComment(echange)
                                          }
                                        }
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: {
                                            icon: "trash",
                                            size: "is-small",
                                            type: "is-danger"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ])
                            ])
                          ])
                        : _vm._e(),
                      echange.type === "COURRIEL"
                        ? _c("div", { staticClass: "courriel-actions" }, [
                            _c("div", { staticClass: "columns" }, [
                              _c("div", { staticClass: "column p-0 m-0" }, [
                                echange.type === "COURRIEL"
                                  ? _c(
                                      "a",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.openCourriel(echange)
                                          }
                                        }
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: {
                                            icon: "eye",
                                            size: "is-small",
                                            type: "is-dark"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ])
                            ])
                          ])
                        : _vm._e()
                    ])
                  ])
                ])
              ])
            ]
          )
        }),
        0
      ),
      _c("div", { staticClass: "columns mt-2" }, [
        _c("div", { staticClass: "column" }),
        _c(
          "div",
          { staticClass: "column is-2" },
          [
            _c("b-button", {
              attrs: {
                "icon-left": "angle-left",
                disabled: _vm.pagination.pageNumber === 1
              },
              on: {
                click: function($event) {
                  return _vm.loadPreviousPage()
                }
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column is-2" },
          [
            _c("b-button", {
              attrs: {
                "icon-left": "angle-right",
                disabled: _vm.canLoadNextPage
              },
              on: {
                click: function($event) {
                  return _vm.loadNextPage()
                }
              }
            })
          ],
          1
        )
      ]),
      _c("CommentaireModalView", {
        attrs: {
          commentaire: _vm.commentaireSelected,
          open: _vm.openCommentaireModal
        },
        on: {
          close: function($event) {
            _vm.openCommentaireModal = false
          }
        }
      }),
      _c("CourrielModalView", {
        attrs: { courriel: _vm.courrielSelected, open: _vm.openCourrielModal },
        on: {
          close: function($event) {
            _vm.openCourrielModal = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }