





































































































































import { Component, Prop, Vue } from "vue-property-decorator"

@Component
export default class FilteredElementsView extends Vue {
  @Prop({ type: [Array, Object, Map], required: true }) elements!: Array<any> | Map<any, any>
  currentYear = new Date().getFullYear()

  // Computed property to determine if data is loading
  get isLoading() {
    return (
      !this.elements ||
      (Array.isArray(this.elements) && this.elements.length === 0) ||
      (this.elements instanceof Map && this.elements.size === 0)
    )
  }
  // Helper methods to get elements array
  get elementsArray() {
    if (this.elements instanceof Map) {
      return Array.from(this.elements.values())
    } else if (Array.isArray(this.elements)) {
      return this.elements
    } else {
      return []
    }
  }

  // Computed properties for filtered elements
  get schoolYearElements() {
    return this.elementsArray.filter((element) => this.isSchoolYear(element.anneeScolaire))
  }

  get singleYearElements() {
    return this.elementsArray.filter((element) => this.isSingleYearMethod(element.anneeScolaire))
  }

  // Computed properties for school year and single year
  get anneeScolaireSingleYear() {
    return this.singleYearElements.length > 0 ? this.singleYearElements[0].anneeScolaire : null
  }

  get anneeScolaireSchoolYear() {
    return this.schoolYearElements.length > 0 ? this.schoolYearElements[0].anneeScolaire : null
  }
  get anneeScolaireSingleYearInscription() {
    return this.singleYearElements.length > 0
      ? this.singleYearElements[0].anneeScolaireInscription
      : null
  }

  get anneeScolaireSchoolYearInscription() {
    return this.schoolYearElements.length > 0
      ? this.schoolYearElements[0].anneeScolaireInscription
      : null
  }

  // Helper methods to compute total values
  totalAEActive(isSingleYear: boolean) {
    const elements = isSingleYear ? this.singleYearElements : this.schoolYearElements
    return elements.reduce(
      (count, element) => count + (element.nbAME || 0) + (element.nbATE || 0),
      0
    )
  }

  totalATEActive(isSingleYear: boolean) {
    const elements = isSingleYear ? this.singleYearElements : this.schoolYearElements
    return elements.reduce((count, element) => count + (element.nbATE || 0), 0)
  }

  totalAMEActive(isSingleYear: boolean) {
    const elements = isSingleYear ? this.singleYearElements : this.schoolYearElements
    return elements.reduce((count, element) => count + (element.nbAME || 0), 0)
  }

  newInscriptions(isSingleYear: boolean) {
    const elements = isSingleYear ? this.singleYearElements : this.schoolYearElements
    return elements.reduce((count, element) => count + (element.inscriptions || 0), 0)
  }

  labelings(isSingleYear: boolean) {
    const elements = isSingleYear ? this.singleYearElements : this.schoolYearElements
    return elements.reduce(
      (count, element) => count + (element.labellisationsAccepteesCloturees || 0),
      0
    )
  }

  renewals(isSingleYear: boolean) {
    const elements = isSingleYear ? this.singleYearElements : this.schoolYearElements
    return elements.reduce(
      (count, element) => count + (element.renouvellementsAcceptesClotures || 0),
      0
    )
  }

  // Utility methods to identify school year and single year
  isSchoolYear(anneeScolaire: string): boolean {
    return typeof anneeScolaire === "string" && /\d{4}-\d{4}/.test(anneeScolaire)
  }

  isSingleYearMethod(anneeScolaire: string): boolean {
    return typeof anneeScolaire === "string" && /^\d{4}$/.test(anneeScolaire)
  }

  // Method to handle redirection with appropriate filters
  redirectToDetails(isInscription: boolean, type: string, isSingleYear: boolean) {
    let anneeScolaire = isSingleYear
      ? this.anneeScolaireSingleYear + "-" + this.anneeScolaireSingleYear
      : this.anneeScolaireSchoolYear
    if (isInscription) {
      anneeScolaire = isSingleYear
        ? this.anneeScolaireSingleYearInscription + "-" + this.anneeScolaireSingleYearInscription
        : this.anneeScolaireSchoolYearInscription
    }
    let filters: any[] = [
      { property: "anneeScolaire", values: [anneeScolaire] },
      { property: "projet.etat", values: ["ACTIF"] },
    ]

    switch (type) {
      case "totalATEActive":
        filters.push({ label: "Nature", property: "nature", values: ["ATE"] })
        break
      case "totalAMEActive":
        filters.push({ label: "Nature", property: "nature", values: ["AME"] })
        break
      case "newInscriptions":
        filters.push({ property: "inscriptionThisYear", values: ["true"] })
        break
      case "labelings":
        filters = [
          { label: "", property: "anneeScolaire", values: [anneeScolaire] },
          { label: "Stade du projet", property: "typeDossier", values: ["DOSSIER_LABELLISATION"] },
          { label: "Statut", property: "statut", values: ["LABELLISATION_EN_DEVENIR", "ACCEPTE"] },
        ]
        break
      case "renewals":
        filters = [
          { property: "anneeScolaire", values: [anneeScolaire] },
          {
            property: "typeDossier",
            values: ["DOSSIER_RENOUVELLEMENT_LIGHT", "DOSSIER_RENOUVELLEMENT"],
          },
          { label: "Statut", property: "statut", values: ["ACCEPTE"] },
        ]
        break
    }

    const encodedFilters = encodeURIComponent(JSON.stringify(filters))
    const url = `/ae/0?filters=${encodedFilters}`
    this.$router.push(url)
  }
}
