



















































































import { Component, Prop } from "vue-property-decorator"
import { ValidationObserver } from "vee-validate"

import EtablissementIdentiteDetails from "@/views/organisme/EtablissementIdentiteDetails.vue"
import EtablissementContactDetails from "@/views/organisme/EtablissementContactDetails.vue"
import EtablissementSiteWebDetails from "@/views/organisme/EtablissementSiteWebDetails.vue"
import EtablissementChefDetails from "@/views/organisme/EtablissementChefDetails.vue"
import TitleView from "@/views/commons/TitleView.vue"
import { EtablissementDTO } from "@/model/bean/GeneratedDTOs"
import TabHeaderWithHint from "@/views/commons/TabHeaderWithHint.vue"
import AbstractFormStepWithTabs from "@/views/commons/AbstractFormStepWithTabs.vue"

@Component({
  components: {
    EtablissementContactDetails,
    EtablissementSiteWebDetails,
    EtablissementChefDetails,
    EtablissementIdentiteDetails,
    TitleView,
    ValidationObserver,
    TabHeaderWithHint,
  },
})
export default class EtablissementView extends AbstractFormStepWithTabs {
  @Prop() etablissement: EtablissementDTO
  @Prop({ default: false }) userCanChange: boolean
  @Prop({ default: false }) readonly: boolean
  @Prop() validationGroup: string
  @Prop({ default: false }) lowerTab: boolean

  created(): void {
    this.numberOfTabs = 4
    this.currentTabIndex = 0
  }

  changeEtablissement(): void {
    this.$emit("change-etablissement")
  }

  updateEtablissement(etablissement: EtablissementDTO): void {
    this.setWarnings()
    this.$emit("update-etablissement", etablissement)
  }
}
