























































































import Constants from "@/services/Constants"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { ValidationObserver } from "vee-validate"
import ValidationResult, { ValidationIssue } from "@/model/ValidationResult"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import { ParametersService } from "@/services/rest/parameters/ParametersService"
import PageChangeForbidder from "@/router/PageChangeForbidder"
import { ProjetAnnuelDTO } from "@/model/bean/GeneratedDTOs"
import { DocumentsService } from "@/services/rest/documents/DocumentsService"
import RouterUtils from "@/router/RouterUtils"
import { ProjectService } from "@/services/rest/project/ProjectService"

export class ProjetAnnualValidation {
  invalidFields = new Map<string, ValidationIssue[]>()
  emptyFields = new Map<string, ValidationIssue[]>()
}

@Component({
  components: { ValidationObserver },
})
export default class ProjectValidationAndSaveHandler extends Vue {
  private infoReportingService = InfoReportingService.INSTANCE
  private parametersService = ParametersService.INSTANCE
  private documentService = DocumentsService.INSTANCE
  private projetService = ProjectService.INSTANCE
  @Prop() project: ProjetAnnuelDTO
  @Prop({ default: true }) validationActivated: boolean
  @Prop({ default: true }) showValidationErrors: boolean
  @Prop() validationRequestDate: Date
  @Prop() dirty: boolean
  @Prop() readonly: boolean
  @Prop() isSaving: boolean
  @Prop() isPorteurProjet: boolean
  @Prop() pxFromBottom: number
  @Prop() hidden: boolean

  hasErrors = true
  hasDocument = false

  //cache pour éviter un appel à chaque test de validité
  outsideLabellisationPeriod = true

  async mounted(): Promise<void> {
    const parameter = await this.parametersService.getParametrage(this.project.grae.id)
    const now = new Date()

    if (now < parameter.debutLabellisation) {
      this.outsideLabellisationPeriod = true
    } else if (now > parameter.finLabellisation) {
      this.outsideLabellisationPeriod = true
    } else {
      this.outsideLabellisationPeriod = false
    }
    this.documentExist()
  }

  /**
   * Indicates if given validation message descriptes a warning (will be considered as error otherwise)
   */
  isWarning(errorMessage: string): boolean {
    return (
      errorMessage == this.$t("validation-input-invalid-required") ||
      errorMessage == this.$t("projet.inscription.finalisation-inscription.certified-error")
    )
  }

  async documentExist() {
    this.hasDocument = await this.documentService.getDocumentByTypeAndAnneScolaire(
      this.project.natureAireEducative,
      this.project.anneeScolaire
    )
  }
  shouldIgnore(groupId: string, _errorMessage: string): boolean {
    // Ignore "new financement" errors
    return groupId == "financement" || groupId == "validation-to-ignore"
  }

  @Watch("validationRequestDate")
  async newValidationRequest(): Promise<void> {
    this.hasErrors = false
    if (this.validationActivated) {
      //@ts-ignore
      const validationResult: ValidationResult = await this.$refs.observer.validateWithInfo()
      const projetAnnuelValidation = this.convertValidationResultToProjectAnnuelValidation(
        validationResult
      )
      this.hasErrors = this.hasErrorsIgnoringParticipants(projetAnnuelValidation)

      this.$emit("validation-changed", projetAnnuelValidation)
    }
  }

  async downloadDoc(): Promise<void> {
    let base =
      window.location.origin +
      "/api/v2/document/" +
      this.project.anneeScolaire +
      "/" +
      this.project.natureAireEducative
    if (this.project.lastEvaluationMention == "LABELLISATION_EN_DEVENIR") {
      base = base + " en devenir"
    }
    window.location.href = base
  }

  hasErrorsIgnoringParticipants(projetAnnuelValidation: ProjetAnnualValidation) {
    let result =
      projetAnnuelValidation.invalidFields.size > 0 || projetAnnuelValidation.emptyFields.size > 0

    // If the only warning is that a referent is missing
    // We allow dépot without referent (a warning will be displayed in ProjetInscriptionFinalisation)
    const allParticipantsErrors = projetAnnuelValidation.emptyFields.get("participants")

    if (allParticipantsErrors && allParticipantsErrors.length == 1) {
      const missingReferentsError = Array.from(allParticipantsErrors).filter((issue) => {
        return issue.field == "participants_referents"
      })
      if (missingReferentsError && missingReferentsError.length > 0) {
        result =
          projetAnnuelValidation.invalidFields.size > 0 ||
          projetAnnuelValidation.emptyFields.size > 1
      }
    }
    return result
  }

  convertValidationResultToProjectAnnuelValidation(
    validationResult: ValidationResult
  ): ProjetAnnualValidation {
    // Step 1: create empty ProjetAnnualValidation
    const projetAnnuelValidation = new ProjetAnnualValidation()
    projetAnnuelValidation.invalidFields = new Map<string, ValidationIssue[]>()
    projetAnnuelValidation.emptyFields = new Map<string, ValidationIssue[]>()

    // Step2: Browse through validation errors and sort them by group and severity
    Object.keys(validationResult.errors).forEach((fieldInError) => {
      const validationErrorsForField: Array<string> =
        validationResult.errors[fieldInError.toString()]
      if (validationErrorsForField && validationErrorsForField.length > 0) {
        let groupId = ""
        if (fieldInError.split("_").length > 1) {
          groupId = fieldInError.split("_")[0]
        }
        if (groupId) {
          // Errors could be warning (empty fields)
          if (this.shouldIgnore(groupId, validationErrorsForField[0])) {
            // Ignore error
          } else if (this.isWarning(validationErrorsForField[0])) {
            let existingErrors = projetAnnuelValidation.emptyFields.get(groupId)
            if (existingErrors == undefined) {
              existingErrors = []
            }
            existingErrors.push(new ValidationIssue(fieldInError, validationErrorsForField[0]))
            projetAnnuelValidation.emptyFields.set(groupId, existingErrors)
          } else {
            // Or severe errors
            let existingErrors = projetAnnuelValidation.invalidFields.get(groupId)
            if (existingErrors == undefined) {
              existingErrors = []
            }
            existingErrors.push(new ValidationIssue(fieldInError, validationErrorsForField[0]))
            projetAnnuelValidation.invalidFields.set(groupId, existingErrors)
          }
        }
      }
    })
    return projetAnnuelValidation
  }

  async saveProject(): Promise<void> {
    // Step 1: validation before save
    //@ts-ignore
    const validationResult: ValidationResult = await this.$refs.observer.validateWithInfo()
    const projetAnnuelValidation = this.convertValidationResultToProjectAnnuelValidation(
      validationResult
    )
    this.hasErrors = this.hasErrorsIgnoringParticipants(projetAnnuelValidation)

    // If there are big errors, prevent from saving
    if (projetAnnuelValidation.invalidFields.size > 0) {
      let firstFieldInError = projetAnnuelValidation.invalidFields.keys().next().value.toString()
      this.infoReportingService.error(
        this.$t("projet.cannot-save", [this.$t(firstFieldInError)]).toString(),
        new Error()
      )
    } else {
      // Otherwise save project
      this.$emit("save-project")
      PageChangeForbidder.INSTANCE.allowPageChange()
    }

    if (this.validationActivated) {
      // And if necessary, refresh menu with validation result
      this.$emit("validation-changed", projetAnnuelValidation)
    }
  }

  @Watch("dirty")
  dirtyChanged(): void {
    if (this.dirty) {
      // If dirty, page change is forbidden
      PageChangeForbidder.INSTANCE.forbidPageChange(
        this.showDirtyPopup,
        this.$t("projet.dirtyPopup.message").toString(),
        true
      )
    } else {
      // If not, page change is allowed again
      PageChangeForbidder.INSTANCE.allowPageChange()
    }
  }

  showDirtyPopup(next: () => void): void {
    this.infoReportingService.dialogWithCancel(
      this.$t("projet.dirtyPopup.title").toString(),
      this.$t("projet.dirtyPopup.message").toString(),
      this.$t("projet.dirtyPopup.discard-and-continue").toString(),
      async () => {
        next()
      },
      () => {
        // Nothing to do
      },
      "is-danger"
    )
  }

  async saveBeforeLabellisation(): Promise<boolean> {
    // Step 1: validation before save
    //@ts-ignore
    const validationResult: ValidationResult = await this.$refs.observer.validateWithInfo()
    const projetAnnuelValidation = this.convertValidationResultToProjectAnnuelValidation(
      validationResult
    )
    this.hasErrors = this.hasErrorsIgnoringParticipants(projetAnnuelValidation)

    // If there are big errors, prevent from saving
    if (projetAnnuelValidation.invalidFields.size > 0) {
      return false
    } else {
      await this.projetService.saveProject(this.project)
      PageChangeForbidder.INSTANCE.allowPageChange()
      return true
    }
  }

  async sendLabellisation(): Promise<void> {
    if (await this.saveBeforeLabellisation()) {
      if (!this.validationActivated) {
        this.$emit("toggle-validation")

        // Step 1: validation before save
        //@ts-ignore
        const validationResult: ValidationResult = await this.$refs.observer.validateWithInfo()
        const projetAnnuelValidation = this.convertValidationResultToProjectAnnuelValidation(
          validationResult
        )
        this.hasErrors =
          projetAnnuelValidation.invalidFields.size > 0 ||
          projetAnnuelValidation.emptyFields.size > 0
      }
      if (this.hasErrors) {
        this.infoReportingService.error(
          this.$t("projet.cannot-submit-labellisation").toString(),
          new Error()
        )
        // this.$router.push({
        //   path:"/project/" + this.project.projetId + "/" + this.project.id + "/historique/0",
        //   hash: "#aide-labellissation"
        // })
        this.$router.push(
          RouterUtils.getRouteForProjectDetailsAideLabOn(this.project.projetId, this.project.id)
        )
      } else {
        this.$emit("send-labellisation")
      }
    } else {
      this.infoReportingService.error(
        this.$t("projet.cannot-submit-labellisation").toString(),
        new Error()
      )
      this.$router.push(
        RouterUtils.getRouteForProjectDetailsAideLabOn(this.project.projetId, this.project.id)
      )
    }
  }

  async exportCarnetDeBord(): Promise<void> {
    console.log(this.project)
    const fileURL = Constants.apiUrlv2("projets/" + this.project.id + "/export/odt")
    window.open(fileURL, "_blank")
  }

  async exportPiecesJointes(): Promise<void> {
    const fileURL = Constants.apiUrlv2("projets/" + this.project.id + "/fichiers-zip")
    window.open(fileURL, "_blank")
  }

  destroyed(): void {
    PageChangeForbidder.INSTANCE.allowPageChange()
  }
}
