var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tag-instructeur" }, [
    _vm.currentInstructeurs.length !== 0
      ? _c(
          "div",
          { staticClass: "InstructionPage-instructeur" },
          _vm._l(_vm.currentInstructeurs, function(instructeur, i) {
            return _c(
              "div",
              { key: instructeur.id },
              [
                _c("b", [_vm._v(_vm._s(i + 1) + " - ")]),
                _vm._v(_vm._s(_vm.getInstructeurNameLabel(instructeur)) + " "),
                _vm.canRemoveInstructeur(instructeur)
                  ? _c("b-button", {
                      staticClass: "remove-button-inscription",
                      attrs: {
                        "icon-left": "times",
                        title: _vm.$t("instructionPage.remove-instructeur"),
                        size: "is-small"
                      },
                      on: {
                        click: function($event) {
                          return _vm.removeInstructeur(instructeur)
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          }),
          0
        )
      : _vm._e(),
    _vm.currentInstructeurs.length < 2
      ? _c(
          "div",
          [
            _vm.canDisplayAddInstructeurButton()
              ? _c(
                  "b-dropdown",
                  {
                    attrs: { "aria-role": "list" },
                    on: {
                      change: function(instructeur) {
                        return _vm.selectInstructeur(instructeur)
                      }
                    }
                  },
                  [
                    _c("b-button", {
                      attrs: {
                        slot: "trigger",
                        "icon-right": "plus",
                        size: "is-small",
                        type: "is-primary",
                        label: _vm.$t("instructionPage.assigner")
                      },
                      slot: "trigger"
                    }),
                    _vm._l(_vm.instructeurs, function(instructeur) {
                      return _c(
                        "div",
                        { key: instructeur.id },
                        [
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                "aria-role": "listitem",
                                value: instructeur
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getInstructeurNameLabel(instructeur)
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }