var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PageView",
    { attrs: { isOnBigHeaderPage: true, noSubTitleExpected: "true" } },
    [
      _c("TitleView", [_vm._v(_vm._s(_vm.$t("login")))]),
      _c("ContentView", [_c("LoginView")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }