
























































































































































































































































import RouterUtils from "@/router/RouterUtils"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import {
  EvaluationDTO,
  ProjetAnnuelForInstructionDTO,
  UtilisateurDTO,
  StatutDossier,
} from "@/model/bean/GeneratedDTOs"
import { InstructionService } from "@/services/rest/instruction/InstructionService"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import LabellisationEvaluationCommentsPopup from "./LabellisationEvaluationCommentsPopup.vue"

@Component({
  components: { LabellisationEvaluationCommentsPopup },
})
export default class LabellisationHarmonisationForProjectView extends Vue {
  instructionService = InstructionService.INSTANCE
  protected infoReportingService = InfoReportingService.INSTANCE
  @Prop() projectAnnuel: ProjetAnnuelForInstructionDTO
  @Prop() loggedUser: UtilisateurDTO
  @Prop() allGraeInstructeurs: UtilisateurDTO[]
  @Prop() evaluations: EvaluationDTO[]

  allGraeInstructeursExceptAlreadyAssigned: UtilisateurDTO[] = []
  evaluationFinale: EvaluationDTO = new EvaluationDTO()
  maxInstructeursNumber = 3
  numberOfLines = 0
  isAddingInstructeur = false
  instructeurBeingDeletedId = ""
  canApproveEvaluation = false
  isApproving = false
  notes = [""]
  mentions: StatutDossier[] = []
  hasRightToModify = true

  mounted(): void {
    this.recomputedFinalEval()
    this.recomputeAvailableGraeInstructeurs()
    if (this.projectAnnuel.typeDossier == "DOSSIER_RENOUVELLEMENT_LIGHT") {
      this.mentions = ["ACCEPTE", "AVIS_PASSABLE", "REFUSE", "AUTOMATISE"]
      this.notes = [" ", "A", "B", "C", "D"]
    } else if (this.projectAnnuel.typeDossier == "DOSSIER_RENOUVELLEMENT") {
      this.mentions = ["ACCEPTE", "AVIS_PASSABLE", "REFUSE"]
      this.notes = ["A", "B", "C", "D"]
    } else {
      this.mentions = ["ACCEPTE", "AVIS_PASSABLE", "LABELLISATION_EN_DEVENIR", "REFUSE"]
      this.notes = ["A", "B", "C", "D"]
    }

    this.evaluations = this.evaluations.sort(function (a, b) {
      var keyA = new Date(a.dateCreation),
        keyB = new Date(b.dateCreation)

      if (keyA < keyB) return -1
      if (keyA > keyB) return 1
      return 0
    })

    const alreadyInstructingIds = this.evaluations.map((e) => e.instructeur.id)
    this.hasRightToModify =
      this.loggedUser.profil !== "OBSERVATEUR_NATIONAL" &&
      (this.loggedUser.profil !== "ACCOMPAGNATEUR_REGIONAL" ||
        (this.loggedUser.profil === "ACCOMPAGNATEUR_REGIONAL" &&
          alreadyInstructingIds.includes(this.loggedUser.id)))
    this.recomputeProjectCanApprove()
  }

  @Watch("evaluations")
  recomputedFinalEval(): void {
    if (this.evaluations.length > 0) {
      this.evaluationFinale = this.projectAnnuel.evaluationFinale
      // Compute final evaluation note & comment
      this.numberOfLines =
        this.evaluations.length + 1 + (this.evaluations.length < this.maxInstructeursNumber ? 1 : 0)
    } else {
      this.evaluationFinale = new EvaluationDTO()
      // 1 line : that assign instructeur button
      this.numberOfLines = 1
    }
    if (!this.evaluationFinale.mention) {
      this.evaluationFinale.mention = this.evaluationFinale.mentionAutomatique
    }
    this.recomputeProjectCanApprove()
  }

  async selectInstructeur(instructeur: UtilisateurDTO): Promise<void> {
    this.isAddingInstructeur = true
    const projectWithUpdatedEvaluations = await this.instructionService.assignInstructeur(
      this.projectAnnuel.id,
      instructeur
    )
    this.$emit("evaluations-changed", projectWithUpdatedEvaluations.evaluations)
    this.isAddingInstructeur = false
  }

  async removeInstructeur(instructeur: UtilisateurDTO): Promise<void> {
    this.instructeurBeingDeletedId = instructeur.id ? instructeur.id : ""
    const projectWithUpdatedEvaluations = await this.instructionService.removeInstructeur(
      this.projectAnnuel.id,
      instructeur.id
    )
    this.$emit("evaluations-changed", projectWithUpdatedEvaluations.evaluations)
    this.instructeurBeingDeletedId = ""
  }

  @Watch("allGraeInstructeurs")
  recomputeAvailableGraeInstructeursByAllGraeInstructeurs(): void {
    console.log("WATCH allGraeInstructeurs = ", this.allGraeInstructeurs)
    this.recomputeAvailableGraeInstructeurs()
  }

  @Watch("evaluations")
  recomputeAvailableGraeInstructeursByEvaluations(): void {
    console.log("WATCH evaluations = ", this.evaluations)
    this.recomputeAvailableGraeInstructeurs()
  }

  recomputeAvailableGraeInstructeurs(): void {
    const alreadyInstructingIds = this.evaluations.map((e) => e.instructeur.id)
    this.allGraeInstructeursExceptAlreadyAssigned = this.allGraeInstructeurs.filter(
      (instructeur) => alreadyInstructingIds.indexOf(instructeur.id) == -1
    )
  }

  getInstructeurNameLabel(instructeur: UtilisateurDTO): string {
    if (!instructeur || !instructeur.id) {
      return "as"
    }
    return this.loggedUser && instructeur.id === this.loggedUser.id
      ? this.$t("instructionPage.me").toString()
      : `${instructeur.nom} ${instructeur.prenom}`
  }

  consult(event: Event): void {
    // Do not foward click event to row (would trigger modal)
    event.stopPropagation()
    let route = this.$router.resolve(
      RouterUtils.getRouteForProjectDetails(this.projectAnnuel.projetId, this.projectAnnuel.id)
    )
    window.open(route.href)
  }

  async approveEvaluation(event: Event): Promise<void> {
    this.isApproving = true
    event.stopPropagation()
    try {
      await this.instructionService.saveEvaluationFinale(
        this.projectAnnuel.id!,
        this.evaluationFinale
      )
      await this.instructionService.approuveEvaluationFinale(this.projectAnnuel.id!)

      this.infoReportingService.success(
        this.$t("instructionPage.labellisations.harmonisation.envoyer-en-cloture").toString()
      )
      this.isApproving = false
      this.$emit("reload-data")
    } catch (e) {
      this.infoReportingService.error(
        this.$t("instructionPage.labellisations.harmonisation.envoyer-en-cloture-error").toString(),
        e as Error
      )
      this.isApproving = false
    }
  }

  async finalEvalChanged(evaluation: EvaluationDTO) {
    try {
      await this.instructionService.saveEvaluationFinale(this.projectAnnuel.id!, evaluation)
      this.infoReportingService.success(
        this.$t("instructionPage.labellisations.harmonisation.evaluation-finale-saved").toString()
      )
      this.recomputeProjectCanApprove()
    } catch (_e) {
      this.infoReportingService.error(
        this.$t(
          "instructionPage.labellisations.harmonisation.evaluation-finale-save-ko"
        ).toString(),
        new Error()
      )
    }
  }

  recomputeProjectCanApprove(): void {
    if (this.projectAnnuel.typeDossier === "DOSSIER_RENOUVELLEMENT_LIGHT") {
      this.canApproveEvaluation =
        this.evaluationFinale.mention?.length > 0 &&
        this.evaluationFinale.commentaireInstructeur?.length > 0 &&
        this.hasRightToModify
    } else {
      this.canApproveEvaluation =
        this.evaluationFinale.adhesionNote?.length > 0 &&
        this.evaluationFinale.derouleNote?.length > 0 &&
        this.evaluationFinale.implicationNote?.length > 0 &&
        this.evaluationFinale.integrationNote?.length > 0 &&
        this.evaluationFinale.mention?.length > 0 &&
        this.evaluationFinale.adhesionCommentaire?.length > 0 &&
        this.evaluationFinale.derouleCommentaire?.length > 0 &&
        this.evaluationFinale.implicationCommentaire?.length > 0 &&
        this.evaluationFinale.integrationCommentaire?.length > 0 &&
        this.evaluationFinale.commentaireInstructeur?.length > 0 &&
        this.hasRightToModify
    }
    
  }

  get disabledAssignedButton(): boolean {
    return (
      this.loggedUser.profil === "OBSERVATEUR_NATIONAL" ||
      this.allGraeInstructeursExceptAlreadyAssigned.length === 0
    )
  }
}
