













































































































































































































import { EnumHelper } from "@/model/bean/EnumHelper"
import {
  PaginationOrder,
  ProjetInscriptionDTO,
  QuickFilter,
  UtilisateurDTO,
} from "@/model/bean/GeneratedDTOs"
import { Filters } from "@/model/bean/Filters"
import RouterUtils from "@/router/RouterUtils"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import StatutDossierTag from "@/views/dossier/StatutDossierTag.vue"
import AbstractTableView from "@/views/tables/AbstractTableView.vue"
import Column from "@/views/tables/Column"
import TableFilterView from "@/views/tables/TableFilterView.vue"
import { Component, Prop, Watch } from "vue-property-decorator"
import { format } from "date-fns"
import QuickFilterView from "./QuickFilterView.vue"
import Instructeur from "@/views/dossier/Instructeur.vue"
import { ProjectService } from "@/services/rest/project/ProjectService"
import { LoginService } from "@/services/rest/login/LoginService"
import { InstructionService } from "@/services/rest/instruction/InstructionService"

const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm"

@Component({
  components: {
    TableFilterView,
    StatutDossierTag,
    QuickFilterView,
    Instructeur,
  },
})
export default class InscriptionsView extends AbstractTableView {
  @Prop() graeId: string
  @Prop({ default: 0 }) activeQuickFilter: number

  private loginService = LoginService.INSTANCE
  private instructionService = InstructionService.INSTANCE
  private projectService = ProjectService.INSTANCE
  protected infoReportingService = InfoReportingService.INSTANCE

  loggedUser: UtilisateurDTO | undefined = new UtilisateurDTO()
  projects: Array<ProjetInscriptionDTO> = []
  quickFilter: QuickFilter | undefined = "MY_ASSIGNMENTS"

  created(): void {
    this.loggedUser = this.loginService.getLoggedUser()
    if (!this.loggedUser || !this.loggedUser.id) {
      return
    }
    this.activeQuickFilterChanged()
  }

  @Watch("activeQuickFilter")
  private activeQuickFilterChanged(): void {
    if (this.activeQuickFilter == 0) {
      this.quickFilter = "MY_ASSIGNMENTS"
    } else if (this.activeQuickFilter == 1) {
      this.quickFilter = "NOT_ASSIGNED"
    } else {
      this.quickFilter = undefined
    }
  }

  async reloadData(): Promise<void> {
    this.isLoading = true
    const statutFilter = Filters.asFilter({
      property: "statut",
      values: ["EN_ATTENTE", "INSTRUCTION_EN_COURS"],
    })

    try {
      const paginatedProjectsList = await this.instructionService.getInscriptions(
        this.graeId,
        this.currentPagedSortedParameter,
        [...this.activeFilters, statutFilter],
        this.quickFilter
      )
      this.projects = paginatedProjectsList.elements
      this.total = paginatedProjectsList.count
    } catch (e) {
      // Silent catch
    }
    this.isLoading = false
  }

  getInitialColumns(): Array<Column> {
    return [
      // default fields
      {
        field: "etablissement.name",
        label: this.$t("graePage.details.projets.etablissements").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "etablissement.codeEtablissement",
        label: this.$t("graePage.details.projets.uais").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "projet.nom",
        label: this.$t("graePage.details.projets.nom").toString(),
        sortable: true,
        backEndSearchable: true,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "statut",
        label: this.$t("graePage.details.projets.statut").toString(),
        sortable: true,
        backEndSearchable: true,
        possibleSearchValues: EnumHelper.allStatusWithoutArchivedAndPassable,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "etat",
        label: this.$t("graePage.details.projets.etat").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "natureAireEducative",
        label: this.$t("graePage.details.projets.nature-ae").toString(),
        sortable: true,
        backEndSearchable: true,
        possibleSearchValues: EnumHelper.allNatures,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "dateModification",
        label: this.$t("graePage.details.projets.dateModification").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "instructeur",
        label: this.$t("graePage.details.projets.instructeur").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
      // hidden fields
      {
        field: "action",
        label: this.$t("projetsListPage.actions").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "codeDepartement",
        label: this.$t("graePage.details.projets.departement").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "ville",
        label: this.$t("graePage.details.projets.ville").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "structure.name",
        label: this.$t("graePage.details.projets.structures").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "enseignant.name",
        label: this.$t("graePage.details.projets.enseignants").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "referent.name",
        label: this.$t("graePage.details.projets.referents").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
    ]
  }

  getDefaultSort(): Array<PaginationOrder> {
    const defaultSort = new PaginationOrder()
    defaultSort.clause = "projet.nom"
    defaultSort.desc = false
    return [defaultSort]
  }

  showProjectDetailsPage(event: Event, projetId: string, projetAnnuelId: string): void {
    // Do not foward click event to row (would trigger modal)
    event.stopPropagation()
    this.$router.push(RouterUtils.getRouteForProjectDetails(projetId, projetAnnuelId))
  }

  consult(event: Event, projetId: string, projetAnnuelId: string): void {
    // Do not foward click event to row (would trigger modal)
    event.stopPropagation()
    let route = this.$router.resolve(
      RouterUtils.getRouteForProjectDetails(projetId, projetAnnuelId)
    )
    window.open(route.href)
  }

  formatDate(date: Date): string {
    if (date) {
      return format(date, DATE_TIME_FORMAT)
    }
    return ""
  }

  quickFilterChanged(quickFilter: QuickFilter | undefined): void {
    this.quickFilter = quickFilter
    const params = this.$router.currentRoute.params
    let tabIndex
    if (this.quickFilter === "MY_ASSIGNMENTS") {
      tabIndex = 0
    } else if (this.quickFilter === "NOT_ASSIGNED") {
      tabIndex = 1
    } else {
      tabIndex = 2
    }
    if (tabIndex !== undefined && tabIndex.toString() !== params["activeQuickFilter"]) {
      params["activeQuickFilter"] = tabIndex.toString()
      this.$router.push({ params: params })
    }
    this.reloadData()
  }

  async selectInstructeur(
    instructeur: UtilisateurDTO,
    projetId: string,
    typeDossier: string
  ): Promise<void> {
    try {
      if (instructeur) {
        if (typeDossier === "DOSSIER_INSCRIPTION") {
          await this.projectService.updateInstructeur(projetId, instructeur)
        } else {
          console.error("Instruction non définie pour ce type de dossier : " + typeDossier)
        }

        this.infoReportingService.success(
          this.$t("instructionPage.inscriptions.instructeur-changed").toString()
        )
        this.projects = []
        this.reloadData()
      }
    } catch (e) {
      this.infoReportingService.error(
        this.$t("instructionPage.inscriptions.add-instructeur-error").toString(),
        e as Error
      )
    }
  }

  async removeInstructeur(
    instructeur: UtilisateurDTO,
    projetId: string,
    typeDossier: string
  ): Promise<void> {
    try {
      if (instructeur) {
        if (typeDossier === "DOSSIER_INSCRIPTION") {
          await this.projectService.removeInstructeur(projetId, instructeur)
        } else {
          console.error("Instruction non définie pour ce type de dossier : " + typeDossier)
        }

        this.infoReportingService.success(
          this.$t("instructionPage.inscriptions.instructeur-removed").toString()
        )
        this.projects = []
        this.reloadData()
      }
    } catch (e) {
      this.infoReportingService.error(
        this.$t("instructionPage.inscriptions.remove-instructeur-error").toString(),
        e as Error
      )
    }
  }
}
