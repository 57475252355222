var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-loading",
        {
          model: {
            value: _vm.exportingPDF,
            callback: function($$v) {
              _vm.exportingPDF = $$v
            },
            expression: "exportingPDF"
          }
        },
        [
          _c(
            "p",
            [
              _c("TitleView", [_vm._v(_vm._s(_vm.$t("pdf-export")))]),
              _vm.progress < 20
                ? _c("b", [_vm._v(_vm._s(_vm.$t("pdf-export-step1")))])
                : _vm.progress < 27
                ? _c("b", [_vm._v(_vm._s(_vm.$t("pdf-export-step2")))])
                : _vm.progress < 40
                ? _c("b", [_vm._v(_vm._s(_vm.$t("pdf-export-step3")))])
                : _c("b", [_vm._v(_vm._s(_vm.$t("pdf-export-step4")))]),
              _c("br"),
              _c("br"),
              _c("b-progress", {
                attrs: {
                  type: "is-info",
                  value: _vm.progress,
                  "show-value": ""
                }
              })
            ],
            1
          )
        ]
      ),
      _c("div", { staticClass: "hidden", attrs: { id: "pdf-target" } }, [
        _c("div", { staticClass: "fond-bleu" }),
        _c("div", { staticClass: "fond-vert" }),
        _c("div", { staticClass: "fullsize" }, [
          _c("div", { staticClass: "left" }, [
            _c("div", { staticClass: "top-container" }, [
              _c("img", {
                staticClass: "poisson",
                attrs: { src: "/img/poisson.svg" }
              }),
              _c(
                "div",
                { staticClass: "info" },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.project.nom))
                  ]),
                  _c("div", { staticClass: "nature" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("pdf-export-" + _vm.project.natureAireEducative)
                      )
                    )
                  ]),
                  _vm._l(_vm.project.etablissements, function(etablissement) {
                    return _c("div", { key: etablissement.id }, [
                      _c("span", { staticClass: "name" }, [
                        _vm._v(_vm._s(etablissement.nom))
                      ]),
                      _vm._v(" (" + _vm._s(etablissement.ville) + ") ")
                    ])
                  }),
                  _c("div", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("pdf-export-eleves", [
                          _vm.totalEleves,
                          _vm.niveauxLabel
                        ])
                      )
                    )
                  ]),
                  _vm.project.structures && _vm.project.structures.length !== 0
                    ? _c(
                        "div",
                        _vm._l(_vm.project.structures, function(structure) {
                          return _c("div", { key: structure.id }, [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(_vm._s(structure.nom))
                            ])
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                  _c("div", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("pdf-export-inscription", [_vm.anneeInscription])
                      )
                    )
                  ])
                ],
                2
              ),
              _c("img", {
                staticClass: "scarabee",
                attrs: { src: "/img/scarabee.svg" }
              })
            ]),
            _c("div", { staticClass: "pitch-map-container" }, [
              _c("div", [
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("pdf-export-annee", [_vm.project.anneeScolaire])
                    )
                  )
                ]),
                _c("div", { staticClass: "pitch" }, [
                  _vm._v(_vm._s(_vm.project.pitch))
                ])
              ]),
              _vm.mapDataUrl
                ? _c("img", {
                    staticClass: "map",
                    attrs: { src: _vm.mapDataUrl }
                  })
                : _vm._e()
            ])
          ]),
          _c("div", { staticClass: "ate photo" }, [
            _c("div", [
              _vm.withPhotos && _vm.imagesUrl[1]
                ? _c("img", {
                    attrs: {
                      src: _vm.imagesUrl[1],
                      id: "img-orig-1",
                      crossorigin: "use-credentials"
                    },
                    on: { load: _vm.convertToBase64 }
                  })
                : _c("img", {
                    attrs: {
                      src: require("@/assets/images/ATE.png"),
                      alt: "Logo Aire Terrestre Éducative",
                      title: "Logo Aire Terrestre Éducative"
                    }
                  })
            ]),
            _c("p", [_vm._v(_vm._s(_vm.$t("pdf-export-photo1")))])
          ]),
          _c("div", { staticClass: "ofb photo" }, [
            _c("div", { staticClass: "photo-container" }, [
              _vm.withPhotos && _vm.imagesUrl[0]
                ? _c("img", {
                    attrs: {
                      src: _vm.imagesUrl[0],
                      id: "img-orig-0",
                      crossorigin: "use-credentials"
                    },
                    on: { load: _vm.convertToBase64 }
                  })
                : _c("img", {
                    attrs: {
                      src: require("@/assets/images/ofb.png"),
                      alt: "Logo Office Français pour la Biodiversité",
                      title: "Logo Office Français pour la Biodiversité"
                    }
                  })
            ]),
            _c("p", [_vm._v(_vm._s(_vm.$t("pdf-export-photo2")))])
          ]),
          _c("div", { staticClass: "ame photo" }, [
            _c("div", [
              _vm.withPhotos && _vm.imagesUrl[2]
                ? _c("img", {
                    attrs: {
                      src: _vm.imagesUrl[2],
                      id: "img-orig-2",
                      crossorigin: "use-credentials"
                    },
                    on: { load: _vm.convertToBase64 }
                  })
                : _c("img", {
                    attrs: {
                      src: require("@/assets/images/AME.png"),
                      alt: "Logo Aire Marine Éducative",
                      title: "Logo Aire Marine Éducative"
                    }
                  })
            ]),
            _c("p", [_vm._v(_vm._s(_vm.$t("pdf-export-photo3")))])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }