






















































































































import { ProjetInscriptionDTO, StatutDossier, UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import { Component, Prop, Vue } from "vue-property-decorator"
import { ProjectInstructionServiceV2 } from "@/services/rest/project/ProjectInstructionServiceV2"
import { InfoReportingService } from "@/services/log/InfoReportingService"

@Component({ components: { InputWithValidation } })
export default class InstructionPannelInscriptionEvaluation extends Vue {
  @Prop() project: ProjetInscriptionDTO
  @Prop() loggedUser: UtilisateurDTO
  @Prop() instructionPannelWidth: number
  inscriptionProject: ProjetInscriptionDTO
  projectInstructionService = ProjectInstructionServiceV2.INSTANCE
  infoReportingService = InfoReportingService.INSTANCE

  assignedToMe = false
  projectIsInInstructionPhase = false
  dirty = false
  isLoading = false

  isOverAccept = false
  isOverRefus = false

  mention: StatutDossier | undefined = undefined

  mounted(): void {
    this.inscriptionProject = this.project as ProjetInscriptionDTO
    this.projectIsInInstructionPhase =
      this.project.statut == "EN_ATTENTE" || this.project.statut == "INSTRUCTION_EN_COURS"

    if (!this.loggedUser) {
      return
    }
    this.assignedToMe =
      (this.project.evaluationFinale &&
        this.project.evaluationFinale.instructeur &&
        this.project.evaluationFinale.instructeur.id === this.loggedUser.id) ||
      (this.project.autresInstructeursInscription &&
        this.project.autresInstructeursInscription.length !== 0 &&
        this.project.autresInstructeursInscription.some(
          (instructeur) => instructeur.id === this.loggedUser.id
        ))

    if (this.project.evaluationFinale) {
      this.mention = this.project.evaluationFinale.mention
    }
  }

  changeMention(newStatut: StatutDossier): void {
    if (this.assignedToMe && this.projectIsInInstructionPhase) {
      this.mention = newStatut
      this.dirty = true
    }
  }

  async sendResults(): Promise<void> {
    if (!this.mention) {
      this.infoReportingService.error(
        this.$t("instruction-pannel.inscription-evaluation.mention-error").toString(),
        new Error()
      )
      return
    }
    this.project.statut = this.mention as StatutDossier
    this.infoReportingService.dialogWithCancel(
      this.$t("instruction-pannel.inscription-evaluation.confirm-dialog.title").toString(),
      this.$t("instruction-pannel.inscription-evaluation.confirm-dialog.message", [
        this.mention == "ACCEPTE" ? "accepter" : "refuser",
      ]).toString(),
      this.$t("instruction-pannel.inscription-evaluation.confirm-dialog.confirm").toString(),
      () => this.doSave(),
      () => {
        this.project.statut = "EN_ATTENTE"
      },
      "is-info"
    )
  }

  async doSave(): Promise<void> {
    this.isLoading = true
    await this.projectInstructionService.acceptOrDeclineProjectInscription(
      this.project as ProjetInscriptionDTO
    )
    this.infoReportingService.success(
      this.project.statut == "ACCEPTE"
        ? this.$t("instruction-pannel.inscription-evaluation.result-ok").toString()
        : this.$t("instruction-pannel.inscription-evaluation.result-ko").toString()
    )
    this.isLoading = false
    this.projectIsInInstructionPhase = this.project.statut == "EN_ATTENTE"
  }

  async save(): Promise<void> {
    if (this.mention) {
      this.project.evaluationFinale.mention = this.mention as StatutDossier
    }
    await this.projectInstructionService.updateInstructionInternalComment(
      this.project as ProjetInscriptionDTO
    )
    this.infoReportingService.success(
      this.$t("instruction-pannel.inscription-evaluation.comment-sent").toString()
    )
    this.isLoading = false
    this.projectIsInInstructionPhase =
      this.project.statut == "EN_ATTENTE" || this.project.statut == "INSTRUCTION_EN_COURS"
    this.dirty = false
    this.dirty = false
  }

  getInstructeurs(): string[] {
    let instructeurs: UtilisateurDTO[] = []
    if (
      this.project.autresInstructeursInscription &&
      this.project.autresInstructeursInscription.length !== 0
    ) {
      instructeurs = [...this.project.autresInstructeursInscription]
    }
    if (this.project.evaluationFinale && this.project.evaluationFinale.instructeur) {
      instructeurs.push(this.project.evaluationFinale.instructeur)
    }

    if (instructeurs) {
      if (instructeurs.length !== 0) {
        return instructeurs.map(
          (instructeur) => instructeur.prenom + " " + instructeur.nom.toUpperCase()
        )
      }
    }
    return [""]
  }
}
