/*-
 * #%L
 * Aires Éducatives
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
import App from "./App.vue"
import router from "./router"
import Vue from "vue"
import "reflect-metadata"

// Buefy & custom icons
import Buefy from "buefy"

import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowCircleUp,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faCalendarAlt,
  faCaretDown,
  faCaretLeft,
  faCaretUp,
  faCommentAlt,
  faChalkboardTeacher,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChild,
  faCircle,
  faDesktop,
  faEdit,
  faEllipsisH,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFolderOpen,
  faGraduationCap,
  faInfoCircle,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMinus,
  faPeopleArrows,
  faPlus,
  faQuestion,
  faQuestionCircle,
  faRedo,
  faSave,
  faSchool,
  faSearch,
  faSmile,
  faSpinner,
  faTimes,
  faTimesCircle,
  faTools,
  faTrash,
  faTrashAlt,
  faUpload,
  faWifi,
  faAward,
  faStamp,
  faEnvelopeOpenText,
  faPaperPlane,
  faPencilRuler,
  faPlayCircle,
  faPauseCircle,
  faStopCircle,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faClipboardList,
  faBuilding,
  faChartPie,
  faUniversity,
  faGavel,
  faUsers,
  faUser,
  faCalendarWeek,
  faClipboard,
  faCog,
  faCalendar,
  faSignature,
  faMailBulk,
  faCalendarDay,
  faHourglassHalf,
  faFile,
  faFileSignature,
  faEuroSign,
  faPowerOff,
  faExclamationCircle,
  faPlusCircle,
  faAddressCard,
  faAt,
  faPhone,
  faNewspaper,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
library.add(
  faAddressCard,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowCircleUp,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faAt,
  faCalendarAlt,
  faCaretDown,
  faCaretLeft,
  faCaretUp,
  faExclamationCircle,
  faCommentAlt,
  faChalkboardTeacher,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChild,
  faCircle,
  faDesktop,
  faEdit,
  faEllipsisH,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFolderOpen,
  faFile,
  faFileSignature,
  faGraduationCap,
  faInfoCircle,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMinus,
  faPeopleArrows,
  faPhone,
  faPlus,
  faQuestion,
  faQuestionCircle,
  faRedo,
  faSave,
  faSchool,
  faSmile,
  faSearch,
  faSpinner,
  faTimes,
  faTimesCircle,
  faTools,
  faTrash,
  faTrashAlt,
  faUpload,
  faWifi,
  faAward,
  faStamp,
  faEnvelopeOpenText,
  faPaperPlane,
  faPencilRuler,
  faPlayCircle,
  faPauseCircle,
  faStopCircle,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faClipboardList,
  faClipboard,
  faBuilding,
  faChartPie,
  faUniversity,
  faGavel,
  faUsers,
  faUser,
  faCalendarWeek,
  faCog,
  faCalendar,
  faCog,
  faSignature,
  faMailBulk,
  faCalendarDay,
  faHourglassHalf,
  faEuroSign,
  faPowerOff,
  faPlusCircle,
  faNewspaper,
  faCommentAlt,
  faEnvelope
)
Vue.component("vue-fontawesome", FontAwesomeIcon)
import "@/styles/global.scss"
import "quill/dist/quill.core.css" // import styles
import "quill/dist/quill.snow.css" // for snow theme
// Validation
import "vee-validate"
import "@/services/validation/vee-validation-rules"

// Translation
import i18n from "@/i18n"

// Mount vue
Vue.use(Buefy, {
  defaultIconComponent: "vue-fontawesome",
  defaultIconPack: "fas",
})

Vue.config.productionTip = false

const vueApp = new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app")

export default vueApp
