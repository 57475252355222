var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "page-container",
          class: { "is-on-big-header-page": _vm.isOnBigHeaderPage }
        },
        [_vm._t("default")],
        2
      ),
      _c("Footer", { staticClass: "App-footer" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }