var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      staticClass: "CommentairesModale",
      attrs: {
        active: _vm.open,
        "has-modal-card": "",
        "trap-focus": "",
        "aria-role": "dialog",
        "aria-label": "CommentairesModale",
        "aria-modal": "",
        width: "100vw"
      },
      on: { cancel: _vm.emitCloseModale }
    },
    [
      [
        _c("div", { staticClass: "modal-card" }, [
          _c(
            "header",
            { staticClass: "modal-card-head modal-card-head-notes" },
            [_vm._v(" " + _vm._s(_vm.$t("commentairesModale.title")) + " ")]
          ),
          _c(
            "section",
            { staticClass: "modal-card-body" },
            _vm._l(_vm.commentaires, function(commentaire) {
              return _c(
                "div",
                { key: commentaire.id, staticClass: "columns mt-1 mb-0" },
                [
                  _c("div", { staticClass: "column is-one-third" }, [
                    _c("div", { staticClass: "columns" }, [
                      _c("div", { staticClass: "column pb-0" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              commentaire.auteur.prenom +
                                " " +
                                commentaire.auteur.nom
                            ) +
                            " "
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "columns" }, [
                      _c(
                        "div",
                        { staticClass: "column pb-0 today date-style" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.formatDate(commentaire.dateCreation)) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "column pt-4 pb-0" },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: "comment-alt",
                              size: "is-small",
                              type: "is-info"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "column" }, [
                    _c("div", { staticClass: "columns" }, [
                      _c("div", { staticClass: "column pb-0" }, [
                        _c("div", { staticClass: "textarea-container" }, [
                          _c("div", { staticClass: "box message" }, [
                            _vm._v(" " + _vm._s(commentaire.contenu) + " ")
                          ])
                        ])
                      ])
                    ])
                  ])
                ]
              )
            }),
            0
          ),
          _c("footer", { staticClass: "modal-card-foot" }, [
            _c("div", { staticClass: "buttons" }, [
              _c(
                "div",
                {
                  staticClass: "hoverable-discrete-link mr-3",
                  on: { click: _vm.emitCloseModale }
                },
                [_vm._v(" " + _vm._s(_vm.$t("annuler")) + " ")]
              )
            ])
          ])
        ])
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }