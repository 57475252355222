var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PageView",
    {
      attrs: {
        mainTitle: _vm.title,
        subTitle: _vm.subTitle,
        subTitleIcon: _vm.subTitleIcon,
        displaySubTitleInGreen: true
      }
    },
    [
      _vm.grae
        ? _c("ContentView", { staticClass: "pr-2" }, [
            _vm.section === "indicateurs"
              ? _c(
                  "div",
                  [
                    _c("h1", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.$t("IndicateursPage.title")))
                    ]),
                    _vm.grae
                      ? _c("IndicateursView", { attrs: { grae: _vm.grae } })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm.section === "details"
              ? _c(
                  "div",
                  [
                    _vm.grae.id
                      ? _c("GraeDetailsView", {
                          attrs: { grae: _vm.grae, activeTab: _vm.activeSubTab }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm.section === "ModifProjet"
              ? _c(
                  "div",
                  [
                    _vm.grae.id
                      ? _c("ModifProjetView", {
                          attrs: { grae: _vm.grae, activeTab: _vm.activeSubTab }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm.section === "membres"
              ? _c(
                  "div",
                  [
                    _vm.grae.id
                      ? _c("GraeMembers", {
                          attrs: { graeId: _vm.grae.id, loggedUser: _vm.user }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm.section === "actualites"
              ? _c(
                  "div",
                  [_c("ActualitesView", { attrs: { graeId: _vm.graeId } })],
                  1
                )
              : _vm.section === "dates"
              ? _c(
                  "div",
                  [
                    _vm.grae.id
                      ? _c("GraeDatesView", { attrs: { grae: _vm.grae } })
                      : _vm._e()
                  ],
                  1
                )
              : _vm.section === "contacts"
              ? _c(
                  "div",
                  [
                    _c("GraeContactView", {
                      attrs: { loggedUser: _vm.user, grae: _vm.grae }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }