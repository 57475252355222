








































import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import FeedbackModal from "@/views/feedback/FeedbackModal.vue"
import { NavbarItem } from "@/views/menus/MenuItems"
import i18n from "@/i18n"

@Component({
  components: { FeedbackModal },
})
export default class MainVerticalMenu extends Vue {
  @Prop() items: NavbarItem[]
  @Prop() hasContextMenu: boolean
  @Prop() menuExpanded: boolean
  @Prop() pendingRequest!: any
  @Prop() role!: string

  toggleOpen = true
  labelPorteur = i18n.t("menu.ModifProjet").toString()

  @Watch("menuExpanded")
  menuExpansionChanged(): void {
    this.toggleOpen = this.menuExpanded
  }

  toggleOpenMenu(): void {
    this.toggleOpen = !this.toggleOpen
    this.$emit("main-menu-expand", this.toggleOpen)
  }

  clickOnItem(item: NavbarItem): void {
    this.$emit("click-on-item", item)

    if (this.labelPorteur == item.label) {
      this.$emit("reset-notif-porteur")
    }
  }

  shouldDisplayBadge(label: string): boolean {
    if (this.role === "ANIMATEUR_REGIONAL" && label === "Mon GRAE") {
      return this.pendingRequest.users > 0
    }
    if (this.role === "ACCOMPAGNATEUR_NATIONAL" && label === "Utilisateurs") {
      return this.pendingRequest.users > 0
    }
    return false
  }

  isDisplayPorteurProjet(label: string): boolean {
    let display = false
    if (this.labelPorteur == label) {
      display = this.pendingRequest.modif_porteur > 0
    }
    return display
  }
}
