






















































import { ValidationProvider } from "vee-validate"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import FormInputLabel from "./FormInputLabel.vue"

@Component({
  components: {
    ValidationProvider,
    FormInputLabel,
  },
})
export default class AutoCompleteWithValidation extends Vue {
  @Prop({ default: "" }) group: string
  @Prop() vid: string
  @Prop() rules: [string]
  @Prop() value: string
  @Prop() tooltip: string
  @Prop({ default: "" }) placeholder: string
  @Prop({ default: "" }) emptyMessage: string
  @Prop() data: [string]
  innerValue = ""
  isMandatory = false

  created(): void {
    if (this.value) {
      this.innerValue = this.value
    }
    this.isMandatory = this.rules && this.rules.includes("required")
  }

  @Watch("innerValue")
  onInnerValueChanged(newVal: string, _oldValue: string): void {
    this.$emit("input", newVal)
  }

  @Watch("value")
  onExternalValueChanged(newVal: string, _oldValue: string): void {
    this.innerValue = newVal
    if (newVal != _oldValue) {
      this.$emit("manual-change", newVal)
    }
  }

  emitSelect(newVal: string): void {
    this.$emit("select", newVal)
  }

  onEmptyResult(): void {
    this.$emit("empty-result")
  }
}
