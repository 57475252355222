var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PageView",
    {
      attrs: {
        mainTitle: _vm.$t(_vm.currentSectionTitle),
        subTitle: _vm.structure ? _vm.structure.nom : ""
      }
    },
    [
      _c("ContentView", [
        _vm.section === "details"
          ? _c("div", [
              !_vm.isChanging && _vm.structure && _vm.structure.id
                ? _c(
                    "div",
                    [
                      _c("StructureUpdateView", {
                        ref: "structureView",
                        attrs: {
                          structure: _vm.structure,
                          userCanChange:
                            !_vm.isAccompagnateurOrAdmin && !_vm.readonly,
                          readonly: _vm.readonly,
                          validationGroup: "StructureDetails"
                        },
                        on: {
                          "change-structure": function($event) {
                            _vm.isChanging = true
                          }
                        }
                      }),
                      !_vm.readonly
                        ? _c(
                            "div",
                            { staticClass: "buttons" },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    "icon-left": "save",
                                    type: "is-primary"
                                  },
                                  on: { click: _vm.updateStructure }
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("profilePage.etablissement.save")
                                      )
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm.user.profil === "REFERENT"
                ? _c(
                    "div",
                    [
                      _c("StructureSelectionView", {
                        attrs: {
                          isChanging: true,
                          isUpdating: "true",
                          enableBack: false
                        },
                        on: { "input-structure": _vm.doChangeStructure }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
          : _vm._e(),
        _vm.section === "referents" &&
        !_vm.isChanging &&
        (_vm.isAccompagnateurOrAdmin ||
          _vm.isFromStructure ||
          _vm.isObservateurNational)
          ? _c(
              "div",
              [
                _vm.structure.id
                  ? _c("StructureReferentsView", {
                      attrs: { structureId: _vm.structure.id }
                    })
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _vm.section === "projets" &&
        !_vm.isChanging &&
        (_vm.isAccompagnateurOrAdmin ||
          _vm.isFromStructure ||
          _vm.isObservateurNational)
          ? _c(
              "div",
              [
                _vm.structure.id
                  ? _c("StructureProjetsView", {
                      attrs: { structureId: _vm.structure.id }
                    })
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }