


























































import { ProjetAnnuelDTO, UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { LoginService } from "@/services/rest/login/LoginService"
import { TAG_TYPES_FOR_BOOKMARK_CARD } from "@/model/bean/EnumHelper"
import BookmarkCard from "@/views/generic_components/BookmarkCard.vue"
import { ValidationObserver } from "vee-validate"
import FileWithValidation from "@/views/inputs/FileWithValidation.vue"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import { FileService } from "@/services/rest/utils/FileService"

@Component({
  components: {
    BookmarkCard,
    ValidationObserver,
    FileWithValidation,
    InputWithValidation,
  },
})
export default class ProjetCharteView extends Vue {
  @Prop() projetAnnuel: ProjetAnnuelDTO
  @Prop() readonly: boolean
  @Prop({ default: true }) validationActivated: boolean
  @Prop({ default: true }) showValidationErrors: boolean

  private loginService = LoginService.INSTANCE
  private fileService = FileService.INSTANCE

  loggedUser: UtilisateurDTO = new UtilisateurDTO()
  invitations: string[] = []
  protected tagType = TAG_TYPES_FOR_BOOKMARK_CARD.CHARTE_ENGAGEMENT
  protected iconNameSignature = "file-signature"
  protected iconNameValid = "check-circle"
  charteFileUrl = ""

  created(): void {
    const loggedUser = this.loginService.getLoggedUser()
    if (!loggedUser || !loggedUser.id) {
      return
    }
    this.loggedUser = loggedUser
    if (this.projetAnnuel.id) {
      this.charteFileUrl = this.fileService.buildUniqueFileURL(
        this.projetAnnuel.id,
        "CHARTE_ENGAGEMENT"
      )
    }
  }

  @Watch("projetAnnuel")
  dossierChanged(): void {
    if (!this.projetAnnuel || !this.projetAnnuel.id) {
      return
    }
    this.charteFileUrl = this.fileService.buildUniqueFileURL(
      this.projetAnnuel.id,
      "CHARTE_ENGAGEMENT"
    )
  }

  addCharte(): void {
    this.projetAnnuel.charteFilePresent = true
    this.projetAnnuel.charteFileValid = true
    this.$emit("trigger-validation")
  }

  deleteCharte(): void {
    this.projetAnnuel.charteFilePresent = false
    this.projetAnnuel.charteFileValid = false
    this.$emit("trigger-validation")
  }

  charteUploadedOrDeleted(projetAnnuel: ProjetAnnuelDTO): void {
    this.projetAnnuel.fichiers = projetAnnuel.fichiers ? projetAnnuel.fichiers : []
    this.$emit("dirtify-project")
  }
}
