var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ContentView",
    [
      _c(
        "b-modal",
        {
          staticClass: "JoinProjectBeforeAskingView",
          attrs: {
            active: true,
            "has-modal-card": "",
            "trap-focus": "",
            "aria-role": "dialog",
            "aria-label": "JoinProjectBeforeAskingModal",
            "aria-modal": "",
            width: "100vw"
          }
        },
        [
          [
            _c("div", { staticClass: "modal-card" }, [
              _c(
                "header",
                { staticClass: "modal-card-head" },
                [
                  _c("H3TitleView", { staticClass: "modal-card-title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("myProjects.create-project.title").toString()
                      )
                    )
                  ])
                ],
                1
              ),
              _c(
                "section",
                { staticClass: "modal-card-body" },
                [
                  _c(
                    "div",
                    [
                      _c("SingleEtablissement", {
                        attrs: { etablissement: _vm.chosenEtablissement }
                      }),
                      _c(
                        "div",
                        { staticClass: "JoinProjectBeforeAskingView-infos" },
                        [
                          _c("b-icon", {
                            attrs: { icon: "times-circle", type: "is-danger" }
                          }),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "myProjects.create-project.etablissementWithProject"
                                )
                              )
                            )
                          ])
                        ],
                        1
                      ),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "myProjects.create-project.etablissementWithProject1"
                                )
                              ) +
                              " "
                          )
                        ])
                      ]),
                      _c("br"),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "myProjects.create-project.etablissementWithProject2"
                              )
                            )
                          )
                        ])
                      ]),
                      _c("br"),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "myProjects.create-project.etablissementWithProject4"
                              )
                            )
                          )
                        ])
                      ]),
                      _c("br"),
                      _c("div", { staticClass: "text-bold" }, [
                        _c("b", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "myProjects.create-project.etablissementWithProject3"
                              )
                            ) + " "
                          )
                        ])
                      ]),
                      _c("br"),
                      _c(
                        "div",
                        { staticClass: "JoinProjectBeforeAskingView-cards" },
                        _vm._l(_vm.filteredProjects, function(project) {
                          return _c(
                            "div",
                            { key: project.id },
                            [
                              _c("EtablissementProjectCard", {
                                attrs: {
                                  project: project,
                                  loggedUser: _vm.loggedUser
                                }
                              })
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ],
                    1
                  ),
                  _c("b-loading", {
                    attrs: { "is-full-page": "" },
                    model: {
                      value: _vm.isLoading,
                      callback: function($$v) {
                        _vm.isLoading = $$v
                      },
                      expression: "isLoading"
                    }
                  })
                ],
                1
              ),
              _c("footer", { staticClass: "modal-card-foot" }, [
                _c("div", { class: "buttons" }, [
                  _c(
                    "div",
                    { staticClass: "modal-finish-buttons" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "hoverable-discrete-link",
                          on: { click: _vm.cancelProjectCreation }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("annuler")) + " ")]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            rounded: "",
                            type: "is-link",
                            disabled: _vm.isLoading
                          },
                          on: { click: _vm.askForCreation }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("myProjects.create-project.create")
                              ) +
                              " "
                          )
                        ]
                      ),
                      !_vm.isReferent
                        ? _c(
                            "b-button",
                            {
                              attrs: {
                                rounded: "",
                                type: "is-primary",
                                disabled: _vm.isLoading
                              },
                              on: { click: _vm.confirmJoin }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "myProjects.create-project.confirmJoin"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ])
            ])
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }