




import { Component, Vue, Prop } from "vue-property-decorator"
import { LoginService } from "@/services/rest/login/LoginService"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import { ProjectService } from "@/services/rest/project/ProjectService"

@Component({})
export default class AcceptOrDeclineProjectInvitationPage extends Vue {
  @Prop() projetId: string
  @Prop() acceptOrDecline: string
  @Prop() userId: string

  loginService = LoginService.INSTANCE
  projectService = ProjectService.INSTANCE
  infoReportingService = InfoReportingService.INSTANCE

  mounted() {
    // Logout if trying to accept or refuse an invitation not matched current user
    const loggedUser = this.loginService.getLoggedUser()
    if (loggedUser && loggedUser.id !== this.userId) {
      this.loginService.logout()
    }

    this.acceptOrDeclineAndGoToProjects()
  }

  async acceptOrDeclineAndGoToProjects(): Promise<void> {
    if (this.acceptOrDecline.indexOf("accept") !== -1) {
      await this.projectService.acceptProjetInclusion(this.projetId, this.userId)
    } else {
      await this.projectService.declineProjetInclusion(this.projetId, this.userId)
    }
    this.infoReportingService.success(this.$t("projet.acceptation").toString())
    this.$router.push("/myProjects")
  }
}
