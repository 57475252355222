var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "projet-part" }, [
    _c("h2", { staticClass: "pdf-page-break" }, [
      _vm._v(
        _vm._s(_vm.$t("projetPage.infoAdministratives.participants.titre"))
      )
    ]),
    _c("fieldset", [
      _c("legend", [
        _vm._v(_vm._s(_vm.$t("projetPage.infoAdministratives.classe.titre")))
      ]),
      _c(
        "div",
        [
          _vm.validationActivated
            ? _c("div", { staticClass: "is-mandatory" }, [
                _vm._v(_vm._s(_vm.$t("obligatoire")))
              ])
            : _vm._e(),
          _vm.validationActivated && _vm.projetAnnuel.classes.length == 0
            ? _c("b-message", { attrs: { type: "is-danger" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "projetPage.infoAdministratives.classe.no-class-warning"
                      )
                    ) +
                    " "
                )
              ])
            : _vm._e(),
          _vm.projetAnnuel.classes.length == 0
            ? _c("InputWithValidation", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false"
                  }
                ],
                attrs: {
                  vid: "participants_classes_missing",
                  rules: "required"
                }
              })
            : _vm._e(),
          _c("ProjetClassesView", {
            attrs: {
              isPorteurProjet: _vm.isPorteurProjet,
              projetAnnuel: _vm.projetAnnuel,
              readonly: _vm.readonly
            },
            on: {
              "dirtify-project": _vm.dirtifyProject,
              "trigger-validation": function($event) {
                return _vm.$emit("trigger-validation")
              }
            }
          })
        ],
        1
      )
    ]),
    _c("fieldset", [
      _c("legend", [
        _vm._v(
          _vm._s(
            _vm.$t(
              "projetPage.infoAdministratives.participants.titreEnseignant"
            )
          )
        )
      ]),
      _c(
        "div",
        [
          _c("ProjetEnseignantsView", {
            attrs: {
              projetAnnuel: _vm.projetAnnuel,
              invitations: _vm.invitations,
              readonly: _vm.readonly,
              showInviteLink: _vm.showInviteLink(),
              isPorteurProjet: _vm.isPorteurProjet
            },
            on: {
              "dirtify-project": _vm.dirtifyProject,
              "invite-enseignant": _vm.inviteEnseignant
            }
          })
        ],
        1
      )
    ]),
    _c("fieldset", [
      _c("legend", [
        _vm._v(
          _vm._s(
            _vm.$t("projetPage.infoAdministratives.participants.titreReferent")
          )
        )
      ]),
      _c(
        "div",
        [
          _c("ProjetReferentsView", {
            attrs: {
              projetAnnuel: _vm.projetAnnuel,
              invitations: _vm.invitations,
              readonly:
                _vm.readonly &&
                _vm.projetAnnuel.statut !== "ACCEPTE_SOUS_RESERVE_REFERENT",
              showInviteLink:
                (_vm.showInviteLink() ||
                  _vm.projetAnnuel.statut ===
                    "ACCEPTE_SOUS_RESERVE_REFERENT") &&
                !_vm.usersService.isObservateurNational(_vm.loggedUser),
              isPorteurProjet: _vm.isPorteurProjet
            },
            on: {
              "dirtify-project": _vm.dirtifyProject,
              "invite-referent": _vm.inviteReferent
            }
          })
        ],
        1
      )
    ]),
    _c("fieldset", [
      _c("legend", [
        _vm._v(
          _vm._s(
            _vm.$t(
              "projetPage.infoAdministratives.participants.titreActeurEducNat"
            )
          )
        )
      ]),
      _c(
        "div",
        [
          _c("div", { staticClass: "mt-3 mb-3" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "projetPage.infoAdministratives.participants.acteurEducNatDescription"
                  )
                ) +
                " "
            )
          ]),
          _c("InputWithValidation", {
            attrs: {
              type: "textarea",
              vid: "participants_acteurs_educ_nat",
              maxlength: "1400",
              showValidationErrors: _vm.showValidationErrors,
              disabled: _vm.readonly
            },
            on: {
              "manual-change": function($event) {
                return _vm.dirtifyProject(false)
              }
            },
            model: {
              value: _vm.projetAnnuel.acteursEducNationaleText,
              callback: function($$v) {
                _vm.$set(_vm.projetAnnuel, "acteursEducNationaleText", $$v)
              },
              expression: "projetAnnuel.acteursEducNationaleText"
            }
          })
        ],
        1
      )
    ]),
    _c("fieldset", [
      _c("legend", [
        _vm._v(
          _vm._s(
            _vm.$t(
              "projetPage.infoAdministratives.participants.titreAutreActeur"
            )
          )
        )
      ]),
      _c(
        "div",
        [
          _c("div", { staticClass: "mt-3 mb-3" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "projetPage.infoAdministratives.participants.autreActeurDescription"
                  )
                ) +
                " "
            )
          ]),
          _c("InputWithValidation", {
            attrs: {
              type: "textarea",
              vid: "participants_autres_acteurs",
              maxlength: "1400",
              disabled: _vm.readonly,
              showValidationErrors: _vm.showValidationErrors
            },
            on: {
              "manual-change": function($event) {
                return _vm.dirtifyProject(false)
              }
            },
            model: {
              value: _vm.projetAnnuel.autresActeursText,
              callback: function($$v) {
                _vm.$set(_vm.projetAnnuel, "autresActeursText", $$v)
              },
              expression: "projetAnnuel.autresActeursText"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }