








































































































import { ActualiteDTO, UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import { DATE_TIME_FORMAT } from "@/views/grae/ActualitesView.vue"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import H3TitleView from "../commons/H3TitleView.vue"
import DatePickerWithValidation from "../inputs/DatePickerWithValidation.vue"
import InputWithValidation from "../inputs/InputWithValidation.vue"
import { format } from "date-fns"
import { LoginService } from "@/services/rest/login/LoginService"
import { ValidationObserver } from "vee-validate"

@Component({
  components: {
    H3TitleView,
    InputWithValidation,
    DatePickerWithValidation,
    ValidationObserver,
  },
})
export default class ActualitesModalView extends Vue {
  @Prop() actualite: ActualiteDTO
  @Prop() open: boolean
  @Prop() readOnly: boolean

  private loginService = LoginService.INSTANCE

  loggedUser? = new UtilisateurDTO()
  internalActualite = new ActualiteDTO()

  created(): void {
    this.loggedUser = this.loginService.getLoggedUser()
    this.assignActualiteToInternal()
  }

  @Watch("actualite")
  onActualiteChanged(): void {
    this.assignActualiteToInternal()
  }

  assignActualiteToInternal(): void {
    this.internalActualite = this.actualite

    if (!this.actualite.id) {
      this.internalActualite.dateCreation = new Date()
      if (this.loggedUser) {
        this.internalActualite.auteur = this.loggedUser
      }
    }
  }

  emitCloseModale(): void {
    this.$emit("close")
  }

  saveModifications(): void {
    this.$emit("close-refresh", this.internalActualite)
  }

  createNewActualite(): void {
    this.$emit("close-refresh", this.internalActualite)
  }

  formatDate(date: Date): string {
    if (date) {
      return format(date, DATE_TIME_FORMAT)
    }
    return ""
  }
}
