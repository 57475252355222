var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PageView",
    { attrs: { isOnBigHeaderPage: true, noSubTitleExpected: "true" } },
    [
      _c("TitleView", [_vm._v(_vm._s(_vm.$t("resetPassword.title")))]),
      _c(
        "ContentView",
        [
          _vm.passwordResetError != ""
            ? _c("b-message", { attrs: { type: "is-danger" } }, [
                _vm._v(" " + _vm._s(_vm.passwordResetError) + " ")
              ])
            : _vm._e(),
          _c(
            "router-link",
            { attrs: { to: { path: "/forgot-password/ " } } },
            [
              _vm.passwordResetError != ""
                ? _c("b-button", { attrs: { type: "is-danger" } }, [
                    _c("span", { staticClass: "icon is-small" }),
                    _vm._v(" " + _vm._s(_vm.$t("resetPassword.tryagain")) + " ")
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm.passwordResetError == ""
            ? _c("ValidationObserver", {
                ref: "observer",
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var handleSubmit = ref.handleSubmit
                        return [
                          _c("b-message", { attrs: { type: "is-info" } }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("resetPassword.explain")) +
                                " "
                            )
                          ]),
                          _c("PasswordCreationWithValidation", {
                            attrs: { label: _vm.$t("input-password") },
                            model: {
                              value: _vm.password,
                              callback: function($$v) {
                                _vm.password = $$v
                              },
                              expression: "password"
                            }
                          }),
                          _c("InputWithValidation", {
                            attrs: {
                              rules: "required|confirmed:password",
                              type: "password",
                              passwordReveal: "",
                              label: _vm.$t("input-password-confirmation")
                            },
                            model: {
                              value: _vm.confirmation,
                              callback: function($$v) {
                                _vm.confirmation = $$v
                              },
                              expression: "confirmation"
                            }
                          }),
                          _c(
                            "b-button",
                            {
                              attrs: { type: "is-success" },
                              on: {
                                click: function($event) {
                                  return handleSubmit(_vm.submit)
                                }
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("resetPassword.submit")) + " "
                                )
                              ])
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2080785111
                )
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }