import { render, staticRenderFns } from "./ResetPasswordPage.vue?vue&type=template&id=01c539c2&"
import script from "./ResetPasswordPage.vue?vue&type=script&lang=ts&"
export * from "./ResetPasswordPage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/ofb/sagae/src/main/webapp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('01c539c2')) {
      api.createRecord('01c539c2', component.options)
    } else {
      api.reload('01c539c2', component.options)
    }
    module.hot.accept("./ResetPasswordPage.vue?vue&type=template&id=01c539c2&", function () {
      api.rerender('01c539c2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/login/ResetPasswordPage.vue"
export default component.exports