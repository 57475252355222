var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "instruction-pannel-entry" },
    [
      _c(
        "ValidationObserver",
        { ref: "observer" },
        [
          _c(
            "div",
            { staticClass: "tabs-header" },
            [
              _c("h2", { staticClass: "h2" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "instruction-pannel.labellisation-qualification.title"
                    )
                  )
                )
              ]),
              _c("TabsDropdownSelector", {
                attrs: {
                  tabs: _vm.tabs,
                  activeTab: _vm.activeTab,
                  disabled: _vm.isSaving
                },
                on: { "change-tab": _vm.changeTab }
              }),
              _c("b-loading", {
                attrs: { "is-full-page": false },
                model: {
                  value: _vm.isSaving,
                  callback: function($$v) {
                    _vm.isSaving = $$v
                  },
                  expression: "isSaving"
                }
              })
            ],
            1
          ),
          _c(
            "b-tabs",
            {
              staticClass: "block InstructionModale-tabs",
              attrs: { vertical: "", animated: false },
              model: {
                value: _vm.activeTab,
                callback: function($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab"
              }
            },
            [
              _c(
                "b-tab-item",
                { attrs: { label: _vm.$t("instruction.thematiques") } },
                [
                  _c("InstructionThematiques", {
                    attrs: {
                      projetAnnuel: _vm.internalProjetAnnuel,
                      readOnly: _vm.readOnly
                    }
                  })
                ],
                1
              ),
              _c(
                "b-tab-item",
                { attrs: { label: _vm.$t("instruction.objets") } },
                [
                  _c("InstructionObjetsRealisation", {
                    attrs: {
                      projetAnnuel: _vm.internalProjetAnnuel,
                      readOnly: _vm.readOnly
                    }
                  })
                ],
                1
              ),
              _c(
                "b-tab-item",
                { attrs: { label: _vm.$t("instruction.milieu-generique") } },
                [
                  _c("InstructionMilieuxGeneriques", {
                    attrs: {
                      projetAnnuel: _vm.internalProjetAnnuel,
                      readOnly: _vm.readOnly
                    }
                  })
                ],
                1
              ),
              _c(
                "b-tab-item",
                {
                  attrs: {
                    label: _vm.$t("instruction.milieu-interet-particulier")
                  }
                },
                [
                  _c("InstructionMilieuxParticuliers", {
                    attrs: {
                      projetAnnuel: _vm.internalProjetAnnuel,
                      readOnly: _vm.readOnly
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "bottom-buttons-evaluation" }, [
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { type: "is-primary", disabled: _vm.readOnly },
                    on: {
                      click: function($event) {
                        return _vm.save()
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("instruction-pannel.instruction.save")) +
                        " "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }