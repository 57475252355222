































































import { ValidationProvider } from "vee-validate"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import FormInputLabel from "./FormInputLabel.vue"

@Component({
  components: {
    ValidationProvider,
    FormInputLabel,
  },
})
export default class SelectWithValidation extends Vue {
  @Prop({ default: "" }) group: string
  @Prop() vid: string
  @Prop() rules: [string]
  @Prop() value: string
  @Prop() tooltip: string
  @Prop({ default: "" }) placeholder: string
  @Prop() data: string[]
  @Prop() selectLabels: string[]
  @Prop({ default: false }) disabled: boolean
  @Prop() inputNotice: string
  @Prop({ default: -1 }) maxNumber: number
  innerValue = ""
  isMandatory = false
  maxNumberReached = false

  created(): void {
    if (this.value) {
      this.innerValue = this.value
    }
    this.isMandatory = this.rules && this.rules.includes("required")
  }

  @Watch("innerValue")
  onInnerValueChanged(newVal: string, _oldValue: string): void {
    this.$emit("input", newVal)
    if (this.innerValue.length >= this.maxNumber && this.maxNumber > 0) {
      this.maxNumberReached = true
    } else {
      this.maxNumberReached = false
    }
  }

  @Watch("maxNumber")
  onMaxNumberChanged(): void {
    if (this.innerValue.length >= this.maxNumber && this.maxNumber > 0) {
      this.maxNumberReached = true
    } else {
      this.maxNumberReached = false
    }
  }

  @Watch("value")
  onExternalValueChanged(): void {
    this.innerValue = this.value
    if (this.innerValue.length >= this.maxNumber && this.maxNumber > 0) {
      this.maxNumberReached = true
    } else {
      this.maxNumberReached = false
    }
  }
}
