var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ValidationObserver",
    { ref: "conseilObserver" },
    [
      _c("BookmarkCard", {
        attrs: {
          tagType: _vm.tagType,
          iconName: _vm.iconName,
          iconType: "is-success"
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c("div", [
                  _vm.conseilAdded
                    ? _c(
                        "div",
                        [
                          _c("DatePickerWithValidation", {
                            staticClass: "pl-2 pr-2",
                            attrs: {
                              validationActivated: _vm.validationActivated,
                              showValidationErrors: _vm.showValidationErrors,
                              vid: "conseil_date",
                              disabled: _vm.readonly,
                              label: _vm.$t(
                                "projetPage.mon-annee.conseils.date"
                              ),
                              rules: "required",
                              hideBr: true
                            },
                            model: {
                              value: _vm.internalConseil.dateConseil,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.internalConseil,
                                  "dateConseil",
                                  $$v
                                )
                              },
                              expression: "internalConseil.dateConseil"
                            }
                          }),
                          _c("SelectWithValidation", {
                            staticClass: "pl-2 pr-2 full",
                            attrs: {
                              validationActivated: _vm.validationActivated,
                              showValidationErrors: _vm.showValidationErrors,
                              vid: "conseil_type",
                              disabled: _vm.readonly,
                              label: _vm.$t(
                                "projetPage.mon-annee.conseils.type"
                              ),
                              data: _vm.typeConseil,
                              selectLabels: _vm.typeConseilLabels,
                              rules: "required"
                            },
                            model: {
                              value: _vm.internalConseil.type,
                              callback: function($$v) {
                                _vm.$set(_vm.internalConseil, "type", $$v)
                              },
                              expression: "internalConseil.type"
                            }
                          }),
                          _c(
                            "b-field",
                            { staticClass: "pl-2 pr-2 full" },
                            [
                              _c("template", { slot: "label" }, [
                                _c("div", { staticClass: "fieldLabel" }, [
                                  _c("div", { staticClass: "innerLabel" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "projetPage.mon-annee.conseils.compte-rendu"
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ])
                              ]),
                              _c(
                                "div",
                                { staticClass: "is-flex is-horizontal-center" },
                                [
                                  _vm.conseil && _vm.conseil.fichier
                                    ? _c("div", [
                                        _c("div", { staticClass: "columns" }, [
                                          _c("div", { staticClass: "column" }, [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "small_file_name",
                                                attrs: {
                                                  target: "_blank",
                                                  href: _vm.getConseilUrl(
                                                    _vm.conseil
                                                  ),
                                                  rel: "noopener"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.truncatedFileName(
                                                        _vm.fichier.nom
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ])
                                        ]),
                                        _c("div", { staticClass: "columns" }, [
                                          _c(
                                            "div",
                                            { staticClass: "column" },
                                            [
                                              _vm.canDelete
                                                ? _c(
                                                    "b-button",
                                                    {
                                                      attrs: {
                                                        type:
                                                          "is-danger is-outlined",
                                                        rounded: "",
                                                        "icon-left": "trash"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.deleteConseil()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t("delete")
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ])
                                      ])
                                    : _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            type: "is-primary is-outlined",
                                            rounded: "",
                                            disabled:
                                              _vm.readonly ||
                                              !_vm.internalConseil
                                                .dateConseil ||
                                              !_vm.internalConseil.type
                                          }
                                        },
                                        [
                                          _c(
                                            "b-upload",
                                            {
                                              attrs: {
                                                accept: ".pdf",
                                                required: "",
                                                disabled:
                                                  _vm.readonly ||
                                                  !_vm.internalConseil
                                                    .dateConseil ||
                                                  !_vm.internalConseil.type
                                              },
                                              on: { input: _vm.addConseil }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "projetPage.mon-annee.conseils.televerser"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                ],
                                1
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "nouveau-conseil-content" },
                        [
                          _c("br"),
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "projetPage.mon-annee.conseils.description"
                              )
                            )
                          ),
                          _c("br"),
                          _c("br"),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                type: "is-primary is-outlined",
                                rounded: ""
                              },
                              on: {
                                click: function($event) {
                                  _vm.conseilAdded = true
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("create")) + " ")]
                          )
                        ],
                        1
                      )
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }