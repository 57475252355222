/*-
 * #%L
 * Aires Éducatives
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
import {
  required,
  confirmed,
  email,
  numeric,
  max,
  length,
  max_value,
  min,
} from "vee-validate/dist/rules"
import { extend } from "vee-validate"
import { isAfter } from "date-fns"
import i18n from "@/i18n"

// See https://logaretm.github.io/vee-validate/guide/rules.html
// For list of all availables rules

extend("required", {
  ...required,
  message: i18n.t("validation-input-invalid-required").toString(),
})

extend("email", {
  message: i18n.t("validation-input-invalid-email").toString(),
  validate: (value) => {
    const asciiBeforeAt = /^[^@]*$/ // Checks for ASCII characters before the '@'
    const forbiddenChars = /['"&*`]/ // Disallow specific characters
    const accentedDomainChars = /[éàè]/ // Allow specific accented characters in the domain
    const [localPart, domain] = value.split("@")

    // Validate only if there is a local part and a domain part
    if (localPart && domain) {
      return (
        email.validate(value) &&
        asciiBeforeAt.test(localPart) &&
        !accentedDomainChars.test(localPart) &&
        !forbiddenChars.test(localPart) &&
        (!forbiddenChars.test(domain) || !accentedDomainChars.test(domain))
      )
    }

    // If the email does not have two parts, it's invalid
    return false
  },
})

extend("confirmed", {
  ...confirmed,
  message: i18n.t("validation-input-invalid-confirm-not-matching").toString(),
})

extend("validPassword", {
  message: i18n.t("validation-input-password-not-matching-regex").toString(),
  validate: (value) => {
    let validated = value && value.length >= 8
    validated = validated && /\d/.test(value)
    validated = validated && /[a-z]/.test(value)
    validated = validated && /[A-Z]/.test(value)
    return validated
  },
})

extend("validPhoneNumber", {
  message: i18n.t("validation-input-phone-not-matching-regex").toString(),
  validate: (value) => {
    let validated = value && value.length == 10
    validated = validated && /[0-9]{10}/.test(value)
    return validated
  },
})

extend("dateIsAfter", {
  message: i18n.t("validation-input-date-not-after").toString(),
  validate: (value, { min }: Record<string, any>) => {
    return isAfter(value, new Date(min))
  },
  params: ["min"],
})

extend("checked", {
  message: i18n.t("validation-checkbox-true").toString(),
  validate: (value) => {
    return value === true
  },
})

extend("certified-exact", {
  message: i18n.t("projet.inscription.finalisation-inscription.certified-error").toString(),
  validate: (value) => {
    return value === true
  },
})

extend("accept-cookie", {
  message: i18n.t("must-accept-cookie").toString(),
  validate: (value) => {
    return value === true
  },
})

extend("accept-rgpd", {
  message: i18n.t("registerPage.must-accept-rgpd").toString(),
  validate: (value) => {
    return value === true
  },
})

extend("numeric", {
  ...numeric,
  message: i18n.t("validation-input-invalid-number").toString(),
})
extend("postalCode", {
  ...numeric, // Ensure we keep the base numeric validations
  message: i18n.t("validation-input-invalid-number").toString(),
  validate(value) {
    // First, verify the value is numeric using the base validation
    const isNumeric = numeric.validate(value)
    if (!isNumeric) {
      return {
        valid: false,
        message: i18n.t("validation-input-invalid-number").toString(),
      }
    }

    // Convert the value to a string to check if it ends with '0'
    const valueStr = value.toString()
    const endsWithZero = valueStr.endsWith("0")

    // Return the validation result
    return {
      valid: endsWithZero,
      message: endsWithZero ? "" : i18n.t("validation-postal-code-must-end-with-zero").toString(),
    }
  },
})

extend("maxChoices", {
  message: i18n.t("validation-max-choices").toString(),
  validate: (value, { max }: Record<string, any>) => {
    return value && value instanceof Array && value.length <= parseInt(max)
  },
  params: ["max"],
})

extend("min", {
  ...min,
  message: i18n.t("validation-min-length").toString(),
})

extend("max", {
  ...max,
  message: i18n.t("validation-max-length").toString(),
})

extend("max_value", {
  ...max_value,
  message: i18n.t("validation-input-invalid-number").toString(),
})

extend("length", {
  ...length,
  message: i18n.t("validation-exact-length").toString(),
})

extend("shouldMatchFollowing", {
  message: i18n.t("validation-match-following").toString(),
  validate: (value, { models }: any) => {
    let array: any[] = models
    if (!Array.isArray(models)) {
      array = [models]
    }
    return array && array.some((model: string) => model === value)
  },
  params: ["models"],
})

extend("periodFinancementValid", {
  ...required,
  message: i18n.t("validation-financement-dates").toString(),
})

extend("niveauValid", {
  ...required,
  message: i18n.t("validation-classe-niveau-valid").toString(),
})
