var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-5" },
    [
      _c("div", [
        _c("div", { staticClass: "calendar-title" }, [
          _c("div", { staticClass: "columns is-vcentered" }, [
            _c("div", { staticClass: "column is-two-fifths" }, [
              _vm._v(_vm._s(_vm.$t("projet.en-bref.calendrier")))
            ]),
            _c("div", { staticClass: "column" }, [
              _c("div", { staticClass: "container has-text-right" }, [
                _c("a", [
                  !_vm.readonly && _vm.isPorteurProjet
                    ? _c(
                        "span",
                        {
                          on: {
                            click: function($event) {
                              _vm.openEventModale = true
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("projet.en-bref.add-event")))]
                      )
                    : _vm._e()
                ])
              ])
            ])
          ])
        ]),
        _c(
          "div",
          { staticClass: "is-flex is-horizontal-center" },
          [
            _c("b-datepicker", {
              attrs: { inline: "", events: _vm.events },
              model: {
                value: _vm.selectedDate,
                callback: function($$v) {
                  _vm.selectedDate = $$v
                },
                expression: "selectedDate"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "event-container is-horizontal-center" },
          _vm._l(_vm.selectedEvenements, function(evenement) {
            return _c(
              "div",
              { key: evenement.id, staticClass: "card mt-2 p-2 event" },
              [
                _c("div", { staticClass: "card-content p0" }, [
                  _c("div", { staticClass: "event-content" }, [
                    _vm._v(" " + _vm._s(evenement.contenu) + " ")
                  ]),
                  _c(
                    "div",
                    { staticClass: "delete-event" },
                    [
                      !_vm.readonly && _vm.isPorteurProjet
                        ? _c("b-button", {
                            attrs: {
                              size: "is-small",
                              type: "is-danger",
                              "icon-right": "trash",
                              rounded: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.removeEvenement(
                                  evenement.projetAnnuel,
                                  evenement.id
                                )
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ]
            )
          }),
          0
        )
      ]),
      _c("EventModale", {
        attrs: {
          projetAnnuel: _vm.projetAnnuel,
          open: _vm.openEventModale,
          isPorteurProjet: _vm.isPorteurProjet,
          unselectableDates: _vm.unselectableDates
        },
        on: {
          "close-modale": function($event) {
            return _vm.closeModale($event)
          }
        }
      }),
      _vm.calendriedFichier.id
        ? _c("FileWithValidation", {
            key: _vm.calendriedFichier.id,
            attrs: {
              inputNotice: "Calendrier des activités",
              fileOperationUrl: _vm.calendrierFichierUrl,
              disabled: true,
              value: true,
              defaultFileName: "calendrier.pdf"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }