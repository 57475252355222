



























import { QuickFilter } from "@/model/bean/GeneratedDTOs"
import { Component, Prop, Vue } from "vue-property-decorator"

@Component({})
export default class QuickFilterView extends Vue {
  @Prop() quickFilter: QuickFilter | undefined
  @Prop({ default: false }) showUnassigned: boolean

  change(quickFilter: QuickFilter | undefined): void {
    this.$emit("quick-filter-changed", quickFilter)
  }

  computeType(value: QuickFilter | undefined): string {
    return this.quickFilter === value ? "is-link" : ""
  }
}
