var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "details-ae" }, [
    _c("header", [
      _c(
        "div",
        {
          staticClass: "hoverable-discrete-link mr-3",
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [
          _c("b-icon", {
            attrs: { size: "is-normal", icon: "angle-double-right" }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "projet_detail" }, [
      _c("div", { staticClass: "header-grid" }, [
        _c("div", [
          _c("h2", [_vm._v(_vm._s(_vm.project.titre))]),
          _c("p", [_vm._v(_vm._s(_vm.project.ville))]),
          _vm.role === "SUPER_ADMINISTRATEUR" ||
          _vm.role === "OBSERVATEUR_NATIONAL" ||
          _vm.role === "ACCOMPAGNATEUR_NATIONAL"
            ? _c(
                "p",
                [
                  _c(
                    "b-button",
                    {
                      attrs: { type: "is-primary" },
                      on: { click: _vm.viewProject }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("mapDraw.legends.project.viewProject")
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          (_vm.role === "ACCOMPAGNATEUR_REGIONAL" ||
            _vm.role === "ANIMATEUR_REGIONAL") &&
          this.showViewProject(_vm.project.graeID)
            ? _c(
                "p",
                [
                  _c(
                    "b-button",
                    {
                      attrs: { type: "is-primary" },
                      on: { click: _vm.viewProject }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("mapDraw.legends.project.viewProject")
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ]),
        _c("div", [
          _c("img", {
            staticClass: "d-flex",
            staticStyle: { "margin-left": "30%" },
            attrs: { src: _vm.icon, alt: _vm.project.titre, width: "120px" }
          })
        ])
      ]),
      _c("div", { staticClass: "pitch-container" }, [
        _c("div", [
          _c("strong", [
            _vm._v(_vm._s(_vm.$t("mapDraw.legends.project.pitch")) + " :")
          ]),
          _c("br"),
          _c(
            "div",
            {
              class: {
                "line-clamp": !_vm.showFullPitch,
                "scrollable-pitch": _vm.showFullPitch
              }
            },
            [
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.displayedPitch ||
                        _vm.$t("mapDraw.legends.project.not-available")
                    ) +
                    " "
                )
              ])
            ]
          ),
          _vm.project.pitch && _vm.project.pitch.length > 300
            ? _c("a", { on: { click: _vm.togglePitch } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.showFullPitch
                        ? _vm.$t("mapDraw.legends.project.showLess")
                        : _vm.$t("mapDraw.legends.project.showMore")
                    ) +
                    " "
                )
              ])
            : _vm._e()
        ])
      ]),
      _c("div", [
        _c("strong", [
          _vm._v(_vm._s(_vm.$t("mapDraw.legends.project.createdIn")) + ":")
        ]),
        _vm._v(
          " " +
            _vm._s(
              _vm.project.anneeCreation ||
                _vm.$t("mapDraw.legends.project.not-available")
            ) +
            " "
        )
      ]),
      _c("div", [
        _c("strong", [
          _vm._v(_vm._s(_vm.$t("mapDraw.legends.project.classes")) + ":")
        ]),
        _c(
          "ul",
          _vm._l(_vm.displayedClasses, function(classItem, index) {
            return _c("li", { key: index }, [_vm._v(_vm._s(classItem))])
          }),
          0
        ),
        _vm.project.classes && _vm.project.classes.length > 2
          ? _c("a", { on: { click: _vm.toggleClasses } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.showFullClasses
                      ? _vm.$t("mapDraw.legends.project.showLess")
                      : _vm.$t("mapDraw.legends.project.showMore")
                  ) +
                  " "
              )
            ])
          : _vm._e(),
        !_vm.project.classes || _vm.project.classes.length === 0
          ? _c("span", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("mapDraw.legends.project.not-available")) +
                  " "
              )
            ])
          : _vm._e()
      ]),
      _c("div", [
        _c("strong", [
          _vm._v(_vm._s(_vm.$t("mapDraw.legends.project.establishment")) + ":")
        ]),
        _c("br"),
        _vm._v(
          " " +
            _vm._s(
              _vm.project.nomEtablissement[0] ||
                _vm.$t("mapDraw.legends.project.not-available")
            ) +
            " "
        )
      ]),
      _vm.role === "ENSEIGNANT" || _vm.role === "REFERENT"
        ? _c("div", [
            _c("strong", [
              _vm._v(_vm._s(_vm.$t("mapDraw.legends.project.teachers")) + ":")
            ]),
            _c(
              "ul",
              _vm._l(_vm.displayedEnseignants, function(enseignant, index) {
                return _c("li", { key: index }, [
                  _vm._v(
                    " " +
                      _vm._s(enseignant.nom) +
                      " " +
                      _vm._s(enseignant.prenom) +
                      " (" +
                      _vm._s(enseignant.courriel) +
                      ") "
                  )
                ])
              }),
              0
            ),
            _vm.visibleEnseignants.length > 2
              ? _c("a", { on: { click: _vm.toggleEnseignants } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.showFullEnseignants
                          ? _vm.$t("mapDraw.legends.project.showLess")
                          : _vm.$t("mapDraw.legends.project.showMore")
                      ) +
                      " "
                  )
                ])
              : _vm._e(),
            _vm.visibleEnseignants.length === 0
              ? _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("mapDraw.legends.project.not-available")) +
                      " "
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _c("div", [
        _c("strong", [
          _vm._v(_vm._s(_vm.$t("mapDraw.legends.project.structure")) + " :")
        ]),
        _c("br"),
        _vm._v(
          " " +
            _vm._s(
              _vm.project.nomStructure[0] ||
                _vm.$t("mapDraw.legends.project.not-available")
            ) +
            " "
        )
      ]),
      _vm.role === "ENSEIGNANT" || _vm.role === "REFERENT"
        ? _c("div", [
            _c("strong", [
              _vm._v(_vm._s(_vm.$t("mapDraw.legends.project.referents")) + ":")
            ]),
            _c(
              "ul",
              _vm._l(_vm.displayedReferents, function(referent, index) {
                return _c("li", { key: index }, [
                  _vm._v(
                    " " +
                      _vm._s(referent.nom) +
                      " " +
                      _vm._s(referent.prenom) +
                      " (" +
                      _vm._s(referent.courriel) +
                      ") "
                  )
                ])
              }),
              0
            ),
            _vm.visibleReferents.length > 2
              ? _c("a", { on: { click: _vm.toggleReferents } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.showFullReferents
                          ? _vm.$t("mapDraw.legends.project.showLess")
                          : _vm.$t("mapDraw.legends.project.showMore")
                      ) +
                      " "
                  )
                ])
              : _vm._e(),
            _vm.visibleReferents.length === 0
              ? _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("mapDraw.legends.project.not-available")) +
                      " "
                  )
                ])
              : _vm._e()
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }