












































import { ProjetAnnuelDTO } from "@/model/bean/GeneratedDTOs"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import { ProjectService } from "@/services/rest/project/ProjectService"
import TabsDropdownSelector from "@/views/commons/TabsDropdownSelector.vue"
import InstructionMilieuxGeneriques from "@/views/instruction/InstructionMilieuxGeneriques.vue"
import InstructionMilieuxParticuliers from "@/views/instruction/InstructionMilieuxParticuliers.vue"
import InstructionObjetsRealisation from "@/views/instruction/InstructionObjetsRealisation.vue"
import InstructionThematiques from "@/views/instruction/InstructionThematiques.vue"
import { ValidationObserver } from "vee-validate"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"

@Component({
  components: {
    ValidationObserver,
    TabsDropdownSelector,
    InstructionThematiques,
    InstructionObjetsRealisation,
    InstructionMilieuxGeneriques,
    InstructionMilieuxParticuliers,
  },
})
export default class InstructionPannelLabellisationQualification extends Vue {
  @Prop() project: ProjetAnnuelDTO
  @Prop({ default: false }) isSaving: boolean
  @Prop({ default: false }) readOnly: boolean

  projectService = ProjectService.INSTANCE
  infoReportingService = InfoReportingService.INSTANCE
  internalProjetAnnuel = new ProjetAnnuelDTO()

  activeTab = 0
  tabs = [
    this.$t("instruction.thematiques"),
    this.$t("instruction.objets"),
    this.$t("instruction.milieu-generique"),
    this.$t("instruction.milieu-interet-particulier"),
  ]

  created(): void {
    this.refreshInternalDossierFromDossier()
  }

  changeTab(tabIndex: number): void {
    this.activeTab = tabIndex
  }

  @Watch("project")
  dossierInscriptionChanged(): void {
    this.refreshInternalDossierFromDossier()
  }

  refreshInternalDossierFromDossier(): void {
    this.internalProjetAnnuel = this.project
  }

  async save(): Promise<void> {
    try {
      this.internalProjetAnnuel = await this.projectService.updateQualifications(
        this.internalProjetAnnuel
      )
      this.infoReportingService.success(this.$t("projet.save-success").toString())
    } catch (e) {
      this.infoReportingService.error(this.$t("projet.save-error").toString(), e as Error)
    }
  }
}
