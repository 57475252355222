
































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { NavbarItem } from "@/views/menus/MenuItems"
import i18n from "@/i18n"

@Component({
  components: {},
})
export default class SubVerticalMenu extends Vue {
  @Prop() items: NavbarItem[]
  @Prop({ default: "" }) activeAnchor: string
  @Prop({ default: "" }) previousScreenName: string

  @Prop() menuExpanded: boolean
  @Prop() pendingRequestMember!: any
  @Prop() pendingRequest!: any
  @Prop() idGrae: string | undefined
  @Prop() role!: string
  @Prop() notifications: Array<any>

  toggleOpen = true
  allowedMenus = [
    "En bref",
    "Mon année",
    "Infos administratives",
    "Envie de renouveler la labellisation ? ",
    "Infos labellisation",
    "Infos renouvellement",
    "En détails",
    "Dites-nous en plus",
  ]

  labelPorteur = i18n.t("menu.ModifProjet").toString()
  countModifPorteurGrae = 0
  countUserGrae = 0

  @Watch("menuExpanded")
  menuExpansionChanged(): void {
    this.toggleOpen = this.menuExpanded
  }

  @Watch("notifications")
  setCoutModifPorteurGrae(){ 
  
    const notifs: Array<any> = Object.keys(this.notifications)
    const idGrae = this.$route.params.graeId ? this.$route.params.graeId : this.idGrae

    if(notifs.length > 0 && notifs.includes(idGrae)){ 
      Object.entries(this.notifications).forEach( notif => {
        if(notif[0] == idGrae) {
          this.countModifPorteurGrae = notif[1]
        }
      })
    }
  }

  toggleOpenMenu(): void {
    this.toggleOpen = !this.toggleOpen
    this.$emit("context-menu-expand", this.toggleOpen)
  }

  checkPendingRequest() {
    const url = new URL(window.location.href)
    const pathParts = url.pathname.split("/")
    const hasMember = pathParts.includes("grae")
    if (hasMember) {
      this.pendingRequest.users = this.pendingRequestMember.users
    }
  }

  shouldDisplayBadge(label: string): boolean {
    if (label === "Membres") {
      if (this.role === "ACCOMPAGNATEUR_NATIONAL") {
        this.checkPendingRequest()
        return this.pendingRequest.users > 0
      }
      if (this.role === "ANIMATEUR_REGIONAL") {
        return this.pendingRequest.users > 0
      }
    }
    return false
  }

  isDisplayPorteurProjet(label: string): boolean { 
    let display = false

    if(this.labelPorteur == label){
      display = this.countModifPorteurGrae > 0
    }

    return display
  }

  resetNotif(){
    const idGrae = this.$route.params.graeId
    this.$emit("reset-notif-grae", { idGrae: idGrae, countNotifGrae: this.countModifPorteurGrae })
    this.countModifPorteurGrae = 0;
  }
}
