



































































import { PaginationOrder, UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import { UsersService } from "@/services/rest/users/UsersService"
import AbstractTableView from "@/views/tables/AbstractTableView.vue"
import Column from "@/views/tables/Column"
import TableFilterView from "@/views/tables/TableFilterView.vue"
import { Component, Prop } from "vue-property-decorator"

@Component({
  components: {
    TableFilterView,
  },
})
export default class StructureReferentsView extends AbstractTableView {
  @Prop() structureId: string

  private usersService = UsersService.INSTANCE

  referentsList: Array<UtilisateurDTO> = []

  async reloadData(): Promise<void> {
    this.isLoading = true
    try {
      const paginatedReferentsList = await this.usersService.getReferents(
        this.structureId,
        this.currentPagedSortedParameter,
        this.activeFilters
      )
      this.referentsList = paginatedReferentsList.elements
      this.total = paginatedReferentsList.count
    } catch (e) {
      // Silent catch
    }
    this.isLoading = false
  }

  getInitialColumns(): Array<Column> {
    return [
      {
        field: "prenom",
        label: this.$t("structurePage.referents.prenom").toString(),
        sortable: true,
        backEndSearchable: true,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "nom",
        label: this.$t("structurePage.referents.nom").toString(),
        sortable: true,
        backEndSearchable: true,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "telephone",
        label: this.$t("structurePage.referents.telephone").toString(),
        sortable: true,
        backEndSearchable: true,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "courriel",
        label: this.$t("structurePage.referents.courriel").toString(),
        sortable: true,
        backEndSearchable: true,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
    ]
  }

  getDefaultSort(): Array<PaginationOrder> {
    const defaultSort = new PaginationOrder()
    defaultSort.clause = "nom"
    defaultSort.desc = true
    return [defaultSort]
  }

  showUserDetailsPage(event: Event, id: number): void {
    // Do not foward click event to row (would trigger modal)
    event.stopPropagation()
    //For APP to open ProfileModale
    this.$root.$emit("open-profile-modale", true, id)
  }
}
