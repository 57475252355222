



















































import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import H3TitleView from "@/views/commons/H3TitleView.vue"
import { ValidationObserver } from "vee-validate"
import { UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import { OrganismeService } from "@/services/rest/organisme/OrganismeService"
import GroupCheckboxesWithValidation from "@/views/inputs/GroupCheckBoxesWithValidation.vue"
import { UsersService } from "@/services/rest/users/UsersService"
import { InfoReportingService } from "@/services/log/InfoReportingService"

@Component({
  components: {
    H3TitleView,
    ValidationObserver,
    GroupCheckboxesWithValidation,
  },
})
export default class GRAEModale extends Vue {
  @Prop() open: boolean
  @Prop() user: UtilisateurDTO

  private organismeService = OrganismeService.INSTANCE
  private usersService = UsersService.INSTANCE
  private infoReportingService = InfoReportingService.INSTANCE

  graeListIds: Array<string> = []
  graeListRegions: Array<string> = []
  isLoading = false
  selectedGraesIds: string[] = []

  created(): void {
    this.init()
  }

  @Watch("open")
  onOpenModale(): void {
    if (this.open) {
      this.updateSelectedGraes()
    }
  }

  async init(): Promise<void> {
    this.isLoading = true
    try {
      const graeList = await this.organismeService.getAllGraes()
      this.graeListIds = graeList.map((g) => g.id)
      this.graeListRegions = graeList.map((g) => g.region)
      this.updateSelectedGraes()
    } catch (e) {
      // Silent catch
    }
    this.isLoading = false
  }

  updateSelectedGraes(): void {
    this.selectedGraesIds = []
    if (this.user.grae) {
      this.selectedGraesIds.push(this.user.grae.id)
    }
    if (this.user.instructedGraes && this.user.instructedGraes.length !== 0) {
      this.selectedGraesIds.push(...this.user.instructedGraes)
    }
  }

  async saveAndClose(): Promise<void> {
    // let newUser = this.user
    let refresh = false
    try {
      if (!this.user.id) {
        return
      }
      // newUser = await this.usersService.affectGraes(this.user.id, this.selectedGraesIds)
      await this.usersService.affectGraes(this.user.id, this.selectedGraesIds)
      refresh = true
      this.infoReportingService.success(this.$t("usersList.grae-modale-success").toString())
    } catch (e) {
      this.infoReportingService.error(this.$t("usersList.grae-modale-error").toString(), e as Error)
    }

    this.emitCloseModale(refresh)
  }

  emitCloseModale(refresh: boolean): void {
    this.$emit("close-modale", refresh)
  }
}
