var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "b-tabs",
        {
          attrs: { animated: false, type: "is-toggle" },
          model: {
            value: _vm.currentTabIndex,
            callback: function($$v) {
              _vm.currentTabIndex = $$v
            },
            expression: "currentTabIndex"
          }
        },
        [
          _c(
            "b-tab-item",
            {
              attrs: {
                value: "0",
                label: _vm.$t("graePage.details.projets.title")
              }
            },
            [
              _vm.activeTab == 0
                ? _c("GraeProjectsView", {
                    attrs: { graeId: _vm.grae ? _vm.grae.id : undefined }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-tab-item",
            {
              attrs: {
                value: "1",
                label: _vm.$t("graePage.details.etablissements.title")
              }
            },
            [
              _vm.activeTab == 1
                ? _c("GraeEtablissementsView", {
                    attrs: { graeId: _vm.grae ? _vm.grae.id : undefined }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-tab-item",
            {
              attrs: {
                value: "2",
                label: _vm.$t("graePage.details.structures.title")
              }
            },
            [
              _vm.activeTab == 2
                ? _c("GraeStructuresView", {
                    attrs: { graeId: _vm.grae ? _vm.grae.id : undefined }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }