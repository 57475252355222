var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationProvider", {
    attrs: {
      vid: (_vm.group != "" ? _vm.group + "_" : "") + _vm.vid,
      name: _vm.$attrs.name || _vm.$attrs.label,
      rules: _vm.rulesWithoutRequiredRulesIfValidationDisabled
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var errors = ref.errors
          return [
            _c(
              "b-field",
              {
                class: { "vertical-checkboxes": _vm.verticalDisplay },
                attrs: {
                  type: {
                    "is-danger": _vm.showValidationErrors && errors[0]
                  }
                }
              },
              [
                _c(
                  "template",
                  { slot: "label" },
                  [
                    _c("FormInputLabel", {
                      attrs: {
                        validationActivated: _vm.validationActivated,
                        showValidationErrors: _vm.showValidationErrors,
                        label: _vm.label,
                        isMandatory: _vm.isMandatory,
                        disabled: _vm.disabled,
                        inputNotice: _vm.inputNotice
                      }
                    })
                  ],
                  1
                ),
                _vm._l(_vm.data, function(option, i) {
                  return _c(
                    "div",
                    { key: option, staticClass: "field" },
                    [
                      _c(
                        "b-checkbox",
                        {
                          attrs: {
                            "native-value": option,
                            disabled: _vm.disabled
                          },
                          model: {
                            value: _vm.innerValue,
                            callback: function($$v) {
                              _vm.innerValue = $$v
                            },
                            expression: "innerValue"
                          }
                        },
                        [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.checkBoxLabels &&
                                  _vm.checkBoxLabels.length !== 0
                                  ? _vm.checkBoxLabels[i]
                                  : option
                              )
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                }),
                Boolean(_vm.inputLabel)
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "field GroupCheckboxesWithValidation-inputField"
                      },
                      [
                        _c(
                          "b-checkbox",
                          {
                            attrs: {
                              "native-value": _vm.inputLabel,
                              disabled: _vm.disabled
                            },
                            model: {
                              value: _vm.innerValue,
                              callback: function($$v) {
                                _vm.innerValue = $$v
                              },
                              expression: "innerValue"
                            }
                          },
                          [
                            _c("span", {
                              domProps: { innerHTML: _vm._s(_vm.inputLabel) }
                            })
                          ]
                        ),
                        _c("ValidationProvider", {
                          staticClass:
                            "GroupCheckboxesWithValidation-inputProvider",
                          attrs: {
                            vid:
                              (_vm.group != "" ? _vm.group + "_" : "") +
                              _vm.vid +
                              "_checkboxInput",
                            name: _vm.inputLabel,
                            rules:
                              "" +
                              (_vm.validationActivated &&
                              _vm.inputType === "is-danger"
                                ? "required"
                                : "")
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c(
                                      "b-field",
                                      { attrs: { type: _vm.inputType } },
                                      [
                                        _c("b-input", {
                                          attrs: {
                                            type: "textarea",
                                            placeholder: _vm.inputPlaceholder,
                                            disabled:
                                              _vm.disabled ||
                                              !_vm.innerValue.includes(
                                                _vm.inputLabel
                                              ),
                                            maxlength: 1400
                                          },
                                          on: { input: _vm.onInputChange },
                                          model: {
                                            value: _vm.inputValue,
                                            callback: function($$v) {
                                              _vm.inputValue = $$v
                                            },
                                            expression: "inputValue"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm.showValidationErrors &&
                                    errors.length > 0
                                      ? _c(
                                          "p",
                                          { staticClass: "help is-danger" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(errors[0]) + " "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              2
            ),
            _vm.showValidationErrors && errors.length > 0
              ? _c("p", { staticClass: "help is-danger" }, [
                  _vm.errorMessage.length > 0
                    ? _c("span", [_vm._v(" " + _vm._s(_vm.errorMessage))])
                    : _vm.showValidationErrors
                    ? _c("span", [_vm._v(" " + _vm._s(errors[0]) + " ")])
                    : _vm._e()
                ])
              : _vm._e()
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }