






































































import { Component, Prop, Vue } from "vue-property-decorator"
import TitleView from "@/views/commons/TitleView.vue"
import ContentView from "@/views/commons/ContentView.vue"
import H3TitleView from "@/views/commons/H3TitleView.vue"
import { EtablissementDTO, ProjetAnnuelDTO, UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import SingleEtablissement from "../organisme/SingleEtablissement.vue"
import EtablissementProjectCard from "./EtablissementProjectCard.vue"
import InputWithValidation from "../inputs/InputWithValidation.vue"
import { ValidationObserver } from "vee-validate"

@Component({
  components: {
    TitleView,
    ContentView,
    H3TitleView,
    SingleEtablissement,
    EtablissementProjectCard,
    InputWithValidation,
    ValidationObserver,
  },
})
export default class AskForProjectCreationView extends Vue {
  @Prop() loggedUser: UtilisateurDTO
  @Prop() projectsInEtablissement: ProjetAnnuelDTO[]
  @Prop() chosenEtablissement: EtablissementDTO

  isReferent = false
  projectName = ""
  projectDescription = ""

  created(): void {
    if (!this.projectsInEtablissement || this.projectsInEtablissement.length === 0) {
      this.$router.push("")
    }
    if (this.loggedUser.profil === "REFERENT") {
      this.isReferent = true
    }
  }

  askForCreation(): void {
    this.$emit("ask-project-creation", this.projectName, this.projectDescription)
  }

  cancelProjectCreation(): void {
    this.$emit("cancel-project-creation")
  }

  goPrevious(): void {
    this.$router.push("/myProjects/new")
  }
}
