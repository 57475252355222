








































































































import { Component, Prop } from "vue-property-decorator"
import ContentView from "@/views/commons/ContentView.vue"

import AbstractTableView from "@/views/tables/AbstractTableView.vue"
import TableFilterView from "@/views/tables/TableFilterView.vue"
import { ActualiteDTO, PaginationOrder, PaginationResult } from "@/model/bean/GeneratedDTOs"
import { LoginService } from "@/services/rest/login/LoginService"
import { GraeService } from "@/services/rest/grae/GraeService"
import Column from "@/views/tables/Column"
import { format } from "date-fns"
import ActualitesModalView from "@/views/grae/ActualitesModalView.vue"
import { InfoReportingService } from "@/services/log/InfoReportingService"

export const DATE_TIME_FORMAT = "DD/MM/YYYY"

@Component({
  components: {
    ContentView,
    TableFilterView,
    ActualitesModalView,
  },
})
export default class ActualitesView extends AbstractTableView {
  @Prop() graeId?: string

  private loginService = LoginService.INSTANCE
  private graeService = GraeService.INSTANCE
  private infoReportingService = InfoReportingService.INSTANCE

  isAnimateur = false
  isNational = false
  isObservateurNational = false
  actualites: ActualiteDTO[] = []
  openActualitesModal = false
  actualiteSelected: ActualiteDTO = new ActualiteDTO()

  created(): void {
    const loggedUser = this.loginService.getLoggedUser()

    if (!loggedUser) {
      return
    }

    this.isNational =
      loggedUser.profil === "SUPER_ADMINISTRATEUR" ||
      loggedUser.profil === "ACCOMPAGNATEUR_NATIONAL" ||
      loggedUser.profil === "OBSERVATEUR_NATIONAL"

    this.isAnimateur =
      this.isNational ||
      (loggedUser.profil === "ANIMATEUR_REGIONAL" &&
        ((loggedUser.grae !== undefined && loggedUser.grae.id === this.graeId) ||
          (loggedUser.instructedGraes &&
            this.graeId !== undefined &&
            loggedUser.instructedGraes.indexOf(this.graeId) !== -1)))

    this.isObservateurNational = loggedUser.profil === "OBSERVATEUR_NATIONAL"

    this.refreshInitialColumns()
  }

  async reloadData(): Promise<void> {
    this.isLoading = true
    try {
      let paginatedActualitesList: PaginationResult<ActualiteDTO> = new PaginationResult()

      if (this.graeId) {
        paginatedActualitesList = await this.graeService.getAllActualitesForGRAE(
          this.graeId,
          this.currentPagedSortedParameter,
          this.activeFilters
        )
      }

      if (!this.graeId && this.isNational) {
        paginatedActualitesList = await this.graeService.getActualitesNationalesOnly(
          this.currentPagedSortedParameter,
          this.activeFilters
        )
      }

      this.actualites = paginatedActualitesList.elements
      this.total = paginatedActualitesList.count
    } catch (e) {
      // Silent catch
    }
    this.isLoading = false
  }

  getInitialColumns(): Array<Column> {
    const initialColumns: Array<Column> = [
      {
        field: "dateDebutPublication",
        label: this.$t("actualitesPage.date-debut").toString(),
        sortable: true,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "dateFinPublication",
        label: this.$t("actualitesPage.date-fin").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "audience",
        label: this.$t("actualitesPage.audience").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "titre",
        label: this.$t("actualitesPage.actu-title").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "nomAuteur",
        label: this.$t("actualitesPage.author-name").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "prenomAuteur",
        label: this.$t("actualitesPage.author-surname").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "courrielAuteur",
        label: this.$t("actualitesPage.author-mail").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "dateCreation",
        label: this.$t("actualitesPage.date-creation").toString(),
        sortable: true,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
    ]

    if (this.isAnimateur && !this.isObservateurNational) {
      initialColumns.push({
        field: "action",
        label: this.$t("projetsListPage.actions").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      })
    }
    return initialColumns
  }

  getDefaultSort(): Array<PaginationOrder> {
    const defaultSort = new PaginationOrder()
    defaultSort.clause = "dateDebutPublication"
    defaultSort.desc = true
    return [defaultSort]
  }

  createNewActualite(): void {
    this.actualiteSelected = new ActualiteDTO()
    this.openActualitesModal = true
  }

  openAcualitesModal(event: Event, actualiteId: string): void {
    event.stopPropagation()
    this.actualiteSelected = this.actualites.find((a) => a.id === actualiteId) || new ActualiteDTO()
    this.openActualitesModal = true
  }

  formatDate(date: Date): string {
    if (date) {
      return format(date, DATE_TIME_FORMAT)
    }
    return ""
  }

  deleteActualite(event: Event, actuToDelete: ActualiteDTO): void {
    event.stopPropagation()
    this.infoReportingService.dialog(
      this.$t("graePage.confirmation").toString(),
      this.$t("actualitesPage.delete-message").toString(),
      this.$t("actualitesPage.confirm-delete").toString(),
      () => this.doDeleteActualite(actuToDelete),
      "is-danger"
    )
  }

  async doDeleteActualite(actuToDelete: ActualiteDTO): Promise<void> {
    try {
      if (actuToDelete.grae && this.isAnimateur) {
        await this.graeService.deleteActualiteForGRAE(actuToDelete, actuToDelete.grae)
      }

      if (!actuToDelete.grae && this.isNational) {
        await this.graeService.deleteActualiteNationale(actuToDelete)
      }
    } catch (e) {
      this.infoReportingService.error(this.$t("actualitesPage.error-delete").toString(), e as Error)
    }
    this.reloadData()
  }

  async closeActualitesModalAndRefresh(actualite: ActualiteDTO): Promise<void> {
    this.openActualitesModal = false
    try {
      if (actualite.id) {
        if (actualite.grae && this.isAnimateur) {
          await this.graeService.updateActualiteForGRAE(actualite, actualite.grae)
        }

        if (!actualite.grae && this.isNational) {
          await this.graeService.updateActualiteNationale(actualite)
        }
      } else {
        if (this.graeId && this.isAnimateur) {
          await this.graeService.createActualiteForGRAE(actualite, this.graeId)
        }

        if (!this.graeId && this.isNational) {
          await this.graeService.createActualiteNationale(actualite)
        }
      }
    } catch (e) {
      this.infoReportingService.error(this.$t("actualitesPage.error").toString(), e as Error)
    }
    this.reloadData()
  }
}
