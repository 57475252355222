var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("QuickFilterView", {
        attrs: { quickFilter: _vm.quickFilter },
        on: { "quick-filter-changed": _vm.quickFilterChanged }
      }),
      _c("TableFilterView", {
        attrs: {
          columns: _vm.columns,
          initialFilters: _vm.activeFilters,
          showExport: false
        },
        on: {
          "update-active-filters": _vm.updateActiveFilters,
          "update-visible-columns": _vm.updateVisibleColumns
        }
      }),
      _c(
        "b-table",
        {
          ref: _vm.tableRef,
          staticClass: "harmonisation-table",
          attrs: {
            striped: false,
            hoverable: false,
            "debounce-search": _vm.config.searchDebounce,
            "aria-next-label": _vm.config.nextLabel(),
            "aria-previous-label": _vm.config.previousLabel(),
            "aria-page-label": _vm.config.pageLabel(),
            "aria-current-label": _vm.config.currentPageLabel(),
            "per-page": _vm.config.perPage,
            "sticky-header": _vm.config.stickyHeader,
            "current-page": _vm.currentPagedSortedParameter.pageNumber,
            "pagination-position": _vm.config.paginationPosition,
            paginated: "",
            "backend-pagination": "",
            "sort-icon": _vm.config.sortIcon,
            "sort-icon-size": _vm.config.sortIconSize,
            "backend-sorting": "",
            "sort-multiple": "",
            "sort-multiple-data": _vm.buefySortingPriority,
            total: _vm.total,
            data: _vm.projects,
            loading: _vm.isLoading
          },
          on: {
            sort: _vm.onSort,
            "sorting-priority-removed": _vm.sortingPriorityRemoved,
            "page-change": _vm.onPageChange
          }
        },
        [
          _vm._l(_vm.columns, function(column) {
            return [
              _c(
                "b-table-column",
                _vm._b(
                  {
                    key: column.field,
                    attrs: {
                      visible: column.visible !== false,
                      "cell-class":
                        column.field == "evaluation.instructeur"
                          ? "harmonisation-cell"
                          : "standard-cell",
                      width:
                        column.field == "evaluation.instructeur"
                          ? 210
                          : column.field == "evaluation.mention"
                          ? 110
                          : column.field == "evaluation.commentaires"
                          ? 50
                          : column.field.indexOf("evaluation.") > -1
                          ? 80
                          : "auto"
                    },
                    scopedSlots: _vm._u(
                      [
                        column.field.indexOf("evaluation.") > -1
                          ? {
                              key: "header",
                              fn: function(ref) {
                                var column = ref.column
                                return [
                                  _vm._v(" " + _vm._s(column.label) + " ")
                                ]
                              }
                            }
                          : null,
                        {
                          key: "default",
                          fn: function(props) {
                            return [
                              _c("div", [
                                column.field ===
                                "etablissement.codeEtablissement"
                                  ? _c("div", [
                                      props.row["etablissements"]
                                        ? _c(
                                            "div",
                                            _vm._l(
                                              props.row[
                                                "etablissements"
                                              ].filter(function(o) {
                                                return o && o.id && o.nom
                                              }),
                                              function(organisme) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key:
                                                      props.row["id"] +
                                                      "_" +
                                                      organisme.codeEtablissement
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          organisme.codeEtablissement
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e()
                                    ])
                                  : _vm._e(),
                                column.field === "etablissement.name"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "clickable",
                                        on: {
                                          click: function($event) {
                                            return _vm.showProjectDetailsPage(
                                              $event,
                                              props.row["projetId"],
                                              props.row["id"]
                                            )
                                          }
                                        }
                                      },
                                      [
                                        props.row["etablissements"]
                                          ? _c(
                                              "div",
                                              _vm._l(
                                                props.row[
                                                  "etablissements"
                                                ].filter(function(o) {
                                                  return o && o.id && o.nom
                                                }),
                                                function(organisme) {
                                                  return _c(
                                                    "div",
                                                    { key: organisme.id },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            organisme.nom
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  : column.field === "projet.nom"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "clickable",
                                        on: {
                                          click: function($event) {
                                            return _vm.showProjectDetailsPage(
                                              $event,
                                              props.row["projetId"],
                                              props.row["id"]
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(props.row["nom"]) + " "
                                        )
                                      ]
                                    )
                                  : column.field === "typeDossier"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "clickable",
                                        on: {
                                          click: function($event) {
                                            return _vm.showProjectDetailsPage(
                                              $event,
                                              props.row["projetId"],
                                              props.row["id"]
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(props.row.typeDossier)
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  : column.field === "statut"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "clickable",
                                        on: {
                                          click: function($event) {
                                            return _vm.showProjectDetailsPage(
                                              $event,
                                              props.row["projetId"],
                                              props.row["id"]
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("StatutDossierTag", {
                                          attrs: {
                                            dossier: props.row,
                                            canSeeInstructionComment: "",
                                            loggedUser: _vm.loggedUser
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                column.field === "enseignant.name"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "clickable",
                                        on: {
                                          click: function($event) {
                                            return _vm.showProjectDetailsPage(
                                              $event,
                                              props.row["projetId"],
                                              props.row["id"]
                                            )
                                          }
                                        }
                                      },
                                      _vm._l(
                                        props.row["enseignants"].filter(
                                          function(u) {
                                            return u && u.id && u.nom
                                          }
                                        ),
                                        function(utilisateur, i) {
                                          return _c(
                                            "div",
                                            { key: i + "_" + utilisateur.id },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    utilisateur.prenom +
                                                      " " +
                                                      utilisateur.nom
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                column.field === "referent.name"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "clickable",
                                        on: {
                                          click: function($event) {
                                            return _vm.showProjectDetailsPage(
                                              $event,
                                              props.row["projetId"],
                                              props.row["id"]
                                            )
                                          }
                                        }
                                      },
                                      _vm._l(
                                        props.row["referents"].filter(function(
                                          u
                                        ) {
                                          return u && u.id && u.nom
                                        }),
                                        function(utilisateur, i) {
                                          return _c(
                                            "div",
                                            { key: i + "_" + utilisateur.id },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    utilisateur.prenom +
                                                      " " +
                                                      utilisateur.nom
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : column.field == "evaluation.instructeur"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "instructeur-cell-column"
                                      },
                                      [
                                        _c(
                                          "LabellisationHarmonisationForProjectView",
                                          {
                                            attrs: {
                                              projectAnnuel: props.row,
                                              evaluations:
                                                props.row["evaluations"],
                                              loggedUser: _vm.loggedUser,
                                              allGraeInstructeurs:
                                                _vm.allGraeInstructeurs
                                            },
                                            on: {
                                              "evaluations-changed": function(
                                                $event
                                              ) {
                                                return _vm.evaluationChanged(
                                                  props.row,
                                                  $event
                                                )
                                              },
                                              "reload-data": function($event) {
                                                return _vm.reloadData()
                                              }
                                            }
                                          }
                                        )
                                      ],
                                      1
                                    )
                                  : column.field.indexOf("evaluation.mention") >
                                    -1
                                  ? _c("div", { staticClass: "mention-cell" })
                                  : column.field.indexOf(
                                      "evaluation.commentaires"
                                    ) > -1
                                  ? _c("div", { staticClass: "comment-cell" })
                                  : column.field.indexOf("evaluation.") > -1
                                  ? _c("div", { staticClass: "note-cell" })
                                  : column.field === "dateModification"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "clickable",
                                        on: {
                                          click: function($event) {
                                            return _vm.showProjectDetailsPage(
                                              $event,
                                              props.row["projetId"],
                                              props.row["id"]
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatDate(
                                                props.row[column.field]
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                column.field === "natureAireEducative"
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            props.row["natureAireEducative"]
                                          ) +
                                          " "
                                      )
                                    ])
                                  : _vm._e(),
                                column.field === "codeDepartement"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "clickable",
                                        on: {
                                          click: function($event) {
                                            return _vm.showProjectDetailsPage(
                                              $event,
                                              props.row["projetId"],
                                              props.row["id"]
                                            )
                                          }
                                        }
                                      },
                                      [
                                        props.row["etablissements"].length
                                          ? _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    props.row[
                                                      "etablissements"
                                                    ][0].codeDepartement.replace(
                                                      /^0+/,
                                                      ""
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e(),
                                column.field === "ville"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "clickable",
                                        on: {
                                          click: function($event) {
                                            return _vm.showProjectDetailsPage(
                                              $event,
                                              props.row["projetId"],
                                              props.row["id"]
                                            )
                                          }
                                        }
                                      },
                                      [
                                        props.row["etablissements"].length
                                          ? _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    props.row[
                                                      "etablissements"
                                                    ][0].ville
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e(),
                                column.field === "structure.name"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "clickable",
                                        on: {
                                          click: function($event) {
                                            return _vm.showProjectDetailsPage(
                                              $event,
                                              props.row["projetId"],
                                              props.row["id"]
                                            )
                                          }
                                        }
                                      },
                                      [
                                        props.row["structures"]
                                          ? _c(
                                              "div",
                                              _vm._l(
                                                props.row["structures"].filter(
                                                  function(o) {
                                                    return o && o.id && o.nom
                                                  }
                                                ),
                                                function(organisme) {
                                                  return _c(
                                                    "div",
                                                    { key: organisme.id },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            organisme.nom
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e(),
                                !column.custom
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "clickable",
                                        on: {
                                          click: function($event) {
                                            return _vm.showProjectDetailsPage(
                                              $event,
                                              props.row["projetId"],
                                              props.row["id"]
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(props.row[column.field])
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  "b-table-column",
                  column,
                  false
                )
              )
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }