






















































import { EchangeDTO } from "@/model/bean/GeneratedDTOs"
import { DATE_TIME_FORMAT } from "@/views/grae/ActualitesView.vue"
import { Component, Prop, Vue } from "vue-property-decorator"
import { format } from "date-fns"
import H3TitleView from "@/views/commons/H3TitleView.vue"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import DatePickerWithValidation from "@/views/inputs/DatePickerWithValidation.vue"

@Component({
  components: {
    H3TitleView,
    InputWithValidation,
    DatePickerWithValidation,
  },
})
export default class CommentaireModalView extends Vue {
  @Prop() commentaire: EchangeDTO
  @Prop() open: boolean

  emitCloseModale(): void {
    this.$emit("close")
  }

  formatDate(date: Date): string {
    if (date) {
      return format(date, DATE_TIME_FORMAT)
    }
    return ""
  }
}
