var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "projet-part" },
    [
      _c("h2", [
        _vm._v(_vm._s(_vm.$t("projetPage.infoAdministratives.charte.titre")))
      ]),
      _vm.validationActivated &&
      _vm.projetAnnuel.charteFilePresent &&
      !_vm.projetAnnuel.charteFileValid
        ? _c(
            "b-message",
            { attrs: { type: "is-warning" } },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("projetPage.infoAdministratives.charte.unvalid")
                  ) +
                  " "
              ),
              _c("InputWithValidation", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false"
                  }
                ],
                attrs: { vid: "charte_unvalid", rules: "required" }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticStyle: { display: "flex" } },
        [
          _c("BookmarkCard", {
            staticClass: "without-footer grey-header",
            attrs: { tagType: _vm.tagType, iconName: _vm.iconNameSignature },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function() {
                  return [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("projetPage.infoAdministratives.charte.link")
                          ) +
                          " "
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://ame.ofb.fr/doku.php?id=les_chartes",
                            target: "_blank",
                            rel: "noopener"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "projetPage.infoAdministratives.charte.linkTitle"
                              )
                            )
                          )
                        ]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "projetPage.infoAdministratives.charte.description"
                            )
                          ) +
                          " "
                      )
                    ])
                  ]
                },
                proxy: true
              }
            ])
          }),
          _c("BookmarkCard", {
            staticClass: "without-footer",
            attrs: {
              tagType: _vm.tagType,
              iconName: _vm.iconNameValid,
              iconType: "is-success"
            },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function() {
                  return [
                    _c(
                      "ValidationObserver",
                      { ref: "charteObserver" },
                      [
                        _c("FileWithValidation", {
                          attrs: {
                            validationActivated: _vm.validationActivated,
                            showValidationErrors: _vm.showValidationErrors,
                            rules:
                              _vm.projetAnnuel.typeDossier ===
                              "DOSSIER_RENOUVELLEMENT_LIGHT"
                                ? ""
                                : "required",
                            inputNotice: _vm.$t(
                              "projetPage.infoAdministratives.charte.signee"
                            ),
                            placeholder: _vm.$t(
                              "projetPage.infoAdministratives.charte.televerser"
                            ),
                            fileOperationUrl: _vm.charteFileUrl,
                            value: _vm.projetAnnuel.charteFilePresent,
                            vid: "charte_ProjetAnnuel",
                            disabled: _vm.readonly,
                            defaultFileName: "CHARTE_ENGAGEMENT.pdf"
                          },
                          on: {
                            "add-file": _vm.addCharte,
                            "delete-file": _vm.deleteCharte,
                            "file-uploaded-or-deleted":
                              _vm.charteUploadedOrDeleted
                          }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }