var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-message", { attrs: { type: "is-info" } }, [
    _c("p", [_vm._v(" " + _vm._s(this.$t("projet.doc.default-text")) + " ")]),
    _c(
      "ul",
      _vm._l(_vm.links, function(link) {
        return _c("li", { key: link.url }, [
          _c("a", { attrs: { href: _vm.$t(link.url), target: "doc" } }, [
            _vm._v(" " + _vm._s(_vm.$t(link.text)))
          ])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }