/*-
 * #%L
 * Aires Éducatives
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
import {
  ActualiteDTO,
  AireEducativeCartoDTO,
  AireEducativeCartoDetailsDTO,
  EtablissementDTO,
  FilterDTO,
  GraeDTO,
  PaginationParameter,
  PaginationResult,
  ProjetAnnuelDTO,
  StructureDTO,
  UtilisateurDTO,
  ModificationsPPResponseDTO,
} from "@/model/bean/GeneratedDTOs"
import ConversionUtils from "@/services/ConversionUtils"
import { AbstractTypedRestService } from "@/services/rest/AbstractTypedRestService"

export class GraeService extends AbstractTypedRestService<GraeDTO> {
  static INSTANCE = new GraeService()

  async getDemandesCreation(graeId: string): Promise<ProjetAnnuelDTO[]> {
    const urlPath = "grae/" + graeId + "/creations"
    const projets = await super.typedGetV2<ProjetAnnuelDTO>(urlPath, 2)
    return projets
  }

  async getModifsPP(idGrae: string): Promise<ModificationsPPResponseDTO> {
    let urlPath = ""
    if (idGrae) {
      urlPath = "/projets/modifications-pp-grae?idGrae=" + idGrae
    } else {
      urlPath = "/projets/all-modifications-pp"
    }

    const list = await super.typedGetV2<ModificationsPPResponseDTO>(urlPath, 2)
    const response: ModificationsPPResponseDTO = list[0];
    return response
  }

  async getEtablissements(
    graeId: string | undefined,
    paginationParameters: PaginationParameter,
    filtersList: Array<FilterDTO>
  ): Promise<PaginationResult<EtablissementDTO>> {
    const params = new Map<string, string | Array<any>>()
    if (graeId) params.set("graeId", graeId)
    const etablissements = await super.typeGetPaginatedV2<EtablissementDTO>(
      "grae/etablissements",
      2,
      paginationParameters,
      filtersList,
      params
    )
    return etablissements
  }
  async getAllEtablissements(): Promise<Array<AireEducativeCartoDTO>> {
    const params = new Map<string, string | Array<any>>()
    const projets = await super.typedGetV2<AireEducativeCartoDTO>("projets/carto-init", 2, params)
    return projets
  }

  async getProjectDetails(projectId: string): Promise<AireEducativeCartoDetailsDTO> {
    const projet = await super.typedGetV2<AireEducativeCartoDetailsDTO>(
      "projets/carto-details/" + projectId,
      2
    )

    // Ensure projet is not an array, or take the first element if it's an array
    const singleProjet = Array.isArray(projet) ? projet[0] : projet

    return singleProjet
  }

  async getStructures(
    graeId: string | undefined,
    paginationParameters: PaginationParameter,
    filtersList: Array<FilterDTO>
  ): Promise<PaginationResult<StructureDTO>> {
    const params = new Map<string, string | Array<any>>()
    if (graeId) params.set("graeId", graeId)
    const structures = await super.typeGetPaginatedV2<StructureDTO>(
      "grae/structures",
      2,
      paginationParameters,
      filtersList,
      params
    )
    return structures
  }

  async updateNote(grae: GraeDTO): Promise<GraeDTO> {
    return await super.typedPutV2<GraeDTO>("grae/" + grae.id + "/note", 2, grae)
  }

  async updateGrae(grae: GraeDTO): Promise<GraeDTO> {
    return await super.typedPostV2<GraeDTO>("grae/" + grae.id, 2, grae)
  }

  async resetGraeParametrage(graeId: string): Promise<GraeDTO> {
    return await super.typedPostV2<GraeDTO>("grae/" + graeId + "/reset-dates", 2, new GraeDTO())
  }

  async getMembers(
    graeId: string,
    paginationParameters: PaginationParameter,
    filtersList: Array<FilterDTO>
  ): Promise<PaginationResult<UtilisateurDTO>> {
    const urlPath = "grae/" + graeId + "/users"
    const members = await super.typeGetPaginatedV2<UtilisateurDTO>(
      urlPath,
      2,
      paginationParameters,
      filtersList
    )
    return members
  }

  async getDemandesCreationCompte(graeId: string): Promise<Array<UtilisateurDTO>> {
    // If we want to paginate this one day
    // Add a paginationParameters: PaginationParameter parameters - see UserService.getAllUser()
    const paginationParameters = new PaginationParameter()
    // We want pageNumber 0, but AbstractRestService operates a -1
    paginationParameters.pageNumber = 1
    paginationParameters.pageSize = -1

    const urlPath = "grae/" + graeId + "/creation-comptes"
    const members = await super.typeGetPaginatedV2<UtilisateurDTO>(
      urlPath,
      2,
      paginationParameters,
      []
    )
    return members.elements
  }

  async getContact(graeId: string): Promise<UtilisateurDTO> {
    return await super.typedGetSingleV2<UtilisateurDTO>("grae/" + graeId + "/contact", 2)
  }

  async getInstructeurs(graeId: string): Promise<UtilisateurDTO[]> {
    return super.typedGetV2("/instructeurs/" + graeId, 2)
  }

  async getActualitesNationalesOnly(
    paginationParameters: PaginationParameter,
    filtersList: Array<FilterDTO>
  ): Promise<PaginationResult<ActualiteDTO>> {
    const paginatedResult = await super.typeGetPaginatedV2(
      "actualites",
      2,
      paginationParameters,
      filtersList
    )
    paginatedResult.elements = paginatedResult.elements.map((actu) =>
      ConversionUtils.convertActualiteDatesToUTC(actu as ActualiteDTO)
    )
    return paginatedResult as PaginationResult<ActualiteDTO>
  }

  async createActualiteNationale(actualite: ActualiteDTO): Promise<ActualiteDTO> {
    return super.typedPostV2("actualites", 2, ConversionUtils.convertActualiteDatesToUTC(actualite))
  }

  async updateActualiteNationale(actualite: ActualiteDTO): Promise<ActualiteDTO> {
    return super.typedPutV2(
      "actualites/" + actualite.id,
      2,
      ConversionUtils.convertActualiteDatesToUTC(actualite)
    )
  }

  async deleteActualiteNationale(actualite: ActualiteDTO): Promise<ActualiteDTO> {
    return super.typedDeleteSingleV2("actualites/" + actualite.id, 2)
  }

  async getAllActualitesForGRAE(
    graeId: string,
    paginationParameters: PaginationParameter,
    filtersList: Array<FilterDTO>
  ): Promise<PaginationResult<ActualiteDTO>> {
    const paginatedResult = await super.typeGetPaginatedV2(
      "grae/" + graeId + "/actualites",
      2,
      paginationParameters,
      filtersList
    )
    paginatedResult.elements = paginatedResult.elements.map((actu) =>
      ConversionUtils.convertActualiteDatesToUTC(actu as ActualiteDTO)
    )
    return paginatedResult as PaginationResult<ActualiteDTO>
  }

  async createActualiteForGRAE(actualite: ActualiteDTO, graeId: string): Promise<ActualiteDTO> {
    return super.typedPostV2(
      "grae/" + graeId + "/actualites",
      2,
      ConversionUtils.convertActualiteDatesToUTC(actualite)
    )
  }

  async updateActualiteForGRAE(actualite: ActualiteDTO, graeId: string): Promise<ActualiteDTO> {
    return super.typedPutV2(
      "grae/" + graeId + "/actualites/" + actualite.id,
      2,
      ConversionUtils.convertActualiteDatesToUTC(actualite)
    )
  }

  async deleteActualiteForGRAE(actualite: ActualiteDTO, graeId: string): Promise<ActualiteDTO> {
    return super.typedDeleteSingleV2("grae/" + graeId + "/actualites/" + actualite.id, 2)
  }

  async getAnneesScolairesForGRAE(graeId: string): Promise<string[]> {
    return super.typedGetV2("grae/" + graeId + "/annees", 2)
  }

  async getAnneesScolaires(): Promise<string[]> {
    return super.typedGetV2("grae/annees", 2)
  }
}
