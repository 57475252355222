








import { Component, Vue } from "vue-property-decorator"
import PageView from "@/views/commons/PageView.vue"
import ContentView from "@/views/commons/ContentView.vue"

@Component({
  components: { PageView, ContentView },
})
export default class NotFound404Page extends Vue {}
