import { EtatProjet, ObjectConcretRealisation, Thematique } from "./GeneratedDTOs"
/*-
 * #%L
 * Aires Éducatives
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
import i18n from "@/i18n"
import {
  ProfilUtilisateur,
  TypeStructure,
  NatureAireEducative,
  NatureSite,
  StatutDossier,
  TypeDossier,
  StatutActivationUtilisateur,
  MilieuGenerique,
  MilieuInteretParticulier,
  AutorisationProprietaire,
} from "@/model/bean/GeneratedDTOs"

export class EnumHelper {
  static allTypesStructure: TypeStructure[] = [
    "ASSOCIATION",
    "COLLECTIVITE",
    "CONSERVATOIRE_ESPACES_NATURELS",
    "CONSERVATOIRE_LITTORAL",
    "PARC_NATIONAL",
    "PARC_NATUREL_MARIN",
    "PARC_NATUREL_REGIONAL",
    "RESERVE_BIOLOGIQUE",
    "RESERVE_BIOSPHERE",
    "RESERVE_NATURELLE_CHASSE_FAUNE",
    "RESERVE_NATURELLE_CORSE",
    "RESERVE_NATURELLE_NATIONALE",
    "RESERVE_NATURELLE_REGIONALE",
    "SITE_NATURA_2000",
    "SYNDICAT_MIXTE",
    "ENTREPRISE_PRIVEE",
  ]

  static allProfilsUtilisateur: ProfilUtilisateur[] = [
    "SUPER_ADMINISTRATEUR",
    "ACCOMPAGNATEUR_NATIONAL",
    "OBSERVATEUR_NATIONAL",
    "ACCOMPAGNATEUR_REGIONAL",
    "ANIMATEUR_REGIONAL",
    "REFERENT",
    "ENSEIGNANT",
  ]

  static allNatures: NatureAireEducative[] = ["AME", "ATE"]

  static allNaturesSite: NatureSite[] = ["PUBLIC", "PRIVE"]

  static allProprietaires: AutorisationProprietaire[] = ["OUI", "NON", "NONNECESSAIRE"]

  static allStatusWithoutArchivedAndPassable: StatutDossier[] = [
    "BROUILLON",
    "EN_ATTENTE",
    "INSTRUCTION_EN_COURS",
    "INSTRUCTION_TERMINEE",
    "REFUSE",
    "LABELLISATION_EN_DEVENIR",
    "ACCEPTE_SOUS_RESERVE_REFERENT",
    "ACCEPTE",
  ]

  static allStatus: StatutDossier[] = EnumHelper.allStatusWithoutArchivedAndPassable.concat(
    "ARCHIVE",
    "AVIS_PASSABLE"
  )

  static allTypeDossiers: TypeDossier[] = [
    "DOSSIER_INSCRIPTION",
    "DOSSIER_LABELLISATION",
    "DOSSIER_RENOUVELLEMENT_LIGHT",
    "DOSSIER_RENOUVELLEMENT",
  ]

  static allStatusActivation: StatutActivationUtilisateur[] = [
    "ACTIF",
    "EMAIL_A_VALIDER",
    "INSCRIPTION_A_VALIDER",
    "NON_INSCRIT",
  ]

  static allMilieuxGenerique: MilieuGenerique[] = [
    "ZONES_COTIERES",
    "HABITATS_MARINS",
    "FLEUVES_LACS_RIVIERES",
    "ZONES_HUMIDES",
    "MONTAGNES",
    "FORETS",
    "PRAIRIES_ESPACES_CULTIVES",
    "NATURE_EN_VILLE",
    "HABITATS_CONTINENTAUX",
  ]

  static allMilieuxInteretParticulier: MilieuInteretParticulier[] = [
    "AFFLEUREMENT_ROCHEUX",
    "BORDURE_ZONE_AGRICOLE",
    "COURS_D_EAU",
    "ESTRAN_PLAGE",
    "ESTUAIRES_LAGUNES",
    "FRICHES",
    "GROTTES",
    "HAIES",
    "LANDES",
    "MANGROVE",
    "MARAIS",
    "PARC_URBAIN_JARDINS_PARTAGES",
    "PLAN_D_EAU",
    "PRAIRIES",
    "RECIF_CORALLIEN",
    "RELIEF_VOLCANIQUE_RECENT",
    "TOURBIERES",
  ]

  static allThematiques: Thematique[] = [
    "ACTIVITES_HUMAINES_COMMERCIALES",
    "ACTIVITES_HUMAINES_LOISIR",
    "BIODIVERSITE_ESPECES",
    "BIODIVERSITE_HABITATS_MILIEUX_NATURELS",
    "CHANGEMENT_CLIMATIQUE",
    "ENERGIE",
    "ESPECES_ENVAHISSANTES",
    "ESPECES_EXPLOITEES",
    "FONCTIONNEMENT_ECOSYSTEME",
    "GEOLOGIE",
    "HISTOIRE",
    "PATRIMOINE_CULTUREL",
    "POLLUTION",
  ]

  static allObjetsRealisation: ObjectConcretRealisation[] = [
    "AMENAGEMENTS",
    "GESTION_ESPECES_HABITATS",
    "OPERATION_DE_COMMUNICATION",
    "PRODUCTION_ARTISTIQUE",
    "PRODUCTION_SCIENTIFIQUE",
    "SOUMISSION_DE_PROPOSITIONS",
  ]

  static allEtatsProjets: EtatProjet[] = ["EN_COURS_DE_CREATION", "ACTIF", "EN_PAUSE", "ARCHIVE"]
}

export const ENTRAVES = {
  AUCUNE: "AUCUNE",
  FINANCEMENT: "FINANCEMENT",
  ANNULATION_METEO: "ANNULATION_METEO",
  DIFFICULTES_MAIRIE: "DIFFICULTES_MAIRIE",
  TENSION_USAGERS_AIRE: "TENSION_USAGERS_AIRE",
  DIFFICULTES_ADMINISTRATIVES: "DIFFICULTES_ADMINISTRATIVES",
  ABSENCE_LONG_TERME: "ABSENCE_LONG_TERME",
  MAUVAISE_ESTIMATION: "MAUVAISE_ESTIMATION",
  AUTRE: "AUTRE",
}

export const BOOLEAN = {
  OUI: true,
  NON: false,
}

export const TRILEAN = {
  YES: "YES",
  NO: "NO",
  MAYBE: "MAYBE",
}

export const STATUT_ETABLISSEMENT = {
  PRIVE: "Privé",
  PUBLIC: "Public",
  AUTRE: "-",
}

export const PROFIL_UTILISATEUR = {
  SUPER_ADMINISTRATEUR: "SUPER_ADMINISTRATEUR",
  ACCOMPAGNATEUR_NATIONAL: "ACCOMPAGNATEUR_NATIONAL",
  OBSERVATEUR_NATIONAL: "OBSERVATEUR_NATIONAL",
  ACCOMPAGNATEUR_REGIONAL: "ACCOMPAGNATEUR_REGIONAL",
  ANIMATEUR_REGIONAL: "ANIMATEUR_REGIONAL",
  REFERENT: "REFERENT",
  ENSEIGNANT: "ENSEIGNANT",
}

export const MATIERES = {
  ARTS_APPLIQUES: "ARTS_APPLIQUES",
  ARTS_PLASTIQUES: "ARTS_PLASTIQUES",
  BIOTECHNOLOGIES: "BIOTECHNOLOGIES",
  DESIGN_ET_METIERS_ARTS: "DESIGN_ET_METIERS_ARTS",
  DOCUMENTATION: "DOCUMENTATION",
  ECONOMIE_GESTION: "ECONOMIE_GESTION",
  EDUCATION_MUSICALE: "EDUCATION_MUSICALE",
  EDUCATION_PHYSIQUE_SPORTIVE: "EDUCATION_PHYSIQUE_SPORTIVE",
  ESTHETIQUE_COSMETIQUE: "ESTHETIQUE_COSMETIQUE",
  HISTOIRE_GEOGRAPHIE: "HISTOIRE_GEOGRAPHIE",
  HOTELLERIE_RESTAURATION: "HOTELLERIE_RESTAURATION",
  INDUSTRIES_GRAPHIQUES: "INDUSTRIES_GRAPHIQUES",
  LANGUE_DES_SIGNES: "LANGUE_DES_SIGNES",
  LANGUE_VIVANTE_ALLEMAND: "LANGUE_VIVANTE_ALLEMAND",
  LANGUE_VIVANTE_ANGLAIS: "LANGUE_VIVANTE_ANGLAIS",
  LANGUE_VIVANTE_ARABE: "LANGUE_VIVANTE_ARABE",
  LANGUE_VIVANTE_CHINOIS: "LANGUE_VIVANTE_CHINOIS",
  LANGUE_VIVANTE_ESPAGNOL: "LANGUE_VIVANTE_ESPAGNOL",
  LANGUE_VIVANTE_HEBREU: "LANGUE_VIVANTE_HEBREU",
  LANGUE_VIVANTE_ITALIEN: "LANGUE_VIVANTE_ITALIEN",
  LANGUE_VIVANTE_JAPONAIS: "LANGUE_VIVANTE_JAPONAIS",
  LANGUE_VIVANTE_NEERLANDAIS: "LANGUE_VIVANTE_NEERLANDAIS",
  LANGUE_VIVANTE_PORTUGAIS: "LANGUE_VIVANTE_PORTUGAIS",
  LANGUE_VIVANTE_RUSSE: "LANGUE_VIVANTE_RUSSE",
  LANGUE_REGIONALE_BASQUE: "LANGUE_REGIONALE_BASQUE",
  LANGUE_REGIONALE_BRETON: "LANGUE_REGIONALE_BRETON",
  LANGUE_REGIONALE_CATALAN: "LANGUE_REGIONALE_CATALAN",
  LANGUE_REGIONALE_CORSE: "LANGUE_REGIONALE_CORSE",
  LANGUE_REGIONALE_CREOLE: "LANGUE_REGIONALE_CREOLE",
  LANGUE_REGIONALE_KANAK: "LANGUE_REGIONALE_KANAK",
  LANGUE_REGIONALE_OCCITAN_LANGUE_OC: "LANGUE_REGIONALE_OCCITAN_LANGUE_OC",
  LANGUE_REGIONALE_TAHITIEN: "LANGUE_REGIONALE_TAHITIEN",
  LETTRES_ANCIENNES: "LETTRES_ANCIENNES",
  LETTRES_MODERNES: "LETTRES_MODERNES",
  MATHEMATIQUES: "MATHEMATIQUES",
  NUMERIQUE_SCIENCES_INFORMATIQUES: "NUMERIQUE_SCIENCES_INFORMATIQUES",
  PHILOSOPHIE: "PHILOSOPHIE",
  PHYSIQUE_CHIMIE: "PHYSIQUE_CHIMIE",
  SCIENCES_VIE_TERRE: "SCIENCES_VIE_TERRE",
  SCIENCES_ECONOMIQUES_SOCIALES: "SCIENCES_ECONOMIQUES_SOCIALES",
  SCIENCES_TECHNIQUES_MEDICO_SOCIALES: "SCIENCES_TECHNIQUES_MEDICO_SOCIALES",
  SCIENCES_INDUSTRIELLES_INGENIEUR: "SCIENCES_INDUSTRIELLES_INGENIEUR",
  AUTRE: "AUTRE",
}

export const TYPE_STRUCTURE = {
  ASSOCIATION: "ASSOCIATION",
  RESERVE_NATURELLE_CORSE: "RESERVE_NATURELLE_CORSE",
  PARC_NATIONAL: "PARC_NATIONAL",
  PARC_NATUREL_MARIN: "PARC_NATUREL_MARIN",
  COLLECTIVITE: "COLLECTIVITE",
  CONSERVATOIRE_ESPACES_NATURELS: "CONSERVATOIRE_ESPACES_NATURELS",
  CONSERVATOIRE_LITTORAL: "CONSERVATOIRE_LITTORAL",
  PARC_NATUREL_REGIONAL: "PARC_NATUREL_REGIONAL",
  RESERVE_BIOSPHERE: "RESERVE_BIOSPHERE",
  RESERVE_NATURELLE_CHASSE_FAUNE: "RESERVE_NATURELLE_CHASSE_FAUNE",
  RESERVE_BIOLOGIQUE: "RESERVE_BIOLOGIQUE",
  RESERVE_NATURELLE_NATIONALE: "RESERVE_NATURELLE_NATIONALE",
  RESERVE_NATURELLE_REGIONALE: "RESERVE_NATURELLE_REGIONALE",
  SITE_NATURA_2000: "SITE_NATURA_2000",
  AUTRE: "AUTRE",
  SYNDICAT_MIXTE: "SYNDICAT_MIXTE",
  ENTREPRISE_PRIVEE: "ENTREPRISE_PRIVEE",
}

export const REGISTER_STEPS = {
  USER: "user",
  PROFIL: "profil",
  STRUCTURE_SELECTION: "structureSelection",
  STRUCTURE_ADDITION: "structureAddition",
  ETABLISSEMENT_SELECTION: "etablissementSelection",
  ETABLISSEMENT_ADDITION: "etablissementAddition",
  REGION: "region",
  FONCTION: "fonction",
  INSCRIPTION: "inscription",
  COMPETENCES: "Competences",
}

export const TETES_STRINGS = {
  CEN: i18n.t("StructureSelectionView.label-CEN").toString(),
  FCPN: i18n.t("StructureSelectionView.label-FCPN").toString(),
  FNE: i18n.t("StructureSelectionView.label-FNE").toString(),
  FPNR: i18n.t("StructureSelectionView.label-FPNR").toString(),
  LPO: i18n.t("StructureSelectionView.label-LPO").toString(),
  OFB: i18n.t("StructureSelectionView.label-OFB").toString(),
  ONF: i18n.t("StructureSelectionView.label-ONF").toString(),
  PNF: i18n.t("StructureSelectionView.label-PNF").toString(),
  REN: i18n.t("StructureSelectionView.label-REN").toString(),
  RGF: i18n.t("StructureSelectionView.label-RGF").toString(),
  RNF: i18n.t("StructureSelectionView.label-RNF").toString(),
  UICN: i18n.t("StructureSelectionView.label-UICN").toString(),
  UNCPIE: i18n.t("StructureSelectionView.label-UNCPIE").toString(),
  AUTRE: i18n.t("StructureSelectionView.label-AUTRE").toString(),
}

export const TAG_TYPES_FOR_BOOKMARK_CARD = {
  INVITATION: "invitation",
  FINANCEMENT: "financement",
  NOUVEAU_FINANCEMENT: "nouveau-financement",
  CLASSE: "classe",
  NOUVELLE_CLASSE: "nouvelle-classe",
  CLASSES_ENGAGEES: "classes-engagees",
  PROJECT: "projet",
  JOIN: "join",
  VALIDATION: "validation",
  RECAP: "recap",
  CHARTE_ENGAGEMENT: "charte-engagement",
  ACTUALITE_REGIONALE: "actualite-regionale",
  ACTUALITE_NATIONALE: "actualite-nationale",
  NOUVEAU_CONSEIL: "nouveau-conseil",
  CONSEIL_DES_ELEVES: "conseil-des-eleves",
}

export const INVITATION_STATUS = {
  PENDING: "pending",
  DECLINED: "declined",
  ACCEPTED: "accepted",
}

export const TEMPS_PASSE = {
  HEBDO: "HEBDO",
  BI_HEBDO: "BI_HEBDO",
  MENSUEL: "MENSUEL",
  TRIMESTRIEL: "TRIMESTRIEL",
}

export const CURSUS = {
  ELEMENTAIRE: {
    eleves_cp: "CP",
    eleves_ce1: "CE1",
    eleves_ce2: "CE2",
    eleves_cm1: "CM1",
    eleves_cm2: "CM2",
  },
  COLLEGE: {
    eleves_6eme: "6E",
    eleves_5eme: "5E",
    eleves_4eme: "4E",
    eleves_3eme: "3E",
  },
  LYCEE: {
    eleves_2nde: "2ND",
    eleves_1ere: "1E",
    eleves_terminale: "TERMINALE",
  },
}

export const ENSEIGNEMENTS = ["ELEMENTAIRE", "COLLEGE", "LYCEE"]

export const NIVEAUX = {
  eleves_cp: "CP",
  eleves_ce1: "CE1",
  eleves_ce2: "CE2",
  eleves_cm1: "CM1",
  eleves_cm2: "CM2",
  eleves_6eme: "6E",
  eleves_5eme: "5E",
  eleves_4eme: "4E",
  eleves_3eme: "3E",
  eleves_2nde: "2ND",
  eleves_1ere: "1E",
  eleves_terminale: "TERMINALE",
}
