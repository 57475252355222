var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "instruction-pannel-entry",
      staticStyle: { height: "100%" }
    },
    [
      _c("h2", { staticClass: "h2" }, [
        _vm._v(_vm._s(_vm.$t("instruction-pannel.contacts.title")))
      ]),
      _c("ContactModale", {
        attrs: {
          open: _vm.openContactModale,
          total: "1",
          projetId: _vm.project.projetId,
          destinataires: _vm.contacts
        },
        on: {
          "close-modale": function($event) {
            _vm.openContactModale = false
          }
        }
      }),
      !_vm.isObservateurNational
        ? _c(
            "b-button",
            {
              staticClass: "mt-5",
              attrs: { type: "is-info" },
              on: {
                click: function($event) {
                  _vm.openContactModale = true
                }
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("instruction-pannel.contacts.contact")) +
                  " "
              )
            ]
          )
        : _vm._e(),
      _c(
        "b-table",
        {
          ref: _vm.tableRef,
          staticClass: "mt-5 contact-table",
          class: { filtered: this.activeFilters.length },
          attrs: {
            striped: _vm.config.isStriped,
            hoverable: _vm.config.isHoverable,
            "debounce-search": _vm.config.searchDebounce,
            "aria-next-label": _vm.config.nextLabel(),
            "aria-previous-label": _vm.config.previousLabel(),
            "aria-page-label": _vm.config.pageLabel(),
            "aria-current-label": _vm.config.currentPageLabel(),
            "per-page": _vm.config.perPage,
            "sticky-header": _vm.config.stickyHeader,
            "current-page": _vm.currentPagedSortedParameter.pageNumber,
            "pagination-position": _vm.config.paginationPosition,
            "backend-pagination": "",
            "sort-icon": _vm.config.sortIcon,
            "sort-icon-size": _vm.config.sortIconSize,
            "backend-sorting": "",
            "sort-multiple": "",
            "sort-multiple-data": _vm.buefySortingPriority,
            total: _vm.total,
            data: _vm.contacts,
            loading: _vm.isLoading
          },
          on: {
            sort: _vm.onSort,
            "sorting-priority-removed": _vm.sortingPriorityRemoved,
            "page-change": _vm.onPageChange
          }
        },
        [
          _vm._l(_vm.columns, function(column) {
            return [
              _c(
                "b-table-column",
                _vm._b(
                  {
                    key: column.id,
                    attrs: { visible: column.visible !== false },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(props) {
                            return [
                              column.field === "prenom-nom-profil"
                                ? _c("div", [
                                    _c("div", { staticClass: "columns" }, [
                                      _c(
                                        "div",
                                        { staticClass: "column pb-0" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                props.row["prenom"] +
                                                  " " +
                                                  props.row["nom"]
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]),
                                    _c("div", { staticClass: "columns" }, [
                                      _c(
                                        "div",
                                        { staticClass: "column pt-0" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t(props.row["profil"]))
                                          )
                                        ]
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              column.field === "coordonnees"
                                ? _c("div", [
                                    _c("div", { staticClass: "columns" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "column pb-0",
                                          staticStyle: {
                                            "word-break": "break-all"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(props.row["courriel"]) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]),
                                    _c("div", { staticClass: "columns" }, [
                                      _c(
                                        "div",
                                        { staticClass: "column pt-0" },
                                        [_vm._v(_vm._s(props.row["telephone"]))]
                                      )
                                    ])
                                  ])
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  "b-table-column",
                  column,
                  false
                )
              )
            ]
          }),
          _c(
            "div",
            {
              staticClass: "empty-table",
              attrs: { slot: "empty" },
              slot: "empty"
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("instruction-pannel.contacts.aucun-contact")) +
                  " "
              )
            ]
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }