var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "carousel-title" }, [
        _c("div", { staticClass: "columns is-vcentered" }, [
          _c("div", { staticClass: "column is-half" }, [
            _vm._v(_vm._s(_vm.$t("projet.en-bref.photos")))
          ]),
          _c("div", { staticClass: "column is-half" }, [
            _vm.images.length !== 0
              ? _c(
                  "div",
                  { staticClass: "container has-text-right" },
                  [
                    !_vm.readonly && _vm.images.length < _vm.maxUploadable
                      ? _c(
                          "b-upload",
                          {
                            ref: "fileInput",
                            attrs: { accept: ".jpg,.jpeg,.png", required: "" },
                            on: { input: _vm.addImage }
                          },
                          [
                            _c("a", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("projet.en-bref.add-photo"))
                                )
                              ])
                            ])
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ])
        ])
      ]),
      _c(
        "div",
        {
          staticStyle: {
            "min-height": "700px",
            display: "flex",
            "justify-content": "center",
            "align-items": "center",
            "border-radius": "15px",
            padding: "5px",
            margin: "50px 50px 75px"
          }
        },
        [
          _vm.images.length === 0
            ? _c(
                "div",
                { staticStyle: { "text-align": "center" } },
                [
                  !_vm.readonly && _vm.images.length < _vm.maxUploadable
                    ? _c(
                        "b-upload",
                        {
                          staticStyle: { display: "inline-block" },
                          attrs: { accept: ".jpg,.jpeg,.png", required: "" },
                          on: { input: _vm.addImage }
                        },
                        [
                          _c(
                            "a",
                            { staticClass: "button is-outlined is-info" },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("projet.en-bref.add-photo"))
                                )
                              ])
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            : _c(
                "div",
                [
                  this.images && this.images.length
                    ? _c(
                        "b-carousel",
                        {
                          attrs: {
                            autoplay: false,
                            "indicator-custom": "",
                            "indicator-inside": false,
                            overlay: _vm.gallery
                          },
                          on: {
                            click: function($event) {
                              return _vm.switchGallery(true)
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "indicators",
                                fn: function(props) {
                                  return [
                                    _c(
                                      "figure",
                                      {
                                        staticClass: "al image",
                                        attrs: { draggable: false }
                                      },
                                      [
                                        _c("img", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.isDeleting,
                                              expression: "!isDeleting"
                                            }
                                          ],
                                          staticStyle: {
                                            height: "80px",
                                            width: "250px !important",
                                            margin: "60px 5px 25px"
                                          },
                                          attrs: {
                                            draggable: false,
                                            src: _vm.imagesUrl[props.i],
                                            title: props.i,
                                            alt:
                                              "Image de l'aire éducative fournie sans texte alternatif"
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2924731424
                          )
                        },
                        [
                          _vm._l(_vm.images, function(image, i) {
                            return _c(
                              "b-carousel-item",
                              { key: i, staticClass: "carousel-item" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-carousel-container",
                                    staticStyle: {
                                      height: "500px",
                                      width: "35vw"
                                    }
                                  },
                                  [
                                    _c("a", { staticClass: "image" }, [
                                      _c("img", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.isDeleting,
                                            expression: "!isDeleting"
                                          }
                                        ],
                                        staticClass: "carousel-pic",
                                        staticStyle: { "max-height": "450px" },
                                        attrs: {
                                          src: _vm.imagesUrl[i],
                                          id: "img-orig-" + i,
                                          crossorigin: "use-credentials",
                                          alt:
                                            "Image de l'aire éducative fournie sans texte alternatif"
                                        },
                                        on: { load: _vm.convertToBase64 }
                                      })
                                    ])
                                  ]
                                ),
                                !_vm.readonly
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass: "delete-button",
                                        attrs: {
                                          title: _vm.$t(
                                            "projet.en-bref.delete-photo"
                                          ),
                                          size: "is-small",
                                          rounded: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.confirmDelete(i)
                                          }
                                        }
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: {
                                            icon: "trash",
                                            size: "is-small",
                                            type: "is-danger"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          _vm.gallery
                            ? _c("span", {
                                staticClass: "modal-close is-large",
                                on: {
                                  click: function($event) {
                                    return _vm.switchGallery(false)
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              )
        ]
      ),
      _c("b-loading", {
        model: {
          value: _vm.isDeleting,
          callback: function($$v) {
            _vm.isDeleting = $$v
          },
          expression: "isDeleting"
        }
      }),
      _vm.isPorteurProjet
        ? _c("b-message", { attrs: { type: "is-info" } }, [
            _c("span", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("projet.en-bref.info-autorisation-droit-image-pp")
                )
              }
            })
          ])
        : _c("b-message", { attrs: { type: "is-warning" } }, [
            _c("span", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("projet.en-bref.info-autorisation-droit-image-acc")
                  ) +
                  " "
              )
            ])
          ]),
      _c("FileWithValidation", {
        attrs: {
          validationActivated: _vm.validationActivated,
          showValidationErrors: _vm.showValidationErrors,
          placeholder: _vm.$t(
            "projet.en-bref.autorisation-droit-image-televerser"
          ),
          inputNotice: _vm.$t("projet.en-bref.autorisation-droit-image-input"),
          fileOperationUrl: _vm.autorisationDroitImageFileUrl,
          disabled: _vm.readonly,
          vid: "autorisation_droit_image",
          value: _vm.projetAnnuel.autorisationDroitImageFilePresent,
          defaultFileName: "AUTORISATION_DROIT_IMAGE.pdf"
        },
        on: { "add-file": _vm.addFile, "delete-file": _vm.deleteFile }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }