






































import { Component, Prop, Vue } from "vue-property-decorator"
import RecapCard from "@/views/projects_pp/RecapCard.vue"

import { ProjetAnnuelDTO } from "@/model/bean/GeneratedDTOs"

@Component({
  components: { RecapCard },
})
export default class LabellisationRecap extends Vue {
  @Prop() projetAnnuel: ProjetAnnuelDTO
  @Prop({ default: "" }) renewalSuffix: string
}
