









































import { PROFIL_UTILISATEUR } from "@/model/bean/EnumHelper"
import { ProjetInscriptionDTO, UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import { GraeService } from "@/services/rest/grae/GraeService"
import { UsersService } from "@/services/rest/users/UsersService"
import { Component, Vue, Prop } from "vue-property-decorator"

//TODO : repasser dessus quand on mettra plusieurs instructeurs
@Component({
  components: {},
})
export default class Instructeur extends Vue {
  @Prop() projet: ProjetInscriptionDTO
  @Prop() loggedUser: UtilisateurDTO
  @Prop() typeDossier: string

  private graeService = GraeService.INSTANCE
  private usersService = UsersService.INSTANCE

  internalProjet: ProjetInscriptionDTO = new ProjetInscriptionDTO()
  instructeurs: UtilisateurDTO[] = []

  currentInstructeurs: UtilisateurDTO[] = []

  created(): void {
    this.init()
  }
  async init(): Promise<void> {
    this.internalProjet = this.projet
    const currentInstructeur = this.internalProjet.evaluationFinale.instructeur
    if (
      this.internalProjet.autresInstructeursInscription &&
      this.internalProjet.autresInstructeursInscription.length !== 0
    ) {
      this.currentInstructeurs = [...this.internalProjet.autresInstructeursInscription]
    }
    if (currentInstructeur) {
      this.currentInstructeurs.push(currentInstructeur)
    }
    this.currentInstructeurs = this.currentInstructeurs.reverse()

    const graeId = this.internalProjet.grae.id
    if (this.canDisplayAddInstructeurButton()) {
      const elements = await this.graeService.getInstructeurs(graeId)
      this.instructeurs = [
        ...elements.filter((instructeur) => instructeur.id === this.loggedUser.id),
        ...elements.filter((instructeur) => instructeur.id !== this.loggedUser.id),
      ]
    }
  }

  canRemoveInstructeur(instructeur: UtilisateurDTO): boolean {
    if (
      this.loggedUser.profil === PROFIL_UTILISATEUR.ENSEIGNANT ||
      this.loggedUser.profil === PROFIL_UTILISATEUR.REFERENT ||
      this.loggedUser.profil === PROFIL_UTILISATEUR.OBSERVATEUR_NATIONAL
    ) {
      return false
    }

    if (
      this.loggedUser.profil === PROFIL_UTILISATEUR.ACCOMPAGNATEUR_REGIONAL &&
      instructeur.id !== this.loggedUser.id
    ) {
      return false
    }

    if (this.loggedUser.profil === PROFIL_UTILISATEUR.ANIMATEUR_REGIONAL) {
      return this.loggedUser.grae ? this.projet.grae.id === this.loggedUser.grae.id : false
    }

    return true
  }

  getInstructeurNameLabel(instructeur: UtilisateurDTO): string {
    if (!instructeur || !instructeur.id) {
      return ""
    }
    return this.loggedUser && instructeur.id === this.loggedUser.id
      ? this.$t("instructionPage.me").toString()
      : `${instructeur.nom} ${instructeur.prenom}`
  }

  canDisplayAddInstructeurButton(): boolean {
    if (!this.internalProjet) {
      return false
    }
    return (
      this.internalProjet.statut &&
      (this.internalProjet.statut == "EN_ATTENTE" ||
        this.internalProjet.statut == "INSTRUCTION_EN_COURS" ||
        this.internalProjet.statut == "INSTRUCTION_TERMINEE" ||
        this.internalProjet.statut == "ACCEPTE_SOUS_RESERVE_REFERENT") &&
      !this.usersService.isObservateurNational(this.loggedUser)
    )
  }

  selectInstructeur(instructeur: UtilisateurDTO): void {
    this.currentInstructeurs.push(instructeur)
    this.$emit("add-instructeur", instructeur, this.projet.id, this.typeDossier)
  }

  removeInstructeur(instructeur: UtilisateurDTO): void {
    this.currentInstructeurs.splice(this.currentInstructeurs.indexOf(instructeur), 1)
    this.$emit("remove-instructeur", instructeur, this.projet.id, this.typeDossier)
  }
}
