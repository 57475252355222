















































import { Component, Vue } from "vue-property-decorator"
import MenuView from "@/views/menus/MenuView.vue"
import Footer from "@/views/commons/Footer.vue"
import MatomoView from "@/views/utils/MatomoView.vue"
import Events from "./services/Events"
import { UtilisateurDTO } from "./model/bean/GeneratedDTOs"
import { LoginService } from "./services/rest/login/LoginService"
import { InfoReportingService } from "./services/log/InfoReportingService"
import VerticalMenus from "./views/menus/VerticalMenus.vue"
import { ContextMenu } from "./views/menus/MenuItems"
import ProfileModale from "./pages/profile/ProfileModale.vue"

@Component({
  components: {
    MenuView,
    Footer,
    MatomoView,
    VerticalMenus,
    ProfileModale,
  },
})
export default class App extends Vue {
  private loginService = LoginService.INSTANCE
  private infoReportingService = InfoReportingService.INSTANCE

  loggedUser: UtilisateurDTO | undefined = new UtilisateurDTO()
  showSubMenu = false
  menuExpanded = true
  mainTitle = ""
  subTitle = ""
  subTitleIcon = ""
  displaySubTitleInGreen = false
  subTitleIsEditable = false
  currentScreenName = this.$t("back-to-list")
  contextMenu: ContextMenu = new ContextMenu([], [])
  profileId = ""
  openProfileModale = false

  created(): void {
    // From PageView
    this.$root.$on("main-title-changed", (title: string) => {
      this.mainTitle = title
    })
    this.$root.$on("sub-title-changed", (subTitle: string) => {
      this.subTitle = subTitle
      // Two scenarios for current screen :
      // If subtitle is not empty, then this is the current screen name
      if (this.subTitle != "") {
        this.currentScreenName = this.subTitle
      } else {
        // Otherwise, we are in a noSubTitleExpected situation (see PageView)
        // Here let's use the mainTitle as screenName
        this.currentScreenName = this.mainTitle
      }
    })
    this.$root.$on(
      "sub-title-icon-changed",
      (subTitleIcon: string) => (this.subTitleIcon = subTitleIcon)
    )
    this.$root.$on(
      "sub-title-is-editable-changed",
      (subTitleIsEditable: boolean) => (this.subTitleIsEditable = subTitleIsEditable)
    )
    this.$root.$on(
      "display-sub-title-in-green-changed",
      (displaySubTitleInGreen: boolean) => (this.displaySubTitleInGreen = displaySubTitleInGreen)
    )
    this.$root.$on("contextual-menu-changed", (contextMenu: ContextMenu) => {
      this.contextMenu = contextMenu
    })
    this.$root.$on("contextual-menu-anchor-update", (newAnchor: string) => {
      this.contextMenu.activeAnchor = newAnchor
    })
    this.$root.$on("open-profile-modale", (open: boolean, id: string) => {
      if (id) {
        this.profileId = id
      } else {
        this.profileId = this.loggedUser && this.loggedUser.id ? this.loggedUser.id : ""
      }
      this.openProfileModale = open
    })
  }

  mounted(): void {
    this.loginService.on(Events.LOGIN_STATUS_CHANGED, () => {
      this.refreshUserInfo()
    })
  }

  refreshUserInfo(): void {
    this.loggedUser = this.loginService.getLoggedUser()
    if ((!this.loggedUser || !this.loggedUser.id) && "/login" !== this.$router.currentRoute.path) {
      this.$router.push("/login")
      this.mainTitle = ""
      this.subTitle = ""
      this.subTitleIcon = ""
    }

    const menuView = this.$refs["menuView"] as MenuView
    const verticalMenus = this.$refs["verticalMenus"] as VerticalMenus

    menuView.hideDropDown()
    verticalMenus.refreshMenuForNewLoggedUser(this.loggedUser)
  }

  logout(): void {
    this.infoReportingService.error(this.$t("logged-out").toString(), new Error())
    this.loginService.logout()
  }

  expandMenu(expand: boolean): void {
    this.menuExpanded = expand
    this.$nextTick(() => {
      document.getElementById("menu")?.dispatchEvent(new Event("menu-expand"))
    })
  }

  showHideSubMenu(show: boolean): void {
    this.showSubMenu = show
    this.menuExpanded = true
    if (!show) {
      this.contextMenu.items = []
    }
  }

  openProfile(userId: string): void {
    this.profileId = userId
    this.openProfileModale = true
  }
}
