



























































import { EtablissementDTO, PaginationOrder } from "@/model/bean/GeneratedDTOs"
import { GraeService } from "@/services/rest/grae/GraeService"
import { OrganismeService } from "@/services/rest/organisme/OrganismeService"
import AbstractTableView from "@/views/tables/AbstractTableView.vue"
import Column from "@/views/tables/Column"
import TableFilterView from "@/views/tables/TableFilterView.vue"
import { Component, Prop } from "vue-property-decorator"

@Component({
  components: {
    TableFilterView,
  },
})
export default class GraeEtablissementsView extends AbstractTableView {
  @Prop() graeId: string | undefined

  private graeService = GraeService.INSTANCE
  private organismeService = OrganismeService.INSTANCE

  etablissementsList: Array<EtablissementDTO> = []
  graeNamesList: Array<string> = []

  async reloadData(): Promise<void> {
    this.isLoading = true
    try {
      const paginatedEtablissementsList = await this.graeService.getEtablissements(
        this.graeId,
        this.currentPagedSortedParameter,
        this.activeFilters
      )
      this.etablissementsList = paginatedEtablissementsList.elements
      this.total = paginatedEtablissementsList.count

      // Get region lists for filtering purposes
      if (!this.graeId) {
        this.organismeService.getAllGraes().then((graes) => {
          this.graeNamesList = graes.map((g) => g.region)
          this.refreshInitialColumns()
        })
      }
    } catch (e) {
      // Silent catch
    }
    this.isLoading = false
  }

  getInitialColumns(): Array<Column> {
    const initialColumns: Array<Column> = [
      {
        field: "codeDepartement",
        label: this.$t("graePage.details.etablissements.departement").toString(),
        sortable: true,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "nom",
        label: this.$t("graePage.details.etablissements.nom").toString(),
        sortable: true,
        backEndSearchable: true,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "ville",
        label: this.$t("graePage.details.etablissements.ville").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "telephone",
        label: this.$t("graePage.details.etablissements.telephone").toString(),
        sortable: true,
        backEndSearchable: false,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "courriel",
        label: this.$t("graePage.details.etablissements.courriel").toString(),
        sortable: true,
        backEndSearchable: false,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "nbProjets",
        label: this.$t("graePage.details.etablissements.nbProjets").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "codeEtablissement",
        label: this.$t("graePage.details.etablissements.codeEtablissement").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: false,
        custom: false,
        canBeAdded: true,
      },
    ]
    if (!this.graeId) {
      initialColumns.push({
        field: "grae.region",
        label: this.$t("graeList.region").toString(),
        possibleSearchValues: this.graeNamesList,
        sortable: true,
        backEndSearchable: true,
        visible: true,
        custom: true,
        canBeAdded: true,
      })
    }
    return initialColumns
  }

  getDefaultSort(): Array<PaginationOrder> {
    const defaultSort = new PaginationOrder()
    defaultSort.clause = "nom"
    defaultSort.desc = true
    return [defaultSort]
  }

  showEtablissementDetailsPage(event: Event, id: number): void {
    // Do not foward click event to row (would trigger modal)
    event.stopPropagation()
    this.$router.push("/etablissement/" + id + "/details")
  }
  showEtablissementListesProjetsPage(event: Event, id: number): void {
    // Do not foward click event to row (would trigger modal)
    event.stopPropagation()
    this.$router.push("/etablissement/" + id + "/projets")
  }
}
