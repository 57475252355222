var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "projet-part" },
    [
      _vm.withTitle
        ? _c("h2", [
            _vm._v(
              _vm._s(_vm.$t("projet.inscription.vue-ensemble.autres-infos"))
            )
          ])
        : _vm._e(),
      _c("RadioWithInputAndValidation", {
        attrs: {
          validationActivated: _vm.validationActivated,
          showValidationErrors: _vm.showValidationErrors,
          label: _vm.$t(
            "projet.inscription.vue-ensemble.projet_ecole.question"
          ),
          data: _vm.trileanValue,
          optionLabels: _vm.trileanValueLabels,
          rules:
            _vm.projetAnnuel.typeDossier === "DOSSIER_RENOUVELLEMENT_LIGHT"
              ? ""
              : "required",
          vid: _vm.validationGroup + "_3",
          verticalDisplay: "false",
          disabled: _vm.readonly
        },
        on: {
          "manual-change": function($event) {
            return _vm.$emit("dirtify-project")
          }
        },
        model: {
          value: _vm.projetAnnuel.contexteProjetEcole,
          callback: function($$v) {
            _vm.$set(_vm.projetAnnuel, "contexteProjetEcole", $$v)
          },
          expression: "projetAnnuel.contexteProjetEcole"
        }
      }),
      _c("GroupCheckboxesWithValidation", {
        attrs: {
          validationActivated: _vm.validationActivated,
          showValidationErrors: _vm.showValidationErrors,
          label: _vm.$t("projet.inscription.vue-ensemble.edd.question"),
          data: _vm.eddAllPossibleValues,
          disabled: _vm.readonly,
          vid: _vm.validationGroup + "_4",
          verticalDisplay: "true",
          inputLabel: _vm.$t("projet.inscription.vue-ensemble.edd.autre"),
          extInputValue: _vm.projetAnnuel.contexteInscritAutre
        },
        on: {
          "manual-change": _vm.eddCheckboxChanged,
          "input-change": _vm.eddAutreValueChanged
        },
        model: {
          value: _vm.eddSelectedValues,
          callback: function($$v) {
            _vm.eddSelectedValues = $$v
          },
          expression: "eddSelectedValues"
        }
      }),
      _vm.projetAnnuel.typeDossier != "DOSSIER_INSCRIPTION"
        ? _c("RadioWithInputAndValidation", {
            attrs: {
              validationActivated: _vm.validationActivated,
              showValidationErrors: _vm.showValidationErrors,
              rules: "required",
              data: _vm.booleanValue,
              optionLabels: _vm.booleanValueLabels,
              verticalDisplay: "true",
              disabled: _vm.readonly,
              label: _vm.$t("projetPage.labellisation.suivi.projet-maintenu"),
              vid: _vm.validationGroup + "_maintientProjetAnneeSuivante"
            },
            on: {
              "manual-change": function($event) {
                return _vm.$emit("dirtify-project")
              }
            },
            model: {
              value: _vm.projetAnnuel.maintientProjetAnneeSuivante,
              callback: function($$v) {
                _vm.$set(_vm.projetAnnuel, "maintientProjetAnneeSuivante", $$v)
              },
              expression: "projetAnnuel.maintientProjetAnneeSuivante"
            }
          })
        : _vm._e(),
      !_vm.projetAnnuel.maintientProjetAnneeSuivante &&
      _vm.projetAnnuel.maintientProjetAnneeSuivante !== null &&
      !_vm.readonly
        ? _c("b-message", { staticClass: "is-warning" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "projetPage.labellisation.suivi.projet-maintenu-warning"
                  )
                ) +
                " "
            )
          ])
        : _vm._e(),
      _c("br"),
      _c("br"),
      _c("br")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }