var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "instruction-pannel-container" }, [
    _c(
      "div",
      { staticClass: "pannel-container" },
      [
        _c(
          "div",
          {
            staticClass: "pannel-content",
            class: {
              hidden: _vm.instructionPannelWidth < _vm.minVisibleWidth,
              "with-padding": _vm.instructionPannelWidth > 400
            }
          },
          [
            _vm.activeItem.toPath == "contacts"
              ? _c(
                  "div",
                  { staticStyle: { height: "100%" } },
                  [
                    _c("InstructionPannelContacts", {
                      attrs: { project: _vm.project }
                    })
                  ],
                  1
                )
              : _vm.activeItem.toPath == "echanges"
              ? _c(
                  "div",
                  [
                    _c("InstructionPannelEchanges", {
                      attrs: { project: _vm.project }
                    })
                  ],
                  1
                )
              : _vm.activeItem.toPath == "inscription-evaluation"
              ? _c(
                  "div",
                  [
                    _c("InstructionPannelInscriptionEvaluation", {
                      attrs: {
                        project: _vm.project,
                        loggedUser: _vm.loggedUser,
                        instructionPannelWidth: _vm.instructionPannelWidth
                      }
                    })
                  ],
                  1
                )
              : _vm.activeItem.toPath == "labellisation-qualification"
              ? _c(
                  "div",
                  [
                    _c("InstructionPannelLabellisationQualification", {
                      attrs: {
                        project: _vm.project,
                        readOnly:
                          _vm.project.typeDossier === "DOSSIER_INSCRIPTION" ||
                          (_vm.project.statut !== "EN_ATTENTE" &&
                            _vm.project.statut !== "INSTRUCTION_EN_COURS" &&
                            _vm.project.statut !== "INSTRUCTION_TERMINEE")
                      }
                    })
                  ],
                  1
                )
              : _vm.activeItem.toPath == "labellisation-evaluation-finale"
              ? _c(
                  "div",
                  [
                    _c("InstructionPannelLabellisationEvaluationFinale", {
                      attrs: {
                        project: _vm.project,
                        instructionPannelWidth: _vm.instructionPannelWidth
                      }
                    })
                  ],
                  1
                )
              : _vm.activeItem.toPath == "labellisation-mon-evaluation"
              ? _c(
                  "div",
                  [
                    _c("InstructionPannelLabellisationMonEvaluation", {
                      attrs: {
                        project: _vm.project,
                        instructionPannelWidth: _vm.instructionPannelWidth
                      },
                      on: { "save-evaluation": _vm.save }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]
        ),
        _c("InstructionPannelMenu", {
          attrs: { project: _vm.project },
          on: { "item-selected": _vm.newItemSelected }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }