


































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import GroupCheckboxesWithValidation from "@/views/inputs/GroupCheckBoxesWithValidation.vue"
import { ValidationObserver } from "vee-validate"
import TitleView from "@/views/commons/TitleView.vue"
import { Matiere, UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import { MATIERES } from "@/model/bean/EnumHelper"
import i18n from "@/i18n"

const COMPETENCES_STRINGS = {
  PEDAGOGIE: i18n.t("profilePage.competences-referent.pedagogie").toString(),
  ECOLOGIE: i18n.t("profilePage.competences-referent.ecologie").toString(),
  AE: i18n.t("profilePage.competences-referent.ae").toString(),
}

@Component({
  components: {
    ValidationObserver,
    InputWithValidation,
    GroupCheckboxesWithValidation,
    TitleView,
  },
})
export default class UserPersonnalInfo extends Vue {
  @Prop() user: UtilisateurDTO
  @Prop({ default: false }) readonly: boolean
  @Prop() validationGroup: string
  @Prop({ default: "" }) errormessage: string
  etablissement = ""
  structure = ""
  idEtablissement = ""
  idStructure = ""

  matieres = Object.values(MATIERES).filter(
    (type) =>
      !type.startsWith("LANGUE_VIVANTE_") &&
      !type.startsWith("LANGUE_REGIONALE_") &&
      type != MATIERES.AUTRE
  )
  matieresLabels = this.matieres.map((type) => {
    return this.$t(`profilePage.matieres.label-${type.toLowerCase().replace(/_/g, "-")}`)
  })
  languesVivantes = Object.values(MATIERES).filter((type) => type.startsWith("LANGUE_VIVANTE_"))
  languesVivantesLabels = this.languesVivantes.map((type) => {
    return this.$t(`profilePage.matieres.label-${type.toLowerCase().replace(/_/g, "-")}`)
  })
  languesRegionales = Object.values(MATIERES).filter((type) => type.startsWith("LANGUE_REGIONALE_"))
  languesRegionalesLabels = this.languesRegionales.map((type) => {
    return this.$t(`profilePage.matieres.label-${type.toLowerCase().replace(/_/g, "-")}`)
  })

  competencesStrings = Object.values(COMPETENCES_STRINGS)
  checkedCompetenceStrings: string[] = []

  created(): void {
    this.init()
  }

  init(): void {
    this.checkedCompetenceStrings = this.getCheckedCompetencesStrings()
    this.etablissement = this.user.etablissement ? this.user.etablissement.nom : ""
    this.structure = this.user.structure ? this.user.structure.nom : ""
    this.idEtablissement = this.user.etablissement?.id || ""
    this.idStructure = this.user.structure?.id || ""
  }

  @Watch("user")
  onUserChanged(_newVal: string, _oldValue: string): void {
    this.init()
  }

  changeCompetences(): void {
    this.user.competencesReferentPedagogie = this.checkedCompetenceStrings.includes(
      COMPETENCES_STRINGS.PEDAGOGIE
    )
    this.user.competencesReferentEcologie = this.checkedCompetenceStrings.includes(
      COMPETENCES_STRINGS.ECOLOGIE
    )
    this.user.competencesReferentAe = this.checkedCompetenceStrings.includes(COMPETENCES_STRINGS.AE)
    this.changeUtilisateur()
  }

  emitCloseModale(): void {
    this.$emit("close-modale")
  }
  GoToStructure(): void {
    this.$router.push("/structure/" + this.idStructure + "/projets")
    this.emitCloseModale()
  }
  GoToEtablissement(): void {
    this.$router.push("/etablissement/" + this.idEtablissement + "/projets")
    this.emitCloseModale()
  }
  getCheckedCompetencesStrings(): string[] {
    const competences: string[] = []
    if (this.user.competencesReferentPedagogie) {
      competences.push(COMPETENCES_STRINGS.PEDAGOGIE)
    }
    if (this.user.competencesReferentEcologie) {
      competences.push(COMPETENCES_STRINGS.ECOLOGIE)
    }
    if (this.user.competencesReferentAe) {
      competences.push(COMPETENCES_STRINGS.AE)
    }
    return competences
  }

  changeUtilisateur(): void {
    this.$emit("change-utilisateur", this.user)
  }

  setMatiere(value: Matiere): void {
    this.user.matiereEnseignee = value
    this.changeUtilisateur()
  }
}
