

























































































import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import { ValidationObserver } from "vee-validate"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import PasswordCreationWithValidation from "@/views/inputs/PasswordCreationWithValidation.vue"
import { UtilisateurRegistrationDTO } from "@/model/bean/GeneratedDTOs"
import CheckboxWithValidation from "@/views/inputs/CheckBoxWithValidation.vue"
import PDFLinkWithModal from "@/views/commons/PDFLinkWithModal.vue"

@Component({
  components: {
    ValidationObserver,
    InputWithValidation,
    PasswordCreationWithValidation,
    CheckboxWithValidation,
    PDFLinkWithModal,
  },
})
export default class UserInformationsView extends Vue {
  @Prop() value: UtilisateurRegistrationDTO

  innerUtilisateur = new UtilisateurRegistrationDTO()
  acceptRGPD = false
  acceptNameVisibilityOnProtectedListing = false

  created(): void {
    if (this.value) {
      this.innerUtilisateur = this.value
    }
  }

  submit(): void {
    this.$emit("input-user", this.innerUtilisateur)
  }

  @Watch("acceptNameVisibilityOnProtectedListing")
  acceptNameVisibilityOnProtectedListingChanged(): void {
    this.$emit("accept-visibility", this.acceptNameVisibilityOnProtectedListing)
  }
}
