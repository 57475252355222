import { ApplicationDateDTO } from "./../../../model/bean/GeneratedDTOs"
/*-
 * #%L
 * Aires Éducatives
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
import { AbstractRestService } from "../AbstractRestService"
import ConversionUtils from "@/services/ConversionUtils"
export class ApplicationDateService extends AbstractRestService {
  public static INSTANCE = new ApplicationDateService()

  async getApplicationDate(): Promise<ApplicationDateDTO> {
    const result: ApplicationDateDTO = await super.typedGetSingleV2("/system-date/", 2)
    result.applicationDate = ConversionUtils.convertToUTCDate(result.applicationDate)
    return result
  }

  async setApplicationDate(date: ApplicationDateDTO): Promise<ApplicationDateDTO> {
    const result: ApplicationDateDTO = await super.typedPostV2("/system-date/", 2, date)
    result.applicationDate = ConversionUtils.convertToUTCDate(result.applicationDate)
    return result
  }

  async resetApplicationDate(): Promise<ApplicationDateDTO> {
    const result: ApplicationDateDTO = await super.typedPostV2(
      "/system-date/reset",
      2,
      new ApplicationDateDTO()
    )
    result.applicationDate = ConversionUtils.convertToUTCDate(result.applicationDate)
    return result
  }

  async simulateAnneeScolaireEnd(graeId: string): Promise<void> {
    await super.typedPostV2("/year-end/end-year/" + graeId, 2, new ApplicationDateDTO())
    await super.typedGetV2("/year-end/" + graeId, 2)
  }
}
