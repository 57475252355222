var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.messageString
        ? _c(
            "b-message",
            {
              attrs: { type: "is-danger", "aria-close-label": "Close message" }
            },
            [_vm._v(" " + _vm._s(_vm.messageString) + " ")]
          )
        : _vm._e(),
      _c(
        "ValidationObserver",
        { ref: "observer" },
        [
          _vm.withOldPwd
            ? _c(
                "b-field",
                [
                  _c("InputWithValidation", {
                    attrs: {
                      rules: !_vm.sent ? "required" : "",
                      type: "password",
                      passwordReveal: "",
                      label: _vm.$t("input-old-password"),
                      placeholder: _vm.$t(
                        "profilePage.ancien-mot-de-passe-placeholder"
                      ),
                      vid: "oldpassword"
                    },
                    model: {
                      value: _vm.oldpassword,
                      callback: function($$v) {
                        _vm.oldpassword = $$v
                      },
                      expression: "oldpassword"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-field",
            [
              _c("PasswordCreationWithValidation", {
                attrs: {
                  password: _vm.password,
                  label: _vm.$t("profilePage.nouveau-mot-de-passe"),
                  placeholder: _vm.$t(
                    "profilePage.nouveau-mot-de-passe-placeholder"
                  ),
                  validationActivated: !_vm.sent
                },
                on: { "new-password": _vm.newPassword }
              })
            ],
            1
          ),
          _c(
            "b-field",
            [
              _c("InputWithValidation", {
                attrs: {
                  rules: !_vm.sent ? "required|confirmed:password" : "",
                  type: "password",
                  passwordReveal: "",
                  label: _vm.$t("profilePage.mot-de-passe-confirmation"),
                  placeholder: _vm.$t(
                    "profilePage.confirmation-mot-de-passe-placeholder"
                  )
                },
                model: {
                  value: _vm.confirmation,
                  callback: function($$v) {
                    _vm.confirmation = $$v
                  },
                  expression: "confirmation"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }