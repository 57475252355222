/*-
 * #%L
 * Aires Éducatives
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
import i18n from "@/i18n"
import { AbstractRestService } from "@/services/rest/AbstractRestService"
import {
  EtablissementDTO,
  PaginationParameter,
  StructureDTO,
  Grae,
  PaginationResult,
  FilterDTO,
  UtilisateurDTO,
  ProjetAnnuelDTO,
  GraeDTO,
} from "@/model/bean/GeneratedDTOs"

const ETABLISSEMENT_API_URL =
  "https://data.education.gouv.fr/api/records/1.0/search/?rows=10&dataset=fr-en-annuaire-education&q=&facet=identifiant_de_l_etablissement&q="
const getCommuneApiURL = (codePostal: string) =>
  `https://geo.api.gouv.fr/communes?codePostal=${codePostal}&fields=code,nom`

export class OrganismeService extends AbstractRestService {
  static INSTANCE = new OrganismeService()
  savedStructures: StructureDTO[] = []

  constructor() {
    super()
  }

  async getEtablissement(etablissementId: string): Promise<EtablissementDTO> {
    return super.typedGetSingleV2("organismes/etablissements/" + etablissementId, 2)
  }

  async getStructures(): Promise<StructureDTO[]> {
    // TODO determine with we want to handle pagination and filters for structuress
    // If so, add a paginationParameters: PaginationParameter parameters - see UserService.getAllUser()
    const paginationParameters = new PaginationParameter()
    // We want pageNumber 0, but AbstractRestService operates a -1
    paginationParameters.pageNumber = 1
    paginationParameters.pageSize = -1
    const paginatedStructures = await super.typeGetPaginatedV2<StructureDTO>(
      "organismes/structures",
      2,
      paginationParameters,
      []
    )
    // TODO handle result as paginated in calling Views
    return [...paginatedStructures.elements, ...this.savedStructures]
  }

  async getStructure(structureId: string): Promise<StructureDTO> {
    return super.typedGetSingleV2("organismes/structures/" + structureId, 2)
  }

  async getEtablissementsFromAPI(searchString: string, index: number): Promise<EtablissementDTO[]> {
    const response = await fetch(`${ETABLISSEMENT_API_URL}${searchString}&start=${index}`, {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
      },
    })
    const responseJSON = await response.json()
    if (!responseJSON || !responseJSON.records || responseJSON.records.length === 0) {
      return []
    }

    return responseJSON.records.map((record: any) => {
      const info = record.fields
      const etablissement = new EtablissementDTO()

      etablissement.codeEtablissement = info.identifiant_de_l_etablissement
      etablissement.nom = info.nom_etablissement
      etablissement.nature = info.libelle_nature
      etablissement.statut = info.statut_public_prive
      etablissement.typeContrat = info.type_contrat_prive
      etablissement.codeDepartement = info.code_departement
      etablissement.departement = info.libelle_departement
      etablissement.academie = info.libelle_academie
      etablissement.telephone = info.telephone ? info.telephone.replace(/\s/g, "") : ""
      etablissement.courriel = info.mail
      etablissement.siteWeb = info.web ? info.web : ""
      etablissement.postBac = Boolean(info.post_bac)
      etablissement.sirenSiret = info.siren_siret
      etablissement.nomChefEtablissement = info.nomChefEtablissement
      etablissement.prenomChefEtablissement = info.prenomChefEtablissement

      etablissement.rue = info.adresse_1 + `${info.adresse_2 ? " " + info.adresse_2 : ""}`
      etablissement.codePostal = info.code_postal
      etablissement.ville = info.nom_commune
      etablissement.pays = i18n.t("OrganismeService.france").toString()
      etablissement.longitude = info.longitude
      etablissement.latitude = info.latitude

      const grae = new Grae()
      grae.region = info.libelle_region
      etablissement.grae = grae

      return etablissement
    })
  }

  addStructure(structure: StructureDTO): void {
    this.savedStructures.push(structure)
  }

  updateStructure(structure: StructureDTO): Promise<StructureDTO> {
    return super.typedPutV2("/organismes/structures/" + structure.id, 2, structure)
  }

  updateEtablissement(etablissement: EtablissementDTO): Promise<EtablissementDTO> {
    return super.typedPutV2("/organismes/etablissements/" + etablissement.id, 2, etablissement)
  }

  async getVilleOptionsFromCodePostal(codePostal: string): Promise<string[]> {
    const response = await fetch(getCommuneApiURL(codePostal), {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
      },
    })
    const responseJSON = await response.json()
    if (!responseJSON) {
      return []
    }

    return responseJSON.map((r: any) => r.nom)
  }

  async getGraes(
    paginationParameters: PaginationParameter,
    filtersList: Array<FilterDTO>
  ): Promise<PaginationResult<Grae>> {
    return super.typeGetPaginatedV2<Grae>("/grae", 2, paginationParameters, filtersList)
  }

  async getGrae(graeId: string): Promise<GraeDTO> {
    return super.typedGetSingleV2<GraeDTO>("/grae/" + graeId, 2)
  }

  async getAllGraes(): Promise<GraeDTO[]> {
    return super.typedGetV2("/grae/all", 2)
  }

  async exportGraeMembersCSV(graeId: string, filtersList: Array<FilterDTO>): Promise<void> {
    this.exportFileV2("/users/grae/" + graeId + "/export/csv", 2, filtersList)
  }

  async exportGraeMembersXLS(graeId: string, filtersList: Array<FilterDTO>): Promise<void> {
    this.exportFileV2("/users/grae/" + graeId + "/export/xls", 2, filtersList)
  }

  async getGraeProjects(
    graeId: string,
    paginationParameters: PaginationParameter,
    filtersList: Array<FilterDTO>
  ): Promise<PaginationResult<ProjetAnnuelDTO>> {
    return super.typeGetPaginatedV2<ProjetAnnuelDTO>(
      "projects/grae/" + graeId,
      2,
      paginationParameters,
      filtersList
    )
  }

  async exportGraeProjectsCSV(graeId: string, filtersList: Array<FilterDTO>): Promise<void> {
    this.exportFileV2("/instruction/projects/grae/" + graeId + "/export/csv", 2, filtersList)
  }

  async exportGraeProjectsXLS(graeId: string, filtersList: Array<FilterDTO>): Promise<void> {
    this.exportFileV2("/instruction/projects/grae/" + graeId + "/export/xls", 2, filtersList)
  }

  async getEnseignantsForEtablissements(etablissementIds: string[]): Promise<UtilisateurDTO[]> {
    const params = new Map<string, string | Array<any>>()
    params.set("etablissementId", etablissementIds)
    return super.typedGetV2<UtilisateurDTO>("/organismes/etablissements/enseignants", 2, params)
  }

  async searchStructures(searchString: string): Promise<StructureDTO[]> {
    const params = new Map<string, string | Array<any>>()
    params.set("query", searchString)
    return super.typedGetV2<StructureDTO>("/organismes/structures/search", 2, params)
  }
}
