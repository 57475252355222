

















import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import TitleView from "@/views/commons/TitleView.vue"
import { ProjetAnnuelDTO } from "@/model/bean/GeneratedDTOs"
import GroupCheckboxesWithValidation from "@/views/inputs/GroupCheckBoxesWithValidation.vue"
import { EnumHelper } from "@/model/bean/EnumHelper"

@Component({
  components: {
    TitleView,
    GroupCheckboxesWithValidation,
  },
})
export default class InstructionMilieuxParticuliers extends Vue {
  @Prop() projetAnnuel: ProjetAnnuelDTO
  @Prop({ default: false }) readOnly: boolean

  internalProjet = new ProjetAnnuelDTO()
  allMilieuxInteretParticulier = EnumHelper.allMilieuxInteretParticulier
  checkedMilieuxInteretsParticuliersLabels: string[] = []

  created(): void {
    this.internalProjet = this.projetAnnuel
    this.refreshChecked()
  }

  @Watch("projetAnnuel")
  dossierInscriptionChanged(): void {
    this.internalProjet = this.projetAnnuel
    this.refreshChecked()
  }

  refreshChecked(): void {
    this.checkedMilieuxInteretsParticuliersLabels = this.internalProjet.milieuInteretParticulier.map(
      (milieuParticulier) => {
        return this.$i18n.t("instruction." + milieuParticulier).toString()
      }
    )
    if (this.internalProjet.milieuInteretParticulierAutre) {
      this.checkedMilieuxInteretsParticuliersLabels.push(
        this.$i18n.t("instruction.AUTRE").toString()
      )
    }
  }

  allMilieuxInteretParticulierLabels(): string[] {
    return this.allMilieuxInteretParticulier.map((milieuParticulier) => {
      return this.$i18n.t("instruction." + milieuParticulier).toString()
    })
  }

  changeMilieuxInterets(): void {
    this.internalProjet.milieuInteretParticulier = this.allMilieuxInteretParticulier.filter(
      (milieuParticulier) => {
        return (
          this.checkedMilieuxInteretsParticuliersLabels.indexOf(
            this.$i18n.t("instruction." + milieuParticulier).toString()
          ) > -1
        )
      }
    )
    if (
      this.checkedMilieuxInteretsParticuliersLabels.indexOf(
        this.$t("instruction.AUTRE").toString()
      ) === -1
    ) {
      this.internalProjet.milieuInteretParticulierAutre = ""
    }
  }

  changeMilieuxInteretsAutre(newValue: string): void {
    this.internalProjet.milieuInteretParticulierAutre = newValue
    const indexAutre = this.checkedMilieuxInteretsParticuliersLabels.indexOf(
      this.$i18n.t("instruction.AUTRE").toString()
    )
    if (newValue && indexAutre === -1) {
      this.checkedMilieuxInteretsParticuliersLabels.push(
        this.$i18n.t("instruction.AUTRE").toString()
      )
    }
  }
}
