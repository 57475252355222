/*-
 * #%L
 * Aires Éducatives
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
import { ToastProgrammatic as Toast } from "buefy"
import { DialogProgrammatic as Dialog } from "buefy"
import { GlobalPositions } from "buefy/types/helpers"
import i18n from "@/i18n"

/**
 * Service used to display info, warning, error message to the end-user.
 */
export class InfoReportingService {
  static INSTANCE = new InfoReportingService()

  defaultToastPosition: GlobalPositions = "is-top"
  defaultToastSuccessOrInfoDuration = 3000
  defaultToastErrorDuration = 8000

  cancelButtonMessage = i18n.t("annuler").toString()

  success(message: string, additionalMessage = ""): void {
    Toast.open({
      message: message + " " + additionalMessage,
      type: "is-success",
      duration: this.defaultToastSuccessOrInfoDuration,
      position: this.defaultToastPosition,
    })
  }

  warn(message: string, error: Error): void {
    console.warn("[WARNING] " + message, error)
    Toast.open({
      message: message,
      type: "is-warning",
      duration: this.defaultToastErrorDuration,
      position: this.defaultToastPosition,
    })
  }

  error(message: string, error: Error): void {
    console.error("[ERROR] " + message, error)
    Toast.open({
      message: message,
      type: "is-danger",
      duration: this.defaultToastErrorDuration,
      position: this.defaultToastPosition,
    })
  }

  dialog(
    title: string,
    message: string,
    confirmText: string,
    onConfirmCb: { (): void },
    type: string
  ): void {
    Dialog.confirm({
      title: title,
      message: message,
      confirmText: confirmText,
      type: type,
      hasIcon: true,
      cancelText: this.cancelButtonMessage,
      onConfirm: () => {
        onConfirmCb()
      },
    })
  }

  dialogWithCancel(
    title: string,
    message: string,
    confirmText: string,
    onConfirmCb: { (): void },
    onCancelCb: { (): void },
    type: string
  ): void {
    Dialog.confirm({
      title: title,
      message: message,
      confirmText: confirmText,
      type: type,
      hasIcon: true,
      cancelText: this.cancelButtonMessage,
      onConfirm: () => {
        onConfirmCb()
      },
      onCancel: () => {
        onCancelCb()
      },
    })
  }

  dialogWithInput(
    title: string,
    message: string,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    inputAttrs: any,
    confirmText: string,
    onConfirmCb: { (value: string): void },
    type: string
  ): void {
    Dialog.prompt({
      title: title,
      message: message,
      cancelText: this.cancelButtonMessage,
      inputAttrs: {
        ...inputAttrs,
      },
      confirmText: confirmText,
      type: type,
      hasIcon: true,

      onConfirm: (value) => {
        onConfirmCb(value)
      },
    })
  }
}
