



























































































import { ValidationProvider } from "vee-validate"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import FormInputLabel from "./FormInputLabel.vue"

@Component({
  components: {
    ValidationProvider,
    FormInputLabel,
  },
})
export default class PasswordCreationWithValidation extends Vue {
  @Prop({ default: "" }) group: string
  @Prop() password: string
  @Prop() vid: string
  @Prop() tooltip: string
  @Prop({ default: "" }) placeholder: string
  @Prop({ default: "" }) customError: string
  @Prop({ default: true }) validationActivated: boolean
  @Prop({ default: true }) showValidationErrors: boolean
  atLeastOneLowerCase = false
  atLeastOneUpperCase = false
  atLeastOneDigit = false
  correctLength = false
  innerValue = ""
  minimalLength = 8
  confirmation = ""

  created(): void {
    if (this.password) {
      this.innerValue = this.password
      this.validate(this.innerValue)
    }
  }

  @Watch("innerValue")
  onInnerValueChanged(newVal: string, _oldValue: string): void {
    this.validate(newVal)
    this.$emit("input", newVal)
  }

  @Watch("password")
  onExternalValueChanged(newVal: string, _oldValue: string): void {
    this.validate(newVal)
    this.innerValue = newVal
    if (newVal != _oldValue) {
      this.$emit("manual-change", newVal)
    }
  }

  validate(value: string): void {
    this.correctLength = value.length >= this.minimalLength
    this.atLeastOneDigit = /\d/.test(value)
    this.atLeastOneLowerCase = /[a-z]/.test(value)
    this.atLeastOneUpperCase = /[A-Z]/.test(value)
  }

  icon(bool: boolean): string {
    if (bool) {
      return "check"
    } else {
      return "times"
    }
  }

  newPassword(): void {
    this.$emit("new-password", this.innerValue)
  }
}
