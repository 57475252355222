







































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import LoginView from "@/views/login/LoginView.vue"
import Activable from "../utils/Activable"
import ImageWithModal from "@/views/commons/ImageWithModal.vue"
import { UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import InlinedInputWithValidation from "../inputs/InlinedInputWithValidation.vue"
import { ValidationObserver } from "vee-validate"
import PageChangeForbidder from "@/router/PageChangeForbidder"

@Component({
  components: {
    LoginView,
    ImageWithModal,
    ValidationObserver,
    InlinedInputWithValidation,
  },
})
export default class MenuView extends Vue {
  @Prop() loggedUser: UtilisateurDTO | undefined
  @Prop() mainTitle: string
  @Prop() subTitle: string
  @Prop() subTitleIcon: string
  @Prop({ default: false }) subTitleIsEditable: boolean
  @Prop({ default: false }) displaySubTitleInGreen: boolean

  edit = false
  editedSubTitle: string

  @Watch("subTitle")
  onSubTitleChanged(): void {
    this.edit = false
  }

  logout(): void {
    if (!PageChangeForbidder.INSTANCE.pageChangeForbidden) {
      this.hideDropDown()
      this.$emit("log-out")
    } else {
      PageChangeForbidder.INSTANCE.callbackActionWhenForbidden(() => {
        PageChangeForbidder.INSTANCE.allowPageChange()
        this.hideDropDown()
        this.$emit("log-out")
      })
    }
  }

  emitOpenProfile(): void {
    this.hideDropDown()
    if (this.loggedUser) {
      this.$emit("open-profile", this.loggedUser.id)
    }
  }

  getInitials(loggedUser: UtilisateurDTO): string {
    let initials = ""
    if (loggedUser.prenom) {
      initials = loggedUser.prenom[0]
    }
    if (loggedUser.nom) {
      initials += loggedUser.nom[0]
    }

    return initials
  }

  hideDropDown(): void {
    // Hide dropdown if opened
    const loginDropdown = (this.$refs["login-dropdown"] as unknown) as Activable
    if (loginDropdown) {
      loginDropdown.isActive = false
    }
  }

  enterEdition(): void {
    this.edit = true
    this.editedSubTitle = this.subTitle
  }

  submit(): void {
    this.edit = false
    this.$root.$emit("sub-title-edited", this.editedSubTitle)
  }
}
