var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-dropdown",
        {
          ref: "columns-values-dropdown",
          attrs: {
            "aria-role": "list",
            position: "is-bottom-right",
            "append-to-body": ""
          }
        },
        [
          _c(
            "b-button",
            {
              staticClass: "button is-primary",
              attrs: {
                slot: "trigger",
                "icon-right": "angle-down",
                rounded: "",
                outlined: ""
              },
              slot: "trigger"
            },
            [_vm._v(" " + _vm._s(_vm.$t("table-filter.filter-columns")) + " ")]
          ),
          _c(
            "b-dropdown-item",
            {
              staticClass: "columns-filter-new",
              attrs: { "aria-role": "listitem", custom: "" }
            },
            [
              _c(
                "b-field",
                { attrs: { label: _vm.$t("table-filter.new-column-name") } },
                [
                  _c(
                    "b-select",
                    {
                      attrs: {
                        expanded: "",
                        placeholder: _vm.$t(
                          "table-filter.new-column-name-placeholder"
                        )
                      },
                      on: { input: _vm.setChosenFilterField },
                      model: {
                        value: _vm.newFilterDTO.property,
                        callback: function($$v) {
                          _vm.$set(_vm.newFilterDTO, "property", $$v)
                        },
                        expression: "newFilterDTO.property"
                      }
                    },
                    _vm._l(_vm.filterFields, function(filterField) {
                      return _c(
                        "option",
                        {
                          key: filterField.field,
                          domProps: { value: filterField.field }
                        },
                        [_vm._v(" " + _vm._s(filterField.label) + " ")]
                      )
                    }),
                    0
                  )
                ],
                1
              ),
              _vm.newFilterDTO.property
                ? _c(
                    "b-radio",
                    {
                      staticClass: "filter-value-radio",
                      attrs: { "native-value": "null" },
                      model: {
                        value: _vm.filterKind,
                        callback: function($$v) {
                          _vm.filterKind = $$v
                        },
                        expression: "filterKind"
                      }
                    },
                    [
                      _c("b-field", {
                        attrs: { label: _vm.$t("table-filter.null") }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.newFilterDTO.property
                ? _c(
                    "b-radio",
                    {
                      staticClass: "filter-value-radio",
                      attrs: { "native-value": "value" },
                      model: {
                        value: _vm.filterKind,
                        callback: function($$v) {
                          _vm.filterKind = $$v
                        },
                        expression: "filterKind"
                      }
                    },
                    [
                      _c("b-field", {
                        attrs: { label: _vm.$t("table-filter.value-equals") }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.newFilterDTO.property &&
              _vm.chosenFilterField.possibleSearchValues.length === 0
                ? _c("b-input", {
                    attrs: {
                      disabled: _vm.filterKind == "null",
                      placeholder: _vm.$t(
                        "table-filter.new-column-value-placeholder"
                      )
                    },
                    model: {
                      value: _vm.newFilterValue,
                      callback: function($$v) {
                        _vm.newFilterValue = $$v
                      },
                      expression: "newFilterValue"
                    }
                  })
                : _vm._e(),
              _vm.newFilterDTO.property &&
              _vm.chosenFilterField.possibleSearchValues.length > 0
                ? _c(
                    "b-select",
                    {
                      attrs: {
                        placeholder: "table-filter.new-column-value-placeholder"
                      },
                      model: {
                        value: _vm.newFilterValue,
                        callback: function($$v) {
                          _vm.newFilterValue = $$v
                        },
                        expression: "newFilterValue"
                      }
                    },
                    _vm._l(_vm.chosenFilterField.possibleSearchValues, function(
                      searchValue
                    ) {
                      return _c(
                        "option",
                        { key: searchValue, domProps: { value: searchValue } },
                        [_vm._v(" " + _vm._s(_vm.$t(searchValue)) + " ")]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _c("br"),
              _c(
                "b-button",
                {
                  attrs: {
                    disabled:
                      !_vm.newFilterDTO.property ||
                      (_vm.filterKind == "value" && !_vm.newFilterValue),
                    type: "is-info"
                  },
                  on: { click: _vm.addActiveFilter }
                },
                [_vm._v(_vm._s(_vm.$t("table-filter.add-filter")) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.activeFilters.length > 0
        ? _c(
            "b-field",
            { attrs: { grouped: "", "group-multiline": "" } },
            _vm._l(_vm.activeFilters, function(filter) {
              return _c(
                "div",
                { key: filter.field, staticClass: "control" },
                [
                  _c(
                    "b-taglist",
                    { staticClass: "filters-list", attrs: { attached: "" } },
                    [
                      _c("b-tag", { attrs: { type: "is-dark" } }, [
                        _vm._v(_vm._s(filter.label) + " ")
                      ]),
                      _c(
                        "b-tag",
                        { attrs: { type: "is-grey" } },
                        [
                          filter.values.length == 0
                            ? _c(
                                "span",
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("table-filter.none")) +
                                      " "
                                  ),
                                  _c(
                                    "b-tag",
                                    {
                                      staticClass: "close",
                                      attrs: { type: "is-grey" },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.removeFilterValue(
                                            filter.property,
                                            _vm.value
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("b-icon", { attrs: { icon: "times" } })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._l(filter.values, function(value, i) {
                            return _c(
                              "span",
                              { key: value },
                              [
                                value.length > 1
                                  ? _c("span", [
                                      _vm._v(" " + _vm._s(_vm.$t(value)) + " ")
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("table-filter.none")) +
                                          " "
                                      )
                                    ]),
                                _c(
                                  "b-tag",
                                  {
                                    staticClass: "close",
                                    attrs: { type: "is-grey" },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.removeFilterValue(
                                          filter.property,
                                          value
                                        )
                                      }
                                    }
                                  },
                                  [_c("b-icon", { attrs: { icon: "times" } })],
                                  1
                                ),
                                i < filter.values.length - 1
                                  ? _c("span", [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(_vm.$t("table-filter.or"))
                                        )
                                      ])
                                    ])
                                  : _vm._e()
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            0
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }