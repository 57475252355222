






















import { Component, Prop, Vue } from "vue-property-decorator"
import TitleView from "@/views/commons/TitleView.vue"
import ContentView from "@/views/commons/ContentView.vue"
@Component({
  components: { TitleView, ContentView },
})
export default class BookmarkCard extends Vue {
  @Prop() tagType: string
  @Prop() iconName: string
  @Prop() iconType: string
  @Prop() isLoading: boolean
  @Prop() clickContentCb: { (): void }

  clickContent(): void {
    if (this.clickContentCb) {
      this.clickContentCb()
    }
  }
}
