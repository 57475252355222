























import { Component, Prop, Vue } from "vue-property-decorator"
import TitleView from "@/views/commons/TitleView.vue"
import ContentView from "@/views/commons/ContentView.vue"
import BookmarkCard from "../generic_components/BookmarkCard.vue"
import { TAG_TYPES_FOR_BOOKMARK_CARD } from "@/model/bean/EnumHelper"
import { ProjetAnnuelDTO, UtilisateurDTO } from "@/model/bean/GeneratedDTOs"

@Component({
  components: { TitleView, ContentView, BookmarkCard },
})
export default class EtablissementProjectCard extends Vue {
  @Prop() project: ProjetAnnuelDTO
  @Prop() loggedUser: UtilisateurDTO

  tagType = TAG_TYPES_FOR_BOOKMARK_CARD.JOIN
  projectJoined = false

  joinProject(): void {
    this.projectJoined = true
    this.project.enseignants.push(this.loggedUser)
    if (this.project.consentementValide === null) {
      this.project.consentementValide = []
    }
    if (this.loggedUser.id) {
      this.project.consentementValide.push(this.loggedUser.id)
    }
  }

  leaveProject(): void {
    this.projectJoined = false
    this.project.enseignants = this.project.enseignants.filter(
      (ens) => ens.id != this.loggedUser.id
    )
    this.project.consentementValide = this.project.consentementValide.filter(
      (id) => id != this.loggedUser.id
    )
  }
}
