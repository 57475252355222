













import { EtablissementDTO, ProjetAnnuelDTO, UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import { LoginService } from "@/services/rest/login/LoginService"
import { ProjectService } from "@/services/rest/project/ProjectService"
import ProjectCard from "@/views/projects_pp/ProjectCard.vue"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"

@Component({
  components: {
    ProjectCard,
  },
})
export default class EtablissementProjetsView extends Vue {
  @Prop() etablissement!: EtablissementDTO

  private loginService = LoginService.INSTANCE
  private projectService = ProjectService.INSTANCE

  loggedUser: UtilisateurDTO = new UtilisateurDTO()
  etablissementProjects: ProjetAnnuelDTO[] = []

  async created(): Promise<void> {
    await this.fetchProjects()
  }

  @Watch("etablissement")
  async onEtablissementChanged(newVal: EtablissementDTO, oldVal: EtablissementDTO): Promise<void> {
    if (newVal !== oldVal) {
      await this.fetchProjects()
    }
  }

  private async fetchProjects(): Promise<void> {
    const loggedUser = this.loginService.getLoggedUser()
    if (!loggedUser) {
      return
    }
    this.loggedUser = loggedUser
    this.etablissementProjects = await this.projectService.getEtablissementProjects(
      this.etablissement
    )
  }
}
