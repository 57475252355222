/*-
 * #%L
 * Aires Éducatives
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
import {
  FilterDTO,
  ProjetAnnuelDTO,
  CourrielContactCreateDTO,
  StatutDossier,
  PorteursProjetEmailListDTO,
} from "@/model/bean/GeneratedDTOs"

import { AbstractTypedRestService } from "@/services/rest/AbstractTypedRestService"
import { Filters } from "@/model/bean/Filters"

export class EmailService extends AbstractTypedRestService<ProjetAnnuelDTO> {
  static INSTANCE = new EmailService()

  async getLabellisationEmail(statut: StatutDossier, projetAnnuelId?: string): Promise<string> {
    return super.getString("projets/" + projetAnnuelId + "/labellisationEmail/" + statut)
  }

  async getPPNumbersToSendTo(
    projectsId: string | undefined,
    filtersList: Array<FilterDTO> | undefined,
    graeId: string | undefined
  ): Promise<PorteursProjetEmailListDTO> {
    const params = new Map<string, string | Array<any>>()
    if (projectsId) params.set("projectId", projectsId)

    if (filtersList) {
      // Convert filters (remove unused attributes)
      const cleanFiltersList = new Array<FilterDTO>()
      filtersList.forEach((filter) => {
        cleanFiltersList.push(
          Filters.asFilter({ property: filter.property, values: filter.values })
        )
      })

      if (graeId) {
        cleanFiltersList.push(Filters.asFilter({ property: "grae", values: [graeId] }))
      }

      params.set("filters", cleanFiltersList)
    }

    return await super.typedGetSingleV2<PorteursProjetEmailListDTO>(
      "courriels/porteursNumber",
      2,
      params
    )
  }

  replaceDoubleQuotes(html: string) {
    return html.replace(/style="(.*?)"/g, "style='$1'")
  }

  async sendCourriel(
    projectsId: string | undefined,
    filtersList: Array<FilterDTO> | undefined,
    courriel: CourrielContactCreateDTO,
    graeId: string | undefined
  ): Promise<void> {
    // Correction problème espacement si  retour à la ligne
    courriel.contenu = courriel.contenu.replace(/<\/p>\s*(<p>|<p>\s*<br>\s*<\/p>)/g, "<br>")
    // Correction problème de l'échappement des caractères
    courriel.contenu = this.replaceDoubleQuotes(courriel.contenu)
    console.log("courriel.contenu", courriel.contenu)
    if (projectsId) courriel.projetId = projectsId

    if (filtersList) {
      // Convert filters (remove unused attributes)
      const cleanFiltersList = new Array<FilterDTO>()
      filtersList.forEach((filter) => {
        cleanFiltersList.push(
          Filters.asFilter({ property: filter.property, values: filter.values })
        )
      })

      if (graeId) {
        cleanFiltersList.push(Filters.asFilter({ property: "grae", values: [graeId] }))
      }
      courriel.filters = cleanFiltersList
    }

    // Ensure courriel.destinataires is an array of strings
    courriel.destinataires = courriel.destinataires || []

    return await super.typedPostWithDifferentResultTypeV2("courriels", 2, courriel)
  }
}
