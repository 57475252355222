













































































































































import {
  EtatProjet,
  ProjetAnnuelDTO,
  ProjetInscriptionDTO,
  ProjetPatchDTO,
  UtilisateurDTO,
} from "@/model/bean/GeneratedDTOs"
import InstructionPannel from "@/pages/instruction/side-pannel/InstructionPannel.vue"
import ProjectValidationAndSaveHandler, {
  ProjetAnnualValidation,
} from "@/pages/projet-page/commons/ProjectValidationAndSaveHandler.vue"
import ProjetInscriptionHolder from "@/pages/projet-page/inscription/ProjetInscriptionHolder.vue"
import ProjetLabellisationHolder from "@/pages/projet-page/labellisation/ProjetLabellisationHolder.vue"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import { LoginService } from "@/services/rest/login/LoginService"
import { ProjectService } from "@/services/rest/project/ProjectService"
import { InstructionService } from "@/services/rest/instruction/InstructionService"
import { UsersService } from "@/services/rest/users/UsersService"
import ContentView from "@/views/commons/ContentView.vue"
import PageView from "@/views/commons/PageView.vue"
import LoadingSkeleton from "@/views/generic_components/LoadingSkeleton.vue"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import ProjetYearAndStatutSelection from "./ProjetYearAndStatutSelection.vue"
import ProjectPDFExport from "./ProjectPDFExport.vue"

const SCROLL_BAR_WIDTH = 15

@Component({
  components: {
    PageView,
    ContentView,
    ProjectValidationAndSaveHandler,
    ProjetYearAndStatutSelection,
    ProjetLabellisationHolder,
    ProjetInscriptionHolder,
    LoadingSkeleton,
    InstructionPannel,
    ProjectPDFExport,
  },
})
export default class ProjetDetailsPage extends Vue {
  private infoReportingService = InfoReportingService.INSTANCE
  private loginService = LoginService.INSTANCE
  private projetService = ProjectService.INSTANCE
  private usersService = UsersService.INSTANCE
  private instructionService = InstructionService.INSTANCE

  @Prop() projectId: string
  @Prop() annualProjectId: string
  @Prop({ default: "bref" }) section: string
  @Prop({ default: "0" }) activeTab: number

  dirty = false
  withRedirect = false
  loggedUser?: UtilisateurDTO = new UtilisateurDTO()
  isLoading = true
  isSaving = false
  project = new ProjetAnnuelDTO()
  lastSaveProject = new ProjetAnnuelDTO()
  readonly = false
  isPorteurProjet = false
  canSeeInstructionPannel = false
  currentWidth = 0
  instructionPannelWidth = 0
  instructionPannelAutoCloseWidth = 350
  currentScrollY = 0
  pdfExportRequested = false
  exportingPDF = false
  withPhotos = false
  pxFromBottom = 900
  instructionCollapsed = true
  showValidationErrors = false
  mapDataUrl = ""
  imagesUrl = []

  // Projet state & periods
  etats: EtatProjet[] = []
  etatsInitiaux: EtatProjet[] = ["ACTIF", "EN_PAUSE", "ARCHIVE"]

  /**************** VALIDATION ****************/
  // Indicates if validation should be launched or not
  validationActivated = false
  // Last validation results
  lastProjectValidation: ProjetAnnualValidation = new ProjetAnnualValidation()
  // Last validation date (used to requets new validations to the ProjectValidationAndSaveHandler)
  lastValidationDate = new Date()

  modalShown = false
  mainTitle = ""
  isActive = true
  mobileShow = true
  role = ""

  async created(): Promise<void> {
    const loggedUser = await this.loginService.getLoggedUser()
    if (!loggedUser || !loggedUser.id) {
      return
    }

    this.loggedUser = loggedUser
    this.role = this.loggedUser ? this.loggedUser.profil : "SUPER_ADMINISTRATEUR"
    this.reloadProjectFromServer()
    this.listenToSubTitleEdition()
    document.getElementById("menu")?.addEventListener("menu-expand", () => {
      this.collapseResizablePannel(this.instructionCollapsed, true, false)
    })
  }

  destroyed(): void {
    this.isActive = false
    const leftPane = document.getElementById("panel-left")
    leftPane?.removeEventListener("scroll", this.trackScroll)
    window.removeEventListener("resize", this.activateResizablePannel)
  }

  mounted(): void {
    this.activateResizablePannel()

    const leftPane = document.getElementById("panel-left")
    if (leftPane) {
      // Listen to scroll to be able to update active anchor
      leftPane.addEventListener("scroll", this.trackScroll)
      this.setCurrentScrollYAndComputepxFromBottom(leftPane.scrollTop)
    }

    window.addEventListener("resize", this.activateResizablePannel)
    this.isActive = true
  }

  async getProjetFromId(loggeduser?: UtilisateurDTO, annualProjectId?: string): Promise<void> {
    try {
      this.isPorteurProjet =
        this.loggedUser != undefined && this.usersService.isPorteurProjet(this.loggedUser)
      this.role == "SUPER_ADMINISTRATEUR"
        ? (this.canSeeInstructionPannel = true)
        : (this.canSeeInstructionPannel = !this.isPorteurProjet)

      if (this.canSeeInstructionPannel) {
        this.project = await this.instructionService.getProject(annualProjectId)
      } else {
        this.project = await this.projetService.getProjetAnnuel(annualProjectId)
      }

      this.lastSaveProject = JSON.parse(JSON.stringify(this.project))
      this.etats = this.etatsInitiaux.filter((etat) => etat !== this.project.etat)
      this.isLoading = false

      // Compute readonly
      this.readonly = this.projetService.isProjetReadOnly(this.loggedUser, this.project, true)
      if (this.readonly) {
        this.validationActivated = false
        this.showValidationErrors = !this.isPorteurProjet
      } else if (this.project.typeDossier == "DOSSIER_INSCRIPTION") {
        // If not read-only, inscription validation should be on
        this.validationActivated = true
        this.showValidationErrors = false
      } else {
        // Remember if project validation should be launched
        const wasValidationActivatedLastTime =
          localStorage.getItem("validate-project-" + this.projectId + "-" + loggeduser?.id) ==
          "true"
        this.validationActivated = wasValidationActivatedLastTime
        this.showValidationErrors = this.validationActivated
      }
      // Project may have changed, relaunch validation if validation is activated
      this.$nextTick(this.activateResizablePannel)
      this.$nextTick(this.triggerValidation)
    } catch (e: unknown) {
      this.infoReportingService.error(this.$t("projet.not-allowed").toString(), e as Error)
      this.$router.push(LoginService.INSTANCE.getPageToRedirectToAfterLogin(this.loggedUser))
    }

    this.updateMainPageTitle()
  }

  @Watch("canSeeInstructionPannel")
  refreshInstructionPannelDragg(): void {
    this.$nextTick(this.activateResizablePannel)
  }

  async saveProject(): Promise<void> {
    this.isSaving = true
    try {
      this.project = await this.projetService.saveProject(this.project)
      this.$root.$emit("project-saved")
      this.dirty = false
      this.lastSaveProject = JSON.parse(JSON.stringify(this.project))
      this.isSaving = false
      this.infoReportingService.success(this.$t("projet.save-success").toString())
      if (this.withRedirect) {
        this.$router.push("/myProjects")
      }
      this.withRedirect = false
    } catch (e) {
      this.infoReportingService.error(this.$t("projet.save-error").toString(), e as Error)
      this.isSaving = false
      this.dirty = true
    }
  }

  async sendInscription(): Promise<void> {
    try {
      this.isSaving = true
      this.project = await this.projetService.submitProjetInscription(
        this.project as ProjetInscriptionDTO
      )
      this.isSaving = false
      this.infoReportingService.success(this.$t("projet.submit-inscription-sent").toString())
    } catch (e) {
      this.infoReportingService.error(
        this.$t("projet.submit-inscription-error").toString(),
        e as Error
      )
      this.isSaving = false
    }
  }

  async sendLabellisation(): Promise<void> {
    try {
      this.isSaving = true
      this.project = await this.projetService.submitProjetLabellisation(this.project)
      this.isSaving = false
      this.infoReportingService.success(this.$t("projet.submit-labellisation-success").toString())
    } catch (e) {
      this.infoReportingService.error(
        this.$t("projet.submit-labellisation-error").toString(),
        e as Error
      )
      this.isSaving = false
    }
  }

  dirtifyProject(withRedirect = false): void {
    // If not already dirty, determine if we are now dirty
    if (!this.dirty) {
      this.showValidationErrors = this.validationActivated
      const projectDidChange = JSON.stringify(this.project) != JSON.stringify(this.lastSaveProject)
      if (projectDidChange) {
        this.dirty = true
        this.withRedirect = this.withRedirect || withRedirect
      }
    }
  }

  @Watch("annualProjectId")
  annualProjectIdChanged(): void {
    this.reloadProjectFromServer()
  }

  async reloadProjectFromServer(): Promise<void> {
    // Reload project as posted on server
    this.isLoading = true
    await this.getProjetFromId(this.loggedUser, this.annualProjectId)
  }

  projetAnnuelValidationChanged(validation: ProjetAnnualValidation): void {
    this.lastProjectValidation = validation
  }

  @Watch("section")
  sectionChanged(): void {
    // Scroll to top
    setTimeout(function () {
      window.focus()
      window.scrollTo(0, 0)
      const leftPane = document.getElementById("panel-left")
      leftPane?.scrollTo(0, 0)
    }, 150)

    // Update validation
    this.triggerValidation()

    this.updateMainPageTitle()
  }

  updateMainPageTitle(): void {
    // Update page title : use accompagneur version if relevant other wise default tile
    let accompagneurMainTitle = null
    if (this.loggedUser && !this.usersService.isPorteurProjet(this.loggedUser)) {
      accompagneurMainTitle = this.$t(`projet.menu.${this.section}-accompagnateur`).toString()
    }
    if (accompagneurMainTitle && accompagneurMainTitle.indexOf("-accompagnateur") == -1) {
      // Use renewal suffix if relevant
      if (this.project.typeDossier !== "DOSSIER_LABELLISATION") {
        const renewalMainTitle = this.$t(
          `projet.menu.${this.section}-accompagnateur-renewal`
        ).toString()
        if (renewalMainTitle && renewalMainTitle.indexOf("-renewal") == -1) {
          this.mainTitle = renewalMainTitle
        } else {
          this.mainTitle = accompagneurMainTitle
        }
      } else {
        this.mainTitle = accompagneurMainTitle
      }
    } else {
      // Use renewal suffix if relevant
      if (this.project.typeDossier !== "DOSSIER_LABELLISATION") {
        const renewalMainTitle = this.$t(`projet.menu.${this.section}-renewal`).toString()
        if (renewalMainTitle && renewalMainTitle.indexOf("-renewal") == -1) {
          this.mainTitle = renewalMainTitle
        } else {
          this.mainTitle = this.$t(`projet.menu.${this.section}`).toString()
        }
      } else {
        this.mainTitle = this.$t(`projet.menu.${this.section}`).toString()
      }
    }

    console.log("mainTitle => " + this.mainTitle)
    console.log("accompagneurMainTitle => " + accompagneurMainTitle)
  }

  triggerValidation(): void {
    if (this.validationActivated) {
      this.lastValidationDate = new Date()
    }
  }

  togglevalidation(): void {
    this.validationActivated = !this.validationActivated
    this.showValidationErrors = this.showValidationErrors && this.validationActivated
    // Remember that validation was activated or not so that user does n
    localStorage.setItem(
      "validate-project-" + this.projectId + "-" + this.loggedUser?.id,
      this.validationActivated.toString()
    )
    this.$nextTick(this.triggerValidation)
  }

  listenToSubTitleEdition(): void {
    this.$root.$on("sub-title-edited", (editedSubTitle: string) => {
      if (this.isActive) {
        this.project.nom = editedSubTitle
        let patch = { nom: editedSubTitle } as ProjetPatchDTO
        this.projetService
          .patchProject(this.projectId, patch)
          .then(() => this.reloadProjectFromServer())
      }
    })
  }

  // A function is used for dragging and moving
  activateResizablePannel(): void {
    const leftPane = document.getElementById("panel-left")
    const separator = document.getElementById("panel-separator")
    const rightPane = document.getElementById("panel-right")
    const projectSave = document.getElementById("project-save")

    if (leftPane && separator && rightPane && projectSave) {
      this.instructionPannelWidth = rightPane.offsetWidth
      this.currentWidth = leftPane.offsetWidth

      this.collapseResizablePannel(false, false, true)
      this.relocateProjectButtonsAndGalery(rightPane.clientWidth + "px", leftPane.offsetWidth)

      separator.onmousedown = (e: Event) => {
        const maxWidth = Math.max(
          (leftPane.offsetWidth + rightPane.offsetWidth + separator.offsetWidth) / 2,
          420
        )
        const minWidth = 64
        var md = {
          e,
          offsetLeft: separator.offsetLeft,
          offsetTop: separator.offsetTop,
          firstWidth: leftPane.offsetWidth,
          secondWidth: rightPane.offsetWidth,
        }

        document.onmousemove = (e: Event) => {
          if (md && md.e) {
            // @ts-ignore
            var delta = { x: e.clientX - md.e.clientX, y: e.clientY - md.e.clientY }
            // Prevent negative-sized elements
            delta.x = Math.min(Math.max(delta.x, -md.firstWidth), md.secondWidth)

            if (md.secondWidth - delta.x < maxWidth && md.secondWidth - delta.x >= minWidth) {
              separator.style.left = md.offsetLeft + delta.x + "px"
              leftPane.style.width = md.firstWidth + delta.x + "px"
              rightPane.style.width = md.secondWidth - delta.x + "px"
              projectSave.style.width = md.firstWidth + delta.x - SCROLL_BAR_WIDTH + "px"

              this.currentWidth = parseInt(leftPane.style.width)
              this.instructionPannelWidth = parseInt(rightPane.style.width)
              this.relocateProjectButtonsAndGalery(
                rightPane.style.width.toString(),
                parseInt(leftPane.style.width)
              )
            }
          }
        }
        document.onmouseup = () => {
          document.onmousemove = document.onmouseup = null
          const currentRightPaneWidth = parseInt(rightPane.style.width)
          if (currentRightPaneWidth < this.instructionPannelAutoCloseWidth) {
            this.instructionCollapsed = true
            const fullWidth = leftPane.offsetWidth + rightPane.offsetWidth + separator.offsetWidth
            const leftPaneWidth = fullWidth - separator.offsetWidth - minWidth
            leftPane.style.width = leftPaneWidth + "px"
            separator.style.left = leftPaneWidth + "px"
            rightPane.style.width = minWidth + "px"
            projectSave.style.width = leftPaneWidth - SCROLL_BAR_WIDTH + "px"
            this.currentWidth = parseInt(leftPane.style.width)
            this.instructionPannelWidth = parseInt(rightPane.style.width)
            this.relocateProjectButtonsAndGalery(
              rightPane.style.width.toString(),
              parseInt(leftPane.style.width)
            )
          } else {
            this.instructionCollapsed = false
          }
        }
      }
    }
    let galery = document.getElementById("carousel-gallery-column")
    if (galery && leftPane) {
      galery.style.maxWidth = Math.floor(leftPane.offsetWidth / 2 - 15) + "px"
    }
  }

  /**
   * Expans or collapses the pannel
   */
  collapseResizablePannel(collapse: boolean, force: boolean, initialLoad: boolean): void {
    const leftPane = document.getElementById("panel-left")
    const separator = document.getElementById("panel-separator")
    const rightPane = document.getElementById("panel-right")
    const splitter = document.getElementById("splitter")
    const projectSave = document.getElementById("project-save")

    // Only expand if collapsed, otherwise keep current width
    if (
      collapse ||
      this.instructionPannelWidth < this.instructionPannelAutoCloseWidth ||
      force ||
      initialLoad
    ) {
      if (separator && leftPane && rightPane && splitter && projectSave) {
        const fullWidth = splitter.offsetWidth
        let rightWidth = 64
        this.instructionCollapsed = collapse
        if (!collapse) {
          rightWidth = Math.max(fullWidth / 3, 420)
        }
        if (initialLoad && Math.max(fullWidth) < 800) {
          rightWidth = 64
          this.instructionCollapsed = true
        }
        separator.style.left = fullWidth - separator.offsetWidth - rightWidth + "px"
        leftPane.style.width = fullWidth - separator.offsetWidth - rightWidth + "px"
        rightPane.style.width = rightWidth + "px"
        projectSave.style.width =
          fullWidth - separator.offsetWidth - rightWidth - SCROLL_BAR_WIDTH + "px"

        this.currentWidth = parseInt(leftPane.style.width)
        this.instructionPannelWidth = parseInt(rightPane.style.width)
        this.relocateProjectButtonsAndGalery(
          rightPane.style.width.toString(),
          parseInt(leftPane.style.width)
        )
      }
    }
  }

  relocateProjectButtonsAndGalery(rightString: string, leftPaneSize: number): void {
    let right = parseInt(rightString.replace("px", ""))

    // On small resolutions, relcoate projet buttons to make sure 'close' cross is never displayed
    if (leftPaneSize + right < 1300) {
      right += 140
    }
    // On small resolutions, hide projet buttons when pannel is expanded
    let hideProjectButtons: boolean = leftPaneSize < 600 && right > 500
    let stateIndicator = document.getElementById("project-state")?.firstChild
    // let saveButton = document.getElementById("project-save")
    // if (saveButton) {
    //   saveButton.style.right = parseInt(right) + 30 + "px"
    // }
    if (stateIndicator as HTMLElement) {
      if (!hideProjectButtons) {
        ;(stateIndicator as HTMLElement).style.display = "block"
        ;(stateIndicator as HTMLElement).style.right = right + "px"
      } else {
        ;(stateIndicator as HTMLElement).style.display = "none"
      }
    } else {
      setTimeout(() => {
        let stateIndicator = document.getElementById("project-state")?.firstChild
        if (!hideProjectButtons) {
          ;(stateIndicator as HTMLElement).style.display = "block"
          ;(stateIndicator as HTMLElement).style.right = right + "px"
        } else {
          ;(stateIndicator as HTMLElement).style.display = "none"
        }
      }, 600)
    }

    let galery = document.getElementById("carousel-gallery-column")
    if (galery) {
      galery.style.maxWidth = Math.floor(this.currentWidth / 2 - 15) + "px"
    }
  }

  trackScroll(): void {
    const leftPane = document.getElementById("panel-left")
    if (leftPane) {
      this.setCurrentScrollYAndComputepxFromBottom(leftPane.scrollTop)
    }
  }

  setCurrentScrollYAndComputepxFromBottom(scrollY: number): void {
    const leftPane = document.getElementById("panel-left")
    const maxHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight,
      leftPane ? leftPane.scrollHeight : 0
    )
    this.pxFromBottom = 900
    if (scrollY > 0) {
      this.pxFromBottom = maxHeight - window.innerHeight - scrollY
    }
    this.currentScrollY = scrollY
  }
}
