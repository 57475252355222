var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ProjectCard",
      on: {
        click: function($event) {
          return _vm.redirectToProjectDetails()
        }
      }
    },
    [
      _c("div", { staticClass: "ProjectCard-card" }, [
        _c("div", { class: "ProjectCard-header " + _vm.projectNature }, [
          _c("span", { staticClass: "ProjectCard-type" }, [
            _c(
              "div",
              {
                staticClass: "BookmarkCard-tag",
                style: {
                  "padding-bottom":
                    _vm.project.statut == "ACCEPTE_SOUS_RESERVE_REFERENT"
                      ? "60px"
                      : ""
                }
              },
              [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.projectService.getProjectStatusLabel(
                        _vm.project,
                        _vm.loggedUser
                      )
                    )
                  }
                })
              ]
            )
          ]),
          _vm.projectState
            ? _c(
                "span",
                {
                  attrs: {
                    title:
                      _vm.$t("myProjects.filter-labels.etats") +
                      " : " +
                      _vm.$t(_vm.project.etat)
                  }
                },
                [
                  _vm.projectState === "active"
                    ? _c(
                        "div",
                        { class: "ProjectCard-state " + _vm.projectState },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: "play-circle",
                              size: "is-medium",
                              type: "is-success"
                            }
                          }),
                          _vm._v(
                            " " + _vm._s(_vm.$t("myProjects.active")) + " "
                          )
                        ],
                        1
                      )
                    : _vm.projectState === "paused"
                    ? _c(
                        "div",
                        { class: "ProjectCard-state " + _vm.projectState },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: "pause-circle",
                              size: "is-medium",
                              type: "is-warning"
                            }
                          }),
                          _vm._v(
                            " " + _vm._s(_vm.$t("myProjects.paused")) + " "
                          )
                        ],
                        1
                      )
                    : _vm.projectState === "stopped"
                    ? _c(
                        "div",
                        { class: "ProjectCard-state " + _vm.projectState },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: "stop-circle",
                              size: "is-medium",
                              type: "is-danger"
                            }
                          }),
                          _vm._v(
                            " " + _vm._s(_vm.$t("myProjects.stopped")) + " "
                          )
                        ],
                        1
                      )
                    : _c("b-icon", {
                        attrs: {
                          icon: "play",
                          size: "is-medium",
                          type: "is-danger"
                        }
                      })
                ],
                1
              )
            : _vm._e()
        ]),
        _c("div", { staticClass: "ProjectCard-content" }, [
          _c(
            "div",
            { staticClass: "ProjectCard-buttons" },
            [
              _vm.project.typeDossier == "DOSSIER_INSCRIPTION" &&
              _vm.project.etat != "ARCHIVE"
                ? _c("b-button", {
                    staticClass: "ProjectCard-delete-button",
                    attrs: {
                      type: "is-danger",
                      "icon-left": "trash",
                      title: _vm.$t("ProjectCard.delete"),
                      rounded: ""
                    },
                    on: { click: _vm.deleteProject }
                  })
                : _vm._e(),
              _c(
                "b-button",
                {
                  staticClass: "ProjectCard-consult-button",
                  attrs: { type: "is-primary", rounded: "" }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.projectService.getProjectConsultActionLabel(
                          _vm.project,
                          _vm.loggedUser
                        )
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          ),
          _vm.project.etablissements.length > 0
            ? _c("div", { staticClass: "ProjectCard-text" }, [
                _vm._v(" " + _vm._s(_vm.project.etablissements[0].nom) + " ")
              ])
            : _vm._e(),
          _c("div", { staticClass: "ProjectCard-text" }, [
            _vm._v(_vm._s(_vm.project.natureAireEducative))
          ]),
          _c("div", { staticClass: "ProjectCard-text name" }, [
            _vm._v(_vm._s(_vm.project.nom))
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }