




































































import { FinancementDTO, ProjetAnnuelDTO } from "@/model/bean/GeneratedDTOs"
import { Component, Prop, Vue } from "vue-property-decorator"
import { FinancementService } from "@/services/rest/financement/FinancementService"
import ProjetEnseignantsView from "@/pages/projet-page/info-administratives/ProjetEnseignantsView.vue"
import FinancementCard from "@/views/project/FinancementCard.vue"

@Component({
  components: {
    ProjetEnseignantsView,
    FinancementCard,
  },
})
export default class ProjetFinancementView extends Vue {
  @Prop() projetAnnuel: ProjetAnnuelDTO
  @Prop() readonly: boolean
  @Prop({ default: false }) validationActivated: boolean
  @Prop({ default: true }) showValidationErrors: boolean

  private financementService = FinancementService.INSTANCE

  added(financement: FinancementDTO): void {
    this.projetAnnuel.financements.push(financement)
    this.$emit("dirtify-project")
  }

  deleted(financement: FinancementDTO): void {
    this.projetAnnuel.financements = this.projetAnnuel.financements.filter(function (value) {
      return value !== financement
    })
    this.$emit("dirtify-project")
  }

  isValid(financement: FinancementDTO): boolean {
    return this.financementService.isValid(this.projetAnnuel, financement.debut, financement.fin)
  }
}
