var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationProvider", {
    attrs: {
      vid: (_vm.group != "" ? _vm.group + "_" : "") + _vm.vid,
      name: _vm.$attrs.name || _vm.$attrs.label,
      rules: _vm.rulesWithoutRequiredRulesIfValidationDisabled
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var errors = ref.errors
          var valid = ref.valid
          return [
            _c(
              "b-field",
              {
                staticClass: "SelectWithValidation-field",
                attrs: {
                  type: {
                    "is-danger": _vm.showValidationErrors && errors[0],
                    "is-success": valid
                  },
                  message: _vm.showValidationErrors ? [errors[0]] : ""
                }
              },
              [
                _c(
                  "template",
                  { slot: "label" },
                  [
                    _c("FormInputLabel", {
                      attrs: {
                        validationActivated: _vm.validationActivated,
                        showValidationErrors: _vm.showValidationErrors,
                        label: _vm.$attrs.label,
                        isMandatory: _vm.isMandatory,
                        disabled: _vm.disabled,
                        inputNotice: _vm.inputNotice,
                        tooltip: _vm.tooltip
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-select",
                  {
                    attrs: {
                      placeholder: _vm.placeholder,
                      disabled: _vm.disabled
                    },
                    model: {
                      value: _vm.innerValue,
                      callback: function($$v) {
                        _vm.innerValue = $$v
                      },
                      expression: "innerValue"
                    }
                  },
                  _vm._l(_vm.data, function(option, i) {
                    return _c(
                      "option",
                      { key: option, domProps: { value: option } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.selectLabels && _vm.selectLabels.length !== 0
                                ? _vm.selectLabels[i]
                                : option
                            ) +
                            " "
                        )
                      ]
                    )
                  }),
                  0
                )
              ],
              2
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }