





















































import {
  ProjetAnnuelDTO,
  UtilisateurDTO,
  EvaluationDTO,
  ProjetAnnuelForInstructionDTO,
} from "@/model/bean/GeneratedDTOs"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import { ProjectService } from "@/services/rest/project/ProjectService"
import { NavbarItem } from "@/views/menus/MenuItems"
import { Component, Prop, Vue } from "vue-property-decorator"
import InstructionPannelInscriptionEvaluation from "./commons/inscription/InstructionPannelInscriptionEvaluation.vue"
import InstructionPannelContacts from "./commons/InstructionPannelContacts.vue"
import InstructionPannelEchanges from "./commons/InstructionPannelEchanges.vue"
import InstructionPannelLabellisationEvaluationFinale from "./commons/labellisation/InstructionPannelLabellisationEvaluationFinale.vue"
import InstructionPannelLabellisationMonEvaluation from "./commons/labellisation/InstructionPannelLabellisationMonEvaluation.vue"
import InstructionPannelLabellisationQualification from "./commons/labellisation/InstructionPannelLabellisationQualification.vue"
import InstructionPannelMenu from "./InstructionPannelMenu.vue"

@Component({
  components: {
    InstructionPannelMenu,
    InstructionPannelContacts,
    InstructionPannelEchanges,
    InstructionPannelLabellisationQualification,
    InstructionPannelLabellisationMonEvaluation,
    InstructionPannelLabellisationEvaluationFinale,
    InstructionPannelInscriptionEvaluation,
  },
})
export default class InstructionPannel extends Vue {
  @Prop() project: ProjetAnnuelDTO
  @Prop() instructionPannelWidth: number
  @Prop() minVisibleWidth: number
  @Prop() loggedUser: UtilisateurDTO

  inforReportingService = InfoReportingService.INSTANCE

  projectService = ProjectService.INSTANCE
  activeItem: NavbarItem = new NavbarItem("", "", "", false)

  newItemSelected(newSelectedItem: NavbarItem): void {
    this.activeItem = newSelectedItem
    this.$emit("new-menu-selected")
  }

  async save(evaluation: EvaluationDTO): Promise<void> {
    //save evaluation
    const newEval = await this.projectService.updateEvaluation(this.project, evaluation)

    //replace evaluation in projetAnnuel avec returned Value
    const projectForInstruction = this.project as ProjetAnnuelForInstructionDTO
    projectForInstruction.evaluations.forEach((evaluation) => {
      if (evaluation.id === newEval.id) {
        evaluation = newEval
      }
    })
  }
}
