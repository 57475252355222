import { render, staticRenderFns } from "./EtablissementProjectCard.vue?vue&type=template&id=0fd0b452&"
import script from "./EtablissementProjectCard.vue?vue&type=script&lang=ts&"
export * from "./EtablissementProjectCard.vue?vue&type=script&lang=ts&"
import style0 from "./EtablissementProjectCard.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/ofb/sagae/src/main/webapp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0fd0b452')) {
      api.createRecord('0fd0b452', component.options)
    } else {
      api.reload('0fd0b452', component.options)
    }
    module.hot.accept("./EtablissementProjectCard.vue?vue&type=template&id=0fd0b452&", function () {
      api.rerender('0fd0b452', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/projects_pp/EtablissementProjectCard.vue"
export default component.exports