var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("GroupCheckboxesWithValidation", {
        attrs: {
          label: _vm.$t("instruction.thematique-traitee"),
          data: _vm.allThematiquesLabels(),
          verticalDisplay: "true",
          inputLabel: _vm.$t("instruction.AUTRE"),
          extInputValue: _vm.internalProjetAnnuel.thematiqueAutre,
          vid: "thematiques",
          disabled: _vm.readOnly
        },
        on: {
          "manual-change": _vm.changeThematiques,
          "input-change": _vm.changeThematiqueAutre
        },
        model: {
          value: _vm.checkedThematiquesLabels,
          callback: function($$v) {
            _vm.checkedThematiquesLabels = $$v
          },
          expression: "checkedThematiquesLabels"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }