













import { Component, Prop, Vue } from "vue-property-decorator"

export class DocLink {
  url: string
  text: string

  constructor(url: string, text: string) {
    this.url = url
    this.text = text
  }
}
@Component
export default class DocumentationLinks extends Vue {
  @Prop() links: DocLink[]
}
