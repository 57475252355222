/*-
 * #%L
 * Sagae
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
import { FinancementDTO, ProjetAnnuelDTO } from "@/model/bean/GeneratedDTOs"
import { AbstractTypedRestService } from "@/services/rest/AbstractTypedRestService"

export class FinancementService extends AbstractTypedRestService<FinancementDTO> {
  static INSTANCE = new FinancementService()

  isValid(projetAnnuel: ProjetAnnuelDTO, debut: Date, fin: Date): boolean {
    const debutAnneeScolaire = new Date(projetAnnuel.anneeScolaireDebut)
    const finAnneeScolaire = new Date(projetAnnuel.anneeScolaireFin)
    const financementDebut = new Date(debut)
    const financementFin = new Date(fin)

    const debutDansAnneeScolaire =
      debutAnneeScolaire <= financementDebut && financementDebut < finAnneeScolaire
    const finDansAnneeScolaire =
      debutAnneeScolaire <= financementFin && financementFin < finAnneeScolaire
    const anneeScolaireDansPeriode =
      debutAnneeScolaire > financementDebut && financementFin >= finAnneeScolaire
    const aVenir = finAnneeScolaire <= financementDebut

    return debutDansAnneeScolaire || finDansAnneeScolaire || anneeScolaireDansPeriode || aVenir
  }
}
