var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationProvider", {
    attrs: {
      vid: (_vm.group != "" ? _vm.group + "_" : "") + _vm.vid,
      name: _vm.$attrs.name || _vm.$attrs.label,
      rules: _vm.rulesWithoutRequiredRulesIfValidationDisabled
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(ref) {
            var errors = ref.errors
            var valid = ref.valid
            return [
              _c(
                "b-field",
                {
                  staticClass: "InputWithValidation-field",
                  attrs: {
                    type: {
                      "is-normal": _vm.disabled,
                      "is-danger":
                        _vm.showValidationErrors &&
                        (errors[0] || _vm.customError.length > 0),
                      "is-success": _vm.showValidationErrors && valid
                    },
                    message: _vm.showValidationErrors
                      ? [errors + _vm.customError]
                      : ""
                  }
                },
                [
                  _c(
                    "template",
                    { slot: "label" },
                    [
                      _c("FormInputLabel", {
                        attrs: {
                          validationActivated: _vm.validationActivated,
                          showValidationErrors: _vm.showValidationErrors,
                          label: _vm.$attrs.label,
                          isMandatory: _vm.isMandatory,
                          disabled: _vm.disabled,
                          inputNotice: _vm.inputNotice,
                          tooltip: _vm.tooltip
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      class:
                        "InputWithValidation-control-container " +
                        (_vm.controlRight ? "control-right" : "")
                    },
                    [
                      _vm.control
                        ? _c(
                            "p",
                            { staticClass: "InputWithValidation-control" },
                            [
                              _c("span", { staticClass: "button is-static" }, [
                                _vm._v(_vm._s(_vm.control))
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm.controlAction
                        ? _c(
                            "p",
                            { staticClass: "InputWithValidation-control" },
                            [_vm._t("default")],
                            2
                          )
                        : _vm._e(),
                      _c("b-input", {
                        ref: "inputField",
                        attrs: {
                          placeholder: _vm.placeholder,
                          type: _vm.$attrs.type,
                          "password-reveal": _vm.passwordReveal,
                          maxlength: _vm.maxlength,
                          disabled: _vm.disabled,
                          "custom-class": _vm.disabled
                            ? "InputWithValidation-disabled"
                            : "auto-expand",
                          autocomplete: _vm.autocomplete
                        },
                        on: { input: _vm.adjustTextAreaHeight },
                        model: {
                          value: _vm.innerValue,
                          callback: function($$v) {
                            _vm.innerValue = $$v
                          },
                          expression: "innerValue"
                        }
                      })
                    ],
                    1
                  )
                ],
                2
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }