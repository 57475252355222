var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.profileId
        ? _c("ProfileModale", {
            attrs: { userId: _vm.profileId, open: _vm.openProfileModale },
            on: {
              "close-modale": function($event) {
                _vm.openProfileModale = false
              }
            }
          })
        : _vm._e(),
      _c("MenuView", {
        ref: "menuView",
        attrs: {
          id: "menu",
          loggedUser: _vm.loggedUser,
          mainTitle: _vm.mainTitle,
          subTitle: _vm.subTitle,
          subTitleIcon: _vm.subTitleIcon,
          subTitleIsEditable: _vm.subTitleIsEditable,
          displaySubTitleInGreen: _vm.displaySubTitleInGreen
        },
        on: { "log-out": _vm.logout, "open-profile": _vm.openProfile }
      }),
      _c(
        "div",
        {
          class:
            "App-content " +
            (!this.loggedUser || !this.loggedUser.id ? "logged-out" : "")
        },
        [
          _c("VerticalMenus", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loggedUser && _vm.loggedUser.id,
                expression: "loggedUser && loggedUser.id"
              }
            ],
            ref: "verticalMenus",
            attrs: {
              loggedUser: _vm.loggedUser,
              contextMenuProp: _vm.contextMenu,
              menuExpanded: _vm.menuExpanded,
              currentScreenName: _vm.currentScreenName
            },
            on: {
              "main-menu-expand": _vm.expandMenu,
              "context-menu-expand": _vm.expandMenu,
              "show-sub-menu": _vm.showHideSubMenu
            }
          }),
          _c(
            "div",
            {
              staticClass: "main",
              class: {
                "sub-menu":
                  _vm.loggedUser &&
                  _vm.loggedUser.id &&
                  _vm.showSubMenu &&
                  !_vm.menuExpanded,
                "sub-menu-open":
                  _vm.loggedUser &&
                  _vm.loggedUser.id &&
                  _vm.showSubMenu &&
                  _vm.menuExpanded,
                "main-menu-open":
                  _vm.loggedUser &&
                  _vm.loggedUser.id &&
                  !_vm.showSubMenu &&
                  _vm.menuExpanded
              }
            },
            [_c("router-view")],
            1
          )
        ],
        1
      ),
      _c("MatomoView")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }