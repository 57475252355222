
























import { Component, Prop, Vue } from "vue-property-decorator"
import TitleView from "@/views/commons/TitleView.vue"
import ContentView from "@/views/commons/ContentView.vue"
import BookmarkCard from "../generic_components/BookmarkCard.vue"
import { TAG_TYPES_FOR_BOOKMARK_CARD } from "@/model/bean/EnumHelper"
import { UtilisateurDTO } from "@/model/bean/GeneratedDTOs"

@Component({
  components: { TitleView, ContentView, BookmarkCard },
})
export default class GraeMemberDemandeCard extends Vue {
  @Prop() userDemande: UtilisateurDTO
  @Prop() accept: { (user: UtilisateurDTO): void }
  @Prop() reject: { (user: UtilisateurDTO): void }

  tagType = TAG_TYPES_FOR_BOOKMARK_CARD.VALIDATION
  projectJoined = false

  acceptDemande(): void {
    if (this.accept) {
      this.accept(this.userDemande)
    }
  }

  rejectDemande(): void {
    if (this.reject) {
      this.reject(this.userDemande)
    }
  }
}
