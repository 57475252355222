var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "year-navigation" },
      [
        _c(
          "b-button",
          {
            staticClass: "button is-primary",
            attrs: {
              disabled:
                _vm.selectedAnneeScolaire ===
                _vm.anneesScolaires[_vm.anneesScolaires.length - 1],
              outlined: ""
            },
            on: { click: _vm.previousYear }
          },
          [_vm._v(" " + _vm._s(_vm.$t("graePage.details.projets.precedent")))]
        ),
        _c(
          "b-select",
          {
            on: {
              input: function($event) {
                return _vm.goToYear()
              }
            },
            model: {
              value: _vm.selectedAnneeScolaire,
              callback: function($$v) {
                _vm.selectedAnneeScolaire = $$v
              },
              expression: "selectedAnneeScolaire"
            }
          },
          _vm._l(_vm.anneesScolaires, function(option) {
            return _c(
              "option",
              {
                key: option,
                domProps: {
                  value: option,
                  selected: option === _vm.selectedAnneeScolaire
                }
              },
              [_vm._v(" " + _vm._s(option) + " ")]
            )
          }),
          0
        ),
        _c(
          "b-button",
          {
            staticClass: "button is-primary",
            attrs: {
              disabled: _vm.selectedAnneeScolaire === _vm.anneesScolaires[0],
              outlined: ""
            },
            on: { click: _vm.nextYear }
          },
          [_vm._v(" " + _vm._s(_vm.$t("graePage.details.projets.suivant")))]
        )
      ],
      1
    ),
    _c("div", { staticClass: "indicateurs-container" }, [
      _c("div", { staticClass: "indicateurs-column" }, [
        _c(
          "div",
          { staticClass: "indicateurs-block bordered main-indicateur" },
          [
            _c("div", { staticClass: "svg-item" }, [
              _c(
                "svg",
                {
                  staticClass: "donut",
                  attrs: { width: "100%", height: "100%", viewBox: "0 0 40 40" }
                },
                [
                  _c("circle", {
                    staticClass: "donut-hole",
                    attrs: {
                      cx: "20",
                      cy: "20",
                      r: "15.91549430918954",
                      fill: "#fff"
                    }
                  }),
                  _c("circle", {
                    staticClass: "donut-ring",
                    attrs: {
                      cx: "20",
                      cy: "20",
                      r: "15.91549430918954",
                      fill: "transparent",
                      "stroke-width": "3.5"
                    }
                  }),
                  _c("circle", {
                    staticClass: "donut-segment donut-segment-2",
                    attrs: {
                      cx: "20",
                      cy: "20",
                      r: "15.91549430918954",
                      fill: "transparent",
                      "stroke-width": "3.5",
                      "stroke-dasharray": _vm.dasharray,
                      "stroke-dashoffset": "25"
                    }
                  }),
                  _c("g", { staticClass: "donut-text donut-text-1" }, [
                    _c(
                      "text",
                      { attrs: { y: "50%", transform: "translate(0, 2)" } },
                      [
                        _c(
                          "tspan",
                          {
                            staticClass: "donut-percent",
                            attrs: { x: "50%", "text-anchor": "middle" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  Number.isNaN(
                                    _vm.graeStats.nbATE + _vm.graeStats.nbAME
                                  )
                                    ? " "
                                    : _vm.graeStats.nbATE + _vm.graeStats.nbAME
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "text",
                      { attrs: { y: "60%", transform: "translate(0, 2)" } },
                      [
                        _c(
                          "tspan",
                          {
                            staticClass: "donut-data",
                            attrs: { x: "50%", "text-anchor": "middle" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("IndicateursPage.projets-actifs")
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    )
                  ])
                ]
              )
            ]),
            _c("div", { staticClass: "column" }, [
              _c("div", { staticClass: "line" }, [
                _c("span", { staticClass: "ate rounded" }, [
                  _vm._v(_vm._s(_vm.graeStats.nbATE))
                ]),
                _c("img", {
                  staticClass: "logo",
                  attrs: { src: "/img/scarabee.svg", alt: "Scarabée" }
                }),
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("IndicateursPage.ate-actives")))
                ])
              ]),
              _c("div", { staticClass: "line" }, [
                _c("span", { staticClass: "ame rounded" }, [
                  _vm._v(_vm._s(_vm.graeStats.nbAME))
                ]),
                _c("img", {
                  staticClass: "logo",
                  attrs: { src: "/img/poisson.svg", alt: "Poisson" }
                }),
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("IndicateursPage.ame-actives")))
                ])
              ])
            ])
          ]
        ),
        _c("div", { staticClass: "indicateurs-block" }, [
          _c(
            "div",
            { staticClass: "indicateurs-card bordered" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      path: "/grae/details/0/" + _vm.grae.id,
                      query: {
                        filters: _vm.inscriptionsFilter
                      }
                    }
                  }
                },
                [
                  _c("div", { staticClass: "title bleu" }, [
                    _vm._v(_vm._s(_vm.$t("IndicateursPage.inscription")))
                  ]),
                  _c("div", { staticClass: "indicateur" }, [
                    _vm._v(_vm._s(_vm.graeStats.inscriptions))
                  ]),
                  _c("div", { staticClass: "text-bold" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("IndicateursPage.nouveaux-inscrits")) +
                        " (" +
                        _vm._s(_vm.graeStats.anneeScolaireInscription) +
                        ") "
                    )
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "indicateurs-card bordered" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      path: "/grae/details/0/" + _vm.grae.id,
                      query: {
                        filters: _vm.labellisesFilters
                      }
                    }
                  }
                },
                [
                  _c("div", { staticClass: "title bleu" }, [
                    _vm._v(_vm._s(_vm.$t("IndicateursPage.labellisation")))
                  ]),
                  _c("div", { staticClass: "indicateur" }, [
                    _vm._v(
                      _vm._s(_vm.graeStats.labellisationsAccepteesCloturees)
                    )
                  ]),
                  _c("div", { staticClass: "text-bold" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("IndicateursPage.labellises")) +
                        " (" +
                        _vm._s(_vm.graeStats.anneeScolaire) +
                        ") "
                    )
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "indicateurs-card bordered" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      path: "/grae/details/0/" + _vm.grae.id,
                      query: {
                        filters: _vm.renouvellesFilters
                      }
                    }
                  }
                },
                [
                  _c("div", { staticClass: "title bleu" }, [
                    _vm._v(_vm._s(_vm.$t("IndicateursPage.renouvellement")))
                  ]),
                  _c("div", { staticClass: "indicateur" }, [
                    _vm._v(
                      _vm._s(_vm.graeStats.renouvellementsAcceptesClotures)
                    )
                  ]),
                  _c("div", { staticClass: "text-bold" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("IndicateursPage.renouvelles")) +
                        " (" +
                        _vm._s(_vm.graeStats.anneeScolaire) +
                        ") "
                    )
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "indicateurs-card bordered" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      path: "/grae/details/0/" + _vm.grae.id,
                      query: {
                        filters: _vm.refusesFilters
                      }
                    }
                  }
                },
                [
                  _c("div", { staticClass: "title bleu" }, [
                    _vm._v(_vm._s(_vm.$t("IndicateursPage.refus")))
                  ]),
                  _c("div", { staticClass: "indicateur" }, [
                    _vm._v(_vm._s(_vm.graeStats.projetsRefuses))
                  ]),
                  _c("div", { staticClass: "text-bold" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("IndicateursPage.refuses")) +
                        " (" +
                        _vm._s(_vm.graeStats.anneeScolaire) +
                        ") "
                    )
                  ])
                ]
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "indicateurs-block" }, [
          _c("div", { staticClass: "bordered centered" }, [
            _vm._v(
              " " +
                _vm._s(_vm.graeStats.pause) +
                " " +
                _vm._s(_vm.$t("IndicateursPage.pauses")) +
                " "
            )
          ]),
          _c("div", { staticClass: "bordered centered" }, [
            _vm._v(
              " " +
                _vm._s(_vm.graeStats.arrete) +
                " " +
                _vm._s(_vm.$t("IndicateursPage.arretes")) +
                " "
            )
          ])
        ])
      ]),
      this.selectedAnneeScolaire === this.anneesScolaires[0]
        ? _c("div", { staticClass: "indicateurs-column" }, [
            _c("div", { staticClass: "indicateurs-column bordered" }, [
              _c("h2", [
                _vm._v(_vm._s(_vm.$t("IndicateursPage.phase-inscription")))
              ]),
              _c(
                "div",
                { staticClass: "indicateurs-block" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "indicateurs-card bordered",
                      attrs: {
                        to: {
                          path: "/instruction/inscriptions/0/0/" + _vm.grae.id
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "title violet" }, [
                        _vm._v(_vm._s(_vm.$t("IndicateursPage.a-instruire")))
                      ]),
                      _c("div", { staticClass: "indicateur" }, [
                        _vm._v(_vm._s(_vm.graeStats.inscriptionsNonInstruites))
                      ]),
                      _c("div", { staticClass: "text" }, [
                        _vm._v(
                          _vm._s(_vm.$t("IndicateursPage.deposes-a-instruire"))
                        )
                      ])
                    ]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "indicateurs-card bordered",
                      attrs: {
                        to: {
                          path: "/grae/details/0/" + _vm.grae.id,
                          query: {
                            filters: _vm.brouillonFilters
                          }
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "title violet-pale" }, [
                        _vm._v(_vm._s(_vm.$t("IndicateursPage.brouillon")))
                      ]),
                      _c("div", { staticClass: "indicateur" }, [
                        _vm._v(_vm._s(_vm.graeStats.inscriptionsBrouillon))
                      ]),
                      _c("div", { staticClass: "text" }, [
                        _vm._v(
                          _vm._s(_vm.$t("IndicateursPage.dossiers-brouillon"))
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "indicateurs-column bordered" }, [
              _c("h2", [
                _vm._v(_vm._s(_vm.$t("IndicateursPage.phase-labellisation")))
              ]),
              _c(
                "div",
                { staticClass: "indicateurs-block" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "indicateurs-card bordered",
                      attrs: {
                        to: {
                          path: "/instruction/labellisations/1/2/" + _vm.grae.id
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "title marron" }, [
                        _vm._v(_vm._s(_vm.$t("IndicateursPage.instruits")))
                      ]),
                      _c("div", { staticClass: "indicateur" }, [
                        _vm._v(
                          _vm._s(
                            _vm.graeStats.labellisationsInstructionTerminee
                          )
                        )
                      ]),
                      _c("div", { staticClass: "text" }, [
                        _vm._v(
                          _vm._s(_vm.$t("IndicateursPage.deposes-instruits"))
                        )
                      ])
                    ]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "indicateurs-card bordered",
                      attrs: {
                        to: {
                          path: "/instruction/labellisations/0/2/" + _vm.grae.id
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "title orange" }, [
                        _vm._v(_vm._s(_vm.$t("IndicateursPage.a-instruire")))
                      ]),
                      _c("div", { staticClass: "indicateur" }, [
                        _vm._v(
                          _vm._s(_vm.graeStats.labellisationsAttenteInstructeur)
                        )
                      ]),
                      _c("div", { staticClass: "text" }, [
                        _vm._v(
                          _vm._s(_vm.$t("IndicateursPage.deposes-a-instruire"))
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "indicateurs-column bordered" }, [
              _c("div", { staticClass: "custom-card mt-5 contact-card" }, [
                _c("div", { staticClass: "custom-card-content" }, [
                  _c("div", { staticClass: "columns" }, [
                    _c(
                      "div",
                      { staticClass: "column is-2 pr-0 pb-0" },
                      [
                        _c("b-icon", {
                          staticClass: "pink",
                          attrs: { icon: "question-circle", size: "is-large" }
                        })
                      ],
                      1
                    ),
                    _c("div", { staticClass: "column pt-5 pl-0 pb-0" }, [
                      _c("span", { staticClass: "area-title" }, [
                        _vm._v(_vm._s(_vm.$t("projet.en-bref.une-question")))
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "columns" }, [
                    _c("div", { staticClass: "column is-2 pr-0 pt-0" }),
                    _c("div", { staticClass: "column pl-0 pt-0" }, [
                      _c("div", { staticClass: "columns" }, [
                        _c(
                          "div",
                          { staticClass: "column is-half" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass:
                                  "is-primary is-outlined is-rounded pl-6 pr-6",
                                attrs: {
                                  tag: "a",
                                  target: "_blank",
                                  href: "https://ame.ofb.fr/doku.php"
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("projet.en-bref.wiki")))]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "column is-half" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass:
                                  "is-primary is-outlined is-rounded pl-6 pr-6",
                                attrs: {
                                  tag: "a",
                                  target: "_blank",
                                  href: "https://ame.ofb.fr/doku.php?id=sagafaq"
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("projet.en-bref.faq")))]
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                ])
              ]),
              _c(
                "div",
                {
                  staticClass: "custom-card mt-5 contact-card",
                  staticStyle: { height: "210px" }
                },
                [
                  _c("div", { staticClass: "custom-card-content" }, [
                    _c("div", { staticClass: "columns" }, [
                      _c(
                        "div",
                        { staticClass: "column is-2 pr-0 pb-0" },
                        [
                          _c("b-icon", {
                            staticClass: "pink",
                            attrs: { icon: "address-card", size: "is-large" }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "column pt-5 pl-0 pb-0" }, [
                        _c("span", { staticClass: "area-title" }, [
                          _vm._v(_vm._s(_vm.$t("projet.en-bref.contact")))
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "columns" }, [
                      _c("div", { staticClass: "column is-2 pr-0 pt-0" }),
                      _c("div", { staticClass: "column pl-0 pt-0" }, [
                        _c("div", { staticClass: "columns" }, [
                          _vm.contact
                            ? _c("div", { staticClass: "column" }, [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(_vm.contact.prenom) +
                                      " " +
                                      _vm._s(_vm.contact.nom)
                                  )
                                ]),
                                _c("p", [
                                  _vm._v(_vm._s(_vm.$t(_vm.contact.profil)))
                                ]),
                                _c(
                                  "p",
                                  { staticClass: "mt-3" },
                                  [
                                    _c("b-icon", {
                                      staticClass: "pink",
                                      staticStyle: { "padding-right": "5px" },
                                      attrs: {
                                        icon: "phone",
                                        size: "is-medium"
                                      }
                                    }),
                                    _vm._v(_vm._s(_vm.contact.telephone) + " ")
                                  ],
                                  1
                                ),
                                _c("p", {}, [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        target: "_blank",
                                        href: "mailto:" + _vm.contact.courriel
                                      }
                                    },
                                    [
                                      _c("b-icon", {
                                        staticClass: "pink",
                                        staticStyle: { "padding-right": "5px" },
                                        attrs: {
                                          icon: "envelope-open-text",
                                          size: "is-medium"
                                        }
                                      }),
                                      _vm._v(
                                        " " + _vm._s(_vm.contact.courriel) + " "
                                      )
                                    ],
                                    1
                                  )
                                ])
                              ])
                            : _c("div", { staticClass: "column" }, [
                                _c("p", [
                                  _vm._v(_vm._s(_vm.$t("default.profil")))
                                ]),
                                _c(
                                  "p",
                                  { staticClass: "mt-3" },
                                  [
                                    _c("b-icon", {
                                      staticClass: "pink",
                                      staticStyle: { "padding-right": "5px" },
                                      attrs: {
                                        icon: "phone",
                                        size: "is-medium"
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("default.phone")) + " "
                                    )
                                  ],
                                  1
                                ),
                                _c("p", {}, [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        target: "_blank",
                                        href:
                                          "mailto:" + _vm.$t("default.email")
                                      }
                                    },
                                    [
                                      _c("b-icon", {
                                        staticClass: "pink",
                                        staticStyle: { "padding-right": "5px" },
                                        attrs: {
                                          icon: "envelope-open-text",
                                          size: "is-medium"
                                        }
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("default.email")) +
                                          " "
                                      )
                                    ],
                                    1
                                  )
                                ])
                              ])
                        ])
                      ])
                    ])
                  ])
                ]
              )
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }