




















import { ProjetAnnuelDTO, ProjetAnnuelForInstructionDTO } from "@/model/bean/GeneratedDTOs"
import { NavbarItem } from "@/views/menus/MenuItems"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { LoginService } from "@/services/rest/login/LoginService"

@Component({
  components: {},
})
export default class InstructionPannelMenu extends Vue {
  @Prop() project: ProjetAnnuelDTO

  loginService = LoginService.INSTANCE

  menuItems: NavbarItem[] = []

  created(): void {
    this.refreshMenuItems()
  }

  @Watch("project")
  projectChanged(): void {
    this.refreshMenuItems()
  }

  refreshMenuItems(): void {
    const newMenuItems = []
    newMenuItems.push(
      new NavbarItem(
        "contacts",
        this.$t("instruction-pannel.contacts.title").toString(),
        "users",
        false
      )
    )
    newMenuItems.push(
      new NavbarItem(
        "echanges",
        this.$t("instruction-pannel.echanges.title").toString(),
        "mail-bulk",
        false
      )
    )

    if (this.project.typeDossier == "DOSSIER_INSCRIPTION") {
      newMenuItems.push(
        new NavbarItem(
          "inscription-evaluation",
          this.$t("instruction-pannel.inscription-evaluation.title").toString(),
          "stamp",
          false
        )
      )
    } else {
      newMenuItems.push(
        new NavbarItem(
          "labellisation-qualification",
          this.$t("instruction-pannel.labellisation-qualification.title").toString(),
          "file",
          false
        )
      )

      //Accessible uniquement si cloturé (accepté, refusé ou en devenir)
      if (
        this.project &&
        (this.project.statut === "ACCEPTE" ||
          this.project.statut === "REFUSE" ||
          this.project.statut === "LABELLISATION_EN_DEVENIR")
      ) {
        newMenuItems.push(
          new NavbarItem(
            "labellisation-evaluation-finale",
            this.$t("instruction-pannel.labellisation-evaluation-finale.title").toString(),
            "stamp",
            false
          )
        )
      }

      //Accessible uniquement si non cloturé (accepté, refusé ou en devenir) et l'utilisateur est instructeur
      if (
        this.isInstructeur() &&
        this.project.statut !== "ACCEPTE" &&
        this.project.statut !== "REFUSE" &&
        this.project.statut !== "LABELLISATION_EN_DEVENIR"
      ) {
        newMenuItems.push(
          new NavbarItem(
            "labellisation-mon-evaluation",
            this.$t("instruction-pannel.labellisation-mon-evaluation.title").toString(),
            "check",
            false
          )
        )
      }
    }

    let previouslySelectedItemIndex = 0
    for (let i = 0; i < this.menuItems.length; i++) {
      if (this.menuItems[i].isLinkActive && i < newMenuItems.length) {
        previouslySelectedItemIndex = i
      }
    }
    this.menuItems = newMenuItems
    this.selectActiveItem(previouslySelectedItemIndex)
  }

  selectActiveItem(itemIndex: number): void {
    for (let i = 0; i < this.menuItems.length; i++) {
      const isActive = i == itemIndex
      this.menuItems[i].isLinkActive = isActive
      if (isActive) {
        this.$emit("item-selected", this.menuItems[i])
      }
    }
  }

  isInstructeur(): boolean {
    const user = this.loginService.getLoggedUser()

    const projectForInstruction = this.project as ProjetAnnuelForInstructionDTO

    let instructeur = false

    if (projectForInstruction.evaluations !== undefined) {
      projectForInstruction.evaluations.forEach((item) => {
        if (user !== undefined && item.instructeur.id === user.id) {
          instructeur = true
        }
      })
    }

    return instructeur
  }
}
