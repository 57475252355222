var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("TableFilterView", {
        attrs: {
          columns: _vm.columns,
          initialFilters: _vm.activeFilters,
          showExport: false
        },
        on: {
          "update-active-filters": _vm.updateActiveFilters,
          "update-visible-columns": _vm.updateVisibleColumns
        }
      }),
      _c(
        "b-table",
        {
          ref: _vm.tableRef,
          attrs: {
            striped: _vm.config.isStriped,
            hoverable: _vm.config.isHoverable,
            "debounce-search": _vm.config.searchDebounce,
            "aria-next-label": _vm.config.nextLabel(),
            "aria-previous-label": _vm.config.previousLabel(),
            "aria-page-label": _vm.config.pageLabel(),
            "aria-current-label": _vm.config.currentPageLabel(),
            "per-page": _vm.config.perPage,
            "sticky-header": _vm.config.stickyHeader,
            "current-page": _vm.currentPagedSortedParameter.pageNumber,
            "pagination-position": _vm.config.paginationPosition,
            paginated: "",
            "backend-pagination": "",
            "sort-icon": _vm.config.sortIcon,
            "sort-icon-size": _vm.config.sortIconSize,
            "backend-sorting": "",
            "sort-multiple": "",
            "sort-multiple-data": _vm.buefySortingPriority,
            total: _vm.total,
            data: _vm.etablissementsList,
            loading: _vm.isLoading
          },
          on: {
            sort: _vm.onSort,
            "sorting-priority-removed": _vm.sortingPriorityRemoved,
            "page-change": _vm.onPageChange
          }
        },
        [
          _vm._l(_vm.columns, function(column) {
            return [
              _c(
                "b-table-column",
                _vm._b(
                  {
                    key: column.id,
                    attrs: { visible: column.visible !== false },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(props) {
                            return [
                              column.field === "codeDepartement"
                                ? _c("div", [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          props.row["codeDepartement"].replace(
                                            /^0+/,
                                            ""
                                          )
                                        )
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              column.field === "grae.region"
                                ? _c("div", [
                                    props.row["grae"]
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(props.row["grae"].region)
                                          )
                                        ])
                                      : _vm._e()
                                  ])
                                : _vm._e(),
                              !column.custom
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "clickable",
                                      on: {
                                        click: function($event) {
                                          return _vm.showEtablissementListesProjetsPage(
                                            $event,
                                            props.row["id"]
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(_vm._s(props.row[column.field]))
                                      ])
                                    ]
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  "b-table-column",
                  column,
                  false
                )
              )
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }