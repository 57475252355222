/*-
 * #%L
 * Aires Éducatives
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
import { AbstractRestService } from "../AbstractRestService"
import { GraeStatsDTO } from "./../../../model/bean/GeneratedDTOs"

export class StatService extends AbstractRestService {
  public static INSTANCE = new StatService()

  async graesStats(): Promise<GraeStatsDTO[]> {
    return await super.typedGetV2<GraeStatsDTO>("stats/grae", 2)
  }

  async graeStats(graeId: string): Promise<GraeStatsDTO> {
    return await super.typedGetSingleV2<GraeStatsDTO>("stats/grae/" + graeId, 2)
  }

  async graeStatsForAnnee(graeId: string, anneeScolaire: string): Promise<GraeStatsDTO> {
    return await super.typedGetSingleV2<GraeStatsDTO>(
      "stats/grae/" + graeId + "/" + anneeScolaire,
      2
    )
  }
}
