var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.internalClasse.internal_id
    ? _c("ValidationObserver", {
        ref: "classeObserver",
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function(ref) {
                var handleSubmit = ref.handleSubmit
                return [
                  _c("BookmarkCard", {
                    attrs: { tagType: _vm.tagType, iconName: _vm.iconName },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "content",
                          fn: function() {
                            return [
                              _c("div", [
                                _vm.classeAdded
                                  ? _c(
                                      "div",
                                      [
                                        !_vm.readOnly
                                          ? _c("SelectWithValidation", {
                                              attrs: {
                                                label: _vm.$t(
                                                  "projetPage.infoAdministratives.classe.enseignement"
                                                ),
                                                data: _vm.enseignements,
                                                selectLabels:
                                                  _vm.enseignementsLabels,
                                                rules: "required",
                                                vid:
                                                  "validation-to-ignore_nouvelle_classe_enseignement_" +
                                                  _vm.internalClasse.internal_id
                                              },
                                              on: {
                                                input: function($event) {
                                                  return _vm.enseignementChanged()
                                                }
                                              },
                                              model: {
                                                value: _vm.enseignement,
                                                callback: function($$v) {
                                                  _vm.enseignement = $$v
                                                },
                                                expression: "enseignement"
                                              }
                                            })
                                          : _vm._e(),
                                        _vm.enseignement === "ELEMENTAIRE" &&
                                        !_vm.readOnly
                                          ? _c("SelectWithValidation", {
                                              attrs: {
                                                label: _vm.$t(
                                                  "projetPage.infoAdministratives.classe.type"
                                                ),
                                                data: _vm.types,
                                                selectLabels: _vm.typesLabels,
                                                rules: "required",
                                                disabled: _vm.readOnly,
                                                vid:
                                                  "classe_type_" +
                                                  _vm.internalClasse.internal_id
                                              },
                                              model: {
                                                value: _vm.type,
                                                callback: function($$v) {
                                                  _vm.type = $$v
                                                },
                                                expression: "type"
                                              }
                                            })
                                          : _vm._e(),
                                        !_vm.isNbNiveauValid && !_vm.readOnly
                                          ? _c("InputWithValidation", {
                                              staticClass: "invisible-input",
                                              attrs: {
                                                vid:
                                                  "classe_type_niveaux_" +
                                                  _vm.internalClasse
                                                    .internal_id,
                                                rules: "niveauValid"
                                              }
                                            })
                                          : _vm._e(),
                                        _vm.enseignement === "COLLEGE" ||
                                        _vm.enseignement === "LYCEE" ||
                                        _vm.type !== "" ||
                                        _vm.readOnly
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "projetPage.infoAdministratives.classe.effectifs"
                                                  )
                                                )
                                              )
                                            ])
                                          : _vm._e(),
                                        (_vm.enseignement === "ELEMENTAIRE"
                                        ? Boolean(_vm.type)
                                        : _vm.enseignement)
                                          ? _c(
                                              "div",
                                              _vm._l(
                                                Object.keys(
                                                  _vm.cursus[_vm.enseignement]
                                                ),
                                                function(eleves) {
                                                  return _c(
                                                    "div",
                                                    { key: eleves },
                                                    [
                                                      !_vm.readOnly ||
                                                      _vm.internalClasse[eleves]
                                                        ? _c("CheckBoxEntry", {
                                                            attrs: {
                                                              vid:
                                                                "validation-to-ignore_nouvelle_classe_nb_" +
                                                                _vm.cursus[
                                                                  _vm
                                                                    .enseignement
                                                                ][eleves] +
                                                                "_" +
                                                                _vm
                                                                  .internalClasse
                                                                  .internal_id,
                                                              isChecked: Boolean(
                                                                _vm
                                                                  .internalClasse[
                                                                  eleves
                                                                ]
                                                              ),
                                                              entryLabel: _vm.$t(
                                                                "projetPage.infoAdministratives.classe.niveauxLabels." +
                                                                  _vm.cursus[
                                                                    _vm
                                                                      .enseignement
                                                                  ][eleves]
                                                              ),
                                                              extraInput1Placeholder: _vm.$t(
                                                                "projetPage.infoAdministratives.classe.nb-placeholder"
                                                              ),
                                                              extraInput1Value:
                                                                _vm
                                                                  .internalClasse[
                                                                  eleves
                                                                ],
                                                              disabled:
                                                                _vm.readOnly ||
                                                                (_vm.niveau
                                                                  .length !==
                                                                  0 &&
                                                                  _vm.isNbNiveauValid &&
                                                                  _vm.niveau.indexOf(
                                                                    _vm.cursus[
                                                                      _vm
                                                                        .enseignement
                                                                    ][eleves]
                                                                  ) === -1 &&
                                                                  _vm.type !==
                                                                    "MULTI"),
                                                              extraInput1Rule:
                                                                "max_value:50",
                                                              hideExtrasUntilChecked: true
                                                            },
                                                            on: {
                                                              checked: function(
                                                                checked
                                                              ) {
                                                                return _vm.modifyClasse(
                                                                  checked,
                                                                  _vm.cursus[
                                                                    _vm
                                                                      .enseignement
                                                                  ][eleves]
                                                                )
                                                              },
                                                              "input-1": function(
                                                                value
                                                              ) {
                                                                _vm.internalClasse[
                                                                  eleves
                                                                ] = value
                                                                  ? parseInt(
                                                                      value
                                                                    )
                                                                  : 0
                                                              }
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                        ((_vm.enseignement === "ELEMENTAIRE"
                                          ? Boolean(_vm.type)
                                          : _vm.enseignement) &&
                                          !_vm.readOnly) ||
                                        Boolean(
                                          _vm.internalClasse.eleves_autre1
                                        )
                                          ? _c("CheckBoxEntry", {
                                              attrs: {
                                                vid:
                                                  "validation-to-ignore_nouvelle_classe_nb_autre_" +
                                                  _vm.internalClasse
                                                    .internal_id,
                                                isChecked: Boolean(
                                                  _vm.internalClasse
                                                    .eleves_autre1
                                                ),
                                                extraInput1Placeholder: _vm.$t(
                                                  "projetPage.infoAdministratives.classe.nb-placeholder"
                                                ),
                                                extraInput1Value:
                                                  _vm.internalClasse
                                                    .eleves_autre1,
                                                entryLabel: _vm.$t(
                                                  "projetPage.infoAdministratives.classe.niveauxLabels.AUTRE"
                                                ),
                                                entryInputValue:
                                                  _vm.internalClasse.autre1,
                                                entryInputPlaceholder: _vm.$t(
                                                  "projetPage.infoAdministratives.classe.autre-placeholder"
                                                ),
                                                disabled:
                                                  _vm.readOnly ||
                                                  (_vm.niveau.length !== 0 &&
                                                    _vm.isNbNiveauValid &&
                                                    _vm.niveau.indexOf(
                                                      "AUTRE"
                                                    ) === -1 &&
                                                    _vm.type !== "MULTI"),
                                                extraInput1Rule: "max_value:50",
                                                hideExtrasUntilChecked: true
                                              },
                                              on: {
                                                checked: function(checked) {
                                                  return _vm.modifyClasse(
                                                    checked,
                                                    "AUTRE"
                                                  )
                                                },
                                                "input-1": function(value) {
                                                  _vm.internalClasse.eleves_autre1 = value
                                                    ? parseInt(value)
                                                    : 0
                                                },
                                                "entry-input": function(value) {
                                                  _vm.internalClasse.autre1 = value
                                                }
                                              }
                                            })
                                          : _vm._e(),
                                        Boolean(
                                          _vm.internalClasse.eleves_autre2
                                        )
                                          ? _c("CheckBoxEntry", {
                                              attrs: {
                                                vid:
                                                  "validation-to-ignore_nouvelle_classe_nb_autre_" +
                                                  _vm.internalClasse
                                                    .internal_id,
                                                isChecked: Boolean(
                                                  _vm.internalClasse
                                                    .eleves_autre2
                                                ),
                                                extraInput1Placeholder: _vm.$t(
                                                  "projetPage.infoAdministratives.classe.nb-placeholder"
                                                ),
                                                extraInput1Value:
                                                  _vm.internalClasse
                                                    .eleves_autre2,
                                                entryLabel: _vm.$t(
                                                  "projetPage.infoAdministratives.classe.niveauxLabels.AUTRE"
                                                ),
                                                entryInputValue:
                                                  _vm.internalClasse.autre2,
                                                entryInputPlaceholder: _vm.$t(
                                                  "projetPage.infoAdministratives.classe.autre-placeholder"
                                                ),
                                                disabled: true,
                                                extraInput1Rule: "max_value:50",
                                                hideExtrasUntilChecked: true
                                              },
                                              on: {
                                                checked: function(checked) {
                                                  return _vm.modifyClasse(
                                                    checked,
                                                    "AUTRE"
                                                  )
                                                },
                                                "input-1": function(value) {
                                                  _vm.internalClasse.eleves_autre2 = value
                                                    ? parseInt(value)
                                                    : 0
                                                },
                                                "entry-input": function(value) {
                                                  _vm.internalClasse.autre2 = value
                                                }
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  : _c("p", [
                                      _c("br"),
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "projetPage.infoAdministratives.classe.description"
                                          )
                                        )
                                      ),
                                      _c("br"),
                                      _c("br")
                                    ])
                              ])
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "footer",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                { staticClass: "classe-footer" },
                                [
                                  !_vm.classeAdded
                                    ? _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            type: "is-primary is-outlined",
                                            rounded: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.classeAdded = true
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("create")) + " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.readOnly && _vm.classeAdded
                                    ? _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            type: "is-primary is-outlined",
                                            rounded: "",
                                            disabled: _vm.niveau.length <= 0
                                          },
                                          on: {
                                            click: function($event) {
                                              return handleSubmit(_vm.addClasse)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("add")) + " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.canDelete
                                    ? _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            type: "is-danger is-outlined",
                                            rounded: "",
                                            "icon-left": "trash"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteClasse()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("delete")) + " "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  })
                ]
              }
            }
          ],
          null,
          false,
          1961422384
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }