var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _vm.section === "inscriptions"
            ? _c(
                "SubHeaderItem",
                {
                  staticClass: "brouillons",
                  attrs: { icon: "exclamation-triangle", type: "is-danger" }
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "item-text",
                      attrs: {
                        to: {
                          path: "/grae/details/0/" + _vm.grae.id,
                          query: {
                            filters: _vm.brouillonFilters
                          }
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("instructionPage.projets-en-brouillon", [
                              this.nbBrouillons
                            ])
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.section === "labellisations"
            ? _c("SubHeaderItem", { staticClass: "brouillons" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("instructionPage.projets-en-labellisation", [
                        this.nbLabellisations
                      ])
                    ) +
                    " "
                )
              ])
            : _vm._e(),
          _vm.section === "inscriptions"
            ? _c(
                "SubHeaderItem",
                { staticClass: "dates", attrs: { icon: "calendar" } },
                [
                  _c("div", { staticClass: "item-text" }, [
                    _vm._v(_vm._s(_vm.$t("myProjects.dateInscription")))
                  ]),
                  _c("div", { staticClass: "item-text range" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("myProjects.dateInscriptionRange", [
                            _vm.formatDate(_vm.parametrage.debutInscription),
                            _vm.formatDate(_vm.parametrage.finInscription)
                          ])
                        ) +
                        " "
                    )
                  ])
                ]
              )
            : _vm._e(),
          _vm.section === "labellisations"
            ? _c(
                "SubHeaderItem",
                { staticClass: "dates", attrs: { icon: "calendar" } },
                [
                  _c("div", { staticClass: "item-text" }, [
                    _vm._v(_vm._s(_vm.$t("myProjects.dateLabellisation")))
                  ]),
                  _c("div", { staticClass: "item-text range" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("myProjects.dateInscriptionRange", [
                            _vm.formatDate(_vm.parametrage.debutLabellisation),
                            _vm.formatDate(_vm.parametrage.finLabellisation)
                          ])
                        ) +
                        " "
                    )
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "PageView",
        {
          attrs: {
            mainTitle: _vm.title,
            subTitle: _vm.subTitle,
            subTitleIcon: _vm.subTitleIcon,
            displaySubTitleInGreen: true
          }
        },
        [
          _c("ContentView", [
            _vm.section === "inscriptions"
              ? _c(
                  "div",
                  [
                    _vm.grae.id
                      ? _c("InscriptionsView", {
                          attrs: {
                            graeId: _vm.grae.id,
                            activeQuickFilter: _vm.activeQuickFilter
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm.section === "labellisations"
              ? _c(
                  "div",
                  [
                    _vm.grae.id
                      ? _c("LabellisationsView", {
                          attrs: {
                            graeId: _vm.grae.id,
                            activeSubTab: _vm.activeSubTab,
                            activeQuickFilter: _vm.activeQuickFilter
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }