var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    {
      staticClass: "main-navbar",
      class: { "main-navbar-unlogged": !_vm.loggedUser || !_vm.loggedUser.id },
      attrs: { "fixed-top": "", shadow: "" }
    },
    [
      _c(
        "template",
        { slot: "brand" },
        [
          !_vm.loggedUser || !_vm.loggedUser.id
            ? _c("b-navbar-item", { attrs: { tag: "div" } }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/rf.png"),
                    alt: "République Française"
                  }
                })
              ])
            : _vm._e(),
          _c(
            "b-navbar-item",
            {
              attrs: {
                href: "https://ofb.gouv.fr/",
                target: "_blank",
                title: "Office Français de la Biodiversité"
              }
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/ofb.png"),
                  alt: "Office Français de la Biodiversité"
                }
              })
            ]
          ),
          _c(
            "b-navbar-item",
            {
              attrs: {
                tag: "router-link",
                to: { path: "/" },
                title: _vm.$t("menu.home")
              }
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/ae.png"), alt: "SAGAE" }
              })
            ]
          )
        ],
        1
      ),
      _c("template", { slot: "start" }, [
        _c("div", { staticClass: "MenuView-titles" }, [
          _c(
            "div",
            { class: "MenuView-main-title " + (!_vm.subTitle ? "big" : "") },
            [
              !_vm.displaySubTitleInGreen
                ? _c("span", [_vm._v(_vm._s(_vm.mainTitle))])
                : _c("span", [_vm._v(_vm._s(_vm.subTitle))])
            ]
          ),
          _vm.subTitle
            ? _c("div", { staticClass: "MenuView-subtitle" }, [
                _c("img", {
                  staticClass: "menu-icon left",
                  attrs: { src: "/img/poisson.svg", alt: "Poisson" }
                }),
                !_vm.subTitleIsEditable
                  ? _c("span", { staticClass: "non-editable" }, [
                      !_vm.displaySubTitleInGreen
                        ? _c("span", [_vm._v(" " + _vm._s(_vm.subTitle) + " ")])
                        : _c("span", [
                            _vm._v(" " + _vm._s(_vm.mainTitle) + " ")
                          ])
                    ])
                  : _vm.subTitleIsEditable && !_vm.edit
                  ? _c(
                      "span",
                      {
                        staticClass: "editable",
                        on: { click: _vm.enterEdition }
                      },
                      [
                        _vm._v(_vm._s(_vm.subTitle) + " "),
                        _c("b-icon", {
                          staticStyle: { cursor: "pointer" },
                          attrs: { icon: "edit", size: "is-small" }
                        })
                      ],
                      1
                    )
                  : _c(
                      "span",
                      [
                        _c("ValidationObserver", {
                          ref: "observer",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var handleSubmit = ref.handleSubmit
                                  var pristine = ref.pristine
                                  var valid = ref.valid
                                  return [
                                    _c(
                                      "InlinedInputWithValidation",
                                      {
                                        attrs: {
                                          rules: "required|min:3|max:140",
                                          type: "text",
                                          placeholder: _vm.$t(
                                            "input-name-tooltip"
                                          ),
                                          maxlength: 140,
                                          controlAction: true,
                                          hasCounter: false
                                        },
                                        model: {
                                          value: _vm.editedSubTitle,
                                          callback: function($$v) {
                                            _vm.editedSubTitle = $$v
                                          },
                                          expression: "editedSubTitle"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticStyle: { display: "flex" } },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticStyle: {
                                                  "margin-right": "5px"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    _vm.edit = false
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("annuler"))
                                                )
                                              ]
                                            ),
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  type: "is-primary",
                                                  disabled: pristine || !valid
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return handleSubmit(
                                                      _vm.submit
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("valider")) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1247839131
                          )
                        })
                      ],
                      1
                    ),
                _vm.subTitle
                  ? _c("img", {
                      staticClass: "menu-icon right",
                      attrs: { src: "/img/scarabee.svg", alt: "Scarabée" }
                    })
                  : _vm._e()
              ])
            : _vm._e()
        ])
      ]),
      _c("template", { slot: "end" }, [
        _c(
          "div",
          { staticClass: "buttons" },
          [
            _c(
              "router-link",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.loggedUser || !_vm.loggedUser.id,
                    expression: "!loggedUser || !loggedUser.id"
                  }
                ],
                staticClass: "button is-primary",
                attrs: { to: "/register/user/0" }
              },
              [_c("strong", [_vm._v(" " + _vm._s(_vm.$t("register")))])]
            ),
            _vm.loggedUser && _vm.loggedUser.id
              ? _c(
                  "b-tooltip",
                  {
                    staticClass: "mr-2",
                    attrs: { label: _vm.$t("logout"), position: "is-left" }
                  },
                  [
                    _c("b-button", {
                      staticClass: "logout",
                      attrs: {
                        rounded: "",
                        outlined: "",
                        "icon-left": "power-off",
                        type: "is-danger"
                      },
                      on: {
                        click: function($event) {
                          return _vm.logout()
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.loggedUser && _vm.loggedUser.id
              ? _c(
                  "div",
                  {
                    staticClass: "profile-circle",
                    attrs: { role: "button" },
                    on: {
                      click: function($event) {
                        return _vm.emitOpenProfile()
                      }
                    }
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.getInitials(_vm.loggedUser)) + " ")
                    ])
                  ]
                )
              : _vm._e(),
            !_vm.loggedUser || !_vm.loggedUser.id
              ? _c(
                  "b-dropdown",
                  {
                    ref: "login-dropdown",
                    staticStyle: { height: "100%" },
                    attrs: {
                      position: "is-bottom-left",
                      "append-to-body": "",
                      "aria-role": "menu",
                      "trap-focus": ""
                    }
                  },
                  [
                    _vm.$route.name != "Login"
                      ? _c(
                          "a",
                          {
                            staticClass: "navbar-item",
                            attrs: { slot: "trigger", role: "button" },
                            slot: "trigger"
                          },
                          [
                            _c("span", [_vm._v(_vm._s(_vm.$t("login")))]),
                            _c("b-icon", { attrs: { icon: "angle-down" } })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-dropdown-item",
                      {
                        attrs: {
                          "aria-role": "menu-item",
                          focusable: false,
                          custom: "",
                          paddingless: ""
                        }
                      },
                      [
                        _c("LoginView", {
                          attrs: { embeded: true },
                          on: { "hide-drop-down": _vm.hideDropDown }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }