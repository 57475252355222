










import { Component, Vue } from "vue-property-decorator"
import LoginView from "@/views/login/LoginView.vue"
import TitleView from "@/views/commons/TitleView.vue"
import PageView from "@/views/commons/PageView.vue"
import ContentView from "@/views/commons/ContentView.vue"
import { LoginService } from "@/services/rest/login/LoginService"

@Component({
  components: { LoginView, ContentView, TitleView, PageView },
})
export default class LoginPage extends Vue {
  private loginService = LoginService.INSTANCE

  mounted(): void {
    this.redirectIfAlreadyLogged()
  }

  async redirectIfAlreadyLogged(): Promise<void> {
    const loggedUser = await this.loginService.getLoggedUser()
    if (loggedUser && loggedUser.id) {
      this.$router.push(this.loginService.getPageToRedirectToAfterLogin(loggedUser))
    }
  }
}
