var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      staticClass: "ContactModale",
      attrs: {
        active: _vm.open,
        "has-modal-card": "",
        "trap-focus": "",
        "aria-role": "dialog",
        "aria-label": "ContactModale",
        "aria-modal": "",
        width: "100vw"
      }
    },
    [
      [
        _c(
          "div",
          { staticClass: "modal-card" },
          [
            _c(
              "header",
              { staticClass: "modal-card-head" },
              [
                _c("H3TitleView", { staticClass: "modal-card-title" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("contactModale.title", [_vm.total]).toString()
                    ) + " "
                  )
                ])
              ],
              1
            ),
            _c("b-loading", {
              model: {
                value: _vm.isSending,
                callback: function($$v) {
                  _vm.isSending = $$v
                },
                expression: "isSending"
              }
            }),
            _c(
              "section",
              { staticClass: "modal-card-body" },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm
                        .$t("contactModale.message", [
                          _vm.sumPPCount,
                          _vm.total,
                          _vm.destinataireEnseignantCount,
                          _vm.destinataireReferentCount
                        ])
                        .toString()
                    ) +
                    " "
                ),
                _c("div", [
                  _c("label", { attrs: { for: "role-select" } }, [
                    _vm._v(_vm._s(_vm.$t("contactModale.destinataire")))
                  ]),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedRole,
                          expression: "selectedRole"
                        }
                      ],
                      attrs: { id: "role-select" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selectedRole = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { value: "Tous" } }, [
                        _vm._v("Tous")
                      ]),
                      _c("option", { attrs: { value: "Enseignants" } }, [
                        _vm._v("Enseignants")
                      ]),
                      _c("option", { attrs: { value: "Référents" } }, [
                        _vm._v("Référents")
                      ])
                    ]
                  )
                ]),
                _c("InputWithValidation", {
                  attrs: {
                    rules: "required",
                    label: _vm.$t("contactModale.courriel-title"),
                    maxlength: 150,
                    vid: "ContactModale-titre"
                  },
                  model: {
                    value: _vm.courriel.titre,
                    callback: function($$v) {
                      _vm.$set(_vm.courriel, "titre", $$v)
                    },
                    expression: "courriel.titre"
                  }
                }),
                _c("RichInputTextWithValidation", {
                  attrs: {
                    rules: "required",
                    label: _vm.$t("contactModale.courriel-body"),
                    maxlength: 1400,
                    type: "textarea",
                    vid: "ContactModale-body"
                  },
                  model: {
                    value: _vm.courriel.contenu,
                    callback: function($$v) {
                      _vm.$set(_vm.courriel, "contenu", $$v)
                    },
                    expression: "courriel.contenu"
                  }
                })
              ],
              1
            ),
            _c("footer", { staticClass: "modal-card-foot" }, [
              _c(
                "div",
                { staticClass: "buttons" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "hoverable-discrete-link mr-3",
                      on: { click: _vm.emitCloseModale }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("close-generic")) + " ")]
                  ),
                  _c("b-button", {
                    attrs: {
                      label: _vm.$t("contactModale.send").toString(),
                      type: "is-primary",
                      rounded: "",
                      disabled: _vm.isFormInvalid
                    },
                    on: { click: _vm.sendCourrielAndClose }
                  })
                ],
                1
              )
            ])
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }