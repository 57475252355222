








































































































import { ProjetAnnuelDTO, UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import MapDrawView from "@/views/maps/MapDrawView.vue"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import CarouselView from "./CarouselView.vue"
import CalendrierView from "./CalendrierView.vue"
import { GraeService } from "@/services/rest/grae/GraeService"

@Component({
  components: { InputWithValidation, MapDrawView, CarouselView, CalendrierView },
})
export default class EnBrefView extends Vue {
  @Prop() projetAnnuel: ProjetAnnuelDTO
  @Prop({ default: false }) readonly: boolean
  @Prop({ default: false }) isPorteurProjet: boolean
  @Prop({ default: false }) validationActivated: boolean
  @Prop({ default: true }) showValidationErrors: boolean
  @Prop() mapScreenShotRequested: boolean
  @Prop() section: string

  // Wait for focus before creating map to avoid< display issue
  shouldCreateMap = false
  private graeService = GraeService.INSTANCE

  contact: UtilisateurDTO = new UtilisateurDTO()

  created(): void {
    if (this.projetAnnuel.grae.id) {
      this.loadContact()
    }
    this.shouldCreateMap = this.section === "bref"
  }

  @Watch("section")
  activeSectionChanged(): void {
    this.shouldCreateMap = this.shouldCreateMap || this.section === "bref"
  }

  async loadContact(): Promise<void> {
    this.contact = await this.graeService.getContact(this.projetAnnuel.grae.id)
  }

  changeZoneGeographique(zoneGeographique: string): void {
    this.projetAnnuel.zoneGeographiqueChanged = true
    this.projetAnnuel.zoneGeographique = zoneGeographique
    this.$emit("dirtify-project")
  }

  modified(): void {
    this.$emit("dirtify-project")
  }
}
