var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BookmarkCard", {
    attrs: { tagType: _vm.tagType, iconName: "user" },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function() {
          return [
            _c("div", { staticClass: "EtablissementProjectCard-text name" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.userDemande.prenom) +
                  " " +
                  _vm._s(_vm.userDemande.nom) +
                  " "
              )
            ]),
            _c("div", { staticClass: "EtablissementProjectCard-text" }, [
              _vm._v(_vm._s(_vm.$t(_vm.userDemande.profil)))
            ]),
            _c("div", { staticClass: "EtablissementProjectCard-text" }, [
              _vm._v(_vm._s(_vm.userDemande.courriel))
            ])
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "buttons EtablissementProjectCard-buttons" },
              [
                _c("b-button", {
                  attrs: {
                    type: "is-danger",
                    "icon-left": "trash-alt",
                    rounded: "",
                    outlined: ""
                  },
                  on: { click: _vm.rejectDemande }
                }),
                _c(
                  "b-button",
                  {
                    attrs: { type: "is-primary", rounded: "" },
                    on: { click: _vm.acceptDemande }
                  },
                  [_vm._v(_vm._s(_vm.$t("accept")))]
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }