/*-
 * #%L
 * Aires Éducatives
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
import {
  EvaluationDTO,
  FilterDTO,
  PaginationParameter,
  PaginationResult,
  ProjetAnnuelForInstructionDTO,
  ProjetInscriptionDTO,
  QuickFilter,
  UtilisateurDTO,
} from "@/model/bean/GeneratedDTOs"
import { AbstractTypedRestService } from "@/services/rest/AbstractTypedRestService"

export class InstructionService extends AbstractTypedRestService<ProjetAnnuelForInstructionDTO> {
  static INSTANCE = new InstructionService()

  async getProjects(
    graeId: string | undefined,
    paginationParameters: PaginationParameter,
    filtersList: Array<FilterDTO>
  ): Promise<PaginationResult<ProjetAnnuelForInstructionDTO>> {
    const params = new Map<string, string | Array<any>>()
    if (graeId) params.set("graeId", graeId)
    const projets = await super.typeGetPaginatedV2<ProjetAnnuelForInstructionDTO>(
      "instruction/projets",
      2,
      paginationParameters,
      filtersList,
      params
    )
    return projets
  }

  async exportProjectsCsv(
    graeId: string | undefined,
    filtersList: Array<FilterDTO>
  ): Promise<void> {
    const params = new Map<string, string | Array<any>>()
    if (graeId) params.set("graeId", graeId)
    this.exportFileV2("instruction/export/csv", 2, filtersList, params)
  }

  async exportProjectsXls(
    graeId: string | undefined,
    filtersList: Array<FilterDTO>
  ): Promise<void> {
    const params = new Map<string, string | Array<any>>()
    if (graeId) params.set("graeId", graeId)
    this.exportFileV2("instruction/export/xls", 2, filtersList, params)
  }

  async getProject(projetAnnuelId: string | undefined): Promise<ProjetAnnuelForInstructionDTO> {
    return await super.typedGetSingleV2<ProjetAnnuelForInstructionDTO>(
      "instruction/projets/" + projetAnnuelId,
      2
    )
  }

  async getInscriptions(
    graeId: string | undefined,
    paginationParameters: PaginationParameter,
    filtersList: Array<FilterDTO>,
    quickFilter: QuickFilter | undefined
  ): Promise<PaginationResult<ProjetInscriptionDTO>> {
    const params = new Map<string, string | Array<any>>()
    if (graeId) params.set("graeId", graeId)
    if (quickFilter) params.set("quickFilter", quickFilter)
    const projets = await super.typeGetPaginatedV2<ProjetInscriptionDTO>(
      "instruction/inscriptions",
      2,
      paginationParameters,
      filtersList,
      params
    )
    return projets
  }

  async getLabellisations(
    graeId: string | undefined,
    paginationParameters: PaginationParameter,
    filtersList: Array<FilterDTO>,
    quickFilter: QuickFilter | undefined
  ): Promise<PaginationResult<ProjetAnnuelForInstructionDTO>> {
    const params = new Map<string, string | Array<any>>()
    if (graeId) params.set("graeId", graeId)
    if (quickFilter) params.set("quickFilter", quickFilter)
    const projets = await super.typeGetPaginatedV2<ProjetAnnuelForInstructionDTO>(
      "instruction/labellisations",
      2,
      paginationParameters,
      filtersList,
      params
    )
    return projets
  }

  async exportLabellisationsClosCsv(
    graeId: string | undefined,
    filtersList: Array<FilterDTO>
  ): Promise<void> {
    const params = new Map<string, string | Array<any>>()
    if (graeId) params.set("graeId", graeId)
    this.exportFileV2("instruction/labellisations/export/csv", 2, filtersList, params)
  }

  async exportLabellisationsClosXls(
    graeId: string | undefined,
    filtersList: Array<FilterDTO>
  ): Promise<void> {
    const params = new Map<string, string | Array<any>>()
    if (graeId) params.set("graeId", graeId)
    this.exportFileV2("instruction/labellisations/export/xls", 2, filtersList, params)
  }

  async saveEvaluationFinale(
    projetAnnuelId: string,
    evaluationFinale: EvaluationDTO
  ): Promise<EvaluationDTO> {
    return super.typedPutV2(
      "projets/labellisation/" + projetAnnuelId + "/eval-finale",
      2,
      evaluationFinale
    )
  }

  async approuveEvaluationFinale(projetAnnuelId: string): Promise<ProjetAnnuelForInstructionDTO> {
    return super.typedGetSingleV2(
      "projets/labellisation/" + projetAnnuelId + "/approuver-eval-finale",
      2
    )
  }

  async desapprouveEvaluationFinale(
    projetAnnuelId: string
  ): Promise<ProjetAnnuelForInstructionDTO> {
    return super.typedGetSingleV2(
      "projets/labellisation/" + projetAnnuelId + "/desapprouver-eval-finale",
      2
    )
  }

  async assignInstructeur(
    projetAnnuelId: string | undefined,
    instructeur: UtilisateurDTO
  ): Promise<ProjetAnnuelForInstructionDTO> {
    return await super.typedPostWithDifferentResultTypeV2(
      "projets/labellisation/" + projetAnnuelId + "/instructeur",
      2,
      instructeur
    )
  }

  async removeInstructeur(
    projetAnnuelId: string | undefined,
    instructeurId: string | undefined
  ): Promise<ProjetAnnuelForInstructionDTO> {
    return await super.typedDeleteSingleV2(
      "projets/labellisation/" + projetAnnuelId + "/instructeur/" + instructeurId,
      2
    )
  }

  async canEndLabellisationForGrae(graeId: string): Promise<boolean> {
    return super.typedGetSingleV2("projets/labellisation/" + graeId + "/can-end-instruction", 2)
  }

  async endLabellisationForGrae(graeId: string): Promise<boolean[]> {
    return super.typedGetV2("projets/labellisation/" + graeId + "/end-instruction", 2)
  }

  async deleteAllInscriptionsInBrouillon(): Promise<boolean[]> {
    return super.typedGetV2("instruction/inscriptions/delete-all-brouillons", 2)
  }
}
