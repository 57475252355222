var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BookmarkCard", {
    attrs: { tagType: _vm.tagType, iconName: "pencil-ruler" },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function() {
          return [
            _vm.project.etablissements[0]
              ? _c("div", { staticClass: "EtablissementProjectCard-text" }, [
                  _vm._v(" " + _vm._s(_vm.project.etablissements[0].nom) + " ")
                ])
              : _vm._e(),
            _c("div", { staticClass: "EtablissementProjectCard-text" }, [
              _vm._v(_vm._s(_vm.project.natureAireEducative))
            ]),
            _c("div", { staticClass: "EtablissementProjectCard-text name" }, [
              _vm._v(_vm._s(_vm.project.nom))
            ])
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "buttons EtablissementProjectCard-buttons" },
              [
                !_vm.projectJoined
                  ? _c(
                      "b-button",
                      {
                        attrs: { type: "is-primary", rounded: "" },
                        on: { click: _vm.joinProject }
                      },
                      [_vm._v(_vm._s(_vm.$t("join")))]
                    )
                  : _vm._e(),
                _vm.projectJoined
                  ? _c(
                      "div",
                      {
                        staticClass: "EtablissementProjectCard-joined",
                        on: { click: _vm.leaveProject }
                      },
                      [
                        _vm._v(" " + _vm._s(_vm.$t("myProjects.joined")) + " "),
                        _c("b-icon", {
                          attrs: {
                            icon: "check-circle",
                            size: "is-large",
                            type: "is-success"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }