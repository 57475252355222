











import { Component, Prop, Vue } from "vue-property-decorator"

@Component({})
export default class SubHeaderItem extends Vue {
  @Prop() icon: string
  @Prop() type: string
}
