var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "observer",
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(ref) {
            var validateWithInfo = ref.validateWithInfo
            return [
              !_vm.hidden
                ? _c(
                    "div",
                    {
                      class:
                        "bottom-buttons " +
                        (_vm.pxFromBottom <= 0 ? "move-up" : ""),
                      attrs: { id: "project-save" }
                    },
                    [
                      (_vm.project.typeDossier ==
                        "DOSSIER_RENOUVELLEMENT_LIGHT" ||
                        _vm.project.typeDossier == "DOSSIER_RENOUVELLEMENT" ||
                        _vm.project.typeDossier == "DOSSIER_LABELLISATION") &&
                      (_vm.project.statut == "ACCEPTE" ||
                        _vm.project.statut == "LABELLISATION_EN_DEVENIR") &&
                      _vm.hasDocument
                        ? _c(
                            "b-button",
                            {
                              attrs: { type: "is-primary" },
                              on: {
                                click: function($event) {
                                  return _vm.downloadDoc()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("projet.export-document")) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.project.typeDossier != "DOSSIER_INSCRIPTION"
                        ? _c(
                            "b-dropdown",
                            {
                              attrs: { position: "is-top-left" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "trigger",
                                    fn: function() {
                                      return [
                                        _c("b-button", {
                                          attrs: {
                                            label: _vm.$t(
                                              "projet.export-choices"
                                            ),
                                            type: "is-info",
                                            "icon-right": "menu-down"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { "aria-role": "listitem" },
                                  on: {
                                    click: function($event) {
                                      return _vm.exportCarnetDeBord()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("projet.export-odt")) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { "aria-role": "listitem" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("export-pdf", true)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("projet.export-pdf")) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { "aria-role": "listitem" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("export-pdf", false)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "projet.export-pdf-without-photos"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { "aria-role": "listitem" },
                                  on: {
                                    click: function($event) {
                                      return _vm.exportPiecesJointes()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("projet.export-pj-pdf")) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _c(
                            "b-button",
                            {
                              attrs: { type: "is-secondary" },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("export-pdf")
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("projet.export-single")) +
                                  " "
                              )
                            ]
                          ),
                      !_vm.readonly
                        ? _c(
                            "b-button",
                            {
                              attrs: {
                                type: "is-primary",
                                disabled: !_vm.dirty || _vm.isSaving
                              },
                              on: { click: _vm.saveProject }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("projet.save")) + " ")]
                          )
                        : _vm._e(),
                      _vm.project.typeDossier == "DOSSIER_INSCRIPTION" &&
                      _vm.project.statut == "BROUILLON" &&
                      !_vm.readonly &&
                      _vm.isPorteurProjet
                        ? _c(
                            "b-button",
                            {
                              attrs: {
                                type: "is-primary",
                                disabled:
                                  _vm.hasErrors ||
                                  _vm.isSaving ||
                                  !_vm.isPorteurProjet ||
                                  _vm.dirty
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("send-inscription")
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("projet.send-inscription")) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.project.typeDossier !== "DOSSIER_INSCRIPTION" &&
                      _vm.project.statut == "BROUILLON" &&
                      !_vm.readonly &&
                      !_vm.outsideLabellisationPeriod &&
                      _vm.isPorteurProjet
                        ? _c(
                            "b-button",
                            {
                              attrs: {
                                type: "is-primary",
                                disabled: !_vm.isPorteurProjet
                              },
                              on: {
                                click: function($event) {
                                  return _vm.sendLabellisation()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("projet.send-labellisation")) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _c("div", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        on: {
                          click: function($event) {
                            return _vm.newValidationRequest(validateWithInfo)
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._t("default")
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }