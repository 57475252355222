
























import { Component, Prop, Vue } from "vue-property-decorator"
import { ValidationObserver } from "vee-validate"
import RadioWithInputAndValidation from "@/views/inputs/RadioWithInputAndValidation.vue"
import { ProfilUtilisateur } from "@/model/bean/GeneratedDTOs"

@Component({
  components: {
    ValidationObserver,
    RadioWithInputAndValidation,
  },
})
export default class ProfilSelectionView extends Vue {
  @Prop() value: ProfilUtilisateur

  profils: ProfilUtilisateur[] = [
    "ENSEIGNANT",
    "REFERENT",
    "ACCOMPAGNATEUR_REGIONAL",
    "OBSERVATEUR_NATIONAL",
    "ACCOMPAGNATEUR_NATIONAL",
  ]

  profilsLabels = this.profils.map((profil) => {
    return this.$t(
      `registerPage.profil-label-${profil.toLowerCase().replace(/_/g, "-")}`
    ).toString()
  })
  selectedProfil: ProfilUtilisateur = this.profils[0]
  helpNotices = this.profils.map((profil) => {
    return this.$t(`registerPage.tooltip-${profil.toLowerCase().replace(/_/g, "-")}`).toString()
  })

  created(): void {
    if (this.value) {
      this.selectedProfil = this.value
    }
  }

  submit(): void {
    this.$emit("input-profil", this.selectedProfil)
  }

  goBack(): void {
    this.$emit("go-back", this.selectedProfil)
  }
}
