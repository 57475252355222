var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "quick-filter" },
    [
      _c(
        "b-button",
        {
          staticClass: "left-button",
          attrs: { type: _vm.computeType("MY_ASSIGNMENTS"), rounded: "" },
          on: {
            click: function($event) {
              return _vm.change("MY_ASSIGNMENTS")
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("quick-filter.assigne-a-moi")))]
      ),
      _vm.showUnassigned
        ? _c(
            "b-button",
            {
              staticClass: "middle-button",
              attrs: { type: _vm.computeType("NOT_ASSIGNED"), rounded: "" },
              on: {
                click: function($event) {
                  return _vm.change("NOT_ASSIGNED")
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("quick-filter.non-assigne")))]
          )
        : _vm._e(),
      _c(
        "b-button",
        {
          class: {
            "middle-button": !_vm.showUnassigned,
            "right-button": _vm.showUnassigned
          },
          attrs: { type: _vm.computeType(undefined), rounded: "" },
          on: {
            click: function($event) {
              return _vm.change(undefined)
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("quick-filter.tous-les-projets")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }