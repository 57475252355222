var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "observer",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var handleSubmit = ref.handleSubmit
          return [
            _c("p", { staticClass: "mb-3" }, [
              _vm._v(
                _vm._s(_vm.$t("registerPage.selection-competences-indications"))
              )
            ]),
            _c("GroupCheckboxesWithValidation", {
              attrs: {
                rules: "required",
                data: _vm.competencesStrings,
                verticalDisplay: "true"
              },
              on: { "manual-change": _vm.changeCompetences },
              model: {
                value: _vm.checkedCompetenceStrings,
                callback: function($$v) {
                  _vm.checkedCompetenceStrings = $$v
                },
                expression: "checkedCompetenceStrings"
              }
            }),
            _c(
              "div",
              { staticClass: "buttons mt-5" },
              [
                _c(
                  "b-button",
                  { attrs: { type: "is-dark" }, on: { click: _vm.goBack } },
                  [_vm._v(" " + _vm._s(_vm.$t("registerPage.precedent")) + " ")]
                ),
                _c(
                  "b-button",
                  {
                    attrs: { type: "is-primary" },
                    on: {
                      click: function($event) {
                        return handleSubmit(_vm.submit)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("registerPage.suivant")) + " ")]
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }