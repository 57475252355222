







































import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import PasswordCreationWithValidation from "@/views/inputs/PasswordCreationWithValidation.vue"
import TitleView from "@/views/commons/TitleView.vue"
import { ValidationObserver } from "vee-validate"
import { LoginService } from "@/services/rest/login/LoginService"
import { UtilisateurDTO, PasswordCheckDTO, PasswordResetDTO } from "@/model/bean/GeneratedDTOs"

@Component({
  components: {
    ValidationObserver,
    InputWithValidation,
    PasswordCreationWithValidation,
    TitleView,
  },
})
export default class PasswordReset extends Vue {
  @Prop() user: UtilisateurDTO
  @Prop({ default: true }) withOldPwd: boolean

  private loginService = LoginService.INSTANCE

  oldpassword = ""
  password = ""
  confirmation = ""

  messageString = ""
  sent = false

  newPassword(newPassword: string): void {
    this.sent = false
    this.password = newPassword
  }

  // Used by ProfileModale
  async submitPassword(): Promise<string> {
    // If user has started writing something about a password change, we check if she's filled everything
    if (this.oldpassword || this.password || this.confirmation) {
      const observerComponent = this.$refs["observer"]
      //@ts-ignore
      const validationResult = await observerComponent.validateWithInfo()
      const errors = Object.values(validationResult.errors).flat()

      if (errors.length !== 0) {
        this.messageString = this.$i18n.t("profilePage.missing-fields").toString()
        return this.messageString
      } else if (!this.withOldPwd) {
        return this.resetPassword()
      } else if (this.oldpassword) {
        const oldPasswordCheck = new PasswordCheckDTO()
        oldPasswordCheck.motDePasse = this.oldpassword
        // Reset the users password
        return this.loginService.checkPassword(oldPasswordCheck).then(
          (_success) => {
            return this.resetPassword()
          },
          (_reject) => {
            return this.$i18n.t("profilePage.authentication-error").toString()
          }
        )
      }
    }

    return ""
  }

  resetPassword(): Promise<string> {
    const passwordReset = new PasswordResetDTO()
    passwordReset.motDePasse = this.password
    passwordReset.confirmationMotDePasse = this.confirmation
    return this.loginService.changePassword(passwordReset, this.user).then(
      (_success) => {
        this.oldpassword = ""
        this.password = ""
        this.confirmation = ""
        this.messageString = ""

        this.$nextTick(() => {
          this.sent = true
        })
        return ""
      },
      (_reject) => {
        this.messageString = this.$i18n.t("profilePage.password-error").toString()
        return this.messageString
      }
    )
  }

  @Watch("oldpassword")
  @Watch("password")
  @Watch("confirmation")
  typedPassword(): void {
    this.sent = false
  }
}
