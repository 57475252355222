var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "observer",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var handleSubmit = ref.handleSubmit
          return [
            _c(
              "section",
              [
                _c(
                  "p",
                  { staticClass: "mb-3" },
                  [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t(
                            "registerPage.informations-personnelles-indications"
                          )
                        )
                      }
                    }),
                    _c("router-link", { attrs: { to: { path: "/login" } } }, [
                      _vm._v(" " + _vm._s(_vm.$t("login")) + " ")
                    ])
                  ],
                  1
                ),
                _c("InputWithValidation", {
                  attrs: {
                    rules: "required",
                    label: _vm.$t("registerPage.nom"),
                    placeholder: _vm.$t("registerPage.saisir-nom"),
                    group: "1"
                  },
                  model: {
                    value: _vm.innerUtilisateur.nom,
                    callback: function($$v) {
                      _vm.$set(_vm.innerUtilisateur, "nom", $$v)
                    },
                    expression: "innerUtilisateur.nom"
                  }
                }),
                _c("InputWithValidation", {
                  attrs: {
                    rules: "required",
                    label: _vm.$t("registerPage.prenom"),
                    placeholder: _vm.$t("registerPage.saisir-prenom"),
                    group: "2"
                  },
                  model: {
                    value: _vm.innerUtilisateur.prenom,
                    callback: function($$v) {
                      _vm.$set(_vm.innerUtilisateur, "prenom", $$v)
                    },
                    expression: "innerUtilisateur.prenom"
                  }
                }),
                _c("InputWithValidation", {
                  attrs: {
                    rules: "required|email",
                    type: "email",
                    label: _vm.$t("registerPage.courriel"),
                    placeholder: _vm.$t("registerPage.saisir-courriel"),
                    inputNotice: _vm.$t("registerPage.tooltip-courriel"),
                    group: "3"
                  },
                  model: {
                    value: _vm.innerUtilisateur.courriel,
                    callback: function($$v) {
                      _vm.$set(_vm.innerUtilisateur, "courriel", $$v)
                    },
                    expression: "innerUtilisateur.courriel"
                  }
                }),
                _c("InputWithValidation", {
                  attrs: {
                    rules: "required|validPhoneNumber",
                    type: "tel",
                    label: _vm.$t("registerPage.telephone"),
                    placeholder: _vm.$t("registerPage.saisir-telephone"),
                    inputNotice: _vm.$t("registerPage.tooltip-telephone"),
                    group: "4"
                  },
                  model: {
                    value: _vm.innerUtilisateur.telephone,
                    callback: function($$v) {
                      _vm.$set(_vm.innerUtilisateur, "telephone", $$v)
                    },
                    expression: "innerUtilisateur.telephone"
                  }
                }),
                _c("PasswordCreationWithValidation", {
                  attrs: {
                    password: _vm.innerUtilisateur.motDePasse,
                    label: _vm.$t("input-password"),
                    placeholder: _vm.$t("input-password-example")
                  },
                  on: {
                    input: function(newMotDePasse) {
                      return (_vm.innerUtilisateur.motDePasse = newMotDePasse)
                    }
                  }
                }),
                _c("InputWithValidation", {
                  attrs: {
                    rules: "required|confirmed:password",
                    type: "password",
                    passwordReveal: "",
                    placeholder: _vm.$t("input-password-example"),
                    label: _vm.$t("profilePage.mot-de-passe-confirmation"),
                    inputNotice: _vm.$t(
                      "registerPage.tooltip-confirmation-mot-passe"
                    )
                  },
                  model: {
                    value: _vm.innerUtilisateur.confirmationMotDePasse,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.innerUtilisateur,
                        "confirmationMotDePasse",
                        $$v
                      )
                    },
                    expression: "innerUtilisateur.confirmationMotDePasse"
                  }
                }),
                _c(
                  "CheckboxWithValidation",
                  {
                    attrs: { rules: "accept-rgpd", vid: "acceptRGPD" },
                    model: {
                      value: _vm.acceptRGPD,
                      callback: function($$v) {
                        _vm.acceptRGPD = $$v
                      },
                      expression: "acceptRGPD"
                    }
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("registerPage.accept-rgpd")) + " "
                    ),
                    _c("PDFLinkWithModal", {
                      attrs: {
                        fileName: "mentions-legales-sagae",
                        linkText: _vm.$t("registerPage.see-rgpd-link")
                      }
                    })
                  ],
                  1
                ),
                _c("br"),
                _c("br"),
                _c(
                  "CheckboxWithValidation",
                  {
                    attrs: { vid: "acceptNameVisibilityOnMap" },
                    model: {
                      value: _vm.acceptNameVisibilityOnProtectedListing,
                      callback: function($$v) {
                        _vm.acceptNameVisibilityOnProtectedListing = $$v
                      },
                      expression: "acceptNameVisibilityOnProtectedListing"
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("registerPage.accept-name-visibility-on-map")
                        ) +
                        " "
                    )
                  ]
                ),
                _c("br"),
                _c("br")
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { type: "is-primary" },
                    on: {
                      click: function($event) {
                        return handleSubmit(_vm.submit)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("registerPage.suivant")) + " ")]
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }