var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "onlyDisplayedifSectionIsActive",
        class: { activeSection: _vm.section == "historique" }
      },
      [
        _c("div", { staticClass: "container" }, [
          !_vm.readonly || !_vm.isPorteurProjet
            ? _c("div", { staticClass: "projet-part main-content" }, [
                _c(
                  "div",
                  { staticClass: "first" },
                  [
                    _c("ProjetHistoryView", {
                      attrs: {
                        role: _vm.role,
                        projetAnnuel: _vm.project,
                        isPorteurProjet: _vm.isPorteurProjet
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "second" },
                  [
                    !_vm.readonly || !_vm.isPorteurProjet
                      ? _c("LabellisationRecap", {
                          attrs: {
                            readonly: _vm.readonly || !_vm.isPorteurProjet,
                            projetAnnuel: _vm.project,
                            projetValidation: _vm.lastProjectValidation,
                            validationActivated: _vm.validationActivated,
                            showValidationErrors: _vm.showValidationErrors,
                            sectionToAnchors: _vm.anchorsToTrack,
                            sectionsToIcon: _vm.sectionsToIcon,
                            renewalSuffix: _vm.renewalSuffix,
                            isPorteurProjet: _vm.isPorteurProjet
                          },
                          on: {
                            "trigger-validation": function($event) {
                              return _vm.$emit("trigger-validation")
                            },
                            "toggle-validation": function($event) {
                              return _vm.$emit("toggle-validation")
                            }
                          }
                        })
                      : _vm._e(),
                    _c("div", {
                      staticClass: "anchor",
                      attrs: { id: "aide-labellissation" }
                    })
                  ],
                  1
                )
              ])
            : _c(
                "div",
                [
                  _c("ProjetHistoryView", {
                    attrs: {
                      role: _vm.role,
                      projetAnnuel: _vm.project,
                      isPorteurProjet: _vm.isPorteurProjet
                    }
                  })
                ],
                1
              )
        ])
      ]
    ),
    _c(
      "div",
      {
        staticClass: "onlyDisplayedifSectionIsActive",
        class: { activeSection: _vm.section == "bref" }
      },
      [
        _c(
          "div",
          { staticClass: "projet-part" },
          [
            _c("EnBrefView", {
              attrs: {
                projetAnnuel: _vm.project,
                readonly: _vm.readonly,
                isPorteurProjet: _vm.isPorteurProjet,
                validationActivated: _vm.validationActivated,
                showValidationErrors: _vm.showValidationErrors,
                section: _vm.section,
                mapScreenShotRequested: _vm.mapScreenShotRequested
              },
              on: {
                "dirtify-project": function($event) {
                  return _vm.$emit("dirtify-project")
                },
                "trigger-validation": function($event) {
                  return _vm.$emit("trigger-validation")
                },
                "map-screenshot-done": function(dataURL) {
                  return _vm.$emit("pdf-export-ready", dataURL)
                },
                "images-url-changed": function(imagesUrl) {
                  return _vm.$emit("images-url-changed", imagesUrl)
                }
              }
            })
          ],
          1
        )
      ]
    ),
    _c(
      "div",
      {
        staticClass: "onlyDisplayedifSectionIsActive pdf-page-break",
        class: { activeSection: _vm.section == "mon-annee" }
      },
      [
        _c("div", { staticClass: "anchor", attrs: { id: "conseil" } }),
        _c("ConseilsDesElevesView", {
          attrs: {
            projetAnnuel: _vm.project,
            readonly: _vm.readonly,
            isPorteurProjet: _vm.isPorteurProjet,
            validationActivated: _vm.validationActivated,
            showValidationErrors: _vm.showValidationErrors
          },
          on: {
            "dirtify-project": function($event) {
              return _vm.$emit("dirtify-project")
            },
            "trigger-validation": function($event) {
              return _vm.$emit("trigger-validation")
            }
          }
        }),
        _c("div", { staticClass: "anchor", attrs: { id: "etat" } }),
        _c("EtatDesLieuxView", {
          attrs: {
            projetAnnuel: _vm.project,
            readonly: _vm.readonly,
            section: _vm.section,
            isPorteurProjet: _vm.isPorteurProjet,
            validationActivated: _vm.validationActivated,
            showValidationErrors: _vm.showValidationErrors,
            currentWidth: _vm.currentWidth,
            currentScrollY: _vm.currentScrollY
          },
          on: {
            "dirtify-project": function($event) {
              return _vm.$emit("dirtify-project")
            },
            "trigger-validation": function($event) {
              return _vm.$emit("trigger-validation")
            }
          }
        }),
        _c("div", { staticClass: "anchor", attrs: { id: "problematiques" } }),
        _c("ProblematiquesOrientationsActionsView", {
          attrs: {
            projetAnnuel: _vm.project,
            readonly: _vm.readonly,
            isPorteurProjet: _vm.isPorteurProjet,
            validationActivated: _vm.validationActivated,
            showValidationErrors: _vm.showValidationErrors
          },
          on: {
            "dirtify-project": function($event) {
              return _vm.$emit("dirtify-project")
            },
            "trigger-validation": function($event) {
              return _vm.$emit("trigger-validation")
            }
          }
        }),
        _c("div", { staticClass: "anchor", attrs: { id: "diffusion" } }),
        _c("ProjetDiffusionView", {
          attrs: {
            projetAnnuel: _vm.project,
            readonly: _vm.readonly,
            isPorteurProjet: _vm.isPorteurProjet,
            validationActivated: _vm.validationActivated,
            showValidationErrors: _vm.showValidationErrors
          },
          on: {
            "dirtify-project": function($event) {
              return _vm.$emit("dirtify-project", $event)
            }
          }
        }),
        _c("div", { staticClass: "anchor", attrs: { id: "autre" } }),
        _c("AutresInfosView", {
          attrs: {
            validationActivated: _vm.validationActivated,
            showValidationErrors: _vm.showValidationErrors,
            projetAnnuel: _vm.project,
            readonly: _vm.readonly,
            isPorteurProjet: _vm.isPorteurProjet
          },
          on: {
            "dirtify-project": function($event) {
              return _vm.$emit("dirtify-project")
            },
            "trigger-validation": function($event) {
              return _vm.$emit("trigger-validation")
            }
          }
        })
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "onlyDisplayedifSectionIsActive pdf-page-break",
        class: { activeSection: _vm.section == "infos_administratives" }
      },
      [
        _c("div", { staticClass: "anchor", attrs: { id: "charte" } }),
        _c("ProjetCharteView", {
          attrs: {
            validationActivated: _vm.validationActivated,
            showValidationErrors: _vm.showValidationErrors,
            projetAnnuel: _vm.project,
            readonly: _vm.readonly
          },
          on: {
            "trigger-validation": function($event) {
              return _vm.$emit("trigger-validation")
            },
            "dirtify-project": function($event) {
              return _vm.$emit("dirtify-project")
            }
          }
        }),
        _c("div", { staticClass: "anchor", attrs: { id: "participants" } }),
        _c("ProjetParticipants", {
          attrs: {
            validationActivated: _vm.validationActivated,
            showValidationErrors: _vm.showValidationErrors,
            projetAnnuel: _vm.project,
            readonly: _vm.readonly,
            isPorteurProjet: _vm.isPorteurProjet
          },
          on: {
            "dirtify-project": function($event) {
              return _vm.$emit("dirtify-project", $event)
            }
          }
        }),
        _c("div", { staticClass: "anchor", attrs: { id: "financement" } }),
        _c("ProjetFinancementView", {
          attrs: {
            validationActivated: _vm.validationActivated,
            showValidationErrors: _vm.showValidationErrors,
            projetAnnuel: _vm.project,
            readonly: _vm.readonly || !_vm.isPorteurProjet
          },
          on: {
            "dirtify-project": function($event) {
              return _vm.$emit("dirtify-project")
            }
          }
        }),
        _c("div", { staticClass: "anchor", attrs: { id: "site" } }),
        _c("ProjetSiteView", {
          attrs: {
            validationActivated: _vm.validationActivated,
            showValidationErrors: _vm.showValidationErrors,
            projetAnnuel: _vm.project,
            readonly: _vm.readonly || !_vm.isPorteurProjet
          },
          on: {
            "dirtify-project": function($event) {
              return _vm.$emit("dirtify-project")
            }
          }
        }),
        _c("div", { staticClass: "anchor", attrs: { id: "autresinfos" } }),
        _c(
          "div",
          { staticClass: "projet-part" },
          [
            _c("ProjetAutresInfosView", {
              attrs: {
                validationActivated: _vm.validationActivated,
                showValidationErrors: _vm.showValidationErrors,
                projetAnnuel: _vm.project,
                readonly: _vm.readonly || !_vm.isPorteurProjet
              },
              on: {
                "dirtify-project": function($event) {
                  return _vm.$emit("dirtify-project")
                }
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "onlyDisplayedifSectionIsActive pdf-page-break",
        class: { activeSection: _vm.section == "labellise" }
      },
      [
        _vm.project.statut !== "BROUILLON"
          ? _c("LabellisationResult", {
              attrs: {
                projetAnnuel: _vm.project,
                renewalSuffix: _vm.renewalSuffix
              }
            })
          : _vm._e(),
        _vm.project.typeDossier !== "DOSSIER_RENOUVELLEMENT_LIGHT"
          ? _c("div", { staticClass: "anchor", attrs: { id: "plus" } })
          : _vm._e(),
        _vm.project.typeDossier !== "DOSSIER_RENOUVELLEMENT_LIGHT"
          ? _c("PlusView", {
              attrs: {
                validationActivated: _vm.validationActivated,
                showValidationErrors: _vm.showValidationErrors,
                projetAnnuel: _vm.project,
                readOnly: _vm.readonly || !_vm.isPorteurProjet
              },
              on: {
                "dirtify-project": function($event) {
                  return _vm.$emit("dirtify-project")
                },
                "trigger-validation": function($event) {
                  return _vm.$emit("trigger-validation")
                }
              }
            })
          : _vm._e(),
        _c("div", { staticClass: "anchor", attrs: { id: "infos" } }),
        _c("SuiviView", {
          attrs: {
            validationActivated: _vm.validationActivated,
            showValidationErrors: _vm.showValidationErrors,
            projetAnnuel: _vm.project,
            readOnly: _vm.readonly || !_vm.isPorteurProjet
          },
          on: {
            "dirtify-project": function($event) {
              return _vm.$emit("dirtify-project")
            },
            "trigger-validation": function($event) {
              return _vm.$emit("trigger-validation")
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }