var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loggedUserCanManageUsers
        ? _c(
            "div",
            { staticClass: "FirstConnectionView-cards" },
            [
              _vm.isLoadingDemandes
                ? _c(
                    "div",
                    [
                      _c("BookmarkCard", {
                        attrs: {
                          isLoading: true,
                          tagType: _vm.tagTypes.VALIDATION,
                          iconName: "user"
                        }
                      })
                    ],
                    1
                  )
                : _vm._l(_vm.demandesEnCours, function(user) {
                    return _c(
                      "div",
                      { key: user.id },
                      [
                        _vm.canAccept(user)
                          ? _c("GraeMemberDemandeCard", {
                              attrs: {
                                userDemande: user,
                                reject: _vm.reject,
                                accept: _vm.accept
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  })
            ],
            2
          )
        : _vm._e(),
      _c("TableFilterView", {
        attrs: { columns: _vm.columns, initialFilters: _vm.activeFilters },
        on: {
          "update-active-filters": _vm.updateActiveFilters,
          "update-visible-columns": _vm.updateVisibleColumns,
          "export-csv": _vm.exportCSV,
          "export-xls": _vm.exportXLS
        }
      }),
      !_vm.hidden
        ? _c(
            "b-table",
            {
              ref: _vm.tableRef,
              attrs: {
                striped: _vm.config.isStriped,
                hoverable: _vm.config.isHoverable,
                "debounce-search": _vm.config.searchDebounce,
                "aria-next-label": _vm.config.nextLabel(),
                "aria-previous-label": _vm.config.previousLabel(),
                "aria-page-label": _vm.config.pageLabel(),
                "aria-current-label": _vm.config.currentPageLabel(),
                "per-page": _vm.config.perPage,
                "sticky-header": _vm.config.stickyHeader,
                "current-page": _vm.currentPagedSortedParameter.pageNumber,
                "pagination-position": _vm.config.paginationPosition,
                paginated: "",
                "backend-pagination": "",
                "sort-icon": _vm.config.sortIcon,
                "sort-icon-size": _vm.config.sortIconSize,
                "backend-sorting": "",
                "sort-multiple": "",
                "sort-multiple-data": _vm.buefySortingPriority,
                total: _vm.total,
                data: _vm.memberList,
                loading: _vm.isLoading
              },
              on: {
                sort: _vm.onSort,
                "sorting-priority-removed": _vm.sortingPriorityRemoved,
                "page-change": _vm.onPageChange
              }
            },
            [
              _vm._l(_vm.columns, function(column) {
                return [
                  _c(
                    "b-table-column",
                    _vm._b(
                      {
                        key: column.id,
                        attrs: { visible: column.visible !== false },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(props) {
                                return [
                                  column.field === "profil"
                                    ? _c(
                                        "div",
                                        [
                                          _vm.canPromote(props.row) ||
                                          _vm.canDemote(props.row)
                                            ? _c(
                                                "b-select",
                                                {
                                                  ref: "select-" + props.row.id,
                                                  refInFor: true,
                                                  attrs: {
                                                    placeholder: _vm.$t(
                                                      "usersList.select-profile"
                                                    ),
                                                    value: props.row.profil
                                                  },
                                                  on: {
                                                    input: function(profil) {
                                                      return _vm.changeUserProfile(
                                                        props.row,
                                                        profil
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: {
                                                        value:
                                                          "ACCOMPAGNATEUR_REGIONAL"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "ACCOMPAGNATEUR_REGIONAL"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: {
                                                        value:
                                                          "ANIMATEUR_REGIONAL"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "ANIMATEUR_REGIONAL"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(props.row.profil)
                                                    ) +
                                                    " "
                                                )
                                              ])
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  column.field == _vm.statusColumnField
                                    ? _c("div", [
                                        _vm.canAccept(props.row)
                                          ? _c(
                                              "div",
                                              [
                                                _vm.userBeingValidated !=
                                                  props.row["id"] &&
                                                _vm.userBeingRefused !=
                                                  props.row["id"]
                                                  ? _c(
                                                      "b-select",
                                                      {
                                                        attrs: {
                                                          placeholder: _vm.$t(
                                                            "usersList.waiting"
                                                          )
                                                        },
                                                        on: {
                                                          input: function(
                                                            value
                                                          ) {
                                                            return _vm.changeStatut(
                                                              value,
                                                              props.row
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "option",
                                                          {
                                                            domProps: {
                                                              value:
                                                                _vm.statut
                                                                  .valide
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.statut
                                                                    .valide
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "option",
                                                          {
                                                            domProps: {
                                                              value:
                                                                _vm.statut
                                                                  .refuse
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.statut
                                                                    .refuse
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : _vm.userBeingValidated ==
                                                    props.row["id"]
                                                  ? _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.statut.validated
                                                          ) +
                                                          " "
                                                      )
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.statut.refused
                                                          ) +
                                                          " "
                                                      )
                                                    ])
                                              ],
                                              1
                                            )
                                          : _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      props.row[
                                                        "statutActivation"
                                                      ]
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                      ])
                                    : _vm._e(),
                                  column.field === "action" && _vm.canDeleteUser
                                    ? _c(
                                        "div",
                                        [
                                          _c("b-button", {
                                            attrs: {
                                              type: "is-danger is-outlined",
                                              "icon-left": "trash",
                                              title: _vm.$t(
                                                "usersList.supprimer"
                                              )
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteUser(
                                                  $event,
                                                  props.row
                                                )
                                              }
                                            }
                                          }),
                                          _vm.canValidateEmail(props.row)
                                            ? _c("b-button", {
                                                staticStyle: {
                                                  "margin-left": "10px"
                                                },
                                                attrs: {
                                                  type:
                                                    "is-primary is-outlined",
                                                  "icon-left": "check",
                                                  title: _vm.$t(
                                                    "usersList.validate-email"
                                                  )
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.validateEmail(
                                                      $event,
                                                      props.row
                                                    )
                                                  }
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !column.custom
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "clickable",
                                          on: {
                                            click: function($event) {
                                              return _vm.showUserDetailsPage(
                                                $event,
                                                props.row["id"]
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(props.row[column.field])
                                            )
                                          ])
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      "b-table-column",
                      column,
                      false
                    )
                  )
                ]
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }