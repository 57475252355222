var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PageView",
    {
      attrs: {
        mainTitle: _vm.title,
        subTitle: _vm.subtitle,
        displaySubTitleInGreen: true
      }
    },
    [
      _c(
        "ContentView",
        [
          _c("h1", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.$t("IndicateursPage.title")))
          ]),
          _vm.grae
            ? _c("IndicateursView", {
                attrs: {
                  grae: _vm.grae,
                  anneeScolaire: _vm.selectedAnneeScolaire
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }