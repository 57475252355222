

















import { UtilisateurDTO } from "@/model/bean/GeneratedDTOs"

import { LoginService } from "@/services/rest/login/LoginService"
import { Component, Prop, Vue } from "vue-property-decorator"

import PageView from "@/views/commons/PageView.vue"
import ContentView from "@/views/commons/ContentView.vue"
import GraeContactView from "@/pages/grae/GraeContactView.vue"

@Component({
  components: {
    GraeContactView,
    PageView,
    ContentView,
  },
})
export default class UsersListPage extends Vue {
  @Prop() graeId?: string

  private loginService = LoginService.INSTANCE
  loggedUser?: UtilisateurDTO

  created(): void {
    this.loggedUser = this.loginService.getLoggedUser()
  }
}
