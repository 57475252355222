



































import { Component, Prop, Vue } from "vue-property-decorator"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import AdresseView from "@/views/commons/AdresseView.vue"
import SubTitleView from "@/views/commons/SubTitleView.vue"
import { EtablissementDTO } from "@/model/bean/GeneratedDTOs"

@Component({
  components: {
    InputWithValidation,
    AdresseView,
    SubTitleView,
  },
})
export default class EtablissementContactDetails extends Vue {
  @Prop() etablissement: EtablissementDTO
  @Prop({ default: false }) readonly: boolean
  @Prop() validationGroup: string

  updateEtablissementContact(): void {
    this.$emit("update-etablissement", this.etablissement)
  }
}
