var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "has-modal-card": "",
        "trap-focus": "",
        "aria-role": "dialog",
        "aria-label": "Confirm changement etat",
        "aria-modal": "",
        "on-cancel": _vm.close
      },
      model: {
        value: _vm.isActive,
        callback: function($$v) {
          _vm.isActive = $$v
        },
        expression: "isActive"
      }
    },
    [
      _c("div", { staticClass: "modal-card change-etat-modal" }, [
        _c(
          "header",
          { staticClass: "modal-card-head" },
          [
            _c("H3TitleView", { staticClass: "modal-card-title" }, [
              _vm._v(_vm._s(_vm.$t("ConfirmChangementEtat.title").toString()))
            ])
          ],
          1
        ),
        _c(
          "section",
          { staticClass: "modal-card-body" },
          [
            _c("H4TitleView", [
              _vm._v(
                _vm._s(_vm.$t("ConfirmChangementEtat.subtitle").toString())
              )
            ]),
            _c(
              "div",
              { staticClass: "mt-5" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "no-hover",
                    attrs: {
                      "icon-left": _vm.getIcon(_vm.oldEtatProjet),
                      type: _vm.getColor(_vm.oldEtatProjet),
                      outlined: ""
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t(_vm.oldEtatProjet)))]
                ),
                _c("b-icon", {
                  staticClass: "mt-2",
                  attrs: { icon: "arrow-right" }
                }),
                _c(
                  "b-button",
                  {
                    staticClass: "no-hover",
                    attrs: {
                      "icon-left": _vm.getIcon(_vm.newEtatProjet),
                      type: _vm.getColor(_vm.newEtatProjet),
                      outlined: ""
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t(_vm.newEtatProjet)))]
                )
              ],
              1
            ),
            _c("div", { staticClass: "mt-5" }, [
              _vm.newEtatProjet === "EN_PAUSE"
                ? _c("span", [
                    _vm._v(_vm._s(_vm.$t("ConfirmChangementEtat.pause-projet")))
                  ])
                : _vm._e(),
              _vm.newEtatProjet === "ARCHIVE"
                ? _c("span", [
                    _vm._v(_vm._s(_vm.$t("ConfirmChangementEtat.stop-projet")))
                  ])
                : _vm._e()
            ])
          ],
          1
        ),
        _c(
          "footer",
          { staticClass: "modal-card-foot" },
          [
            _c("b-button", {
              attrs: { label: _vm.$t("annuler") },
              on: { click: _vm.close }
            }),
            _c("b-button", {
              attrs: { label: _vm.$t("valider"), type: "is-primary" },
              on: { click: _vm.validate }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }