var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "b-tabs",
        {
          attrs: { animated: false, type: "is-toggle" },
          model: {
            value: _vm.currentTabIndex,
            callback: function($$v) {
              _vm.currentTabIndex = $$v
            },
            expression: "currentTabIndex"
          }
        },
        [
          _c(
            "b-tab-item",
            {
              attrs: {
                value: "0",
                label: _vm.$t(
                  "instructionPage.labellisations.harmonisation.title"
                )
              }
            },
            [
              _vm.activeSubTab == 0
                ? _c("LabellisationHarmonisationView", {
                    attrs: {
                      graeId: _vm.graeId,
                      activeQuickFilter: _vm.activeQuickFilter
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-tab-item",
            {
              attrs: {
                value: "1",
                label: _vm.$t("instructionPage.labellisations.cloture.title")
              }
            },
            [
              _vm.activeSubTab == 1
                ? _c("LabellisationClotureView", {
                    attrs: { graeId: _vm.graeId }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }