






























import { LoginService } from "@/services/rest/login/LoginService"
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({})
export default class FormInputLabel extends Vue {
  @Prop({ default: "" }) label: string
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: false }) isMandatory: boolean
  @Prop({ default: true }) validationActivated: boolean
  @Prop({ default: true }) showValidationErrors: boolean
  @Prop({ default: "" }) inputNotice: string
  @Prop({ default: "" }) tooltip: string

  isPorteurProjet = true

  mounted(): void {
    const loggedUser = LoginService.INSTANCE.getLoggedUser()
    this.isPorteurProjet = loggedUser?.profil == "ENSEIGNANT" || loggedUser?.profil == "REFERENT"
  }
}
