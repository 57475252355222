var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationProvider", {
    attrs: {
      vid: (_vm.group != "" ? _vm.group + "_" : "") + _vm.vid,
      name: _vm.$attrs.name || _vm.$attrs.label,
      rules: _vm.rules
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(ref) {
            var errors = ref.errors
            return [
              _c(
                "b-checkbox",
                {
                  attrs: { disabled: _vm.disabled },
                  model: {
                    value: _vm.innerValue,
                    callback: function($$v) {
                      _vm.innerValue = $$v
                    },
                    expression: "innerValue"
                  }
                },
                [_vm._t("default")],
                2
              ),
              errors.length > 0
                ? _c("p", { staticClass: "help is-danger" }, [
                    _vm.errorMessage.length > 0
                      ? _c("span", [_vm._v(" " + _vm._s(_vm.errorMessage))])
                      : _c("span", [_vm._v(" " + _vm._s(errors[0]) + " ")])
                  ])
                : _vm._e()
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }