







































































































































































































































































































import { EnumHelper } from "@/model/bean/EnumHelper"
import {
  FilterDTO,
  PaginationOrder,
  PaginationParameter,
  ProjetAnnuelDTO,
  UtilisateurDTO,
} from "@/model/bean/GeneratedDTOs"
import { Filters } from "@/model/bean/Filters"
import RouterUtils from "@/router/RouterUtils"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import { ProjectService } from "@/services/rest/project/ProjectService"
import StatutDossierTag from "@/views/dossier/StatutDossierTag.vue"
import AbstractTableView from "@/views/tables/AbstractTableView.vue"
import ContactModale from "@/views/commons/ContactModale.vue"
import Column from "@/views/tables/Column"
import TableFilterView from "@/views/tables/TableFilterView.vue"
import { Component, Prop } from "vue-property-decorator"
import { format } from "date-fns"
import { InstructionService } from "@/services/rest/instruction/InstructionService"
import { UsersService } from "@/services/rest/users/UsersService"
import { GraeService } from "@/services/rest/grae/GraeService"
import { LoginService } from "@/services/rest/login/LoginService"
import { OrganismeService } from "@/services/rest/organisme/OrganismeService"
import TabParamsStore from "@/services/storage/TabParamsStore"
import { BOOLEAN } from "@/model/bean/EnumHelper"

const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm"

@Component({
  components: {
    TableFilterView,
    StatutDossierTag,
    ContactModale,
  },
})
export default class GraeProjectsView extends AbstractTableView {
  @Prop() graeId: string | undefined

  private loginService = LoginService.INSTANCE
  private instructionService = InstructionService.INSTANCE
  protected infoReportingService = InfoReportingService.INSTANCE
  protected projetsService = ProjectService.INSTANCE
  private organismeService = OrganismeService.INSTANCE
  private usersService = UsersService.INSTANCE
  private graeService = GraeService.INSTANCE

  loggedUser: UtilisateurDTO | undefined = new UtilisateurDTO()
  projectsList: Array<ProjetAnnuelDTO> = []
  total = 0
  filters: Array<FilterDTO> = []
  nbBrouillons = 0
  graeNamesList: Array<string> = []
  isDeletingBrouillons = false
  openContactModale = false
  contacts: UtilisateurDTO[] = []
  isObservateurNational = false
  previousYearAvailable = true
  nextYearAvailable = true
  anneesScolaires: Array<string> = []
  selectedAnneeScolaire = ""

  created(): void {
    this.loggedUser = this.loginService.getLoggedUser()
    if (!this.loggedUser || !this.loggedUser.id) {
      return
    } else {
      this.isObservateurNational = this.usersService.isObservateurNational(this.loggedUser)
    }
    if (!this.graeId && this.hasRightsToDeleteAllInscriptionsInBrouillon()) {
      this.countNbBrouillons()
    }

    // Get region lists for filtering purposes
    if (!this.graeId) {
      this.organismeService.getAllGraes().then((graes) => {
        this.graeNamesList = graes.map((g) => g.region)
        this.refreshInitialColumns()
      })
    }
  }

  mounted(): void {
    this.loadAnneesScolaires()
  }

  async countNbBrouillons(): Promise<void> {
    const page = await this.instructionService.getInscriptions(
      undefined,
      this.paginationParameter(),
      this.inscriptionsFilters(),
      undefined
    )
    this.nbBrouillons = page.count
  }

  reloadDataWhenMounted(): boolean {
    return false
  }

  private paginationParameter(): PaginationParameter {
    const paginationParameter = new PaginationParameter()
    paginationParameter.pageNumber = 1
    paginationParameter.pageSize = 1
    return paginationParameter
  }

  private inscriptionsFilters(): Array<FilterDTO> {
    const filters = new Array<FilterDTO>()
    filters.push(Filters.asFilter({ property: "statut", values: ["BROUILLON"] }))
    return filters
  }

  async reloadData(): Promise<void> {
    this.isLoading = true
    try {
      const paginatedProjectsList = await this.instructionService.getProjects(
        this.graeId,
        this.currentPagedSortedParameter,
        this.activeFilters
      )
      this.projectsList = paginatedProjectsList.elements
      this.total = paginatedProjectsList.count

      const newFiltersInQuery = JSON.stringify(this.activeFilters)
      this.$router.push({ query: { filters: newFiltersInQuery } })
    } catch (e) {
      // Silent catch
    }
    this.isLoading = false
  }

  invited(user: UtilisateurDTO, project: any): boolean {
    if (user.id) {
      return project.consentementEnAttente.indexOf(user.id) !== -1
    } else {
      return false
    }
  }

  concatContactsForProjects(): void {
    this.contacts = []
    for (const project of this.projectsList) {
      const enseignants = project.enseignants
        ? project.enseignants.filter((e) => !this.invited(e, project))
        : []
      const referents = project.referents
        ? project.referents.filter((r) => !this.invited(r, project))
        : []
      const tab = enseignants.concat(referents)
      this.contacts = this.contacts.concat(tab)
    }
    this.openContactModale = true
  }

  getInitialColumns(): Array<Column> {
    const initialColumns: Array<Column> = [
      // default fields
      {
        field: "etablissement.name",
        label: this.$t("graePage.details.projets.etablissements").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "etablissement.codeEtablissement",
        label: this.$t("graePage.details.projets.uais").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "structure.name",
        label: this.$t("graePage.details.projets.structures").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "projet.nom",
        label: this.$t("graePage.details.projets.nom").toString(),
        sortable: true,
        backEndSearchable: true,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "typeDossier",
        label: this.$t("graePage.details.projets.typeDossier").toString(),
        sortable: true,
        backEndSearchable: true,
        possibleSearchValues: EnumHelper.allTypeDossiers,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "statut",
        label: this.$t("graePage.details.projets.statut").toString(),
        sortable: true,
        backEndSearchable: true,
        possibleSearchValues: EnumHelper.allStatusWithoutArchivedAndPassable,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "projet.etat",
        label: this.$t("graePage.details.projets.etat").toString(),
        sortable: true,
        backEndSearchable: true,
        possibleSearchValues: EnumHelper.allEtatsProjets,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "nature",
        label: this.$t("graePage.details.projets.nature").toString(),
        sortable: true,
        backEndSearchable: true,
        possibleSearchValues: EnumHelper.allNatures,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      // hidden fields
      {
        field: "codeDepartement",
        label: this.$t("graePage.details.projets.departement").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: false,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "dateModification",
        label: this.$t("graePage.details.projets.dateModification").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "thematique",
        label: this.$t("graePage.details.projets.thematiques").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "objectsConcretRealisation",
        label: this.$t("graePage.details.projets.objets-concrets-de-realisation").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "milieuGenerique",
        label: this.$t("graePage.details.projets.milieu-generique").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "milieuInteretParticulier",
        label: this.$t("graePage.details.projets.milieux-interets-particuliers").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "ville",
        label: this.$t("graePage.details.projets.ville").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: false,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "enseignant.name",
        label: this.$t("graePage.details.projets.enseignants").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "referent.name",
        label: this.$t("graePage.details.projets.referents").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "lastEvaluationInstructeurNames",
        label: this.$t("graePage.details.projets.instructeurs").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: false,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "action",
        label: this.$t("projetsListPage.actions").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "inscriptionThisYear",
        label: this.$t("graePage.details.projets.inscriptionThisYear").toString(),
        sortable: true,
        backEndSearchable: true,
        possibleSearchValues: Object.values(BOOLEAN).map((v) => v.toString()),
        visible: false,
        custom: false,
        canBeAdded: true,
      },
    ]
    if (!this.graeId) {
      initialColumns.push({
        field: "grae.region",
        label: this.$t("graeList.region").toString(),
        possibleSearchValues: this.graeNamesList,
        sortable: true,
        backEndSearchable: true,
        visible: true,
        custom: true,
        canBeAdded: true,
      })
    }
    return initialColumns
  }

  getInitialActiveFilters(): Array<FilterDTO> {
    if (
      this.$route.query &&
      this.$route.query.filters &&
      typeof this.$route.query.filters === "string"
    ) {
      const filters = JSON.parse(this.$route.query.filters) as FilterDTO[]

      // Le filtre "anneeScolaire n'est pas a mettre avec les autres filtres,
      // mais dans la sélection en haut
      const yearFilter = filters.find((f) => f.property == "anneeScolaire")
      if (yearFilter && yearFilter.values.length == 1) {
        this.selectedAnneeScolaire = yearFilter.values[0]
      }

      return filters.filter((f) => f.property !== "anneeScolaire")
    }
    return new Array<FilterDTO>()
  }

  getDefaultSort(): Array<PaginationOrder> {
    const defaultSort = new PaginationOrder()
    defaultSort.clause = "projet.nom"
    defaultSort.desc = true
    return [defaultSort]
  }

  showProjectDetailsPage(event: Event, projetId: string, projetAnnuelId: string): void {
    // Do not foward click event to row (would trigger modal)
    event.stopPropagation()
    this.$router.push(RouterUtils.getRouteForProjectDetails(projetId, projetAnnuelId))
  }

  consult(event: Event, projetId: string, projetAnnuelId: string): void {
    // Do not foward click event to row (would trigger modal)
    event.stopPropagation()
    let route = this.$router.resolve(
      RouterUtils.getRouteForProjectDetails(projetId, projetAnnuelId)
    )
    window.open(route.href)
  }

  formatDate(date: Date): string {
    if (date) {
      return format(date, DATE_TIME_FORMAT)
    }
    return ""
  }

  canBeDrafted(projet: ProjetAnnuelDTO): boolean {
    if (projet.statut !== "BROUILLON" && this.hasRightsToPutInDraft()) {
      return true
    }
    return false
  }

  canUnarchive(projet: ProjetAnnuelDTO): boolean {
    if (projet.etat == "ARCHIVE") {
      const projectLastModificationDate = new Date(
        projet.dateModification ? projet.dateModification : "2000-01-01"
      )
      var projetLastModicationTime = projectLastModificationDate.getTime()
      const diffFromNow = new Date().getTime() - projetLastModicationTime
      const diffFromNowInDays = diffFromNow / (1000 * 3600 * 24)
      return 365 > diffFromNowInDays
    }
    return false
  }

  canUnlock(projet: ProjetAnnuelDTO): boolean {
    if (projet.etat == "EN_COURS_DE_CREATION" || projet.statut == "INSTRUCTION_EN_COURS") {
      const projectLastModificationDate = new Date(
        projet.dateModification ? projet.dateModification : "2000-01-01"
      )
      var projetLastModicationTime = projectLastModificationDate.getTime()
      const diffFromNow = new Date().getTime() - projetLastModicationTime
      const diffFromNowInDays = diffFromNow / (1000 * 3600 * 24)
      return 365 > diffFromNowInDays
    }
    return false
  }

  isInstruction(projet: ProjetAnnuelDTO): boolean {
    return projet.statut == "EN_ATTENTE" || projet.statut == "INSTRUCTION_EN_COURS"
  }

  async unarchive(event: Event, projet: ProjetAnnuelDTO): Promise<void> {
    event.stopPropagation()
    this.infoReportingService.dialog(
      this.$t("projetsListPage.archive.confirm-title").toString(),
      this.$t("projetsListPage.archive.confirm-message").toString(),
      this.$t("projetsListPage.archive.confirm-action").toString(),
      async () => {
        if (projet.projetId) {
          await this.projetsService.unarchiveProject(projet.projetId)
        }
        this.reloadData()
      },
      "is-warning"
    )
  }

  async unlock(event: Event, projet: ProjetAnnuelDTO): Promise<void> {
    event.stopPropagation()
    this.infoReportingService.dialog(
      this.$t("projetsListPage.brouillon.confirm-title").toString(),
      this.$t("projetsListPage.brouillon.confirm-message").toString(),
      this.$t("projetsListPage.brouillon.confirm-action").toString(),
      async () => {
        if (projet.projetId) {
          await this.projetsService.unlockProject(projet.projetId)
        }
        this.reloadData()
      },
      "is-warning"
    )
  }

  hasRightsToPutInDraft(): boolean {
    if (this.loggedUser) {
      return (
        this.loggedUser.profil === "ACCOMPAGNATEUR_NATIONAL" ||
        this.loggedUser.profil === "SUPER_ADMINISTRATEUR" ||
        this.loggedUser.profil === "ANIMATEUR_REGIONAL"
      )
    }
    return false
  }

  hasRightsToDeleteAllInscriptionsInBrouillon(): boolean {
    if (this.loggedUser) {
      return (
        this.loggedUser.profil == "ACCOMPAGNATEUR_NATIONAL" ||
        this.loggedUser.profil == "SUPER_ADMINISTRATEUR"
      )
    }
    return false
  }

  deleteAllInscriptionsInBrouillon(): void {
    this.infoReportingService.dialogWithCancel(
      this.$t("graePage.details.projets.delete-brouillon", [this.nbBrouillons]).toString(),
      this.$t("graePage.details.projets.confirm-popup-message", [this.nbBrouillons]).toString(),
      this.$t("graePage.details.projets.confirm-popup-ok-button").toString(),
      async () => {
        this.isDeletingBrouillons = true
        this.isLoading = true
        try {
          await this.instructionService.deleteAllInscriptionsInBrouillon()
          this.isLoading = false
          this.infoReportingService.success(
            this.$t("graePage.details.projets.deleted-inscriptions").toString()
          )
          this.isDeletingBrouillons = false
          this.reloadData()
          this.countNbBrouillons()
        } catch (e) {
          this.isLoading = false
          this.infoReportingService.error(
            this.$t("graePage.details.projets.delete-inscriptions-failure").toString(),
            e
          )
          this.isDeletingBrouillons = false
        }
      },
      () => {
        // Nothing to do
      },
      "is-danger"
    )
  }

  exportProjectsCsv(): void {
    this.instructionService.exportProjectsCsv(this.graeId, this.activeFilters)
  }

  exportProjectsXls(): void {
    this.instructionService.exportProjectsXls(this.graeId, this.activeFilters)
  }

  exportProjectsXlsAll(): void {
    this.instructionService.exportProjectsXls(
      this.graeId,
      this.activeFilters.filter((f) => f.property != "anneeScolaire")
    )
  }

  previousYear(): void {
    const thisYearIndex = this.anneesScolaires.indexOf(this.selectedAnneeScolaire)
    const previousYear = this.anneesScolaires[thisYearIndex + 1]

    this.clearAnneeScolaireFilter()

    this.activeFilters.push(Filters.asFilter({ property: "anneeScolaire", values: [previousYear] }))
    this.reloadData()
    this.selectedAnneeScolaire = previousYear
  }

  nextYear(): void {
    const thisYearIndex = this.anneesScolaires.indexOf(this.selectedAnneeScolaire)
    const nextYear = this.anneesScolaires[thisYearIndex - 1]

    this.clearAnneeScolaireFilter()

    this.activeFilters.push(Filters.asFilter({ property: "anneeScolaire", values: [nextYear] }))
    this.reloadData()
    this.selectedAnneeScolaire = nextYear
  }

  goToYear(): void {
    this.clearAnneeScolaireFilter()

    this.activeFilters.push(
      Filters.asFilter({ property: "anneeScolaire", values: [this.selectedAnneeScolaire] })
    )
    this.reloadData()
  }

  async loadAnneesScolaires(): Promise<void> {
    if (this.graeId) {
      this.anneesScolaires = await this.graeService.getAnneesScolairesForGRAE(this.graeId)
    } else {
      this.anneesScolaires = await this.graeService.getAnneesScolaires()
    }

    this.selectedAnneeScolaire ||= this.anneesScolaires[0]
    this.goToYear()
  }

  clearAnneeScolaireFilter(): void {
    this.activeFilters = this.activeFilters.filter((item) => item.property !== "anneeScolaire")
  }

  updateActiveFilters(newActiveFilters: Array<FilterDTO>): void {
    this.activeFilters = newActiveFilters
    this.goToYear()

    TabParamsStore.INSTANCE.storeFilters(
      this.$router.currentRoute.path + this.tableRef,
      newActiveFilters
    )
    this.goToYear()
  }
}
