var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    [
      _c("PDFLinkWithModal", {
        staticClass: "footerlink",
        attrs: {
          fileName: "mentions-legales-sagae",
          linkText: _vm.$t("legalNotice")
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }