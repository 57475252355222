












































































































import { ProjetAnnuelDTO } from "@/model/bean/GeneratedDTOs"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import SelectWithValidation from "@/views/inputs/SelectWithValidation.vue"
import GroupCheckboxesWithValidation from "@/views/inputs/GroupCheckBoxesWithValidation.vue"
import DocumentationLinks from "@/views/commons/DocumentationLinks.vue"
import i18n from "@/i18n"

const CONTEXTE_STRINGS = {
  NON: i18n.t("projetPage.labellisation.suivi.problemes.non").toString(),
  FINANCEMENT: i18n.t("projetPage.labellisation.suivi.problemes.financement").toString(),
  METEO: i18n.t("projetPage.labellisation.suivi.problemes.meteo").toString(),
  MAIRIE: i18n.t("projetPage.labellisation.suivi.problemes.mairie").toString(),
  USAGERS: i18n.t("projetPage.labellisation.suivi.problemes.usagers").toString(),
  ADMINISTRATIF: i18n.t("projetPage.labellisation.suivi.problemes.administratif").toString(),
  ABSENCES: i18n.t("projetPage.labellisation.suivi.problemes.absences").toString(),
  AMPLEUR: i18n.t("projetPage.labellisation.suivi.problemes.ampleur").toString(),
  AUTRE: i18n.t("projetPage.labellisation.suivi.autre-entraves").toString(),
}

@Component({
  components: {
    DocumentationLinks,
    InputWithValidation,
    SelectWithValidation,
    GroupCheckboxesWithValidation,
  },
})
export default class SuiviView extends Vue {
  @Prop() projetAnnuel: ProjetAnnuelDTO
  @Prop() readOnly: boolean
  @Prop({ default: true }) validationActivated: boolean
  @Prop({ default: true }) showValidationErrors: boolean

  contexteCheckBoxesStrings = Object.values(CONTEXTE_STRINGS).filter(
    (value) => value !== CONTEXTE_STRINGS.AUTRE
  )
  checkedContexteCheckBoxesStrings: string[] = []

  created(): void {
    this.dossierChanged()
  }

  @Watch("projetAnnuel")
  dossierChanged(): void {
    this.checkedContexteCheckBoxesStrings = this.getCheckedContexteCheckBoxesStrings()
  }

  modified(): void {
    this.$emit("dirtify-project")
    this.$emit("trigger-validation")
  }

  changeEntraves(): void {
    this.projetAnnuel.entraves = []
    if (this.checkedContexteCheckBoxesStrings.includes(CONTEXTE_STRINGS.NON)) {
      this.projetAnnuel.entraves.push("AUCUNE")
    }

    if (this.checkedContexteCheckBoxesStrings.includes(CONTEXTE_STRINGS.FINANCEMENT)) {
      this.projetAnnuel.entraves.push("FINANCEMENT")
    }
    if (this.checkedContexteCheckBoxesStrings.includes(CONTEXTE_STRINGS.METEO)) {
      this.projetAnnuel.entraves.push("ANNULATION_METEO")
    }
    if (this.checkedContexteCheckBoxesStrings.includes(CONTEXTE_STRINGS.MAIRIE)) {
      this.projetAnnuel.entraves.push("DIFFICULTES_MAIRIE")
    }
    if (this.checkedContexteCheckBoxesStrings.includes(CONTEXTE_STRINGS.USAGERS)) {
      this.projetAnnuel.entraves.push("TENSION_USAGERS_AIRE")
    }
    if (this.checkedContexteCheckBoxesStrings.includes(CONTEXTE_STRINGS.ADMINISTRATIF)) {
      this.projetAnnuel.entraves.push("DIFFICULTES_ADMINISTRATIVES")
    }
    if (this.checkedContexteCheckBoxesStrings.includes(CONTEXTE_STRINGS.ABSENCES)) {
      this.projetAnnuel.entraves.push("ABSENCE_LONG_TERME")
    }
    if (this.checkedContexteCheckBoxesStrings.includes(CONTEXTE_STRINGS.AMPLEUR)) {
      this.projetAnnuel.entraves.push("MAUVAISE_ESTIMATION")
    }
    if (this.checkedContexteCheckBoxesStrings.includes(CONTEXTE_STRINGS.AUTRE)) {
      this.projetAnnuel.entraves.push("AUTRE")
    }

    this.modified()
  }

  getCheckedContexteCheckBoxesStrings(): string[] {
    const contexteString: string[] = []
    if (this.projetAnnuel.entraves) {
      if (this.projetAnnuel.entraves.includes("AUCUNE")) {
        contexteString.push(CONTEXTE_STRINGS.NON)
      }
      if (this.projetAnnuel.entraves.includes("FINANCEMENT")) {
        contexteString.push(CONTEXTE_STRINGS.FINANCEMENT)
      }
      if (this.projetAnnuel.entraves.includes("ANNULATION_METEO")) {
        contexteString.push(CONTEXTE_STRINGS.METEO)
      }
      if (this.projetAnnuel.entraves.includes("DIFFICULTES_MAIRIE")) {
        contexteString.push(CONTEXTE_STRINGS.MAIRIE)
      }
      if (this.projetAnnuel.entraves.includes("TENSION_USAGERS_AIRE")) {
        contexteString.push(CONTEXTE_STRINGS.USAGERS)
      }
      if (this.projetAnnuel.entraves.includes("DIFFICULTES_ADMINISTRATIVES")) {
        contexteString.push(CONTEXTE_STRINGS.ADMINISTRATIF)
      }
      if (this.projetAnnuel.entraves.includes("ABSENCE_LONG_TERME")) {
        contexteString.push(CONTEXTE_STRINGS.ABSENCES)
      }
      if (this.projetAnnuel.entraves.includes("MAUVAISE_ESTIMATION")) {
        contexteString.push(CONTEXTE_STRINGS.AMPLEUR)
      }
      if (this.projetAnnuel.entraves.includes("AUTRE")) {
        contexteString.push(CONTEXTE_STRINGS.AUTRE)
      }
    }
    return contexteString
  }

  changeAutreEntraves(value: string): void {
    this.projetAnnuel.entravesAutres = value
    this.modified()
  }
}
