var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tag-dossier",
      class: { "has-comment": _vm.displayComment }
    },
    [
      _c(
        "div",
        [
          _c("b-tag", {
            class: _vm.status,
            domProps: {
              innerHTML: _vm._s(
                _vm.projectService.getProjectStatusLabel(
                  _vm.dossier,
                  _vm.loggedUser
                )
              )
            }
          })
        ],
        1
      ),
      _vm.displayComment
        ? _c(
            "b-tooltip",
            {
              attrs: {
                label: _vm.comment,
                type: "is-black",
                position: "is-left",
                multilined: true
              }
            },
            [
              _c(
                "div",
                { staticClass: "tag-dossier-commentaire", class: _vm.status },
                [_c("b-icon", { attrs: { icon: "comment-alt" } })],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }