var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "section",
      [
        _c(
          "b-tabs",
          {
            class: "sticky-tabs-headers " + (_vm.lowerTab ? "lower" : ""),
            attrs: { animated: false },
            model: {
              value: _vm.currentTabIndex,
              callback: function($$v) {
                _vm.currentTabIndex = $$v
              },
              expression: "currentTabIndex"
            }
          },
          [
            _c(
              "b-tab-item",
              {
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function() {
                      return [
                        _c("TabHeaderWithHint", {
                          attrs: {
                            label: _vm.$t(
                              "StructureSelectionView.label-description"
                            ),
                            tabValidationHint: _vm.tabValidationHints[0]
                          }
                        })
                      ]
                    },
                    proxy: true
                  }
                ])
              },
              [
                _c(
                  "ValidationObserver",
                  { ref: "observer-0" },
                  [
                    _c(
                      "InputWithValidation",
                      {
                        attrs: {
                          rules: "required",
                          label: _vm.$t("StructureSelectionView.label-nom"),
                          placeholder: _vm.$t(
                            "StructureSelectionView.placeholder-nom"
                          ),
                          disabled: _vm.readonly,
                          vid: "nomStructure",
                          controlAction: _vm.userCanChange && !_vm.readonly,
                          controlRight: true
                        },
                        on: { "manual-change": _vm.updateStructure },
                        model: {
                          value: _vm.structure.nom,
                          callback: function($$v) {
                            _vm.$set(_vm.structure, "nom", $$v)
                          },
                          expression: "structure.nom"
                        }
                      },
                      [
                        _vm.userCanChange && !_vm.readonly
                          ? _c(
                              "b-button",
                              {
                                attrs: { type: "is-dark" },
                                on: { click: _vm.changeStructure }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "profilePage.structure.change.change"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c("ValidationProvider", {
                      attrs: {
                        name: _vm.$t("StructureSelectionView.label-type"),
                        rules: "required"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var errors = ref.errors
                            var valid = ref.valid
                            return [
                              _c(
                                "b-field",
                                {
                                  attrs: {
                                    type: {
                                      "is-normal": _vm.readonly,
                                      "is-danger": errors[0],
                                      "is-success": valid
                                    },
                                    message: errors
                                  }
                                },
                                [
                                  _c("template", { slot: "label" }, [
                                    _c("div", { staticClass: "fieldLabel" }, [
                                      _c("div", {
                                        staticClass: "innerLabel",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$t(
                                              "StructureSelectionView.label-type"
                                            )
                                          )
                                        }
                                      }),
                                      !_vm.readonly
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "mandatory" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("obligatoire"))
                                                )
                                              ]
                                            )
                                          ])
                                        : _vm._e()
                                    ])
                                  ]),
                                  !_vm.readonly
                                    ? _c(
                                        "b-select",
                                        {
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "StructureSelectionView.placeholder-type"
                                            ),
                                            value: _vm.structure.type
                                          },
                                          on: { input: _vm.setStructureType }
                                        },
                                        [
                                          _c("option"),
                                          _vm._l(
                                            _vm.structureTypesRadios,
                                            function(option, i) {
                                              return _c(
                                                "option",
                                                {
                                                  key: option,
                                                  domProps: { value: option }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm
                                                          .structureTypesRadiosLabels[
                                                          i
                                                        ]
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            }
                                          ),
                                          _c(
                                            "optgroup",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  "StructureSelectionView.types-label-aire-protegee"
                                                )
                                              }
                                            },
                                            _vm._l(
                                              _vm.aireProtegeeTypes,
                                              function(aire, i) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: aire,
                                                    domProps: { value: aire }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm
                                                            .aireProtegeeTypesLabels[
                                                            i
                                                          ]
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "AUTRE" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "StructureSelectionView.label-autre"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        2
                                      )
                                    : _c("b-input", {
                                        attrs: {
                                          disabled: _vm.readonly,
                                          "custom-class":
                                            "SelectWithValidation-disabled"
                                        },
                                        model: {
                                          value: _vm.structure.type,
                                          callback: function($$v) {
                                            _vm.$set(_vm.structure, "type", $$v)
                                          },
                                          expression: "structure.type"
                                        }
                                      })
                                ],
                                2
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _vm.displayOther
                      ? _c("InputWithValidation", {
                          staticClass: "StructureUpdateView-autreType",
                          attrs: {
                            rules: "required",
                            placeholder: _vm.$t(
                              "StructureSelectionView.placeholder-autre"
                            ),
                            disabled: _vm.readonly,
                            vid: "typeAutreStructure"
                          },
                          on: { "manual-change": _vm.updateStructure },
                          model: {
                            value: _vm.structure.autreType,
                            callback: function($$v) {
                              _vm.$set(_vm.structure, "autreType", $$v)
                            },
                            expression: "structure.autreType"
                          }
                        })
                      : _vm._e(),
                    _c("GroupCheckboxesWithValidation", {
                      attrs: {
                        label: _vm.$t("StructureSelectionView.tete-structure"),
                        group: _vm.validationGroup,
                        data: _vm.tetesStructureLabels,
                        disabled: _vm.readonly,
                        verticalDisplay: "true",
                        inputLabel: _vm.$t(
                          "StructureSelectionView.label-AUTRE"
                        ),
                        extInputValue: _vm.structure.autreTeteStructure,
                        inputPlaceholder: _vm.$t(
                          "StructureSelectionView.placeholder-autre-tete"
                        ),
                        vid: "teteStructure"
                      },
                      on: {
                        "manual-change": _vm.updateTetesStructure,
                        "input-change": _vm.updateAutreTeteStructure
                      },
                      model: {
                        value: _vm.checkedTetesStructure,
                        callback: function($$v) {
                          _vm.checkedTetesStructure = $$v
                        },
                        expression: "checkedTetesStructure"
                      }
                    }),
                    _c("InputWithValidation", {
                      attrs: {
                        rules: "required",
                        type: "textarea",
                        label: _vm.$t("profilePage.structure.description"),
                        placeholder: _vm.$t(
                          "StructureSelectionView.placeholder-description"
                        ),
                        disabled: _vm.readonly,
                        vid: "descriptionStructure"
                      },
                      on: { "manual-change": _vm.updateStructure },
                      model: {
                        value: _vm.structure.description,
                        callback: function($$v) {
                          _vm.$set(_vm.structure, "description", $$v)
                        },
                        expression: "structure.description"
                      }
                    }),
                    _c(
                      "b-field",
                      {
                        attrs: {
                          label: _vm.$t("StructureSelectionView.label-agrement")
                        }
                      },
                      [
                        _c(
                          "b-switch",
                          {
                            attrs: { disabled: _vm.readonly },
                            on: { input: _vm.updateStructure },
                            model: {
                              value: _vm.structure.agree,
                              callback: function($$v) {
                                _vm.$set(_vm.structure, "agree", $$v)
                              },
                              expression: "structure.agree"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.structure.agree
                                    ? _vm.$t(
                                        "StructureSelectionView.label-agree"
                                      )
                                    : _vm.$t(
                                        "StructureSelectionView.label-non-agree"
                                      )
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm.structure.agree
                      ? _c(
                          "div",
                          [
                            _c("FileWithValidation", {
                              attrs: {
                                rules: "required",
                                inputNotice: _vm.$t(
                                  "StructureSelectionView.label-info-agrement"
                                ),
                                placeholder: _vm.$t(
                                  "StructureSelectionView.label-upload-agrement"
                                ),
                                fileOperationUrl: _vm.agrementFileUrl,
                                value: _vm.structure.agrementFilePresent,
                                vid: "agrementStructure",
                                disabled: _vm.readonly
                              },
                              on: {
                                "manual-change": _vm.updateStructure,
                                "delete-file": _vm.deleteAgrement,
                                "file-uploaded-or-deleted": _vm.agrementUploaded
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-tab-item",
              {
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function() {
                      return [
                        _c("TabHeaderWithHint", {
                          attrs: {
                            label: _vm.$t("profilePage.structure.adresse"),
                            tabValidationHint: _vm.tabValidationHints[1]
                          }
                        })
                      ]
                    },
                    proxy: true
                  }
                ])
              },
              [
                _c(
                  "ValidationObserver",
                  { ref: "observer-1" },
                  [
                    _c("AdresseView", {
                      attrs: {
                        readonly: _vm.readonly,
                        withPays: "true",
                        validationGroup: _vm.validationGroup
                      },
                      on: { "change-address": _vm.updateStructure },
                      model: {
                        value: _vm.structure,
                        callback: function($$v) {
                          _vm.structure = $$v
                        },
                        expression: "structure"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }