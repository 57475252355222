









import { TabValidationHint } from "@/model/ValidationResult"
import { Component, Prop, Vue } from "vue-property-decorator"

@Component({})
export default class TabHeaderWithHint extends Vue {
  @Prop({ default: "" }) label: string
  @Prop({ type: Object, default: () => new TabValidationHint() })
  tabValidationHint: TabValidationHint
}
