var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("GroupCheckboxesWithValidation", {
        attrs: {
          label: _vm.$t("instruction.milieu-interet-particulier"),
          data: _vm.allMilieuxInteretParticulierLabels(),
          verticalDisplay: "true",
          inputLabel: _vm.$t("instruction.AUTRE"),
          extInputValue: _vm.internalProjet.milieuInteretParticulierAutre,
          vid: "demarche",
          disabled: _vm.readOnly
        },
        on: {
          "manual-change": _vm.changeMilieuxInterets,
          "input-change": _vm.changeMilieuxInteretsAutre
        },
        model: {
          value: _vm.checkedMilieuxInteretsParticuliersLabels,
          callback: function($$v) {
            _vm.checkedMilieuxInteretsParticuliersLabels = $$v
          },
          expression: "checkedMilieuxInteretsParticuliersLabels"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }