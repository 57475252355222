var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "CheckBoxEntry-infos-container" },
      [
        _c(
          "b-checkbox",
          {
            attrs: { disabled: _vm.disabled },
            on: {
              input: function(value) {
                return _vm.$emit("checked", value)
              }
            },
            model: {
              value: _vm.innerChecked,
              callback: function($$v) {
                _vm.innerChecked = $$v
              },
              expression: "innerChecked"
            }
          },
          [_c("div", [_vm._v(" " + _vm._s(_vm.entryLabel) + " ")])]
        ),
        !_vm.hideExtrasUntilChecked || _vm.innerChecked
          ? _c(
              "div",
              { staticClass: "CheckBoxEntry-extras-container" },
              [
                _vm.extraInput1Placeholder
                  ? _c("CheckBoxEntryInput", {
                      attrs: {
                        entryInputControl: _vm.extraInput1Control,
                        entryInputPlaceholder: _vm.extraInput1Placeholder,
                        entryInputValue: _vm.extraInput1Value,
                        disabled: _vm.disabled || !_vm.innerChecked,
                        vid: _vm.vid + "_input1",
                        group: _vm.group,
                        rules: _vm.extraInput1Rule,
                        resetInput: _vm.resetInputs
                      },
                      on: {
                        input: function(value) {
                          return _vm.$emit("input-1", value)
                        }
                      }
                    })
                  : _vm._e(),
                _vm.extraInput2Placeholder
                  ? _c("CheckBoxEntryInput", {
                      attrs: {
                        entryInputControl: _vm.extraInput2Control,
                        entryInputPlaceholder: _vm.extraInput2Placeholder,
                        entryInputValue: _vm.extraInput2Value,
                        disabled: _vm.disabled || !_vm.innerChecked,
                        vid: _vm.vid + "_input2",
                        group: _vm.group,
                        rules: _vm.extraInput2Rule,
                        resetInput: _vm.resetInputs
                      },
                      on: {
                        input: function(value) {
                          return _vm.$emit("input-2", value)
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ],
      1
    ),
    _vm.entryInputPlaceholder
      ? _c(
          "div",
          [
            _c("CheckBoxEntryInput", {
              staticClass: "CheckBoxEntry-input",
              attrs: {
                name: _vm.entryLabel,
                entryInputPlaceholder: _vm.entryInputPlaceholder,
                entryInputValue: _vm.entryInputValue,
                disabled: _vm.disabled || !_vm.innerChecked,
                group: _vm.group,
                vid: _vm.vid,
                resetInput: _vm.resetInputs
              },
              on: {
                input: function(value) {
                  return _vm.$emit("entry-input", value)
                }
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }