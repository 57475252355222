var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "projet-part" },
    [
      _c("h2", [
        _vm._v(_vm._s(_vm.$t("projetPage.mon-annee.autres-infos.titre")))
      ]),
      _c("InputWithValidation", {
        attrs: {
          validationActivated: _vm.validationActivated,
          showValidationErrors: _vm.showValidationErrors,
          type: "textarea",
          label: _vm.$t("projetPage.mon-annee.autres-infos.autres-infos"),
          inputNotice: _vm.$t(
            "projetPage.mon-annee.autres-infos.autres-infos-hint"
          ),
          maxlength: "1400",
          disabled: _vm.readonly || !_vm.isPorteurProjet,
          vid: "autre_procedesDeterminationActions"
        },
        on: { "manual-change": _vm.changeAutresInfos },
        model: {
          value: _vm.projetAnnuel.autresInfos,
          callback: function($$v) {
            _vm.$set(_vm.projetAnnuel, "autresInfos", $$v)
          },
          expression: "projetAnnuel.autresInfos"
        }
      }),
      _c("FileWithValidation", {
        attrs: {
          validationActivated: _vm.validationActivated,
          showValidationErrors: _vm.showValidationErrors,
          placeholder: _vm.$t(
            "projetPage.infoAdministratives.charte.televerser"
          ),
          inputNotice: _vm.$t(
            "projetPage.mon-annee.autres-infos.autres-infos-fichier-message"
          ),
          fileOperationUrl: _vm.autresInfosFileUrl,
          disabled: _vm.readonly,
          vid: "autre_fichierProblematiques",
          value: _vm.projetAnnuel.autresInfosFilePresent,
          defaultFileName: "FICHIER_COMPLEMENTAIRE.pdf",
          rules:
            _vm.projetAnnuel.typeDossier === "DOSSIER_RENOUVELLEMENT_LIGHT"
              ? "required"
              : ""
        },
        on: { "add-file": _vm.addFile, "delete-file": _vm.deleteFile }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }