




























































































































import { ConseilDTO, ProjetAnnuelDTO } from "@/model/bean/GeneratedDTOs"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import SelectWithValidation from "@/views/inputs/SelectWithValidation.vue"
import DatePickerWithValidation from "@/views/inputs/DatePickerWithValidation.vue"
import { ValidationObserver } from "vee-validate"
import ConseilCard from "@/views/project/ConseilCard.vue"
import { ConseilService } from "@/services/rest/project/ConseilService"
import { SettingsService } from "@/services/rest/utils/SettingsService"
import { InfoReportingService } from "@/services/log/InfoReportingService"

@Component({
  components: {
    SelectWithValidation,
    InputWithValidation,
    ConseilCard,
    DatePickerWithValidation,
    ValidationObserver,
  },
})
export default class ConseilsDesElevesView extends Vue {
  @Prop() projetAnnuel: ProjetAnnuelDTO
  @Prop() readonly: boolean
  @Prop() isPorteurProjet: boolean
  @Prop({ default: false }) validationActivated: boolean
  @Prop({ default: true }) showValidationErrors: boolean

  private conseilService = ConseilService.INSTANCE
  private settingsService = SettingsService.INSTANCE
  private infoReportingService = InfoReportingService.INSTANCE

  maxUploadSizeString = ""

  displayOther = false
  displayOrganisation = false
  constitutionConseil = [
    "CLASSES_ENTIERES",
    "DELEGUES",
    "CLASSES_ENTIERES_PUIS_DELEGUES",
    "GROUPE_ELEVES",
    "GROUPE_ELEVES_ET_DELEGUES",
    "AUTRE",
  ]
  constitutionConseilLabels = this.constitutionConseil.map((value) => {
    return this.$t(`projetPage.mon-annee.conseils.${value}`).toString()
  })
  calculatedRules = "required"
  showRequiredMessage = true

  created(): void {
    this.computeDisplayOther()
    this.computeDisplayOrganisation()
  }

  mounted(): void {
    this.updateConseilsLiensRules()
  }

  @Watch("projetAnnuel")
  projetAnnuelChanged(): void {
    if (!this.projetAnnuel || !this.projetAnnuel.id) {
      return
    }
    this.computeDisplayOther()
    this.computeDisplayOrganisation()
  }

  @Watch("projetAnnuel.classes")
  classesChanged(): void {
    this.computeDisplayOrganisation()
  }

  @Watch("projetAnnuel.problematiquesLiens")
  @Watch("projetAnnuel.diffusionLiensEnseignements")
  @Watch("projetAnnuel.etatDesLieuxLiensEnseignements")
  @Watch("projetAnnuel.conseilsLiens")
  updateConseilsLiensRules(): void {
    if (
      !this.projetAnnuel.problematiquesLiens &&
      !this.projetAnnuel.diffusionLiensEnseignements &&
      !this.projetAnnuel.etatDesLieuxLiensEnseignements
    ) {
      this.calculatedRules = "required"
    } else {
      if (this.projetAnnuel.conseilsLiens) {
        this.calculatedRules = "required"
      } else {
        this.calculatedRules = ""
      }
    }

    if (
      this.projetAnnuel.problematiquesLiens ||
      this.projetAnnuel.conseilsLiens ||
      this.projetAnnuel.diffusionLiensEnseignements ||
      this.projetAnnuel.etatDesLieuxLiensEnseignements
    ) {
      this.showRequiredMessage = false
    } else {
      this.showRequiredMessage = true
    }
  }

  changeConseils(): void {
    this.computeDisplayOther()
    this.$emit("dirtify-project")
  }

  changeConseilsLiens(): void {
    this.updateConseilsLiensRules()
    this.changeConseils()
  }

  private computeDisplayOther(): void {
    if (this.projetAnnuel.constitutionConseilTerreMer === "AUTRE") {
      this.displayOther = true
    } else {
      this.displayOther = false
    }
  }

  private computeDisplayOrganisation(): void {
    if (this.projetAnnuel.classes.length > 1 || this.projetAnnuel.organisationClasses) {
      this.displayOrganisation = true
    } else {
      this.displayOrganisation = false
    }
  }

  added(conseil: ConseilDTO, file: File): void {
    if (this.projetAnnuel.id) {
      this.conseilService
        .create(this.projetAnnuel.id, conseil, file)
        .then((projetAnnuel) => {
          this.projetAnnuel.fichiers = projetAnnuel.fichiers
          this.projetAnnuel.conseils = projetAnnuel.conseils
          this.$emit("dirtify-project")
        })
        .catch(async (error) => {
          const settings = await this.settingsService.getSettings()
          let uploadSize = parseInt(settings.MAX_UPLOAD_SIZE.toLowerCase().replace("K", ""))
          if (uploadSize > 0) {
            this.maxUploadSizeString = "(max " + Math.floor(uploadSize / 1000) + "Mo)"
          }
          this.infoReportingService.error(
            this.$t("upload-failed", [this.maxUploadSizeString]).toString(),
            error
          )
        })
    }
  }

  deleted(conseil: ConseilDTO): void {
    if (this.projetAnnuel.id) {
      this.conseilService.delete(this.projetAnnuel.id, conseil.id).then((projetAnnuel) => {
        this.projetAnnuel.fichiers = projetAnnuel.fichiers
        this.projetAnnuel.conseils = projetAnnuel.conseils
        this.$emit("dirtify-project")
      })
    }
  }
}
