var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.projetAnnuel.statut !== "INSTRUCTION_EN_COURS" &&
    _vm.projetAnnuel.statut !== "INSTRUCTION_TERMINEE" &&
    _vm.projetAnnuel.statut !== "EN_ATTENTE"
    ? _c("div", { staticClass: "projet-part" }, [
        _c("h2", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("projet.labellise.result.titre" + _vm.renewalSuffix)
              ) +
              " "
          )
        ]),
        _c("fieldset", [
          _vm.projetAnnuel.statut === "EN_ATTENTE"
            ? _c("legend", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "projet.labellise.result.saisi" + _vm.renewalSuffix
                      )
                    ) +
                    " "
                )
              ])
            : _c("legend", [
                _vm.projetAnnuel.statut === "ACCEPTE"
                  ? _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "projet.labellise.result.accepte" +
                                _vm.renewalSuffix
                            )
                          ) +
                          " "
                      )
                    ])
                  : _vm.projetAnnuel.statut === "LABELLISATION_EN_DEVENIR"
                  ? _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "projet.labellise.result.en-devenir" +
                                _vm.renewalSuffix
                            )
                          ) +
                          " "
                      )
                    ])
                  : _vm.projetAnnuel.statut === "REFUSE"
                  ? _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "projet.labellise.result.refuse" +
                                _vm.renewalSuffix
                            )
                          ) +
                          " "
                      )
                    ])
                  : _vm._e()
              ]),
          _vm.projetAnnuel.evaluationFinale
            ? _c("div", [
                _c("b", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("projet.labellise.result.instructeur-commentaire")
                    )
                  )
                ]),
                _c("br"),
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.projetAnnuel.evaluationFinale.commentaireInstructeur.replace(
                        "\n",
                        "<br/>"
                      )
                    )
                  }
                })
              ])
            : _vm._e()
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }