

















import { Component, Prop, Vue } from "vue-property-decorator"

@Component({
  components: {},
})
export default class ImageWithModal extends Vue {
  @Prop({ default: true }) withModal: boolean
  @Prop({ default: "" }) name: string
  @Prop({ default: "" }) missingImgLabel: string
  @Prop({ default: "100%" }) imgHeight: string
  @Prop({ default: "100%" }) imgWidth: string

  isImageModalActive = false

  /**
   * In order to generate dynamic image src.
   * See here :
   * https://stackoverflow.com/questions/40491506/vue-js-dynamic-images-not-working
   */
  getImgUrl(): string {
    var images = require.context("@/assets/images", false, /\.png$/)
    return images("./" + this.name + ".png")
  }
}
