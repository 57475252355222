



















import { LoginService } from "@/services/rest/login/LoginService"
import Vue from "vue"
import PageView from "@/views/commons/PageView.vue"
import ContentView from "@/views/commons/ContentView.vue"
import { Component, Prop } from "vue-property-decorator"
import { InfoReportingService } from "@/services/log/InfoReportingService"

@Component({
  components: { PageView, ContentView },
})
export default class ValidateEmailPage extends Vue {
  loginService = LoginService.INSTANCE
  infoReportingService = InfoReportingService.INSTANCE

  @Prop() validationToken: string
  loading = true
  emailValidated = false
  requireValidation = false

  mounted(): void {
    this.validateEmail()
  }

  async validateEmail(): Promise<void> {
    try {
      const user = await this.loginService.validateEmail(this.validationToken)
      this.emailValidated = true
      this.loading = false
      if (user.statutActivation != "ACTIF") {
        this.requireValidation = true
      } else {
        // Nothing to do: user already re-routed after succesfull login (see LoginService.loginSucceeded())
      }
    } catch (e) {
      this.emailValidated = false
      this.loading = false
    }
  }
}
