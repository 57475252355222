








import { Component, Vue } from "vue-property-decorator"
import BookmarkCard from "../generic_components/BookmarkCard.vue"
import { TAG_TYPES_FOR_BOOKMARK_CARD } from "@/model/bean/EnumHelper"

@Component({
  components: { BookmarkCard },
})
export default class RecapCard extends Vue {
  tagType = TAG_TYPES_FOR_BOOKMARK_CARD.RECAP
}
