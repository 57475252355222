















































import { ValidationProvider } from "vee-validate"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"

@Component({
  components: {
    ValidationProvider,
  },
})
export default class InlinedInputWithValidation extends Vue {
  @Prop({ default: "" }) group: string
  @Prop() vid: string
  @Prop() rules: [string]
  @Prop() value: string
  @Prop({ default: "" }) placeholder: string
  @Prop({ default: "" }) customError: string
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: false }) passwordReveal: boolean
  @Prop({ default: "" }) maxlength: number
  @Prop({ default: "" }) autocomplete: string
  @Prop({ default: true }) hasCounter: boolean
  innerValue = ""
  isMandatory = false

  created(): void {
    if (this.value != null && this.value != undefined) {
      this.innerValue = this.value
    }
    this.isMandatory = this.rules && this.rules.includes("required")
  }

  @Watch("innerValue")
  onInnerValueChanged(newVal: string, _oldValue: string): void {
    this.$emit("input", newVal)
  }

  @Watch("value")
  onExternalValueChanged(newVal: string, _oldValue: string): void {
    this.innerValue = newVal
    if (newVal != _oldValue) {
      this.$emit("manual-change", newVal)
    }
  }
}
