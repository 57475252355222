






import { Component, Vue } from "vue-property-decorator"
import PageView from "@/views/commons/PageView.vue"
import ActualitesView from "@/views/grae/ActualitesView.vue"

@Component({
  components: {
    PageView,
    ActualitesView,
  },
})
export default class ActualitesNationalesPage extends Vue {}
