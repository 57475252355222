





























import { Component, Vue, Prop, Watch } from "vue-property-decorator"

@Component
export default class TabsDropdownSelector extends Vue {
  @Prop() tabs: string[]
  @Prop() activeTab: number
  @Prop() currentContainerWidth: number
  @Prop({ default: false }) disabled: number
  arrowType = "is-primary"

  changeTab(tabIndex: number): void {
    if (!this.disabled) {
      this.$emit("change-tab", tabIndex)
    }
  }

  @Watch("disabled")
  disabledChanged(): void {
    if (this.disabled) {
      this.arrowType = "is-grey"
    } else {
      this.arrowType = "is-primary"
    }
  }
}
