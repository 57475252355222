var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      staticClass: "NotesModale",
      attrs: {
        active: _vm.open,
        "has-modal-card": "",
        "trap-focus": "",
        "aria-role": "dialog",
        "aria-label": "NotesModale",
        "aria-modal": "",
        width: "100vw"
      },
      on: { cancel: _vm.emitCloseModale }
    },
    [
      [
        _c("div", { staticClass: "modal-card" }, [
          _c(
            "header",
            { staticClass: "modal-card-head modal-card-head-notes" },
            [
              _c("div", { staticClass: "notes" }, [
                _vm._v(" " + _vm._s(_vm.$t("notesModale.title")) + " "),
                _c("br"),
                _c("div", { staticClass: "notes-line" })
              ]),
              _c("div", { staticClass: "notes-grae" }, [
                _vm._v(" " + _vm._s(_vm.grae.region) + " ")
              ])
            ]
          ),
          _c("section", { staticClass: "modal-card-body" }, [
            _vm.grae.dateModification
              ? _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column has-text-right" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm
                            .$t("notesModale.modified", [
                              _vm.formatDate(_vm.grae.dateModification)
                            ])
                            .toString()
                        ) +
                        " "
                    )
                  ])
                ])
              : _vm._e(),
            _c("div", { staticClass: "columns" }, [
              _c(
                "div",
                { staticClass: "column" },
                [
                  _c("InputWithValidation", {
                    attrs: {
                      type: "textarea",
                      vid: "notes",
                      maxlength: "1400",
                      disabled: !_vm.canUserEditNotes
                    },
                    model: {
                      value: _vm.grae.note,
                      callback: function($$v) {
                        _vm.$set(_vm.grae, "note", $$v)
                      },
                      expression: "grae.note"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c("footer", { staticClass: "modal-card-foot" }, [
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _c(
                  "div",
                  {
                    staticClass: "hoverable-discrete-link mr-3",
                    on: { click: _vm.emitCloseModale }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("annuler")) + " ")]
                ),
                _vm.canUserEditNotes
                  ? _c(
                      "b-button",
                      {
                        attrs: { rounded: "", type: "is-primary" },
                        on: { click: _vm.saveAndClose }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("notesModale.save")) + " ")]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ])
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }