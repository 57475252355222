var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      staticClass: "CommentaireModal",
      attrs: {
        active: _vm.open,
        "has-modal-card": "",
        "trap-focus": "",
        "aria-role": "dialog",
        "aria-label": "CommentaireModal",
        "aria-modal": "",
        width: "100vw"
      },
      on: { cancel: _vm.emitCloseModale }
    },
    [
      [
        _c("div", { staticClass: "modal-card" }, [
          _c(
            "header",
            { staticClass: "modal-card-head" },
            [
              _c("H3TitleView", { staticClass: "modal-card-title" }, [
                _vm._v(
                  _vm._s(_vm.$t("instruction-pannel.echanges.commentaire"))
                )
              ]),
              _c("div", { staticClass: "ActualitesModalView-infos" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("instruction-pannel.echanges.date-creation") +
                        " " +
                        _vm.formatDate(_vm.commentaire.dateCreation) +
                        (_vm.commentaire.auteur
                          ? " par " +
                            _vm.commentaire.auteur.prenom +
                            " " +
                            _vm.commentaire.auteur.nom
                          : "")
                    ) +
                    " "
                )
              ])
            ],
            1
          ),
          _c(
            "section",
            { staticClass: "modal-card-body" },
            [
              _c("InputWithValidation", {
                attrs: {
                  type: "textarea",
                  label: _vm.$t("instruction-pannel.echanges.content"),
                  maxlength: "1400",
                  vid: "commentaire-content",
                  disabled: "true"
                },
                model: {
                  value: _vm.commentaire.contenu,
                  callback: function($$v) {
                    _vm.$set(_vm.commentaire, "contenu", $$v)
                  },
                  expression: "commentaire.contenu"
                }
              })
            ],
            1
          ),
          _c("footer", { staticClass: "modal-card-foot" }, [
            _c("div", { staticClass: "buttons" }, [
              _c(
                "div",
                {
                  staticClass: "hoverable-discrete-link mr-3",
                  on: { click: _vm.emitCloseModale }
                },
                [_vm._v(" " + _vm._s(_vm.$t("close-generic")) + " ")]
              )
            ])
          ])
        ])
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }