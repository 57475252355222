var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c(
      "div",
      { staticClass: "EtablissementProjetsView-cards" },
      [
        _vm._l(_vm.etablissementProjects, function(project) {
          return _c(
            "div",
            { key: project.id },
            [
              _c("ProjectCard", {
                attrs: { project: project, loggedUser: _vm.loggedUser }
              })
            ],
            1
          )
        }),
        _vm.etablissementProjects.length === 0
          ? _c("b-message", { attrs: { type: "is-warning" } }, [
              _vm._v(" " + _vm._s(_vm.$t("etablissementPage.noProjects")) + " ")
            ])
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }