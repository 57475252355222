var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationProvider", {
    attrs: {
      vid: (_vm.group != "" ? _vm.group + "_" : "") + _vm.vid,
      name: _vm.$attrs.name || _vm.$attrs.label,
      rules: _vm.rules
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var errors = ref.errors
          var valid = ref.valid
          return [
            _c(
              "b-field",
              {
                staticClass: "DropDownMultipleWithValidation-field",
                attrs: {
                  type: {
                    "is-danger": errors[0],
                    "is-success": valid
                  },
                  message: [errors[0]]
                }
              },
              [
                _c(
                  "template",
                  { slot: "label" },
                  [
                    _c("FormInputLabel", {
                      attrs: {
                        label: _vm.$attrs.label,
                        isMandatory: _vm.isMandatory,
                        disabled: _vm.disabled,
                        inputNotice: _vm.inputNotice,
                        tooltip: _vm.tooltip
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-dropdown",
                  {
                    attrs: { multiple: "", "aria-role": "list" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "trigger",
                          fn: function() {
                            return [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    type: "is-primary",
                                    "icon-right": "angle-down",
                                    disabled: _vm.disabled
                                  }
                                },
                                _vm._l(_vm.innerValue, function(option) {
                                  return _c(
                                    "span",
                                    { key: _vm.vid + option + "_span" },
                                    [_vm._v(" " + _vm._s(option) + " ")]
                                  )
                                }),
                                0
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.innerValue,
                      callback: function($$v) {
                        _vm.innerValue = $$v
                      },
                      expression: "innerValue"
                    }
                  },
                  _vm._l(_vm.data, function(option, i) {
                    return _c(
                      "b-dropdown-item",
                      {
                        key: _vm.vid + option + "_dropdown",
                        attrs: {
                          value: option,
                          "aria-role": "listitem",
                          disabled:
                            _vm.maxNumberReached &&
                            !_vm.innerValue.includes(option)
                        }
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.selectLabels && _vm.selectLabels.length !== 0
                                ? _vm.selectLabels[i]
                                : option
                            )
                          )
                        ])
                      ]
                    )
                  }),
                  1
                )
              ],
              2
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }