var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "hidden-on-pdf" },
      [
        _vm.validationActivated && !_vm.innerZoneGeographique
          ? _c("b-message", { attrs: { type: "is-danger" } }, [
              _vm._v(
                _vm._s(_vm.$t("mapDraw.mandatory-zone-geographique")) + " "
              )
            ])
          : _vm._e(),
        _vm.mapVisible
          ? _c(
              "div",
              { staticClass: "MapDrawView-container" },
              [
                _c("l-map", {
                  ref: "map",
                  staticClass: "MapDrawView-map",
                  attrs: {
                    zoom: _vm.initialZoom,
                    center: _vm.initialCenter,
                    maxZoom: 17,
                    minZoom: 2
                  },
                  on: {
                    ready: _vm.mapIsReady,
                    "draw:drawstop": _vm.stopDraw,
                    "draw:created": _vm.enableEditPolygon,
                    "draw:editstop": _vm.updateZoneGeographique
                  }
                }),
                _vm.showDetails
                  ? _c("ProjectDetails", {
                      attrs: {
                        project: _vm.selectedProject,
                        role: _vm.role,
                        icon: _vm.iconDetails,
                        userRegionId: _vm.userRegionId,
                        instructedGraes: _vm.instructedGraes
                      },
                      on: { close: _vm.closeDetails }
                    })
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "MapDrawView-legend-container" }, [
          _c("div", { staticClass: "MapDrawView-legend-grid" }, [
            _c("div", { staticClass: "MapDrawView-legend" }, [
              _c("img", {
                staticClass: "icon",
                staticStyle: { margin: "5px" },
                attrs: { src: "/blueCircle.ico", alt: "AMENV" }
              }),
              _vm._v(" " + _vm._s(_vm.$t("mapDraw.legends.AMENV")) + " ")
            ]),
            _c("div", { staticClass: "MapDrawView-legend" }, [
              _c("img", {
                staticClass: "icon",
                staticStyle: { margin: "5px" },
                attrs: { src: "/AME.ico", alt: "AME" }
              }),
              _vm._v(" " + _vm._s(_vm.$t("mapDraw.legends.AME")) + " ")
            ]),
            _c("div", { staticClass: "MapDrawView-legend" }, [
              _c("img", {
                staticClass: "icon",
                staticStyle: { margin: "5px" },
                attrs: { src: "/AME_R.ico", alt: "AME" }
              }),
              _vm._v(" " + _vm._s(_vm.$t("mapDraw.legends.AME_R")) + " ")
            ]),
            _c("div", { staticClass: "MapDrawView-legend" }, [
              _c("img", {
                staticClass: "icon",
                staticStyle: { margin: "5px" },
                attrs: { src: "/greenCircle.ico", alt: "ATENV" }
              }),
              _vm._v(" " + _vm._s(_vm.$t("mapDraw.legends.ATENV")) + " ")
            ]),
            _c("div", { staticClass: "MapDrawView-legend" }, [
              _c("img", {
                staticClass: "icon",
                staticStyle: { margin: "5px" },
                attrs: { src: "/ATE.ico", alt: "ATE" }
              }),
              _vm._v(" " + _vm._s(_vm.$t("mapDraw.legends.ATE")) + " ")
            ]),
            _c("div", { staticClass: "MapDrawView-legend" }, [
              _c("img", {
                staticClass: "icon",
                staticStyle: { margin: "5px" },
                attrs: { src: "/ATE_R.ico", alt: "ATE" }
              }),
              _vm._v(" " + _vm._s(_vm.$t("mapDraw.legends.ATE_R")) + " ")
            ])
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "only-on-pdf" }, [
      _c("img", { attrs: { id: "map-screenshot" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }