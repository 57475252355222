









































































































































































import { ProjetAnnuelDTO } from "@/model/bean/GeneratedDTOs"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import FileWithValidation from "@/views/inputs/FileWithValidation.vue"
import { FileService } from "@/services/rest/utils/FileService"

@Component({ components: { InputWithValidation, FileWithValidation } })
export default class EtatDesLieuxView extends Vue {
  fileService = FileService.INSTANCE

  @Prop() projetAnnuel: ProjetAnnuelDTO
  @Prop() readonly: boolean
  @Prop() isPorteurProjet: boolean
  @Prop({ default: false }) validationActivated: boolean
  @Prop({ default: true }) showValidationErrors: boolean
  @Prop() section: string
  @Prop() currentWidth: number
  @Prop() currentScrollY: number

  requiredIfAllFieldsAreEmptyRule = "required"
  etatDesLieuxFileUrl = ""
  etatDesLieuxLiensUrl = ""
  calculatedRules = "required"
  showRequiredMessage = true

  created(): void {
    if (this.projetAnnuel.id) {
      this.etatDesLieuxFileUrl = this.fileService.buildUniqueFileURL(
        this.projetAnnuel.id,
        "ETAT_DES_LIEUX"
      )
      this.etatDesLieuxLiensUrl = this.fileService.buildUniqueFileURL(
        this.projetAnnuel.id,
        "ETAT_DES_LIEUX_LIENS_ENSEIGNEMENTS"
      )
    }
  }

  destroyed(): void {
    this.deleteSVG()
  }

  mounted(): void {
    this.$nextTick(this.drawSpiderLines)
    this.updateEtatdesLieuxLiensRenseignementRules()
    this.recomputedRequiredIfAllFieldsAreEmptyRule()
  }

  dirtifyProject(): void {
    this.recomputedRequiredIfAllFieldsAreEmptyRule()
    this.$emit("dirtify-project")
  }

  @Watch("projetAnnuel")
  dossierChanged(): void {
    if (!this.projetAnnuel || !this.projetAnnuel.id) {
      return
    }
    this.etatDesLieuxFileUrl = this.fileService.buildUniqueFileURL(
      this.projetAnnuel.id,
      "ETAT_DES_LIEUX"
    )
    this.etatDesLieuxLiensUrl = this.fileService.buildUniqueFileURL(
      this.projetAnnuel.id,
      "ETAT_DES_LIEUX_LIENS_ENSEIGNEMENTS"
    )
  }

  addLiensFile(): void {
    this.projetAnnuel.etatdesLieuxLiensFilePresent = true
    this.$emit("trigger-validation")
  }

  deleteLiensFile(): void {
    this.projetAnnuel.etatdesLieuxLiensFilePresent = false
    this.$emit("trigger-validation")
  }

  addEtatDesLieuxFile(): void {
    this.projetAnnuel.etatdesLieuxFilePresent = true
    this.$emit("trigger-validation")
  }

  deleteEtatDesLieuxFile(): void {
    this.projetAnnuel.etatdesLieuxFilePresent = false
    this.$emit("trigger-validation")
  }

  recomputedRequiredIfAllFieldsAreEmptyRule(): void {
    // If all fields are empty => required
    if (
      this.projetAnnuel.typeDossier !== "DOSSIER_RENOUVELLEMENT_LIGHT" &&
      !this.projetAnnuel.etatDesLieuxContexteAdministratif &&
      !this.projetAnnuel.etatDesLieuxContextePhysique &&
      !this.projetAnnuel.etatDesLieuxContextePatrimonial &&
      !this.projetAnnuel.etatDesLieuxContexteSocio &&
      !this.projetAnnuel.etatDesLieuxContexteEcoGeo
    ) {
      this.requiredIfAllFieldsAreEmptyRule = "required"
    } else {
      // optionnal otherwise
      this.requiredIfAllFieldsAreEmptyRule = ""
    }
  }

  @Watch("projetAnnuel.problematiquesLiens")
  @Watch("projetAnnuel.conseilsLiens")
  @Watch("projetAnnuel.diffusionLiensEnseignements")
  @Watch("projetAnnuel.etatDesLieuxLiensEnseignements")
  updateEtatdesLieuxLiensRenseignementRules(): void {
    if (
      !this.projetAnnuel.problematiquesLiens &&
      !this.projetAnnuel.conseilsLiens &&
      !this.projetAnnuel.diffusionLiensEnseignements
    ) {
      this.calculatedRules = "required"
    } else {
      if (this.projetAnnuel.etatDesLieuxLiensEnseignements) {
        this.calculatedRules = "required"
      } else {
        this.calculatedRules = ""
      }
    }

    if (
      this.projetAnnuel.problematiquesLiens ||
      this.projetAnnuel.conseilsLiens ||
      this.projetAnnuel.diffusionLiensEnseignements ||
      this.projetAnnuel.etatDesLieuxLiensEnseignements
    ) {
      this.showRequiredMessage = false
    } else {
      this.showRequiredMessage = true
    }
  }

  @Watch("currentScrollY")
  @Watch("currentWidth")
  drawSpiderLines(): void {
    const existingSVG = document.getElementById("lines-svg")
    if (existingSVG) {
      existingSVG.remove()
    }
    if (this.section == "mon-annee") {
      const strokeColor = "#003976"
      const strokeWidth = "2"
      const circleR = 10

      const startPointEl = document.getElementById("circle")
      const topMostEl = document.getElementById("etat1")
      const bottomMostEl = document.getElementById("etat5")
      // @ts-ignore
      if (startPointEl && topMostEl && bottomMostEl) {
        var startPoint = this.getOffset(startPointEl)
        var topMost = this.getOffset(topMostEl)
        var bottomMost = this.getOffset(bottomMostEl)
        let height = bottomMost.top + bottomMost.height - topMost.top
        let startPointX = startPoint.left + startPoint.width / 2
        let startPointY = height / 2
        let width = topMost.left - 5 - startPointX

        const leftCircle = document.getElementById("left_circle")
        if (leftCircle) {
          leftCircle.style.height = height + "px"
        }

        // Create SVG element
        const leftPane = document.getElementById("panel-left")
        if (leftPane) {
          const visiblePane = leftPane.getBoundingClientRect()
          const visiblePaneHeight = visiblePane.bottom
          // Truncate svg size to never exceed visible region height
          // And hence make sure it does not create scroll in document
          if (topMost.top + height > visiblePaneHeight) {
            height = visiblePaneHeight - topMost.top
          }
        }

        const svgNS = "http://www.w3.org/2000/svg"
        var svgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg")
        svgElement.setAttributeNS(
          "http://www.w3.org/2000/xmlns/",
          "xmlns:xlink",
          "http://www.w3.org/1999/xlink"
        )
        svgElement.setAttribute("id", "lines-svg")
        svgElement.setAttribute("width", width.toString())
        svgElement.setAttribute("height", height.toString())
        svgElement.setAttribute(
          "style",
          "position:absolute; left:" + startPointX + "px; top: " + topMost.top + "px;"
        )

        for (let i = 1; i < 6; i++) {
          const targetPointEl = document.getElementById("etat" + i)
          if (targetPointEl) {
            var targetPoint = this.getOffset(targetPointEl)
            let targetPointY = Math.abs(topMost.top - targetPoint.top) + 25

            // Draw line to target point
            var line = document.createElementNS(svgNS, "line")
            line.setAttributeNS(null, "x1", "0")
            line.setAttributeNS(null, "y1", startPointY.toString())
            line.setAttributeNS(null, "x2", (width - circleR).toString())
            line.setAttributeNS(null, "y2", targetPointY.toString())
            line.setAttributeNS(null, "stroke-width", strokeWidth)
            line.setAttributeNS(null, "stroke", strokeColor)

            // Draw circle next to target point
            svgElement.appendChild(line)
            var circle = document.createElementNS(svgNS, "circle")
            circle.setAttributeNS(null, "cx", (width - circleR).toString())
            circle.setAttributeNS(null, "cy", targetPointY.toString())
            circle.setAttributeNS(null, "r", circleR.toString())
            circle.setAttributeNS(null, "fill", strokeColor)
            svgElement.appendChild(circle)
          }
          document.body.appendChild(svgElement)
        }
      }
    }
  }

  getOffset(el: HTMLElement): any {
    var rect = el.getBoundingClientRect()
    return {
      left: rect.left + window.pageXOffset,
      top: rect.top + window.pageYOffset,
      width: rect.width || el.offsetWidth,
      height: rect.height || el.offsetHeight,
    }
  }

  deleteSVG(): void {
    const existingSVG = document.getElementById("lines-svg")
    if (existingSVG) {
      existingSVG.remove()
    }
  }

  @Watch("section")
  sectionChanged(): void {
    if (this.section == "mon-annee") {
      this.$nextTick(this.drawSpiderLines)
    } else {
      this.deleteSVG()
    }
  }
}
