


































































































































import { LoginService } from "@/services/rest/login/LoginService"
import { FeedbackService } from "@/services/rest/utils/FeedbackService"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import { FeedbackDTO } from "@/model/bean/GeneratedDTOs"
import { Component, Vue } from "vue-property-decorator"
import { ValidationObserver } from "vee-validate"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import html2canvas from "html2canvas"
import PDFLinkWithModal from "@/views/commons/PDFLinkWithModal.vue"
import H3TitleView from "../commons/H3TitleView.vue"

@Component({
  components: {
    InputWithValidation,
    ValidationObserver,
    PDFLinkWithModal,
    H3TitleView,
  },
})
export default class FeedbackModal extends Vue {
  private feedbackService = FeedbackService.INSTANCE
  private loginService = LoginService.INSTANCE
  private infoReportingService = InfoReportingService.INSTANCE

  display = false

  version = process.env.VUE_APP_VERSION
  projectVersion = process.env.VUE_APP_PROJECT_VERSION
  gitRevision = process.env.VUE_APP_GIT_REVISION
  frontendVersion = `${this.projectVersion} (${this.gitRevision})`

  model: FeedbackDTO = new FeedbackDTO()
  withPicture = false
  userFullName = ""

  currentTabIndex = 0

  categories: any[] = [
    { id: "BUG", name: this.$t("feedbackModal.categories.bug") },
    { id: "LOST", name: this.$t("feedbackModal.categories.lost") },
  ]

  openFeedback(): void {
    this.display = true
  }

  sendClicked(): void {
    this.addNavigationContext()
    this.addUserEnvInfo()
    // this.model.device = this.device;
    this.display = false

    if (this.withPicture) {
      const rootElement: HTMLElement = this.castRootElement(document.querySelector("body"))
      const modal: HTMLElement = this.castRootElement(document.querySelector(".modal"))
      modal.classList.add("hidden")
      html2canvas(rootElement, {
        x: window.pageXOffset,
        y: window.pageYOffset,
        width: window.innerWidth,
        height: window.innerHeight,
      }).then((canvas: any) => {
        const pngPicture = canvas.toDataURL("image/png")
        this.model.screenshot = pngPicture
        this.sendFeedback()
        modal.classList.remove("hidden")
      })
    } else {
      this.sendFeedback()
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  castRootElement(whatever: any): HTMLElement {
    return whatever
  }

  sendFeedback(): void {
    this.feedbackService.sendFeedback(this.model).then(() => {
      this.infoReportingService.success(this.$t("feedbackModal.feedback-sent").toString())
    })
  }

  closeFeedback(): void {
    this.display = false
  }

  reinitializeForm(): void {
    this.model = new FeedbackDTO()
    this.model.category = this.categories[0].name
    this.withPicture = false
  }

  loadProfile(): void {
    const logged = this.loginService.getLoggedUser()
    if (logged != undefined && logged.id != undefined) {
      this.model.userId = logged.id
      this.model.userFullName = logged.prenom + " " + logged.nom
      this.model.email = logged.courriel
    }
  }

  addNavigationContext(): void {
    this.model.date = new Date()
    this.model.location = window.location.href
    this.model.locationTitle = window.document.title
    this.model.frontendVersion = this.frontendVersion
  }

  addUserEnvInfo(): void {
    this.model.browser = this.getBrowserNameAndVersion()
    this.model.os = this.getOperatingSystemNameAndVersion()
    this.model.platform = navigator.platform
    this.model.screenResolution = screen.width + "x" + screen.height
    this.model.displaySize = window.innerWidth + "x" + window.innerHeight
    this.model.devicePixelRatio = "" + window.devicePixelRatio
    this.model.locale = navigator.language
  }

  getOperatingSystemNameAndVersion(): string {
    const userAgent = navigator.userAgent
    let os = userAgent
    if (userAgent.indexOf("Windows NT 10.0") != -1) os = "Windows 10"
    if (userAgent.indexOf("Windows NT 6.3") != -1) os = "Windows 8.1"
    if (userAgent.indexOf("Windows NT 6.2") != -1) os = "Windows 8"
    if (userAgent.indexOf("Windows NT 6.1") != -1) os = "Windows 7"
    if (userAgent.indexOf("Windows NT 6.0") != -1) os = "Windows Vista"
    if (userAgent.indexOf("Windows NT 5.1") != -1) os = "Windows XP"
    if (userAgent.indexOf("Windows NT 5.0") != -1) os = "Windows 2000"
    if (userAgent.indexOf("Mac") != -1) os = "Mac/iOS"
    if (userAgent.indexOf("X11") != -1) os = "UNIX"
    if (userAgent.indexOf("Linux") != -1) os = "Linux"
    if (userAgent.indexOf("Android") != -1) os = "Android"
    return os
  }

  getBrowserNameAndVersion(): string {
    var ua = navigator.userAgent,
      tem,
      M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*([\d]+)/i) || []
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+(\.\d+)?)/g.exec(ua) || []
      return "IE " + (tem[1] || "")
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"]
    if ((tem = ua.match(/version\/([\d]+)/i)) != null) M[2] = tem[1]
    return M.join(" ")
  }
}
