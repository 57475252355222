






















































































































































import {
  EtatProjet,
  ParametrageDTO,
  PeriodDTO,
  ProjetAnnuelDTO,
  ProjetPatchDTO,
  UtilisateurDTO,
} from "@/model/bean/GeneratedDTOs"
import { ProjectService } from "@/services/rest/project/ProjectService"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import SubHeaderItem from "@/views/commons/SubHeaderItem.vue"
import { ParametersService } from "@/services/rest/parameters/ParametersService"
import { UsersService } from "@/services/rest/users/UsersService"
import { format } from "date-fns"
import * as frLocale from "date-fns/locale/fr"
import ConfirmChangementEtat from "@/views/commons/ConfirmChangementEtat.vue"

export const PROJECT_SECTIONS = {
  EN_BREF: "bref",
  MY_YEAR: "mon-annee",
  ADMIN_INFOS: "infos_administratives",
  LABELISED: "labellise",
}

const DATE_FORMAT = "D MMMM YYYY"
const SHORT_DATE_FORMAT = "MMM YYYY"

@Component({
  components: {
    SubHeaderItem,
    ConfirmChangementEtat,
  },
})
export default class ProjetYearAndStatutSelection extends Vue {
  private parametersService = ParametersService.INSTANCE
  private projetService = ProjectService.INSTANCE
  private usersService = UsersService.INSTANCE

  @Prop() projetAnnuel: ProjetAnnuelDTO
  @Prop() loggedUser: UtilisateurDTO
  @Prop() etats: EtatProjet[]
  @Prop() readonly: boolean
  @Prop() isPorteurProjet: boolean

  selectedEtat: EtatProjet = "ACTIF"
  parametrage = new ParametrageDTO()
  periods: PeriodDTO[] = []

  showConfirmModal = false
  graeId = ""
  canChangeEtat = false

  isObservateurNational = false

  created(): void {
    this.loadParameters()
    this.loadPeriods(this.projetAnnuel.projetId)
    this.projetAnnualChanged()
    this.isObservateurNational = this.usersService.isObservateurNational(this.loggedUser)
  }

  async loadParameters(): Promise<void> {
    await this.parametersService.getParametrage(this.projetAnnuel.grae.id).then((params) => {
      this.parametrage = params
    })
  }

  async loadPeriods(projectId: string): Promise<void> {
    this.periods = await this.projetService.getPeriods(projectId)
    this.canChangeEtat =
      (!this.isPorteurProjet || this.projetAnnuel.etat != "ARCHIVE") &&
      this.periods[0] &&
      this.periods[0].projetAnnuelId === this.projetAnnuel.id
  }

  @Watch("projetAnnuel")
  projetAnnualChanged(): void {
    this.selectedEtat = this.projetAnnuel.etat
    this.graeId = this.projetAnnuel.etablissements[0]?.grae?.id
  }

  selectPeriod(index: number): void {
    // Reuse section and active tab if target dossier is of the same type
    const period = this.periods[index]
    let section = this.$router.currentRoute.params["section"]
    let activeTab = this.$router.currentRoute.params["activeTab"]
    const isPeriodInscription = period.typeDossier == "DOSSIER_INSCRIPTION"
    const isCurrentProjetAnnuelInscription = this.projetAnnuel.typeDossier == "DOSSIER_INSCRIPTION"
    if (isPeriodInscription != isCurrentProjetAnnuelInscription) {
      section = period.typeDossier == "DOSSIER_INSCRIPTION" ? "inscription" : "bref"
      activeTab = "0"
    }
    const path = [
      "/project",
      this.projetAnnuel.projetId,
      period.projetAnnuelId,
      section,
      activeTab,
    ].join("/")
    this.$router.push(path)
  }

  getPeriodLabel(debut: Date, fin: Date): string {
    return (
      this.formatDate(debut, SHORT_DATE_FORMAT) + " / " + this.formatDate(fin, SHORT_DATE_FORMAT)
    )
  }

  formatDate(date: Date, customFormat: string = DATE_FORMAT): string {
    return date ? format(date, customFormat, { locale: frLocale }) : ""
  }

  changeEtat(): void {
    this.showConfirmModal = true
    this.$emit("show-confirm-modal")
  }

  closeConfirmModal(): void {
    this.$emit("close-confirm-modal")
    this.showConfirmModal = false
    this.selectedEtat = this.projetAnnuel.etat
  }

  confirmChangementEtat(): void {
    let patch = { etat: this.selectedEtat } as ProjetPatchDTO
    this.projetService
      .patchProject(this.projetAnnuel.projetId, patch)
      .then(() => this.$emit("reload-project"))
  }
}
