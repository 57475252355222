var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "VerticalMenus" },
    [
      _c("MainVerticalMenu", {
        attrs: {
          items: _vm.mainNavbarItems,
          hasContextMenu: _vm.contextMenu.items.length > 0,
          menuExpanded: _vm.menuExpanded,
          pendingRequest: _vm.pendingRequest,
          role: _vm.role
        },
        on: {
          "main-menu-expand": function(open) {
            return this$1.$emit("main-menu-expand", open)
          },
          "click-on-item": function($event) {
            return _vm.refreshActiveLinkDisplay(_vm.$router.current)
          },
          "reset-notif-porteur": function($event) {
            return _vm.resetNotifPorteur()
          }
        }
      }),
      _c("SubVerticalMenu", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.contextMenu.items.length,
            expression: "contextMenu.items.length"
          }
        ],
        attrs: {
          items: _vm.contextMenu.items,
          activeAnchor: _vm.contextMenu.activeAnchor,
          previousScreenName: _vm.previousScreenName,
          pendingRequest: _vm.pendingRequest,
          pendingRequestMember: _vm.pendingRequestMember,
          role: _vm.role,
          idGrae: _vm.graeId,
          notifications: _vm.notifications,
          menuExpanded: _vm.menuExpanded
        },
        on: {
          "context-menu-expand": function(open) {
            return this$1.$emit("context-menu-expand", open)
          },
          "back-to-previous": function($event) {
            return _vm.backToPreviousRoute()
          },
          "reset-notif-grae": function(event) {
            return _vm.resestNotifGraeId(event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }