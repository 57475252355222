


























































































































import { FichierDTO, ProjetAnnuelDTO } from "@/model/bean/GeneratedDTOs"
import Constants from "@/services/Constants"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import { FileService } from "@/services/rest/utils/FileService"
import { SettingsService } from "@/services/rest/utils/SettingsService"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { DialogProgrammatic as Dialog } from "buefy"
import TitleView from "@/views/commons/TitleView.vue"
import FileWithValidation from "@/views/inputs/FileWithValidation.vue"

@Component({ components: { TitleView, FileWithValidation } })
export default class CarouselView extends Vue {
  @Prop() projetAnnuel: ProjetAnnuelDTO
  @Prop({ default: false }) readonly: boolean
  @Prop({ default: false }) isPorteurProjet: boolean
  @Prop({ default: false }) validationActivated: boolean
  @Prop({ default: true }) showValidationErrors: boolean

  private fileService = FileService.INSTANCE
  private settingsService = SettingsService.INSTANCE
  private infoReportingService = InfoReportingService.INSTANCE

  images: FichierDTO[] = []

  maxUploadable = 5
  maxUploadSizeString = ""

  autorisationDroitImageFileUrl = ""

  gallery = false
  isDeleting = false

  imagesUrl: string[] = []

  created(): void {
    this.initImages()
  }

  @Watch("projetAnnuel")
  projetAnnuelChanged(): void {
    this.initImages()
  }

  async initImages(): Promise<void> {
    if (!this.projetAnnuel.id) {
      return
    }

    this.images = await this.fileService.getFichiers(this.projetAnnuel.id, "IMAGE")
    this.autorisationDroitImageFileUrl = this.fileService.buildUniqueFileURL(
      this.projetAnnuel.id,
      "AUTORISATION_DROIT_IMAGE"
    )

    if (this.images && this.images.length !== 0) {
      this.imagesUrl = this.images.map((image) =>
        Constants.apiUrlv2(`/projets/${this.projetAnnuel.id}/fichiers/${image.id}/content`)
      )
    } else {
      this.imagesUrl = []
    }

    this.$emit("images-url-changed", this.imagesUrl)
  }

  addImage(image: File): void {
    console.log(image, this.projetAnnuel.id)
    if (this.projetAnnuel.id && image) {
      this.fileService
        .uploadFichier(this.projetAnnuel.id, "IMAGE", image)
        .then((_result) => {
          this.initImages()
          if (this.$refs.fileInput && (this.$refs.fileInput as Vue).$el) {
            ;((this.$refs.fileInput as Vue).$el.querySelector(
              'input[type="file"]'
            ) as HTMLInputElement).value = ""
          }
        })
        .catch(async (error) => {
          const settings = await this.settingsService.getSettings()
          let uploadSize = parseInt(settings.MAX_UPLOAD_SIZE.toLowerCase().replace("K", ""))
          if (uploadSize > 0) {
            this.maxUploadSizeString = "(max " + Math.floor(uploadSize / 1000) + "Mo)"
          }
          this.infoReportingService.error(
            this.$t("upload-image-failed", [this.maxUploadSizeString]).toString(),
            error
          )
        })
    }
  }

  confirmDelete(index: number): void {
    Dialog.confirm({
      message: this.$t("projet.en-bref.confirm-delete").toString(),
      confirmText: this.$t("confirmer").toString(),
      cancelText: this.$t("annuler").toString(),
      onConfirm: () => this.deleteImage(index),
    })
  }

  private async deleteImage(index: number): Promise<void> {
    if (this.projetAnnuel.id) {
      this.isDeleting = true
      try {
        await this.fileService.deleteFichier(this.projetAnnuel.id, this.images[index].id)

        this.initImages()
        this.isDeleting = false
      } catch (e) {
        this.isDeleting = false
      }
    }
  }

  switchGallery(value: boolean): void {
    this.gallery = value
    if (value) {
      return document.documentElement.classList.add("is-clipped")
    } else {
      return document.documentElement.classList.remove("is-clipped")
    }
  }

  convertToBase64(e: Event) {
    // @ts-ignore
    const origin = e.currentTarget as HTMLElement
    // @ts-ignore
    const target = document.getElementById(e.currentTarget.id.replace("orig", "clone"))
    // @ts-ignore
    const canvas = document.getElementById(e.currentTarget.id.replace("orig", "canvas"))
    // @ts-ignore
    if (origin && canvas) {
      const dataURL = this.getDataUrl(origin, canvas)
      // @ts-ignore
      target?.setAttribute("src", dataURL)
    }
  }

  getDataUrl(img: HTMLElement, canvas: HTMLElement): string {
    // Create canvas
    // @ts-ignore
    const ctx = canvas.getContext("2d")
    //@ts-ignore
    canvas.width = img.naturalWidth
    //@ts-ignore
    canvas.height = img.naturalHeight

    // Draw the image
    //@ts-ignore
    ctx.drawImage(img, 0, 0)
    // @ts-ignore convert as base 64
    return canvas.toDataURL("image/jpeg")
  }

  addFile(): void {
    this.projetAnnuel.autorisationDroitImageFilePresent = true
    this.$emit("trigger-validation")
  }

  deleteFile(): void {
    this.projetAnnuel.autorisationDroitImageFilePresent = false
    this.$emit("trigger-validation")
  }
}
