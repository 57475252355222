






































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import TabsDropdownSelector from "@/views/commons/TabsDropdownSelector.vue"
import {
  EvaluationDTO,
  ProjetAnnuelDTO,
  StatutDossier,
  TypeDossier,
} from "@/model/bean/GeneratedDTOs"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import SelectWithValidation from "@/views/inputs/SelectWithValidation.vue"
import ResponsiveEvaluationHelp from "./ResponsiveEvaluationHelp.vue"
import { ValidationObserver } from "vee-validate"
import { EmailService } from "@/services/rest/email/EmailService"
import { InfoReportingService } from "@/services/log/InfoReportingService"

@Component({
  components: {
    InputWithValidation,
    SelectWithValidation,
    ValidationObserver,
    TabsDropdownSelector,
    ResponsiveEvaluationHelp,
  },
})
export default class Evaluation extends Vue {
  @Prop() projetAnnuel: ProjetAnnuelDTO
  @Prop() evaluation: EvaluationDTO
  @Prop() instructionPannelWidth: number
  @Prop({ default: true }) instructionModal: boolean
  @Prop({ default: "" }) renewalSuffix: string
  @Prop({ default: "" }) renewalHelpSuffix: string
  @Prop({ default: false }) isSaving: boolean
  @Prop({ default: false }) readOnly: boolean
  @Prop({ default: false }) isEvaluationFinale: boolean

  emailService = EmailService.INSTANCE
  inforReportingService = InfoReportingService.INSTANCE

  internalProjetAnnuel = new ProjetAnnuelDTO()

  emailDisplayed = false

  emailText = ""

  activeTab = 0
  tabs: Array<string> = []

  instructionStatus: StatutDossier[] = [
    "ACCEPTE",
    "AVIS_PASSABLE",
    "LABELLISATION_EN_DEVENIR",
    "REFUSE",
    "AUTOMATISE",
  ]
  statusLabels = this.instructionStatus.map((status) => {
    return this.$t(`instruction-pannel.instruction.${status}`).toString()
  })

  notes = [""]

  created(): void {
    this.refreshInternalDossierFromDossier()
  }

  mounted(): void {
    if (this.isRenouvellementLight(this.projetAnnuel.typeDossier)) {
      this.notes = [" ", "A", "B", "C", "D"]
    } else {
      this.notes = ["A", "B", "C", "D"]
    }
  }

  @Watch("projetAnnuel")
  dossierInscriptionChanged(): void {
    this.refreshInternalDossierFromDossier()
  }

  async refreshInternalDossierFromDossier(): Promise<void> {
    this.internalProjetAnnuel = this.projetAnnuel
    // Cannot set "EN DEVENIR" status for renewals
    if (
      this.isRenouvellementLight(this.internalProjetAnnuel.typeDossier) ||
      this.internalProjetAnnuel.typeDossier === "DOSSIER_RENOUVELLEMENT"
    ) {
      this.instructionStatus = ["ACCEPTE", "AVIS_PASSABLE", "REFUSE"]
      this.statusLabels = this.instructionStatus.map((status) => {
        return this.$t(`instruction-pannel.instruction.${status}`).toString()
      })
    } else {
      this.instructionStatus = ["ACCEPTE", "AVIS_PASSABLE", "REFUSE", "LABELLISATION_EN_DEVENIR"]
      this.statusLabels = this.instructionStatus.map((status) => {
        return this.$t(`instruction-pannel.instruction.${status}`).toString()
      })
    }
    this.tabs = [
      this.$t("instruction-pannel.instruction.instructeurs.title").toString(),
      this.$t("instruction-pannel.instruction.deroule.title").toString(),
      this.$t("instruction-pannel.instruction.adhesion.title").toString(),
      this.$t("instruction-pannel.instruction.integration.title").toString(),
      this.$t("instruction-pannel.instruction.implication.title").toString(),
    ]
    if (!this.isEvaluationFinale && !this.readOnly) {
      this.tabs.push(this.$t("instruction-pannel.instruction.labellisation").toString())
    }
    this.tabs.push(this.$t("instruction-pannel.instruction.appreciation").toString())
  }

  isRenouvellementLight(typeDossier: TypeDossier): boolean {
    return typeDossier == "DOSSIER_RENOUVELLEMENT_LIGHT"
  }

  computeNote(): void {
    let noteDeroule = 0
    switch (this.evaluation.derouleNote) {
      case "A":
        noteDeroule = 10
        break
      case "B":
        noteDeroule = 7
        break
      case "C":
        noteDeroule = 5
        break
      case "D":
        noteDeroule = 0
        break
    }

    let noteImplication = 0
    switch (this.evaluation.implicationNote) {
      case "A":
        noteImplication = 10
        break
      case "B":
        noteImplication = 7
        break
      case "C":
        noteImplication = 5
        break
      case "D":
        noteImplication = 0
        break
    }

    let noteIntegration = 0
    switch (this.evaluation.integrationNote) {
      case "A":
        noteIntegration = 5
        break
      case "B":
        noteIntegration = 3
        break
      case "C":
        noteIntegration = 1
        break
      case "D":
        noteIntegration = 0
        break
    }

    let noteAdhesion = 0
    switch (this.evaluation.adhesionNote) {
      case "A":
        noteAdhesion = 3
        break
      case "B":
        noteAdhesion = 2
        break
      case "C":
        noteAdhesion = 1
        break
      case "D":
        noteAdhesion = 0
        break
    }

    let noteFinale = noteDeroule + noteImplication + noteIntegration + noteAdhesion

    this.evaluation.note = noteFinale

    if (noteFinale >= 15) {
      // A labellisation is "good" only if its finale grade is above 15 AND if the grade for "deroule" and "implacation" are at least "B"
      if (
        (this.evaluation.derouleNote == "A" || this.evaluation.derouleNote == "B") &&
        (this.evaluation.implicationNote == "A" || this.evaluation.implicationNote == "B")
      ) {
        this.evaluation.mentionAutomatique = "ACCEPTE"
      } else {
        this.evaluation.mentionAutomatique = "AVIS_PASSABLE"
      }
    } else if (noteFinale >= 10) {
      this.evaluation.mentionAutomatique = "AVIS_PASSABLE"
    } else {
      this.evaluation.mentionAutomatique = "REFUSE"
    }
  }

  changeTab(tabIndex: number): void {
    this.activeTab = tabIndex
  }

  cancel(): void {
    this.$emit("close-modale")
  }

  launchValidation(): void {
    //@ts-ignore
    // this.$refs.observer.validateWithInfo().then(({ _isValid, errors }) => {
    //   const err = Object.values(errors).flat()
    //   this.$emit(
    //     "on-validation",
    //     new DossierLabellisationWithErrors(this.internalProjetAnnuel, err && err.length !== 0)
    //   )
    // })
  }

  validationFieldEvaluation(evaluation: EvaluationDTO): boolean {
    if (
      evaluation.adhesionCommentaire &&
      evaluation.adhesionNote &&
      evaluation.derouleCommentaire &&
      evaluation.derouleNote &&
      evaluation.implicationCommentaire &&
      evaluation.implicationNote &&
      evaluation.integrationCommentaire &&
      evaluation.integrationNote &&
      evaluation.commentaireInstructeur &&
      evaluation.mention
    ) {
      return true
    }
    return false
  }

  async save(): Promise<void> {
    const isRequiredFieldWarning =
      this.projetAnnuel.typeDossier != "DOSSIER_RENOUVELLEMENT_LIGHT" &&
      !this.validationFieldEvaluation(this.evaluation)
    const reportingMessage =
      this.$t("instruction-pannel.instruction.evaluation-saved").toString() +
      (isRequiredFieldWarning ? " " + this.$t("profilePage.field-required-warn").toString() : "")

    try {
      this.$emit("save-evaluation", this.evaluation)

      if (isRequiredFieldWarning) {
        this.inforReportingService.warn(reportingMessage, new Error())
      } else {
        this.inforReportingService.success(reportingMessage)
      }
    } catch (e) {
      this.inforReportingService.error((e as Error).toString(), e as Error)
    }
  }

  async displayMail(): Promise<void> {
    if (this.evaluation.mention) {
      this.emailText = await this.emailService.getLabellisationEmail(
        this.evaluation.mention,
        this.projetAnnuel.id
      )
    } else {
      this.emailText = this.$t(
        "instruction-pannel.instruction.evaluation-mail-no-preview"
      ).toString()
    }
    this.emailDisplayed = true
  }

  undisplayMail(): void {
    this.emailText = ""
    this.emailDisplayed = false
  }

  getInstructeurs(): string[] {
    return this.projetAnnuel.lastEvaluationInstructeurNames.split(",")
  }

  //find evaluation for user
}
