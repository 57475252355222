var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "splitter", attrs: { id: "splitter" } }, [
    _c(
      "div",
      {
        class: {
          "panel-left": _vm.canSeeInstructionPannel,
          "panel-full": !_vm.canSeeInstructionPannel
        },
        attrs: { id: "panel-left" }
      },
      [
        _c(
          "PageView",
          {
            attrs: {
              mainTitle: _vm.mainTitle,
              subTitle: _vm.project ? _vm.project.nom : "",
              subTitleIsEditable: true
            }
          },
          [
            _c(
              "ContentView",
              { attrs: { id: "content" } },
              [
                _vm.isLoading
                  ? _c("LoadingSkeleton", {
                      attrs: { "loading-text": _vm.$t("projectPage.loading") }
                    })
                  : _c(
                      "ProjectValidationAndSaveHandler",
                      {
                        attrs: {
                          project: _vm.project,
                          validationActivated: _vm.validationActivated,
                          showValidationErrors: _vm.showValidationErrors,
                          validationRequestDate: _vm.lastValidationDate,
                          dirty: _vm.dirty,
                          readonly: _vm.readonly,
                          role: _vm.role,
                          isPorteurProjet: _vm.isPorteurProjet,
                          isSaving: _vm.isSaving,
                          pxFromBottom: _vm.pxFromBottom,
                          hidden: _vm.modalShown
                        },
                        on: {
                          "validation-changed":
                            _vm.projetAnnuelValidationChanged,
                          "save-project": _vm.saveProject,
                          "toggle-validation": _vm.togglevalidation,
                          "send-inscription": _vm.sendInscription,
                          "send-labellisation": _vm.sendLabellisation,
                          "export-pdf": function(withPhotos) {
                            this$1.pdfExportRequested = true
                            this$1.withPhotos = withPhotos
                          }
                        }
                      },
                      [
                        _vm.loggedUser && _vm.project && _vm.project.id
                          ? _c("ProjetYearAndStatutSelection", {
                              attrs: {
                                projetAnnuel: _vm.project,
                                readonly: _vm.readonly,
                                role: _vm.role,
                                loggedUser: _vm.loggedUser,
                                isPorteurProjet: _vm.isPorteurProjet,
                                etats: _vm.etats
                              },
                              on: {
                                "reload-project": _vm.reloadProjectFromServer,
                                "show-confirm-modal": function($event) {
                                  _vm.modalShown = true
                                },
                                "close-confirm-modal": function($event) {
                                  _vm.modalShown = false
                                }
                              }
                            })
                          : _vm._e(),
                        _c("b-loading", {
                          model: {
                            value: _vm.isSaving,
                            callback: function($$v) {
                              _vm.isSaving = $$v
                            },
                            expression: "isSaving"
                          }
                        }),
                        _c("ProjectPDFExport", {
                          attrs: {
                            project: _vm.project,
                            exportingPDF: _vm.exportingPDF,
                            "with-photos": _vm.withPhotos,
                            "map-data-url": _vm.mapDataUrl,
                            "images-url": _vm.imagesUrl
                          },
                          on: {
                            "export-done": function($event) {
                              _vm.exportingPDF = false
                              _vm.pdfExportRequested = false
                              _vm.withPhotos = false
                              _vm.mapDataUrl = ""
                            }
                          }
                        }),
                        _vm.project &&
                        _vm.project.id &&
                        _vm.project.typeDossier == "DOSSIER_INSCRIPTION"
                          ? _c("ProjetInscriptionHolder", {
                              attrs: {
                                currentScrollY: _vm.currentScrollY,
                                project: _vm.project,
                                section: _vm.section,
                                activeTab: _vm.activeTab,
                                validationActivated: _vm.validationActivated,
                                showValidationErrors: _vm.showValidationErrors,
                                lastProjectValidation:
                                  _vm.lastProjectValidation,
                                readonly:
                                  _vm.readonly ||
                                  (_vm.project.statut != "BROUILLON" &&
                                    _vm.isPorteurProjet),
                                isPorteurProjet: _vm.isPorteurProjet,
                                pxFromBottom: _vm.pxFromBottom,
                                pdfExportRequested: _vm.pdfExportRequested
                              },
                              on: {
                                "dirtify-project": _vm.dirtifyProject,
                                "reload-project": _vm.reloadProjectFromServer,
                                "trigger-validation": _vm.triggerValidation,
                                "toggle-validation": _vm.togglevalidation,
                                "pdf-export-ready": function($event) {
                                  _vm.exportingPDF = true
                                }
                              }
                            })
                          : _vm.project && _vm.project.id
                          ? _c("ProjetLabellisationHolder", {
                              attrs: {
                                currentScrollY: _vm.currentScrollY,
                                project: _vm.project,
                                section: _vm.section,
                                activeTab: _vm.activeTab,
                                validationActivated: _vm.validationActivated,
                                showValidationErrors: _vm.showValidationErrors,
                                lastProjectValidation:
                                  _vm.lastProjectValidation,
                                readonly: _vm.readonly,
                                role: _vm.role,
                                isPorteurProjet: _vm.isPorteurProjet,
                                pxFromBottom: _vm.pxFromBottom,
                                currentWidth: _vm.currentWidth,
                                pdfExportRequested: _vm.pdfExportRequested
                              },
                              on: {
                                "dirtify-project": _vm.dirtifyProject,
                                "reload-project": _vm.reloadProjectFromServer,
                                "trigger-validation": _vm.triggerValidation,
                                "toggle-validation": _vm.togglevalidation,
                                "images-url-changed": function(imagesUrl) {
                                  return (this$1.imagesUrl = imagesUrl)
                                },
                                "pdf-export-ready": function(dataUrl) {
                                  _vm.mapDataUrl = dataUrl
                                  _vm.exportingPDF = true
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _vm.canSeeInstructionPannel
      ? _c("div", { attrs: { id: "panel-separator" } }, [
          _c("div", { staticClass: "resize-symbol" })
        ])
      : _vm._e(),
    _vm.canSeeInstructionPannel
      ? _c(
          "div",
          { attrs: { id: "panel-right" } },
          [
            _vm.instructionPannelWidth > _vm.instructionPannelAutoCloseWidth
              ? _c(
                  "button",
                  {
                    staticClass: "panel-right-close",
                    on: {
                      click: function($event) {
                        return _vm.collapseResizablePannel(true, false, false)
                      }
                    }
                  },
                  [
                    _c("b-icon", { attrs: { icon: "times", size: "is-small" } })
                  ],
                  1
                )
              : _vm._e(),
            _c("InstructionPannel", {
              attrs: {
                project: _vm.project,
                loggedUser: _vm.loggedUser,
                minVisibleWidth: _vm.instructionPannelAutoCloseWidth,
                instructionPannelWidth: _vm.instructionPannelWidth
              },
              on: {
                "new-menu-selected": function($event) {
                  return _vm.collapseResizablePannel(false, false, false)
                }
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }