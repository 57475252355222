







































































































































import { Component, Prop, Vue } from "vue-property-decorator"
import TitleView from "@/views/commons/TitleView.vue"
import { PaginationOrder, ProfilUtilisateur, UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import { GraeService } from "@/services/rest/grae/GraeService"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import { UsersService } from "@/services/rest/users/UsersService"
import AbstractTableView from "@/views/tables/AbstractTableView.vue"
import TableFilterView from "@/views/tables/TableFilterView.vue"
import Column from "@/views/tables/Column"
import PageView from "@/views/commons/PageView.vue"
import ContentView from "@/views/commons/ContentView.vue"
import GraeMemberDemandeCard from "./GraeMemberDemandeCard.vue"
import BookmarkCard from "@/views/generic_components/BookmarkCard.vue"
import { EnumHelper, TAG_TYPES_FOR_BOOKMARK_CARD } from "@/model/bean/EnumHelper"
import { OrganismeService } from "@/services/rest/organisme/OrganismeService"

@Component({
  components: {
    PageView,
    ContentView,
    TitleView,
    TableFilterView,
    GraeMemberDemandeCard,
    BookmarkCard,
  },
})
export default class GraeMembers extends AbstractTableView {
  @Prop() graeId: string
  @Prop() loggedUser: UtilisateurDTO
  @Prop({ default: false }) readonly: boolean

  private graeService = GraeService.INSTANCE
  private userService = UsersService.INSTANCE
  private infoReportingService = InfoReportingService.INSTANCE
  private organismeService = OrganismeService.INSTANCE

  memberList: Array<UtilisateurDTO> = []
  demandesEnCours: Array<UtilisateurDTO> = []
  userBeingValidated: string | undefined = ""
  userBeingRefused: string | undefined = ""
  isLoadingDemandes = false
  canDeleteUser = false

  statut = {
    valide: this.$t("usersList.validate").toString(),
    refuse: this.$t("usersList.refuse").toString(),
    validated: this.$t("usersList.validated").toString(),
    refused: this.$t("usersList.refused").toString(),
  }
  statusColumnField = "statutActivation"

  tagTypes = TAG_TYPES_FOR_BOOKMARK_CARD
  hidden = false

  async created(): Promise<void> {
    await this.loadDemandes()
    this.canDeleteUser = this.loggedUserCanManageUsers()
  }

  async reloadData(): Promise<void> {
    this.isLoading = true
    try {
      const paginatedGraeMembersList = await this.graeService.getMembers(
        this.graeId,
        this.currentPagedSortedParameter,
        this.activeFilters
      )
      this.memberList = paginatedGraeMembersList.elements
      this.total = paginatedGraeMembersList.count
    } catch (e) {
      // Silent catch
    }
    this.isLoading = false
  }

  accept(user: UtilisateurDTO): void {
    this.infoReportingService.dialog(
      this.$t("usersList.confirmation").toString(),
      this.$t("usersList.are-you-sure-validate").toString(),
      this.$t("usersList.validate").toString(),
      () => this.validateUserRegistration(user),
      "is-info"
    )
  }

  validateUserRegistration(user: UtilisateurDTO): void {
    this.userBeingValidated = user.id
    this.userService.validateUserRegistration(user).then(
      (_success) => {
        this.loadDemandes()
        this.reloadData()
        this.userBeingValidated = ""
      },
      (reject) => {
        this.userBeingValidated = ""
        console.error("Could not validate user ", reject)
        this.infoReportingService.error(this.$t("userActions.cannot-validate").toString(), reject)
      }
    )
  }

  reject(user: UtilisateurDTO): void {
    this.infoReportingService.dialog(
      this.$t("usersList.confirmation").toString(),
      this.$t("usersList.are-you-sure-deny").toString(),
      this.$t("usersList.refuse").toString(),
      () => {
        this.rejectUserRegistration(user)
      },
      "is-danger"
    )
  }

  rejectUserRegistration(user: UtilisateurDTO): void {
    this.userBeingRefused = user.id
    this.userService.rejectUserRegistration(user, "").then(
      (_success) => {
        this.loadDemandes()
        this.reloadData()
        this.userBeingRefused = ""
      },
      (reject) => {
        this.userBeingRefused = ""
        this.infoReportingService.error(this.$t("userActions.cannot-reject").toString(), reject)
      }
    )
  }

  loggedUserCanManageUsers(): boolean {
    return (
      this.loggedUser.profil == "ACCOMPAGNATEUR_NATIONAL" ||
      this.loggedUser.profil == "ANIMATEUR_REGIONAL" ||
      this.loggedUser.profil == "SUPER_ADMINISTRATEUR"
    )
  }

  canPromote(user: UtilisateurDTO): boolean {
    return (
      this.loggedUserCanManageUsers() &&
      this.loggedUser !== undefined &&
      this.loggedUser.id !== user.id &&
      user.statutActivation == "ACTIF" &&
      user.profil == "ACCOMPAGNATEUR_REGIONAL"
    )
  }

  promote(user: UtilisateurDTO): void {
    this.userService.promoteUser(user).then(
      async (_success) => {
        this.hidden = true
        this.$nextTick(async () => {
          await this.reloadData()
          this.hidden = false
        })
      },
      (reject) => {
        this.infoReportingService.error(this.$t("userActions.cannot-promote").toString(), reject)
        this.updateSelectValue(user, "ACCOMPAGNATEUR_REGIONAL")
      }
    )
  }

  updateSelectValue(user: UtilisateurDTO, value: string): void {
    const elements = this.$refs[`select-${user.id}`] as Vue[]

    if (!elements || elements.length === 0) {
      return
    }

    const select = elements[0]
    //@ts-ignore
    select.computedValue = value
  }

  canDemote(user: UtilisateurDTO): boolean {
    return (
      this.loggedUserCanManageUsers() &&
      this.loggedUser !== undefined &&
      this.loggedUser.id !== user.id &&
      user.statutActivation == "ACTIF" &&
      user.profil == "ANIMATEUR_REGIONAL"
    )
  }

  demote(user: UtilisateurDTO): void {
    this.userService.demoteUser(user).then(
      (_success) => {
        this.reloadData()
        this.hidden = true
        this.$nextTick(async () => {
          await this.reloadData()
          this.hidden = false
        })
      },
      (reject) => {
        this.infoReportingService.error(this.$t("userActions.cannot-demote").toString(), reject)
        this.updateSelectValue(user, "ANIMATEUR_REGIONAL")
      }
    )
  }

  changeUserProfile(user: UtilisateurDTO, profil: ProfilUtilisateur): void {
    if (profil == "ACCOMPAGNATEUR_REGIONAL" && user.profil != profil) {
      if (this.loggedUser && this.loggedUser.id === user.id) {
        this.infoReportingService.dialog(
          this.$t("usersList.confirmation").toString(),
          this.$t("usersList.are-you-sure-demote").toString(),
          this.$t("usersList.demote").toString(),
          () => this.demote(user),

          "is-warning"
        )
        //If user has clicked on cancel button :
        this.updateSelectValue(user, "ANIMATEUR_REGIONAL")
      } else {
        this.demote(user)
      }
    }

    if (profil == "ANIMATEUR_REGIONAL" && user.profil != profil) {
      this.promote(user)
    }
  }

  canAccept(user: UtilisateurDTO): boolean {
    const hasExpectedProfil =
      [
        "SUPER_ADMINISTRATEUR",
        "ACCOMPAGNATEUR_NATIONAL",
        "ACCOMPAGNATEUR_REGIONAL",
        "ANIMATEUR_REGIONAL",
      ].indexOf(user.profil) >= 0
    return (
      this.loggedUserCanManageUsers() &&
      hasExpectedProfil &&
      user.statutActivation == "INSCRIPTION_A_VALIDER"
    )
  }

  changeStatut(statutValue: string, user: UtilisateurDTO): void {
    if (statutValue === this.statut.valide) {
      this.accept(user)
    } else {
      this.reject(user)
    }
  }

  deleteUser(event: Event, user: UtilisateurDTO): void {
    event.stopPropagation()
    let message = this.$t("usersList.are-you-sure-delete").toString()
    let type = "is-warning"
    if (user.hasAireEducative) {
      type = "is-danger"
      message = this.$t("usersList.delete-user-warning").toString()
    }
    this.infoReportingService.dialog(
      this.$t("usersList.confirmation").toString(),
      message,
      this.$t("usersList.delete").toString(),
      () => {
        this.deleteUserAccount(user)
      },
      type
    )
  }

  deleteUserAccount(user: UtilisateurDTO): void {
    this.userService.deleteUserAccount(user).then(
      (_success) => {
        this.infoReportingService.success(this.$t("usersList.user-deleted").toString())
        this.reloadData()
      },
      (reject) => {
        this.infoReportingService.error(this.$t("usersList.cannot-delete").toString(), reject)
      }
    )
  }

  canValidateEmail(user: UtilisateurDTO): boolean {
    return (
      this.loggedUserCanManageUsers() &&
      user.statutActivation == "EMAIL_A_VALIDER" &&
      user.nom != "N/A"
    )
  }

  validateEmail(event: Event, user: UtilisateurDTO): void {
    event.stopPropagation()
    let message = this.$t("usersList.are-you-sure-validate-email").toString()
    let type = "is-warning"
    this.infoReportingService.dialog(
      this.$t("usersList.confirmation").toString(),
      message,
      this.$t("usersList.validate-email").toString(),
      () => {
        this.userService.validateEmail(user).then(
          (_success) => {
            this.infoReportingService.success(this.$t("usersList.user-email-validated").toString())
            this.reloadData()
          },
          (reject) => {
            this.infoReportingService.error(
              this.$t("usersList.cannot-validate-email").toString(),
              reject
            )
          }
        )
      },
      type
    )
  }

  getInitialColumns(): Array<Column> {
    return [
      {
        field: "prenom",
        label: this.$t("graePage.membres.prenom").toString(),
        sortable: true,
        backEndSearchable: true,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "nom",
        label: this.$t("graePage.membres.nom").toString(),
        sortable: true,
        backEndSearchable: true,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "profil",
        label: this.$t("usersList.profil").toString(),
        tooltip: this.$t("usersList.profil-explanations").toString(),
        sortable: true,
        backEndSearchable: true,
        possibleSearchValues: EnumHelper.allProfilsUtilisateur,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "telephone",
        label: this.$t("graePage.membres.telephone").toString(),
        sortable: true,
        backEndSearchable: true,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "courriel",
        label: this.$t("graePage.membres.courriel").toString(),
        sortable: true,
        backEndSearchable: true,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "statutActivation",
        label: this.$t("usersList.statut").toString(),
        tooltip: this.$t("usersList.statut-explanations").toString(),
        sortable: true,
        backEndSearchable: true,
        possibleSearchValues: EnumHelper.allStatusActivation,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "action",
        label: this.$t("projetPage.actions").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
    ]
  }

  async loadDemandes(): Promise<void> {
    if (this.loggedUserCanManageUsers()) {
      this.isLoadingDemandes = true
      try {
        this.demandesEnCours = await this.graeService.getDemandesCreationCompte(this.graeId)
      } catch (e) {
        // Silent catch
      }
    }

    this.isLoadingDemandes = false
  }

  getDefaultSort(): Array<PaginationOrder> {
    const nameSort = new PaginationOrder()
    nameSort.clause = "nom"
    nameSort.desc = true
    const profilSort = new PaginationOrder()
    profilSort.clause = "profil"
    profilSort.desc = true
    return [profilSort, nameSort]
  }

  showUserDetailsPage(event: Event, id: number): void {
    // Do not foward click event to row (would trigger modal)
    event.stopPropagation()
    //For APP to open ProfileModale
    this.$root.$emit("open-profile-modale", true, id)
  }

  exportCSV(): void {
    this.organismeService.exportGraeMembersCSV(this.graeId, this.activeFilters)
  }

  exportXLS(): void {
    this.organismeService.exportGraeMembersXLS(this.graeId, this.activeFilters)
  }
}
