/*-
 * #%L
 * Aires Éducatives
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
import { AbstractTypedRestService } from "@/services/rest/AbstractTypedRestService"
import { ParametrageDTO } from "@/model/bean/GeneratedDTOs"
import ConversionUtils from "@/services/ConversionUtils"

export class ParametersService extends AbstractTypedRestService<ParametrageDTO> {
  static INSTANCE = new ParametersService()

  async getParametrage(graeId?: string): Promise<ParametrageDTO> {
    const params = new Map<string, string | Array<any>>()
    if (graeId) {
      params.set("graeId", graeId)
    }
    const paramDTO = await super.typedGetSingleV2<ParametrageDTO>("/parametrage", 2, params)
    return ConversionUtils.convertParametrageDatesToLocal(paramDTO)
  }

  async createParametrage(param: ParametrageDTO): Promise<void> {
    super.postV2("/parametrage", {
      ...param,
      debutInscription: param.debutInscription && new Date(param.debutInscription.toISOString()),
      finInscription: param.finInscription && new Date(param.finInscription.toISOString()),
      debutLabellisation:
        param.debutLabellisation && new Date(param.debutLabellisation.toISOString()),
      finLabellisation: param.finLabellisation && new Date(param.finLabellisation.toISOString()),
    })
  }
}
