var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isMapClusterReady
        ? _c("LoadingFullScreenSpinner", {
            attrs: { "loading-text": _vm.$t("carte.loading-projects") }
          })
        : _vm._e(),
      _vm.isMapClusterReady
        ? _c("MapDrawWithClusterView", {
            staticClass: "MapDrawView",
            attrs: {
              validationActivated: false,
              showValidationErrors: false,
              loggedUser: _vm.loggedUser,
              mapHasFocus: true,
              projets: _vm.projets,
              guest: _vm.guest,
              role: _vm.role
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }