

















import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import TitleView from "@/views/commons/TitleView.vue"
import { ProjetAnnuelDTO } from "@/model/bean/GeneratedDTOs"
import GroupCheckboxesWithValidation from "@/views/inputs/GroupCheckBoxesWithValidation.vue"
import { EnumHelper } from "@/model/bean/EnumHelper"

@Component({
  components: {
    TitleView,
    GroupCheckboxesWithValidation,
  },
})
export default class InstructionThematiques extends Vue {
  @Prop() projetAnnuel: ProjetAnnuelDTO
  @Prop({ default: false }) readOnly: boolean

  internalProjetAnnuel = new ProjetAnnuelDTO()
  allThematiques = EnumHelper.allThematiques
  checkedThematiquesLabels: string[] = []

  created(): void {
    this.internalProjetAnnuel = this.projetAnnuel
    this.refreshChecked()
  }

  @Watch("projetAnnuel")
  dossierChanged(): void {
    this.internalProjetAnnuel = this.projetAnnuel
    this.refreshChecked()
  }

  refreshChecked(): void {
    this.checkedThematiquesLabels = this.internalProjetAnnuel.thematique.map((thematique) => {
      return this.$i18n.t("instruction." + thematique).toString()
    })
    if (this.internalProjetAnnuel.thematiqueAutre) {
      this.checkedThematiquesLabels.push(this.$i18n.t("instruction.AUTRE").toString())
    }
  }

  allThematiquesLabels(): string[] {
    return this.allThematiques.map((thematique) => {
      return this.$i18n.t("instruction." + thematique).toString()
    })
  }

  changeThematiques(): void {
    this.internalProjetAnnuel.thematique = this.allThematiques.filter((thematique) => {
      return (
        this.checkedThematiquesLabels.indexOf(
          this.$i18n.t("instruction." + thematique).toString()
        ) > -1
      )
    })
    if (this.checkedThematiquesLabels.indexOf(this.$i18n.t("instruction.AUTRE").toString()) == -1) {
      this.changeThematiqueAutre("")
    }
  }

  changeThematiqueAutre(newValue: string): void {
    this.internalProjetAnnuel.thematiqueAutre = newValue
    const indexAutre = this.checkedThematiquesLabels.indexOf(
      this.$i18n.t("instruction.AUTRE").toString()
    )
    if (newValue && indexAutre === -1) {
      this.checkedThematiquesLabels.push(this.$i18n.t("instruction.AUTRE").toString())
    }
  }
}
