var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cards" },
    [
      !_vm.readonly && _vm.isPorteurProjet
        ? _c("ClasseCard", {
            attrs: { projetAnnuel: _vm.projetAnnuel, canDelete: false },
            on: { add: _vm.added }
          })
        : _vm._e(),
      _vm._l(_vm.projetAnnuel.classes.slice().reverse(), function(classe) {
        return _c(
          "div",
          { key: classe.id },
          [
            _c("ClasseCard", {
              attrs: {
                classe: classe,
                projetAnnuel: _vm.projetAnnuel,
                canDelete: !_vm.readonly && _vm.isPorteurProjet
              },
              on: { delete: _vm.deleted }
            })
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }