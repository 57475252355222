
































import { Component, Prop, Vue } from "vue-property-decorator"
import pdf from "vue-pdf"

@Component({
  components: { pdf },
})
export default class PDFLinkWithModal extends Vue {
  @Prop({ default: "" }) fileName: string
  @Prop({ default: "" }) linkText: string
  isPDFModalActive = false

  page = 1
  numPages = 3

  getPdfUrl(): string {
    var pdfs = require.context("@/assets/pdf", false, /\.pdf$/)
    return pdfs("./" + this.fileName + ".pdf")
  }
}
