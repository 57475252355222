var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PageView",
    { attrs: { isOnBigHeaderPage: true, noSubTitleExpected: "true" } },
    [
      _c("TitleView", [_vm._v(_vm._s(_vm.$t("forgotPassword.title")))]),
      _c(
        "ContentView",
        [
          _c("ValidationObserver", {
            ref: "observer",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var handleSubmit = ref.handleSubmit
                  return [
                    _vm.passwordSent
                      ? _c("b-message", { attrs: { type: "is-success" } }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("forgotPassword.passwordsent")) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    _vm.passwordSent
                      ? _c("b-message", { attrs: { type: "is-warning" } }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("forgotPassword.checkspams")) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    !_vm.passwordSent
                      ? _c("b-message", { attrs: { type: "is-info" } }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("forgotPassword.explain")) + " "
                          )
                        ])
                      : _vm._e(),
                    !_vm.passwordSent
                      ? _c("InputWithValidation", {
                          attrs: {
                            rules: "required|email",
                            type: "email",
                            customError: _vm.passworderror,
                            placeholder: _vm.$t("input-login-tooltip"),
                            label: _vm.$t("input-login")
                          },
                          model: {
                            value: _vm.email,
                            callback: function($$v) {
                              _vm.email = $$v
                            },
                            expression: "email"
                          }
                        })
                      : _vm._e(),
                    !_vm.passwordSent
                      ? _c(
                          "b-button",
                          {
                            attrs: { type: "is-primary" },
                            on: {
                              click: function($event) {
                                return handleSubmit(_vm.submit)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("forgotPassword.send")) + " "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }