

























import { Component, Prop, Vue } from "vue-property-decorator"

@Component({})
export default class ResponsiveEvaluationHelp extends Vue {
  @Prop() criteria: string
  @Prop() helpText: string

  opened = false
}
