var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("RadioWithInputAndValidation", {
        attrs: {
          label: _vm.$t("instruction.milieu-generique"),
          data: _vm.allMilieuxGenerique,
          optionLabels: _vm.allMilieuxGeneriqueLabels(),
          verticalDisplay: "true",
          vid: "milieuxGenerique",
          disabled: _vm.readOnly
        },
        model: {
          value: _vm.internalProjet.milieuGenerique,
          callback: function($$v) {
            _vm.$set(_vm.internalProjet, "milieuGenerique", $$v)
          },
          expression: "internalProjet.milieuGenerique"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }