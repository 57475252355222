var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ValidationProvider", {
        attrs: {
          vid: _vm.group + "password",
          name: _vm.$attrs.name || _vm.$attrs.label,
          rules: "validPassword"
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var errors = ref.errors
              var valid = ref.valid
              return [
                _c(
                  "b-field",
                  {
                    staticClass: "InputWithValidation-field",
                    attrs: {
                      type: {
                        "is-danger":
                          _vm.validationActivated &&
                          (errors[0] || _vm.customError.length > 0),
                        "is-success": valid || !_vm.validationActivated
                      },
                      message: errors + _vm.customError
                    }
                  },
                  [
                    _c(
                      "template",
                      { slot: "label" },
                      [
                        _c("FormInputLabel", {
                          attrs: {
                            label: _vm.$attrs.label,
                            isMandatory: true,
                            tooltip: _vm.tooltip
                          }
                        })
                      ],
                      1
                    ),
                    _c("b-input", {
                      attrs: {
                        placeholder: _vm.placeholder,
                        type: "password",
                        passwordReveal: ""
                      },
                      on: { input: _vm.newPassword },
                      model: {
                        value: _vm.innerValue,
                        callback: function($$v) {
                          _vm.innerValue = $$v
                        },
                        expression: "innerValue"
                      }
                    })
                  ],
                  2
                )
              ]
            }
          }
        ])
      }),
      _c("div", { staticClass: "PasswordCreationWithValidation-hints" }, [
        _c(
          "div",
          {
            class: {
              "PasswordCreationWithValidation-validationHint": true,
              "is-danger": _vm.validationActivated && !_vm.atLeastOneLowerCase,
              "is-success": !_vm.validationActivated || _vm.atLeastOneLowerCase
            }
          },
          [
            _c("b-icon", {
              staticClass: "mr-2",
              attrs: {
                size: "is-small",
                icon: _vm.icon(
                  !_vm.validationActivated || _vm.atLeastOneLowerCase
                )
              }
            }),
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("passwordCreation.at-least-one-lowercase-letter")
                ) +
                " "
            )
          ],
          1
        ),
        _c(
          "div",
          {
            class: {
              "PasswordCreationWithValidation-validationHint": true,
              "is-danger": _vm.validationActivated && !_vm.atLeastOneUpperCase,
              "is-success": !_vm.validationActivated || _vm.atLeastOneUpperCase
            }
          },
          [
            _c("b-icon", {
              staticClass: "mr-2",
              attrs: {
                size: "is-small",
                icon: _vm.icon(
                  !_vm.validationActivated || _vm.atLeastOneUpperCase
                )
              }
            }),
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("passwordCreation.at-least-one-upercase-letter")
                ) +
                " "
            )
          ],
          1
        ),
        _c(
          "div",
          {
            class: {
              "PasswordCreationWithValidation-validationHint": true,
              "is-danger": _vm.validationActivated && !_vm.atLeastOneDigit,
              "is-success": !_vm.validationActivated || _vm.atLeastOneDigit
            }
          },
          [
            _c("b-icon", {
              staticClass: "mr-2",
              attrs: {
                size: "is-small",
                icon: _vm.icon(!_vm.validationActivated || _vm.atLeastOneDigit)
              }
            }),
            _vm._v(
              " " + _vm._s(_vm.$t("passwordCreation.at-least-one-digit")) + " "
            )
          ],
          1
        ),
        _c(
          "div",
          {
            class: {
              "PasswordCreationWithValidation-validationHint": true,
              "is-danger": _vm.validationActivated && !_vm.correctLength,
              "is-success": !_vm.validationActivated || _vm.correctLength
            }
          },
          [
            _c("b-icon", {
              staticClass: "mr-2",
              attrs: {
                size: "is-small",
                icon: _vm.icon(!_vm.validationActivated || _vm.correctLength)
              }
            }),
            _vm._v(
              " " + _vm._s(_vm.$t("passwordCreation.at-least-8-chars")) + " "
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }