


















































import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import TitleView from "@/views/commons/TitleView.vue"
import PageView from "@/views/commons/PageView.vue"
import ContentView from "@/views/commons/ContentView.vue"
import { OrganismeService } from "@/services/rest/organisme/OrganismeService"
import { UsersService } from "@/services/rest/users/UsersService"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import { LoginService } from "@/services/rest/login/LoginService"
import { StructureDTO, UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import StructureUpdateView from "@/views/commons/StructureUpdateView.vue"
import StructureSelectionView from "@/views/login/registration/StructureSelectionView.vue"
import StructureReferentsView from "@/views/structure/StructureReferentsView.vue"
import StructureProjetsView from "@/views/structure/StructureProjetsView.vue"
import {
  BackToPreviousScreenItem,
  ContextMenu,
  NavbarItem,
  NavbarItemWithoutLink,
} from "@/views/menus/MenuItems"

@Component({
  components: {
    TitleView,
    PageView,
    ContentView,
    StructureUpdateView,
    StructureSelectionView,
    StructureReferentsView,
    StructureProjetsView,
  },
})
export default class StructurePage extends Vue {
  @Prop() structureId?: string
  @Prop({ default: "details" }) section: string

  private organismeService = OrganismeService.INSTANCE
  private usersService = UsersService.INSTANCE
  private infoReportingService = InfoReportingService.INSTANCE
  private loginService = LoginService.INSTANCE

  user = new UtilisateurDTO()
  structure = new StructureDTO()
  readonly = true
  isFromStructure = false
  isAccompagnateurOrAdmin = false
  isObservateurNational = false
  isChanging = false
  currentSectionTitle = "structurePage.details"

  @Watch("section")
  sectionChanged(): void {
    if (this.isChanging || !(this.isAccompagnateurOrAdmin || this.isFromStructure)) {
      this.currentSectionTitle = "structurePage.details"
    } else {
      if (this.section === "referents") {
        this.currentSectionTitle = "structurePage.referents.title"
      } else if (this.section === "projets") {
        this.currentSectionTitle = "structurePage.liste-projets"
      } else {
        this.currentSectionTitle = "structurePage.details"
      }
    }
  }
  created(): void {
    const loggedUser = this.loginService.getLoggedUser()
    if (!loggedUser || !loggedUser.id) {
      return
    }

    this.isAccompagnateurOrAdmin = this.usersService.isAccompagnateurOrAdmin(loggedUser)
    this.isObservateurNational = this.usersService.isObservateurNational(loggedUser)
    this.readonly =
      loggedUser.profil !== "SUPER_ADMINISTRATEUR" &&
      loggedUser.profil !== "ACCOMPAGNATEUR_NATIONAL" &&
      loggedUser.profil !== "ANIMATEUR_REGIONAL"

    if (!this.structureId) {
      this.getStructureFromLoggedUser(loggedUser.id)
    } else {
      // Get structure from server
      this.getStructureFromServer(loggedUser, this.structureId)
    }
  }

  async getStructureFromLoggedUser(id: string): Promise<void> {
    this.user = await this.usersService.getUserProfile(id)
    if (this.user && this.user.structure) {
      this.structure = this.user.structure as StructureDTO
      this.isFromStructure = true
      this.readonly = false
      this.sectionChanged()
      this.refreshContextMenu(false)
    }
  }

  async getStructureFromServer(loggedUser: UtilisateurDTO, structureId: string): Promise<void> {
    this.user = loggedUser
    this.structure = await this.organismeService.getStructure(structureId)
    this.isFromStructure = this.user.structure?.id == this.structure?.id
    this.readonly =
      !this.isFromStructure &&
      loggedUser.profil !== "SUPER_ADMINISTRATEUR" &&
      loggedUser.profil !== "ACCOMPAGNATEUR_NATIONAL" &&
      loggedUser.profil !== "ANIMATEUR_REGIONAL"
    this.sectionChanged()
    this.refreshContextMenu(!this.isFromStructure)
  }

  doChangeStructure(structure: StructureDTO): void {
    this.user.structure = structure
    this.structure = structure

    this.usersService.updateProfile(this.user).then(
      (utilisateur: UtilisateurDTO) => {
        this.user = utilisateur
        this.infoReportingService.success(
          this.$i18n.t("profilePage.profile-modification-ok").toString()
        )
        this.isChanging = false
      },
      (_reject) => {
        this.infoReportingService.error(
          this.$i18n.t("profilePage.modification-error").toString(),
          _reject
        )
        this.isChanging = false
      }
    )
  }

  updateStructure(): void {
    //@ts-ignore
    if (this.$refs.structureView.getNumberOfWarningsOnAllTabs() !== 0) {
      this.infoReportingService.error(this.$t("profilePage.field-errors").toString(), new Error())
      return
    }

    this.infoReportingService.dialog(
      this.$t("profilePage.confirmation").toString(),
      this.$t("profilePage.structure.are-you-sure").toString(),
      this.$t("profilePage.structure.save").toString(),
      () => this.validateStructureModifications(this.structure as StructureDTO),
      "is-warning"
    )
  }

  validateStructureModifications(structure: StructureDTO): void {
    this.organismeService.updateStructure(structure).then(
      (_success) => {
        this.infoReportingService.success(
          this.$i18n.t("profilePage.structure.modification-ok").toString()
        )
      },
      (_reject) => {
        this.infoReportingService.error(
          this.$i18n.t("profilePage.structure.error").toString(),
          _reject
        )
      }
    )
  }

  refreshContextMenu(showBackToListMenuEntry: boolean): void {
    const menuItems = []
    if (this.structure) {
      const structureId = this.structure.id
      // Back to previous screen (if comming from list)
      if (showBackToListMenuEntry) {
        menuItems.push(new BackToPreviousScreenItem())
      }

      // Current Etablissement
      menuItems.push(new NavbarItemWithoutLink(this.structure.nom, ""))

      // Sub-sections
      if (this.isAccompagnateurOrAdmin || this.isFromStructure || this.isObservateurNational) {
        menuItems.push(
          new NavbarItem(
            "/structure/" + structureId + "/projets",
            this.$t("structurePage.liste-projets").toString(),
            "clipboard-list",
            false
          )
        )
        menuItems.push(
          new NavbarItem(
            "/structure/" + structureId + "/referents",
            this.$t("structurePage.referents.title").toString(),
            "users",
            false
          )
        )
      }
      menuItems.push(
        new NavbarItem(
          "/structure/" + structureId + "/details",
          this.$t("structurePage.details").toString(),
          "cog",
          false
        )
      )
      const possibleMenuParents = ["/structure/details"]
      this.$root.$emit("contextual-menu-changed", new ContextMenu(possibleMenuParents, menuItems))
    }
  }
}
