/*-
 * #%L
 * Sagae
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
import {
  CommentaireCreateDTO,
  CommentaireDTO,
  EchangeDTO,
  PaginationParameter,
  PaginationResult,
} from "@/model/bean/GeneratedDTOs"
import { AbstractTypedRestService } from "@/services/rest/AbstractTypedRestService"

export class CommentaireService extends AbstractTypedRestService<CommentaireDTO> {
  static INSTANCE = new CommentaireService()

  async createComment(
    projetId: string,
    commentaire: CommentaireCreateDTO
  ): Promise<CommentaireDTO> {
    return super.typedPostWithDifferentResultTypeV2<CommentaireCreateDTO, CommentaireDTO>(
      "projets/" + projetId + "/commentaires",
      2,
      commentaire
    )
  }

  async removeComment(projetId: string, commentaireId: string): Promise<string[]> {
    return super.typedDeleteV2("projets/" + projetId + "/commentaires/" + commentaireId, 2)
  }

  async getEchanges(
    projetId: string,
    pagination: PaginationParameter
  ): Promise<PaginationResult<EchangeDTO>> {
    return super.typeGetPaginatedV2<EchangeDTO>(
      "projets/" + projetId + "/echanges",
      2,
      pagination,
      []
    )
  }

  async getCommentaires(projetId: string): Promise<CommentaireDTO[]> {
    return super.typedGetV2<CommentaireDTO>("projets/" + projetId + "/commentaires", 2)
  }
}
