/* tslint:disable */
/* eslint-disable */

export class ActualiteCreateUdpdateDTO {
    contenu: string;
    dateDebutPublication: Date;
    dateFinPublication: Date;
    titre: string;
}

export class ActualiteDTO {
    auteur: UtilisateurDTO;
    contenu: string;
    dateCreation: Date;
    dateDebutPublication: Date;
    dateFinPublication: Date;
    dateModification?: Date;
    grae?: string;
    id: string;
    titre: string;
}

export class AireEducativeCartoDTO {
    latitude: number;
    longitude: number;
    natureAireEducative: NatureAireEducative;
    polygone: CoordinateDTO[];
    projetAnnuelId: string;
    projetAnnuelIdPrecedent: string;
    statutDossier: StatutDossier;
    statutDossierPrecedent: StatutDossier;
    titre: string;
    typeDossier: TypeDossier;
    typeDossierPrecedent: TypeDossier;
}

export class AireEducativeCartoDetailsDTO extends AireEducativeCartoDTO {
    anneeCreation: string;
    classes: string[];
    consentementValide: string[];
    enseignants: UtilisateurDTO[];
    graeID: string;
    idProjet: string;
    nomEtablissement: string[];
    nomStructure: string[];
    pitch: string;
    referents: UtilisateurDTO[];
    ville: string;
}

export class ApplicationDateDTO {
    applicationDate: Date;
}

export class AuthenticationDTO {
    courriel: string;
    motDePasse: string;
}

export class CalendrierEvenementCreateDTO {
    contenu: string;
    dateEvenement: Date;
}

export class CalendrierEvenementDTO {
    contenu: string;
    dateCreation: Date;
    dateEvenement: Date;
    dateModification?: Date;
    id: string;
    projetAnnuel: string;
}

export class ChangePasswordDTO {
    confirmation: string;
    courriel: string;
    motDePasse: string;
    nouveauMotDePasse: string;
}

export class ClasseDTO {
    autre1: string;
    autre2: string;
    dateCreation: Date;
    eleves_1ere: number;
    eleves_2nde: number;
    eleves_3eme: number;
    eleves_4eme: number;
    eleves_5eme: number;
    eleves_6eme: number;
    eleves_autre1: number;
    eleves_autre2: number;
    eleves_ce1: number;
    eleves_ce2: number;
    eleves_cm1: number;
    eleves_cm2: number;
    eleves_cp: number;
    eleves_terminale: number;
    id: string;
    niveaux: string[];
}

export class CommentaireCreateDTO {
    contenu: string;
}

export class CommentaireDTO {
    auteur: UtilisateurDTO;
    contenu: string;
    dateCreation: Date;
    id: string;
    projet: string;
}

export class ConseilDTO {
    dateConseil: Date;
    dateCreation: Date;
    fichier: string;
    id: string;
    projetAnnuel: string;
    type: TypeConseil;
}

export class CoordinateDTO {
    latitude: number;
    longitude: number;
}

export class CoordonneesRegions {
}

export class CourrielContactDTO {
    auteur: UtilisateurDTO;
    contenu: string;
    date: Date;
    titre: string;
}

export class CourrielContactCreateDTO extends CourrielContactDTO {
    destinataires: string[];
    filters: FilterDTO[];
    mailDestinataires: string[];
    projetId: string;
}

export class DocumentsDTO {
    anneescolaire: string;
    documentfile: any;
    documentname: string;
    file: string;
    id: string;
    type: string;
}

export class EchangeDTO {
    auteur: UtilisateurDTO;
    contenu: string;
    dateCreation: Date;
    id: string;
    projet: string;
    titre: string;
    type: string;
}

export class EmptyUtilisateurDTO {
    acceptNameVisibilityOnProtectedListing: boolean;
    courriel: string;
    fonction: string;
    grae: Grae;
    matiere: Matiere;
    matiereAutre: string;
    nom: string;
    prenom: string;
    profil: ProfilUtilisateur;
    telephone: string;
}

export class OrganismeDTO {
    codePostal: string;
    complement?: string;
    grae: Grae;
    id?: string;
    nom: string;
    pays: string;
    rue: string;
    ville: string;
}

export class EtablissementDTO extends OrganismeDTO {
    academie: string;
    codeDepartement: string;
    codeEtablissement: string;
    courriel?: string;
    departement: string;
    latitude: string;
    longitude: string;
    nature: string;
    nbProjets: number;
    nomChefEtablissement?: string;
    postBac: boolean;
    prenomChefEtablissement?: string;
    sirenSiret: string;
    siteWeb?: string;
    statut: string;
    telephone?: string;
    typeContrat: string;
}

export class EvaluationDTO {
    adhesionCommentaire: string;
    adhesionNote: string;
    commentaireInstructeur: string;
    commentaireStatut: string;
    dateCreation: Date;
    dateModification: Date;
    derouleCommentaire: string;
    derouleNote: string;
    elementsPositifs: string;
    elementsRemarquables: string;
    id: string;
    implicationCommentaire: string;
    implicationNote: string;
    instructeur: UtilisateurDTO;
    integrationCommentaire: string;
    integrationNote: string;
    mention: StatutDossier;
    mentionAutomatique: StatutDossier;
    note: number;
    pistesAmelioration: string;
    retoursTerrain: string;
}

export class FeedbackDTO {
    backendVersion: string;
    browser: string;
    category: string;
    date: Date;
    description: string;
    devicePixelRatio: string;
    displaySize: string;
    email: string;
    frontendVersion: string;
    id: string;
    locale: string;
    location: string;
    locationTitle: string;
    os: string;
    phone: string;
    platform: string;
    screenResolution: string;
    screenshot: string;
    subject: string;
    userFullName: string;
    userId: string;
}

export class FichierDTO {
    auteur: string;
    description: string;
    id: string;
    nom: string;
    titre: string;
    typeFichier: TypeFichier;
}

export class FilterDTO {
    property: string;
    values: string[];
}

export class FinancementDTO {
    dateCreation: Date;
    dateModification: Date;
    debut: Date;
    fin: Date;
    id: string;
    montant: number;
    origine: OrigineFinancement;
    origineAutreTexte: string;
}

export class ForgotPasswordDTO {
    courriel: string;
}

export class Grae implements Serializable {
    anneeCreation: number;
    anneeScolaireIndicateurs: string;
    anneeScolaireInscription: string;
    commentaire: string;
    contact: string;
    dateModification: Date;
    id: string;
    labellisationsClotureesAcceptees: number;
    note: string;
    parametrage: string;
    projetsRefuses: number;
    region: string;
    renouvellementsCloturesAcceptes: number;
    siteWeb: string;
}

export class GraeDTO {
    anneeCreation: number;
    commentaire: string;
    contactId: string;
    dateModification?: Date;
    id: string;
    note: string;
    parametrage?: ParametrageDTO;
    region: string;
    siteWeb: string;
}

export class GraeStatsDTO {
    anneeScolaire: string;
    anneeScolaireInscription: string;
    arrete: number;
    graeId: string;
    inscriptions: number;
    inscriptionsBrouillon: number;
    inscriptionsNonInstruites: number;
    labellisationsAccepteesCloturees: number;
    labellisationsAttenteInstructeur: number;
    labellisationsInstructionTerminee: number;
    nbAME: number;
    nbATE: number;
    nbCompteAvalider: number;
    pause: number;
    projetsRefuses: number;
    renouvellementsAcceptesClotures: number;
}

export class MessageDTO {
    corps: string;
    sujet: string;
}

export class ModificationPPDTO {
    ajoutEnseignants: UtilisateurModifieDTO[];
    ajoutReferent: UtilisateurModifieDTO[];
    nomEtablissement: string[];
    nomProjet: string;
    projet: string;
    projetAnnuel: string;
    retraitEnseigant: UtilisateurModifieDTO[];
    retraitReferent: UtilisateurModifieDTO[];
    uai: string[];
}

export class ModificationsPPDD {
    etablissements: string[];
    grae: string;
    idProjet: string;
    idProjetAnnuel: string;
    nom: string;
}

export class ModificationsPPResponseDTO {
    modificationsPPDTO: ModificationPPDTO[];
}

export class NotificationPPResponseDTO {
    notifications: { [index: string]: number };
    total: number;
}

export class PaginationOrder implements Serializable {
    clause: string;
    desc: boolean;
}

export class PaginationParameter implements Serializable {
    all: boolean;
    endIndex: number;
    orderClauses: PaginationOrder[];
    pageNumber: number;
    pageSize: number;
    startIndex: number;
}

export class PaginationResult<O> implements Serializable {
    count: number;
    currentPage: PaginationParameter;
    elements: O[];
    firstPage: PaginationParameter;
    lastPage: PaginationParameter;
    nextPage: PaginationParameter;
    pageCount: number;
    previousPage: PaginationParameter;
}

export class ParametrageDTO {
    debutInscription: Date;
    debutLabellisation: Date;
    finInscription: Date;
    finLabellisation: Date;
    id?: string;
    jourDebutAnneeScolaire: number;
    moisDebutAnneeScolaire: MoisAnnee;
}

export class PasswordCheckDTO {
    id?: string;
    motDePasse: string;
}

export class PasswordResetDTO extends PasswordCheckDTO {
    confirmationMotDePasse: string;
}

export class PeriodDTO {
    anneeScolaireDebut: Date;
    anneeScolaireFin: Date;
    projetAnnuelId: string;
    typeDossier: TypeDossier;
}

export class PorteursProjetEmailListDTO {
    destinataireEnseignantEmails: string[];
    destinataireReferentEmails: string[];
}

export class ProjetDTO {
    consentementEnAttente: string[];
    consentementValide: string[];
    dateCreation: Date;
    dateModification?: Date;
    etat: EtatProjet;
    financementCommentaireDemarche: string;
    financements: FinancementDTO[];
    grae: Grae;
    id?: string;
    lastEvaluationAnneeScolaireDebut: Date;
    lastEvaluationAnneeScolaireFin: Date;
    lastEvaluationInstructeurNames: string;
    lastEvaluationMention: StatutDossier;
    lastEvaluationTypeDossier: TypeDossier;
    nom: string;
}

export class ProjetAnnuelDTO extends ProjetDTO {
    acteursEducNationaleText: string;
    actionsRealisees: string;
    activitesTransDisrFilePresent: boolean;
    adhesionDiffusionFilePresent: boolean;
    aireSiteChangee: boolean;
    aireSiteChangeeImplicationEleves: string;
    aireSiteChangeeRaisons: string;
    annee: number;
    anneeScolaire: string;
    anneeScolaireDebut: Date;
    anneeScolaireFin: Date;
    autorisationDroitImageFilePresent: boolean;
    autorisationProprietaire: AutorisationProprietaire;
    autresActeursText: string;
    autresInfos: string;
    autresInfosFilePresent: boolean;
    avisFavorableConseilMunicipalSite: boolean;
    calendrierActivitesAttente: string;
    charteFilePresent: boolean;
    charteFileValid: boolean;
    choixAiresEducatives: string;
    classes: ClasseDTO[];
    classesCommentaire: string;
    cmtAvezConnuLeDispositif: string;
    cmtFonctionnementBinome: string;
    cmtProjetEtablissement: string;
    commentaireAdhesionTerritoire: string;
    commentaireSupplementaire: string;
    commentairesDeroulementProjet: string;
    communicationAutresClasses: string;
    competencesDeveloppees: string;
    competencesScolairesAbordees: string;
    conseils: ConseilDTO[];
    conseilsLiens: string;
    constitutionConseilTerreMer: ConstitutionConseilEleves;
    constitutionConseilTerreMerAutre: string;
    contexteInscritAutre: string;
    contexteInscritE3d: boolean;
    contexteInscritEcoecole: boolean;
    contexteProjetEcole: Trilean;
    criteresDeterminantsPourChoix: string;
    descriptionParticipationAutresEtapes: string;
    descriptionPassageFlambeau: string;
    difficultesTroisAns: string;
    diffusionLiensEnseignements: string;
    elementsFacilitateurs: string;
    enseignants: UtilisateurDTO[];
    entraves: TypeEntrave[];
    entravesAutres: string;
    etablissements: EtablissementDTO[];
    etatDesLieuxContexteAdministratif: string;
    etatDesLieuxContexteEcoGeo: string;
    etatDesLieuxContextePatrimonial: string;
    etatDesLieuxContextePhysique: string;
    etatDesLieuxContexteSocio: string;
    etatDesLieuxLiensEnseignements: string;
    etatdesLieuxFilePresent: boolean;
    etatdesLieuxLiensFilePresent: boolean;
    evaluationFinale: EvaluationDTO;
    evaluationImpactEnvironnementEleves: string;
    explicationAdhesionInterneClasses: string;
    explicationFinProjet: string;
    fichiers: string[];
    financementAnneeSuivante: boolean;
    impactSurTerritoire: string;
    intensiteCoconstructionEnseignentReferent: number;
    maintientProjetAnneeSuivante: boolean;
    milieuGenerique: MilieuGenerique;
    milieuInteretParticulier: MilieuInteretParticulier[];
    milieuInteretParticulierAutre: string;
    miseEnOeuvreAction: string;
    natureAireEducative: NatureAireEducative;
    natureSite: NatureSite;
    nbConseilsElargis: number;
    nbConseilsTerreMer: number;
    nbSeanceClasseEtatDesLieux: number;
    nbSeancesClasseDediees: number;
    nbSortiesEtatDesLieux: number;
    nbSortiesSite: number;
    nbTotalPresenceReferent: number;
    nouveauxActeurs: UtilisateurDTO[];
    nouvellesThematiques: string;
    objectConcretRealisationAutre: string;
    objectifAutre: string;
    objectifs: TypeRenouvellementObjectif[];
    objectsConcretRealisation: ObjectConcretRealisation[];
    organisationClasses: string;
    organisationEchangesInterAe: string;
    organisationEchangesInterAePresent: boolean;
    participationAutresEtapes: string;
    pitch: string;
    presentationElvesProjetsElusInstituions: string;
    presentationProjetElus: string;
    prevuFinAnnee: string;
    problematiquesFilePresent: boolean;
    problematiquesIdentifiees: string;
    problematiquesLiens: string;
    procedesDeterminationActions: string;
    procedesDeterminationProblematiques: string;
    procedesEtatDesLieux: string;
    productionsEleves: string;
    projetAbordeDebutAnnee: string;
    projetFacilitateurAcquisFondamentaux: string;
    projetId: string;
    projetPedagogique: string;
    projetValoriseSurTerritoire: string;
    qualiteLienCommune: Qualite;
    raisonArretAutre: string;
    raisonsArret: RaisonArret[];
    recapitulatifAnneePrecedente: string;
    referents: UtilisateurDTO[];
    rencontresActeursTerritoire: string;
    souhaitsAxeEtudes: string;
    statut: StatutDossier;
    structures: StructureDTO[];
    tempsPasseProjet: TempsPasse;
    thematique: Thematique[];
    thematiqueAutre: string;
    typeDossier: TypeDossier;
    zoneGeographique?: string;
    zoneGeographiqueChanged: boolean;
}

export class ProjetAnnuelForInstructionDTO extends ProjetAnnuelDTO {
    evaluationFinaleValidated: boolean;
    evaluations: EvaluationDTO[];
}

export class ProjetInscriptionDTO extends ProjetAnnuelForInstructionDTO {
    autresInstructeursInscription: UtilisateurDTO[];
    commentaireFinal: string;
    contexteLienMairie: boolean;
    contexteReunionReferent: boolean;
    contexteSitesPotentiels: string;
    projetAnnuel: string;
    recevoirKitAireEducative: boolean;
}

export class ProjetAnnuelForExportDTO extends ProjetInscriptionDTO {
    classesJson: string;
    enseignantsJson: string;
    etablissementsJson: string;
    evaluationsJson: string;
    financementsJson: string;
    nouveauxActeursJson: string;
    referentsJson: string;
    structuresJson: string;
}

export class ProjetPatchDTO {
    etat: EtatProjet;
    nom: string;
}

export interface Serializable {
}

export class StructureDTO extends OrganismeDTO {
    agree: boolean;
    agrementFile?: string;
    agrementFilePresent: boolean;
    autreTeteStructure: string;
    autreType?: string;
    description?: string;
    nbProjets: number;
    tetesStructure: TeteStructure[];
    type: TypeStructure;
}

export class UtilisateurDTO {
    acceptNameVisibilityOnProtectedListing: boolean;
    competencesReferentAe: boolean;
    competencesReferentEcologie: boolean;
    competencesReferentNovice: boolean;
    competencesReferentPedagogie: boolean;
    coordinates: CoordinateDTO;
    courriel: string;
    employeur?: string;
    etablissement?: EtablissementDTO;
    fonction?: string;
    grae?: GraeDTO;
    hasAireEducative: boolean;
    id?: string;
    instructedGraes: string[];
    lastConnection?: Date;
    matiereEnseignee: Matiere;
    matiereEnseigneeAutre: string;
    nom: string;
    prenom: string;
    profil: ProfilUtilisateur;
    statutActivation: StatutActivationUtilisateur;
    structure?: StructureDTO;
    telephone: string;
}

export class UtilisateurModifieDTO {
    lastDateModification: Date;
    nom: string;
    prenom: string;
}

export class UtilisateurRegistrationDTO extends UtilisateurDTO {
    confirmationMotDePasse: string;
    motDePasse: string;
}

export class ValidateEmailDTO {
    token: string;
}

export type AutorisationProprietaire = "OUI" | "NON" | "NONNECESSAIRE";

export type ConstitutionConseilEleves = "CLASSES_ENTIERES" | "DELEGUES" | "CLASSES_ENTIERES_PUIS_DELEGUES" | "GROUPE_ELEVES" | "GROUPE_ELEVES_ET_DELEGUES" | "AUTRE";

export type EtatProjet = "ACTIF" | "EN_PAUSE" | "ARCHIVE" | "EN_COURS_DE_CREATION";

export type Matiere = "ARTS_APPLIQUES" | "ARTS_PLASTIQUES" | "BIOTECHNOLOGIES" | "DESIGN_ET_METIERS_ARTS" | "DOCUMENTATION" | "ECONOMIE_GESTION" | "EDUCATION_MUSICALE" | "EDUCATION_PHYSIQUE_SPORTIVE" | "ESTHETIQUE_COSMETIQUE" | "HISTOIRE_GEOGRAPHIE" | "HOTELLERIE_RESTAURATION" | "INDUSTRIES_GRAPHIQUES" | "LANGUE_DES_SIGNES" | "LANGUE_VIVANTE_ALLEMAND" | "LANGUE_VIVANTE_ANGLAIS" | "LANGUE_VIVANTE_ARABE" | "LANGUE_VIVANTE_CHINOIS" | "LANGUE_VIVANTE_ESPAGNOL" | "LANGUE_VIVANTE_HEBREU" | "LANGUE_VIVANTE_ITALIEN" | "LANGUE_VIVANTE_JAPONAIS" | "LANGUE_VIVANTE_NEERLANDAIS" | "LANGUE_VIVANTE_PORTUGAIS" | "LANGUE_VIVANTE_RUSSE" | "LANGUE_REGIONALE_BASQUE" | "LANGUE_REGIONALE_BRETON" | "LANGUE_REGIONALE_CATALAN" | "LANGUE_REGIONALE_CORSE" | "LANGUE_REGIONALE_CREOLE" | "LANGUE_REGIONALE_KANAK" | "LANGUE_REGIONALE_OCCITAN_LANGUE_OC" | "LANGUE_REGIONALE_TAHITIEN" | "LETTRES_ANCIENNES" | "LETTRES_MODERNES" | "MATHEMATIQUES" | "NUMERIQUE_SCIENCES_INFORMATIQUES" | "PHILOSOPHIE" | "PHYSIQUE_CHIMIE" | "SCIENCES_VIE_TERRE" | "SCIENCES_ECONOMIQUES_SOCIALES" | "SCIENCES_TECHNIQUES_MEDICO_SOCIALES" | "SCIENCES_INDUSTRIELLES_INGENIEUR" | "AUTRE";

export type MilieuGenerique = "ZONES_COTIERES" | "HABITATS_MARINS" | "FLEUVES_LACS_RIVIERES" | "ZONES_HUMIDES" | "MONTAGNES" | "FORETS" | "PRAIRIES_ESPACES_CULTIVES" | "NATURE_EN_VILLE" | "HABITATS_CONTINENTAUX";

export type MilieuInteretParticulier = "AFFLEUREMENT_ROCHEUX" | "BORDURE_ZONE_AGRICOLE" | "COURS_D_EAU" | "ESTRAN_PLAGE" | "ESTUAIRES_LAGUNES" | "FRICHES" | "GROTTES" | "HAIES" | "LANDES" | "MANGROVE" | "MARAIS" | "PARC_URBAIN_JARDINS_PARTAGES" | "PLAN_D_EAU" | "PRAIRIES" | "RECIF_CORALLIEN" | "RELIEF_VOLCANIQUE_RECENT" | "TOURBIERES" | "AUTRE";

export type MoisAnnee = "JANVIER" | "FEVRIER" | "MARS" | "AVRIL" | "MAI" | "JUIN" | "JUILLET" | "AOUT" | "SEPTEMBRE" | "OCTOBRE" | "NOVEMBRE" | "DECEMBRE";

export type NatureAireEducative = "AME" | "ATE";

export type NatureSite = "PUBLIC" | "PRIVE";

export type ObjectConcretRealisation = "AMENAGEMENTS" | "GESTION_ESPECES_HABITATS" | "OPERATION_DE_COMMUNICATION" | "PRODUCTION_ARTISTIQUE" | "PRODUCTION_SCIENTIFIQUE" | "SOUMISSION_DE_PROPOSITIONS" | "AUTRE";

export type OrigineFinancement = "COMMUNE" | "INTERVENTION_EDD" | "COMMUNAUTE_COMMUNE" | "DEPARTEMENT" | "REGION" | "EUROPE" | "STRUCTURE_GEST_ENV" | "APPEL_PROJET" | "FONDATION_PRIVEE" | "AGENCE_EAU" | "OFB" | "AUTRE";

export type ProfilUtilisateur = "SUPER_ADMINISTRATEUR" | "ACCOMPAGNATEUR_NATIONAL" | "ACCOMPAGNATEUR_REGIONAL" | "ANIMATEUR_REGIONAL" | "REFERENT" | "ENSEIGNANT" | "OBSERVATEUR_NATIONAL";

export type Qualite = "TRES_BONNE" | "BONNE" | "PEU_FREQUENTE" | "MAUVAIS_INEXISTANT";

export type QuickFilter = "MY_ASSIGNMENTS" | "NOT_ASSIGNED";

export type RaisonArret = "DEPART_ENSEIGNANT" | "DEPART_REFERENT" | "PAS_FINANCEMENT" | "MOTIVATION_ENSEIGNANT" | "MOTIVATION_REFERENT" | "INVESTISSEMENT_ENSEIGNANT" | "INVESTISSEMENT_REFERENT" | "LOURDEUR_ADMINISTRATIVE_LABELLISATION" | "ENTRAVES_CONTRAIGNANTES" | "MANQUE_VOLONTE_DIRECTION" | "AUTRE_PROJET" | "ETABLISSEMENT_FERME" | "STRUCTURE_FERME" | "AE_IMPACTEEE" | "NE_SAIT_PAS" | "AUTRE";

export type StatutActivationUtilisateur = "ACTIF" | "EMAIL_A_VALIDER" | "INSCRIPTION_A_VALIDER" | "NON_INSCRIT";

export type StatutDossier = "BROUILLON" | "EN_ATTENTE" | "INSTRUCTION_EN_COURS" | "INSTRUCTION_TERMINEE" | "ACCEPTE_SOUS_RESERVE_REFERENT" | "ACCEPTE" | "REFUSE" | "LABELLISATION_EN_DEVENIR" | "AVIS_PASSABLE" | "ARCHIVE" | "AUTOMATISE";

export type TempsPasse = "HEBDO" | "BI_HEBDO" | "MENSUEL" | "TRIMESTRIEL";

export type TeteStructure = "CEN" | "FCPN" | "FNE" | "FPNR" | "LPO" | "OFB" | "ONF" | "PNF" | "REN" | "RGF" | "RNF" | "UICN" | "UNCPIE" | "AUTRE";

export type Thematique = "ACTIVITES_HUMAINES_COMMERCIALES" | "ACTIVITES_HUMAINES_LOISIR" | "BIODIVERSITE_ESPECES" | "BIODIVERSITE_HABITATS_MILIEUX_NATURELS" | "CHANGEMENT_CLIMATIQUE" | "ENERGIE" | "ESPECES_ENVAHISSANTES" | "ESPECES_EXPLOITEES" | "FONCTIONNEMENT_ECOSYSTEME" | "GEOLOGIE" | "HISTOIRE" | "PATRIMOINE_CULTUREL" | "POLLUTION" | "AUTRE";

export type Trilean = "YES" | "NO" | "MAYBE";

export type TypeConseil = "NORMAL" | "ELARGI";

export type TypeDossier = "DOSSIER_INSCRIPTION" | "DOSSIER_LABELLISATION" | "DOSSIER_RENOUVELLEMENT" | "DOSSIER_RENOUVELLEMENT_LIGHT";

export type TypeEntrave = "AUCUNE" | "FINANCEMENT" | "ANNULATION_METEO" | "DIFFICULTES_MAIRIE" | "TENSION_USAGERS_AIRE" | "DIFFICULTES_ADMINISTRATIVES" | "ABSENCE_LONG_TERME" | "MAUVAISE_ESTIMATION" | "AUTRE";

export type TypeFichier = "FILE_STRUCTURE_AGREMENT" | "CHARTE_ENGAGEMENT" | "CR_CONSEIL_TERRE_MER" | "CR_CONSEIL_TERRE_MER_ELARGIS" | "ETAT_DES_LIEUX" | "PROBLEMATIQUES" | "ADHESION_DIFFUSION" | "ECHANGES_INTER_AE" | "ACTIVITES_TRANSDISCIPLINAIRES" | "FICHIER_COMPLEMENTAIRE" | "CALENDRIER" | "DEMARCHE" | "FICHIER_IMPLICATION_ELEVES" | "AUTRE" | "IMAGE" | "ETAT_DES_LIEUX_LIENS_ENSEIGNEMENTS" | "AUTORISATION_DROIT_IMAGE";

export type TypeRenouvellementObjectif = "ENRICHISSEMENT" | "NOUVELLE_ETUDE" | "REPRISE" | "AUTRE";

export type TypeStructure = "ASSOCIATION" | "RESERVE_NATURELLE_CORSE" | "PARC_NATIONAL" | "PARC_NATUREL_MARIN" | "COLLECTIVITE" | "CONSERVATOIRE_ESPACES_NATURELS" | "CONSERVATOIRE_LITTORAL" | "PARC_NATUREL_REGIONAL" | "RESERVE_BIOSPHERE" | "RESERVE_NATURELLE_CHASSE_FAUNE" | "RESERVE_BIOLOGIQUE" | "RESERVE_NATURELLE_NATIONALE" | "RESERVE_NATURELLE_REGIONALE" | "SITE_NATURA_2000" | "AUTRE" | "SYNDICAT_MIXTE" | "ENTREPRISE_PRIVEE";
