var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _vm.parametrage && _vm.parametrage.debutInscription
            ? _c(
                "SubHeaderItem",
                {
                  staticClass: "date-inscription",
                  attrs: { icon: "calendar" }
                },
                [
                  _c("div", { staticClass: "item-text" }, [
                    _vm._v(_vm._s(_vm.$t("myProjects.dateInscription")))
                  ]),
                  _c("div", { staticClass: "item-text range" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("myProjects.dateInscriptionRange", [
                            _vm.formatDate(_vm.parametrage.debutInscription),
                            _vm.formatDate(_vm.parametrage.finInscription)
                          ])
                        ) +
                        " "
                    )
                  ])
                ]
              )
            : _vm._e(),
          _vm.parametrage &&
          _vm.parametrage.debutLabellisation &&
          _vm.showLabellisationDates
            ? _c(
                "SubHeaderItem",
                {
                  staticClass: "date-labellisation",
                  attrs: { icon: "calendar" }
                },
                [
                  _c("div", { staticClass: "item-text" }, [
                    _vm._v(_vm._s(_vm.$t("projetDetails.dateLabellisation")))
                  ]),
                  _c("div", { staticClass: "item-text range" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("myProjects.dateInscriptionRange", [
                            _vm.formatDate(_vm.parametrage.debutLabellisation),
                            _vm.formatDate(_vm.parametrage.finLabellisation)
                          ])
                        ) +
                        " "
                    )
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "PageView",
        {
          attrs: {
            mainTitle: _vm.$t("myProjects.title"),
            noSubTitleExpected: "true"
          }
        },
        [
          _c(
            "ContentView",
            { staticClass: "MyProjectsPage-content" },
            [
              _c(
                "div",
                { staticClass: "MyProjectsPage-projects-part" },
                [
                  _vm.pendingProjects && _vm.pendingProjects.length > 0
                    ? _c(
                        "div",
                        [
                          _c("H3TitleView", [
                            _vm._v(
                              _vm._s(_vm.$t("myProjects.pendingInvit")) + " "
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "MyProjectsPage-cards" },
                            [
                              _vm.isLoadingData
                                ? _c(
                                    "div",
                                    [
                                      _c("BookmarkCard", {
                                        attrs: {
                                          isLoading: true,
                                          tagType: _vm.tagTypes.INVITATION,
                                          iconName: "paper-plane"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._l(_vm.pendingProjects, function(
                                    project
                                  ) {
                                    return _c(
                                      "div",
                                      { key: project.id },
                                      [
                                        _c("PendingProjectCard", {
                                          attrs: {
                                            project: project,
                                            loggedUserId: _vm.loggedUser
                                              ? _vm.loggedUser.id
                                              : ""
                                          },
                                          on: {
                                            "invitation-processed": function(
                                              $event
                                            ) {
                                              return _vm.invitationProcessed()
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("H3TitleView", [
                    _vm._v(_vm._s(_vm.$t("myProjects.ae-projects")) + " ")
                  ]),
                  _c(
                    "div",
                    { staticClass: "MyProjectsPage-buttons" },
                    [
                      _c("FilterComponent", {
                        attrs: {
                          filterFields: _vm.filterFields,
                          initialFilters: _vm.activeFilters
                        },
                        on: { "update-active-filters": _vm.updateFilters }
                      }),
                      _c("b-button", {
                        attrs: {
                          rounded: "",
                          type: "is-primary",
                          disabled: !_vm.showCreateLink,
                          label: _vm.$t("myProjects.createProject")
                        },
                        on: { click: _vm.createProject }
                      })
                    ],
                    1
                  ),
                  _vm.isPorteurProjet && _vm.showCreateLink
                    ? _c(
                        "b-message",
                        {
                          staticStyle: {
                            "margin-top": "20px",
                            "margin-bottom": "-20px"
                          },
                          attrs: { type: "is-info" }
                        },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("myProjects.info-porteur-projet"))
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm.isLoadingData
                    ? _c("LoadingSkeleton", {
                        attrs: { "loading-text": _vm.$t("myProjects.loading") }
                      })
                    : _c(
                        "div",
                        { staticClass: "MyProjectsPage-cards" },
                        [
                          _vm._l(_vm.myProjects, function(project) {
                            return _c(
                              "div",
                              { key: project.id },
                              [
                                _c("ProjectCard", {
                                  attrs: {
                                    project: project,
                                    loggedUser: _vm.loggedUser
                                  },
                                  on: {
                                    "delete-project": function($event) {
                                      return _vm.loadProjects()
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          }),
                          _vm.myProjects.length === 0 &&
                          _vm.activeFilters.length === 0
                            ? _c(
                                "b-message",
                                { attrs: { type: "is-warning" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("myProjects.noProjects")) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.myProjects.length === 0 &&
                          _vm.activeFilters.length !== 0
                            ? _c(
                                "b-message",
                                { attrs: { type: "is-warning" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("myProjects.noProjectsFiltered")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        2
                      )
                ],
                1
              ),
              _c("ActualitesForPorteursProjetView", {
                staticClass: "MyProjectsPage-actualites",
                attrs: {
                  graeId:
                    _vm.loggedUser &&
                    _vm.loggedUser.grae &&
                    _vm.loggedUser.grae.id
                }
              })
            ],
            1
          ),
          _c("FirstConnectionView", {
            attrs: {
              isLoading: _vm.isLoadingData,
              pendingProjects: _vm.pendingProjects,
              projectsInEtablissement: _vm.projectsNotParticipatingIn,
              loggedUser: _vm.loggedUser,
              isComponentModalActive: _vm.openFirstConnectionModal
            },
            on: {
              "modal-closed": _vm.closeFirstConnectionModal,
              "send-answers": _vm.sendAnswers,
              "projects-joined": _vm.updateProjectsInEtablissement
            }
          }),
          _c("router-view", {
            attrs: {
              loggedUser: _vm.loggedUser,
              chosenEtablissement: _vm.chosenEtablissement,
              projectsInEtablissement: _vm.projectsInEtablissement
            },
            on: {
              "etablissement-chosen": _vm.setChosenEtablissement,
              "cancel-project-creation": _vm.closeProjectCreation,
              "end-project-creation": _vm.endProjectCreation,
              "confirm-join": _vm.confirmJoinedProjects,
              "ask-project-creation": _vm.askForProjectCreation
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }