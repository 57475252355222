/*-
 * #%L
 * Aires Éducatives
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
export default class ValidationResult {
  isValid: boolean
  errors: any
  flags: any
  fields: any
}

export class ValidationIssue {
  constructor(public field: string, public error: string) {}
}

export enum ValidationStatus {
  VALID,
  EMPTY,
  WARNING,
  ERROR,
  NONE,
  NEUTRAL,
}

export class TabValidationHint {
  errorsCount: number
  type: string

  constructor() {
    this.errorsCount = 0
    this.type = VALIDATION_TYPES.SUCCESS
  }
}

export const VALIDATION_TYPES = {
  SUCCESS: "is-success",
  WARNING: "is-warning",
  DANGER: "is-danger",
}
