var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PageView",
    { attrs: { isOnBigHeaderPage: true, noSubTitleExpected: "true" } },
    [
      _c("TitleView", [_vm._v(_vm._s(_vm.$t("registerPage.titre")))]),
      _c(
        "ContentView",
        [
          _vm.currentStep === _vm.registerSteps.USER
            ? _c("UserInformationsView", {
                on: {
                  "input-user": _vm.updateUser,
                  "accept-visibility": function(accept) {
                    return (_vm.utilisateur.acceptNameVisibilityOnProtectedListing = accept)
                  }
                },
                model: {
                  value: _vm.utilisateur,
                  callback: function($$v) {
                    _vm.utilisateur = $$v
                  },
                  expression: "utilisateur"
                }
              })
            : _vm._e(),
          _vm.currentStep === _vm.registerSteps.PROFIL
            ? _c("ProfilSelectionView", {
                on: {
                  "input-profil": _vm.moveProfileOn,
                  "go-back": function(profil) {
                    return _vm.updateProfile(profil, _vm.registerSteps.USER)
                  }
                },
                model: {
                  value: _vm.utilisateur.profil,
                  callback: function($$v) {
                    _vm.$set(_vm.utilisateur, "profil", $$v)
                  },
                  expression: "utilisateur.profil"
                }
              })
            : _vm._e(),
          _vm.currentStep === _vm.registerSteps.COMPETENCES
            ? _c("ReferentCompetencesView", {
                on: {
                  "go-back": function(updatedUser) {
                    return _vm.updateCompetences(
                      updatedUser,
                      _vm.registerSteps.PROFIL
                    )
                  },
                  "input-competences": function(updatedUser) {
                    return _vm.updateCompetences(
                      updatedUser,
                      _vm.registerSteps.STRUCTURE_SELECTION
                    )
                  }
                }
              })
            : _vm._e(),
          _vm.currentStep === _vm.registerSteps.STRUCTURE_SELECTION ||
          _vm.currentStep === _vm.registerSteps.STRUCTURE_ADDITION
            ? _c("StructureSelectionView", {
                ref: "structureSelection",
                attrs: { showAcceptVisibilityCheckbox: true },
                on: {
                  "input-structure": function(structure) {
                    return _vm.updateStructure(
                      structure,
                      _vm.registerSteps.INSCRIPTION
                    )
                  },
                  "go-back": function(structure) {
                    return _vm.updateStructure(
                      structure,
                      _vm.registerSteps.COMPETENCES
                    )
                  },
                  "tab-index-changed": _vm.refreshURLFromCurrentTabIndex
                },
                model: {
                  value: _vm.utilisateur.structure,
                  callback: function($$v) {
                    _vm.$set(_vm.utilisateur, "structure", $$v)
                  },
                  expression: "utilisateur.structure"
                }
              })
            : _vm._e(),
          _vm.currentStep === _vm.registerSteps.ETABLISSEMENT_SELECTION ||
          _vm.currentStep === _vm.registerSteps.ETABLISSEMENT_ADDITION
            ? _c("EtablissementSelectionView", {
                ref: "etablissementSelection",
                attrs: {
                  showAcceptVisibilityCheckbox: true,
                  inputLabel: "",
                  buttonLabel: _vm.$t("valider")
                },
                on: {
                  "go-back": function(etablissement) {
                    return _vm.updateEtablissement(
                      etablissement,
                      _vm.registerSteps.PROFIL
                    )
                  },
                  "input-etablissement": function(etablissement) {
                    return _vm.updateEtablissement(
                      etablissement,
                      _vm.registerSteps.INSCRIPTION
                    )
                  }
                },
                model: {
                  value: _vm.utilisateur.etablissement,
                  callback: function($$v) {
                    _vm.$set(_vm.utilisateur, "etablissement", $$v)
                  },
                  expression: "utilisateur.etablissement"
                }
              })
            : _vm._e(),
          _vm.currentStep === _vm.registerSteps.REGION
            ? _c("AccompagnateurRegionalInformationsView", {
                on: {
                  "go-back": function(updatedUser) {
                    return _vm.updateAccompagnateurRegional(
                      updatedUser,
                      _vm.registerSteps.PROFIL
                    )
                  },
                  "input-region": function(updatedUser) {
                    return _vm.updateAccompagnateurRegional(
                      updatedUser,
                      _vm.registerSteps.INSCRIPTION
                    )
                  }
                }
              })
            : _vm._e(),
          _vm.currentStep === _vm.registerSteps.FONCTION
            ? _c("AccompagnateurNationalInformationsView", {
                on: {
                  "go-back": function(updatedUser) {
                    return _vm.updateAccompagnateurNational(
                      updatedUser,
                      _vm.registerSteps.PROFIL
                    )
                  },
                  "input-fonction": function(updatedUser) {
                    return _vm.updateAccompagnateurNational(
                      updatedUser,
                      _vm.registerSteps.INSCRIPTION
                    )
                  }
                }
              })
            : _vm._e(),
          _c("b-loading", {
            attrs: { "is-full-page": "" },
            model: {
              value: _vm.isLoading,
              callback: function($$v) {
                _vm.isLoading = $$v
              },
              expression: "isLoading"
            }
          }),
          _vm.errorMessage
            ? _c(
                "b-message",
                { attrs: { type: "is-danger" } },
                [
                  _vm._v(" " + _vm._s(_vm.errorMessage) + " "),
                  _c("br"),
                  _c("br"),
                  _c(
                    "router-link",
                    {
                      staticClass: "button is-primary",
                      attrs: { to: "/login" }
                    },
                    [_c("strong", [_vm._v(" " + _vm._s(_vm.$t("login")))])]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }