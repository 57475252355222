










































































































import { Component, Prop, Vue } from "vue-property-decorator"
import ContentView from "@/views/commons/ContentView.vue"
import H3TitleView from "../commons/H3TitleView.vue"
import H4TitleView from "../commons/H4TitleView.vue"
import { ProjetAnnuelDTO, UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import PendingProjectCard from "./PendingProjectCard.vue"
import EtablissementProjectCard from "./EtablissementProjectCard.vue"
import BookmarkCard from "../generic_components/BookmarkCard.vue"
import { TAG_TYPES_FOR_BOOKMARK_CARD } from "@/model/bean/EnumHelper"

@Component({
  components: {
    H3TitleView,
    ContentView,
    H4TitleView,
    PendingProjectCard,
    EtablissementProjectCard,
    BookmarkCard,
  },
})
export default class FirstConnectionView extends Vue {
  @Prop() pendingProjects: ProjetAnnuelDTO[]
  @Prop() projectsInEtablissement: ProjetAnnuelDTO[]
  @Prop() isLoading: boolean
  @Prop() loggedUser: UtilisateurDTO
  @Prop() isComponentModalActive: boolean

  currentStep = 0
  tagTypes = TAG_TYPES_FOR_BOOKMARK_CARD

  endFirstConnection(): void {
    this.$emit("modal-closed")
  }

  sendAnswersAndGoNext(): void {
    this.currentStep = 1
    this.$emit("send-answers", false)
  }

  sendAnswersAndClose(): void {
    this.$emit("send-answers", true)
  }

  joinProjectsAndClose(): void {
    this.$emit("projects-joined")
  }
}
