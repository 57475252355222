var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationProvider", {
    staticClass: "CheckBoxEntryInput-provider",
    attrs: {
      vid: (_vm.group != "" ? _vm.group + "_" : "") + _vm.vid,
      name: _vm.name || _vm.entryInputControl,
      rules:
        _vm.rules +
        "|" +
        (_vm.entryInputType === "is-danger" && !_vm.disabled ? "required" : "")
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var errors = ref.errors
          var valid = ref.valid
          return [
            _c(
              "b-field",
              {
                staticClass: "CheckBoxEntryInput-field",
                attrs: {
                  type: {
                    "is-normal": _vm.disabled,
                    "is-danger": errors[0],
                    "is-success": valid
                  }
                }
              },
              [
                _c(
                  "div",
                  { class: "CheckBoxEntryInput-control-container" },
                  [
                    _c("b-input", {
                      attrs: {
                        placeholder: _vm.entryInputPlaceholder,
                        disabled: _vm.disabled
                      },
                      on: { input: _vm.onInputChange },
                      model: {
                        value: _vm.innerValue,
                        callback: function($$v) {
                          _vm.innerValue = $$v
                        },
                        expression: "innerValue"
                      }
                    }),
                    _vm.entryInputControl
                      ? _c("p", { staticClass: "CheckBoxEntryInput-control" }, [
                          _c("span", { staticClass: "button is-static" }, [
                            _vm._v(_vm._s(_vm.entryInputControl))
                          ])
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ]
            ),
            errors.length > 0
              ? _c("p", { staticClass: "help is-danger" }, [
                  _vm._v(" " + _vm._s(errors[0]) + " ")
                ])
              : _vm._e()
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }