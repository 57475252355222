









































































import { CalendrierEvenementCreateDTO, ProjetAnnuelDTO } from "@/model/bean/GeneratedDTOs"
import H3TitleView from "@/views/commons/H3TitleView.vue"
import DatePickerWithValidation from "@/views/inputs/DatePickerWithValidation.vue"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { EvenementService } from "@/services/rest/evenement/EvenementService"
import ConversionUtils from "@/services/ConversionUtils"
import { ValidationObserver } from "vee-validate"

@Component({
  components: {
    DatePickerWithValidation,
    InputWithValidation,
    H3TitleView,
    ValidationObserver,
  },
})
export default class EventModale extends Vue {
  @Prop() projetAnnuel: ProjetAnnuelDTO
  @Prop() open: boolean
  @Prop({ default: false }) isPorteurProjet: boolean
  @Prop({ default: () => [] }) unselectableDates: Date[]

  private evenementService = EvenementService.INSTANCE

  selectedDate: Date | undefined = undefined
  minDate = new Date()
  maxDate = new Date()
  content = ""

  mounted(): void {
    if (this.projetAnnuel) {
      this.minDate = new Date(this.projetAnnuel.anneeScolaireDebut)
      this.maxDate = new Date(this.projetAnnuel.anneeScolaireFin)
    }
  }

  @Watch("unselectableDates")
  unselectableDatesChanged(): void {
    const dates = this.unselectableDates.map((date) => date.getTime())
    this.selectedDate?.setHours(0, 0, 0, 0)
    while (dates.includes(this.selectedDate ? this.selectedDate.getTime() : 0)) {
      this.selectedDate?.setDate(this.selectedDate.getDate() - 1)
    }
  }

  async addAndClose(): Promise<void> {
    if (this.projetAnnuel && this.projetAnnuel.id && this.selectedDate) {
      const evenement = {
        dateEvenement: ConversionUtils.convertToUTCDate(this.selectedDate),
        contenu: this.content,
      } as CalendrierEvenementCreateDTO
      this.evenementService.createEvenement(this.projetAnnuel.id, evenement).then(() => {
        this.resetForm()
        this.emitCloseModale(true)
      })
    }
  }

  resetForm(): void {
    this.selectedDate = new Date()
    this.content = ""
  }

  emitCloseModale(withRefresh: boolean): void {
    this.$emit("close-modale", withRefresh)
  }
}
