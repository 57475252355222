





















import { ProjetAnnuelDTO, UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import { LoginService } from "@/services/rest/login/LoginService"
import { ProjectService } from "@/services/rest/project/ProjectService"
import ProjectCard from "@/views/projects_pp/ProjectCard.vue"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import H3TitleView from "@/views/commons/H3TitleView.vue"

@Component({
  components: {
    H3TitleView,
    ProjectCard,
  },
})
export default class StructureProjetsView extends Vue {
  @Prop() structureId!: string

  private loginService = LoginService.INSTANCE
  private projectService = ProjectService.INSTANCE
  private isLoading = false

  loggedUser: UtilisateurDTO = new UtilisateurDTO()
  regionProjects: Map<string, ProjetAnnuelDTO[]> = new Map()

  async created(): Promise<void> {
    this.isLoading = true
    try {
      await this.fetchProjects()
    } finally {
      this.isLoading = false
    }
  }

  @Watch("structureId")
  async onStructureIdChanged(newVal: string, oldVal: string): Promise<void> {
    if (newVal !== oldVal) {
      await this.fetchProjects()
    }
  }

  private async fetchProjects(): Promise<void> {
    const loggedUser = this.loginService.getLoggedUser()
    if (!loggedUser) {
      return
    }
    this.loggedUser = loggedUser
    const structureProjects: ProjetAnnuelDTO[] = await this.projectService.getProjectsOfStructure(
      this.structureId
    )

    if (structureProjects.length > 0) {
      this.regionProjects = this.updateRegionProjectMap(structureProjects)
    }
  }

  private updateRegionProjectMap(structures: ProjetAnnuelDTO[]) {
    // Création de la Map avec region comme clé
    return structures.reduce((map, structure) => {
      if (!map.has(structure.grae?.region)) {
        map.set(structure.grae.region, [])
      }
      map.get(structure.grae?.region)!.push(structure)
      return map
    }, new Map<string, ProjetAnnuelDTO[]>())
  }
}
