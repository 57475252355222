




























































































































































import {
  CommentaireCreateDTO,
  CommentaireDTO,
  EchangeDTO,
  PaginationParameter,
  PaginationResult,
  ProjetAnnuelDTO,
  UtilisateurDTO,
} from "@/model/bean/GeneratedDTOs"
import { LoginService } from "@/services/rest/login/LoginService"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import { Component, Prop, Vue } from "vue-property-decorator"
import { format } from "date-fns"
import { CommentaireService } from "@/services/rest/commentaire/CommentaireService"
import { ValidationObserver } from "vee-validate"
import { DialogProgrammatic as Dialog } from "buefy"
import CommentaireModalView from "./CommentaireModalView.vue"
import CourrielModalView from "./CourrielModalView.vue"

const DATE_FORMAT = "DD/MM/YYYY"

@Component({
  components: { InputWithValidation, ValidationObserver, CommentaireModalView, CourrielModalView },
})
export default class InstructionPannelEchanges extends Vue {
  @Prop() project: ProjetAnnuelDTO

  private loginService = LoginService.INSTANCE
  private commentaireService = CommentaireService.INSTANCE

  loggedUser: UtilisateurDTO | undefined = new UtilisateurDTO()
  isRegional = false
  isNational = false
  contenu = ""
  echanges: PaginationResult<EchangeDTO> = new PaginationResult()
  pagination = { ...new PaginationParameter(), pageNumber: 1, pageSize: 3 }
  canLoadNextPage = false
  openCommentaireModal = false
  commentaireSelected: EchangeDTO = new EchangeDTO()
  openCourrielModal = false
  courrielSelected: EchangeDTO = new EchangeDTO()

  $refs!: {
    commentObserver: InstanceType<typeof ValidationObserver>
  }

  created(): void {
    this.loggedUser = this.loginService.getLoggedUser()
    if (!this.loggedUser || !this.loggedUser.id) {
      return
    }

    this.isNational =
      this.loggedUser.profil === "SUPER_ADMINISTRATEUR" ||
      this.loggedUser.profil === "ACCOMPAGNATEUR_NATIONAL"

    this.isRegional =
      (this.loggedUser.profil === "ACCOMPAGNATEUR_REGIONAL" ||
        this.loggedUser.profil === "ANIMATEUR_REGIONAL") &&
      this.loggedUser.grae !== undefined &&
      this.project.grae != undefined &&
      this.loggedUser.grae.id === this.project.grae.id
  }

  mounted(): void {
    if (this.project && this.project.projetId) {
      this.loadEchanges(this.project.projetId)
    }
  }

  async loadEchanges(idProjet: string): Promise<void> {
    this.echanges = await this.commentaireService.getEchanges(idProjet, this.pagination)
    this.computeCanLoadNextPage()
  }

  formatDate(date: Date): string {
    if (date) {
      return format(date, DATE_FORMAT)
    }
    return ""
  }

  async addComment(): Promise<void> {
    if (this.project && this.project.projetId) {
      const comment = {
        contenu: this.contenu,
      } as CommentaireCreateDTO
      this.commentaireService.createComment(this.project.projetId, comment).then(() => {
        this.contenu = ""
        requestAnimationFrame(() => {
          this.$refs.commentObserver.reset()
        })
        this.loadEchanges(this.project.projetId)
      })
    }
  }

  openComment(comment: EchangeDTO): void {
    this.commentaireSelected = comment
    this.openCommentaireModal = true
  }

  openCourriel(courriel: EchangeDTO): void {
    this.courrielSelected = courriel
    this.openCourrielModal = true
  }

  async removeComment(comment: CommentaireDTO): Promise<void> {
    Dialog.confirm({
      message: this.$t("instruction-pannel.echanges.remove-comment").toString(),
      confirmText: this.$t("confirmer").toString(),
      cancelText: this.$t("annuler").toString(),
      onConfirm: () =>
        this.commentaireService.removeComment(comment.projet, comment.id).then(() => {
          this.loadEchanges(this.project.projetId)
        }),
    })
  }

  computeCanLoadNextPage(): void {
    this.canLoadNextPage =
      this.echanges.count / this.pagination.pageSize <= this.pagination.pageNumber
  }

  async loadNextPage(): Promise<void> {
    this.pagination.pageNumber++
    this.loadEchanges(this.project.projetId)
  }

  async loadPreviousPage(): Promise<void> {
    this.pagination.pageNumber--
    this.loadEchanges(this.project.projetId)
  }
}
