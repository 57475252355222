var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.etablissement ||
      !_vm.etablissement.latitude ||
      !_vm.etablissement.longitude
        ? _c("b-message", { attrs: { type: "is-warning" } }, [
            _vm._v(_vm._s(_vm.$t("mapDraw.no-coordinates")))
          ])
        : _vm._e(),
      _vm.validationActivated && !_vm.innerZoneGeographique
        ? _c("b-message", { attrs: { type: "is-danger" } }, [
            _vm._v(_vm._s(_vm.$t("mapDraw.mandatory-zone-geographique")))
          ])
        : _vm._e(),
      _c("SelectWithValidation", {
        staticClass: "mb-3",
        attrs: {
          label: _vm.$t("mapDraw.tile-choices.label"),
          data: _vm.tileChoicesLabels,
          selectLabels: _vm.tileChoicesLabels,
          validationActivated: false,
          showValidationErrors: false,
          vid: "selectMapTile"
        },
        model: {
          value: _vm.tileChoice,
          callback: function($$v) {
            _vm.tileChoice = $$v
          },
          expression: "tileChoice"
        }
      }),
      _vm.validationActivated
        ? _c("p", [
            _c("i", [
              _vm._v(_vm._s(_vm.$t("projet.en-bref.info-site-aire-educative")))
            ])
          ])
        : _vm._e(),
      _vm.mapVisible
        ? _c(
            "div",
            { staticClass: "MapDrawView-container" },
            [
              _c(
                "l-map",
                {
                  ref: "map",
                  staticClass: "MapDrawView-map",
                  attrs: { zoom: _vm.currentZoom, maxZoom: 17 },
                  on: {
                    ready: function($event) {
                      return _vm.mapIsReady()
                    },
                    "draw:drawstop": function(event) {
                      return _vm.stopDraw(event)
                    },
                    "draw:created": function(event) {
                      return _vm.enableEditPolygon(event)
                    },
                    "draw:editstop": function(event) {
                      return _vm.updateZoneGeographique(event)
                    }
                  }
                },
                [
                  _c("l-tile-layer", { attrs: { url: _vm.tileURL } }),
                  _vm.etablissement &&
                  _vm.etablissement.latitude &&
                  _vm.etablissement.longitude
                    ? _c(
                        "l-marker",
                        { attrs: { "lat-lng": _vm.markerLocation } },
                        [
                          _c("l-popup", [
                            _c("h1", [_vm._v(_vm._s(_vm.etablissement.nom))])
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "MapDrawView-legend-container" },
        [
          _c(
            "div",
            { staticClass: "MapDrawView-legend" },
            [
              _c("b-icon", { attrs: { icon: "map-marker-alt" } }),
              _vm._v(
                " " + _vm._s(_vm.$t("mapDraw.legends.position-ecole")) + " "
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "MapDrawView-legend" },
            [
              _c("b-icon", { attrs: { icon: "plus" } }),
              _vm._v(" " + _vm._s(_vm.$t("mapDraw.zoomin")))
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "MapDrawView-legend" },
            [
              _c("b-icon", { attrs: { icon: "minus" } }),
              _vm._v(" " + _vm._s(_vm.$t("mapDraw.zoomout")))
            ],
            1
          ),
          !_vm.readonly
            ? _c("div", { staticClass: "MapDrawView-legend" }, [
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: require("@/assets/images/icones/polygon.svg"),
                    alt: "Polygone"
                  }
                }),
                _vm._v(
                  " " + _vm._s(_vm.$t("mapDraw.legends.draw-polygon")) + " "
                )
              ])
            : _vm._e(),
          !_vm.readonly
            ? _c(
                "div",
                { staticClass: "MapDrawView-legend" },
                [
                  _c("b-icon", { attrs: { icon: "edit" } }),
                  _vm._v(" " + _vm._s(_vm.$t("mapDraw.legends.edit")) + " ")
                ],
                1
              )
            : _vm._e(),
          !_vm.readonly
            ? _c(
                "div",
                { staticClass: "MapDrawView-legend" },
                [
                  _c("b-icon", { attrs: { icon: "trash-alt" } }),
                  _vm._v(" " + _vm._s(_vm.$t("mapDraw.legends.delete")) + " ")
                ],
                1
              )
            : _vm._e(),
          _c("b-message", { staticClass: "mt-2" }, [
            _c("div", {
              staticClass: "inputNotice",
              domProps: { innerHTML: _vm._s(_vm.$t("mapDraw.legends.help")) }
            })
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }