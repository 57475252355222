var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "projet-part" },
    [
      _c("h2", [
        _vm._v(_vm._s(_vm.$t("projetPage.labellisation.suivi.titre")))
      ]),
      _c("p", [_vm._v(_vm._s(_vm.$t("projetPage.labellisation.suivi.intro")))]),
      _c("InputWithValidation", {
        attrs: {
          validationActivated: _vm.validationActivated,
          showValidationErrors: _vm.showValidationErrors,
          type: "textarea",
          label:
            _vm.projetAnnuel.typeDossier !== "DOSSIER_RENOUVELLEMENT"
              ? _vm.$t("projetPage.labellisation.suivi.prevu-fin-annee-label")
              : _vm.$t(
                  "projetPage.labellisation.suivi.prevu-fin-annee-message-lourd"
                ),
          inputNotice:
            _vm.projetAnnuel.typeDossier !== "DOSSIER_RENOUVELLEMENT"
              ? _vm.$t("projetPage.labellisation.suivi.prevu-fin-annee-message")
              : "",
          maxlength: "1400",
          disabled: _vm.readOnly,
          vid: "infos_prevuFinAnnee"
        },
        on: {
          "manual-change": function($event) {
            return _vm.modified()
          }
        },
        model: {
          value: _vm.projetAnnuel.prevuFinAnnee,
          callback: function($$v) {
            _vm.$set(_vm.projetAnnuel, "prevuFinAnnee", $$v)
          },
          expression: "projetAnnuel.prevuFinAnnee"
        }
      }),
      _c("GroupCheckboxesWithValidation", {
        attrs: {
          validationActivated: _vm.validationActivated,
          showValidationErrors: _vm.showValidationErrors,
          rules: "maxChoices:2",
          label:
            _vm.projetAnnuel.typeDossier !== "DOSSIER_RENOUVELLEMENT"
              ? _vm.$t("projetPage.labellisation.suivi.entraves")
              : _vm.$t("projetPage.labellisation.suivi.entraves-lourd"),
          data: _vm.contexteCheckBoxesStrings,
          disabled: _vm.readOnly,
          verticalDisplay: "true",
          extInputValue: _vm.projetAnnuel.entravesAutres,
          inputLabel: _vm.$t("projetPage.labellisation.suivi.autre-entraves"),
          inputNotice: _vm.$t(
            "projetPage.labellisation.suivi.entraves-message"
          ),
          vid: "infos_entravesAutres"
        },
        on: {
          "manual-change": function($event) {
            return _vm.changeEntraves()
          },
          "input-change": _vm.changeAutreEntraves
        },
        model: {
          value: _vm.checkedContexteCheckBoxesStrings,
          callback: function($$v) {
            _vm.checkedContexteCheckBoxesStrings = $$v
          },
          expression: "checkedContexteCheckBoxesStrings"
        }
      }),
      _c("InputWithValidation", {
        attrs: {
          validationActivated: _vm.validationActivated,
          showValidationErrors: _vm.showValidationErrors,
          type: "textarea",
          label:
            _vm.projetAnnuel.typeDossier !== "DOSSIER_RENOUVELLEMENT"
              ? _vm.$t("projetPage.labellisation.suivi.faciliteurs")
              : _vm.$t("projetPage.labellisation.suivi.faciliteurs-lourd"),
          maxlength: "1400",
          disabled: _vm.readOnly,
          vid: "infos_elementsFacilitateurs"
        },
        on: {
          "manual-change": function($event) {
            return _vm.modified()
          }
        },
        model: {
          value: _vm.projetAnnuel.elementsFacilitateurs,
          callback: function($$v) {
            _vm.$set(_vm.projetAnnuel, "elementsFacilitateurs", $$v)
          },
          expression: "projetAnnuel.elementsFacilitateurs"
        }
      }),
      _c("InputWithValidation", {
        attrs: {
          validationActivated: _vm.validationActivated,
          showValidationErrors: _vm.showValidationErrors,
          type: "textarea",
          label: _vm.$t("projetPage.labellisation.suivi.evaluation-impact"),
          maxlength: "1400",
          disabled: _vm.readOnly,
          vid: "infos_evaluationImpactEnvironnementEleves"
        },
        on: {
          "manual-change": function($event) {
            return _vm.modified()
          }
        },
        model: {
          value: _vm.projetAnnuel.evaluationImpactEnvironnementEleves,
          callback: function($$v) {
            _vm.$set(
              _vm.projetAnnuel,
              "evaluationImpactEnvironnementEleves",
              $$v
            )
          },
          expression: "projetAnnuel.evaluationImpactEnvironnementEleves"
        }
      }),
      _c("InputWithValidation", {
        attrs: {
          validationActivated: _vm.validationActivated,
          showValidationErrors: _vm.showValidationErrors,
          type: "textarea",
          label:
            _vm.projetAnnuel.typeDossier !== "DOSSIER_RENOUVELLEMENT"
              ? _vm.$t("projetPage.labellisation.suivi.impact")
              : _vm.$t(
                  "projetPage.labellisation.suivi.prevu-fin-annee-label-lourd"
                ),
          inputNotice:
            _vm.projetAnnuel.typeDossier !== "DOSSIER_RENOUVELLEMENT"
              ? _vm.$t("projetPage.labellisation.suivi.impact-message")
              : "",
          maxlength: "1400",
          disabled: _vm.readOnly,
          vid: "infos_impactSurTerritoire"
        },
        on: {
          "manual-change": function($event) {
            return _vm.modified()
          }
        },
        model: {
          value: _vm.projetAnnuel.impactSurTerritoire,
          callback: function($$v) {
            _vm.$set(_vm.projetAnnuel, "impactSurTerritoire", $$v)
          },
          expression: "projetAnnuel.impactSurTerritoire"
        }
      }),
      _c("InputWithValidation", {
        attrs: {
          validationActivated: _vm.validationActivated,
          showValidationErrors: _vm.showValidationErrors,
          type: "textarea",
          label: _vm.$t("projetPage.labellisation.suivi.commentaire"),
          maxlength: "1400",
          disabled: _vm.readOnly,
          vid: "infos_commentaireSupplementaire"
        },
        on: {
          "manual-change": function($event) {
            return _vm.modified()
          }
        },
        model: {
          value: _vm.projetAnnuel.commentaireSupplementaire,
          callback: function($$v) {
            _vm.$set(_vm.projetAnnuel, "commentaireSupplementaire", $$v)
          },
          expression: "projetAnnuel.commentaireSupplementaire"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }