var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        "BookmarkCard " + _vm.tagType + " " + (_vm.iconName ? "with-icon" : "")
    },
    [
      _vm.iconName
        ? _c(
            "div",
            { staticClass: "BookmarkCard-icon" },
            [
              _c("b-icon", {
                attrs: {
                  icon: _vm.iconName,
                  size: "is-large",
                  type: _vm.iconType
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          class: "BookmarkCard-card " + (_vm.clickContentCb ? "clickable" : ""),
          on: { click: _vm.clickContent }
        },
        [
          _c("div", { staticClass: "BookmarkCard-tag" }, [
            _vm._v(" " + _vm._s(_vm.$t("tag-types." + _vm.tagType)) + " ")
          ]),
          _c(
            "div",
            { staticClass: "BookmarkCard-content" },
            [
              _vm.isLoading
                ? _c("b-skeleton", {
                    attrs: { width: "80%", height: "50px", animated: "" }
                  })
                : _vm._t("content")
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "BookmarkCard-footer" },
            [
              _vm.isLoading
                ? _c("b-skeleton", { attrs: { width: "50%", animated: "" } })
                : _vm._t("footer")
            ],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }