








import { Component, Prop, Vue } from "vue-property-decorator"
import PageView from "@/views/commons/PageView.vue"
import ContentView from "@/views/commons/ContentView.vue"
import GraeDetailsView from "@/views/grae/GraeDetailsView.vue"

import { LoginService } from "@/services/rest/login/LoginService"

@Component({
  components: {
    PageView,
    ContentView,
    GraeDetailsView,
  },
})
export default class AEPage extends Vue {
  @Prop() activeSubTab: number

  private loginService = LoginService.INSTANCE

  title = ""

  created(): void {
    const loggedUser = this.loginService.getLoggedUser()
    if (!loggedUser || !loggedUser.id) {
      return
    }
    this.title = this.$t(`menu.aires-educatives`).toString()
  }
}
