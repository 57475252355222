var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", {}, [
        _c("div", { staticClass: "custom-card mt-5 contact-card" }, [
          _c("div", { staticClass: "custom-card-content" }, [
            _c("div", { staticClass: "columns is-vcentered" }, [
              _c(
                "div",
                { staticClass: "column is-2 pr-0 pb-0" },
                [
                  _c("b-icon", {
                    staticClass: "pink",
                    attrs: { icon: "question-circle", size: "is-large" }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "column pt-5 pl-0 pb-0" }, [
                _c("span", { staticClass: "area-title" }, [
                  _vm._v(_vm._s(_vm.$t("projet.en-bref.une-question")))
                ])
              ])
            ]),
            _c("div", { staticClass: "columns is-vcentered" }, [
              _c("div", { staticClass: "column is-2 pr-0 pt-0" }),
              _c("div", { staticClass: "column pl-0 pt-0 column-card" }, [
                _c("div", { staticClass: "columns is-vcentered" }, [
                  _c(
                    "div",
                    { staticClass: "column is-one-third" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "is-primary is-outlined is-rounded",
                          attrs: {
                            tag: "a",
                            target: "_blank",
                            href: "https://ame.ofb.fr/doku.php"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("projet.en-bref.wiki")))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "column is-one-third" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "is-primary is-outlined is-rounded",
                          attrs: {
                            tag: "a",
                            target: "_blank",
                            href: "https://ame.ofb.fr/doku.php?id=sagafaq"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("projet.en-bref.faq")))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "column is-one-third" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass:
                            "is-primary is-outlined is-rounded is-flex is-align-items-center is-justify-content-center",
                          staticStyle: { "padding-top": "9px" },
                          attrs: {
                            tag: "a",
                            target: "_blank",
                            href:
                              "https://www.facebook.com/groups/804644174157988/"
                          }
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "bi bi-facebook",
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                width: "25",
                                height: "25",
                                fill: "currentColor",
                                viewBox: "0 0 16 16"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"
                                }
                              })
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        ]),
        _c(
          "div",
          {
            staticClass: "custom-card mt-5 contact-card",
            staticStyle: { height: "210px", "margin-bottom": "50px" }
          },
          [
            _c("div", { staticClass: "custom-card-content" }, [
              _c("div", { staticClass: "columns is-vcentered" }, [
                _c(
                  "div",
                  { staticClass: "column is-2 pr-0 pb-0" },
                  [
                    _c("b-icon", {
                      staticClass: "pink",
                      attrs: { icon: "address-card", size: "is-large" }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "column pt-5 pl-0 pb-0" }, [
                  _c("span", { staticClass: "area-title" }, [
                    _vm._v(_vm._s(_vm.$t("projet.en-bref.contact")))
                  ])
                ])
              ]),
              _c("div", { staticClass: "columns is-vcentered" }, [
                _c("div", { staticClass: "column is-2 pr-0 pt-0" }),
                _c("div", { staticClass: "column pl-0 pt-0 column-card" }, [
                  _c("div", { staticClass: "columns" }, [
                    _c("div", { staticClass: "column" }, [
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.contact.prenom) +
                            " " +
                            _vm._s(_vm.contact.nom)
                        )
                      ]),
                      _c("p", [_vm._v(_vm._s(_vm.$t(_vm.contact.profil)))]),
                      _c(
                        "p",
                        { staticClass: "mt-3 is-vcentered" },
                        [
                          _c("b-icon", {
                            staticClass: "pink",
                            staticStyle: { "padding-right": "5px" },
                            attrs: { icon: "phone", size: "is-medium" }
                          }),
                          _vm._v(_vm._s(_vm.contact.telephone) + " ")
                        ],
                        1
                      ),
                      _c("p", { staticClass: "is-vcentered" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href: "mailto:" + _vm.contact.courriel
                            }
                          },
                          [
                            _c("b-icon", {
                              staticClass: "pink",
                              staticStyle: { "padding-right": "5px" },
                              attrs: {
                                icon: "envelope-open-text",
                                size: "is-medium"
                              }
                            }),
                            _vm._v(" " + _vm._s(_vm.contact.courriel) + " ")
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]
        )
      ]),
      _c("H3TitleView", { staticClass: "mb-4" }, [
        _vm._v(" " + _vm._s(_vm.$t("projet.en-bref.actualites")) + " ")
      ]),
      _vm.actualites.length === 0
        ? _c("b-message", { attrs: { type: "is-info" } }, [
            _vm._v(" " + _vm._s(_vm.$t("projet.en-bref.no-actu")) + " ")
          ])
        : _vm._e(),
      _vm._l(_vm.actualites, function(actualite, i) {
        return _c("BookmarkCard", {
          key: i,
          class: { "mt-2": i > 0 },
          attrs: {
            tagType: actualite.grae
              ? _vm.types.ACTUALITE_REGIONALE
              : _vm.types.ACTUALITE_NATIONALE
          },
          scopedSlots: _vm._u(
            [
              {
                key: "content",
                fn: function() {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "ActualitesForPorteursProjetView-actualite"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "ActualitesForPorteursProjetView-actualite-date"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("projet.en-bref.postee-le", [
                                    _vm.formatDate(
                                      actualite.dateDebutPublication
                                    )
                                  ])
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "ActualitesForPorteursProjetView-actualite-content"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "ActualitesForPorteursProjetView-actualite-message"
                              },
                              [_vm._v(" " + _vm._s(actualite.titre) + " ")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "ActualitesForPorteursProjetView-actualite-link hoverable-discrete-link",
                                on: {
                                  click: function($event) {
                                    return _vm.openModale(actualite.id)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("projet.en-bref.lire-la-suite")
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                },
                proxy: true
              }
            ],
            null,
            true
          )
        })
      }),
      _c(
        "b-modal",
        {
          staticClass: "ActualitesForPorteursProjetView-modale",
          attrs: {
            active: _vm.open,
            "has-modal-card": "",
            "trap-focus": "",
            "aria-role": "dialog",
            "aria-label": "ActualitesForPorteursProjetView",
            "aria-modal": "",
            width: "100vw"
          },
          on: {
            cancel: function($event) {
              _vm.open = false
            }
          }
        },
        [
          [
            _c("div", { staticClass: "modal-card" }, [
              _c(
                "header",
                { staticClass: "modal-card-head" },
                [
                  _vm.actualiteSelected.grae
                    ? _c("H3TitleView", { staticClass: "modal-card-title" }, [
                        _vm._v(_vm._s(_vm.$t("projet.en-bref.actu-regionale")))
                      ])
                    : _c("H3TitleView", { staticClass: "modal-card-title" }, [
                        _vm._v(_vm._s(_vm.$t("projet.en-bref.actu-nationale")))
                      ]),
                  _c("div", { staticClass: "ActualitesModalView-infos" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("projet.en-bref.postee-le", [
                            _vm.formatDate(
                              _vm.actualiteSelected.dateDebutPublication
                            )
                          ])
                        ) +
                        " "
                    )
                  ])
                ],
                1
              ),
              _c(
                "section",
                { staticClass: "modal-card-body" },
                [
                  _c("H3TitleView", [
                    _vm._v(_vm._s(_vm.actualiteSelected.titre))
                  ]),
                  _c("hr"),
                  _c("p", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.actualiteSelected.contenu
                          ? _vm.actualiteSelected.contenu.replaceAll(
                              "\n",
                              "<br/>"
                            )
                          : ""
                      )
                    }
                  })
                ],
                1
              ),
              _c("footer", { staticClass: "modal-card-foot" }, [
                _c("div", { staticClass: "buttons" }, [
                  _c(
                    "div",
                    {
                      staticClass: "hoverable-discrete-link mr-3",
                      on: {
                        click: function($event) {
                          _vm.open = false
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("close-generic")) + " ")]
                  )
                ])
              ])
            ])
          ]
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }