/*-
 * #%L
 * Aires Éducatives
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */

import { AbstractRestService } from "../AbstractRestService"
import ConversionUtils from "@/services/ConversionUtils"
import Constants from "@/services/Constants"
import { FichierDTO, TypeFichier } from "@/model/bean/GeneratedDTOs"

export class FileService extends AbstractRestService {
  static INSTANCE = new FileService()

  async uploadFile(file: File, fileOperationUrl: string): Promise<string> {
    const converted = await ConversionUtils.convertFileToBase64String(file)
    const uploadUrl = fileOperationUrl
    return this.sendFile(uploadUrl, converted)
  }

  async uploadFileWithName(file: File, fileOperationUrl: string): Promise<string> {
    const converted = await ConversionUtils.convertFileToBase64String(file)
    const uploadUrl = fileOperationUrl
    const params = `?nom=${encodeURIComponent(file.name)}`
    return this.sendFile(uploadUrl + params, converted)
  }

  async sendFile<T>(urlPath: string, data: string): Promise<T> {
    const apiURL = Constants.apiUrlv2(urlPath)
    const response = await fetch(apiURL, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "text/plain",
      },
      body: data,
    })
    const result = await this.handleResponse<T>(apiURL, "POST", response)
    if (result.length == 1) {
      return Promise.resolve(result[0])
    }
    return Promise.reject({
      status: "Result did not have a single value but " + result.length,
    })
  }

  async deleteFile(fileOperationUrl: string): Promise<string[]> {
    return super.typedDeleteV2(fileOperationUrl, 2)
  }

  async getFichiers(idProjetAnnuel: string, type: TypeFichier): Promise<FichierDTO[]> {
    return super.typedGetV2<FichierDTO>("/projets/" + idProjetAnnuel + "/fichiers?type=" + type, 2)
  }

  async getFichier(idProjetAnnuel: string, idFichier: string): Promise<FichierDTO> {
    return super.typedGetSingleV2<FichierDTO>(`/projets/${idProjetAnnuel}/fichiers/${idFichier}`, 2)
  }
  async uploadFichier(idProjetAnnuel: string, type: TypeFichier, file: File): Promise<string> {
    // Function to generate a random string
    const generateRandomString = (length = 6) => {
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
      let result = ""
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length))
      }
      return result
    }

    const converted = await ConversionUtils.convertFileToBase64String(file)

    // Append a random string to the file name
    const uniqueFileName = `${file.name
      .split(".")
      .slice(0, -1)
      .join(".")}_${generateRandomString()}.${file.name.split(".").pop()}`
    console.log(uniqueFileName)
    const path = `/projets/${idProjetAnnuel}/fichiers`
    const params = `?type=${type}&nom=${encodeURIComponent(uniqueFileName)}`

    return this.sendFile(path + params, converted)
  }

  async deleteFichier(idProjetAnnuel: string, idFichier: string): Promise<string[]> {
    return super.typedDeleteV2("/projets/" + idProjetAnnuel + "/fichiers/" + idFichier, 2)
  }

  buildUniqueFileURL(idProjetAnnuel: string, typeFichier: TypeFichier): string {
    return "/projets/" + idProjetAnnuel + "/fichier-unique/" + typeFichier
  }
}
