

































































import { ProjetInscriptionDTO } from "@/model/bean/GeneratedDTOs"
import { Component, Prop, Vue } from "vue-property-decorator"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import RadioWithInputAndValidation from "@/views/inputs/RadioWithInputAndValidation.vue"
import ProjetAutresInfosView from "@/pages/projet-page/info-administratives/ProjetAutresInfosView.vue"

@Component({
  components: {
    InputWithValidation,
    RadioWithInputAndValidation,
    ProjetAutresInfosView,
  },
})
export default class ProjetInscriptionVueEnsemble extends Vue {
  @Prop() projetAnnuel: ProjetInscriptionDTO
  @Prop({ default: false }) readonly: boolean
  @Prop({ default: true }) showValidationErrors: boolean

  booleanValue = [true, false]
  booleanValueLabels = this.booleanValue.map((value) => {
    return this.$t(`${value}`).toString()
  })
}
