










import { Component, Prop, Vue } from "vue-property-decorator"
import PageView from "@/views/commons/PageView.vue"
import ContentView from "@/views/commons/ContentView.vue"
import GraeDetailsView from "@/views/grae/GraeDetailsView.vue"
import GraeDatesView from "@/views/grae/GraeDatesView.vue"
import IndicateursView from "./IndicateursView.vue"

import { GraeDTO, GraeStatsDTO, UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import { LoginService } from "@/services/rest/login/LoginService"
import { UsersService } from "@/services/rest/users/UsersService"
import { OrganismeService } from "@/services/rest/organisme/OrganismeService"
import { BackToPreviousScreenItem, ContextMenu } from "@/views/menus/MenuItems"

@Component({
  components: {
    PageView,
    ContentView,
    GraeDetailsView,
    GraeDatesView,
    IndicateursView,
  },
})
export default class IndicateursPage extends Vue {
  @Prop() graeId?: string

  private loginService = LoginService.INSTANCE
  private usersService = UsersService.INSTANCE
  private organismeService = OrganismeService.INSTANCE

  user = new UtilisateurDTO()
  grae = new GraeDTO()
  graeStats = new GraeStatsDTO()
  contact = new UtilisateurDTO()

  title = ""
  subtitle = this.$t("indicateursPage.title")

  dasharray = "100 0"

  brouillonFilters = JSON.stringify([
    { property: "typeDossier", values: ["DOSSIER_INSCRIPTION"] },
    { property: "statut", values: ["BROUILLON"] },
  ])

  async created(): Promise<void> {
    const loggedUser = this.loginService.getLoggedUser()

    if (!loggedUser || !loggedUser.id) {
      return
    }
    if (!this.graeId) {
      await this.getGraeFromLoggedUser(loggedUser.id)
    } else {
      await this.getGraeFromId(loggedUser.id, this.graeId)
    }
  }

  async getGraeFromLoggedUser(id: string): Promise<void> {
    this.user = await this.usersService.getUserProfile(id)
    if (this.user && this.user.grae) {
      this.grae = this.user.grae as GraeDTO
    }
    this.refreshContextMenu(false)
    this.title = this.$t("indicateursPage.subTitle").toString() + this.grae.region
  }

  async getGraeFromId(loggedUserId: string, graedId: string): Promise<void> {
    this.user = await this.usersService.getUserProfile(loggedUserId)
    this.grae = await this.organismeService.getGrae(graedId)
    const isFromGrae = this.user.grae?.id == this.grae.id
    this.refreshContextMenu(!isFromGrae)
    this.title = this.$t("indicateursPage.subTitle").toString() + this.grae.region
  }

  refreshContextMenu(showBackToListMenuEntry: boolean): void {
    const menuItems = []
    if (this.grae) {
      //const graeId = this.grae.id
      // Back to previous screen (if comming from list)
      if (showBackToListMenuEntry) {
        menuItems.push(new BackToPreviousScreenItem())
      }

      const possibleMenuParents = ["/grae-list", "/grae/details/"]
      this.$root.$emit("contextual-menu-changed", new ContextMenu(possibleMenuParents, menuItems))
    }
  }
}
