









































































import { ProjetAnnuelDTO } from "@/model/bean/GeneratedDTOs"
import { Component, Prop, Vue } from "vue-property-decorator"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import SelectWithValidation from "@/views/inputs/SelectWithValidation.vue"
import DocumentationLinks from "@/views/commons/DocumentationLinks.vue"
import { TEMPS_PASSE } from "@/model/bean/EnumHelper"

@Component({
  components: {
    DocumentationLinks,
    InputWithValidation,
    SelectWithValidation,
  },
})
export default class PlusView extends Vue {
  @Prop() projetAnnuel: ProjetAnnuelDTO
  @Prop() readOnly: boolean
  @Prop({ default: true }) validationActivated: boolean
  @Prop({ default: true }) showValidationErrors: boolean

  tempsPasseProjet = Object.values(TEMPS_PASSE)
  tempsPasseProjetLabels = this.tempsPasseProjet.map((value) => {
    return this.$t(`projetPage.labellisation.plus.temps-passe-projet-labels.${value}`).toString()
  })

  modified(): void {
    this.$emit("dirtify-project")
    this.$emit("trigger-validation")
  }
}
