var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      staticClass: "ProfileModale",
      attrs: {
        active: _vm.open,
        "has-modal-card": "",
        "trap-focus": "",
        "aria-role": "dialog",
        "aria-label": "ProfileModale",
        "aria-modal": "",
        width: "100vw"
      },
      on: { cancel: _vm.emitCloseModale }
    },
    [
      [
        _c("div", { staticClass: "modal-card" }, [
          _c(
            "header",
            { staticClass: "modal-card-head" },
            [
              _vm.isCurrentUserProfile
                ? _c("H3TitleView", { staticClass: "modal-card-title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("profilePage.myProfile-management").toString()
                      )
                    )
                  ])
                : _c("H3TitleView", { staticClass: "modal-card-title" }, [
                    _vm._v(
                      _vm._s(
                        _vm
                          .$t("profilePage.title", [
                            _vm.user.nom,
                            _vm.user.prenom
                          ])
                          .toString()
                      )
                    )
                  ])
            ],
            1
          ),
          _c(
            "section",
            { staticClass: "modal-card-body" },
            [
              _c(
                "b-tabs",
                {
                  staticClass: "ProfileModale-tabs",
                  attrs: { type: "is-toggle", animated: false },
                  model: {
                    value: _vm.currentTabIndex,
                    callback: function($$v) {
                      _vm.currentTabIndex = $$v
                    },
                    expression: "currentTabIndex"
                  }
                },
                [
                  _c(
                    "b-tab-item",
                    { attrs: { label: _vm.$t("profilePage.identity") } },
                    [
                      _c("UserInfoDetails", {
                        ref: "userInfoDetails",
                        staticClass: "title-without-margin",
                        attrs: {
                          user: _vm.user,
                          readonly: !_vm.canUserEditProfil
                        },
                        on: { "close-modale": _vm.emitCloseModale }
                      })
                    ],
                    1
                  ),
                  _vm.isCurrentUserProfile || _vm.canUserChangeOtherPwd
                    ? _c(
                        "b-tab-item",
                        { attrs: { label: _vm.$t("input-password") } },
                        [
                          _c("PasswordReset", {
                            ref: "resetPwd",
                            staticClass: "title-without-margin",
                            attrs: {
                              withOldPwd: _vm.isCurrentUserProfile,
                              user: _vm.user
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isCurrentUserProfile
                    ? _c(
                        "b-tab-item",
                        { attrs: { label: _vm.$t("profilePage.delete-info") } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "ProfileModale-delete-warning mb-4"
                            },
                            [
                              _c("b-icon", {
                                staticClass: "mr-2",
                                attrs: {
                                  size: "is-large",
                                  icon: "times-circle",
                                  type: "is-danger"
                                }
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("profilePage.delete-warning")) +
                                  " "
                              )
                            ],
                            1
                          ),
                          _vm.doDeleteProfil
                            ? _c(
                                "div",
                                [
                                  _c("InputWithValidation", {
                                    attrs: {
                                      rules: "required",
                                      type: "password",
                                      passwordReveal: "",
                                      placeholder: _vm.$t(
                                        "input-password-tooltip"
                                      ),
                                      label: _vm.$t(
                                        "profilePage.delete-account-confirmation"
                                      ),
                                      vid: "password"
                                    },
                                    model: {
                                      value: _vm.password,
                                      callback: function($$v) {
                                        _vm.password = $$v
                                      },
                                      expression: "password"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c("footer", { staticClass: "modal-card-foot" }, [
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _c(
                  "div",
                  {
                    staticClass: "hoverable-discrete-link mr-3",
                    on: { click: _vm.emitCloseModale }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("close-generic")) + " ")]
                ),
                _vm.canUserEditProfil &&
                _vm.currentTabIndex == "2" &&
                !_vm.doDeleteProfil
                  ? _c("b-button", {
                      attrs: {
                        label: _vm.$t("profilePage.delete").toString(),
                        type: "is-danger",
                        rounded: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.doDeleteProfil = true
                        }
                      }
                    })
                  : _vm.canUserEditProfil &&
                    _vm.currentTabIndex == "2" &&
                    _vm.doDeleteProfil
                  ? _c("b-button", {
                      attrs: {
                        label: _vm
                          .$t("profilePage.delete-account-confirm")
                          .toString(),
                        type: "is-danger",
                        rounded: ""
                      },
                      on: { click: _vm.deleteUserAccount }
                    })
                  : _vm.canUserEditProfil
                  ? _c(
                      "b-button",
                      {
                        attrs: { rounded: "", type: "is-primary" },
                        on: { click: _vm.saveAndClose }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("profilePage.save")) + " ")]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ])
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }