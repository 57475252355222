var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          staticClass: "feeback-button",
          attrs: { title: _vm.$t("feedbackModal.button") },
          on: { click: _vm.openFeedback }
        },
        [
          _c(
            "b-tooltip",
            {
              attrs: {
                label: _vm.$t("feedbackModal.button"),
                type: "is-black",
                position: "is-right"
              }
            },
            [_c("b-icon", { attrs: { icon: "question" } })],
            1
          )
        ],
        1
      ),
      _c("ValidationObserver", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var handleSubmit = ref.handleSubmit
              return [
                _c(
                  "b-modal",
                  {
                    staticClass: "FeedbackModal",
                    attrs: {
                      "has-modal-card": "",
                      "trap-focus": "",
                      "aria-role": "dialog",
                      "aria-label": "Feedback modal",
                      "aria-modal": "",
                      width: "100vw"
                    },
                    model: {
                      value: _vm.display,
                      callback: function($$v) {
                        _vm.display = $$v
                      },
                      expression: "display"
                    }
                  },
                  [
                    [
                      _c("div", { staticClass: "modal-card" }, [
                        _c(
                          "header",
                          { staticClass: "modal-card-head" },
                          [
                            _c(
                              "H3TitleView",
                              { staticClass: "modal-card-title" },
                              [_vm._v(_vm._s(_vm.$t("feedbackModal.title")))]
                            )
                          ],
                          1
                        ),
                        _c(
                          "section",
                          { staticClass: "modal-card-body" },
                          [
                            _c(
                              "b-tabs",
                              {
                                staticClass: "FeedbackModal-tabs",
                                attrs: { type: "is-toggle", animated: false },
                                model: {
                                  value: _vm.currentTabIndex,
                                  callback: function($$v) {
                                    _vm.currentTabIndex = $$v
                                  },
                                  expression: "currentTabIndex"
                                }
                              },
                              [
                                _c("b-tab-item", {
                                  attrs: { label: _vm.$t("feedbackModal.help") }
                                }),
                                _c(
                                  "b-tab-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("feedbackModal.support")
                                    }
                                  },
                                  [
                                    _c(
                                      "b-message",
                                      { attrs: { type: "is-info" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "feedbackModal.feedback-guide"
                                              )
                                            ) +
                                            " "
                                        ),
                                        _vm.model.userId
                                          ? _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "feedbackModal.account-modification-guide"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "a",
                                                {
                                                  on: {
                                                    click:
                                                      _vm.showPersonnalAccount
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("profile"))
                                                  )
                                                ]
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    ),
                                    _c("InputWithValidation", {
                                      attrs: {
                                        rules: "required",
                                        label: _vm.$t(
                                          "feedbackModal.user-name"
                                        ),
                                        vid: "userFullName",
                                        disabled: _vm.model.userId
                                      },
                                      model: {
                                        value: _vm.model.userFullName,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "userFullName",
                                            $$v
                                          )
                                        },
                                        expression: "model.userFullName"
                                      }
                                    }),
                                    _c("InputWithValidation", {
                                      attrs: {
                                        rules: "required|email",
                                        label: _vm.$t(
                                          "profilePage.saisir-courriel"
                                        ),
                                        vid: "email",
                                        disabled: _vm.model.userId
                                      },
                                      model: {
                                        value: _vm.model.email,
                                        callback: function($$v) {
                                          _vm.$set(_vm.model, "email", $$v)
                                        },
                                        expression: "model.email"
                                      }
                                    }),
                                    _c("InputWithValidation", {
                                      attrs: {
                                        rules: "validPhoneNumber",
                                        label: _vm.$t(
                                          "profilePage.saisir-telephone"
                                        ),
                                        vid: "phoneNumber"
                                      },
                                      model: {
                                        value: _vm.model.phone,
                                        callback: function($$v) {
                                          _vm.$set(_vm.model, "phone", $$v)
                                        },
                                        expression: "model.phone"
                                      }
                                    }),
                                    _c(
                                      "b-field",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "feedbackModal.categories.label"
                                          )
                                        }
                                      },
                                      _vm._l(_vm.categories, function(
                                        category
                                      ) {
                                        return _c(
                                          "b-radio",
                                          {
                                            key: category.id,
                                            attrs: {
                                              name: "category",
                                              "native-value": category.name
                                            },
                                            model: {
                                              value: _vm.model.category,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.model,
                                                  "category",
                                                  $$v
                                                )
                                              },
                                              expression: "model.category"
                                            }
                                          },
                                          [_vm._v(_vm._s(category.name))]
                                        )
                                      }),
                                      1
                                    ),
                                    _vm.model.category
                                      ? _c(
                                          "b-message",
                                          { attrs: { type: "is-info" } },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "feedback-doc" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "feedbackModal.consult-doc-before"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _c("PDFLinkWithModal", {
                                              staticClass: "feedback-doc",
                                              attrs: {
                                                fileName:
                                                  "SAGAE_Guide_utilisateur",
                                                linkText: _vm.$t(
                                                  "userDocumentation"
                                                )
                                              }
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "feedback-doc" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "feedbackModal.consult-doc-after"
                                                      )
                                                    )
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("InputWithValidation", {
                                      attrs: {
                                        label: _vm.$t("feedbackModal.subject"),
                                        vid: "subject"
                                      },
                                      model: {
                                        value: _vm.model.subject,
                                        callback: function($$v) {
                                          _vm.$set(_vm.model, "subject", $$v)
                                        },
                                        expression: "model.subject"
                                      }
                                    }),
                                    _c("InputWithValidation", {
                                      attrs: {
                                        rules: "required",
                                        type: "textarea",
                                        vid: "description",
                                        label: _vm.$t(
                                          "feedbackModal.issue-description"
                                        )
                                      },
                                      model: {
                                        value: _vm.model.description,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "description",
                                            $$v
                                          )
                                        },
                                        expression: "model.description"
                                      }
                                    }),
                                    _c(
                                      "b-checkbox",
                                      {
                                        model: {
                                          value: _vm.withPicture,
                                          callback: function($$v) {
                                            _vm.withPicture = $$v
                                          },
                                          expression: "withPicture"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "feedbackModal.include-screenshot"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "footer",
                          { staticClass: "modal-card-foot" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "hoverable-discrete-link mr-3",
                                on: { click: _vm.closeFeedback }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("close-generic")) + " "
                                )
                              ]
                            ),
                            _vm.currentTabIndex === 1
                              ? _c("b-button", {
                                  attrs: {
                                    label: _vm.$t("feedbackModal.send"),
                                    type: "is-primary"
                                  },
                                  on: {
                                    click: function($event) {
                                      return handleSubmit(_vm.sendClicked)
                                    }
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    ]
                  ],
                  2
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }