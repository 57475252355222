var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "SingleEtablissement" }, [
    _c("div", [
      _c("span", { staticClass: "etablissement-nom" }, [
        _vm._v(_vm._s(_vm.etablissement.nom + " "))
      ]),
      _c("span", { staticClass: "etablissement-infos" }, [
        _vm._v(
          _vm._s(_vm.$t("registerPage.uai")) +
            " " +
            _vm._s(_vm.etablissement.codeEtablissement)
        )
      ])
    ]),
    _c("div", { staticClass: "etablissement-infos" }, [
      _vm._v(
        " " +
          _vm._s(
            _vm.etablissement.rue +
              " " +
              _vm.etablissement.codePostal +
              " " +
              _vm.etablissement.ville
          ) +
          " "
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }