

























import { Component, Prop, Vue } from "vue-property-decorator"

@Component({})
export default class LoadingSkeleton extends Vue {
  @Prop() loadingText: string
}
