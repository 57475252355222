

















import { Component, Vue } from "vue-property-decorator"
import { ValidationObserver } from "vee-validate"
import { UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import PosteDetails from "@/views/commons/PosteDetails.vue"

@Component({
  components: {
    ValidationObserver,
    PosteDetails,
  },
})
export default class AccompagnateurRegionalInformationsView extends Vue {
  innerUtilisateur = new UtilisateurDTO()

  created(): void {
    this.innerUtilisateur.profil = "ACCOMPAGNATEUR_REGIONAL"
  }

  changePoste(changedUser: UtilisateurDTO): void {
    this.innerUtilisateur.fonction = changedUser.fonction
    this.innerUtilisateur.employeur = changedUser.employeur
    this.innerUtilisateur.grae = changedUser.grae
  }

  submit(): void {
    this.$emit("input-region", this.innerUtilisateur)
  }

  goBack(): void {
    this.$emit("go-back", this.innerUtilisateur)
  }
}
