var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("ContactModale", {
        attrs: {
          open: _vm.openContactModale,
          total: _vm.total,
          graeId: _vm.graeId,
          filters: _vm.activeFilters
        },
        on: {
          "close-modale": function($event) {
            _vm.openContactModale = false
          }
        }
      }),
      !_vm.graeId &&
      _vm.hasRightsToDeleteAllInscriptionsInBrouillon() &&
      _vm.nbBrouillons > 0
        ? _c(
            "div",
            [
              !_vm.isDeletingBrouillons
                ? _c(
                    "b-button",
                    {
                      staticClass: "button is-danger",
                      attrs: { outlined: "" },
                      on: { click: _vm.deleteAllInscriptionsInBrouillon }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "graePage.details.projets.delete-brouillon",
                              [_vm.nbBrouillons]
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e(),
              _vm.isDeletingBrouillons
                ? _c("b-message", { attrs: { type: "is-warning" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("graePage.details.projets.deleting-brouillon")
                        ) +
                        " "
                    )
                  ])
                : _vm._e(),
              _c("br"),
              _c("br")
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "year-navigation" },
        [
          _c(
            "b-button",
            {
              staticClass: "button is-primary",
              attrs: {
                disabled:
                  _vm.selectedAnneeScolaire ===
                  _vm.anneesScolaires[_vm.anneesScolaires.length - 1],
                outlined: ""
              },
              on: { click: _vm.previousYear }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("graePage.details.projets.precedent")) + " "
              )
            ]
          ),
          _c(
            "b-select",
            {
              staticClass: "fullwidth",
              on: {
                input: function($event) {
                  return _vm.goToYear()
                }
              },
              model: {
                value: _vm.selectedAnneeScolaire,
                callback: function($$v) {
                  _vm.selectedAnneeScolaire = $$v
                },
                expression: "selectedAnneeScolaire"
              }
            },
            _vm._l(_vm.anneesScolaires, function(option) {
              return _c(
                "option",
                {
                  key: option,
                  domProps: {
                    value: option,
                    selected: option === _vm.selectedAnneeScolaire
                  }
                },
                [_vm._v(" " + _vm._s(option) + " ")]
              )
            }),
            0
          ),
          _c(
            "b-button",
            {
              staticClass: "button is-primary",
              attrs: {
                disabled: _vm.selectedAnneeScolaire === _vm.anneesScolaires[0],
                outlined: ""
              },
              on: { click: _vm.nextYear }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("graePage.details.projets.suivant")) + " "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "TableFilterView",
            {
              attrs: {
                columns: _vm.columns,
                initialFilters: _vm.activeFilters,
                canExportCsv: false
              },
              on: {
                "update-active-filters": _vm.updateActiveFilters,
                "update-visible-columns": _vm.updateVisibleColumns,
                "export-csv": _vm.exportProjectsCsv,
                "export-xls": _vm.exportProjectsXls,
                "export-xls-all": _vm.exportProjectsXlsAll
              }
            },
            [
              !_vm.isObservateurNational
                ? _c(
                    "b-button",
                    {
                      attrs: { type: "is-primary" },
                      on: {
                        click: function($event) {
                          return _vm.concatContactsForProjects()
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("graePage.details.projets.contact-projets", [
                              _vm.total
                            ])
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-table",
            {
              ref: _vm.tableRef,
              attrs: {
                striped: _vm.config.isStriped,
                hoverable: _vm.config.isHoverable,
                "debounce-search": _vm.config.searchDebounce,
                "aria-next-label": _vm.config.nextLabel(),
                "aria-previous-label": _vm.config.previousLabel(),
                "aria-page-label": _vm.config.pageLabel(),
                "aria-current-label": _vm.config.currentPageLabel(),
                "per-page": _vm.config.perPage,
                "sticky-header": _vm.config.stickyHeader,
                "current-page": _vm.currentPagedSortedParameter.pageNumber,
                "pagination-position": _vm.config.paginationPosition,
                paginated: "",
                "backend-pagination": "",
                "sort-icon": _vm.config.sortIcon,
                "sort-icon-size": _vm.config.sortIconSize,
                "backend-sorting": "",
                "sort-multiple": "",
                "sort-multiple-data": _vm.buefySortingPriority,
                total: _vm.total,
                data: _vm.projectsList,
                loading: _vm.isLoading
              },
              on: {
                sort: _vm.onSort,
                "sorting-priority-removed": _vm.sortingPriorityRemoved,
                "page-change": _vm.onPageChange
              }
            },
            [
              _vm._l(_vm.columns, function(column) {
                return [
                  _c(
                    "b-table-column",
                    _vm._b(
                      {
                        key: column.field,
                        attrs: { visible: column.visible !== false },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(props) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "clickable",
                                      on: {
                                        click: function($event) {
                                          return _vm.showProjectDetailsPage(
                                            $event,
                                            props.row["projetId"],
                                            props.row["id"]
                                          )
                                        }
                                      }
                                    },
                                    [
                                      column.field === "etablissement.name"
                                        ? _c("div", [
                                            props.row["etablissements"]
                                              ? _c(
                                                  "div",
                                                  _vm._l(
                                                    props.row[
                                                      "etablissements"
                                                    ].filter(function(o) {
                                                      return o && o.id && o.nom
                                                    }),
                                                    function(organisme) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key:
                                                            props.row["id"] +
                                                            "_" +
                                                            organisme.id
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                organisme.nom
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e()
                                          ])
                                        : _vm._e(),
                                      column.field ===
                                      "etablissement.codeEtablissement"
                                        ? _c("div", [
                                            props.row["etablissements"]
                                              ? _c(
                                                  "div",
                                                  _vm._l(
                                                    props.row[
                                                      "etablissements"
                                                    ].filter(function(o) {
                                                      return o && o.id && o.nom
                                                    }),
                                                    function(organisme) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key:
                                                            props.row["id"] +
                                                            "_" +
                                                            organisme.codeEtablissement
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                organisme.codeEtablissement
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e()
                                          ])
                                        : _vm._e(),
                                      column.field === "structure.name"
                                        ? _c("div", [
                                            props.row["structures"]
                                              ? _c(
                                                  "div",
                                                  _vm._l(
                                                    props.row[
                                                      "structures"
                                                    ].filter(function(o) {
                                                      return o && o.id && o.nom
                                                    }),
                                                    function(organisme) {
                                                      return _c(
                                                        "div",
                                                        { key: organisme.id },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                organisme.nom
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e()
                                          ])
                                        : _vm._e(),
                                      column.field === "projet.nom"
                                        ? _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(props.row["nom"]) +
                                                " "
                                            )
                                          ])
                                        : _vm._e(),
                                      column.field === "statut"
                                        ? _c(
                                            "div",
                                            [
                                              _c("StatutDossierTag", {
                                                attrs: {
                                                  dossier: props.row,
                                                  canSeeInstructionComment: "",
                                                  loggedUser: _vm.loggedUser
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      column.field === "projet.etat"
                                        ? _c("div", [
                                            props.row["etat"] != "ARCHIVE"
                                              ? _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          props.row["etat"]
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              : props.row["typeDossier"] ==
                                                "DOSSIER_INSCRIPTION"
                                              ? _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "projetsListPage.archive.etat"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              : _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "projetsListPage.arret.etat"
                                                      )
                                                    )
                                                  )
                                                ])
                                          ])
                                        : _vm._e(),
                                      column.field === "nature"
                                        ? _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  props.row[
                                                    "natureAireEducative"
                                                  ]
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _vm._e(),
                                      column.field === "grae.region"
                                        ? _c("div", [
                                            props.row["grae"]
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.row["grae"].region
                                                    )
                                                  )
                                                ])
                                              : _vm._e()
                                          ])
                                        : _vm._e(),
                                      column.field === "codeDepartement"
                                        ? _c("div", [
                                            props.row["etablissements"].length
                                              ? _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        props.row[
                                                          "etablissements"
                                                        ][0].codeDepartement.replace(
                                                          /^0+/,
                                                          ""
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              : _vm._e()
                                          ])
                                        : _vm._e(),
                                      column.field === "dateModification"
                                        ? _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.formatDate(
                                                    props.row[column.field]
                                                  )
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _vm._e(),
                                      column.field === "thematique"
                                        ? _c(
                                            "div",
                                            { staticClass: "clamp" },
                                            _vm._l(
                                              props.row[column.field],
                                              function(thematique, index) {
                                                return _c(
                                                  "span",
                                                  { key: thematique },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "instruction." +
                                                              thematique
                                                          )
                                                        )
                                                    ),
                                                    index !=
                                                    props.row[column.field]
                                                      .length -
                                                      1
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("virgule")
                                                            )
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                      column.field ===
                                      "objectsConcretRealisation"
                                        ? _c(
                                            "div",
                                            { staticClass: "clamp" },
                                            _vm._l(
                                              props.row[column.field],
                                              function(objet, index) {
                                                return _c(
                                                  "span",
                                                  { key: objet },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "instruction." +
                                                              objet
                                                          )
                                                        )
                                                    ),
                                                    index !=
                                                    props.row[column.field]
                                                      .length -
                                                      1
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("virgule")
                                                            )
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                      column.field === "milieuGenerique" &&
                                      props.row[column.field]
                                        ? _c("div", { staticClass: "clamp" }, [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "instruction." +
                                                      props.row[column.field]
                                                  )
                                                )
                                              )
                                            ])
                                          ])
                                        : _vm._e(),
                                      column.field ===
                                      "milieuInteretParticulier"
                                        ? _c(
                                            "div",
                                            { staticClass: "clamp" },
                                            _vm._l(
                                              props.row[column.field],
                                              function(milieu, index) {
                                                return _c(
                                                  "span",
                                                  { key: milieu },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "instruction." +
                                                              milieu
                                                          )
                                                        )
                                                    ),
                                                    index !=
                                                    props.row[column.field]
                                                      .length -
                                                      1
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("virgule")
                                                            )
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                      column.field === "ville"
                                        ? _c("div", [
                                            props.row["etablissements"].length
                                              ? _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        props.row[
                                                          "etablissements"
                                                        ][0].ville
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              : _vm._e()
                                          ])
                                        : _vm._e(),
                                      column.field === "enseignant.name"
                                        ? _c(
                                            "div",
                                            _vm._l(
                                              props.row["enseignants"].filter(
                                                function(u) {
                                                  return u && u.id && u.nom
                                                }
                                              ),
                                              function(utilisateur, i) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key:
                                                      i + "_" + utilisateur.id
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          utilisateur.prenom +
                                                            " " +
                                                            utilisateur.nom
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                      column.field === "referent.name"
                                        ? _c(
                                            "div",
                                            _vm._l(
                                              props.row["referents"].filter(
                                                function(u) {
                                                  return u && u.id && u.nom
                                                }
                                              ),
                                              function(utilisateur, i) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key:
                                                      i + "_" + utilisateur.id
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          utilisateur.prenom +
                                                            " " +
                                                            utilisateur.nom
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                      column.field === "action"
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "buttons" },
                                              [
                                                _c("b-button", {
                                                  attrs: {
                                                    "icon-left": "eye",
                                                    type: "is-link is-outlined",
                                                    title: _vm.$t(
                                                      "consult-projet"
                                                    ),
                                                    label: _vm.projetsService.getProjectConsultActionLabel(
                                                      props.row,
                                                      _vm.loggedUser
                                                    )
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.consult(
                                                        $event,
                                                        props.row["projetId"],
                                                        props.row["id"]
                                                      )
                                                    }
                                                  }
                                                }),
                                                _vm.canUnarchive(props.row) &&
                                                props.row["typeDossier"] ==
                                                  "DOSSIER_INSCRIPTION" &&
                                                _vm.hasRightsToPutInDraft()
                                                  ? _c("b-button", {
                                                      attrs: {
                                                        type:
                                                          "is-success is-outlined",
                                                        "icon-left": "save",
                                                        title: _vm.$t(
                                                          "projetsListPage.archive.undo-action"
                                                        ),
                                                        label: _vm.$t(
                                                          "projetsListPage.archive.undo-action-label"
                                                        )
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.unarchive(
                                                            $event,
                                                            props.row
                                                          )
                                                        }
                                                      }
                                                    })
                                                  : _vm._e(),
                                                _vm.canUnarchive(props.row) &&
                                                props.row["typeDossier"] !=
                                                  "DOSSIER_INSCRIPTION" &&
                                                _vm.hasRightsToPutInDraft()
                                                  ? _c("b-button", {
                                                      attrs: {
                                                        type:
                                                          "is-success is-outlined",
                                                        "icon-left": "save",
                                                        title: _vm.$t(
                                                          "projetsListPage.arret.undo-action"
                                                        ),
                                                        label: _vm.$t(
                                                          "projetsListPage.arret.undo-action-label"
                                                        )
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.unarchive(
                                                            $event,
                                                            props.row
                                                          )
                                                        }
                                                      }
                                                    })
                                                  : _vm._e(),
                                                _vm.canUnlock(props.row) &&
                                                _vm.isInstruction(props.row) &&
                                                _vm.hasRightsToPutInDraft()
                                                  ? _c("b-button", {
                                                      attrs: {
                                                        type:
                                                          "is-success is-outlined",
                                                        "icon-left": "save",
                                                        title: _vm.$t(
                                                          "projetsListPage.brouillon.undo-action"
                                                        ),
                                                        label: _vm.$t(
                                                          "projetsListPage.brouillon.undo-action-label"
                                                        )
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.unlock(
                                                            $event,
                                                            props.row
                                                          )
                                                        }
                                                      }
                                                    })
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ])
                                        : _vm._e(),
                                      column.field === "typeDossier"
                                        ? _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(props.row.typeDossier)
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _vm._e(),
                                      !column.custom
                                        ? _c("div", [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(props.row[column.field])
                                              )
                                            ])
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      "b-table-column",
                      column,
                      false
                    )
                  )
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }