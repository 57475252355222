var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tabs-dropdown-selector" },
    [
      _c("b-icon", {
        class: { hidden: _vm.activeTab < 1 },
        attrs: { type: _vm.arrowType, icon: "angle-left", size: "is-large" },
        nativeOn: {
          click: function($event) {
            return _vm.changeTab(_vm.activeTab - 1)
          }
        }
      }),
      _c(
        "b-dropdown",
        { attrs: { disabled: _vm.disabled } },
        [
          _c(
            "b-button",
            {
              staticClass: "button",
              attrs: { slot: "trigger", "icon-right": "angle-down" },
              slot: "trigger"
            },
            [
              _c(
                "span",
                {
                  staticClass: "dropdown-entry",
                  style: { "max-width": _vm.currentContainerWidth - 270 + "px" }
                },
                [_vm._v(" " + _vm._s(_vm.tabs[_vm.activeTab]))]
              )
            ]
          ),
          _vm._l(_vm.tabs, function(tab, index) {
            return _c(
              "b-dropdown-item",
              {
                key: tab,
                on: {
                  click: function($event) {
                    return _vm.changeTab(index)
                  }
                }
              },
              [_c("span", [_vm._v(" " + _vm._s(tab))])]
            )
          })
        ],
        2
      ),
      _c("b-icon", {
        class: { hidden: _vm.activeTab >= _vm.tabs.length - 1 },
        attrs: { type: _vm.arrowType, icon: "angle-right", size: "is-large" },
        nativeOn: {
          click: function($event) {
            return _vm.changeTab(_vm.activeTab + 1)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }