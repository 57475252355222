/*-
 * #%L
 * Aires Éducatives
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
import { FilterDTO } from "./GeneratedDTOs"

export class Filters {
  /**
   * Converts a list of filters defined as objects to a list of typed filters
   *
   * Example use:
   *
   * ```
   * Filters.asFilters([
   *   {property: "nature", values: ["AME"]}
   *   {property: "etat", values: ["ACTIF"]}
   * ])
   * ```
   *
   * @param filtersAsObject filters defined as objects
   * @returns properly typed filters
   */
  static asFilters(filtersAsObject: Array<{ property: string; values: string[] }>): FilterDTO[] {
    const ret = new Array<FilterDTO>()
    for (const filter of filtersAsObject) {
      ret.push(this.asFilter(filter))
    }

    return ret
  }

  /**
   * Converts a filter defined as object to a typed filters
   *
   * Example use:
   *
   * ```
   * Filters.asFilter(
   *   {property: "nature", values: ["AME"]}
   * )
   * ```
   *
   * @param filterAsObject filter defined as object
   * @returns properly typed filter
   */
  static asFilter(filterAsObject: { property: string; values: string[] }): FilterDTO {
    const filterObj = new FilterDTO()
    filterObj.property = filterAsObject.property
    filterObj.values = filterAsObject.values
    return filterObj
  }
}
