var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm._v(" " + _vm._s(_vm.label) + " "),
      _vm.tabValidationHint.errorsCount
        ? _c(
            "b-tag",
            { attrs: { rounded: "", type: _vm.tabValidationHint.type } },
            [_vm._v(" " + _vm._s(_vm.tabValidationHint.errorsCount) + " ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }