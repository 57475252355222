







































































































































import { Component, Vue } from "vue-property-decorator"
import TitleView from "@/views/commons/TitleView.vue"
import PageView from "@/views/commons/PageView.vue"
import ContentView from "@/views/commons/ContentView.vue"
import { LoginService } from "@/services/rest/login/LoginService"
import { ProjectService } from "@/services/rest/project/ProjectService"
import FirstConnectionView from "@/views/projects_pp/FirstConnectionView.vue"
import H3TitleView from "@/views/commons/H3TitleView.vue"
import {
  EtablissementDTO,
  EtatProjet,
  FilterDTO,
  ParametrageDTO,
  ProjetAnnuelDTO,
  UtilisateurDTO,
} from "@/model/bean/GeneratedDTOs"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import PendingProjectCard from "@/views/projects_pp/PendingProjectCard.vue"
import { EnumHelper, TAG_TYPES_FOR_BOOKMARK_CARD } from "@/model/bean/EnumHelper"
import BookmarkCard from "@/views/generic_components/BookmarkCard.vue"
import FilterComponent, { FilterField } from "@/views/generic_components/FilterComponent.vue"
import ProjectCard from "@/views/projects_pp/ProjectCard.vue"
import { ParametersService } from "@/services/rest/parameters/ParametersService"
import TabParamsStore from "@/services/storage/TabParamsStore"
import SubHeaderItem from "@/views/commons/SubHeaderItem.vue"
import { format } from "date-fns"
import * as frLocale from "date-fns/locale/fr"
import RouterUtils from "@/router/RouterUtils"
import LoadingSkeleton from "@/views/generic_components/LoadingSkeleton.vue"
import ActualitesForPorteursProjetView from "../projet-page/en-bref/ActualitesForPorteursProjetView.vue"
import { UsersService } from "@/services/rest/users/UsersService"

const DATE_FORMAT = "D MMMM YYYY"

@Component({
  components: {
    TitleView,
    PageView,
    ContentView,
    FirstConnectionView,
    H3TitleView,
    PendingProjectCard,
    BookmarkCard,
    FilterComponent,
    ProjectCard,
    SubHeaderItem,
    LoadingSkeleton,
    ActualitesForPorteursProjetView,
  },
})
export default class MyProjectsPage extends Vue {
  private loginService = LoginService.INSTANCE
  private projectService = ProjectService.INSTANCE
  private infoReportingService = InfoReportingService.INSTANCE
  private usersService = UsersService.INSTANCE
  private parametersService = ParametersService.INSTANCE

  openFirstConnectionModal = false
  pendingProjects: ProjetAnnuelDTO[] = []
  projectsInEtablissement: ProjetAnnuelDTO[] = []
  myProjects: ProjetAnnuelDTO[] = []
  isLoadingData = true
  loggedUser: UtilisateurDTO = new UtilisateurDTO()
  chosenEtablissement: EtablissementDTO = new EtablissementDTO()
  tagTypes = TAG_TYPES_FOR_BOOKMARK_CARD
  filterFields: FilterField[] = []
  projectsNotParticipatingIn: ProjetAnnuelDTO[] = []
  parametrage = new ParametrageDTO()
  showCreateLink = false
  showLabellisationDates = false
  isPorteurProjet = false

  // Filters
  activeFilters = new Array<FilterDTO>()

  created(): void {
    const loggedUser = this.loginService.getLoggedUser()
    if (!loggedUser) {
      return
    }
    this.loggedUser = loggedUser
    this.activeFilters = TabParamsStore.INSTANCE.getLastFilters(this.$router.currentRoute.path)
    this.init()
  }

  async init(): Promise<void> {
    try {
      this.isLoadingData = true
      this.loadProjects()
      this.pendingProjects = await this.projectService.getPendingInvitationsToProject()
      this.isPorteurProjet = this.usersService.isPorteurProjet(this.loggedUser)
      const firstConnection = !this.loggedUser.lastConnection

      if (this.loggedUser.etablissement && firstConnection) {
        this.projectsInEtablissement = await this.projectService.getEtablissementProjects(
          this.loggedUser.etablissement
        )
        this.projectsNotParticipatingIn = this.projectsInEtablissement.filter(
          (p) => !p.enseignants.some((e) => e.id === this.loggedUser.id)
        )
      }

      this.openFirstConnectionModal =
        firstConnection &&
        !this.loginService.haveUserBeenLoggedIn() &&
        (this.pendingProjects.length !== 0 || this.projectsNotParticipatingIn.length !== 0)

      this.loadParameters(this.loggedUser)
    } catch (e) {
      this.infoReportingService.error(
        this.$t("myProjects.retrieve-project-error").toString(),
        e as Error
      )
    }
    this.isLoadingData = false
    this.createFilterFields()
  }

  createFilterFields(): void {
    const newFFEtablissementName = new FilterField()
    newFFEtablissementName.field = "etablissement.nom"
    newFFEtablissementName.label = this.$t("myProjects.filter-labels.etablissement").toString()
    this.filterFields.push(newFFEtablissementName)

    const newFFname = new FilterField()
    newFFname.field = "projet.nom"
    newFFname.label = this.$t("myProjects.filter-labels.nom").toString()
    this.filterFields.push(newFFname)

    const newFFProjectStatus = new FilterField()
    newFFProjectStatus.field = "projet.etat"
    newFFProjectStatus.label = this.$t("myProjects.filter-labels.etats").toString()
    newFFProjectStatus.possibleSearchValues = EnumHelper.allEtatsProjets
    this.filterFields.push(newFFProjectStatus)
  }

  updateFilters(filters: FilterDTO[]): void {
    this.activeFilters = filters
    TabParamsStore.INSTANCE.storeFilters(this.$router.currentRoute.path, filters)
    this.loadProjects()
  }

  closeFirstConnectionModal(): void {
    this.openFirstConnectionModal = false
    this.loginService.setUserHaveBeenLoggedIn()
    this.loadProjects()
  }

  async loadProjects(): Promise<void> {
    try {
      this.isLoadingData = true
      this.myProjects = await this.projectService.getProjetsForPorteurProjet(
        this.loggedUser,
        this.activeFilters
      )
      this.showLabellisationDates =
        this.myProjects.filter((p) => p.typeDossier != "DOSSIER_INSCRIPTION").length > 0
    } catch (e) {
      this.infoReportingService.error(
        this.$t("myProjects.retrieve-project-error").toString(),
        e as Error
      )
    }
    this.isLoadingData = false
  }

  async loadParameters(loggedUser?: UtilisateurDTO): Promise<void> {
    await this.parametersService.getParametrage(loggedUser?.grae?.id).then((params) => {
      this.parametrage = params
      const today = new Date()
      this.showCreateLink = params.debutInscription < today && today < params.finInscription
    })
  }

  async invitationProcessed(): Promise<void> {
    this.loadProjects()
    this.pendingProjects = await this.projectService.getPendingInvitationsToProject()
  }

  async sendAnswers(closeModal: boolean): Promise<void> {
    this.isLoadingData = true
    await this.projectService.sendAnswersAboutPendingInvitations(
      this.pendingProjects,
      this.loggedUser
    )
    if (closeModal) {
      this.closeFirstConnectionModal()
      // refresh pending projects
      this.pendingProjects = await this.projectService.getPendingInvitationsToProject()
    }
    this.isLoadingData = false
  }

  async updateProjectsInEtablissement(): Promise<void> {
    this.isLoadingData = true
    await this.projectService.sendAnswersAboutPendingInvitations(
      this.projectsInEtablissement,
      this.loggedUser
    )
    this.isLoadingData = false
    this.closeFirstConnectionModal()
    // refresh pending projects
    this.pendingProjects = await this.projectService.getPendingInvitationsToProject()
  }

  async createProject(): Promise<void> {
    let route = "myProjects/new"
    if (this.loggedUser.profil === "ENSEIGNANT" && this.loggedUser.etablissement) {
      this.chosenEtablissement = this.loggedUser.etablissement
      this.projectsInEtablissement = await this.projectService.getEtablissementProjects(
        this.chosenEtablissement
      )
      if (this.projectsInEtablissement.length === 0) {
        route = `myProjects/new/${this.loggedUser.etablissement.codeEtablissement}`
      } else {
        const projectsNotParticipatingIn = this.projectsInEtablissement.filter(
          (p) => !p.enseignants.some((e) => e.id === this.loggedUser.id)
        )

        if (projectsNotParticipatingIn.length === 0) {
          route = `myProjects/new/${this.chosenEtablissement.codeEtablissement}/ask`
        } else {
          route = `myProjects/new/${this.chosenEtablissement.codeEtablissement}/join`
        }
      }
    }
    this.$router.push(route)
  }

  async setChosenEtablissement(etablissement: EtablissementDTO): Promise<void> {
    this.chosenEtablissement = etablissement
    this.projectsInEtablissement = await this.projectService.getEtablissementProjects(
      this.chosenEtablissement
    )
    if (this.projectsInEtablissement.length === 0) {
      this.$router.push(`/myProjects/new/${etablissement.codeEtablissement}`)
    } else {
      this.$router.push(`/myProjects/new/${etablissement.codeEtablissement}/join`)
    }
  }

  closeProjectCreation(): void {
    this.chosenEtablissement = new EtablissementDTO()
    this.$router.push("/myProjects")
  }

  async endProjectCreation(projectName: string): Promise<void> {
    const newProject = this.getProject("ACTIF", projectName, "")

    try {
      this.isLoadingData = true
      const projetAnnuel = await this.projectService.createProject(newProject)
      this.chosenEtablissement = new EtablissementDTO()
      this.closeProjectCreation()
      await this.loadProjects()
      if (projetAnnuel && projetAnnuel.projetId && projetAnnuel.id) {
        this.$router.push(
          RouterUtils.getRouteForProjectDetails(projetAnnuel.projetId, projetAnnuel.id)
        )
      }
    } catch (e) {
      this.infoReportingService.error(
        this.$t("myProjects.create-project-error").toString(),
        e as Error
      )
    }
  }

  async confirmJoinedProjects(): Promise<void> {
    await this.projectService.sendAnswersAboutPendingInvitations(
      this.projectsInEtablissement,
      this.loggedUser
    )
    this.closeProjectCreation()
    this.loadProjects()
  }

  async askForProjectCreation(projectName: string, projectDescription: string): Promise<void> {
    const newProject = this.getProject("ACTIF", projectName, projectDescription)

    try {
      this.isLoadingData = true
      const projetAnnuel = await this.projectService.createProjectDemande(newProject)

      this.closeProjectCreation()
      await this.loadProjects()
      if (projetAnnuel && projetAnnuel.projetId && projetAnnuel.id) {
        this.$router.push(
          RouterUtils.getRouteForProjectDetails(projetAnnuel.projetId, projetAnnuel.id)
        )
      }
    } catch (e) {
      this.infoReportingService.error(
        this.$t("myProjects.create-demande-error").toString(),
        e as Error
      )
    }
    this.isLoadingData = false
  }

  getProject(etat: EtatProjet, projectName: string, projectDescription: string): ProjetAnnuelDTO {
    const newProject = new ProjetAnnuelDTO()
    newProject.nom = projectName
    newProject.etablissements = [this.chosenEtablissement]
    newProject.statut = "BROUILLON"
    newProject.etat = etat
    newProject.pitch = projectDescription || ""
    if (this.loggedUser.profil === "REFERENT") {
      if (this.loggedUser.structure) {
        newProject.structures = [this.loggedUser.structure]
      }
      newProject.referents = [this.loggedUser]
    } else if (this.loggedUser.profil === "ENSEIGNANT") {
      if (this.loggedUser.etablissement) {
        newProject.etablissements = [this.loggedUser.etablissement]
        newProject.enseignants = [this.loggedUser]
      }
    }
    return newProject
  }

  formatDate(date: Date): string {
    return date ? format(date, DATE_FORMAT, { locale: frLocale }) : ""
  }
}
