
















































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import LabellisationRecap from "@/pages/projet-page/labellisation/LabellisationRecap.vue"
import LabellisationResult from "@/pages/projet-page/labellisation/LabellisationResult.vue"
import ProjetCharteView from "@/pages/projet-page/info-administratives/ProjetCharteView.vue"
import ProjetParticipants from "@/pages/projet-page/info-administratives/ProjetParticipants.vue"
import ProjetSiteView from "@/pages/projet-page/info-administratives/ProjetSiteView.vue"
import ProjetFinancementView from "@/pages/projet-page/info-administratives/ProjetFinancementView.vue"
import ProjetAutresInfosView from "@/pages/projet-page/info-administratives/ProjetAutresInfosView.vue"
import ProjetDiffusionView from "@/pages/projet-page/mon-annee/ProjetDiffusionView.vue"
import PlusView from "@/pages/projet-page/labellisation/PlusView.vue"
import SuiviView from "@/pages/projet-page/labellisation/SuiviView.vue"
import ProjectValidationAndSaveHandler, {
  ProjetAnnualValidation,
} from "@/pages/projet-page/commons/ProjectValidationAndSaveHandler.vue"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import { ProjetAnnuelDTO, StatutDossier } from "@/model/bean/GeneratedDTOs"
import { ValidationStatus } from "@/model/ValidationResult"
import {
  BackToPreviousScreenItem,
  NavbarItemWithoutLink,
  ContextMenu,
  NavbarItem,
  BulletWithValidation,
} from "@/views/menus/MenuItems"
import EnBrefView from "@/pages/projet-page/en-bref/EnBrefView.vue"
import ProblematiquesOrientationsActionsView from "@/pages/projet-page/mon-annee/ProblematiquesOrientationsActionsView.vue"
import ConseilsDesElevesView from "@/pages/projet-page/mon-annee/ConseilsDesElevesView.vue"
import AutresInfosView from "@/pages/projet-page/mon-annee/AutresInfosView.vue"
import EtatDesLieuxView from "@/pages/projet-page/mon-annee/EtatDesLieuxView.vue"
import TitleView from "@/views/commons/TitleView.vue"
import ProjetHistoryView from "@/pages/projet-page/history/ProjetHistoryView.vue"

export const PROJECT_SECTIONS = {
  HISTORY: "historique",
  EN_BREF: "bref",
  MY_YEAR: "mon-annee",
  ADMIN_INFOS: "infos_administratives",
  LABELISED: "labellise",
}

@Component({
  components: {
    EnBrefView,
    ProjetCharteView,
    ProjetParticipants,
    ProjetHistoryView,
    ProjectValidationAndSaveHandler,
    InputWithValidation,
    LabellisationRecap,
    LabellisationResult,
    ProjetSiteView,
    ProjetFinancementView,
    ProjetAutresInfosView,
    EtatDesLieuxView,
    ProblematiquesOrientationsActionsView,
    ConseilsDesElevesView,
    ProjetDiffusionView,
    PlusView,
    SuiviView,
    AutresInfosView,
    TitleView,
  },
})
export default class ProjetLabellisationHolder extends Vue {
  @Prop() project: ProjetAnnuelDTO
  @Prop({ default: "" }) role: string
  @Prop({ default: "history" }) section: string
  @Prop({ default: "0" }) activeTab: number
  @Prop() lastProjectValidation: ProjetAnnualValidation
  @Prop({ default: true }) validationActivated: boolean
  @Prop({ default: true }) showValidationErrors: boolean
  @Prop() readonly: boolean
  @Prop() isPorteurProjet: boolean
  @Prop() currentWidth: number
  @Prop() currentScrollY: number
  @Prop() pxFromBottom: number
  @Prop() pdfExportRequested: boolean

  anchorsToTrack: Map<string, string[]> = new Map<string, string[]>()
  sectionsToIcon: Map<string, string> = new Map<string, string>()

  //Hack to please linter
  statutDossier: StatutDossier = "BROUILLON"

  // Indicates if projet has been validated at least once
  // (and hence lastProjectvalidationCanBeUsed to display last validation results)
  wasValidatedAtLeastOnce = false

  renewalSuffix = ""
  mapScreenShotRequested = false

  mounted(): void {
    this.setRenewalSuffix()
    this.refreshContextMenu()
  }

  @Watch("lastProjectValidation")
  projetAnnuelValidationChanged(): void {
    this.wasValidatedAtLeastOnce = true
    this.refreshContextMenu()
  }

  @Watch("validationActivated")
  validationActivatedChanged(): void {
    this.refreshContextMenu()
  }

  refreshContextMenu(): void {
    const menuItems = []
    this.anchorsToTrack = new Map<string, string[]>()
    if (this.project) {
      menuItems.push(new BackToPreviousScreenItem())

      menuItems.push(new NavbarItemWithoutLink(this.project.nom, ""))

      menuItems.push(this.getMenuEntry(PROJECT_SECTIONS.HISTORY, "calendar", "0"))

      menuItems.push(this.getMenuEntry(PROJECT_SECTIONS.EN_BREF, "smile", "0"))

      // Mon année
      const myYearMenuEntry = this.getMenuEntry(
        PROJECT_SECTIONS.MY_YEAR,
        "calendar-alt",
        "0",
        !this.isPorteurProjet
      )
      myYearMenuEntry.children = [
        this.getBulletEntry(PROJECT_SECTIONS.MY_YEAR, "conseil", false),
        this.getBulletEntry(PROJECT_SECTIONS.MY_YEAR, "etat", false),
        this.getBulletEntry(PROJECT_SECTIONS.MY_YEAR, "problematiques", false),
        this.getBulletEntry(PROJECT_SECTIONS.MY_YEAR, "diffusion", false),
        this.getBulletEntry(PROJECT_SECTIONS.MY_YEAR, "autre", false),
      ]
      menuItems.push(myYearMenuEntry)

      // Infos administrative
      const adminMenuEntry = this.getMenuEntry(PROJECT_SECTIONS.ADMIN_INFOS, "file", "0")
      adminMenuEntry.children = [
        this.getBulletEntry(PROJECT_SECTIONS.ADMIN_INFOS, "charte", false),
        this.getBulletEntry(PROJECT_SECTIONS.ADMIN_INFOS, "participants", false),
        this.getBulletEntry(PROJECT_SECTIONS.ADMIN_INFOS, "financement", false),
        this.getBulletEntry(PROJECT_SECTIONS.ADMIN_INFOS, "site", false),
        this.getBulletEntry(PROJECT_SECTIONS.ADMIN_INFOS, "autresinfos", false),
      ]
      menuItems.push(adminMenuEntry)

      // Labelisation
      const labeliseMenuEntry = this.getMenuEntry(
        PROJECT_SECTIONS.LABELISED,
        "check",
        "0",
        !this.isPorteurProjet
      )
      labeliseMenuEntry.children = []
      //if (!this.readonly) {
      //  labeliseMenuEntry.children.push(
      //    this.getBulletEntry(PROJECT_SECTIONS.LABELISED, "presentation", false, true)
      //  )
      //}
      if (this.project.typeDossier !== "DOSSIER_RENOUVELLEMENT_LIGHT") {
        labeliseMenuEntry.children.push(
          this.getBulletEntry(PROJECT_SECTIONS.LABELISED, "plus", false, false)
        )
      }

      labeliseMenuEntry.children.push(
        this.getBulletEntry(PROJECT_SECTIONS.LABELISED, "infos", true, false)
      )
      menuItems.push(labeliseMenuEntry)

      const possibleMenuParents = [""]
      this.$root.$emit("contextual-menu-changed", new ContextMenu(possibleMenuParents, menuItems))
      this.sectionChanged()
      this.refreshActiveAnchor()
    }
  }

  @Watch("currentScrollY")
  refreshActiveAnchor(): void {
    const anchorsForThisSection = this.anchorsToTrack.get(this.section)
    for (let i = 0; anchorsForThisSection && i < anchorsForThisSection.length; i++) {
      const anchorY = document.getElementById(anchorsForThisSection[i])?.offsetTop
      if (this.pxFromBottom <= 60) {
        this.$root.$emit(
          "contextual-menu-anchor-update",
          anchorsForThisSection[anchorsForThisSection.length - 1]
        )
        return
      } else {
        if (anchorY && this.currentScrollY <= anchorY - 20) {
          this.$root.$emit(
            "contextual-menu-anchor-update",
            anchorsForThisSection[Math.max(0, i - 1)]
          )
          return
        }
      }
    }
  }

  getMenuEntry(
    section: string,
    icone: string,
    subtab: string,
    useDifferentMenuTitleForAccompagnateur?: boolean
  ): NavbarItem {
    const basePath = "/project/" + this.project.projetId + "/" + this.project.id + "/"
    let textConstantId = "projet.menu." + section
    if (useDifferentMenuTitleForAccompagnateur) {
      textConstantId += "-accompagnateur"
    }

    if (section == "labellise") {
      textConstantId += this.renewalSuffix
    }

    this.sectionsToIcon.set(section, icone)
    return new NavbarItem(
      basePath + section + "/" + subtab,
      this.$t(textConstantId).toString(),
      icone,
      false
    )
  }

  getBulletEntry(
    section: string,
    anchor: string,
    addSuffixForPP: boolean,
    alwaysValidatioNeutral?: boolean
  ): BulletWithValidation {
    let validationStatus = ValidationStatus.NONE
    if (this.validationActivated && this.wasValidatedAtLeastOnce && this.lastProjectValidation) {
      validationStatus = ValidationStatus.VALID
      if (this.lastProjectValidation.invalidFields.get(anchor)) {
        validationStatus = ValidationStatus.ERROR
      } else if (this.lastProjectValidation.emptyFields.get(anchor)) {
        validationStatus = ValidationStatus.WARNING
      }
    }
    // For the 'finalisation section': get the worse status
    if (anchor == "presentation") {
      if (this.lastProjectValidation.invalidFields.size > 0) {
        validationStatus = ValidationStatus.ERROR
      } else if (this.lastProjectValidation.emptyFields.size > 0) {
        validationStatus = ValidationStatus.WARNING
      }
    }

    if (alwaysValidatioNeutral) {
      validationStatus = ValidationStatus.NEUTRAL
    }

    let anchorsForSection = this.anchorsToTrack.get(section)
    if (!anchorsForSection) {
      anchorsForSection = []
    }
    anchorsForSection.push(anchor)
    this.anchorsToTrack.set(section, anchorsForSection)

    let itemTitle = this.$t("projet." + section + "." + anchor).toString()
    if (addSuffixForPP && this.isPorteurProjet) {
      itemTitle = this.$t("projet." + section + "." + anchor + "-pp").toString()
    }
    return new BulletWithValidation(anchor, itemTitle, validationStatus)
  }

  @Watch("section")
  sectionChanged(): void {
    const anchorsForThisSection = this.anchorsToTrack.get(this.section)
    if (anchorsForThisSection) {
      this.$root.$emit("contextual-menu-anchor-update", anchorsForThisSection[0])
    }

    const mapScreenShotRequestedInQueryURL: string = this.$route.query?.mapScreenShotRequested?.toString()
    if (mapScreenShotRequestedInQueryURL) {
      this.$nextTick(() => {
        setTimeout(() => {
          this.mapScreenShotRequested = true
        }, 1500)
      })
    }
  }

  @Watch("project")
  projetAnnuelChanged(): void {
    this.setRenewalSuffix()
  }

  setRenewalSuffix(): void {
    this.renewalSuffix = this.project.typeDossier === "DOSSIER_LABELLISATION" ? "" : "-renewal"
  }

  @Watch("pdfExportRequested")
  pdfExportRequestedChanged(): void {
    if (this.pdfExportRequested) {
      // Change section
      if (this.section != "bref") {
        this.$router.push({
          params: { section: "bref" },
          query: { mapScreenShotRequested: "true" },
        })
      } else {
        this.mapScreenShotRequested = true
      }
    } else {
      this.mapScreenShotRequested = false
    }
  }
}
