

































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import BookmarkCard from "../generic_components/BookmarkCard.vue"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import SelectWithValidation from "@/views/inputs/SelectWithValidation.vue"
import DatePickerWithValidation from "@/views/inputs/DatePickerWithValidation.vue"
import { ValidationObserver } from "vee-validate"
import { TAG_TYPES_FOR_BOOKMARK_CARD } from "@/model/bean/EnumHelper"
import { ConseilDTO, FichierDTO, ProjetAnnuelDTO } from "@/model/bean/GeneratedDTOs"
import Constants from "@/services/Constants"
import ConversionUtils from "@/services/ConversionUtils"
import { FileService } from "@/services/rest/utils/FileService"

@Component({
  components: {
    BookmarkCard,
    InputWithValidation,
    DatePickerWithValidation,
    SelectWithValidation,
    ValidationObserver,
  },
})
export default class ConseilCard extends Vue {
  @Prop() conseil: ConseilDTO
  @Prop() projetAnnuel: ProjetAnnuelDTO
  @Prop() canDelete: boolean
  @Prop() readonly: boolean
  @Prop({ default: true }) validationActivated: boolean
  @Prop({ default: true }) showValidationErrors: boolean
  conseilAdded = false

  $refs!: {
    conseilObserver: InstanceType<typeof ValidationObserver>
  }

  protected infoReportingService = InfoReportingService.INSTANCE
  protected fileService = FileService.INSTANCE

  protected tagType = TAG_TYPES_FOR_BOOKMARK_CARD.NOUVEAU_CONSEIL
  protected iconName = "plus"

  protected internalConseil = new ConseilDTO()
  protected fichier = new FichierDTO()

  typeConseil = ["NORMAL", "ELARGI"]
  typeConseilLabels = this.typeConseil.map((value) => {
    return this.$t(`projetPage.mon-annee.conseils.${value}`).toString()
  })

  mounted(): void {
    this.conseilChanged()
    if (this.conseil) {
      this.tagType = TAG_TYPES_FOR_BOOKMARK_CARD.CONSEIL_DES_ELEVES
      this.iconName = "users"
    } else {
      this.tagType = TAG_TYPES_FOR_BOOKMARK_CARD.NOUVEAU_CONSEIL
      this.iconName = "plus"
    }
  }

  addConseil(file: File): void {
    if (this.projetAnnuel.id && file) {
      this.conseilAdded = false
      this.$emit("add", this.internalConseil, file)
      this.conseilChanged()
      requestAnimationFrame(() => {
        this.$refs.conseilObserver.reset()
      })
    }
  }

  deleteConseil(): void {
    this.infoReportingService.dialog(
      this.$t("confirmation").toString(),
      this.$t("projetPage.mon-annee.conseils.are-you-sure").toString(),
      this.$t("projetPage.mon-annee.conseils.supprimer").toString(),
      () => {
        this.$emit("delete", this.conseil)
      },
      "is-info"
    )
  }

  @Watch("projetAnnuel")
  projetAnnuelChanged(): void {
    requestAnimationFrame(() => {
      this.$refs.conseilObserver.reset()
    })
  }

  @Watch("conseil")
  conseilChanged(): void {
    if (this.conseil) {
      this.conseilAdded = true
      this.internalConseil = ConversionUtils.convertConseilDatesToLocal(this.conseil)
      this.loadFichier()
    } else {
      this.internalConseil = new ConseilDTO()
    }
  }

  async loadFichier(): Promise<void> {
    if (this.projetAnnuel.id) {
      this.fichier = await this.fileService.getFichier(this.projetAnnuel.id, this.conseil.fichier)
    }
  }

  truncatedFileName(fileName: string) {
    return fileName.length > 20 ? fileName.substring(0, 20) + "..." : fileName
  }

  getConseilUrl(conseil: ConseilDTO): string {
    return Constants.apiUrlv2(
      `/projets/${this.projetAnnuel.id}/fichiers/${conseil.fichier}/content`
    )
  }
}
