















































































import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import TitleView from "@/views/commons/TitleView.vue"
import ContentView from "@/views/commons/ContentView.vue"
import H3TitleView from "@/views/commons/H3TitleView.vue"
import { EtablissementDTO, ProjetAnnuelDTO, UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import SingleEtablissement from "../organisme/SingleEtablissement.vue"
import EtablissementProjectCard from "./EtablissementProjectCard.vue"

@Component({
  components: {
    TitleView,
    ContentView,
    H3TitleView,
    SingleEtablissement,
    EtablissementProjectCard,
  },
})
export default class JoinProjectBeforeAskingView extends Vue {
  @Prop() loggedUser: UtilisateurDTO
  @Prop() projectsInEtablissement: ProjetAnnuelDTO[]
  @Prop() chosenEtablissement: EtablissementDTO

  filteredProjects: ProjetAnnuelDTO[] = []
  isLoading = false
  isReferent = false

  created(): void {
    this.projectsInEtablissementChanged()
    if (!this.filteredProjects || this.filteredProjects.length === 0) {
      this.$router.push("")
    }
  }

  @Watch("projectsInEtablissement")
  projectsInEtablissementChanged(): void {
    this.filteredProjects = this.projectsInEtablissement.filter(
      (p) => !p.enseignants.some((e) => e.id === this.loggedUser.id)
    )
  }
  askForCreation(): void {
    this.$router.push(`/myProjects/new/${this.chosenEtablissement.codeEtablissement}/ask`)
  }

  confirmJoin(): void {
    this.isLoading = true
    this.$emit("confirm-join")
  }

  cancelProjectCreation(): void {
    this.isLoading = true
    this.$emit("cancel-project-creation")
  }

  goPrevious(): void {
    this.$router.push("/myProjects/new")
  }
}
