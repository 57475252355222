


















































































































































































































































































































import { EnumHelper } from "@/model/bean/EnumHelper"
import { PaginationOrder, ProjetAnnuelDTO, UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import { Filters } from "@/model/bean/Filters"
import RouterUtils from "@/router/RouterUtils"
import { format } from "date-fns"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import { InstructionService } from "@/services/rest/instruction/InstructionService"
import { LoginService } from "@/services/rest/login/LoginService"
import { UsersService } from "@/services/rest/users/UsersService"
import AbstractTableView from "@/views/tables/AbstractTableView.vue"
import Column from "@/views/tables/Column"
import TableFilterView from "@/views/tables/TableFilterView.vue"
import StatutDossierTag from "@/views/dossier/StatutDossierTag.vue"
import { Component, Prop } from "vue-property-decorator"
import LabellisationEvaluationCommentsPopup from "./LabellisationEvaluationCommentsPopup.vue"

const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm"

@Component({
  components: { TableFilterView, LabellisationEvaluationCommentsPopup, StatutDossierTag },
})
export default class LabellisationClotureView extends AbstractTableView {
  @Prop() graeId: string
  @Prop({ default: 0 }) activeSubTab: number

  private loginService = LoginService.INSTANCE
  private instructionService = InstructionService.INSTANCE
  protected infoReportingService = InfoReportingService.INSTANCE
  private usersService = UsersService.INSTANCE

  loggedUser: UtilisateurDTO | undefined = new UtilisateurDTO()
  projects: Array<ProjetAnnuelDTO> = []
  canEndLabellisationInstruction = false
  hasRightToModify = false

  exportLabellisationClotureCsv(): void {
    console.log("****exportLabelisationClotureCsv***")
    const statutFilter = Filters.asFilter({ property: "statut", values: ["INSTRUCTION_TERMINEE"] })
    const onlyValidatedFilter = Filters.asFilter({
      property: "evaluationFinaleValidated",
      values: ["true"],
    })
    this.instructionService.exportLabellisationsClosCsv(this.graeId, [
      ...this.activeFilters,
      statutFilter,
      onlyValidatedFilter,
    ])
  }
  exportLabellisationClotureXls(): void {
    console.log("****exportLabelisationClotureXls***")
    const statutFilter = Filters.asFilter({ property: "statut", values: ["INSTRUCTION_TERMINEE"] })
    const onlyValidatedFilter = Filters.asFilter({
      property: "evaluationFinaleValidated",
      values: ["true"],
    })
    this.instructionService.exportLabellisationsClosXls(this.graeId, [
      ...this.activeFilters,
      statutFilter,
      onlyValidatedFilter,
    ])
  }
  formatDate(date: Date): string {
    if (date) {
      return format(date, DATE_TIME_FORMAT)
    }
    return ""
  }

  created(): void {
    this.loggedUser = this.loginService.getLoggedUser()
    if (!this.loggedUser || !this.loggedUser.id) {
      return
    }
    this.checkEndLabellisationInstruction()
    this.hasRightToModify =
      this.loggedUser.profil !== "OBSERVATEUR_NATIONAL" &&
      this.loggedUser.profil !== "ACCOMPAGNATEUR_REGIONAL"
  }

  async checkEndLabellisationInstruction(): Promise<void> {
    this.canEndLabellisationInstruction = await this.instructionService.canEndLabellisationForGrae(
      this.graeId
    )
  }

  hasRightsToEndLabellisationInstruction(): boolean {
    if (this.loggedUser) {
      return this.usersService.canModifyGrae(this.loggedUser, this.graeId)
    }
    return false
  }

  endLabellisation(): void {
    this.infoReportingService.dialogWithCancel(
      this.$t("graePage.dossiersLabellisationList.confirm-popup-title").toString(),
      this.$t("graePage.dossiersLabellisationList.confirm-popup-message").toString(),
      this.$t("graePage.dossiersLabellisationList.confirm-popup-ok-button").toString(),
      async () => {
        this.isLoading = true
        try {
          await this.instructionService.endLabellisationForGrae(this.graeId)
          this.isLoading = false
          this.infoReportingService.success(
            this.$t("graePage.dossiersLabellisationList.ended-labellisation").toString()
          )
          this.canEndLabellisationInstruction = false
          this.reloadData()
        } catch (e) {
          this.isLoading = false
          this.infoReportingService.error(
            this.$t("graePage.dossiersLabellisationList.end-labellisation-failure").toString(),
            e
          )
        }
      },
      () => {
        // Nothing to do
      },
      "is-danger"
    )
  }

  async reloadData(): Promise<void> {
    this.isLoading = true
    const statutFilter = Filters.asFilter({ property: "statut", values: ["INSTRUCTION_TERMINEE"] })
    const onlyValidatedFilter = Filters.asFilter({
      property: "evaluationFinaleValidated",
      values: ["true"],
    })
    try {
      const paginatedProjectsList = await this.instructionService.getLabellisations(
        this.graeId,
        this.currentPagedSortedParameter,
        [...this.activeFilters, statutFilter, onlyValidatedFilter],
        undefined
      )
      this.projects = paginatedProjectsList.elements
      this.total = paginatedProjectsList.count
    } catch (e) {
      // Silent catch
    }
    this.isLoading = false
  }

  getInitialColumns(): Array<Column> {
    return [
      // default fields
      {
        field: "codeDepartement",
        label: this.$t("graePage.details.projets.dpt").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "ville",
        label: this.$t("graePage.details.projets.ville").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "etablissement.name",
        label: this.$t("graePage.details.projets.etablissements").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "etablissement.codeEtablissement",
        label: this.$t("graePage.details.projets.uais").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "projet.nom",
        label: this.$t("graePage.details.projets.nom").toString(),
        sortable: true,
        backEndSearchable: true,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "natureAireEducative",
        label: this.$t("graePage.details.projets.nature-ae").toString(),
        sortable: true,
        backEndSearchable: true,
        possibleSearchValues: EnumHelper.allNatures,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "typeDossier",
        label: this.$t("graePage.details.projets.typeDossier").toString(),
        sortable: true,
        backEndSearchable: true,
        possibleSearchValues: EnumHelper.allTypeDossiers,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "statut",
        label: this.$t("graePage.details.projets.statut").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "enseignant.name",
        label: this.$t("graePage.details.projets.enseignants").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "referent.name",
        label: this.$t("graePage.details.projets.referents").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "structure.name",
        label: this.$t("graePage.details.projets.structures").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "dateModification",
        label: this.$t("graePage.details.projets.dateModification").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "instructeurs.name",
        label: this.$t("graePage.details.projets.instructeurs").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "deroule",
        label: this.$t("instructionPage.labellisations.harmonisation.note.deroule").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "diffusion",
        label: this.$t("instructionPage.labellisations.harmonisation.note.diffusion").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "enseignements",
        label: this.$t(
          "instructionPage.labellisations.harmonisation.note.enseignements"
        ).toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "implication",
        label: this.$t("instructionPage.labellisations.harmonisation.note.implication").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "commentaires",
        label: this.$t("instructionPage.labellisations.harmonisation.note.commentaires").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "evaluation.mention",
        label: this.$t("instructionPage.labellisations.harmonisation.note.mention").toString(),
        sortable: true,
        backEndSearchable: false,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "action",
        label: this.$t("projetsListPage.actions").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      // hidden fields
      {
        field: "thematique",
        label: this.$t("graePage.details.projets.thematiques").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "objectsConcretRealisation",
        label: this.$t("graePage.details.projets.objets-concrets-de-realisation").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "milieuGenerique",
        label: this.$t("graePage.details.projets.milieu-generique").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "milieuInteretParticulier",
        label: this.$t("graePage.details.projets.milieux-interets-particuliers").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
    ]
  }

  getDefaultSort(): Array<PaginationOrder> {
    const defaultSort = new PaginationOrder()
    defaultSort.clause = "projet.nom"
    defaultSort.desc = false
    return [defaultSort]
  }

  showProjectDetailsPage(event: Event, projetId: string, projetAnnuelId: string): void {
    // Do not foward click event to row (would trigger modal)
    event.stopPropagation()
    this.$router.push(RouterUtils.getRouteForProjectDetails(projetId, projetAnnuelId))
  }

  consult(event: Event, projetId: string, projetAnnuelId: string): void {
    // Do not foward click event to row (would trigger modal)
    event.stopPropagation()
    let route = this.$router.resolve(
      RouterUtils.getRouteForProjectDetails(projetId, projetAnnuelId)
    )
    window.open(route.href)
  }

  async renvoyer(event: Event, projetAnnuelId: string): Promise<void> {
    // Do not foward click event to row (would trigger modal)
    event.stopPropagation()
    try {
      await this.instructionService.desapprouveEvaluationFinale(projetAnnuelId)

      this.infoReportingService.success(
        this.$t("instructionPage.labellisations.cloture.renvoyer-en-harmonisation").toString()
      )
      this.projects = []
      this.reloadData()
    } catch (e) {
      this.infoReportingService.error(
        this.$t(
          "instructionPage.labellisations.cloture.renvoyer-en-harmonisation-error"
        ).toString(),
        e as Error
      )
    }
  }
}
