




















































import { EtatProjet } from "@/model/bean/GeneratedDTOs"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import H3TitleView from "./H3TitleView.vue"
import H4TitleView from "./H4TitleView.vue"

@Component({
  components: {
    H3TitleView,
    H4TitleView,
  },
})
export default class ConfirmChangementEtat extends Vue {
  @Prop() isModalActive: boolean
  @Prop() oldEtatProjet: EtatProjet
  @Prop() newEtatProjet: EtatProjet

  isActive = false

  @Watch("isModalActive")
  isModalActiveChanged(): void {
    this.isActive = this.isModalActive
  }

  getIcon(etat: EtatProjet): string {
    if (etat === "ACTIF") {
      return "play-circle"
    } else if (etat === "ARCHIVE") {
      return "stop-circle"
    }
    return "pause-circle"
  }

  getColor(etat: EtatProjet): string {
    if (etat === "ACTIF") {
      return "is-success"
    } else if (etat === "ARCHIVE") {
      return "is-danger"
    }
    return "is-warning"
  }

  close(): void {
    this.isActive = false
    this.$emit("close")
  }

  validate(): void {
    this.$emit("confirm")
    this.close()
  }
}
