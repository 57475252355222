

























































import { Component, Prop, Vue } from "vue-property-decorator"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import { LoginService } from "@/services/rest/login/LoginService"
import { GraeDTO } from "@/model/bean/GeneratedDTOs"
import H3TitleView from "@/views/commons/H3TitleView.vue"
import { GraeService } from "@/services/rest/grae/GraeService"
import { format } from "date-fns"

const DATE_FORMAT = "DD/MM/YYYY"

@Component({
  components: {
    InputWithValidation,
    H3TitleView,
  },
})
export default class NotesModale extends Vue {
  @Prop() grae: GraeDTO
  @Prop() open: boolean

  private loginService = LoginService.INSTANCE
  private graeService = GraeService.INSTANCE

  canUserEditNotes = false

  created(): void {
    const loggedUser = this.loginService.getLoggedUser()
    this.canUserEditNotes =
      loggedUser?.profil === "SUPER_ADMINISTRATEUR" ||
      loggedUser?.profil === "ACCOMPAGNATEUR_NATIONAL"
  }

  formatDate(date: Date): string {
    if (date) {
      return format(date, DATE_FORMAT)
    }
    return ""
  }

  async saveAndClose(): Promise<void> {
    this.graeService.updateNote(this.grae).then(() => {
      this.grae.dateModification = new Date()
      this.emitCloseModale()
    })
  }

  emitCloseModale(): void {
    this.$emit("close-modale")
  }
}
