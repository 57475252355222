




































































































import Constants from "@/services/Constants"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import { FileService } from "@/services/rest/utils/FileService"
import { SettingsService } from "@/services/rest/utils/SettingsService"
import { ValidationProvider } from "vee-validate"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"

@Component({
  components: {
    ValidationProvider,
  },
})
export default class FileWithValidation extends Vue {
  @Prop({ default: "" }) group: string
  @Prop() vid: string
  @Prop() rules: [string]
  @Prop() value: boolean
  @Prop() tooltip: string
  @Prop() inputNotice: string
  @Prop({ default: "" }) placeholder: string
  @Prop({ default: "" }) customError: string
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: true }) validationActivated: boolean
  @Prop({ default: true }) showValidationErrors: boolean
  @Prop() fileOperationUrl: string
  @Prop({ default: "" }) defaultFileName: string

  private infoReportingService = InfoReportingService.INSTANCE
  private fileService = FileService.INSTANCE
  private settingsService = SettingsService.INSTANCE

  innerValue: File | undefined
  downloadFileUrl = ""

  isUploading = false
  uploadError = false

  isMandatory = false
  fileName = ""
  fileIsPresent = false

  maxUploadSizeString = ""
  rulesWithoutRequiredRulesIfValidationDisabled = ""

  // eslint-disable-next-line
  data() {
    return {
      innerValue: null,
    }
  }

  created(): void {
    if (this.value) {
      this.fileIsPresent = this.value
    }
    this.isMandatory = this.rules && this.rules.includes("required")
    this.downloadFileUrl = this.fileOperationUrl
    this.onRulesChanged()
  }

  @Watch("innerValue")
  onInnerValueChanged(newVal: File, _oldValue: File): void {
    this.$emit("input", newVal)
  }

  @Watch("value")
  onExternalValueChanged(newVal: boolean, _oldValue: boolean): void {
    this.fileIsPresent = newVal
  }

  addFile(): void {
    if (this.innerValue) {
      if (this.fileOperationUrl) {
        this.uploadError = false
        this.isUploading = true
        this.fileService
          .uploadFileWithName(this.innerValue, this.fileOperationUrl)
          .then((_result) => {
            // File is uploaded, warnings can be refreshed
            this.$emit("file-uploaded-or-deleted", _result)
            this.isUploading = false

            this.$emit("add-file", this.innerValue)
            this.$emit("manual-change", this.innerValue)
            this.fileIsPresent = true
          })
          .catch(async (error) => {
            const settings = await this.settingsService.getSettings()
            let uploadSize = parseInt(settings.MAX_UPLOAD_SIZE.toLowerCase().replace("K", ""))
            if (uploadSize > 0) {
              //uploadSize est en Ko et le passage en base64 augmente de 1/3 la taille
              this.maxUploadSizeString = "(max " + Math.floor(uploadSize / 1333) + "Mo)"
            }
            this.infoReportingService.error(
              this.$t("upload-failed", [this.maxUploadSizeString]).toString(),
              error
            )
            this.isUploading = false
            this.uploadError = true
            this.fileIsPresent = false
          })
      } else {
        this.fileName = this.innerValue.name
      }
    }
  }

  deleteFile(): void {
    this.infoReportingService.dialog(
      this.$t("file.delete.confirmation").toString(),
      this.$t("file.delete.are-you-sure").toString(),
      this.$t("file.delete.save").toString(),
      () => this.doDeleteFile(),
      "is-warning"
    )
  }

  doDeleteFile(): void {
    if (this.fileOperationUrl) {
      this.fileService.deleteFile(this.fileOperationUrl).then((_result) => {
        // File is deleted, warnings can be refreshed
        this.$emit("file-uploaded-or-deleted", _result)
      })
    }
    this.$emit("delete-file")
    this.$emit("manual-change", undefined)
    this.innerValue = undefined
    this.fileIsPresent = false
  }

  async openFile(): Promise<void> {
    const fileURL = Constants.apiUrlv2(this.fileOperationUrl)
    window.open(fileURL, "_blank")
  }

  @Watch("rules")
  onRulesChanged(): void {
    this.isMandatory = this.rules && this.rules.includes("required")
    if (this.rules) {
      this.rulesWithoutRequiredRulesIfValidationDisabled = this.rules.toString()
      if (!this.validationActivated) {
        this.rulesWithoutRequiredRulesIfValidationDisabled = this.rules
          .toString()
          .replace("required|", "")
          .replace("|required", "")
          .replace("required", "")
      }
    } else {
      this.rulesWithoutRequiredRulesIfValidationDisabled = ""
    }
  }

  @Watch("validationActivated")
  onValidationActivated(): void {
    this.onRulesChanged()
  }
}
