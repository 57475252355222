var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ContentView",
    [
      _c("ValidationObserver", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var handleSubmit = ref.handleSubmit
              return [
                _c(
                  "b-modal",
                  {
                    staticClass: "AskForProjectCreationView",
                    attrs: {
                      active: true,
                      "has-modal-card": "",
                      "trap-focus": "",
                      "aria-role": "dialog",
                      "aria-label": "AskForProjectCreationModal",
                      "aria-modal": "",
                      width: "100vw"
                    }
                  },
                  [
                    [
                      _c("div", { staticClass: "modal-card" }, [
                        _c(
                          "header",
                          { staticClass: "modal-card-head" },
                          [
                            _c(
                              "H3TitleView",
                              { staticClass: "modal-card-title" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm
                                      .$t("myProjects.create-project.title")
                                      .toString()
                                  )
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "section",
                          { staticClass: "modal-card-body" },
                          [
                            _c("SingleEtablissement", {
                              attrs: { etablissement: _vm.chosenEtablissement }
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "AskForProjectCreationView-infos"
                              },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    icon: "times-circle",
                                    type: "is-danger"
                                  }
                                }),
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "myProjects.create-project.etablissementWithProject-condition"
                                        )
                                      ) +
                                      " "
                                  )
                                ])
                              ],
                              1
                            ),
                            _c("div", [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "myProjects.create-project.etablissementWithProject1"
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _c("br"),
                              _c("br"),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "myProjects.create-project.etablissementWithProject2"
                                    )
                                  )
                                )
                              ]),
                              _c("br"),
                              _c("br"),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "myProjects.create-project.etablissementWithProject4"
                                    )
                                  )
                                )
                              ])
                            ]),
                            _c("InputWithValidation", {
                              staticClass: "AskForProjectCreationView-name",
                              attrs: {
                                rules: "required",
                                label: _vm.$t("myProjects.create-project.name"),
                                maxlength: 150,
                                vid: "AskForProjectCreationView-nomProjet"
                              },
                              model: {
                                value: _vm.projectName,
                                callback: function($$v) {
                                  _vm.projectName = $$v
                                },
                                expression: "projectName"
                              }
                            })
                          ],
                          1
                        ),
                        _c("footer", { staticClass: "modal-card-foot" }, [
                          _c(
                            "div",
                            {
                              class:
                                "buttons " +
                                (_vm.isReferent ? "hasPreviousStep" : "")
                            },
                            [
                              _vm.isReferent
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        rounded: "",
                                        type: "is-primary"
                                      },
                                      on: { click: _vm.goPrevious }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("myProjects.previousStep")
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "modal-finish-buttons" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "hoverable-discrete-link",
                                      on: { click: _vm.cancelProjectCreation }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("annuler")) + " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        rounded: "",
                                        type: "is-primary"
                                      },
                                      on: {
                                        click: function($event) {
                                          return handleSubmit(
                                            _vm.askForCreation
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "myProjects.create-project.create"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    ]
                  ],
                  2
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }