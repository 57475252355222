var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "projet-part" },
      [
        _c("h2", [
          _vm._v(_vm._s(_vm.$t("projet.inscription.site-inscription.titre")))
        ]),
        _c("RadioWithInputAndValidation", {
          attrs: {
            rules: "required",
            data: _vm.natures,
            optionLabels: _vm.natureLabels,
            showValidationErrors: _vm.showValidationErrors,
            verticalDisplay: "true",
            disabled: _vm.readonly,
            label: _vm.$t(
              "projet.inscription.site-inscription.nature-question"
            ),
            inputNotice:
              _vm.projetAnnuel.etat == "EN_COURS_DE_CREATION"
                ? _vm.$t(
                    "projet.inscription.site-inscription.nature-question-notice"
                  )
                : "",
            vid: "site_1"
          },
          on: {
            "manual-change": function($event) {
              return _vm.$emit("dirtify-project")
            }
          },
          model: {
            value: _vm.projetAnnuel.natureAireEducative,
            callback: function($$v) {
              _vm.$set(_vm.projetAnnuel, "natureAireEducative", $$v)
            },
            expression: "projetAnnuel.natureAireEducative"
          }
        }),
        _c("InputWithValidation", {
          attrs: {
            label: _vm.$t(
              "projet.inscription.site-inscription.repere.question"
            ),
            rules: "required",
            showValidationErrors: _vm.showValidationErrors,
            type: "textarea",
            disabled: _vm.readonly || _vm.projetAnnuel.statut !== "BROUILLON",
            vid: "site_2"
          },
          on: {
            "manual-change": function($event) {
              return _vm.$emit("dirtify-project")
            }
          },
          model: {
            value: _vm.projetAnnuel.contexteSitesPotentiels,
            callback: function($$v) {
              _vm.$set(_vm.projetAnnuel, "contexteSitesPotentiels", $$v)
            },
            expression: "projetAnnuel.contexteSitesPotentiels"
          }
        }),
        _c("template", { slot: "message" }, [
          _c("div", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "projet.inscription.site-inscription.repere.sites-potentiels-tooltip"
                  )
                ) +
                " "
            )
          ]),
          _c("div", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "projet.inscription.site-inscription.repere.sites-potentiels-tooltip2"
                  )
                ) +
                " "
            )
          ]),
          _c("div", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "projet.inscription.site-inscription.repere.sites-potentiels-tooltip3"
                  )
                ) +
                " "
            )
          ]),
          _c("div", [
            _vm._v(
              _vm._s(
                _vm.$t(
                  "projet.inscription.site-inscription.repere.sites-potentiels-link"
                )
              )
            )
          ])
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }