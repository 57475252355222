


















































































import { Component, Prop, Vue } from "vue-property-decorator"
import TitleView from "@/views/commons/TitleView.vue"
import ContentView from "@/views/commons/ContentView.vue"
import PageView from "@/views/commons/PageView.vue"
import UserInformationsView from "@/views/login/registration/UserInformationsView.vue"
import ProfilSelectionView from "@/views/login/registration/ProfilSelectionView.vue"
import StructureSelectionView from "@/views/login/registration/StructureSelectionView.vue"
import EtablissementSelectionView from "@/views/login/registration/EtablissementSelectionView.vue"
import ReferentCompetencesView from "@/views/login/registration/ReferentCompetencesView.vue"
import AccompagnateurRegionalInformationsView from "@/views/login/registration/AccompagnateurRegionalInformationsView.vue"
import AccompagnateurNationalInformationsView from "@/views/login/registration/AccompagnateurNationalInformationsView.vue"
import { LoginService } from "@/services/rest/login/LoginService"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import {
  UtilisateurRegistrationDTO,
  ProfilUtilisateur,
  StructureDTO,
  EtablissementDTO,
} from "@/model/bean/GeneratedDTOs"
import { REGISTER_STEPS } from "@/model/bean/EnumHelper"

Component.registerHooks(["beforeRouteUpdate", "beforeRouteEnter"])

@Component({
  components: {
    TitleView,
    ContentView,
    PageView,
    UserInformationsView,
    ProfilSelectionView,
    StructureSelectionView,
    EtablissementSelectionView,
    AccompagnateurRegionalInformationsView,
    AccompagnateurNationalInformationsView,
    ReferentCompetencesView,
  },
})
export default class RegisterPage extends Vue {
  private loginService = LoginService.INSTANCE
  private infoReportingService = InfoReportingService.INSTANCE

  @Prop() registerStep: string
  @Prop() urlTabIndex: string

  registerSteps = REGISTER_STEPS
  currentStep = REGISTER_STEPS.USER
  utilisateur = new UtilisateurRegistrationDTO()
  registrationMessage = ""
  errorMessage = ""
  isLoading = false

  // We need to be sure that we always enter the register page by user form
  // eslint-disable-next-line
  beforeRouteEnter(to: any, _from: any, next: any): void {
    next((vm: Vue) => {
      // We ts ignore the next line to avoid typescript screaming about moveToStep not existing on element
      //@ts-ignore
      vm.moveToStep(REGISTER_STEPS.USER)
    })
  }

  // eslint-disable-next-line
  beforeRouteUpdate(to: any, from: any, next: any): void {
    if (!to || !to.params || !to.params.registerStep) {
      next()
    }
    const toParamRegisterStep = to.params.registerStep

    // We can't trust the from params registerStep because it has been changed by updateURL
    const fromParamRegisterStep = from.path.split("/")[2]

    const newTabIndex =
      toParamRegisterStep === fromParamRegisterStep ? parseInt(to.params.urlTabIndex) : 0

    const etablissementSelectionElmt = this.$refs.etablissementSelection

    if (etablissementSelectionElmt) {
      // We ts ignore the next line to avoid typescript screaming about needToAddEtablissement not existing on element
      //@ts-ignore
      etablissementSelectionElmt.needToAddEtablissement =
        toParamRegisterStep === REGISTER_STEPS.ETABLISSEMENT_ADDITION
    }

    const structureSelectionElmt = this.$refs.structureSelection

    if (structureSelectionElmt) {
      // We ts ignore the next line to avoid typescript screaming about needToAddStructure not existing on element
      //@ts-ignore
      structureSelectionElmt.needToAddStructure =
        toParamRegisterStep === REGISTER_STEPS.STRUCTURE_ADDITION
      //@ts-ignore
      structureSelectionElmt.setTabIndex(newTabIndex)
    }

    this.currentStep = toParamRegisterStep
    this.errorMessage = ""

    next()
    this.refreshURLFromCurrentTabIndex(newTabIndex)
  }

  mounted(): void {
    window.scrollTo(0, 0)
  }

  /**
   * Refreshes url when tab item changes
   */
  refreshURLFromCurrentTabIndex(newCurrentTabIndex: number): void {
    const params = this.$router.currentRoute.params
    if (newCurrentTabIndex.toString() !== params["urlTabIndex"]) {
      params["urlTabIndex"] = newCurrentTabIndex.toString()
      this.$router.push({ params: params })
    }
  }

  updateUser(user: UtilisateurRegistrationDTO): void {
    this.utilisateur.nom = user.nom
    this.utilisateur.prenom = user.prenom
    this.utilisateur.courriel = user.courriel
    this.utilisateur.motDePasse = user.motDePasse
    this.utilisateur.confirmationMotDePasse = user.confirmationMotDePasse
    this.moveToStep(this.registerSteps.PROFIL)
  }

  moveProfileOn(profil: ProfilUtilisateur): void {
    const step = this.getStepAccordingToProfil(profil) || this.registerSteps.INSCRIPTION
    this.updateProfile(profil, step)
  }

  updateProfile(profil: ProfilUtilisateur, step: string): void {
    this.utilisateur.profil = profil
    this.moveToStep(step)
  }

  updateStructure(struct: StructureDTO, step: string): void {
    this.utilisateur.structure = struct
    this.moveToStep(step)
  }

  updateEtablissement(etablissement: EtablissementDTO, step: string): void {
    this.utilisateur.etablissement = etablissement
    this.moveToStep(step)
  }

  updateAccompagnateurRegional(utilisateur: UtilisateurRegistrationDTO, step: string): void {
    this.utilisateur.grae = utilisateur.grae
    this.utilisateur.fonction = utilisateur.fonction
    this.utilisateur.employeur = utilisateur.employeur
    this.moveToStep(step)
  }

  updateAccompagnateurNational(utilisateur: UtilisateurRegistrationDTO, step: string): void {
    this.utilisateur.fonction = utilisateur.fonction
    this.utilisateur.employeur = utilisateur.employeur
    this.moveToStep(step)
  }

  updateCompetences(utilisateur: UtilisateurRegistrationDTO, step: string): void {
    this.utilisateur.competencesReferentPedagogie = utilisateur.competencesReferentPedagogie
    this.utilisateur.competencesReferentEcologie = utilisateur.competencesReferentEcologie
    this.utilisateur.competencesReferentAe = utilisateur.competencesReferentAe
    this.moveToStep(step)
  }

  getStepAccordingToProfil(profil?: ProfilUtilisateur): string {
    if (!profil) {
      return ""
    }
    let step = ""
    if (profil === "ENSEIGNANT") {
      step = this.registerSteps.ETABLISSEMENT_SELECTION
    } else if (profil === "REFERENT") {
      step = this.registerSteps.COMPETENCES
    } else if (profil === "ACCOMPAGNATEUR_REGIONAL") {
      step = this.registerSteps.REGION
    } else if (profil === "ACCOMPAGNATEUR_NATIONAL") {
      step = this.registerSteps.FONCTION
    } else if (profil === "OBSERVATEUR_NATIONAL") {
      step = this.registerSteps.FONCTION
    }
    return step
  }

  async moveToStep(nextStep: string): Promise<void> {
    this.currentStep = nextStep
    if (this.currentStep === this.registerSteps.INSCRIPTION) {
      await this.confirm()
    } else {
      this.updateUrl(nextStep)
    }
  }

  updateUrl(nextStep: string): void {
    const params = this.$router.currentRoute.params
    if (nextStep !== params["registerStep"]) {
      params["registerStep"] = nextStep
      this.$router.push({ params: params })
    }
  }

  async confirm(): Promise<void> {
    try {
      this.isLoading = true
      await this.loginService.register(this.utilisateur)
      this.registrationMessage = this.$t("registerPage.mailsent").toString()
      this.$router.push({
        name: "RegisterPending",
        params: {
          errorMessage: this.errorMessage,
          registrationMessage: this.registrationMessage,
          initialResendMode: "none",
        },
      })
    } catch (error) {
      this.errorMessage = this.$t(error).toString()
    } finally {
      this.isLoading = false
    }
  }
}
