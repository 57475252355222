var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "bref-container" }, [
      _c(
        "div",
        { staticClass: "main-column" },
        [
          _c("InputWithValidation", {
            staticClass: "pitch",
            attrs: {
              validationActivated: _vm.validationActivated,
              showValidationErrors: _vm.showValidationErrors,
              label: _vm.$t("projet.en-bref.pitch"),
              maxlength: "2590",
              rules:
                _vm.projetAnnuel.typeDossier === "DOSSIER_RENOUVELLEMENT_LIGHT"
                  ? ""
                  : "required",
              vid: "bref_pitch",
              disabled: _vm.readonly || !_vm.isPorteurProjet,
              type: "textarea"
            },
            on: {
              "manual-change": function($event) {
                return _vm.$emit("dirtify-project")
              }
            },
            model: {
              value: _vm.projetAnnuel.pitch,
              callback: function($$v) {
                _vm.$set(_vm.projetAnnuel, "pitch", $$v)
              },
              expression: "projetAnnuel.pitch"
            }
          }),
          _c(
            "div",
            { staticClass: "map" },
            [
              _vm.projetAnnuel.natureAireEducative === "AME"
                ? _c("div", { staticClass: "area-title" }, [
                    _c("img", {
                      staticClass: "icon",
                      attrs: { src: "/img/poisson.svg", alt: "Poisson" }
                    }),
                    _vm._v(" " + _vm._s(_vm.$t("projet.en-bref.AME")) + " ")
                  ])
                : _c("div", { staticClass: "area-title" }, [
                    _c("img", {
                      staticClass: "icon",
                      attrs: { src: "/img/scarabee.svg", alt: "Scarabée" }
                    }),
                    _vm._v(" " + _vm._s(_vm.$t("projet.en-bref.ATE")) + " ")
                  ]),
              !_vm.projetAnnuel.zoneGeographique ||
              _vm.projetAnnuel.zoneGeographique == "null"
                ? _c("InputWithValidation", {
                    staticClass: "mt-3 MapDrawView-invisible-input",
                    attrs: {
                      validationActived: _vm.validationActivated,
                      type: "textarea",
                      vid: "bref_map",
                      rules: "required"
                    }
                  })
                : _vm._e(),
              _vm.shouldCreateMap
                ? _c("MapDrawView", {
                    key: _vm.projetAnnuel.zoneGeographique,
                    staticClass: "MapDrawView",
                    attrs: {
                      validationActivated: _vm.validationActivated,
                      showValidationErrors: _vm.showValidationErrors,
                      mapHasFocus: true,
                      zoneGeographique: _vm.projetAnnuel.zoneGeographique,
                      etablissement: _vm.projetAnnuel.etablissements[0],
                      readonly: _vm.readonly || !_vm.isPorteurProjet
                    },
                    on: { "zone-update": _vm.changeZoneGeographique }
                  })
                : _vm._e(),
              _vm.mapScreenShotRequested
                ? _c("MapDrawView", {
                    staticClass: "HiddenMapDrawView",
                    attrs: {
                      validationActivated: _vm.validationActivated,
                      showValidationErrors: _vm.showValidationErrors,
                      mapHasFocus: true,
                      zoneGeographique: _vm.projetAnnuel.zoneGeographique,
                      etablissement: _vm.projetAnnuel.etablissements[0],
                      readonly: _vm.readonly || !_vm.isPorteurProjet,
                      mapScreenShotRequested: _vm.mapScreenShotRequested
                    },
                    on: {
                      "map-screenshot-done": function(dataURL) {
                        return _vm.$emit("map-screenshot-done", dataURL)
                      },
                      "zone-update": _vm.changeZoneGeographique
                    }
                  })
                : _vm._e(),
              _c("br"),
              _c("InputWithValidation", {
                attrs: {
                  validationActivated: _vm.validationActivated,
                  showValidationErrors: _vm.showValidationErrors,
                  rules:
                    _vm.projetAnnuel.zoneGeographiqueChanged ||
                    _vm.projetAnnuel.typeDossier !==
                      "DOSSIER_RENOUVELLEMENT_LIGHT"
                      ? "required"
                      : "",
                  type: "textarea",
                  label: _vm.$t("projetPage.infoAdministratives.site.choix"),
                  inputNotice: _vm.$t(
                    "projetPage.infoAdministratives.site.choix-message"
                  ),
                  maxlength: "1400",
                  disabled: _vm.readonly || !_vm.isPorteurProjet,
                  vid: "site_choixAiresEducatives"
                },
                on: { "manual-change": _vm.modified },
                model: {
                  value: _vm.projetAnnuel.choixAiresEducatives,
                  callback: function($$v) {
                    _vm.$set(_vm.projetAnnuel, "choixAiresEducatives", $$v)
                  },
                  expression: "projetAnnuel.choixAiresEducatives"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "carousel-gallery-column",
          attrs: { id: "carousel-gallery-column" }
        },
        [
          _c("CarouselView", {
            attrs: {
              projetAnnuel: _vm.projetAnnuel,
              readonly: _vm.readonly,
              isPorteurProjet: _vm.isPorteurProjet,
              validationActivated: _vm.validationActivated,
              showValidationErrors: _vm.showValidationErrors
            },
            on: {
              "trigger-validation": function($event) {
                return _vm.$emit("trigger-validation")
              },
              "images-url-changed": function(imagesUrl) {
                return _vm.$emit("images-url-changed", imagesUrl)
              }
            }
          }),
          _c("CalendrierView", {
            attrs: {
              projetAnnuel: _vm.projetAnnuel,
              readonly: _vm.readonly,
              isPorteurProjet: _vm.isPorteurProjet
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }