var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("GroupCheckboxesWithValidation", {
        attrs: {
          label: _vm.$t("instruction.objet-realisation"),
          data: _vm.allObjetsRealisationLabels(),
          verticalDisplay: "true",
          inputLabel: _vm.$t("instruction.AUTRE"),
          extInputValue: _vm.internalProjetAnnuel.objectConcretRealisationAutre,
          vid: "objet",
          disabled: _vm.readOnly
        },
        on: {
          "manual-change": _vm.changeObjetsRealisation,
          "input-change": _vm.changeObjetRealisationAutre
        },
        model: {
          value: _vm.checkedObjetsLabels,
          callback: function($$v) {
            _vm.checkedObjetsLabels = $$v
          },
          expression: "checkedObjetsLabels"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }