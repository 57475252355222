






















































































import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { OrganismeService } from "@/services/rest/organisme/OrganismeService"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import { EtablissementDTO } from "@/model/bean/GeneratedDTOs"
import CheckboxWithValidation from "@/views/inputs/CheckBoxWithValidation.vue"
import SubTitleView from "@/views/commons/SubTitleView.vue"
import H3TitleView from "@/views/commons/H3TitleView.vue"
import i18n from "@/i18n"

@Component({
  components: {
    ValidationObserver,
    InputWithValidation,
    ValidationProvider,
    CheckboxWithValidation,
    SubTitleView,
    H3TitleView,
  },
})
export default class EtablissementSelectionView extends Vue {
  private organismeService = OrganismeService.INSTANCE

  @Prop() value: EtablissementDTO
  @Prop({ default: true }) enableBack: boolean
  @Prop({ default: "" }) buttonLabel: string
  @Prop({ default: true }) showSpeech: boolean
  @Prop({ default: i18n.t("registerPage.chercher-etablissement") }) inputLabel: string
  @Prop({ default: true }) showBottomButtons: boolean
  @Prop({ default: i18n.t("registerPage.precedent") }) goBackLabel: string

  lastScheduledValidationTime = new Date().getTime()
  etablissementSearch = ""
  etablissementsFromApi: EtablissementDTO[] = []
  notFound = false
  selectedEtablissement = new EtablissementDTO()
  isLoading = false
  nbResultats = 0
  hasNext = true

  created(): void {
    if (this.value) {
      this.etablissementSearch = this.value.nom || ""
    }
  }

  setNotFound(notFound: boolean): void {
    this.notFound = notFound
  }

  @Watch("selectedEtablissement")
  selectedEtablissementChanged(): void {
    if (this.selectedEtablissement && this.selectedEtablissement.codeEtablissement) {
      this.etablissementSearch = this.selectedEtablissement.nom
      this.$emit("etablissement-selected", this.selectedEtablissement)
    }
  }

  async search(): Promise<void> {
    try {
      this.isLoading = true

      const etablissements = await this.organismeService.getEtablissementsFromAPI(
        this.etablissementSearch.toUpperCase(),
        this.nbResultats
      )
      this.setNotFound(etablissements.length === 0)
      if (etablissements.length !== 0) {
        this.etablissementsFromApi = this.etablissementsFromApi.concat(etablissements)
        this.nbResultats += etablissements.length
      }
      this.hasNext = etablissements.length >= 10
    } catch (error) {
      // Silent catch
    }
    this.isLoading = false
  }

  seeNext(): void {
    this.search()
  }

  resetSearch(): void {
    this.etablissementsFromApi = []
    this.nbResultats = 0
    this.hasNext = true
  }

  submit(): void {
    this.$emit("input-etablissement", this.selectedEtablissement)
  }

  goBack(): void {
    this.$emit("go-back", this.selectedEtablissement)
  }
}
