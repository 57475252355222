












import { Component, Vue } from "vue-property-decorator"
import PageView from "@/views/commons/PageView.vue"
import ContentView from "@/views/commons/ContentView.vue"
import ModifProjetView from "./ModifProjetView.vue"

@Component({
  components: {
    PageView,
    ContentView,
    ModifProjetView,
  },
})
export default class ModifProjetPage extends Vue {
  hasSubTitle = true
  subTitle: string | undefined = ""
}
