





































































































































import { ValidationProvider } from "vee-validate"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import FormInputLabel from "./FormInputLabel.vue"

@Component({
  components: {
    ValidationProvider,
    FormInputLabel,
  },
})
export default class RadioWithInputAndValidation extends Vue {
  @Prop({ default: "" }) group: string
  @Prop() vid: string
  @Prop() rules: [string]
  @Prop() value: string
  @Prop() tooltip: string
  @Prop() data: [string]
  @Prop() optionLabels: [string]
  @Prop() inputLabel: string
  @Prop() inputNotice: string
  @Prop({ default: "" }) inputPlaceholder: string
  @Prop() verticalDisplay: boolean
  @Prop() helpNotices: string
  @Prop() selectLabel: string
  @Prop() selectData: [string]
  @Prop({ default: "" }) selectPlaceholder: string
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: "" }) errorMessage: string
  @Prop({ default: true }) validationActivated: boolean
  @Prop({ default: true }) showValidationErrors: boolean

  inputValue = ""
  innerValue = ""
  inputType = ""
  selectValue = ""
  selectType = ""
  isMandatory = false
  rulesWithoutRequiredRulesIfValidationDisabled = ""

  created(): void {
    if (this.value !== null || this.value !== undefined) {
      this.innerValue = this.value
    }
    this.isMandatory = this.rules && this.rules.includes("required")
    this.onRulesChanged()
  }

  @Watch("innerValue")
  onInnerValueChanged(newVal: string, _oldValue: string): void {
    this.inputType = ""
    this.selectType = ""
    if (Boolean(this.inputLabel) && this.innerValue === this.inputLabel) {
      this.inputType = this.inputValue ? "is-success" : "is-danger"
    }
    if (Boolean(this.selectLabel) && this.innerValue === this.selectLabel) {
      this.selectType = this.selectValue ? "is-success" : "is-danger"
    }
    this.$emit("input", newVal)
  }

  @Watch("value")
  onExternalValueChanged(newVal: string, _oldValue: string): void {
    this.innerValue = newVal
    if (newVal != _oldValue) {
      this.$emit("manual-change", newVal)
    }
  }

  onInputChange(): void {
    this.inputType = this.inputValue ? "is-success" : "is-danger"
    this.$emit("input-change", this.inputValue)
  }

  onSelectChange(): void {
    this.selectType = this.selectValue ? "is-success" : "is-danger"
    this.$emit("select-change", this.selectValue)
  }

  @Watch("rules")
  onRulesChanged(): void {
    this.isMandatory = this.rules && this.rules.includes("required")
    if (this.rules) {
      this.rulesWithoutRequiredRulesIfValidationDisabled = this.rules.toString()
      if (!this.validationActivated) {
        this.rulesWithoutRequiredRulesIfValidationDisabled = this.rules
          .toString()
          .replace("required|", "")
          .replace("|required", "")
          .replace("required", "")
      }
    } else {
      this.rulesWithoutRequiredRulesIfValidationDisabled = ""
    }
  }

  @Watch("validationActivated")
  onValidationActivated(): void {
    this.onRulesChanged()
  }
}
