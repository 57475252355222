var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "InputLabel-fieldLabel" },
    [
      _c("div", [
        _c("div", {
          staticClass: "innerLabel",
          domProps: { innerHTML: _vm._s(_vm.label) }
        }),
        _vm.inputNotice
          ? _c("div", {
              staticClass: "inputNotice",
              domProps: { innerHTML: _vm._s(_vm.inputNotice) }
            })
          : _vm._e()
      ]),
      (_vm.showValidationErrors ||
        (!_vm.disabled && _vm.validationActivated) ||
        (!_vm.isPorteurProjet && _vm.validationActivated)) &&
      _vm.label
        ? _c("div", [
            _vm.isMandatory
              ? _c("div", { staticClass: "mandatory" }, [
                  _vm._v(_vm._s(_vm.$t("obligatoire")))
                ])
              : _c("div", { staticClass: "mandatory" }, [
                  _vm._v(_vm._s(_vm.$t("facultatif")))
                ])
          ])
        : _vm._e(),
      _vm.tooltip && _vm.tooltip.length > 0 && !_vm.disabled
        ? _c(
            "b-tooltip",
            {
              attrs: {
                type: "is-dark",
                label: _vm.tooltip,
                size: "is-large",
                multilined: "",
                position: "is-left"
              }
            },
            [
              _c("b-icon", {
                attrs: { size: "is-small", icon: "question-circle" }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }