

































import { ProjetAnnuelDTO } from "@/model/bean/GeneratedDTOs"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import FileWithValidation from "@/views/inputs/FileWithValidation.vue"
import { FileService } from "@/services/rest/utils/FileService"

@Component({ components: { InputWithValidation, FileWithValidation } })
export default class AutresInfosView extends Vue {
  private fileService = FileService.INSTANCE

  @Prop() projetAnnuel: ProjetAnnuelDTO
  @Prop() readonly: boolean
  @Prop() isPorteurProjet: boolean
  @Prop({ default: false }) validationActivated: boolean
  @Prop({ default: true }) showValidationErrors: boolean

  autresInfosFileUrl = ""

  created(): void {
    if (this.projetAnnuel.id) {
      this.autresInfosFileUrl = this.fileService.buildUniqueFileURL(
        this.projetAnnuel.id,
        "FICHIER_COMPLEMENTAIRE"
      )
    }
  }

  @Watch("projetAnnuel")
  dossierChanged(): void {
    if (!this.projetAnnuel || !this.projetAnnuel.id) {
      return
    }
    this.autresInfosFileUrl = this.fileService.buildUniqueFileURL(
      this.projetAnnuel.id,
      "FICHIER_COMPLEMENTAIRE"
    )
  }

  changeAutresInfos(): void {
    this.$emit("dirtify-project")
  }

  addFile(): void {
    this.projetAnnuel.autresInfosFilePresent = true
    this.$emit("trigger-validation")
  }

  deleteFile(): void {
    this.projetAnnuel.autresInfosFilePresent = false
    this.$emit("trigger-validation")
  }
}
