var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "instruction-pannel-entry" },
    [
      _c("h2", { staticClass: "h2" }, [
        _vm._v(
          _vm._s(
            _vm.$t("instruction-pannel.labellisation-mon-evaluation.title")
          )
        )
      ]),
      _c("Evaluation", {
        attrs: {
          projetAnnuel: _vm.project,
          evaluation: _vm.evaluation,
          instructionPannelWidth: _vm.instructionPannelWidth,
          readOnly: _vm.project.evaluationFinaleValidated,
          isEvaluationFinale: false
        },
        on: { "save-evaluation": _vm.saveEvaluation }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }