











































































import { Component, Prop, Vue, Watch } from "vue-property-decorator"

import RichInputTextWithValidation from "@/views/inputs/RichInputTextWithValidation.vue"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import UserInfoDetails from "@/views/profile/UserInfoDetails.vue"
import PasswordReset from "@/views/profile/PasswordReset.vue"
import TitleView from "@/views/commons/TitleView.vue"
import PageView from "@/views/commons/PageView.vue"

import {
  CourrielContactCreateDTO,
  FilterDTO,
  PorteursProjetEmailListDTO,
  UtilisateurDTO,
} from "@/model/bean/GeneratedDTOs"
import H3TitleView from "@/views/commons/H3TitleView.vue"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import { EmailService } from "@/services/rest/email/EmailService"
import { ValidationObserver } from "vee-validate"

@Component({
  components: {
    TitleView,
    PageView,
    RichInputTextWithValidation,
    InputWithValidation,
    UserInfoDetails,
    PasswordReset,
    H3TitleView,
    ValidationObserver,
  },
})
export default class ContactModale extends Vue {
  @Prop() open: boolean
  @Prop() filters?: Array<FilterDTO>
  @Prop() destinataires?: Array<UtilisateurDTO>
  @Prop() projetId?: string
  @Prop() total: number
  @Prop() graeId?: string

  private infoReportingService = InfoReportingService.INSTANCE
  private emailService = EmailService.INSTANCE

  ppEmailList = new PorteursProjetEmailListDTO()
  destinataireEnseignantCount = 0
  destinataireReferentCount = 0
  sumPPCount = 0
  isSending = false
  selectedRole = "Tous"

  courriel = new CourrielContactCreateDTO()

  get isFormInvalid(): boolean {
    // Vérifie si le titre et le contenu du courriel sont vides
    return !this.courriel.titre || !this.courriel.contenu
  }

  @Watch("open")
  async loadNumbers(): Promise<void> {
    this.ppEmailList = await this.emailService.getPPNumbersToSendTo(
      this.projetId,
      this.filters,
      this.graeId
    )
    this.destinataireEnseignantCount = this.ppEmailList.destinataireEnseignantEmails.length
    this.destinataireReferentCount = this.ppEmailList.destinataireReferentEmails.length
    this.sumPPCount = this.destinataireEnseignantCount + this.destinataireReferentCount
  }

  async sendCourrielAndClose(): Promise<void> {
    this.isSending = true
    this.setCourrielDestinataires()

    this.emailService
      .sendCourriel(this.projetId, this.filters, this.courriel, this.graeId)
      .then(() => {
        this.isSending = false
        this.infoReportingService.success(this.$i18n.t("contactModale.sendCourriel-ok").toString())
        this.emitCloseModale()
      })
      .catch((e) => {
        this.isSending = false
        this.infoReportingService.error(this.$i18n.t("contactModale.sendCourriel-ko").toString(), e)
      })
  }

  setCourrielDestinataires(): void {
    if (!this.destinataires) {
      this.courriel.destinataires = []
      if (this.selectedRole === "Enseignants") {
        this.courriel.destinataires.push(...this.ppEmailList.destinataireEnseignantEmails)
      } else if (this.selectedRole === "Référents") {
        this.courriel.destinataires.push(...this.ppEmailList.destinataireReferentEmails)
      } else {
        this.courriel.destinataires.push(...this.ppEmailList.destinataireEnseignantEmails)
        this.courriel.destinataires.push(...this.ppEmailList.destinataireReferentEmails)
      }
    } else {
      const selecteddestinataires =
        this.destinataires
          ?.filter((destinataire) => {
            if (this.selectedRole === "Enseignants") {
              return destinataire.profil === "ENSEIGNANT"
            } else if (this.selectedRole === "Référents") {
              return destinataire.profil === "REFERENT"
            }
            return true
          })
          .map((destinataire) => destinataire.courriel) || []

      // Supprimer les courriels dupliqués
      this.courriel.destinataires = Array.from(new Set(selecteddestinataires))
    }
  }

  emitCloseModale(): void {
    this.courriel = new CourrielContactCreateDTO()
    this.$emit("close-modale")
  }
}
