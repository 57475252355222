











































import { Component, Prop, Vue } from "vue-property-decorator"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import CheckboxWithValidation from "@/views/inputs/CheckBoxWithValidation.vue"
import { ValidationObserver } from "vee-validate"
import { LoginService } from "@/services/rest/login/LoginService"
import { AuthenticationDTO } from "@/model/bean/GeneratedDTOs"
import LoadingSkeleton from "../generic_components/LoadingSkeleton.vue"

@Component({
  components: {
    InputWithValidation,
    ValidationObserver,
    CheckboxWithValidation,
    LoadingSkeleton,
  },
})
export default class LoginView extends Vue {
  private loginService = LoginService.INSTANCE
  @Prop({ default: false }) showRegisterLink: boolean
  @Prop() embeded: boolean

  isLoggingIn = false
  email = ""
  password = ""
  logginError = ""
  acceptCookie = true

  mounted(): void {
    this.email = this.loginService.getLastSuccessfullEmail()
  }

  enterPressed(): void {
    this.submit()
  }

  submit(): void {
    this.isLoggingIn = true
    setTimeout(this.performLogin, 500)
  }

  async performLogin(): Promise<void> {
    try {
      const authentication = new AuthenticationDTO()
      authentication.courriel = this.email
      authentication.motDePasse = this.password

      await this.loginService.login(authentication)
      this.isLoggingIn = false
      // Nothing to do: user already re-routed after succesfull login (see LoginService.loginSucceeded())
    } catch (errorMessage) {
      this.isLoggingIn = false
      this.logginError = errorMessage as string
      if (this.logginError == this.$t("login-error-non-validated-email")) {
        this.$emit("hide-drop-down")
        this.$router.push({
          name: "RegisterPending",
          params: {
            errorMessage: this.$t("login-error-non-validated-email").toString(),
            registrationMessage: this.$t("registerPage.mailsent").toString(),
            mail: this.email,
            initialResendMode: "true",
          },
        })
      }
    }
  }
}
