/*-
 * #%L
 * Sagae
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
export default class PageChangeForbidder {
  static INSTANCE = new PageChangeForbidder()

  public pageChangeForbidden = false
  public allowPageChangeIfNewRouteHasTheSameName = false
  public callbackActionWhenForbidden = (_next: () => void): void => {
    // Do nothing
  }

  forbidPageChange(
    callbackActionWhenForbidden: (next: () => void) => void,
    browserMessageWhenPageIsReloaded: string,
    allowPageChangeIfNewRouteHasTheSameName: boolean
  ): void {
    this.pageChangeForbidden = true
    this.allowPageChangeIfNewRouteHasTheSameName = allowPageChangeIfNewRouteHasTheSameName
    this.callbackActionWhenForbidden = callbackActionWhenForbidden

    // Also define a custom event listener to warn at page refresh
    window.onbeforeunload = () => {
      //@ts-ignore
      window.event.returnValue = browserMessageWhenPageIsReloaded
      return browserMessageWhenPageIsReloaded
    }
  }

  allowPageChange(): void {
    this.pageChangeForbidden = false
    this.callbackActionWhenForbidden = (): void => {
      // Do nothing
    }
    //@ts-ignore
    window.onbeforeunload = undefined
  }
}
