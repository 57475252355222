


































import { Component, Prop, Vue } from "vue-property-decorator"
import TitleView from "@/views/commons/TitleView.vue"
import ContentView from "@/views/commons/ContentView.vue"
import PageView from "@/views/commons/PageView.vue"
import { ValidationObserver } from "vee-validate"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import { LoginService } from "@/services/rest/login/LoginService"
@Component({
  components: { TitleView, ContentView, PageView, InputWithValidation, ValidationObserver },
})
export default class ForgotPasswordPage extends Vue {
  @Prop({ default: "" }) defaultEmail: string
  passwordSent = false
  email = ""
  passworderror = ""

  private loginService = LoginService.INSTANCE

  mounted(): void {
    this.email = this.defaultEmail.trim()
  }

  submit(): void {
    this.loginService.forgotPassword(this.email).then(
      (_success) => {
        this.passwordSent = true
      },
      (_reject) => {
        this.passworderror = this.$i18n.t("forgotPassword.error").toString()
      }
    )
  }
}
