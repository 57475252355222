




import { Component, Vue } from "vue-property-decorator"
import TableConfiguration from "@/views/tables/TableConfiguration"
import { PaginationParameter, PaginationOrder, FilterDTO } from "@/model/bean/GeneratedDTOs"
import { SortOrder } from "@/model/SortOrder"
import Column from "./Column"
import TabParamsStore from "@/services/storage/TabParamsStore"

/**
 * Abstract class providing methods for sorting, paginating and handling b-tables.
 */
@Component({})
export default class AbstractTableView extends Vue {
  tableRef = "tableRef"
  isLoading = true
  public columns = new Array<Column>()
  // Indicates the paged parameters (pageNumber, pageSize, sorting options)
  currentPagedSortedParameter = new PaginationParameter()
  // Sorting priority at buefty format (mirros currentPageSortedParameter)
  multipleSortEnabled = true
  buefySortingPriority = new Array<BuefySortingPriority>()
  total = 0
  config = TableConfiguration.INSTANCE
  perPage = this.config.perPage

  // Filters
  activeFilters = new Array<FilterDTO>()

  created(): void {
    // Load lastly used columns or used lastly stored version
    const lastlyFilteredColumns = TabParamsStore.INSTANCE.getLastVisibleColumns(
      this.$router.currentRoute.path + this.tableRef
    )
    if (lastlyFilteredColumns.length > 0) {
      this.columns = lastlyFilteredColumns
    } else {
      this.columns = this.getInitialColumns()
    }

    // Load lastly used filters or used lastly stored version
    const lastlyActivatedFilters = TabParamsStore.INSTANCE.getLastFilters(
      this.$router.currentRoute.path + this.tableRef
    )
    const hasQueryFiltersInURL =
      this.$route.query &&
      this.$route.query.filters &&
      typeof this.$route.query.filters === "string"

    if (lastlyActivatedFilters.length > 0 && !hasQueryFiltersInURL) {
      this.activeFilters = lastlyActivatedFilters
    } else {
      this.activeFilters = this.getInitialActiveFilters()
    }

    // Load lastly used filters or used lastly stored version
    const lastlyUstedSort = TabParamsStore.INSTANCE.getLastSorts(
      this.$router.currentRoute.path + this.tableRef
    )
    if (lastlyUstedSort.length > 0) {
      this.currentPagedSortedParameter.orderClauses = lastlyUstedSort
    } else {
      this.currentPagedSortedParameter.orderClauses = this.getDefaultSort()
    }
  }

  mounted(): void {
    this.currentPagedSortedParameter.pageNumber = 1
    this.currentPagedSortedParameter.pageSize = this.perPage
    this.refreshBuefySortingPriority()
    if (this.reloadDataWhenMounted()) {
      this.reloadData()
    }
  }

  refreshInitialColumns(): void {
    // Re-evaluate initial columns now that all data has been retrieved
    const refreshedInitialColumns = this.getInitialColumns()
    // But keep current visibility
    refreshedInitialColumns.forEach((refreshedColummn) => {
      const oldCorrespondingColumn = this.columns.filter(
        (col) => col.field == refreshedColummn.field
      )
      if (oldCorrespondingColumn && oldCorrespondingColumn.length > 0) {
        refreshedColummn.visible = oldCorrespondingColumn[0].visible
      }
    })
    this.columns = refreshedInitialColumns
  }
  /**
   * Methods to implement in charge of reloading the table data.
   * This should take into account currentPage, sorting & filtering informations
   */
  async reloadData(): Promise<void> {
    // Subclasses will override this using currentPagedSortedParameter
    // also take activeFilters into accounts
    // Do not forget to update total attribute
  }

  /**
   * Return the inital columns list with default visibility.
   */
  getInitialColumns(): Array<Column> {
    return new Array<Column>()
  }

  /**
   * Reload data when table is mounted
   */
  reloadDataWhenMounted(): boolean {
    return true
  }

  getInitialActiveFilters(): Array<FilterDTO> {
    return new Array<FilterDTO>()
  }

  /**
   * Indicates the default sort to apply the b-table.
   */
  getDefaultSort(): Array<PaginationOrder> {
    // Subclasses will override this
    return new Array<PaginationOrder>()
  }

  /**
   * Updates the visible columns list.
   */
  updateVisibleColumns(newVisibleColumnList: Array<Column>): void {
    this.columns = newVisibleColumnList

    TabParamsStore.INSTANCE.storeVisibleColumns(
      this.$router.currentRoute.path + this.tableRef,
      newVisibleColumnList
    )
  }

  onPageChange(newPage: number): void {
    this.currentPagedSortedParameter.pageNumber = newPage
    this.reloadData()
  }

  onSort(field: string, order: SortOrder): void {
    if (!this.multipleSortEnabled) {
      // No multiple sorting, push a single sorting element
      const newSort = new PaginationOrder()
      newSort.clause = field
      newSort.desc = order == "desc"
      this.currentPagedSortedParameter.orderClauses = [newSort]
    } else {
      // Multiple sorting: search for existing sort to inverse its direction
      let foundOrderClause = false
      let i = 0
      while (!foundOrderClause && i < this.currentPagedSortedParameter.orderClauses.length) {
        const orderClause = this.currentPagedSortedParameter.orderClauses[i]
        if (orderClause.clause === field) {
          orderClause.desc = !orderClause.desc
          foundOrderClause = true
        }
        i++
      }
      if (!foundOrderClause) {
        const newSort = new PaginationOrder()
        newSort.clause = field
        newSort.desc = order == "desc"
        this.currentPagedSortedParameter.orderClauses.push(newSort)
      }
    }

    TabParamsStore.INSTANCE.storeSorts(
      this.$router.currentRoute.path + this.tableRef,
      this.currentPagedSortedParameter.orderClauses
    )
    this.refreshBuefySortingPriority()
    this.reloadData()
  }

  sortingPriorityRemoved(field: string): void {
    this.currentPagedSortedParameter.orderClauses = this.currentPagedSortedParameter.orderClauses.filter(
      (orderClause) => orderClause.clause !== field
    )
    if (this.currentPagedSortedParameter.orderClauses.length == 0) {
      this.currentPagedSortedParameter.orderClauses = this.getDefaultSort()
      // Cannot cast this as a B-Table, but it is the only possible type
      // @ts-ignore
      this.$refs[this.tableRef].resetMultiSorting()
    }

    TabParamsStore.INSTANCE.storeSorts(
      this.$router.currentRoute.path + this.tableRef,
      this.currentPagedSortedParameter.orderClauses
    )
    this.refreshBuefySortingPriority()
    this.reloadData()
  }

  refreshBuefySortingPriority(): void {
    this.buefySortingPriority = this.currentPagedSortedParameter.orderClauses.map(
      (orderClause): BuefySortingPriority => {
        const sortingPriority = new BuefySortingPriority()
        sortingPriority.field = orderClause.clause
        sortingPriority.order = orderClause.desc ? "desc" : "asc"
        return sortingPriority
      }
    )
  }

  updateActiveFilters(newActiveFilters: Array<FilterDTO>): void {
    this.activeFilters = newActiveFilters
    TabParamsStore.INSTANCE.storeFilters(
      this.$router.currentRoute.path + this.tableRef,
      newActiveFilters
    )
    this.reloadData()
  }
}

export class BuefySortingPriority {
  field: string
  order: SortOrder
}
