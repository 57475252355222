var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "full-content loading-dots" }, [
    _vm.loadingText
      ? _c("span", { domProps: { innerHTML: _vm._s(_vm.loadingText) } })
      : _vm._e(),
    !_vm.loadingText ? _c("span", [_vm._v("Chargement en cours")]) : _vm._e(),
    _c("span", {
      staticClass: "dot",
      domProps: { innerHTML: _vm._s(_vm.point) }
    }),
    _c("span", {
      staticClass: "dot",
      domProps: { innerHTML: _vm._s(_vm.point) }
    }),
    _c("span", {
      staticClass: "dot",
      domProps: { innerHTML: _vm._s(_vm.point) }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }