





















import { ProjetAnnuelDTO, UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import { ProjectService } from "@/services/rest/project/ProjectService"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"

@Component({
  components: {},
})
export default class StatutDossierTag extends Vue {
  @Prop() dossier: ProjetAnnuelDTO
  @Prop() loggedUser: UtilisateurDTO
  @Prop({ default: false }) canSeeInstructionComment: boolean

  status = "BROUILLON"
  projectService = ProjectService.INSTANCE

  displayComment = false
  comment = ""

  created(): void {
    this.init()
  }

  init(): void {
    if (this.dossier.statut == "BROUILLON") {
      this.status = this.dossier.statut + "_" + this.dossier.typeDossier
    } else {
      this.status = this.dossier.statut
    }

    this.configureComments()
  }

  configureComments(): void {
    this.comment = ""
    this.displayComment = false

    if (
      this.canSeeInstructionComment &&
      (this.dossier.statut === "EN_ATTENTE" || this.dossier.statut === "REFUSE") &&
      this.dossier.evaluationFinale.commentaireStatut
    ) {
      this.displayComment = true
      this.comment = this.dossier.evaluationFinale.commentaireStatut
      return
    }

    if (
      this.canSeeInstructionComment &&
      (this.dossier.statut === "ACCEPTE" ||
        this.dossier.statut === "ACCEPTE_SOUS_RESERVE_REFERENT" ||
        this.dossier.statut === "INSTRUCTION_EN_COURS") &&
      this.dossier.evaluationFinale.commentaireInstructeur
    ) {
      this.displayComment = true
      this.comment = this.dossier.evaluationFinale.commentaireInstructeur
      return
    }
  }

  @Watch("dossier")
  onDossierValueChanged(_newVal: string, _oldValue: string): void {
    this.init()
  }
}
