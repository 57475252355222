









































































































































import { Component, Prop, Vue } from "vue-property-decorator"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { OrganismeService } from "@/services/rest/organisme/OrganismeService"
import StructureUpdateView from "@/views/commons/StructureUpdateView.vue"
import { StructureDTO } from "@/model/bean/GeneratedDTOs"
import { REGISTER_STEPS } from "@/model/bean/EnumHelper"
import SubTitleView from "@/views/commons/SubTitleView.vue"
import CheckboxWithValidation from "@/views/inputs/CheckBoxWithValidation.vue"

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    StructureUpdateView,
    SubTitleView,
    CheckboxWithValidation,
  },
})
export default class StructureSelectionView extends Vue {
  private organismeService = OrganismeService.INSTANCE
  @Prop() value: StructureDTO
  @Prop({ default: false }) isChanging: boolean
  @Prop({ default: true }) enableBack: boolean

  selectedStructure = new StructureDTO()
  filteredStructures: StructureDTO[] = []
  needToAddStructure = false
  structureSearch = ""
  inputValid = false
  isAskConfirmationActive = false

  created(): void {
    if (this.value) {
      this.selectedStructure = this.value
      this.structureSearch = this.selectedStructure.nom || ""
    }
  }

  filterStructures(): void {
    const search = this.structureSearch.toLowerCase()
    this.inputValid = search.length >= 2
    this.organismeService.searchStructures(search).then((r) => {
      this.filteredStructures = []
      r.forEach((s) => this.filteredStructures.push(s))
    })
  }

  maybeAskConfirmation(): void {
    this.filteredStructures.length > 0
      ? (this.isAskConfirmationActive = true)
      : this.goAddStructure()
  }

  goAddStructure(): void {
    this.needToAddStructure = true
    this.updateURL(REGISTER_STEPS.STRUCTURE_ADDITION)
    this.isAskConfirmationActive = false
  }

  createStructure(): void {
    this.organismeService.addStructure(this.selectedStructure)
    this.structureSearch = this.selectedStructure.nom || ""
    this.filteredStructures.push(this.selectedStructure)
    this.needToAddStructure = false
    this.updateURL(REGISTER_STEPS.STRUCTURE_SELECTION)
    this.submit()
  }

  submit(): void {
    this.$emit("input-structure", this.selectedStructure)
  }

  goBack(): void {
    this.$emit("go-back", this.selectedStructure)
  }

  updateURL(nextStep: string): void {
    const params = this.$router.currentRoute.params
    if (nextStep !== params["registerStep"]) {
      params["registerStep"] = nextStep
      this.$router.push({ params: params })
    }
  }

  setTabIndex(tabIndex: number): void {
    //@ts-ignore
    this.$refs.structureUpdate && this.$refs.structureUpdate.setTabIndex(tabIndex)
  }

  emitTabChanged(tabIndex: number): void {
    this.$emit("tab-index-changed", tabIndex)
  }

  goToNextTab(): void {
    //@ts-ignore
    this.$refs.structureUpdate && this.$refs.structureUpdate.goToNextTab()
  }

  lastTabDisplayed(): boolean {
    //@ts-ignore
    return this.$refs.structureUpdate && this.$refs.structureUpdate.lastTabDisplayed()
  }

  goToPrevious(): void {
    const firstTabDisplayed =
      //@ts-ignore
      this.$refs.structureUpdate && this.$refs.structureUpdate.firstTabDisplayed()

    if (firstTabDisplayed) {
      this.needToAddStructure = false
    } else {
      //@ts-ignore
      this.$refs.structureUpdate && this.$refs.structureUpdate.goToPreviousTab()
    }
  }
}
