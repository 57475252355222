








import { Component, Vue } from "vue-property-decorator"
import PageView from "@/views/commons/PageView.vue"
import ContentView from "@/views/commons/ContentView.vue"
import MapClusterComponent from "@/views/maps/MapClusterComponent.vue"

@Component({
  components: {
    PageView,
    ContentView,
    MapClusterComponent,
  },
})
export default class CartePage extends Vue {}
