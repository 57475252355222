














































































































































import {
  FilterDTO,
  Grae,
  GraeDTO,
  GraeStatsDTO,
  PaginationOrder,
  UtilisateurDTO,
} from "@/model/bean/GeneratedDTOs"
import { OrganismeService } from "@/services/rest/organisme/OrganismeService"
import { Component } from "vue-property-decorator"
import AbstractTableView from "@/views/tables/AbstractTableView.vue"
import GraeListVueNational from "@/pages/grae/GraeListVueNational.vue"

import TitleView from "@/views/commons/TitleView.vue"
import PageView from "@/views/commons/PageView.vue"
import ContentView from "@/views/commons/ContentView.vue"
import TableFilterView from "@/views/tables/TableFilterView.vue"
import Column from "@/views/tables/Column"
import NotesModale from "./NotesModale.vue"
import { StatService } from "@/services/rest/stats/StatService"
import { LoginService } from "@/services/rest/login/LoginService"

@Component({
  components: {
    TitleView,
    PageView,
    ContentView,
    TableFilterView,
    NotesModale,
    GraeListVueNational,
  },
})
export default class GRAEListPage extends AbstractTableView {
  private organismeService = OrganismeService.INSTANCE
  private statService = StatService.INSTANCE
  private loginService = LoginService.INSTANCE

  graeList: Array<Grae> = []
  graesStats: Map<string, GraeStatsDTO> = new Map<string, GraeStatsDTO>()
  selectedGrae: GraeDTO = new GraeDTO()
  openNotesModale = false
  loggedUser: UtilisateurDTO | undefined = new UtilisateurDTO()
  isRegional = false
  instructedGraes: string[] = []
  role: string | undefined = ""

  created(): void {
    this.init()
    this.loadStats()
  }

  init(): void {
    this.loggedUser = this.loginService.getLoggedUser()
    if (this.loggedUser && this.loggedUser.instructedGraes) {
      this.instructedGraes = this.loggedUser.instructedGraes
    }
    this.role = this.loggedUser ? this.loggedUser.profil : ""
    this.isRegional = (this.loggedUser &&
      (this.loggedUser.profil === "ACCOMPAGNATEUR_REGIONAL" ||
        this.loggedUser.profil === "ANIMATEUR_REGIONAL") &&
      this.instructedGraes.length !== 0) as boolean
  }

  async loadStats(): Promise<void> {
    let stats: GraeStatsDTO[] = []
    if (this.loggedUser && this.isRegional && this.loggedUser.grae) {
      const promises = []
      this.instructedGraes.forEach(async (instructedGrae) => {
        promises.push(this.statService.graeStats(instructedGrae))
      })

      promises.push(this.statService.graeStats(this.loggedUser.grae.id))
      stats = await Promise.all(promises)
    } else {
      stats = await this.statService.graesStats()
    }
    this.graesStats = stats.reduce(
      (acc, curr) => acc.set(curr.graeId, curr),
      new Map<string, GraeStatsDTO>()
    )
  }

  async reloadData(): Promise<void> {
    this.isLoading = true

    this.currentPagedSortedParameter.pageSize = 50
    const activeFilters = await this.getActiveFiltersForApis()

    try {
      const paginatedGraeList = await this.organismeService.getGraes(
        this.currentPagedSortedParameter,
        activeFilters
      )
      this.graeList = paginatedGraeList.elements
      this.total = paginatedGraeList.count
    } catch (e) {
      // Silent catch
    }

    this.isLoading = false
  }

  async getActiveFiltersForApis(): Promise<FilterDTO[]> {
    //We want a copy of activeFilters
    const activeFilters = this.activeFilters.map((filter) => filter)
    if (this.isRegional && this.loggedUser && this.loggedUser.grae) {
      const graeFilter = new FilterDTO()
      graeFilter.property = "region"

      const allGraes = await this.organismeService.getAllGraes()

      graeFilter.values = [
        ...this.instructedGraes.map((instructedGrae) => {
          const foundGrae = allGraes.find((g) => g.id === instructedGrae)
          return foundGrae ? foundGrae.region : ""
        }),
      ]
      graeFilter.values.push(this.loggedUser.grae.region)

      // graeFilter.property = "grae"
      // graeFilter.values = this.instructedGraes
      // const allGraes = await this.organismeService.getAllGraes()

      // const foundGrae = allGraes.find(
      //   (g) => this.loggedUser && this.loggedUser.grae && g.region === this.loggedUser.grae.region
      // )
      // if (foundGrae) {
      //   graeFilter.values.push(foundGrae.id)
      // }

      activeFilters.push(graeFilter)
    }
    return activeFilters
  }
  canSeeNational(): boolean {
    if (
      this.loggedUser &&
      (this.loggedUser.profil === "OBSERVATEUR_NATIONAL" ||
        this.loggedUser.profil === "ACCOMPAGNATEUR_NATIONAL" ||
        this.loggedUser.profil === "SUPER_ADMINISTRATEUR")
    ) {
      return true
    }
    return false
  }
  getInitialColumns(): Array<Column> {
    // Re-init in case getInitialColumns goes before the created
    this.init()
    const initialColumns = [
      {
        field: "region",
        label: this.$t("graeList.nom").toString(),
        sortable: true,
        backEndSearchable: true,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "ate",
        label: this.$t("graeList.ate").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "ame",
        label: this.$t("graeList.ame").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "inscriptions-a-instruire",
        label: this.$t("graeList.inscriptions-a-instruire").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "inscriptions-brouillon",
        label: this.$t("graeList.inscriptions-brouillon").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "labellisations-instruites",
        label: this.$t("graeList.labellisations-instruites").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "labellisations-a-instruire",
        label: this.$t("graeList.labellisations-a-instruire").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "projets-pause",
        label: this.$t("graeList.projets-pause").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "projets-arretes",
        label: this.$t("graeList.projets-arretes").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
    ]

    if (!this.isRegional) {
      initialColumns.push({
        field: "notes",
        label: this.$t("graeList.notes").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      })
    }

    return initialColumns
  }

  getDefaultSort(): Array<PaginationOrder> {
    const defaultSort = new PaginationOrder()
    defaultSort.clause = "region"
    defaultSort.desc = false
    return [defaultSort]
  }

  showDetailsPage(event: Event, id: string, pendingRequest: number): void {
    // Do not foward click event to row (would trigger modal)
    event.stopPropagation()
    if (pendingRequest) {
      this.$router.push({
        path: "/grae/indicateurs/0/" + id,
      })
    } else {
      this.$router.push("/grae/indicateurs/0/" + id)
    }
  }

  openNotesDialog(event: Event, grae: GraeDTO): void {
    // Do not foward click event to row (would trigger modal)
    event.stopPropagation()
    this.selectedGrae = grae
    this.openNotesModale = true
  }
  shouldDisplayBadge(id: string): boolean {
    if (this.graesStats.get(id)) {
      const pendingRequest = this.graesStats.get(id)?.nbCompteAvalider
      if (this.role === "ACCOMPAGNATEUR_NATIONAL" && pendingRequest) {
        return pendingRequest > 0
      }
    }
    return false
  }
}
