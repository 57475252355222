var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationProvider", {
    attrs: {
      vid: (_vm.group != "" ? _vm.group + "_" : "") + _vm.vid,
      name: _vm.$attrs.name || _vm.$attrs.label,
      rules: _vm.rules
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var errors = ref.errors
          var valid = ref.valid
          return [
            _c(
              "b-field",
              {
                staticClass: "AutoCompleteWithValidation-field",
                attrs: {
                  type: {
                    "is-danger": errors[0],
                    "is-success": valid
                  },
                  message: errors
                }
              },
              [
                _c(
                  "template",
                  { slot: "label" },
                  [
                    _c("FormInputLabel", {
                      attrs: {
                        label: _vm.$attrs.label,
                        isMandatory: _vm.isMandatory,
                        tooltip: _vm.tooltip
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-autocomplete",
                  {
                    attrs: {
                      "open-on-focus": "",
                      placeholder: _vm.placeholder,
                      data: _vm.data,
                      icon: "search",
                      clearable: ""
                    },
                    on: { select: _vm.emitSelect },
                    model: {
                      value: _vm.innerValue,
                      callback: function($$v) {
                        _vm.innerValue = $$v
                      },
                      expression: "innerValue"
                    }
                  },
                  [
                    Boolean(_vm.emptyMessage)
                      ? _c("template", { slot: "empty" }, [
                          _vm.$attrs.clickOnEmpty
                            ? _c("a", { on: { click: _vm.onEmptyResult } }, [
                                _c("span", [
                                  _vm._v(" " + _vm._s(_vm.emptyMessage) + " ")
                                ])
                              ])
                            : _vm._e(),
                          !_vm.$attrs.clickOnEmpty
                            ? _c("div", [_vm._v(_vm._s(_vm.emptyMessage))])
                            : _vm._e()
                        ])
                      : _vm._e()
                  ],
                  2
                )
              ],
              2
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }