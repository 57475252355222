var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "projet-part" },
    [
      _c("h2", [
        _vm._v(_vm._s(_vm.$t("projetPage.mon-annee.problematiques.titre")))
      ]),
      _c("InputWithValidation", {
        attrs: {
          validationActivated: _vm.validationActivated,
          showValidationErrors: _vm.showValidationErrors,
          rules:
            _vm.projetAnnuel.typeDossier === "DOSSIER_RENOUVELLEMENT_LIGHT"
              ? ""
              : "required",
          type: "textarea",
          label: _vm.$t("projetPage.mon-annee.problematiques.problematiques"),
          inputNotice: _vm.$t(
            "projetPage.mon-annee.problematiques.problematiques-message"
          ),
          maxlength: "1400",
          disabled: _vm.readonly || !_vm.isPorteurProjet,
          vid: "problematiques_problematiquesIdentifiees"
        },
        on: { "manual-change": _vm.changeProblematiques },
        model: {
          value: _vm.projetAnnuel.problematiquesIdentifiees,
          callback: function($$v) {
            _vm.$set(_vm.projetAnnuel, "problematiquesIdentifiees", $$v)
          },
          expression: "projetAnnuel.problematiquesIdentifiees"
        }
      }),
      _vm.projetAnnuel.objectifAutre ||
      (_vm.projetAnnuel.objectifs && _vm.projetAnnuel.objectifs.length > 0)
        ? _c(
            "div",
            [
              _c("GroupCheckboxesWithValidation", {
                attrs: {
                  validationActivated: false,
                  showValidationErrors: false,
                  label:
                    "Comment avez-vous affiné ou redéfini le ou les objectif·s et la ou les action·s de votre aire éducative pour cette nouvelle année ?",
                  data: _vm.objectifCheckBoxesStrings,
                  verticalDisplay: "true",
                  inputLabel: "<b>Autre</b>",
                  extInputValue: _vm.projetAnnuel.objectifAutre,
                  vid: "objectifs",
                  disabled: _vm.readonly || !_vm.isPorteurProjet
                },
                model: {
                  value: _vm.checkedObjectifCheckBoxesStrings,
                  callback: function($$v) {
                    _vm.checkedObjectifCheckBoxesStrings = $$v
                  },
                  expression: "checkedObjectifCheckBoxesStrings"
                }
              }),
              _c("br")
            ],
            1
          )
        : _vm._e(),
      _c("InputWithValidation", {
        attrs: {
          validationActivated: _vm.validationActivated,
          showValidationErrors: _vm.showValidationErrors,
          rules:
            _vm.projetAnnuel.typeDossier === "DOSSIER_RENOUVELLEMENT"
              ? "required"
              : "",
          type: "textarea",
          label: _vm.$t("projetPage.mon-annee.problematiques.actions"),
          inputNotice: _vm.$t(
            "projetPage.mon-annee.problematiques.actions-message"
          ),
          maxlength: "1400",
          disabled: _vm.readonly || !_vm.isPorteurProjet,
          vid: "problematiques_procedesDeterminationActions"
        },
        on: { "manual-change": _vm.changeProblematiques },
        model: {
          value: _vm.projetAnnuel.procedesDeterminationActions,
          callback: function($$v) {
            _vm.$set(_vm.projetAnnuel, "procedesDeterminationActions", $$v)
          },
          expression: "projetAnnuel.procedesDeterminationActions"
        }
      }),
      _vm.validationActivated &&
      (_vm.projetAnnuel.typeDossier === "DOSSIER_LABELLISATION" ||
        _vm.projetAnnuel.typeDossier === "DOSSIER_RENOUVELLEMENT") &&
      _vm.showRequiredMessage
        ? _c("b-message", { attrs: { type: "is-danger" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "projetPage.mon-annee.autres-infos.labellisation-renouvellement"
                  )
                ) +
                " "
            )
          ])
        : _vm._e(),
      _c("InputWithValidation", {
        attrs: {
          validationActivated: _vm.validationActivated,
          showValidationErrors: _vm.showValidationErrors,
          type: "textarea",
          label: _vm.$t("projetPage.mon-annee.problematiques.liens"),
          rules:
            _vm.projetAnnuel.typeDossier === "DOSSIER_RENOUVELLEMENT_LIGHT"
              ? ""
              : _vm.calculatedRules,
          maxlength: "1400",
          disabled: _vm.readonly || !_vm.isPorteurProjet,
          vid: "problematiques_problematiquesLiens"
        },
        on: { "manual-change": _vm.changeProblematiques },
        model: {
          value: _vm.projetAnnuel.problematiquesLiens,
          callback: function($$v) {
            _vm.$set(_vm.projetAnnuel, "problematiquesLiens", $$v)
          },
          expression: "projetAnnuel.problematiquesLiens"
        }
      }),
      _c("InputWithValidation", {
        attrs: {
          validationActivated: _vm.validationActivated,
          showValidationErrors: _vm.showValidationErrors,
          type: "textarea",
          label: _vm.$t("projetPage.mon-annee.problematiques.productions"),
          maxlength: "1400",
          disabled: _vm.readonly || !_vm.isPorteurProjet,
          vid: "problematiques_productionsEleves"
        },
        on: { "manual-change": _vm.changeProblematiques },
        model: {
          value: _vm.projetAnnuel.productionsEleves,
          callback: function($$v) {
            _vm.$set(_vm.projetAnnuel, "productionsEleves", $$v)
          },
          expression: "projetAnnuel.productionsEleves"
        }
      }),
      _c("FileWithValidation", {
        attrs: {
          validationActivated: _vm.validationActivated,
          showValidationErrors: _vm.showValidationErrors,
          placeholder: _vm.$t(
            "projetPage.infoAdministratives.charte.televerser"
          ),
          inputNotice: _vm.$t(
            "projetPage.mon-annee.problematiques.productions-message"
          ),
          fileOperationUrl: _vm.problematiquesFileUrl,
          disabled: _vm.readonly,
          vid: "problematiques_fichierProblematiques",
          value: _vm.projetAnnuel.problematiquesFilePresent,
          defaultFileName: "PROBLEMATIQUES.pdf"
        },
        on: { "add-file": _vm.addFile, "delete-file": _vm.deleteFile }
      }),
      _c("FileWithValidation", {
        attrs: {
          validationActivated: _vm.validationActivated,
          showValidationErrors: _vm.showValidationErrors,
          placeholder: _vm.$t(
            "projetPage.infoAdministratives.charte.televerser"
          ),
          inputNotice: _vm.$t(
            "projetPage.mon-annee.problematiques.transdis-message"
          ),
          fileOperationUrl: _vm.activitesTransDisFileUrl,
          disabled: _vm.readonly,
          vid: "problematiques_fichierProblematiques",
          value: _vm.projetAnnuel.activitesTransDisrFilePresent,
          defaultFileName: "PROBLEMATIQUES.pdf"
        },
        on: {
          "add-file": _vm.addTransdisFile,
          "delete-file": _vm.deleteTransdisFile
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }