
















































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import * as html2pdf from "html2pdf.js"
import { ProjetAnnuelDTO, TypeDossier } from "@/model/bean/GeneratedDTOs"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import TitleView from "@/views/commons/TitleView.vue"
import { NIVEAUX } from "@/model/bean/EnumHelper"
import { ProjectService } from "@/services/rest/project/ProjectService"

@Component({
  components: { TitleView },
})
export default class ProjectPDFExport extends Vue {
  @Prop() project: ProjetAnnuelDTO
  @Prop() exportingPDF: boolean
  @Prop() withPhotos: boolean
  @Prop() mapDataUrl: string
  @Prop() imagesUrl: string[]
  private infoReportingService = InfoReportingService.INSTANCE
  private projetService = ProjectService.INSTANCE

  progress = 0
  totalEleves = 0
  niveauxLabel = ""
  anneeInscription = ""

  async created(): Promise<void> {
    const periods = await this.projetService.getPeriods(this.project.projetId)
    const max = periods.length
    this.anneeInscription =
      new Date(periods[max - 1].anneeScolaireDebut).getFullYear() +
      "-" +
      new Date(periods[max - 1].anneeScolaireFin).getFullYear()
  }

  @Watch("exportingPDF")
  exportPDFRequest(exportingPDF: boolean): void {
    if (exportingPDF) {
      this.preparePDF()
      this.progress = 10
      this.increaseProgress()
      setTimeout(() => {
        this.exportPDF()
      }, 200)
    }
  }

  preparePDF(): void {
    this.generateClasseStrings()
  }

  getStatutLabel(typeDossier: TypeDossier): string {
    switch (typeDossier) {
      case "DOSSIER_INSCRIPTION":
        return this.$t("IndicateursPage.inscription").toString()
      case "DOSSIER_LABELLISATION":
        return this.$t("IndicateursPage.labellisation").toString()
      case "DOSSIER_RENOUVELLEMENT":
      case "DOSSIER_RENOUVELLEMENT_LIGHT":
        return this.$t("IndicateursPage.renouvellement").toString()
      default:
        return typeDossier
    }
  }

  generateClasseStrings(): void {
    if (!this.project.classes || this.project.classes.length === 0) {
      return
    }
    const niveaux: string[] = []
    const autreLabels: string[] = []

    this.project.classes.forEach((classe) => {
      //@ts-ignore
      Object.entries(NIVEAUX).forEach(([niveau, niveauLabel]) => {
        //@ts-ignore
        const nbreEleve = classe[niveau]

        if (nbreEleve) {
          this.totalEleves += nbreEleve
          if (niveaux.indexOf(niveauLabel as string) === -1) {
            niveaux.push(niveauLabel)
          }
        }
      })
      if (classe.autre1) {
        this.totalEleves += classe.eleves_autre1
        if (autreLabels.indexOf(classe.autre1) === -1) {
          autreLabels.push(classe.autre1)
        }
      }

      if (classe.autre2) {
        this.totalEleves += classe.eleves_autre2
        if (autreLabels.indexOf(classe.autre2) === -1) {
          autreLabels.push(classe.autre2)
        }
      }
    })

    const niveauxLabels: string[] = []

    Object.values(NIVEAUX).forEach((niveauLabel) => {
      if (niveaux.indexOf(niveauLabel) !== -1) {
        niveauxLabels.push(
          this.$t(`projetPage.infoAdministratives.classe.niveauxLabels.${niveauLabel}`).toString()
        )
      }
    })

    const allNiveauxLabels = [...niveauxLabels, ...autreLabels]

    const nbreNiveaux = allNiveauxLabels.length
    allNiveauxLabels.forEach((niveau, index) => {
      if (index === 0) {
        //@ts-ignore
        this.niveauxLabel = niveau
      } else if (index === nbreNiveaux - 1) {
        this.niveauxLabel += ` et ${niveau}`
      } else {
        this.niveauxLabel += `, ${niveau}`
      }
    })
  }

  async exportPDF(): Promise<void> {
    try {
      const dateString = new Date().toISOString().substring(0, 10)
      const pdfFileName =
        this.project.natureAireEducative +
        "_" +
        this.project.nom
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(" ", "_") +
        "_" +
        dateString +
        ".pdf"
      var element = document.getElementById("pdf-target")
      var clone = this.createPDFHTML(element as HTMLElement)
      // All options here
      // https://ekoopmans.github.io/html2pdf.js/#options
      const opt = {
        margin: 0,
        filename: pdfFileName,
        image: { type: "jpg", quality: 0.98 },
        pagebreak: { mode: ["avoid-all"], before: ".pdf-page-break" },
        html2canvas: {
          scale: 1.5,
          useCORS: true,
        },
        jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
      }
      await html2pdf().from(clone).set(opt).toContainer().toCanvas().toImg().toPdf().save()
    } catch (exception) {
      console.error(exception)
      this.infoReportingService.error(this.$t("pdf-export-error").toString(), new Error())
    }
    this.$router.push({
      query: {},
    })
    this.$emit("export-done", true)
  }

  createPDFHTML(original: HTMLElement): HTMLElement {
    const pdfClone = original?.cloneNode(true) as HTMLElement
    pdfClone.classList.remove("hidden")
    return pdfClone
  }

  increaseProgress(): void {
    if (this.progress < 98) {
      this.progress += 3
    }
    setTimeout(this.increaseProgress, 50)
  }

  convertToBase64(e: Event): void {
    // @ts-ignore
    const origin = e.currentTarget as HTMLImageElement
    var canvas = document.createElement("canvas")
    const dataURL = this.getDataUrl(origin, canvas)
    // @ts-ignore
    origin?.setAttribute("src", dataURL)
  }

  getDataUrl(img: HTMLImageElement, canvas: HTMLCanvasElement): string {
    // Create canvas
    // @ts-ignore
    const ctx = canvas.getContext("2d")

    var width = 0
    var height = 0

    if (img.naturalWidth < img.naturalHeight) {
      width = img.naturalWidth
      height = img.naturalWidth
    } else {
      width = img.naturalHeight
      height = img.naturalHeight
    }

    canvas.width = width
    canvas.height = height

    // Draw the image
    //@ts-ignore
    ctx.drawImage(img, 0, 0, width, height, 0, 0, width, height)
    // @ts-ignore convert as base 64
    return canvas.toDataURL("image/jpeg")
  }
}
