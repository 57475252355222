




































































import {
  latLng,
  Icon,
  FeatureGroup,
  LatLngBounds,
  canvas,
  Control,
  LatLng as LatLngType,
} from "leaflet"
import * as L from "leaflet"

import "leaflet-draw"
import "leaflet.markercluster"

type D = Icon.Default & {
  _getIconUrl?: string
}
delete (Icon.Default.prototype as D)._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
})
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import "leaflet/dist/leaflet.css"
import regionsGeoJSON from "@/assets/leaflet/regions.geojson"
import TitleView from "@/views/commons/TitleView.vue"
import {
  AireEducativeCartoDTO,
  AireEducativeCartoDetailsDTO,
  CoordinateDTO,
  UtilisateurDTO,
} from "@/model/bean/GeneratedDTOs"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import InputWithValidation from "../inputs/InputWithValidation.vue"
import SelectWithValidation from "../inputs/SelectWithValidation.vue"
import i18n from "@/i18n"
import * as leafletImage from "leaflet-image"
import { GraeService } from "@/services/rest/grae/GraeService"
import ProjectDetails from "@/views/maps/ProjectDetails.vue"

import "mapbox-gl-leaflet/leaflet-mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
/* eslint-disable @typescript-eslint/no-var-requires */
const wemapStyle = require("@/assets/leaflet/wemapStyle.json")

const TILE_URLS = {
  OPEN_STREET_MAP: "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png",
  SATELLITE: "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png",
}

const TILE_CHOICES = {
  AUTO: i18n.t("mapDraw.tile-choices.auto"),
  SATELLITE: i18n.t("mapDraw.tile-choices.pref-satellite"),
  OSM: i18n.t("mapDraw.tile-choices.pref-osm"),
}

@Component({
  components: {
    TitleView,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    InputWithValidation,
    SelectWithValidation,
    ProjectDetails,
  },
})
export default class MapDrawWithClusterView extends Vue {
  @Prop({}) zoneGeographique: string
  @Prop({}) projets: AireEducativeCartoDTO[]
  @Prop({}) loggedUser: UtilisateurDTO | undefined
  @Prop() mapHasFocus: boolean
  @Prop({ default: "" }) group: string
  @Prop() readonly: boolean
  @Prop() guest: boolean
  @Prop() role: string | undefined
  @Prop() mapScreenShotRequested: boolean
  @Prop({ default: false }) validationActivated: boolean
  @Prop({ default: true }) showValidationErrors: boolean

  mapVisible = false
  showDetails = false
  userRegionId: string | undefined
  instructedGraes: string[]
  iconDetails: string | undefined
  coordinates = new CoordinateDTO()
  private mapInitialized = false
  private initialLoadComplete = false

  private infoReportingService = InfoReportingService.INSTANCE
  private graeService = GraeService.INSTANCE

  ae = new AireEducativeCartoDTO()
  selectedProject = new AireEducativeCartoDetailsDTO()
  // @ts-ignore
  drawControl: Control.Draw
  // @ts-ignore
  drawControlEditOnly: Control.Draw
  drawnItems: FeatureGroup = new window.L.FeatureGroup()
  tileURL = TILE_URLS.OPEN_STREET_MAP

  tileChoicesLabels = Object.values(TILE_CHOICES)
  tileChoice = TILE_CHOICES.OSM

  innerZoneGeographique = ""
  userRegionName: string | undefined

  created(): void {
    if (this.zoneGeographique && this.zoneGeographique !== "null") {
      this.innerZoneGeographique = this.zoneGeographique
    }
    if (this.loggedUser) {
      this.coordinates = this.loggedUser.coordinates
      this.userRegionId = this.loggedUser.grae?.id
      this.instructedGraes = this.loggedUser.instructedGraes
      this.userRegionName = this.loggedUser.grae?.region
    }
    this.revealMapOnlyIfNeeded(this.mapHasFocus)
    this.mapIsReady()
    this.initialLoadComplete = true
  }

  @Watch("loggedUser")
  loggedUserChanged(
    newUser: UtilisateurDTO | undefined,
    _oldFocus: UtilisateurDTO | undefined
  ): void {
    // @ts-ignore
    this.coordinates = newUser?.coordinates
    // @ts-ignore
    this.userRegionId = this.loggedUser.grae?.id
    this.userRegionName = this.loggedUser?.grae?.region
    this.instructedGraes = this.loggedUser?.instructedGraes ?? []
  }

  @Watch("mapHasFocus")
  mapFocusChanged(newFocus: boolean, _oldFocus: boolean): void {
    this.revealMapOnlyIfNeeded(newFocus)
  }

  @Watch("zoneGeographique")
  onExternalZoneChanged(): void {
    this.innerZoneGeographique = this.zoneGeographique === "null" ? "" : this.zoneGeographique
  }

  @Watch("guest")
  guestChanged(): void {
    this.updateMapCenterAndZoom()
  }

  onZoneChanged(): void {
    this.$emit("zone-update", this.innerZoneGeographique)
  }

  get initialCenter(): LatLngType {
    const long = 0
    const lat = 0
    return this.coordinates?.latitude && this.coordinates?.longitude
      ? latLng(this.coordinates.latitude, this.coordinates.longitude)
      : latLng(lat, long)
  }

  get initialZoom(): number {
    if (this.role === "ENSEIGNANT") {
      return this.coordinates?.latitude ? 12 : 2
    } else {
      return this.coordinates?.latitude ? 10 : 2
    }
  }

  mapIsReady(): void {
    if (this.mapInitialized) {
      return // Exit if the map is already initialized
    }

    // Existing map initialization code
    // @ts-ignore
    const map = this.$refs.map.mapObject
    const bounds = new LatLngBounds([])

    try {
      if (this.innerZoneGeographique) {
        const zoneGeoParse = JSON.parse(this.innerZoneGeographique)
        if (zoneGeoParse.geometry && zoneGeoParse.geometry.coordinates) {
          zoneGeoParse.geometry.coordinates.forEach((geoCoord: any) => {
            for (let i = 0; i < geoCoord.length; i++) {
              bounds.extend([geoCoord[i][1], geoCoord[i][0]])
            }
          })
        }
        const canvasRenderer = canvas({ padding: 0.5 })
        // @ts-ignore
        this.drawnItems = window.L.geoJSON(zoneGeoParse, { renderer: canvasRenderer })
      } else {
        const canvasRenderer = canvas({ padding: 0.5 })
        // @ts-ignore
        this.drawnItems = new window.L.FeatureGroup([], { renderer: canvasRenderer })
      }

      map.addLayer(this.drawnItems)

      this.modifyLocale()
      map.zoomControl.remove()
      window.L.control
        .zoom({
          zoomInTitle: this.$t("mapDraw.zoomin").toString(),
          zoomOutTitle: this.$t("mapDraw.zoomout").toString(),
        })
        .addTo(map)

      // @ts-ignore
      this.drawControl = new window.L.Control.Draw({
        position: "topleft",
        draw: {
          polyline: false,
          polygon: true,
          rectangle: false,
          circle: false,
          marker: false,
          circlemarker: false,
        },
      })

      this.drawControlEditOnly = this.getEditControl()

      if (!this.readonly) {
        map.addControl(this.innerZoneGeographique ? this.drawControlEditOnly : this.drawControl)
      }

      if (this.initialCenter && this.initialZoom) {
        // map.setView(this.initialCenter, this.initialZoom)
        if (this.userRegionName) {
          this.zoomToRegion(this.userRegionName)
        } else {
          map.setView(this.initialCenter, this.initialZoom)
        }
      } else if (bounds.isValid()) {
        const padding = 4
        map.fitBounds(bounds, { padding: [padding, padding] })
      } else {
        console.error("Bounds are not valid. Falling back to default location.")
        map.panTo(latLng(46.227, 2.213), 2)
      }
    } catch (error) {
      console.error("An error occurred while setting up the map:", error)
      map.panTo(latLng(46.227, 2.213), 2)
    }

    this.createMapScreenshot()
    this.updateMarkers() // Initial markers setup
    // Contour de la région
    // @ts-ignore
    regionsGeoJSON.features.forEach((feature) => {
      const regionNom = feature.properties.nom
      L.geoJSON(feature, {
        style: {
          weight: 1,
          opacity: 0.5,
          color: "grey",
          fillOpacity: 0,
        },
        onEachFeature: (feature, layer) => {
          layer.on("click", () => {
            this.zoomToRegion(regionNom)
          })
        },
      }).addTo(map)
    })
    const accessToken =
      "pk.eyJ1IjoiYW5haXNqdXN0IiwiYSI6ImNseW9pbmlsajBpaG8yaXM1dXJmcml4cHEifQ.XhN39UtE1RLxWXARCBLBPg"
    L.mapboxGL({
      accessToken: accessToken,
      style: wemapStyle, // You can use your desired Mapbox style here
    }).addTo(map)

    this.mapInitialized = true
  }

  updateMarkers(): void {
    // @ts-ignore
    const map = this.$refs.map?.mapObject
    const bounds = new LatLngBounds([])

    // Store the current center and zoom level
    const currentCenter = map.getCenter()
    const currentZoom = map.getZoom()

    const markersCluster = L.markerClusterGroup({
      spiderfyOnMaxZoom: true, // Disable spiderfying behavior
      showCoverageOnHover: false, // Disable coverage polygon on hover
      spiderLegPolylineOptions: {
        weight: 0, // Set the weight to 0 to effectively disable drawing lines
      },
      iconCreateFunction: (cluster: any) => {
        const clusterSize = cluster.getChildCount()
        return L.divIcon({
          html: `<div style="position: relative;"> 
              <img src="AE.ico" style="width: 44px; height: 44px; transition: width 0.3s ease, height 0.3s ease;-webkit-filter: drop-shadow(1px 2px 2px #222);filter: drop-shadow(1px 2px 2px #222);"
                   onmouseover="this.style.width='52px'; this.style.height='52px';"
                   onmouseout="this.style.width='44px'; this.style.height='44px';" />
              <div style="position: absolute; top: -10px; right: -10px; background: white; color: black; border-radius: 50%; width: 24px; height: 24px; display: flex; align-items: center; justify-content: center;">
                  ${clusterSize}
              </div>
            </div>`,
          className: "mycluster",
          iconSize: undefined,
        })
      },
    })
    // markersCluster
    //   .on("clustermouseover", function () {})
    //   .on("clustermouseouts", function () {})
    this.projets.forEach((projet: AireEducativeCartoDTO) => {
      const markerLocation = this.getMarkerLocation(projet)
      if (markerLocation.lat !== 0 && markerLocation.lng !== 0) {
        bounds.extend(markerLocation)

        const iconATE = "/ATE.ico"
        const iconAME = "/AME.ico"
        const iconATER = "/ATE_R.ico"
        const iconAMER = "/AME_R.ico"
        const iconAMENV = "/blueCircle.ico"
        const iconATENV = "/greenCircle.ico"
        let iconpic = "/greenCircle.ico"

        // algo affichage icone aire educatives carto
        if (projet.typeDossier === "DOSSIER_INSCRIPTION") {
          if (
            ["ACCEPTE", "ACCEPTE_SOUS_RESERVE_REFERENT", "AVIS_PASSABLE"].includes(
              projet.statutDossier
            )
          ) {
            iconpic = projet.natureAireEducative === "ATE" ? iconATENV : iconAMENV
          }
        } else {
          if (
            projet.projetAnnuelIdPrecedent &&
            projet.typeDossierPrecedent &&
            projet.statutDossierPrecedent &&
            [
              "DOSSIER_LABELLISATION",
              "DOSSIER_RENOUVELLEMENT",
              "DOSSIER_RENOUVELLEMENT_LIGHT",
            ].includes(projet.typeDossierPrecedent)
          ) {
            if (
              ["ACCEPTE", "AVIS_PASSABLE", "LABELLISATION_EN_DEVENIR"].includes(
                projet.statutDossierPrecedent
              )
            ) {
              iconpic = projet.natureAireEducative === "ATE" ? iconATER : iconAMER
            } else if (
              projet.statutDossierPrecedent === "REFUSE" ||
              (projet.typeDossierPrecedent === "DOSSIER_LABELLISATION" &&
                projet.statutDossierPrecedent === "BROUILLON")
            ) {
              iconpic = projet.natureAireEducative === "ATE" ? iconATENV : iconAMENV
            } else if (
              (projet.typeDossierPrecedent === "DOSSIER_RENOUVELLEMENT" ||
                projet.typeDossierPrecedent === "DOSSIER_RENOUVELLEMENT_LIGHT") &&
              projet.statutDossierPrecedent === "BROUILLON"
            ) {
              iconpic = projet.natureAireEducative === "ATE" ? iconATE : iconAME
            }
          }
        }

        const myIcon = L.icon({
          iconUrl: iconpic,
          iconSize: [60, 60],
          iconAnchor: [30, 60],
          className: "marker-pic",
        })
        const bigIcon = L.icon({
          iconUrl: iconpic,
          iconSize: [90, 90],
          iconAnchor: [45, 90],
          className: "marker-pic",
        })
        map.removeLayer(markerLocation)

        // Add markers for the main location
        const marker = L.marker(markerLocation, {
          alt: projet.titre,
          title: projet.titre,
          icon: myIcon,
        })
        marker.on("mouseover", function () {
          marker.setIcon(bigIcon)
        })
        marker.on("mouseout", function () {
          marker.setIcon(myIcon)
        })
        marker.on("click", this.markerClicked(projet, iconpic))

        markersCluster.addLayer(marker)

        // Check if the project has a polygon and draw it
        // @ts-ignore
        if (projet.polygone && projet.polygone.length > 0) {
          // @ts-ignore
          const polygonCoords = projet.polygone.map((coord) => [coord.latitude, coord.longitude])
          // @ts-ignore
          const polygon = L.polygon(polygonCoords, {
            color: "#004AAD",
            weight: 2,
            fillColor: "#004AAD",
            fillOpacity: 0.2,
          })

          marker.on("mouseover", () => {
            polygon.addTo(map)
          })

          marker.on("mouseout", () => {
            map.removeLayer(polygon)
          })
        }
      }
    })
    map.addLayer(markersCluster)
    if (bounds.isValid()) {
      map.fitBounds(bounds)
    }

    // Reapply the stored center and zoom level
    map.setView(currentCenter, currentZoom)
  }

  getMarkerLocation(projet: AireEducativeCartoDTO): LatLngType {
    if (projet.latitude && projet.longitude) {
      return latLng(projet.latitude, projet.longitude)
    }
    return latLng(0, 0)
  }

  zoomToRegion(regionName: string): void {
    // @ts-ignore
    const map = this.$refs.map?.mapObject
    // @ts-ignore
    const regionFeature = regionsGeoJSON.features.find(
      (feature: any) => feature.properties.nom === regionName
    )

    if (regionFeature && map) {
      const bounds = L.geoJSON(regionFeature).getBounds()
      map.fitBounds(bounds, { duration: 1 })
      //const extraZoomLevel = 2; // Ajustez ce niveau de zoom supplémentaire selon vos besoins
      //const currentZoom = map.getZoom();
      //map.setZoom(currentZoom + extraZoomLevel);
    } else {
      console.error(`La région ${regionName} n'a pas été trouvée.`)
    }
  }

  // @ts-ignore
  getEditControl(): Control.Draw {
    // @ts-ignore
    window.L.EditToolbar.Delete.include({
      enable: () => this.onDelete(),
    })

    // @ts-ignore
    return new window.L.Control.Draw({
      edit: {
        featureGroup: this.drawnItems,
      },
      draw: false,
    })
  }

  onDelete(): void {
    this.infoReportingService.dialog(
      this.$t("usersList.confirmation").toString(),
      this.$t("mapDraw.delete-draw").toString(),
      this.$t("mapDraw.delete").toString(),
      () => this.deleteAireAndEnableDrawControl(),
      "is-warning"
    )
  }

  deleteAireAndEnableDrawControl(): void {
    this.refreshDrawnItems()
    // @ts-ignore
    const map = this.$refs.map.mapObject

    if (this.drawnItems.getLayers().length === 0) {
      map.removeControl(this.drawControlEditOnly)
      map.addControl(this.drawControl)
      this.innerZoneGeographique = ""
      this.onZoneChanged()
    }
  }

  refreshDrawnItems(): void {
    // @ts-ignore
    const map = this.$refs.map.mapObject
    map.removeLayer(this.drawnItems)
    this.drawnItems = new window.L.FeatureGroup()
    map.addLayer(this.drawnItems)
  }

  enableEditPolygon(e: any): void {
    // @ts-ignore
    const map = this.$refs.map.mapObject
    this.drawnItems.addLayer(e.layer)
    map.removeControl(this.drawControl)
    this.refreshDrawnControlEditOnly()
  }

  refreshDrawnControlEditOnly(): void {
    // @ts-ignore
    const map = this.$refs.map.mapObject
    this.drawControlEditOnly = this.getEditControl()
    map.addControl(this.drawControlEditOnly)
  }

  markerClicked(projet: AireEducativeCartoDTO, icon: string): () => void {
    return async () => {
      this.iconDetails = icon
      this.ae = projet
      this.showDetails = true
      this.selectedProject = await this.graeService.getProjectDetails(projet.projetAnnuelId)
    }
  }

  closeDetails(): void {
    this.showDetails = false
    this.ae = new AireEducativeCartoDTO()
  }

  projetClicked(
    projet: AireEducativeCartoDTO,
    marker: any,
    markerLocation: any,
    map: any
  ): () => void {
    return () => {
      let content = "${projet.rue} ${projet.codePostal} ${projet.ville}"
      if (projet) {
        content += "<br/>Téléphone : ${projet.projetAnnuelId}"
      }
      marker.unbindPopup()
      marker.bindPopup(
        `<div class='projet_detail'><h2>Détail du Projet:  ${projet.titre}</h2><p> ${content}</p></div>`
      )
      marker.openPopup()

      const bounds = new LatLngBounds([])
      bounds.extend(markerLocation)
      const zoneGeoParse = JSON.parse("{}")

      if (zoneGeoParse.geometry && zoneGeoParse.geometry.coordinates) {
        zoneGeoParse.geometry.coordinates.forEach((geoCoord: any) => {
          for (let i = 0; i < geoCoord.length; i++) {
            bounds.extend([geoCoord[i][1], geoCoord[i][0]])
          }
        })
      }
      const canvasRenderer = canvas({ padding: 0.5 })
      // @ts-ignore
      const aeZone = window.L.geoJSON(zoneGeoParse, { renderer: canvasRenderer })
      map.removeLayer(aeZone)
      map.addLayer(aeZone)
      map.flyToBounds(bounds, { padding: [5, 5], duration: 1 })
    }
  }

  @Watch("projets")
  projetsChanged(newvalue: any, oldValue: any): void {
    console.log("*****projetsChanged", JSON.parse(JSON.stringify(oldValue)).length)
    if (JSON.parse(JSON.stringify(oldValue)).length == 0) {
      this.updateMarkers()
    }
  }

  modifyLocale(): void {
    // @ts-ignore
    window.L.drawLocal = {
      draw: {
        toolbar: {
          actions: {
            title: this.$t("mapDraw.toolbar.annuler-title"),
            text: this.$t("annuler"),
          },
          finish: {
            title: this.$t("mapDraw.toolbar.finir-title"),
            text: this.$t("mapDraw.toolbar.finir-text"),
          },
          undo: {
            title: this.$t("mapDraw.toolbar.supprimer-title"),
            text: this.$t("mapDraw.toolbar.supprimer-text"),
          },
          buttons: {
            polyline: this.$t("mapDraw.toolbar.polyline"),
            rectangle: this.$t("mapDraw.toolbar.rectangle"),
            circle: this.$t("mapDraw.toolbar.circle"),
            circlemarker: this.$t("mapDraw.toolbar.circlemarker"),
            polygon: this.$t("mapDraw.toolbar.polygon"),
            marker: this.$t("mapDraw.toolbar.marker"),
          },
        },
        handlers: {
          circle: {
            tooltip: {
              start: "Click and drag to draw circle.",
            },
            radius: "Radius",
          },
          circlemarker: {
            tooltip: {
              start: "Click map to place circle marker.",
            },
          },
          polyline: {
            error: "<strong>Error:</strong> shape edges cannot cross!",
            tooltip: {
              start: "Click to start drawing line.",
              cont: "Click to continue drawing line.",
              end: "Click last point to finish line.",
            },
          },
          rectangle: {
            tooltip: {
              start: "Click and drag to draw rectangle.",
            },
          },
          simpleshape: {
            tooltip: {
              end: "Release mouse to finish drawing.",
            },
          },
          marker: {
            tooltip: {
              start: this.$t("mapDraw.handlers.marker"),
            },
          },
          polygon: {
            tooltip: {
              start: this.$t("mapDraw.handlers.polygon-start"),
              cont: this.$t("mapDraw.handlers.polygon-cont"),
              end: this.$t("mapDraw.handlers.polygon-end"),
            },
          },
        },
      },
      edit: {
        toolbar: {
          actions: {
            save: {
              title: this.$t("mapDraw.edit-toolbar.save-title"),
              text: this.$t("mapDraw.edit-toolbar.save-text"),
            },
            cancel: {
              title: this.$t("mapDraw.edit-toolbar.cancel-title"),
              text: this.$t("annuler"),
            },
            clearAll: {
              title: this.$t("mapDraw.edit-toolbar.clearAll-title"),
              text: this.$t("mapDraw.edit-toolbar.clearAll-text"),
            },
          },
          buttons: {
            edit: this.$t("mapDraw.edit-toolbar.edit"),
            editDisabled: this.$t("mapDraw.edit-toolbar.editDisabled"),
            remove: this.$t("mapDraw.edit-toolbar.remove"),
            removeDisabled: this.$t("mapDraw.edit-toolbar.removeDisabled"),
          },
        },
        handlers: {
          edit: {
            tooltip: {
              text: this.$t("mapDraw.edit-handlers.edit-tooltip-text"),
              subtext: this.$t("mapDraw.edit-handlers.edit-tooltip-subtext"),
            },
          },
          remove: {
            tooltip: {
              text: this.$t("mapDraw.edit-handlers.remove-tooltip-text"),
            },
          },
        },
      },
    }
  }

  revealMapOnlyIfNeeded(reveal: boolean): void {
    if (!this.mapVisible && reveal) {
      this.$nextTick(() => {
        this.mapVisible = true
      })
    }
  }

  @Watch("mapScreenShotRequested")
  createMapScreenshot(): void {
    // @ts-ignore
    const map = this.$refs.map.mapObject
    if (this.mapScreenShotRequested) {
      console.info("Map Screenshot request...")
      leafletImage(map, (err: any, canvas: any) => {
        let dataURL
        if (!err) {
          dataURL = canvas.toDataURL()
        } else {
          console.error("Error while taking map screenshot")
        }
        this.$emit("map-screenshot-done", dataURL)
      })
    }
  }

  stopDraw(event: any): void {
    console.log("Draw stopped", event)
  }

  updateZoneGeographique(event: any): void {
    console.log("Zone Geographique updated", event)
  }

  updateMapCenterAndZoom(): void {
    // @ts-ignore
    const map = this.$refs.map.mapObject
    if (map) {
      map.setView(this.initialCenter, this.initialZoom)
    }
  }
}
