























































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import TitleView from "@/views/commons/TitleView.vue"
import ContentView from "@/views/commons/ContentView.vue"
import BookmarkCard from "../generic_components/BookmarkCard.vue"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import SelectWithValidation from "@/views/inputs/SelectWithValidation.vue"
import DropDownMultipleWithValidation from "@/views/inputs/DropDownMultipleWithValidation.vue"
import DatePickerWithValidation from "@/views/inputs/DatePickerWithValidation.vue"
import { ValidationObserver } from "vee-validate"
import { CURSUS, ENSEIGNEMENTS, TAG_TYPES_FOR_BOOKMARK_CARD } from "@/model/bean/EnumHelper"
import { ClasseDTO, ProjetAnnuelDTO } from "@/model/bean/GeneratedDTOs"
import CheckBoxEntry from "../inputs/CheckBoxEntry.vue"

@Component({
  components: {
    TitleView,
    ContentView,
    BookmarkCard,
    InputWithValidation,
    DatePickerWithValidation,
    SelectWithValidation,
    ValidationObserver,
    DropDownMultipleWithValidation,
    CheckBoxEntry,
  },
})
export default class ClasseCard extends Vue {
  @Prop({ default: () => undefined })
  classe: ClasseDTO | undefined
  @Prop()
  projetAnnuel: ProjetAnnuelDTO
  @Prop()
  canDelete: boolean
  classeAdded = false

  $refs!: {
    classeObserver: InstanceType<typeof ValidationObserver>
  }

  protected infoReportingService = InfoReportingService.INSTANCE

  protected tagType = TAG_TYPES_FOR_BOOKMARK_CARD.NOUVELLE_CLASSE
  protected readOnly = false
  protected iconName = "plus"

  protected internalClasse = new ClasseDTO()

  protected enseignement = ""
  protected type = ""
  protected niveau: string[] = []

  enseignements = ENSEIGNEMENTS
  enseignementsLabels = this.enseignements.map((value) => {
    return this.$t(`projetPage.infoAdministratives.classe.enseignementLabels.${value}`).toString()
  })

  types = ["SIMPLE", "DOUBLE", "MULTI"]
  typesLabels = this.types.map((value) => {
    return this.$t(`projetPage.infoAdministratives.classe.typesLabels.${value}`).toString()
  })

  cursus = CURSUS
  isNbNiveauValid = false

  mounted(): void {
    this.classeChanged()
    if (this.classe) {
      this.readOnly = true
      this.tagType = TAG_TYPES_FOR_BOOKMARK_CARD.CLASSE
      this.iconName = "users"
    } else {
      this.readOnly = false
      this.tagType = TAG_TYPES_FOR_BOOKMARK_CARD.NOUVELLE_CLASSE
      this.iconName = "plus"
    }
  }

  enseignementChanged(): void {
    this.reinitElevesNumbersIfEdition()
  }

  reinitElevesNumbersIfEdition(): void {
    if (this.classe) {
      return
    }

    this.enseignements.map((enseignement: string) =>
      //@ts-ignore
      Object.keys(this.cursus[enseignement]).map((niveau) => {
        //@ts-ignore

        if (this.internalClasse[niveau]) {
          //@ts-ignore
          this.internalClasse[niveau] = 0
        }
      })
    )

    this.internalClasse.autre1 = ""
    this.internalClasse.eleves_autre1 = 0
  }

  reinitClasseElevesNumber(niveauToEmpty: string): void {
    if (this.classe) {
      return
    }

    this.enseignements.map((enseignement: string) =>
      //@ts-ignore
      Object.entries(this.cursus[enseignement]).map(([niveau, niveauLabel]) => {
        //@ts-ignore
        if (niveauLabel == niveauToEmpty && this.internalClasse[niveau]) {
          //@ts-ignore
          this.internalClasse[niveau] = 0
        }
      })
    )

    if (niveauToEmpty == "AUTRE") {
      this.internalClasse.autre1 = ""
      this.internalClasse.eleves_autre1 = 0
    }
  }

  addClasse(): void {
    this.classeAdded = false
    this.$emit("add", this.internalClasse)
    this.classeChanged()
    this.enseignement = ""
    this.type = ""
    this.niveau = []
  }

  deleteClasse(): void {
    this.infoReportingService.dialog(
      this.$t("confirmation").toString(),
      this.$t("projetPage.infoAdministratives.classe.are-you-sure").toString(),
      this.$t("projetPage.infoAdministratives.classe.delete").toString(),
      () => {
        this.$emit("delete", this.classe)
      },
      "is-info"
    )
  }

  setNbNiveauValid(): void {
    this.isNbNiveauValid =
      (this.type === "DOUBLE" && this.niveau.length === 2) ||
      (this.type === "MULTI" && this.niveau.length >= 3) ||
      ((this.type === "SIMPLE" || !this.type) && this.niveau.length === 1)
  }

  @Watch("projetAnnuel")
  projetAnnuelChanged(): void {
    requestAnimationFrame(() => {
      this.$refs.classeObserver.reset()
    })
  }

  modifyClasse(add: boolean, classeNiveau: string): void {
    if (add) {
      this.niveau.push(classeNiveau)
    } else {
      const index = this.niveau.indexOf(classeNiveau)
      if (index !== -1) {
        this.niveau.splice(index, 1)
        this.reinitClasseElevesNumber(classeNiveau)
      }
    }
    this.setNbNiveauValid()
    // this.emitModifyClasse()
  }

  @Watch("classe")
  classeChanged(): void {
    if (this.classe) {
      this.classeAdded = true
      this.internalClasse = this.classe
      // @ts-ignore
      this.internalClasse.internal_id =
        // @ts-ignore
        this.classe.id || this.internalClasse.internal_id
    } else {
      this.internalClasse = new ClasseDTO()
      // @ts-ignore
      this.internalClasse.internal_id = "new-" + (this.projetAnnuel.classes.length + 1)
    }

    this.niveau = []

    this.enseignements.map((enseignement) => {
      //@ts-ignore
      Object.keys(this.cursus[enseignement]).map((niveau) => {
        //@ts-ignore
        const elevesForNiveau = this.internalClasse[niveau]
        if (elevesForNiveau) {
          this.enseignement = enseignement
          //@ts-ignore
          this.niveau.push(this.cursus[enseignement][niveau])
        }
      })
    })

    if (this.internalClasse.eleves_autre1) {
      this.niveau.push("AUTRE")
    }

    this.type = ""
    if (this.enseignement === "ELEMENTAIRE") {
      const nbNiveaux = this.niveau.length
      if (nbNiveaux === 1) {
        this.type = "SIMPLE"
      } else if (nbNiveaux === 2) {
        this.type = "DOUBLE"
      } else if (nbNiveaux >= 3) {
        this.type = "MULTI"
      }
    }
    this.enseignementChanged()
    this.setNbNiveauValid()
  }
}
