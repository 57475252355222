



































































import { ProjetAnnuelDTO } from "@/model/bean/GeneratedDTOs"
import { DocLink } from "@/views/commons/DocumentationLinks.vue"
import { Component, Prop, Vue } from "vue-property-decorator"
import DocumentationLinks from "@/views/commons/DocumentationLinks.vue"
import RadioWithInputAndValidation from "@/views/inputs/RadioWithInputAndValidation.vue"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import { EnumHelper } from "@/model/bean/EnumHelper"

@Component({
  components: {
    DocumentationLinks,
    RadioWithInputAndValidation,
    InputWithValidation,
  },
})
export default class ProjetSiteView extends Vue {
  @Prop() projetAnnuel: ProjetAnnuelDTO
  @Prop() readonly: boolean
  @Prop({ default: false }) validationActivated: boolean
  @Prop({ default: true }) showValidationErrors: boolean

  booleanValue = [true, false]
  booleanValueLabels = this.booleanValue.map((value) => {
    return this.$t(`${value}`).toString()
  })

  proprietaireValue = EnumHelper.allProprietaires
  proprietaireValueLabels = this.proprietaireValue.map((proprietaire) => {
    return this.$t(
      `projetPage.infoAdministratives.site.autorisation-proprietaire-labels.${proprietaire}`
    ).toString()
  })

  natures = EnumHelper.allNatures
  natureLabels = this.natures.map((nature) => {
    return this.$t(`projet.inscription.site-inscription.nature-labels.${nature}`).toString()
  })
  natureSiteValue = EnumHelper.allNaturesSite
  natureSiteValueLabels = this.natureSiteValue.map((value) => {
    return this.$t(`projetPage.infoAdministratives.site.natureSite.${value}`).toString()
  })

  links: DocLink[] = []

  created(): void {
    this.links.push(
      new DocLink(
        "projet.doc.mise-disposition-terrain-url",
        "projet.doc.mise-disposition-terrain-label"
      )
    )
  }

  modified(): void {
    this.$emit("dirtify-project")
  }
}
