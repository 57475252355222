














































































































































































































































import {
  PaginationOrder,
  ProjetAnnuelDTO,
  ProjetAnnuelForInstructionDTO,
  QuickFilter,
  UtilisateurDTO,
  EvaluationDTO,
} from "@/model/bean/GeneratedDTOs"
import { Filters } from "@/model/bean/Filters"
import { format } from "date-fns"
import RouterUtils from "@/router/RouterUtils"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import { GraeService } from "@/services/rest/grae/GraeService"
import { LoginService } from "@/services/rest/login/LoginService"
import { ProjectService } from "@/services/rest/project/ProjectService"
import AbstractTableView from "@/views/tables/AbstractTableView.vue"
import Column from "@/views/tables/Column"
import { Component, Prop, Watch } from "vue-property-decorator"
import QuickFilterView from "../QuickFilterView.vue"
import TableFilterView from "@/views/tables/TableFilterView.vue"
import StatutDossierTag from "@/views/dossier/StatutDossierTag.vue"
import LabellisationHarmonisationForProjectView from "./LabellisationHarmonisationForProjectView.vue"
import { InstructionService } from "@/services/rest/instruction/InstructionService"
import { EnumHelper } from "@/model/bean/EnumHelper"

const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm"

@Component({
  components: {
    QuickFilterView,
    TableFilterView,
    StatutDossierTag,
    LabellisationHarmonisationForProjectView,
  },
})
export default class LabellisationHarmonisationView extends AbstractTableView {
  @Prop() graeId: string
  @Prop({ default: 0 }) activeQuickFilter: number

  quickFilter: QuickFilter | undefined = "NOT_ASSIGNED"
  private projectService = ProjectService.INSTANCE
  private loginService = LoginService.INSTANCE
  private graeService = GraeService.INSTANCE
  private instructionService = InstructionService.INSTANCE
  protected infoReportingService = InfoReportingService.INSTANCE
  allGraeInstructeurs: UtilisateurDTO[] = []

  loggedUser: UtilisateurDTO | undefined = new UtilisateurDTO()
  projects: Array<ProjetAnnuelDTO> = []

  created(): void {
    this.loggedUser = this.loginService.getLoggedUser()
    if (!this.loggedUser || !this.loggedUser.id) {
      return
    }
    this.activeQuickFilterChanged()
  }

  @Watch("activeQuickFilter")
  private activeQuickFilterChanged(): void {
    if (this.activeQuickFilter == 0) {
      this.quickFilter = "MY_ASSIGNMENTS"
    } else if (this.activeQuickFilter == 1) {
      this.quickFilter = "NOT_ASSIGNED"
    } else {
      this.quickFilter = undefined
    }
  }

  async mounted(): Promise<void> {
    await this.loadAllInstructeurs()
  }

  async loadAllInstructeurs(): Promise<void> {
    const allInstructeursNotSorted = await this.graeService.getInstructeurs(this.graeId)
    this.allGraeInstructeurs = [
      ...allInstructeursNotSorted.filter((instructeur) => instructeur.id === this.loggedUser?.id),
      ...allInstructeursNotSorted.filter((instructeur) => instructeur.id !== this.loggedUser?.id),
    ]
  }

  quickFilterChanged(newQuickFilter: QuickFilter): void {
    this.quickFilter = newQuickFilter
    const params = this.$router.currentRoute.params
    let tabIndex
    if (this.quickFilter === "MY_ASSIGNMENTS") {
      tabIndex = 0
    } else if (this.quickFilter === "NOT_ASSIGNED") {
      tabIndex = 1
    } else {
      tabIndex = 2
    }
    if (tabIndex !== undefined && tabIndex.toString() !== params["activeQuickFilter"]) {
      params["activeQuickFilter"] = tabIndex.toString()
      this.$router.push({ params: params })
    }
    this.reloadData()
  }

  async reloadData(): Promise<void> {
    this.isLoading = true
    const statutFilter = Filters.asFilter({
      property: "statut",
      values: ["EN_ATTENTE", "INSTRUCTION_EN_COURS"],
    })
    const onlyNotValidatedFilter = Filters.asFilter({
      property: "evaluationFinaleValidated",
      values: ["false"],
    })
    try {
      // TODO Should be harmonisation projects
      const paginatedProjectsList = await this.instructionService.getLabellisations(
        this.graeId,
        this.currentPagedSortedParameter,
        [...this.activeFilters, statutFilter, onlyNotValidatedFilter],
        this.quickFilter
      )
      this.projects = paginatedProjectsList.elements
      this.total = paginatedProjectsList.count
    } catch (e) {
      // Silent catch
    }
    this.isLoading = false
  }

  getInitialColumns(): Array<Column> {
    return [
      {
        field: "codeDepartement",
        label: this.$t("graePage.details.projets.dpt").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "ville",
        label: this.$t("graePage.details.projets.ville").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "etablissement.name",
        label: this.$t("graePage.details.projets.etablissements").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "natureAireEducative",
        label: this.$t("graePage.details.projets.nature-ae").toString(),
        sortable: true,
        backEndSearchable: true,
        possibleSearchValues: EnumHelper.allNatures,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "etablissement.codeEtablissement",
        label: this.$t("graePage.details.projets.uais").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "projet.nom",
        label: this.$t("graePage.details.projets.nom").toString(),
        sortable: true,
        backEndSearchable: true,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "typeDossier",
        label: this.$t("graePage.details.projets.typeDossier").toString(),
        sortable: true,
        backEndSearchable: true,
        possibleSearchValues: EnumHelper.allTypeDossiers,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "enseignant.name",
        label: this.$t("graePage.details.projets.enseignants").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "structure.name",
        label: this.$t("graePage.details.projets.structures").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "statut",
        label: this.$t("graePage.details.projets.statut").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "dateModification",
        label: this.$t("graePage.details.projets.dateModification").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "referent.name",
        label: this.$t("graePage.details.projets.referents").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: false,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "evaluation.instructeur",
        label: this.$t("graePage.details.projets.instructeur").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "evaluation.deroule",
        label: this.$t("instructionPage.labellisations.harmonisation.note.deroule").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },

      {
        field: "evaluation.diffusion",
        label: this.$t("instructionPage.labellisations.harmonisation.note.diffusion").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "evaluation.enseignements",
        label: this.$t(
          "instructionPage.labellisations.harmonisation.note.enseignements"
        ).toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "evaluation.implication",
        label: this.$t("instructionPage.labellisations.harmonisation.note.implication").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "evaluation.commentaires",
        label: this.$t("instructionPage.labellisations.harmonisation.note.commentaires").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "evaluation.mention",
        label: this.$t("instructionPage.labellisations.harmonisation.note.mention").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "evaluation.actions",
        label: this.$t("instructionPage.labellisations.harmonisation.actions").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
    ]
  }

  getDefaultSort(): Array<PaginationOrder> {
    const defaultSort = new PaginationOrder()
    defaultSort.clause = "projet.nom"
    defaultSort.desc = false
    return [defaultSort]
  }

  showProjectDetailsPage(event: Event, projetId: string, projetAnnuelId: string): void {
    // Do not foward click event to row (would trigger modal)
    event.stopPropagation()
    this.$router.push(RouterUtils.getRouteForProjectDetails(projetId, projetAnnuelId))
  }

  formatDate(date: Date): string {
    if (date) {
      return format(date, DATE_TIME_FORMAT)
    }
    return ""
  }

  async selectInstructeur(
    instructeur: UtilisateurDTO,
    dossierId: string,
    typeDossier: string
  ): Promise<void> {
    try {
      if (instructeur) {
        if (typeDossier === "DOSSIER_INSCRIPTION") {
          await this.projectService.updateInstructeur(dossierId, instructeur)
        } else {
          console.error("Instruction non définie pour ce type de dossier : " + typeDossier)
        }

        this.infoReportingService.success(
          this.$t("instructionPage.inscriptions.instructeur-changed").toString()
        )
        this.projects = []
        this.reloadData()
      }
    } catch (e) {
      this.infoReportingService.error(
        this.$t("instructionPage.inscriptions.add-instructeur-error").toString(),
        e as Error
      )
    }
  }

  async removeInstructeur(
    instructeur: UtilisateurDTO,
    dossierId: string,
    typeDossier: string
  ): Promise<void> {
    try {
      if (instructeur) {
        if (typeDossier === "DOSSIER_INSCRIPTION") {
          await this.projectService.removeInstructeur(dossierId, instructeur)
        } else {
          console.error("Instruction non définie pour ce type de dossier : " + typeDossier)
        }

        this.infoReportingService.success(
          this.$t("instructionPage.inscriptions.instructeur-removed").toString()
        )
        this.projects = []
        this.reloadData()
      }
    } catch (e) {
      this.infoReportingService.error(
        this.$t("instructionPage.inscriptions.remove-instructeur-error").toString(),
        e as Error
      )
    }
  }

  evaluationChanged(projetAnnuel: ProjetAnnuelForInstructionDTO, newEvaluations: EvaluationDTO[]) {
    projetAnnuel.evaluations = newEvaluations
  }
}
