












































































import { Component, Prop, Vue } from "vue-property-decorator"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import { OrganismeService } from "@/services/rest/organisme/OrganismeService"
import SelectWithValidation from "../inputs/SelectWithValidation.vue"

@Component({
  components: {
    InputWithValidation,
    SelectWithValidation,
  },
})
export default class AdresseView extends Vue {
  @Prop() value: Record<string, unknown>
  @Prop() withPays: boolean
  @Prop({ default: false }) readonly: boolean
  @Prop() validationGroup: string

  private organismeService = OrganismeService.INSTANCE

  suggestedVilles: string[] = []

  created(): void {
    this.updateSuggestedVilles()
  }

  updateAddress(): void {
    this.$emit("change-address", this.value)
  }

  updateCodePostal(): void {
    this.updateAddress()
    this.updateSuggestedVilles()
  }

  async updateSuggestedVilles(): Promise<void> {
    const codePostal = this.value.codePostal as string
    if (!codePostal || codePostal.length !== 5) {
      this.suggestedVilles = []
      this.value.ville = ""
      return
    }
    this.suggestedVilles = await this.organismeService.getVilleOptionsFromCodePostal(codePostal)
    if (this.suggestedVilles.length === 1) {
      this.value.ville = this.suggestedVilles[0]
    }
  }
}
