

















































































































































import { ProjetInscriptionDTO } from "@/model/bean/GeneratedDTOs"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import CheckboxWithValidation from "@/views/inputs/CheckBoxWithValidation.vue"
import { ProjetAnnualValidation } from "../commons/ProjectValidationAndSaveHandler.vue"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import RecapCard from "@/views/projects_pp/RecapCard.vue"

@Component({
  components: { CheckboxWithValidation, InputWithValidation, RecapCard },
})
export default class ProjetInscriptionFinalisation extends Vue {
  @Prop() projetAnnuel: ProjetInscriptionDTO
  @Prop() readonly: boolean
  @Prop() projetValidation: ProjetAnnualValidation
  @Prop({ default: true }) showValidationErrors: boolean
  @Prop({ default: true }) validationActivated: boolean
  errorsPerLabel = new Map<string, number>()
  exactInfosCertified = false
  isMissingReferent = false

  @Watch("projetValidation")
  validationChanged(): void {
    // Sort all invalid & empty fields by sections, calculating total for each subsection
    this.errorsPerLabel = new Map<string, number>()
    const allErrors = Array.from(this.projetValidation.invalidFields.keys())
    const allWarnings = Array.from(this.projetValidation.emptyFields.keys())

    allErrors.forEach((field: string) => {
      if (field != "finalisation") {
        let numberOfError = this.projetValidation.invalidFields.get(field)?.length
        this.errorsPerLabel.set(field, numberOfError ? numberOfError : 0)
      }
    })
    allWarnings.forEach((field: string) => {
      console.log("Fields => " + field)
      let numberOfWarnings = this.projetValidation.emptyFields.get(field)?.length
      let numberOfError = this.projetValidation.invalidFields.get(field)?.length
      let total = (numberOfError ? numberOfError : 0) + (numberOfWarnings ? numberOfWarnings : 0)
      // If the only remaining issue is that referent is missing, do not forbid inscription, just display a custom warning
      if (field == "participants") {
        const allParticipantsErrors = this.projetValidation.emptyFields.get(field)
        if (allParticipantsErrors) {
          const missingReferentsError = Array.from(allParticipantsErrors).filter((issue) => {
            return issue.field == "participants_referents"
          })
          if (missingReferentsError && missingReferentsError.length > 0) {
            if (allParticipantsErrors.length == 1) {
              this.isMissingReferent = true
            }
            total = total - 1
          }
        }
      }
      if (total > 0) {
        this.errorsPerLabel.set(field, total)
      }
    })
  }
}
