var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-table",
        {
          ref: _vm.tableRef,
          attrs: {
            striped: _vm.config.isStriped,
            hoverable: _vm.config.isHoverable,
            "debounce-search": _vm.config.searchDebounce,
            "aria-next-label": _vm.config.nextLabel(),
            "aria-previous-label": _vm.config.previousLabel(),
            "aria-page-label": _vm.config.pageLabel(),
            "aria-current-label": _vm.config.currentPageLabel(),
            "per-page": _vm.config.perPage,
            "sticky-header": _vm.config.stickyHeader,
            "current-page": _vm.currentPagedSortedParameter.pageNumber,
            "pagination-position": _vm.config.paginationPosition,
            paginated: "",
            "backend-pagination": "",
            "sort-icon": _vm.config.sortIcon,
            "sort-icon-size": _vm.config.sortIconSize,
            "backend-sorting": "",
            "sort-multiple": "",
            "sort-multiple-data": _vm.buefySortingPriority,
            total: _vm.total,
            data: _vm.list,
            loading: _vm.isLoading
          },
          on: {
            sort: _vm.onSort,
            "sorting-priority-removed": _vm.sortingPriorityRemoved,
            "page-change": _vm.onPageChange
          }
        },
        [
          _vm._l(_vm.columns, function(column, index) {
            return [
              _c(
                "b-table-column",
                _vm._b(
                  {
                    key: "colonne-" + index,
                    attrs: { visible: column.visible !== false },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(props) {
                            return [
                              column.field === "nomProjet"
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "clickable",
                                      on: {
                                        click: function($event) {
                                          return _vm.showUserDetailsPage(
                                            $event,
                                            props.row
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(props.row[column.field]) +
                                          " "
                                      )
                                    ]
                                  )
                                : column.field !== "action"
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "clickable",
                                      on: {
                                        click: function($event) {
                                          return _vm.showUserDetailsPage(
                                            $event,
                                            props.row
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "ul",
                                        _vm._l(
                                          props.row[column.field],
                                          function(v, index) {
                                            return _c(
                                              "li",
                                              {
                                                key: column.field + "-" + index
                                              },
                                              [_vm._v(_vm._s(v))]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass: "clickable",
                                      on: {
                                        click: function($event) {
                                          return _vm.showUserDetailsPage(
                                            $event,
                                            props.row
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "ul",
                                        _vm._l(props.row["actions"], function(
                                          v,
                                          index
                                        ) {
                                          return _c(
                                            "li",
                                            { key: "action-" + index },
                                            [_vm._v("- " + _vm._s(v))]
                                          )
                                        }),
                                        0
                                      )
                                    ]
                                  )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  "b-table-column",
                  column,
                  false
                )
              )
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }