




































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator"

import ImageWithModal from "@/views/commons/ImageWithModal.vue"
import LoginView from "@/views/login/LoginView.vue"
import MapClusterComponent from "@/views/maps/MapClusterComponent.vue"
import { LoginService } from "@/services/rest/login/LoginService"
import { StatService } from "@/services/rest/stats/StatService"
import Events from "@/services/Events"
import { AireEducativeCartoDTO } from "@/model/bean/GeneratedDTOs"
import { GraeService } from "@/services/rest/grae/GraeService"

@Component({
  components: {
    ImageWithModal,
    LoginView,
    MapClusterComponent,
  },
})
export default class HomePage extends Vue {
  isUserLoggedIn = false
  private loginService = LoginService.INSTANCE
  private statsService = StatService.INSTANCE
  private graeService = GraeService.INSTANCE

  Projets: Array<AireEducativeCartoDTO> = []

  created(): void {
    this.refreshUserInfo()
  }

  mounted(): void {
    window.scrollTo(0, 0)
    this.loginService.on(Events.LOGIN_STATUS_CHANGED, () => {
      this.refreshUserInfo()
    })

    console.log(this.Projets)
  }

  refreshUserInfo(): void {
    const logged = this.loginService.getLoggedUser()
    this.isUserLoggedIn = logged != undefined
  }
}
