

















import { Component, Vue } from "vue-property-decorator"
import { ValidationObserver } from "vee-validate"
import { UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import PosteDetails from "@/views/commons/PosteDetails.vue"

@Component({
  components: {
    ValidationObserver,
    PosteDetails,
  },
})
export default class AccompagnateurNationalInformationsView extends Vue {
  innerUtilisateur = new UtilisateurDTO()

  changePoste(changedUser: UtilisateurDTO): void {
    this.innerUtilisateur.fonction = changedUser.fonction
    this.innerUtilisateur.employeur = changedUser.employeur
  }

  submit(): void {
    this.$emit("input-fonction", this.innerUtilisateur)
  }

  goBack(): void {
    this.$emit("go-back", this.innerUtilisateur)
  }
}
