var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        "padding-right": "30px",
        "margin-top": "10 px",
        "margin-bottom": "10px"
      }
    },
    [
      _c("div", [
        _c("h2", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("graeList.national.filteredElements")))
        ]),
        _c("h3", { staticClass: "title2" }, [
          _vm._v(
            " " + _vm._s(_vm.$t("graeList.national.singleYearElements")) + " "
          )
        ]),
        _c("table", [
          _c("thead", [
            _c("tr", [
              _c("th", [
                _vm._v(_vm._s(_vm.$t("graeList.national.totalAEActive")))
              ]),
              _c("th", [
                _vm._v(_vm._s(_vm.$t("graeList.national.totalATEActive")))
              ]),
              _c("th", [
                _vm._v(_vm._s(_vm.$t("graeList.national.totalAMEActive")))
              ]),
              _c("th", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("graeList.national.newInscriptions")) +
                    " " +
                    _vm._s(_vm.anneeScolaireSingleYearInscription) +
                    "-" +
                    _vm._s(_vm.anneeScolaireSingleYearInscription) +
                    " "
                )
              ]),
              _c("th", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("graeList.national.labelings")) +
                    " " +
                    _vm._s(_vm.anneeScolaireSingleYear) +
                    "-" +
                    _vm._s(_vm.anneeScolaireSingleYear) +
                    " "
                )
              ]),
              _c("th", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("graeList.national.renewals")) +
                    " " +
                    _vm._s(_vm.anneeScolaireSingleYear) +
                    "-" +
                    _vm._s(_vm.anneeScolaireSingleYear) +
                    " "
                )
              ])
            ])
          ]),
          _c("tbody", [
            _c("tr", [
              _c("td", [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.redirectToDetails(
                          false,
                          "totalAEActive",
                          true
                        )
                      }
                    }
                  },
                  [
                    _vm.isLoading
                      ? _c("span", { staticClass: "spinner" })
                      : _c("span", [_vm._v(_vm._s(_vm.totalAEActive(true)))])
                  ]
                )
              ]),
              _c("td", [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.redirectToDetails(
                          false,
                          "totalATEActive",
                          true
                        )
                      }
                    }
                  },
                  [
                    _vm.isLoading
                      ? _c("span", { staticClass: "spinner" })
                      : _c("span", [_vm._v(_vm._s(_vm.totalATEActive(true)))])
                  ]
                )
              ]),
              _c("td", [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.redirectToDetails(
                          false,
                          "totalAMEActive",
                          true
                        )
                      }
                    }
                  },
                  [
                    _vm.isLoading
                      ? _c("span", { staticClass: "spinner" })
                      : _c("span", [_vm._v(_vm._s(_vm.totalAMEActive(true)))])
                  ]
                )
              ]),
              _c("td", [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.redirectToDetails(
                          true,
                          "newInscriptions",
                          true
                        )
                      }
                    }
                  },
                  [
                    _vm.isLoading
                      ? _c("span", { staticClass: "spinner" })
                      : _c("span", [_vm._v(_vm._s(_vm.newInscriptions(true)))])
                  ]
                )
              ]),
              _c("td", [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.redirectToDetails(false, "labelings", true)
                      }
                    }
                  },
                  [
                    _vm.isLoading
                      ? _c("span", { staticClass: "spinner" })
                      : _c("span", [_vm._v(_vm._s(_vm.labelings(true)))])
                  ]
                )
              ]),
              _c("td", [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.redirectToDetails(false, "renewals", true)
                      }
                    }
                  },
                  [
                    _vm.isLoading
                      ? _c("span", { staticClass: "spinner" })
                      : _c("span", [_vm._v(_vm._s(_vm.renewals(true)))])
                  ]
                )
              ])
            ])
          ])
        ])
      ]),
      _c("div", [
        _c("h3", { staticClass: "title2" }, [
          _vm._v(
            " " + _vm._s(_vm.$t("graeList.national.schoolYearElements")) + " "
          )
        ]),
        _c("table", [
          _c("thead", [
            _c("tr", [
              _c("th", [
                _vm._v(_vm._s(_vm.$t("graeList.national.totalAEActive")))
              ]),
              _c("th", [
                _vm._v(_vm._s(_vm.$t("graeList.national.totalATEActive")))
              ]),
              _c("th", [
                _vm._v(_vm._s(_vm.$t("graeList.national.totalAMEActive")))
              ]),
              _c("th", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("graeList.national.newInscriptions")) +
                    " " +
                    _vm._s(_vm.anneeScolaireSchoolYearInscription) +
                    " "
                )
              ]),
              _c("th", [
                _vm._v(
                  _vm._s(_vm.$t("graeList.national.labelings")) +
                    " " +
                    _vm._s(_vm.anneeScolaireSchoolYear)
                )
              ]),
              _c("th", [
                _vm._v(
                  _vm._s(_vm.$t("graeList.national.renewals")) +
                    " " +
                    _vm._s(_vm.anneeScolaireSchoolYear)
                )
              ])
            ])
          ]),
          _c("tbody", [
            _c("tr", [
              _c("td", [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.redirectToDetails(
                          true,
                          "totalAEActive",
                          false
                        )
                      }
                    }
                  },
                  [
                    _vm.isLoading
                      ? _c("span", { staticClass: "spinner" })
                      : _c("span", [_vm._v(_vm._s(_vm.totalAEActive(false)))])
                  ]
                )
              ]),
              _c("td", [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.redirectToDetails(
                          true,
                          "totalATEActive",
                          false
                        )
                      }
                    }
                  },
                  [
                    _vm.isLoading
                      ? _c("span", { staticClass: "spinner" })
                      : _c("span", [_vm._v(_vm._s(_vm.totalATEActive(false)))])
                  ]
                )
              ]),
              _c("td", [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.redirectToDetails(
                          true,
                          "totalAMEActive",
                          false
                        )
                      }
                    }
                  },
                  [
                    _vm.isLoading
                      ? _c("span", { staticClass: "spinner" })
                      : _c("span", [_vm._v(_vm._s(_vm.totalAMEActive(false)))])
                  ]
                )
              ]),
              _c("td", [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.redirectToDetails(
                          true,
                          "newInscriptions",
                          false
                        )
                      }
                    }
                  },
                  [
                    _vm.isLoading
                      ? _c("span", { staticClass: "spinner" })
                      : _c("span", [_vm._v(_vm._s(_vm.newInscriptions(false)))])
                  ]
                )
              ]),
              _c("td", [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.redirectToDetails(false, "labelings", false)
                      }
                    }
                  },
                  [
                    _vm.isLoading
                      ? _c("span", { staticClass: "spinner" })
                      : _c("span", [_vm._v(_vm._s(_vm.labelings(false)))])
                  ]
                )
              ]),
              _c("td", [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.redirectToDetails(false, "renewals", false)
                      }
                    }
                  },
                  [
                    _vm.isLoading
                      ? _c("span", { staticClass: "spinner" })
                      : _c("span", [_vm._v(_vm._s(_vm.renewals(false)))])
                  ]
                )
              ])
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }