var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PageView",
    {
      attrs: { mainTitle: _vm.$t("graeList.title"), noSubTitleExpected: "true" }
    },
    [
      _c(
        "ContentView",
        { attrs: { wideScreen: "true" } },
        [
          _vm.canSeeNational()
            ? _c(
                "div",
                [
                  _c("GraeListVueNational", {
                    attrs: { elements: _vm.graesStats }
                  })
                ],
                1
              )
            : _vm._e(),
          _c("TableFilterView", {
            attrs: {
              columns: _vm.columns,
              initialFilters: _vm.activeFilters,
              showExport: false
            },
            on: {
              "update-active-filters": _vm.updateActiveFilters,
              "update-visible-columns": _vm.updateVisibleColumns
            }
          }),
          _c(
            "b-table",
            {
              ref: _vm.tableRef,
              class: { filtered: this.activeFilters.length },
              attrs: {
                striped: _vm.config.isStriped,
                hoverable: _vm.config.isHoverable,
                "debounce-search": _vm.config.searchDebounce,
                "aria-next-label": _vm.config.nextLabel(),
                "aria-previous-label": _vm.config.previousLabel(),
                "aria-page-label": _vm.config.pageLabel(),
                "aria-current-label": _vm.config.currentPageLabel(),
                "per-page": "50",
                "sticky-header": _vm.config.stickyHeader,
                "current-page": _vm.currentPagedSortedParameter.pageNumber,
                "pagination-position": _vm.config.paginationPosition,
                "backend-pagination": "",
                "sort-icon": _vm.config.sortIcon,
                "sort-icon-size": _vm.config.sortIconSize,
                "backend-sorting": "",
                "sort-multiple": "",
                "sort-multiple-data": _vm.buefySortingPriority,
                total: _vm.total,
                data: _vm.graeList,
                loading: _vm.isLoading
              },
              on: {
                sort: _vm.onSort,
                "sorting-priority-removed": _vm.sortingPriorityRemoved,
                "page-change": _vm.onPageChange
              }
            },
            [
              _vm._l(_vm.columns, function(column) {
                return [
                  _c(
                    "b-table-column",
                    _vm._b(
                      {
                        key: column.id,
                        attrs: { visible: column.visible !== false },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function() {
                                return [
                                  column.tooltip
                                    ? _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            size: "is-small",
                                            "append-to-body": "",
                                            dashed: "",
                                            position: "is-bottom",
                                            animated: ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "content",
                                                fn: function() {
                                                  return [
                                                    _c("span", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          column.tooltip
                                                        )
                                                      }
                                                    })
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(column.label) + " "
                                          )
                                        ]
                                      )
                                    : _c("span", [
                                        _vm._v(" " + _vm._s(column.label))
                                      ])
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "default",
                              fn: function(props) {
                                return [
                                  column.field === "region"
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "item-label label-container"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.graesStats.has(
                                                      props.row["id"]
                                                    )
                                                      ? props.row["nom"]
                                                      : ""
                                                  ) +
                                                  " "
                                              ),
                                              _vm.shouldDisplayBadge(
                                                props.row["id"]
                                              )
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass: "badge-tab"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.graesStats.get(
                                                            props.row["id"]
                                                          ).nbCompteAvalider
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  column.field === "ate"
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.graesStats.has(
                                                  props.row["id"]
                                                )
                                                  ? _vm.graesStats.get(
                                                      props.row["id"]
                                                    ).nbATE
                                                  : ""
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  column.field === "ame"
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.graesStats.has(
                                                  props.row["id"]
                                                )
                                                  ? _vm.graesStats.get(
                                                      props.row["id"]
                                                    ).nbAME
                                                  : ""
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  column.field === "inscriptions-a-instruire"
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.graesStats.has(
                                                  props.row["id"]
                                                )
                                                  ? _vm.graesStats.get(
                                                      props.row["id"]
                                                    ).inscriptionsNonInstruites
                                                  : ""
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  column.field === "inscriptions-brouillon"
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.graesStats.has(
                                                  props.row["id"]
                                                )
                                                  ? _vm.graesStats.get(
                                                      props.row["id"]
                                                    ).inscriptionsBrouillon
                                                  : ""
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  column.field === "labellisations-instruites"
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.graesStats.has(
                                                  props.row["id"]
                                                )
                                                  ? _vm.graesStats.get(
                                                      props.row["id"]
                                                    )
                                                      .labellisationsInstructionTerminee
                                                  : ""
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  column.field === "labellisations-a-instruire"
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.graesStats.has(
                                                  props.row["id"]
                                                )
                                                  ? _vm.graesStats.get(
                                                      props.row["id"]
                                                    )
                                                      .labellisationsAttenteInstructeur
                                                  : ""
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  column.field === "projets-pause"
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.graesStats.has(
                                                  props.row["id"]
                                                )
                                                  ? _vm.graesStats.get(
                                                      props.row["id"]
                                                    ).pause
                                                  : ""
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  column.field === "projets-arretes"
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.graesStats.has(
                                                  props.row["id"]
                                                )
                                                  ? _vm.graesStats.get(
                                                      props.row["id"]
                                                    ).arrete
                                                  : ""
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  column.field === "notes" && !_vm.isRegional
                                    ? _c(
                                        "div",
                                        [
                                          _c("b-button", {
                                            attrs: {
                                              type: "is-outlined",
                                              "icon-left": "comment-alt"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.openNotesDialog(
                                                  $event,
                                                  props.row
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !column.custom
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "clickable",
                                          on: {
                                            click: function($event) {
                                              _vm.showDetailsPage(
                                                $event,
                                                props.row["id"],
                                                _vm.graesStats.get(
                                                  props.row["id"]
                                                ).nbCompteAvalider
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(props.row[column.field])
                                            )
                                          ])
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      "b-table-column",
                      column,
                      false
                    )
                  )
                ]
              }),
              _c(
                "div",
                {
                  staticClass: "empty-table",
                  attrs: { slot: "empty" },
                  slot: "empty"
                },
                [_vm._v(" " + _vm._s(_vm.$t("graeList.aucun-resultat")) + " ")]
              )
            ],
            2
          ),
          _c("NotesModale", {
            attrs: { grae: _vm.selectedGrae, open: _vm.openNotesModale },
            on: {
              "close-modale": function($event) {
                _vm.openNotesModale = false
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }