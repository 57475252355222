














import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import TitleView from "@/views/commons/TitleView.vue"
import { ProjetAnnuelDTO } from "@/model/bean/GeneratedDTOs"
import RadioWithInputAndValidation from "@/views/inputs/RadioWithInputAndValidation.vue"
import { EnumHelper } from "@/model/bean/EnumHelper"

@Component({
  components: {
    TitleView,
    RadioWithInputAndValidation,
  },
})
export default class InstructionMilieuxGeneriques extends Vue {
  @Prop() projetAnnuel: ProjetAnnuelDTO
  @Prop({ default: false }) readOnly: boolean

  internalProjet = new ProjetAnnuelDTO()
  allMilieuxGenerique = EnumHelper.allMilieuxGenerique

  created(): void {
    this.internalProjet = this.projetAnnuel
  }

  @Watch("projetAnnuel")
  dossierInscriptionChanged(): void {
    this.internalProjet = this.projetAnnuel
  }

  allMilieuxGeneriqueLabels(): string[] {
    return this.allMilieuxGenerique.map((milieuGenerique) => {
      return this.$i18n.t("instruction." + milieuGenerique).toString()
    })
  }
}
