/*-
 * #%L
 * Aires Éducatives
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */

import { AbstractRestService } from "../AbstractRestService"
import ConversionUtils from "@/services/ConversionUtils"
import { ConseilDTO, ProjetAnnuelDTO } from "@/model/bean/GeneratedDTOs"
import { FileService } from "../utils/FileService"
import { format } from "date-fns"

export class ConseilService extends AbstractRestService {
  static INSTANCE = new ConseilService()

  private fileService = FileService.INSTANCE

  async create(idProjetAnnuel: string, conseil: ConseilDTO, file: File): Promise<ProjetAnnuelDTO> {
    const converted = await ConversionUtils.convertFileToBase64String(file)
    const dateConseil = encodeURIComponent(format(conseil.dateConseil, "YYYY-MM-DD"))
    const path = `/projets/${idProjetAnnuel}/conseils`
    const params = `?date=${dateConseil}&type=${conseil.type}&nom=${encodeURIComponent(file.name)}`
    return this.fileService.sendFile(path + params, converted)
  }

  async delete(idProjetAnnuel: string, idConseil: string): Promise<ProjetAnnuelDTO> {
    return super.typedDeleteSingleV2(`/projets/${idProjetAnnuel}/conseils/${idConseil}`, 2)
  }
}
