var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      staticClass: "CourrielModal",
      attrs: {
        active: _vm.open,
        "has-modal-card": "",
        "trap-focus": "",
        "aria-role": "dialog",
        "aria-label": "CourrielModal",
        "aria-modal": "",
        width: "100vw"
      }
    },
    [
      [
        _c("div", { staticClass: "modal-card" }, [
          _c(
            "header",
            { staticClass: "modal-card-head" },
            [
              _c("H3TitleView", { staticClass: "modal-card-title" }, [
                _vm._v(_vm._s(_vm.$t("instruction-pannel.echanges.courriel")))
              ]),
              _c("div", { staticClass: "ActualitesModalView-infos" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("instruction-pannel.echanges.date-envoi") +
                        " " +
                        _vm.formatDate(_vm.courriel.dateCreation) +
                        (_vm.courriel.auteur
                          ? " par " +
                            _vm.courriel.auteur.prenom +
                            " " +
                            _vm.courriel.auteur.nom
                          : "")
                    ) +
                    " "
                )
              ])
            ],
            1
          ),
          _c(
            "section",
            { staticClass: "modal-card-body" },
            [
              _c("InputWithValidation", {
                attrs: {
                  type: "textarea",
                  label: _vm.$t("instruction-pannel.echanges.courriel-title"),
                  maxlength: "140",
                  vid: "courriel-title",
                  disabled: true
                },
                model: {
                  value: _vm.courriel.titre,
                  callback: function($$v) {
                    _vm.$set(_vm.courriel, "titre", $$v)
                  },
                  expression: "courriel.titre"
                }
              }),
              _c("InputWithValidation", {
                attrs: {
                  type: "textarea",
                  label: _vm.$t("instruction-pannel.echanges.courriel-content"),
                  maxlength: "1400",
                  vid: "courriel-content",
                  disabled: "true"
                },
                model: {
                  value: _vm.courriel.contenu,
                  callback: function($$v) {
                    _vm.$set(_vm.courriel, "contenu", $$v)
                  },
                  expression: "courriel.contenu"
                }
              })
            ],
            1
          ),
          _c("footer", { staticClass: "modal-card-foot" }, [
            _c("div", { staticClass: "buttons" }, [
              _c(
                "div",
                {
                  staticClass: "hoverable-discrete-link mr-3",
                  on: { click: _vm.emitCloseModale }
                },
                [_vm._v(" " + _vm._s(_vm.$t("close-generic")) + " ")]
              )
            ])
          ])
        ])
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }