





























































import { ProjetAnnuelDTO } from "@/model/bean/GeneratedDTOs"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { ProjetAnnualValidation } from "@/pages/projet-page/commons/ProjectValidationAndSaveHandler.vue"
import { ValidationStatus } from "@/model/ValidationResult"
import {
  BackToPreviousScreenItem,
  NavbarItemWithoutLink,
  ContextMenu,
  NavbarItem,
  BulletWithValidation,
} from "@/views/menus/MenuItems"

import ProjetParticipants from "@/pages/projet-page/info-administratives/ProjetParticipants.vue"
import ProjetInscriptionVueEnsemble from "@/pages/projet-page/inscription/ProjetInscriptionVueEnsemble.vue"
import ProjetInscriptionSite from "@/pages/projet-page/inscription/ProjetInscriptionSite.vue"
import ProjetInscriptionFinalisation from "@/pages/projet-page/inscription/ProjetInscriptionFinalisation.vue"
import ProjetFinancementView from "@/pages/projet-page/info-administratives/ProjetFinancementView.vue"
import ProjetHistoryView from "../history/ProjetHistoryView.vue"

export const PROJECT_SECTIONS = {
  HISTORY: "historique",
  INSCRIPTION: "inscription",
}

@Component({
  components: {
    ProjetParticipants,
    ProjetInscriptionVueEnsemble,
    ProjetInscriptionFinalisation,
    ProjetInscriptionSite,
    ProjetFinancementView,
    ProjetHistoryView,
  },
})
export default class ProjetInscriptionHolder extends Vue {
  @Prop() project: ProjetAnnuelDTO
  @Prop({ default: "" }) role: string
  @Prop({ default: "inscription" }) section: string
  @Prop({ default: "0" }) activeTab: number
  @Prop({ default: true }) validationActivated: boolean
  @Prop({ default: true }) showValidationErrors: boolean
  @Prop() lastProjectValidation: ProjetAnnualValidation
  @Prop() readonly: boolean
  @Prop() isPorteurProjet: boolean
  @Prop() currentScrollY: number
  @Prop() pxFromBottom: number
  @Prop() pdfExportRequested: boolean

  anchorsToTrack: string[] = []
  // Indicates if projet has been validated at least once
  // (and hence lastProjectvalidationCanBeUsed to display last validation results)
  wasValidatedAtLeastOnce = false

  created(): void {
    if (this.$router.currentRoute.params["section"] == "bref") {
      const newRouteWithCorrectSection = this.$router.currentRoute
      newRouteWithCorrectSection.params["section"] = "inscription"
      this.$router.push({ params: newRouteWithCorrectSection.params })
    }
  }
  mounted(): void {
    this.refreshContextMenu()

    // Launch a validation at first load
    this.$emit("trigger-validation")
  }

  @Watch("lastProjectValidation")
  projetAnnuelValidationChanged(): void {
    this.wasValidatedAtLeastOnce = true
    this.refreshContextMenu()
  }

  @Watch("validationActivated")
  validationActivatedChanged(): void {
    this.refreshContextMenu()
  }

  refreshContextMenu(): void {
    const menuItems = []
    this.anchorsToTrack = []
    if (this.project) {
      menuItems.push(new BackToPreviousScreenItem())
      menuItems.push(new NavbarItemWithoutLink(this.project.nom, ""))
      if (this.project.statut != "BROUILLON") {
        menuItems.push(this.getMenuEntry(PROJECT_SECTIONS.HISTORY, "calendar", "0"))
      } else {
        const newRouteWithCorrectSection = this.$router.currentRoute
        newRouteWithCorrectSection.params["section"] = "inscription"
        this.$router.push({ params: newRouteWithCorrectSection.params })
      }
      const inscriptionEntry = this.getMenuEntry(PROJECT_SECTIONS.INSCRIPTION, "calendar-alt", "0")
      inscriptionEntry.children = [
        this.getBulletEntry(PROJECT_SECTIONS.INSCRIPTION, "ensemble"),
        this.getBulletEntry(PROJECT_SECTIONS.INSCRIPTION, "participants"),
        this.getBulletEntry(PROJECT_SECTIONS.INSCRIPTION, "site"),
        this.getBulletEntry(PROJECT_SECTIONS.INSCRIPTION, "financements"),
        this.getBulletEntry(PROJECT_SECTIONS.INSCRIPTION, "finalisation"),
      ]
      menuItems.push(inscriptionEntry)

      const possibleMenuParents = [""]
      this.$root.$emit("contextual-menu-changed", new ContextMenu(possibleMenuParents, menuItems))
      this.updateActiveAnchor()
    }
  }

  getMenuEntry(section: string, icone: string, subtab: string): NavbarItem {
    const basePath = "/project/" + this.project.projetId + "/" + this.project.id + "/"
    return new NavbarItem(
      basePath + section + "/" + subtab,
      this.$t("projet.menu." + section).toString(),
      icone,
      false
    )
  }

  getBulletEntry(section: string, anchor: string): BulletWithValidation {
    let validationStatus = ValidationStatus.NONE
    if (this.validationActivated && this.wasValidatedAtLeastOnce && this.lastProjectValidation) {
      validationStatus = ValidationStatus.VALID
      if (this.lastProjectValidation.invalidFields.get(anchor)) {
        validationStatus = ValidationStatus.ERROR
      } else if (this.lastProjectValidation.emptyFields.get(anchor)) {
        validationStatus = ValidationStatus.WARNING
      }
    }

    // For the 'finalisation section': get the worse status
    if (anchor == "finalisation") {
      if (this.lastProjectValidation.invalidFields.size > 0) {
        validationStatus = ValidationStatus.ERROR
      } else if (this.lastProjectValidation.emptyFields.size > 0) {
        validationStatus = ValidationStatus.WARNING
      }
    }

    this.anchorsToTrack.push(anchor)
    return new BulletWithValidation(
      anchor,
      this.$t("projet." + section + "." + anchor).toString(),
      validationStatus
    )
  }

  @Watch("currentScrollY")
  updateActiveAnchor(): void {
    for (let i = 0; i < this.anchorsToTrack.length; i++) {
      const anchorY = document.getElementById(this.anchorsToTrack[i])?.offsetTop
      if (this.pxFromBottom <= 60) {
        this.$root.$emit(
          "contextual-menu-anchor-update",
          this.anchorsToTrack[this.anchorsToTrack.length - 1]
        )
        return
      } else {
        if (anchorY && this.currentScrollY <= anchorY) {
          this.$root.$emit("contextual-menu-anchor-update", this.anchorsToTrack[Math.max(0, i - 1)])
          return
        }
      }
    }
  }

  @Watch("pdfExportRequested")
  pdfExportRequestedChanged(): void {
    if (this.pdfExportRequested) {
      this.$emit("pdf-export-ready")
    }
  }
}
