



















































































import { ValidationProvider } from "vee-validate"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import FormInputLabel from "@/views/inputs/FormInputLabel.vue"

@Component({
  components: {
    ValidationProvider,
    FormInputLabel,
  },
})
export default class GroupCheckboxesWithValidation extends Vue {
  @Prop({ default: "" }) group: string
  @Prop({ default: "" }) vid: string
  @Prop({ default: "" }) label: string
  @Prop() verticalDisplay: boolean
  @Prop() rules: [string]
  @Prop() inputNotice: string
  @Prop({ default: "" }) errorMessage: string
  @Prop({ default: false }) disabled: boolean
  @Prop() checkBoxLabels: string[]
  @Prop() value: string[]
  @Prop() data: string[]
  @Prop() inputLabel: string
  @Prop({ default: "" }) extInputValue: string
  @Prop({ default: "" }) inputPlaceholder: string
  @Prop({ default: true }) validationActivated: boolean
  @Prop({ default: true }) showValidationErrors: boolean

  innerValue: string[] = []
  inputValue = ""
  inputType = ""
  isMandatory = false
  rulesWithoutRequiredRulesIfValidationDisabled = ""

  created(): void {
    if (this.value) {
      this.innerValue = this.value
    }
    if (this.extInputValue) {
      this.inputValue = this.extInputValue
    }
    this.isMandatory = this.rules && this.rules.includes("required")
    this.onRulesChanged()
  }

  @Watch("innerValue")
  onInnerValueChanged(newVal: string[], _oldValue: string[]): void {
    this.inputType = ""
    if (Boolean(this.inputLabel) && this.innerValue.includes(this.inputLabel)) {
      this.inputType = this.inputValue ? "is-success" : "is-danger"
    }

    if (this.inputLabel && !this.innerValue.includes(this.inputLabel)) {
      this.inputValue = ""
    }

    this.$emit("input", newVal)
  }

  @Watch("value")
  onExternalValueChanged(newVal: string[], _oldValue: string[]): void {
    this.innerValue = newVal
    if (newVal.join() != _oldValue.join()) {
      this.$emit("manual-change", newVal)
    }
  }

  onInputChange(): void {
    this.inputType = this.inputValue ? "is-success" : "is-danger"
    this.$emit("input-change", this.inputValue)
  }

  @Watch("rules")
  onRulesChanged(): void {
    this.isMandatory = this.rules && this.rules.includes("required")
    if (this.rules) {
      this.rulesWithoutRequiredRulesIfValidationDisabled = this.rules.toString()
      if (!this.validationActivated) {
        this.rulesWithoutRequiredRulesIfValidationDisabled = this.rules
          .toString()
          .replace("required|", "")
          .replace("|required", "")
          .replace("required", "")
      }
    } else {
      this.rulesWithoutRequiredRulesIfValidationDisabled = ""
    }
  }

  @Watch("validationActivated")
  onValidationActivated(): void {
    this.onRulesChanged()
  }
}
