






































































import { EnumHelper } from "@/model/bean/EnumHelper"
import { PaginationOrder, StructureDTO } from "@/model/bean/GeneratedDTOs"
import { GraeService } from "@/services/rest/grae/GraeService"
import { OrganismeService } from "@/services/rest/organisme/OrganismeService"
import AbstractTableView from "@/views/tables/AbstractTableView.vue"
import Column from "@/views/tables/Column"
import TableFilterView from "@/views/tables/TableFilterView.vue"
import { Component, Prop } from "vue-property-decorator"

@Component({
  components: {
    TableFilterView,
  },
})
export default class GraeStructuresView extends AbstractTableView {
  @Prop() graeId: string | undefined

  private graeService = GraeService.INSTANCE
  private organismeService = OrganismeService.INSTANCE

  structuresList: Array<StructureDTO> = []
  graeNamesList: Array<string> = []

  async reloadData(): Promise<void> {
    this.isLoading = true
    try {
      const paginatedStructuresList = await this.graeService.getStructures(
        this.graeId,
        this.currentPagedSortedParameter,
        this.activeFilters
      )
      this.structuresList = paginatedStructuresList.elements
      this.total = paginatedStructuresList.count

      // Get region lists for filtering purposes
      if (!this.graeId) {
        this.organismeService.getAllGraes().then((graes) => {
          this.graeNamesList = graes.map((g) => g.region)
          this.refreshInitialColumns()
        })
      }
    } catch (e) {
      // Silent catch
    }
    this.isLoading = false
  }

  getInitialColumns(): Array<Column> {
    const initialColumns: Array<Column> = [
      {
        field: "codeDepartement",
        label: this.$t("graePage.details.structures.departement").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "nom",
        label: this.$t("graePage.details.structures.nom").toString(),
        sortable: true,
        backEndSearchable: true,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "ville",
        label: this.$t("graePage.details.structures.ville").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "type",
        label: this.$t("graePage.details.structures.type").toString(),
        sortable: true,
        backEndSearchable: true,
        possibleSearchValues: EnumHelper.allTypesStructure,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "nbProjets",
        label: this.$t("graePage.details.structures.nbProjets").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
    ]
    if (!this.graeId) {
      initialColumns.push({
        field: "grae.region",
        label: this.$t("graeList.region").toString(),
        possibleSearchValues: this.graeNamesList,
        sortable: true,
        backEndSearchable: true,
        visible: true,
        custom: true,
        canBeAdded: true,
      })
    }
    return initialColumns
  }

  getDefaultSort(): Array<PaginationOrder> {
    const defaultSort = new PaginationOrder()
    defaultSort.clause = "nom"
    defaultSort.desc = true
    return [defaultSort]
  }

  showStructureDetailsPage(event: Event, id: number): void {
    // Do not foward click event to row (would trigger modal)
    event.stopPropagation()
    this.$router.push("/structure/" + id + "/details")
  }
  showStructureListesProjetsPage(event: Event, id: number): void {
    // Do not foward click event to row (would trigger modal)
    event.stopPropagation()
    this.$router.push("/structure/" + id + "/projets")
  }

  buildCodeDepartement(codePostal: string): string {
    return codePostal.startsWith("97") || codePostal.startsWith("98")
      ? codePostal.substring(0, 3)
      : codePostal.substring(0, 2)
  }
}
