var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "project-harmonisation-container",
      style: { height: _vm.numberOfLines * 50 + "px", "min-height": "80px" }
    },
    [
      _c("b-loading", {
        model: {
          value: _vm.isApproving,
          callback: function($$v) {
            _vm.isApproving = $$v
          },
          expression: "isApproving"
        }
      }),
      !_vm.isApproving
        ? _c(
            "table",
            {
              staticClass: "project-harmonisation-table",
              class: {
                "project-harmonisation-table-no-instructeur":
                  _vm.evaluations.length == 0
              },
              attrs: {
                id: "project-harmonisation-table-" + _vm.projectAnnuel.id
              }
            },
            [
              _vm._l(_vm.evaluations, function(evaluation, i) {
                return _c("tr", { key: evaluation.id }, [
                  _c("td", { staticClass: "instructeur-cell" }, [
                    _vm.instructeurBeingDeletedId != evaluation.instructeur.id
                      ? _c(
                          "p",
                          [
                            _c("b-button", {
                              staticClass: "remove-button",
                              attrs: {
                                "icon-left": "times",
                                title: _vm.$t(
                                  "instructionPage.remove-instructeur"
                                ),
                                size: "is-small",
                                disabled:
                                  _vm.loggedUser.profil ===
                                    "OBSERVATEUR_NATIONAL" ||
                                  (_vm.loggedUser.profil ===
                                    "ACCOMPAGNATEUR_REGIONAL" &&
                                    _vm.loggedUser.id !==
                                      evaluation.instructeur.id)
                              },
                              on: {
                                click: function($event) {
                                  return _vm.removeInstructeur(
                                    evaluation.instructeur
                                  )
                                }
                              }
                            }),
                            _c("b", [_vm._v(_vm._s(i + 1) + " - ")]),
                            _vm._v(
                              _vm._s(
                                _vm.getInstructeurNameLabel(
                                  evaluation.instructeur
                                )
                              ) + " "
                            )
                          ],
                          1
                        )
                      : _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "instructionPage.labellisations.harmonisation.removing-instructeur"
                                )
                              ) +
                              " "
                          )
                        ])
                  ]),
                  _c("td", { staticClass: "note-cell" }, [
                    _c("p", [
                      _vm._v(" " + _vm._s(evaluation.derouleNote) + " ")
                    ])
                  ]),
                  _c("td", { staticClass: "note-cell" }, [
                    _c("p", [
                      _vm._v(" " + _vm._s(evaluation.adhesionNote) + " ")
                    ])
                  ]),
                  _c("td", { staticClass: "note-cell" }, [
                    _c("p", [
                      _vm._v(" " + _vm._s(evaluation.integrationNote) + " ")
                    ])
                  ]),
                  _c("td", { staticClass: "note-cell" }, [
                    _c("p", [
                      _vm._v(" " + _vm._s(evaluation.implicationNote) + " ")
                    ])
                  ]),
                  _c("td", { staticClass: "comment-cell" }, [
                    _c(
                      "p",
                      [
                        _c("LabellisationEvaluationCommentsPopup", {
                          attrs: {
                            projectAnnuel: _vm.projectAnnuel,
                            evaluation: evaluation,
                            isFinal: false
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("td", { staticClass: "mention-cell" }, [
                    _c("p", { staticClass: "mention" }, [
                      evaluation.mention
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "instructionPage.labellisations.harmonisation.mention." +
                                    evaluation.mention
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ])
                  ]),
                  i == 0
                    ? _c(
                        "td",
                        {
                          staticClass: "actions-cell",
                          attrs: {
                            rowspan:
                              _vm.evaluations.length < _vm.maxInstructeursNumber
                                ? _vm.evaluations.length + 2
                                : _vm.evaluations.length + 1
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "actions-container",
                              style: {
                                height: _vm.numberOfLines * 50 - 20 + "px",
                                "min-height": "80px"
                              }
                            },
                            [
                              _c("b-button", {
                                attrs: {
                                  "icon-left": "eye",
                                  type: "is-link is-outlined",
                                  title: _vm.$t("consult-projet"),
                                  label: _vm.$t("consult")
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.consult($event)
                                  }
                                }
                              }),
                              _c("b-button", {
                                attrs: {
                                  "icon-left": "check",
                                  disabled: !_vm.canApproveEvaluation,
                                  type: "is-link is-outlined",
                                  title: _vm.$t(
                                    "instructionPage.labellisations.harmonisation.approve-evaluation"
                                  ),
                                  label: _vm.$t(
                                    "instructionPage.labellisations.harmonisation.approve"
                                  )
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.approveEvaluation($event)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e()
                ])
              }),
              _vm.evaluations.length < _vm.maxInstructeursNumber
                ? _c(
                    "tr",
                    [
                      !_vm.isAddingInstructeur
                        ? _c(
                            "td",
                            {
                              class: {
                                "assign-instructeur-cell":
                                  _vm.evaluations.length > 0,
                                "assign-instructeur-cell-with-no-evaluation-cell":
                                  _vm.evaluations.length == 0
                              }
                            },
                            [
                              _c(
                                "b-dropdown",
                                {
                                  attrs: {
                                    "aria-role": "list",
                                    disabled: _vm.disabledAssignedButton
                                  },
                                  on: {
                                    change: function(instructeur) {
                                      return _vm.selectInstructeur(instructeur)
                                    }
                                  }
                                },
                                [
                                  _c("b-button", {
                                    attrs: {
                                      slot: "trigger",
                                      "icon-right": "plus",
                                      from: "",
                                      size: "is-small",
                                      type: "is-primary",
                                      label: _vm.$t("instructionPage.assigner")
                                    },
                                    slot: "trigger"
                                  }),
                                  _vm._l(
                                    _vm.allGraeInstructeursExceptAlreadyAssigned,
                                    function(instructeur) {
                                      return _c(
                                        "div",
                                        { key: instructeur.id },
                                        [
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              attrs: {
                                                "aria-role": "listitem",
                                                value: instructeur,
                                                disabled:
                                                  _vm.loggedUser.profil ===
                                                  "OBSERVATEUR_NATIONAL"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getInstructeurNameLabel(
                                                      instructeur
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    }
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        : _c(
                            "td",
                            {
                              class: {
                                "assign-instructeur-cell":
                                  _vm.evaluations.length > 0,
                                "assign-instructeur-cell-with-no-evaluation-cell":
                                  _vm.evaluations.length == 0
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "instructionPage.labellisations.harmonisation.adding-instructeur"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                      _vm._l(4, function(i) {
                        return _c("td", {
                          key: "empty_" + _vm.projectAnnuel.id + i,
                          staticClass: "note-cell"
                        })
                      }),
                      _c("td", { staticClass: "comment-cell" }),
                      _c("td", { staticClass: "mention-cell" }),
                      _vm.evaluations.length == 0
                        ? _c("td", { staticClass: "actions-cell" }, [
                            _c(
                              "div",
                              { staticClass: "actions-container" },
                              [
                                _c("b-button", {
                                  staticStyle: {
                                    "max-width": "110px",
                                    "margin-left": "30px"
                                  },
                                  attrs: {
                                    "icon-left": "eye",
                                    type: "is-link is-outlined",
                                    title: _vm.$t("consult-projet"),
                                    label: _vm.$t("consult")
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.consult($event)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e(),
              _vm.evaluationFinale.id
                ? _c("tr", { staticClass: "final-eval-line" }, [
                    _c("td", { staticClass: "final-eval-cell" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "instructionPage.labellisations.harmonisation.note.final-eval"
                            )
                          ) +
                          " "
                      )
                    ]),
                    _c(
                      "td",
                      { staticClass: "note-cell" },
                      [
                        _c(
                          "b-select",
                          {
                            staticClass: "select-note",
                            attrs: { disabled: !_vm.hasRightToModify },
                            on: {
                              input: function($event) {
                                return _vm.finalEvalChanged(
                                  _vm.evaluationFinale
                                )
                              }
                            },
                            model: {
                              value: _vm.evaluationFinale.derouleNote,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.evaluationFinale,
                                  "derouleNote",
                                  $$v
                                )
                              },
                              expression: "evaluationFinale.derouleNote"
                            }
                          },
                          _vm._l(_vm.notes, function(option) {
                            return _c(
                              "option",
                              { key: option, domProps: { value: option } },
                              [_vm._v(_vm._s(option))]
                            )
                          }),
                          0
                        ),
                        _c("p")
                      ],
                      1
                    ),
                    _c(
                      "td",
                      { staticClass: "note-cell" },
                      [
                        _c(
                          "b-select",
                          {
                            staticClass: "select-note",
                            attrs: { disabled: !_vm.hasRightToModify },
                            on: {
                              input: function($event) {
                                return _vm.finalEvalChanged(
                                  _vm.evaluationFinale
                                )
                              }
                            },
                            model: {
                              value: _vm.evaluationFinale.adhesionNote,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.evaluationFinale,
                                  "adhesionNote",
                                  $$v
                                )
                              },
                              expression: "evaluationFinale.adhesionNote"
                            }
                          },
                          _vm._l(_vm.notes, function(option) {
                            return _c(
                              "option",
                              { key: option, domProps: { value: option } },
                              [_vm._v(_vm._s(option))]
                            )
                          }),
                          0
                        )
                      ],
                      1
                    ),
                    _c(
                      "td",
                      { staticClass: "note-cell" },
                      [
                        _c(
                          "b-select",
                          {
                            staticClass: "select-note",
                            attrs: { disabled: !_vm.hasRightToModify },
                            on: {
                              input: function($event) {
                                return _vm.finalEvalChanged(
                                  _vm.evaluationFinale
                                )
                              }
                            },
                            model: {
                              value: _vm.evaluationFinale.integrationNote,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.evaluationFinale,
                                  "integrationNote",
                                  $$v
                                )
                              },
                              expression: "evaluationFinale.integrationNote"
                            }
                          },
                          _vm._l(_vm.notes, function(option) {
                            return _c(
                              "option",
                              { key: option, domProps: { value: option } },
                              [_vm._v(_vm._s(option))]
                            )
                          }),
                          0
                        )
                      ],
                      1
                    ),
                    _c(
                      "td",
                      { staticClass: "note-cell" },
                      [
                        _c(
                          "b-select",
                          {
                            staticClass: "select-note",
                            attrs: { disabled: !_vm.hasRightToModify },
                            on: {
                              input: function($event) {
                                return _vm.finalEvalChanged(
                                  _vm.evaluationFinale
                                )
                              }
                            },
                            model: {
                              value: _vm.evaluationFinale.implicationNote,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.evaluationFinale,
                                  "implicationNote",
                                  $$v
                                )
                              },
                              expression: "evaluationFinale.implicationNote"
                            }
                          },
                          _vm._l(_vm.notes, function(option) {
                            return _c(
                              "option",
                              { key: option, domProps: { value: option } },
                              [_vm._v(_vm._s(option))]
                            )
                          }),
                          0
                        )
                      ],
                      1
                    ),
                    _c("td", { staticClass: "comment-cell" }, [
                      _c(
                        "p",
                        [
                          _c("LabellisationEvaluationCommentsPopup", {
                            attrs: {
                              projectAnnuel: _vm.projectAnnuel,
                              evaluation: _vm.evaluationFinale,
                              isFinal: true,
                              readOnly: !_vm.hasRightToModify
                            },
                            on: {
                              "comment-changed": function($event) {
                                return _vm.recomputeProjectCanApprove()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("td", { staticClass: "mention-cell" }, [
                      _c(
                        "p",
                        { staticClass: "mention" },
                        [
                          _c(
                            "b-select",
                            {
                              staticClass: "select-note",
                              attrs: { disabled: !_vm.hasRightToModify },
                              on: {
                                input: function($event) {
                                  return _vm.finalEvalChanged(
                                    _vm.evaluationFinale
                                  )
                                }
                              },
                              model: {
                                value: _vm.evaluationFinale.mention,
                                callback: function($$v) {
                                  _vm.$set(_vm.evaluationFinale, "mention", $$v)
                                },
                                expression: "evaluationFinale.mention"
                              }
                            },
                            _vm._l(_vm.mentions, function(option) {
                              return _c(
                                "option",
                                { key: option, domProps: { value: option } },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "instructionPage.labellisations.harmonisation.mention." +
                                            option
                                        )
                                      )
                                    )
                                  ])
                                ]
                              )
                            }),
                            0
                          )
                        ],
                        1
                      )
                    ])
                  ])
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }