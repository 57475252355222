var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-tooltip",
        {
          staticClass: "responsive-evaluation-help",
          attrs: {
            position: "is-bottom",
            multilined: "",
            active: _vm.opened,
            always: ""
          },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "evaluation-help-tooltip-content" },
                    [
                      _c("h3", [_vm._v(_vm._s(_vm.criteria))]),
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.helpText) }
                      })
                    ]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("b-button", {
            attrs: {
              icon: "help",
              label: _vm.$t("instruction-pannel.instruction.evaluation-howto"),
              type: "is-primary"
            },
            on: {
              click: function($event) {
                _vm.opened = !_vm.opened
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }