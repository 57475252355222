










































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import RecapCard from "@/views/projects_pp/RecapCard.vue"

import { ProjetAnnualValidation } from "@/pages/projet-page/commons/ProjectValidationAndSaveHandler.vue"
import { ProjetAnnuelDTO } from "@/model/bean/GeneratedDTOs"

@Component({
  components: { RecapCard },
})
export default class LabellisationRecap extends Vue {
  @Prop() projetValidation: ProjetAnnualValidation
  @Prop() projetAnnuel: ProjetAnnuelDTO
  @Prop({ default: true }) validationActivated: boolean
  @Prop({ default: true }) showValidationErrors: boolean
  @Prop() readonly: boolean
  @Prop() sectionToAnchors: Map<string, string[]>
  @Prop() sectionsToIcon: Map<string, string>
  @Prop({ default: "" }) renewalSuffix: string
  @Prop() isPorteurProjet: boolean

  errorsPerLabel = new Map<string, Map<string, number>>()

  validationActivatedInternal = false

  mounted(): void {
    this.validationActivatedChanged()
  }

  @Watch("validationActivated")
  validationActivatedChanged(): void {
    this.validationActivatedInternal = this.validationActivated
  }

  @Watch("projetValidation")
  validationChanged(): void {
    // Sort errors & warnings per sections and subfields
    this.errorsPerLabel = new Map<string, Map<string, number>>()
    const allErrors = Array.from(this.projetValidation.invalidFields.keys())
    const allWarnings = Array.from(this.projetValidation.emptyFields.keys())
    allErrors.forEach((field: string) => {
      if (field != "finalisation") {
        let numberOfError = this.projetValidation.invalidFields.get(field)?.length
        const section = this.getSectionForField(field)
        let existingErrorsForSection = this.errorsPerLabel.get(section)
        if (!existingErrorsForSection) {
          existingErrorsForSection = new Map<string, number>()
        }
        existingErrorsForSection.set(field, numberOfError ? numberOfError : 0)
        this.errorsPerLabel.set(section, existingErrorsForSection)
      }
    })
    allWarnings.forEach((field: string) => {
      if (field != "finalisation") {
        let numberOfWarnings = this.projetValidation.emptyFields.get(field)?.length
        let numberOfError = this.projetValidation.invalidFields.get(field)?.length
        let total = (numberOfError ? numberOfError : 0) + (numberOfWarnings ? numberOfWarnings : 0)
        const section = this.getSectionForField(field)
        let existingErrorsForSection = this.errorsPerLabel.get(section)
        if (!existingErrorsForSection) {
          existingErrorsForSection = new Map<string, number>()
        }
        existingErrorsForSection.set(field, total)
        this.errorsPerLabel.set(section, existingErrorsForSection)
      }
    })
  }

  redirectToSection(section: string): void {
    this.$router.push({ params: { section: section } })
  }
  getSectionForField(field: string): string {
    for (let key of this.sectionToAnchors.keys()) {
      let anchorsForSection = this.sectionToAnchors.get(key)
      if (!anchorsForSection) {
        anchorsForSection = []
      }
      if (anchorsForSection.indexOf(field) > -1) {
        return key
      }
    }
    return field
  }

  compareSections(a: string, b: string): number {
    let result = 0
    if (a == "autresinfos") {
      result = 1000
    } else if (b == "autresinfos") {
      result = -1000
    } else if (a == "diffusion") {
      result = 500
    } else if (b == "diffusion") {
      result = -500
    } else {
      result = a.localeCompare(b)
    }
    return result
  }
}
