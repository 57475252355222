















































import { ProjetInscriptionDTO } from "@/model/bean/GeneratedDTOs"
import { Component, Prop, Vue } from "vue-property-decorator"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import RadioWithInputAndValidation from "@/views/inputs/RadioWithInputAndValidation.vue"
import { EnumHelper } from "@/model/bean/EnumHelper"

@Component({
  components: { InputWithValidation, RadioWithInputAndValidation },
})
export default class ProjetInscriptionSite extends Vue {
  @Prop() projetAnnuel: ProjetInscriptionDTO
  @Prop({ default: false }) readonly: boolean
  @Prop({ default: true }) showValidationErrors: boolean

  natures = EnumHelper.allNatures
  natureLabels = this.natures.map((nature) => {
    return this.$t(`projet.inscription.site-inscription.nature-labels.${nature}`).toString()
  })
}
