/*-
 * #%L
 * Aires Éducatives
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
/**
 * Shared configuration for all b-table parameters.
 */
import i18n from "@/i18n"
export default class TableConfiguration {
  static INSTANCE = new TableConfiguration()
  // Number of items per b-array page (also use for server-side pagination)
  perPage = 11
  // ms to wait before executing a search (avoids slow downs when user types quickly)
  searchDebounce = 50
  // Sorting icon appearance
  sortIcon = "arrow-up"
  sortIconSize = "is-small"
  // Location of the pagination buttons (previous/next page, page numbers...)
  paginationPosition = "bottom"
  isSearchable = true
  isStriped = true
  isHoverable = true
  stickyHeader = true

  nextLabel(): string {
    return i18n.t("table-page-next").toString()
  }

  previousLabel(): string {
    return i18n.t("table-page-previous").toString()
  }

  pageLabel(): string {
    return i18n.t("table-page-page").toString()
  }

  currentPageLabel(): string {
    return i18n.t("table-page-current").toString()
  }
}
