


































import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import { ValidationProvider } from "vee-validate"

@Component({
  components: { ValidationProvider },
})
export default class CheckBoxEntryInput extends Vue {
  @Prop({ default: "" }) vid: string
  @Prop({ default: "" }) group: string
  @Prop({ default: "" }) entryInputControl: string
  @Prop({ default: "" }) entryInputPlaceholder: string
  @Prop({ default: "" }) entryInputValue: string
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: "" }) name: string
  @Prop({ default: "" }) rules: string
  @Prop({ default: false }) resetInput: boolean

  entryInputType = ""
  innerValue = ""

  created(): void {
    if (this.entryInputValue) {
      this.innerValue = this.entryInputValue
    }
    this.setEntryInputType()
  }

  onInputChange(): void {
    this.setEntryInputType()
    this.$emit("input", this.innerValue)
  }

  @Watch("disabled")
  onDisabledChanged(): void {
    this.setEntryInputType()
  }

  @Watch("resetInput")
  onResetInput(): void {
    if (!this.resetInput) {
      return
    }

    this.innerValue = ""
    this.$emit("input", this.innerValue)
  }

  setEntryInputType(): void {
    if (this.disabled) {
      this.entryInputType = "is-normal"
    } else {
      this.entryInputType = this.innerValue ? "is-success" : "is-danger"
    }
  }
}
