var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("SubTitleView", [
        _vm._v(_vm._s(_vm.$t("profilePage.etablissement.chef")) + " ")
      ]),
      _c("InputWithValidation", {
        attrs: {
          rules: "required",
          label: _vm.$t("profilePage.etablissement.chef-nom"),
          placeholder: _vm.$t("profilePage.etablissement.chef-nom"),
          disabled: _vm.readonly,
          group: _vm.validationGroup,
          vid: "nomChefEtablissement"
        },
        on: { "manual-change": _vm.updateEtablissementChefEtablissement },
        model: {
          value: _vm.etablissement.nomChefEtablissement,
          callback: function($$v) {
            _vm.$set(_vm.etablissement, "nomChefEtablissement", $$v)
          },
          expression: "etablissement.nomChefEtablissement"
        }
      }),
      _c("InputWithValidation", {
        attrs: {
          rules: "required",
          label: _vm.$t("profilePage.etablissement.chef-prenom"),
          placeholder: _vm.$t("profilePage.etablissement.chef-prenom"),
          disabled: _vm.readonly,
          group: _vm.validationGroup,
          vid: "prenomChefEtablissement"
        },
        on: { "manual-change": _vm.updateEtablissementChefEtablissement },
        model: {
          value: _vm.etablissement.prenomChefEtablissement,
          callback: function($$v) {
            _vm.$set(_vm.etablissement, "prenomChefEtablissement", $$v)
          },
          expression: "etablissement.prenomChefEtablissement"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }