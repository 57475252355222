




















import { Component, Vue, Watch } from "vue-property-decorator"
import MapDrawWithClusterView from "@/views/maps/MapDrawWithClusterView.vue"
import { GraeService } from "@/services/rest/grae/GraeService"
import { AireEducativeCartoDTO, UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import { LoginService } from "@/services/rest/login/LoginService"
import LoadingFullScreenSpinner from "@/views/generic_components/LoadingFullScreenSpinner.vue"
import ContentView from "@/views/commons/ContentView.vue"

@Component({
  components: {
    ContentView,
    LoadingFullScreenSpinner,
    MapDrawWithClusterView,
  },
})
export default class MapClusterComponent extends Vue {
  private graeService = GraeService.INSTANCE
  private loginService = LoginService.INSTANCE

  projets: Array<AireEducativeCartoDTO> = []
  innerLoggedUser: UtilisateurDTO | undefined = new UtilisateurDTO()
  loggedUser: UtilisateurDTO | undefined = new UtilisateurDTO()
  guest = true
  role: string | undefined
  isMapClusterReady = false

  async created(): Promise<void> {
    this.loggedUser = await this.loginService.getLoggedUser()
    this.innerLoggedUser = this.loggedUser
    await this.reloadData()
  }

  async reloadData(): Promise<void> {
    this.isMapClusterReady = false
    // this.Projets = []
    try {
      this.guest = !this.innerLoggedUser?.id
      this.role = this.innerLoggedUser?.profil ? this.innerLoggedUser?.profil : ""
      if (this.projets.length === 0) {
        this.projets = await this.graeService.getAllEtablissements()
      }
    } catch (e) {
      console.log(e)
    } finally {
      this.isMapClusterReady = true
    }
  }

  @Watch("loggedUser")
  onLoggedUserChanged(newLoggedUser: UtilisateurDTO): void {
    if (this.innerLoggedUser?.id != newLoggedUser?.id) {
      this.innerLoggedUser = newLoggedUser
      this.reloadData()
    }
  }
}
