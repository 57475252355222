










































































import {
  GraeDTO,
  PaginationParameter,
  ParametrageDTO,
  UtilisateurDTO,
} from "@/model/bean/GeneratedDTOs"
import { Filters } from "@/model/bean/Filters"
import { InstructionService } from "@/services/rest/instruction/InstructionService"
import { LoginService } from "@/services/rest/login/LoginService"
import { OrganismeService } from "@/services/rest/organisme/OrganismeService"
import { ParametersService } from "@/services/rest/parameters/ParametersService"
import { UsersService } from "@/services/rest/users/UsersService"
import ContentView from "@/views/commons/ContentView.vue"
import PageView from "@/views/commons/PageView.vue"
import SubHeaderItem from "@/views/commons/SubHeaderItem.vue"
import { BackToPreviousScreenItem, ContextMenu, NavbarItem } from "@/views/menus/MenuItems"
import { format } from "date-fns"
import * as frLocale from "date-fns/locale/fr"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import InscriptionsView from "./InscriptionsView.vue"
import LabellisationsView from "./labellisation/LabellisationsView.vue"
const DATE_FORMAT = "D MMMM YYYY"

@Component({
  components: {
    PageView,
    ContentView,
    SubHeaderItem,
    InscriptionsView,
    LabellisationsView,
  },
})
export default class InstructionPage extends Vue {
  @Prop() graeId?: string
  @Prop({ default: "inscriptions" }) section: string
  @Prop({ default: 0 }) activeSubTab: number
  @Prop({ default: 0 }) activeQuickFilter: number

  private loginService = LoginService.INSTANCE
  private usersService = UsersService.INSTANCE
  private organismeService = OrganismeService.INSTANCE
  private parametersService = ParametersService.INSTANCE
  private instructionService = InstructionService.INSTANCE

  user = new UtilisateurDTO()
  grae = new GraeDTO()
  parametrage = new ParametrageDTO()

  title = ""
  subTitle = ""
  subTitleIcon = ""

  nbBrouillons = 0
  brouillonFilters = JSON.stringify([
    { property: "typeDossier", values: ["DOSSIER_INSCRIPTION"] },
    { property: "statut", values: ["BROUILLON"] },
  ])
  nbLabellisations = 0

  created(): void {
    const loggedUser = this.loginService.getLoggedUser()
    if (!loggedUser || !loggedUser.id) {
      return
    }

    if (!this.graeId) {
      this.getGraeFromLoggedUser(loggedUser.id)
    } else {
      this.getGraeFromId(loggedUser.id, this.graeId)
    }

    this.subTitle = this.$t("instructionPage.instruction").toString()
    this.activeSectionChanged()
    this.loadParameters()
    this.reloadCount()
  }

  @Watch("grae")
  graeChanged(): void {
    this.reloadCount()
  }

  @Watch("section")
  sectionChanged(): void {
    this.reloadCount()
  }

  reloadCount(): void {
    if (this.grae.id && this.section === "inscriptions") {
      this.countNbBrouillons()
    }
    if (this.grae.id && this.section === "labellisations") {
      this.countNbLabellisations()
    }
  }

  async countNbBrouillons(): Promise<void> {
    const page = await this.instructionService.getInscriptions(
      this.grae.id,
      this.paginationParameter(),
      Filters.asFilters([{ property: "statut", values: ["BROUILLON"] }]),
      undefined
    )
    this.nbBrouillons = page.count
  }

  async countNbLabellisations(): Promise<void> {
    const page = await this.instructionService.getLabellisations(
      this.grae.id,
      this.paginationParameter(),
      Filters.asFilters([
        {
          property: "statut",
          values: ["EN_ATTENTE", "INSTRUCTION_EN_COURS", "INSTRUCTION_TERMINEE"],
        },
      ]),
      undefined
    )
    this.nbLabellisations = page.count
  }

  private paginationParameter(): PaginationParameter {
    const paginationParameter = new PaginationParameter()
    paginationParameter.pageNumber = 1
    paginationParameter.pageSize = 1
    return paginationParameter
  }

  async getGraeFromLoggedUser(id: string): Promise<void> {
    this.user = await this.usersService.getUserProfile(id)
    if (this.user && this.user.grae) {
      this.grae = this.user.grae as GraeDTO
    }
    this.refreshContextMenu(false)
    this.subTitle = this.$t("instructionPage.instruction").toString()
  }

  async getGraeFromId(loggedUserId: string, graedId: string): Promise<void> {
    this.user = await this.usersService.getUserProfile(loggedUserId)
    this.grae = await this.organismeService.getGrae(graedId)
    const isFromGrae = this.user.grae?.id == this.grae.id
    this.refreshContextMenu(!isFromGrae)
    this.subTitle = isFromGrae
      ? this.$t("instructionPage.instruction").toString()
      : this.grae.region
  }

  refreshContextMenu(showBackToListMenuEntry: boolean): void {
    const menuItems = []

    if (this.grae) {
      const graeId = this.grae.id
      // Back to previous screen (if comming from list)
      if (showBackToListMenuEntry) {
        menuItems.push(new BackToPreviousScreenItem())
      }

      // Sub-sections
      menuItems.push(
        new NavbarItem(
          `/instruction/inscriptions/0/0/${graeId}`,
          this.$t("instructionPage.inscriptions.title").toString(),
          "calendar",
          false
        )
      )
      menuItems.push(
        new NavbarItem(
          `/instruction/labellisations/0/0/${graeId}`,
          this.$t("instructionPage.labellisations.title").toString(),
          "address-card",
          false
        )
      )
      const possibleMenuParents = ["/instruction/inscriptions/"]
      this.$root.$emit("contextual-menu-changed", new ContextMenu(possibleMenuParents, menuItems))
    }
  }

  @Watch("section")
  activeSectionChanged(): void {
    this.title = this.$t(`instructionPage.${this.section}.title`).toString()
    this.subTitleIcon = ""
  }

  async loadParameters(): Promise<void> {
    this.parametrage = await this.parametersService.getParametrage(this.graeId)
  }

  formatDate(date: Date): string {
    return date ? format(date, DATE_FORMAT, { locale: frLocale }) : ""
  }
}
