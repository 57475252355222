


















































import { Component, Prop, Vue } from "vue-property-decorator"
import TitleView from "@/views/commons/TitleView.vue"
import { GraeDTO, UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import { InfoReportingService } from "@/services/log/InfoReportingService"

import InputWithValidation from "@/views/inputs/InputWithValidation.vue"
import { PROFIL_UTILISATEUR } from "@/model/bean/EnumHelper"
import AutoCompleteWithValidation from "@/views/inputs/AutoCompleteWithValidation.vue"
import { OrganismeService } from "@/services/rest/organisme/OrganismeService"

@Component({
  components: {
    TitleView,
    InputWithValidation,
    AutoCompleteWithValidation,
  },
})
export default class PosteDetails extends Vue {
  @Prop() user: UtilisateurDTO
  @Prop({ default: true }) regionModifiable: boolean
  @Prop({ default: false }) readonly: boolean

  private infoReportingService = InfoReportingService.INSTANCE
  private organismeService = OrganismeService.INSTANCE

  profils = PROFIL_UTILISATEUR

  region = ""
  regions: string[] = []
  allGraes: GraeDTO[] = []

  mounted(): void {
    if (this.user) {
      this.region = this.user.grae ? this.user.grae.region : ""
    }
    this.init()
  }

  filteredRegions(): string[] {
    return this.regions.filter((r) => r.toLowerCase().includes(this.region.toLowerCase()))
  }

  async init(): Promise<void> {
    try {
      this.allGraes = await this.organismeService.getAllGraes()
      this.regions = this.allGraes.map((g) => g.region)
    } catch (err) {
      this.infoReportingService.error(this.$t("RegionSelection.erreur-api").toString(), err)
    }
  }

  changeUserRegion(newRegion: string): void {
    const matchingGrae = this.allGraes.filter((grae) => grae.region == newRegion)
    if (matchingGrae.length) {
      this.user.grae = matchingGrae[0]
      this.changeUserProfile()
    }
  }

  changeUserProfile(): void {
    this.$emit("change-poste", this.user)
  }
}
