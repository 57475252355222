














import { ProjetAnnuelForInstructionDTO } from "@/model/bean/GeneratedDTOs"
import { Component, Prop, Vue } from "vue-property-decorator"
import Evaluation from "@/pages/instruction/side-pannel/commons/labellisation/Evaluation.vue"

@Component({
  components: {
    Evaluation,
  },
})
export default class InstructionPannelLabellisationEvaluationFinale extends Vue {
  @Prop() project: ProjetAnnuelForInstructionDTO
  @Prop() instructionPannelWidth: number
}
