/*-
 * #%L
 * Sagae
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
import { ProjetAnnuelDTO, ProjetInscriptionDTO } from "@/model/bean/GeneratedDTOs"
import { AbstractTypedRestService } from "@/services/rest/AbstractTypedRestService"

export class ProjectInstructionServiceV2 extends AbstractTypedRestService<ProjetAnnuelDTO> {
  static INSTANCE = new ProjectInstructionServiceV2()

  async acceptOrDeclineProjectInscription(
    project: ProjetInscriptionDTO
  ): Promise<ProjetInscriptionDTO> {
    if (project.statut == "ACCEPTE") {
      return super.typedPutV2("projets/inscription/" + project.id + "/validate", 2, project)
    } else if (project.statut == "REFUSE") {
      return super.typedPutV2("projets/inscription/" + project.id + "/reject", 2, project)
    } else {
      return this.updateInstructionInternalComment(project)
    }
  }

  async updateInstructionInternalComment(
    project: ProjetInscriptionDTO
  ): Promise<ProjetInscriptionDTO> {
    return super.typedPutV2("projets/inscription/" + project.id + "/update-comment", 2, project)
  }
}
