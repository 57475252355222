


































import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import PageView from "@/views/commons/PageView.vue"
import ContentView from "@/views/commons/ContentView.vue"
import GraeDetailsView from "@/views/grae/GraeDetailsView.vue"
import GraeDatesView from "@/views/grae/GraeDatesView.vue"
import GraeMembers from "@/views/grae/GraeMembers.vue"
import IndicateursView from "@/pages/grae/IndicateursView.vue"
import ModifProjetView from "@/pages/modif_projet/ModifProjetView.vue"
import ActualitesView from "@/views/grae/ActualitesView.vue"
import GraeContactView from "@/pages/grae/GraeContactView.vue"

import { GraeDTO, UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import { LoginService } from "@/services/rest/login/LoginService"
import { UsersService } from "@/services/rest/users/UsersService"
import { OrganismeService } from "@/services/rest/organisme/OrganismeService"
import {
  BackToPreviousScreenItem,
  ContextMenu,
  NavbarItem,
  NavbarItemWithoutLink,
} from "@/views/menus/MenuItems"

@Component({
  components: {
    PageView,
    ContentView,
    GraeDetailsView,
    GraeMembers,
    GraeDatesView,
    ActualitesView,
    IndicateursView,
    GraeContactView,
    ModifProjetView,
  },
})
export default class GraePage extends Vue {
  @Prop() graeId?: string
  @Prop({ default: "indicateurs" }) section: string
  @Prop() activeSubTab: number

  private loginService = LoginService.INSTANCE
  private usersService = UsersService.INSTANCE
  private organismeService = OrganismeService.INSTANCE

  user = new UtilisateurDTO()
  grae = new GraeDTO()

  title = ""
  subTitle = ""
  subTitleIcon = ""

  created(): void {
    const loggedUser = this.loginService.getLoggedUser()
    if (!loggedUser || !loggedUser.id) {
      return
    }

    if (!this.graeId) {
      this.getGraeFromLoggedUser(loggedUser.id)
    } else {
      this.getGraeFromId(loggedUser.id, this.graeId)
    }
  }

  async getGraeFromLoggedUser(id: string): Promise<void> {
    this.user = await this.usersService.getUserProfile(id)
    if (this.user && this.user.grae) {
      this.grae = this.user.grae as GraeDTO
    }
    // If user has multi-graes we want to show the back button
    this.refreshContextMenu(this.user.instructedGraes && this.user.instructedGraes.length !== 0)
    this.subTitle = this.$t("graePage.myGrae").toString()
    this.activeSectionChanged()
  }

  async getGraeFromId(loggedUserId: string, graedId: string): Promise<void> {
    this.user = await this.usersService.getUserProfile(loggedUserId)
    this.grae = await this.organismeService.getGrae(graedId)
    const hasOneGrae =
      this.user.grae?.id == this.grae.id &&
      (!this.user.instructedGraes || this.user.instructedGraes.length === 0)
    // If user is from grae but has multi graes, we want to show the back button
    this.refreshContextMenu(!hasOneGrae)
    this.subTitle = hasOneGrae ? this.$t("graePage.myGrae").toString() : this.grae.region
    this.activeSectionChanged()
  }

  refreshContextMenu(showBackToListMenuEntry: boolean): void {
    const menuItems = []
    if (this.grae) {
      const graeId = this.grae.id
      // Back to previous screen (if comming from list)
      if (showBackToListMenuEntry) {
        menuItems.push(new BackToPreviousScreenItem())
      }

      // Current Grae
      menuItems.push(new NavbarItemWithoutLink("GRAE " + this.grae.region, ""))

      // Sub-sections members / actualites / dates
      menuItems.push(
        new NavbarItem(
          "/grae/indicateurs/0/" + graeId,
          this.$t("menu.indicateurs").toString(),
          "chart-pie",
          false
        )
      )
      menuItems.push(
        new NavbarItem(
          "/grae/details/0/" + graeId,
          this.$t("graePage.details.title").toString(),
          "signature",
          false
        )
      )
      // Add instructions menu entries for acc nat
      if (
        this.user.profil == "OBSERVATEUR_NATIONAL" ||
        this.user.profil == "ACCOMPAGNATEUR_NATIONAL" ||
        this.user.profil == "SUPER_ADMINISTRATEUR" ||
        this.user.profil == "ACCOMPAGNATEUR_REGIONAL" ||
        this.user.profil == "ANIMATEUR_REGIONAL"
      ) {
        menuItems.push(
          new NavbarItem(
            `/grae/ModifProjet/0/${graeId}`,
            this.$t("menu.ModifProjet").toString(),
            "calendar",
            false
          )
        )
      }
      menuItems.push(
        new NavbarItem(
          "/grae/membres/0/" + graeId,
          this.$t("graePage.membres.title").toString(),
          "users",
          false
        )
      )
      menuItems.push(
        new NavbarItem(
          "/grae/actualites/0/" + graeId,
          this.$t("graePage.actualites.title").toString(),
          "newspaper",
          false
        )
      )
      menuItems.push(
        new NavbarItem(
          "/grae/dates/0/" + graeId,
          this.$t("graePage.dates.title").toString(),
          "calendar-day",
          false
        )
      )

      // Add instructions menu entries for acc nat
      if (
        this.user.profil == "OBSERVATEUR_NATIONAL" ||
        this.user.profil == "ACCOMPAGNATEUR_NATIONAL" ||
        this.user.profil == "SUPER_ADMINISTRATEUR" ||
        ((this.user.profil == "ACCOMPAGNATEUR_REGIONAL" ||
          this.user.profil == "ANIMATEUR_REGIONAL") &&
          this.user.instructedGraes &&
          this.user.instructedGraes.length !== 0)
      ) {
        menuItems.push(
          new NavbarItem(
            `/instruction/inscriptions/0/0/${graeId}`,
            this.$t("instructionPage.instruction").toString(),
            "calendar",
            false
          )
        )
      }

      // Add contact-project-list
      if (
        (this.user.profil == "ACCOMPAGNATEUR_REGIONAL" ||
          this.user.profil == "ANIMATEUR_REGIONAL") &&
        this.user?.instructedGraes?.length !== 0
      ) {
        menuItems.push(
          new NavbarItem(
            `/grae/contacts/0/${graeId}`,
            this.$t("menu.contact-porteurs-projet").toString(),
            "users",
            false
          )
        )
      }

      const possibleMenuParents = ["/grae-list", "/grae/details/"]
      this.$root.$emit("contextual-menu-changed", new ContextMenu(possibleMenuParents, menuItems))
    }
  }

  @Watch("section")
  activeSectionChanged(): void {
    this.title = this.$t(`graePage.${this.section}.title`).toString()
    this.subTitleIcon = ""
  }
}
