



















































































import { ProjetAnnuelDTO, UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import AbstractTableView from "@/views/tables/AbstractTableView.vue"
import Column from "@/views/tables/Column"
import { Component, Prop, Watch } from "vue-property-decorator"
import ContactModale from "@/views/commons/ContactModale.vue"
import { UsersService } from "@/services/rest/users/UsersService"
import { LoginService } from "@/services/rest/login/LoginService"

@Component({
  components: {
    ContactModale,
  },
})
export default class InstructionPannelContacts extends AbstractTableView {
  @Prop() project: ProjetAnnuelDTO

  openContactModale = false
  isObservateurNational = false

  contacts: UtilisateurDTO[] = []

  private loginService = LoginService.INSTANCE
  private usersService = UsersService.INSTANCE

  @Watch("project")
  projectChanges(): void {
    this.concatContacts()
  }

  created(): void {
    const loggedUser = this.loginService.getLoggedUser()
    if (loggedUser) {
      this.isObservateurNational = this.usersService.isObservateurNational(loggedUser)
    }
  }

  async reloadData(): Promise<void> {
    this.isLoading = true
    this.concatContacts()
    this.isLoading = false
  }

  concatContacts(): void {
    const enseignants = this.project.enseignants
      ? this.project.enseignants.filter((e) => !this.invited(e))
      : []
    const referents = this.project.referents
      ? this.project.referents.filter((r) => !this.invited(r))
      : []
    this.contacts = enseignants.concat(referents)
  }

  getInitialColumns(): Array<Column> {
    return [
      {
        field: "prenom-nom-profil",
        label: this.$t("instruction-pannel.contacts.prenom-nom-profil").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: false,
      },
      {
        field: "coordonnees",
        label: this.$t("instruction-pannel.contacts.coordonnees").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: false,
      },
    ]
  }

  invited(user: UtilisateurDTO): boolean {
    if (user.id) {
      return this.project.consentementEnAttente.indexOf(user.id) !== -1
    } else {
      return false
    }
  }
}
