





import { Component, Vue } from "vue-property-decorator"

import { SettingsService, Settings } from "@/services/rest/utils/SettingsService"

@Component({})
export default class MatomoView extends Vue {
  settings: Settings = new Settings()

  created(): void {
    this.plugMatomoIfNeeded()
  }

  async plugMatomoIfNeeded(): Promise<void> {
    this.settings = await SettingsService.INSTANCE.getSettings()
    var matomoURL = "" + this.settings.MATOMO_URL
    var siteId = "" + this.settings.MATOMO_SITE_ID
    if (matomoURL.length > 5 && siteId.length > 1) {
      // @ts-ignore
      var event = new CustomEvent<Settings>("matomo", { detail: this.settings })
      document.dispatchEvent(event)
    }
  }
}
