/*-
 * #%L
 * Sagae
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
import { ValidationStatus } from "@/model/ValidationResult"

export type MenuItemType =
  | "NORMAL"
  | "BACK_TO_PREVIOUS_SCREEN"
  | "NO_LINK"
  | "BULLET_WITH_VALIDATION"

export class NavbarItem {
  toPath: string
  label: string
  icon: string
  hasSubMenu: boolean
  isLinkActive: boolean
  type: MenuItemType = "NORMAL"
  validationStatus = ValidationStatus.NONE
  children: NavbarItem[] = []

  constructor(toPath: string, label: string, icon: string, hasSubMenu: boolean) {
    this.toPath = toPath
    this.label = label
    this.icon = icon
    this.hasSubMenu = hasSubMenu
    this.isLinkActive = false
  }
}

export class BulletWithValidation extends NavbarItem {
  constructor(anchor: string, label: string, validationStatus: ValidationStatus) {
    super(anchor, label, "", false)
    this.type = "BULLET_WITH_VALIDATION"
    this.validationStatus = validationStatus
  }
}

export class NavbarItemWithoutLink extends NavbarItem {
  constructor(label: string, icon: string) {
    super("", label, icon, false)
    this.type = "NO_LINK"
  }
}

export class BackToPreviousScreenItem extends NavbarItem {
  constructor() {
    super("", "", "carret-left", false)
    this.type = "BACK_TO_PREVIOUS_SCREEN"
  }
}

export class ContextMenu {
  items: NavbarItem[]
  parentsPath: string[]
  activeAnchor = ""

  constructor(parentsPath: string[], items: NavbarItem[]) {
    this.parentsPath = parentsPath
    this.items = items
  }
}
