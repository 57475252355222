import { render, staticRenderFns } from "./JoinProjectBeforeAskingView.vue?vue&type=template&id=7d834928&"
import script from "./JoinProjectBeforeAskingView.vue?vue&type=script&lang=ts&"
export * from "./JoinProjectBeforeAskingView.vue?vue&type=script&lang=ts&"
import style0 from "./JoinProjectBeforeAskingView.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/ofb/sagae/src/main/webapp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7d834928')) {
      api.createRecord('7d834928', component.options)
    } else {
      api.reload('7d834928', component.options)
    }
    module.hot.accept("./JoinProjectBeforeAskingView.vue?vue&type=template&id=7d834928&", function () {
      api.rerender('7d834928', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/projects_pp/JoinProjectBeforeAskingView.vue"
export default component.exports